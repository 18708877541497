import React, { useState } from 'react';
import { useForm } from '../../app/forms/useForm';
import { Form, Message } from 'semantic-ui-react';
import TextInput from '../../app/components/Form/TextInput';
import ClientButton from '../../app/layout/ui/genericStyles/ClientButton';
import { useFirebase } from '../../context/Firebase';
import styled from 'styled-components';

export default function LoginForm({ history }) {
	const formFields = [
		{
			name: 'email',
			title: 'Email',
			type: 'email',
			validate: (x) => {
				var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
				return re.test(x);
			},
			error: 'Must be valid email address',
			placeholder: 'example@turnkey.com'
		},
		{
			name: 'password',
			title: 'Password',
			type: 'password',
			validate: (x) => x.length >= 6,
			error: 'Password must be 6 characters or longer',
			placeholder: '••••••'
		}
	];

	const { formValid, inputProps, formattedValues, values } = useForm(
		formFields
	);
	const [error, setError] = useState();
	const [loading, setLoading] = useState(false);

	const firebase = useFirebase();

	const handleSubmit = () => {
		setLoading(true);
		firebase
			.loginWithEmail(formattedValues.email, formattedValues.password)
			.then(() => history.push('/dashboard'))
			.catch((error) => {
				console.log(error);
				setError(error);
				setLoading(false);
			});
	};

	const inputPropObj = { inputProps, values };

	return (
		<LoginBlock>
			<Form>
				{formFields.map(({ title, name, placeholder }, idx) => (
					<TextInput
						key={idx}
						name={name}
						label={title}
						placeholder={placeholder}
						{...inputPropObj}
					/>
				))}
				{error && <Message negative>{error.message}</Message>}
				{loading ? (
					<ClientButton fluid loading>
						Loading
					</ClientButton>
				) : (
					<ClientButton
						fluid
						disabled={!formValid}
						onClick={() => handleSubmit()}
					>
						LOGIN
					</ClientButton>
				)}
			</Form>
		</LoginBlock>
	);
}

const LoginBlock = styled.div`
	.form .field:first-child {
		border-bottom: 0;
		border-bottom-left-radius: 0 !important;
		border-bottom-right-radius: 0 !important;
		margin-bottom: 0 !important;
	}
	.form .field:first-child input {
		border-radius: 0;
	}
	.form .field:nth-child(2) {
		border-top-left-radius: 0 !important;
		border-top-right-radius: 0 !important;
	}
`;
