import React, { useState, useEffect } from 'react';
import release from '../config/release.json';
import { useFirebase } from '../../context/Firebase';
import { Button, Card } from 'semantic-ui-react';
import styled from 'styled-components';

export default function CheckVersion() {
	const [show, setShow] = useState(false);
	const [dbVersion, setDbVersion] = useState(false);
	const firebase = useFirebase();
	useEffect(() => {
		const unsubscribe = firebase.db
			.collection('settings')
			.doc('release')
			.onSnapshot((snap) => {
				const dbrelease = snap.data();
				setDbVersion(dbrelease.versionClient);
				setShow(release.versionClient !== dbrelease.versionClient);
			});
		return () => unsubscribe();
	});
	return show ? (
		<CheckVersionInfo>
			<Card>
				<Card.Content>
					<h2>New Version Available</h2>
					<p>
						A new version ({dbVersion}) of Turnkey BOSS is available. You need
						to reload this page to see the latest changes.
					</p>
					<Button
						onClick={() => {
							window.location.reload(true);
						}}
					>
						Get latest version
					</Button>
				</Card.Content>
			</Card>
		</CheckVersionInfo>
	) : null;
}

const CheckVersionInfo = styled.div`
	position: fixed;
	top: 50px;
	right: 30px;
	z-index: 999;
	width: 320px;

	box-shadow: 2px 3px 8px 0 rgba(145, 145, 145, 0.17);
	border-radius: 4px;
	h2 {
		color: black;
		margin-top: 0;
	}
	p {
		color: black;
	}
	.ui.button {
		text-transform: uppercase;
		background-color: ${(props) => props.theme.primaryBg} !important;

		color: ${(props) => props.theme.primaryTxt} !important;
	}

	.ui.card {
		margin: 0;
		background: #f3f3f3;
		width: 100%;
		padding: 1em;
	}

	@media (max-width: 576px) {
		width: 250px;
		top: 30px;
		right: 30px;
	}
`;
