import { isBefore } from 'date-fns';
import { getObjFromLink } from '../helpers';

export const formatNewOrderData = (order, profile) => {
	const currentTime = new Date();

	const orderFields = {
		...order,
		dateCreated: currentTime,
		createdBy: {
			name: profile.nameDisplay,
			id: profile.id,
			email: profile.email,
		},
		status: 'draft',
		stock: [],
	};

	if (order.orderType === 'Exchange') {
		orderFields.contactPerson = `${profile.nameDisplay} (${profile.email})`;
	}

	return orderFields;
};

export const formatOrderData = (order, profile) => {
	const currentTime = new Date();

	const orderFields = {
		...order,
		dateUpdated: currentTime,
		lastWorkedOnBy: {
			name: profile.nameDisplay,
			id: profile.id,
			email: profile.email,
		},
	};

	if (order.orderType === 'Exchange') {
		orderFields.contactPerson = `${profile.nameDisplay} (${profile.email})`;
	}

	return orderFields;
};

export const formatDispatchOrderData = (order, firebase, locationLink) => {
	const orderFields = {
		...order,
		dispatchedLocations: firebase.firestore.FieldValue.arrayUnion(locationLink),
	};
	let locations = [];
	if (order.locations) {
		locations = order.locations;
	} else {
		locations = Array.from(new Set(order.stock.map((s) => s.locationLink)));
		orderFields.locations = locations;
	}

	if (
		(order.dispatchedLocations &&
			locations &&
			order.dispatchedLocations.length + 1 === locations.length) ||
		locations.length === 1
	) {
		orderFields.status = 'dispatched';
	} else {
		orderFields.status = 'dispatching';
	}
	const dispatchedStock = order.stock.map((item) => {
		const notEnough = item.quantity > item.availableQuantity;
		return {
			...item,
			quantity: notEnough ? item.availableQuantity : item.quantity,
		};
	});
	orderFields.stock = dispatchedStock;

	return orderFields;
};

export const formatReceiveOrderData = (order, firebase, locationLink) => {
	const orderFields = {
		...order,
		receivedLocations: firebase.firestore.FieldValue.arrayUnion(locationLink),
	};
	let locations = [];
	if (order.locations) {
		locations = order.locations;
	} else {
		locations = Array.from(new Set(order.stock.map((s) => s.locationLink)));
		orderFields.locations = locations;
	}

	if (
		(order.receivedLocations &&
			locations &&
			order.receivedLocations.length + 1 === locations.length) ||
		locations.length === 1
	) {
		orderFields.status = 'received';
	} else {
		orderFields.status = 'receiving';
	}

	return orderFields;
};

export const formatDeliveryNote = (profile, locationLink, stock) => {
	const currentTime = new Date();
	return {
		dateDispatched: currentTime,
		dispatchedBy: {
			name: profile.nameDisplay,
			id: profile.id,
			email: profile.email,
		},
		locationLink,
		stock,
	};
};

export const formatReceiveList = (profile, locationLink, stock) => {
	const currentTime = new Date();
	return {
		dateReceived: currentTime,
		receivedBy: {
			name: profile.nameDisplay,
			id: profile.id,
			email: profile.email,
		},
		locationLink,
		stock,
	};
};

export const formatNotePrint = (profile, locationLink) => {
	const currentTime = new Date();
	return {
		datePrinted: currentTime,
		printedBy: {
			name: profile.nameDisplay,
			id: profile.id,
			email: profile.email,
		},
		locationLink,
	};
};

export const formatOrderDates = (order) => {
	const dispatchDates = getFormattedDates(
		order.dispatchDate,
		order.dispatchTime,
		order.dispatchEndTime
	);

	const returnDates = getFormattedDates(
		order.returnDate,
		order.returnTime,
		order.returnEndTime
	);

	return { ...dispatchDates, ...returnDates };
};

const getFormattedDates = (date, time, endTime, action) => {
	console.log(time);
	let dateFields = {};
	if (date && time) {
		date.setHours(time.getHours());
		date.setMinutes(time.getMinutes());

		time.setFullYear(date.getFullYear(), date.getMonth(), date.getDate());
		dateFields[action === 'dispatch' ? 'dispatchDate' : 'returnDate'] = date;
		dateFields[action === 'dispatch' ? 'dispatchTime' : 'returnTime'] =
			new Date(time);

		if (endTime) {
			endTime.setFullYear(date.getFullYear(), date.getMonth(), date.getDate());
			dateFields[action === 'dispatch' ? 'dispatchEndTime' : 'returnEndTime'] =
				new Date(endTime);
		}
	}
	return dateFields;
};

export const formatNewOrder = ({ type, companyLink, lastOrder, invoice }) => {
	const compId = getObjFromLink(companyLink).id;
	const dateObj = new Date();
	const orderTimestamp = dateObj.getTime().toString();
	const month = dateObj.getUTCMonth() + 1; //months from 1-12
	const day = dateObj.getUTCDate();
	const year = dateObj.getUTCFullYear();
	const newdate = year + '-' + month + '-' + day;

	let orderCount = 1;

	if (lastOrder && lastOrder.length > 0 && lastOrder[0]['orderNumber']) {
		const lastEntry = lastOrder[0]['orderNumber'];
		const index = lastEntry.lastIndexOf('-') + 1;

		const count = parseInt(lastEntry.substr(index));
		orderCount = +count + 1;
	}

	const ordNum = `${compId}-${newdate}-${orderCount}`;

	return {
		compId: compId,
		companyLink,
		orderType: type,
		orderNumber: ordNum,
		step: 1,
		deliveryMethod: 'setup',
		invoice,
	};
};

export const formatIncomingOrders = (
	orders,
	getDatedAvailableStock,
	user,
	forAllOrders,
	stock
) => {
	return orders
		.filter((o) => {
			if (forAllOrders) {
				return !o.isArchived;
			} else {
				if (
					user.isAdmin ||
					user.isClientAdmin ||
					user.isWarehouse ||
					user.isCostCentreManager
				) {
					return !o.isArchived;
				}
				return !o.isArchived && o.createdBy.id === user.id;
			}
		})
		.map((order, index) => {
			let issues = [];
			if (
				order.status !== 'received' &&
				order.status !== 'receiving' &&
				order.status !== 'dispatched' &&
				order.dispatchDate &&
				order.returnDate &&
				order.stock &&
				isBefore(order.dispatchDate.toDate(), order.returnDate.toDate())
			) {
				const availableStock = forAllOrders
					? stock
					: getDatedAvailableStock({
							startDate: order.dispatchDate,
							endDate: order.returnDate,
							order,
							orders,
					  });

				const formattedStock = order.stock.map((item, idx) => {
					const availableStockItem = availableStock.find(
						(s) => s.id === getObjFromLink(item.stockLink).id
					);
					const availableQuantity =
						availableStockItem &&
						availableStockItem.quantities &&
						availableStockItem.quantities.find(
							(q) =>
								q.locationLink === item.locationLink && q.size === item.size
						);
					const quantity = availableQuantity ? availableQuantity.quantity : 0;

					if (quantity < item.quantity) {
						issues = [
							...issues,
							{
								...item,
								availableQuantity: quantity,
							},
						];
					}
					return {
						...item,
						availableQuantity: quantity,
					};
				});
				return { ...order, stock: formattedStock, issues };
			}
			return { ...order, issues };
		});
};

/* Formats stock from db structure to new usable structure (EXAMPLE BELOW)

	OLD
	//fixed

		stock: {
			stock_id__stock_name:{
				location_id: qty
			}
		}

	//variable

		stock: {
			stock_id__stock_name:{
				location_id: {
					size: qty
				}
			}
		}

	NEW
		stock: [
			{
				stockLink: stock_id__stock_name,
				locationLink: location_id__location_name,
				quantity: qty,
				size: "fixed"/"size"
			}
		]

*/

export const formatOrderStock = (orders, locations) => {
	const formattedOrders = orders.map((order) => {
		if (order.stock) {
			let stock = [];
			Object.keys(order.stock).forEach((stockLink) => {
				return Object.keys(order.stock[stockLink]).forEach((locationId) => {
					const location = locations.find((l) => l.id === locationId);
					const locationLink = `${location.id}__${location.warehouseName}`;
					if (
						typeof order.stock[stockLink][locationId] === 'object' &&
						order.stock[stockLink][locationId] !== null
					) {
						Object.keys(order.stock[stockLink][locationId]).forEach((size) => {
							stock = [
								...stock,
								{
									stockLink,
									...getObjFromLink(stockLink),
									locationLink,
									quantity: order.stock[stockLink][locationId][size],
									size,
								},
							];
						});
					} else {
						stock = [
							...stock,
							{
								stockLink,
								...getObjFromLink(stockLink),
								locationLink,
								quantity: order.stock[stockLink][locationId],
								size: 'Fixed',
							},
						];
					}
				});
			});
			return {
				...order,
				stock,
			};
		} else {
			return order;
		}
	});
	return formattedOrders;
};

export const oldFormatStockCart = (cart) => {
	const formattedStock = {};
	cart.forEach((item) => {
		const locationObj = getObjFromLink(item.locationLink);
		if (item.size === 'Fixed') {
			if (
				formattedStock[item.stockLink] &&
				formattedStock[item.stockLink][locationObj.id]
			) {
				formattedStock[item.stockLink][locationObj.id] =
					formattedStock[item.stockLink][locationObj.id] + item.quantity;
			} else {
				formattedStock[item.stockLink] = {
					...formattedStock[item.stockLink],
					[locationObj.id]: item.quantity,
				};
			}
		} else {
			if (
				formattedStock[item.stockLink] &&
				formattedStock[item.stockLink][locationObj.id] &&
				formattedStock[item.stockLink][locationObj.id][item.size]
			) {
				formattedStock[item.stockLink][locationObj.id][item.size] =
					formattedStock[item.stockLink][locationObj.id][item.size] +
					item.quantity;
			} else if (
				formattedStock[item.stockLink] &&
				formattedStock[item.stockLink][locationObj.id]
			) {
				formattedStock[item.stockLink] = {
					...formattedStock[item.stockLink],
					[locationObj.id]: {
						...formattedStock[item.stockLink][locationObj.id],
						[item.size]: item.quantity,
					},
				};
			} else {
				formattedStock[item.stockLink] = {
					...formattedStock[item.stockLink],
					[locationObj.id]: {
						[item.size]: item.quantity,
					},
				};
			}
		}
	});
	return formattedStock;
};
