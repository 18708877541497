// import * as firebase from 'firebase';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import { toastr } from 'react-redux-toastr';
import config from './config';

// Initialize Firebase
firebase.initializeApp(config);

const auth = firebase.auth();
const db = firebase.firestore();
const firestore = firebase.firestore;

const Firebase = {
	/////////////////////// AUTH ///////////////////////////////
	auth,

	loginWithEmail: (email, password) => {
		return auth.signInWithEmailAndPassword(email, password);
	},
	signupWithEmail: (email, password) => {
		return auth.createUserWithEmailAndPassword(email, password);
	},
	signOut: () => {
		return auth.signOut();
	},
	checkUserAuth: (user) => {
		return auth.onAuthStateChanged(user);
	},

	sendSignInMail: (email, actionCodeSettings) => {
		return auth.sendSignInLinkToEmail(email, actionCodeSettings);
	},

	loginInWithLink: (email, link) => {
		return auth.signInWithEmailLink(email, link);
	},
	updatePassword: (password) => {
		return auth.currentUser.updatePassword(password);
	},

	sendForgotPasswordEmail: (email) => {
		return auth.sendPasswordResetEmail(email);
	},

	/////////////////////// FIRESTORE ///////////////////////////////
	db,
	firestore,
	//----------------- USERS -----------------

	createNewUser: (userData) => {
		return db.collection('users').doc().set(userData);
	},

	createUserWithId: (userData) => {
		return db.collection('users').doc(userData.id).set(userData);
	},

	user: (userId) => {
		return db.collection('users').doc(userId);
	},

	users: () => {
		return db.collection('users');
	},

	findUserByEmail: (email) => {
		return db.collection('users').where('email', '==', email);
	},

	archiveUser: (userId) => {
		return db.collection('users').doc(userId).update({ isArchived: true });
	},

	///////ORDERS///////

	orders: (companyId) => {
		return db.collection('companies').doc(companyId).collection('orders');
	},

	order: (companyId, orderId) => {
		return db
			.collection('companies')
			.doc(companyId)
			.collection('orders')
			.doc(orderId);
	},

	createOrder: async (companyId, order) => {
		return await db
			.collection('companies')
			.doc(companyId)
			.collection('orders')
			.add(order)
			.then((data) => data.id);
	},
	updateOrder: (companyId, order) => {
		return db
			.collection('companies')
			.doc(companyId)
			.collection('orders')
			.doc(order.id)
			.update(order);
	},

	/////////STOCK/////////

	stock: (companyId) => {
		return db.collection('companies').doc(companyId).collection('stock');
	},

	createStock: async (companyId, stock) => {
		return await db
			.collection('companies')
			.doc(companyId)
			.collection('stock')
			.add(stock)
			.then((data) => data.id);
	},

	updateStock: (companyId, stock) => {
		return db
			.collection('companies')
			.doc(companyId)
			.collection('stock')
			.doc(stock.id)
			.update(stock);
	},

	/////////COST CENTRES/////////

	costCentres: (companyId) => {
		return db.collection('companies').doc(companyId).collection('costCentres');
	},

	createCostCentre: async (companyId, costCentre) => {
		return await db
			.collection('companies')
			.doc(companyId)
			.collection('costCentres')
			.add(costCentre);
	},

	createCostCentreBatch: async (companyId, centreArray) => {
		const batch = db.batch();

		centreArray.forEach((doc) => {
			const docRef = db
				.collection('companies')
				.doc(companyId)
				.collection('costCentres')
				.doc();
			batch.set(docRef, doc);
		});

		batch.commit();
	},

	updateCostCentre: (compId, costCentre) => {
		return db
			.collection('companies')
			.doc(compId)
			.collection('costCentres')
			.doc(costCentre.id)
			.update(costCentre);
	},

	assignOrderToCentre: (order, compId, costCentre) => {
		return db
			.collection('companies')
			.doc(compId)
			.collection('costCentres')
			.doc(costCentre.id)
			.collection('orders')
			.add(order);
	},

	archiveCostCentre: (compId, costCentre) => {
		return db
			.collection('companies')
			.doc(compId)
			.collection('costCentres')
			.doc(costCentre.id)
			.update({ isArchived: !costCentre.isArchived });
	},

	//////LOCATIONS////////

	locations: () => {
		return db.collection('warehouses');
	},
	createLocation: async (location) => {
		return await db
			.collection('warehouses')
			.add(location)
			.then((data) => data.id);
	},

	updateLocation: (location) => {
		return db.collection('warehouses').doc(location.id).update(location);
	},

	//////COMPANIES////////

	companies: () => {
		return db.collection('companies');
	},

	company: (id) => {
		return db.collection('companies').doc(id);
	},

	/////////BRANDING GROUPS/////////

	brandingGroups: (companyId) => {
		return db
			.collection('companies')
			.doc(companyId)
			.collection('brandingGroups');
	},

	createBrandingGroup: async (companyId, group) => {
		return await db
			.collection('companies')
			.doc(companyId)
			.collection('brandingGroups')
			.add(group)
			.then((data) => data.id);
	},

	updateBrandingGroup: (companyId, group) => {
		return db
			.collection('companies')
			.doc(companyId)
			.collection('brandingGroups')
			.doc(group.id)
			.update(group);
	},
	/////////VARIABLE SIZES/////////

	variableSizes: (companyId) => {
		return db
			.collection('companies')
			.doc(companyId)
			.collection('variableSizes');
	},

	/////////FEEDBACK QUESTIONS/////////

	feedbackQuestions: (companyId) => {
		return db
			.collection('companies')
			.doc(companyId)
			.collection('feedbackQuestions');
	},

	/////////PAYFAST PAYMENTS/////////

	paymentRequests: () => {
		return db.collection('payments').doc('paymentRequests');
	},

	addPaymentRequest: (orderData, orderID) => {
		return db
			.collection('payments')
			.doc('paymentRequests')
			.collection(orderID)
			.add(orderData);
	},

	completedPayments: () => {
		return db.collection('payments').doc('completedPayments');
	},
};

export default Firebase;
