import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';

import App from './app/App';
import 'firebase/firestore';
import 'firebase/auth';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import 'semantic-ui-css/semantic.min.css';
import './index.css';
import { configureStore } from './app/store/configureStore';
import { Provider } from 'react-redux';
import ReduxToaster from 'react-redux-toastr';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import 'react-datepicker/dist/react-datepicker.css';
import LoadingComponent from './app/layout/LoadingComponent';
import LazyLoader from './app/components/LazyLoader';
const store = configureStore();

ReactDOM.render(
	<Provider store={store}>
		<Suspense fallback={<LazyLoader delay={300} />}>
			<BrowserRouter>
				<>
					<ReduxToaster
						position="top-right"
						transitionIn="bounceInDown"
						transitionOut="bounceOutUp"
						progressBar={false}
					/>
					<App />
				</>
			</BrowserRouter>
		</Suspense>
	</Provider>,
	document.getElementById('root')
);
serviceWorker.unregister();
