import React, { useState, useEffect } from 'react';
import { useAuth } from '../../context/auth';
import companyThemes from '../../app/config/companyThemes';
import { getObjFromLink } from '../../context/helpers';
import styled from 'styled-components';
import { Image, Icon, Button } from 'semantic-ui-react';
import LoginForm from './LoginForm';
import ForgotPasswordForm from './ForgotPasswordForm';
import RegisterForm from './RegisterForm';
import releaseJson from '../../app/config/release.json';

export default function LoginPage({ match, history }) {
	const [screen, setScreen] = useState('login');
	const [companyCode, setCompanyCode] = useState();
	const { setCompanyLink, companyLink } = useAuth();

	const [theme, setTheme] = useState();

	if (companyCode === undefined) {
		if (match.params.company) {
			setCompanyCode(match.params.company);
			switch (
				isNaN(parseInt(match.params.company))
					? match.params.company
					: parseInt(match.params.company)
			) {
				case 1:
					setCompanyLink('TK-BOSS-MTN__MTN');
					break;
				case 2:
					setCompanyLink('TK-BOSS-CAPITEC__Capitec');
					break;
				case 3:
					setCompanyLink('TK-BOSS-NBA__NBA');
					break;
				case 4:
					setCompanyLink('TK-BOSS-ADMIN__Turnkey');
					break;
				case 5:
					setCompanyLink('TK-BOSS-SASOL__Sasol');
					break;
				case 6:
					setCompanyLink('TK-BOSS-MOMENTUM__Momentum');
					break;
				case 7:
					setCompanyLink('TK-BOSS-DISCOVERY__Discovery');
					break;
				default:
					setCompanyLink('TK-BOSS-ADMIN__Turnkey');
					break;
			}
		} else {
			setCompanyCode(0);
			const link = localStorage.getItem('companyLink');
			if (link) {
				setCompanyLink(link);
			} else {
				setCompanyLink('TK-BOSS-ADMIN__Turnkey');
			}
		}
	}

	useEffect(() => {
		if (companyLink) {
			const companyObj = getObjFromLink(companyLink);
			setTheme(companyThemes[companyObj.id]);
		}
	}, [companyLink]);

	return (
		<HalfGrid>
			<FormBlock>
				{theme && theme.logoUrl === '/assets/images/logo-turnkey-dark.svg' ? (
					<LogoBlock>
						<Image src={theme && theme.logoUrl} />
						{process.env.REACT_APP_ENV !== 'production' && (
							<StagingLabel>{process.env.REACT_APP_ENV}</StagingLabel>
						)}
					</LogoBlock>
				) : (
					<ClientLogoBlock>
						<Image src="/assets/images/logo-turnkey-dark.svg" />
						<div className="div"></div>
						<Image src={theme && theme.logoUrl} />
						{process.env.REACT_APP_ENV !== 'production' && (
							<StagingLabel>{process.env.REACT_APP_ENV}</StagingLabel>
						)}
					</ClientLogoBlock>
				)}

				<TitleBlock>
					<h1>
						{
							{
								login: 'LOGIN TO TURNKEY BOSS SYSTEM',
								register: 'REGISTER ON TURNKEY BOSS SYSTEM',
								forgot: 'FORGOT PASSWORD',
							}[screen]
						}
					</h1>
				</TitleBlock>
				<FormContainer>
					{
						{
							login: <LoginForm history={history} />,
							register: (
								<RegisterForm companyLink={companyLink} history={history} />
							),
							forgot: <ForgotPasswordForm history={history} />,
						}[screen]
					}
				</FormContainer>
				<Footer>
					{
						{
							login: (
								<div>
									<p className="forgot" onClick={() => setScreen('forgot')}>
										Forgot your password?
									</p>
									<p>
										Don't have an account?{' '}
										<Button size="small" onClick={() => setScreen('register')}>
											Create One <Icon name="arrow right" />
										</Button>
									</p>
								</div>
							),
							register: (
								<div>
									<p>Already have an account?</p>
									<Button size="small" onClick={() => setScreen('login')}>
										<Icon name="arrow left" /> Login
									</Button>
								</div>
							),
							forgot: (
								<div>
									<Button size="small" onClick={() => setScreen('login')}>
										<Icon name="arrow left" /> Back to login
									</Button>
								</div>
							),
						}[screen]
					}
				</Footer>
				<VersionNumber>Version: {releaseJson.versionClient}</VersionNumber>
			</FormBlock>
			<ImageBlock image={theme && theme.loginBgImageUrl}></ImageBlock>
		</HalfGrid>
	);
}
const HalfGrid = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	height: 100vh;
	background: white;
`;

const VersionNumber = styled.div`
	margin: 20px 20px 40px 20px;
	color: rgba(0, 0, 0, 0.5);
	text-align: center;
`;

const FormBlock = styled.div`
	display: grid;
	grid-template-rows: 35vh 5vh auto auto auto;
`;

const ClientLogoBlock = styled.div`
	display: grid;
	justify-content: center;
	align-content: end;
	align-items: center;
	padding-bottom: 5em;
	grid-template-columns: 25% 2px 25%;

	grid-column-gap: 10px;
	.div {
		height: 6vh;
		background: #ddd;
	}
	img {
		max-height: 10vh;
	}
	img:first-child {
		width: 100%;
	}
`;

const LogoBlock = styled.div`
	display: grid;
	justify-content: center;
	align-content: end;
	padding-bottom: 5em;
	grid-template-columns: 25%;
	img {
		width: 100%;
	}
`;

const TitleBlock = styled.div`
	background: #1f1f24;
	display: grid;
	justify-content: center;
	align-content: center;

	h1 {
		color: white;
	}
`;

const FormContainer = styled.div`
	display: grid;
	align-content: center;
	.form {
		width: 80%;
		margin: auto;
		padding: 20px;
	}
	.form .field {
		background-color: #f6f9fc;
		border: 1px solid #d6dce2;
		border-top-left-radius: 5px;
		border-top-right-radius: 5px;
		border-bottom-left-radius: 5px;
		border-bottom-right-radius: 5px;
		margin-bottom: 20px;
	}

	.form .field.error {
		background-color: #fff6f6;
		border: 1px solid #e0b4b4;
		border-top-left-radius: 5px;
		border-top-right-radius: 5px;
		border-bottom-left-radius: 5px;
		border-bottom-right-radius: 5px;
		margin-bottom: 20px;
	}

	.ui.form .field input {
		border-top-left-radius: 0 !important;
		border-top-right-radius: 0 !important;
		border: none;
		background-color: #f6f9fc;
		color: #1f1f24 !important;
		padding: 15px;
	}

	.ui.form input[type='text']:focus,
	.form .field input:focus {
		background-color: #f6f9fc;
	}
	.single-input .form .field {
		margin-bottom: 20px !important;
		border: 1px solid #d6dce2;
		border-radius: 5px !important;
	}
	.single-input .form .field input[type='text'] {
		border-bottom-left-radius: 5px !important;
		border-bottom-right-radius: 5px !important;
	}
	.ui.form .field label {
		text-transform: uppercase;
		color: #8898aa;
		font-family: 'Muli', sans-serif;
		font-weight: 400;
		font-size: 12px;
		padding: 15px;
		margin: 0;
	}

	.ui.form .field label:nth-child(3) {
		text-transform: none;
		font-family: 'Muli', sans-serif;
		font-weight: 600;
		font-size: 10px;
		padding: 5px 15px;
		margin: 0;
	}
`;

const Footer = styled.div`
	text-align: center;
	button.ui.button {
		margin-left: 10px;
	}
	.forgot {
		margin: 0 0 40px 0;
		&:hover {
			cursor: pointer;
			text-decoration: underline;
		}
	}
`;

const ImageBlock = styled.div`
	background-image: url(${(props) => props.image});
	background-size: cover;
	background-position: right;
`;

const StagingLabel = styled.div`
	padding: 8px 12px;
	text-transform: uppercase;
	font-size: 18px;
	text-align: center;
	background: #009688;
	color: #fff;
	margin-top: 30px;
	border-radius: 5px;
	grid-column: 1 / 4;
	justify-self: center;
`;
