import React from 'react';
import { Form } from 'semantic-ui-react';

export default function ValidationWrapper({ children, values, name, width }) {
	return (
		<Form.Field
			style={{ width }}
			error={
				values[name] &&
				values[name].error &&
				values[name].touched &&
				values[name].blur &&
				!values[name].valid
			}
		>
			{children}
			{values[name] &&
				values[name].error &&
				values[name].touched &&
				values[name].blur &&
				!values[name].valid && <label>{values[name].error}</label>}
		</Form.Field>
	);
}
