import React, { useEffect, Suspense } from 'react';

import { Switch, Route, Redirect } from 'react-router-dom';

import LoginPage from '../features/Auth';
// import CreateOrder from '../features/Orders/CreateOrder';
// import HireOrder from '../features/Orders/HireOrder';
import { useAuth } from '../context/auth';
// import ManageStock from '../features/Stock/ManageStock';
// import ViewStock from '../features/Stock/ViewStock';
// import Dispatch from '../features/StockControl/Dispatch';
// import Receive from '../features/StockControl/Receive';
// import OpenOrders from '../features/Orders/OpenOrders';
// import NotApproved from '../features/Dashboard/NotApproved';
// import Users from '../features/Users';
// import OrderHistory from '../features/Orders/OrderHistory';
// import Dashboard from '../features/Dashboard';
// import EditUser from '../features/Users/EditUser';
// import BrandingGroups from '../features/BrandingGroups';
// import Warehouses from '../features/Warehouses';
// import CreateWarehouse from '../features/Warehouses/CreateWarehouse';
// import EditWarehouse from '../features/Warehouses/EditWarehouse';
// import StockTake from '../features/Stock/StockTake';
// import ClientSettings from '../features/ClientSettings';
// import AllStockReport from '../features/Reports/AllStockReport';
// import StockMovements from '../features/Reports/StockMovements/StockMovements';
// import StockUsage from '../features/Reports/StockUsage/StockUsage';
// import AllOrders from '../features/Reports/AllOrders/AllOrders';
// import OrderHistoryReport from '../features/Reports/OrderHistory/OrderHistoryReport';
// import CostCentres from '../features/CostCentres';
//  import EditCostCentre from '../features/CostCentres/EditCostCentre';
// import CreateCostCentre from '../features/CostCentres/CreateCostCentre';
// import Payments from '../features/CostCentres/Payments';
// import MonthlyReport from '../features/Reports/Monthly';
const CreateOrder = React.lazy(() => import('../features/Orders/CreateOrder'));
const BrandingGroups = React.lazy(() => import('../features/BrandingGroups'));
const Warehouses = React.lazy(() => import('../features/Warehouses'));
const CreateWarehouse = React.lazy(() =>
	import('../features/Warehouses/CreateWarehouse')
);
const EditWarehouse = React.lazy(() =>
	import('../features/Warehouses/EditWarehouse')
);
const AllStockReport = React.lazy(() =>
	import('../features/Reports/AllStockReport')
);
const StockMovements = React.lazy(() =>
	import('../features/Reports/StockMovements/StockMovements')
);
const StockUsage = React.lazy(() =>
	import('../features/Reports/StockUsage/StockUsage')
);
const AllOrders = React.lazy(() =>
	import('../features/Reports/AllOrders/AllOrders')
);
const MonthlyReport = React.lazy(() => import('../features/Reports/Monthly'));
const OrderHistoryReport = React.lazy(() =>
	import('../features/Reports/OrderHistory/OrderHistoryReport')
);
const HireOrder = React.lazy(() => import('../features/Orders/HireOrder'));
const ManageStock = React.lazy(() => import('../features/Stock/ManageStock'));
const ViewStock = React.lazy(() => import('../features/Stock/ViewStock'));
const Dispatch = React.lazy(() => import('../features/StockControl/Dispatch'));
const Receive = React.lazy(() => import('../features/StockControl/Receive'));
const OpenOrders = React.lazy(() => import('../features/Orders/OpenOrders'));
const NotApproved = React.lazy(() =>
	import('../features/Dashboard/NotApproved')
);
const Users = React.lazy(() => import('../features/Users'));
const OrderHistory = React.lazy(() =>
	import('../features/Orders/OrderHistory')
);
const Dashboard = React.lazy(() => import('../features/Dashboard'));
const EditUser = React.lazy(() => import('../features/Users/EditUser'));
const StockTake = React.lazy(() => import('../features/Stock/StockTake'));
const ClientSettings = React.lazy(() => import('../features/ClientSettings'));
const CostCentres = React.lazy(() => import('../features/CostCentres'));
const EditCostCentre = React.lazy(() =>
	import('../features/CostCentres/EditCostCentre')
);
const CreateCostCentre = React.lazy(() =>
	import('../features/CostCentres/CreateCostCentre')
);
const Payments = React.lazy(() => import('../features/CostCentres/Payments'));
export default function Router() {
	const adminRoutes = [
		{
			path: '/stock/branding-groups',
			component: BrandingGroups,
		},
		{ path: '/warehouses', component: Warehouses },
		{ path: '/warehouses/create', component: CreateWarehouse },
		{ path: '/warehouses/edit/:id', component: EditWarehouse },
		{ path: '/reports/all-stock', component: AllStockReport },
		{ path: '/reports/stock-movements', component: StockMovements },
		{ path: '/reports/stock-usage', component: StockUsage },
		{ path: '/reports/all-orders', component: AllOrders },
		{ path: '/reports/monthly', component: MonthlyReport },
		{ path: '/reports/order-history', component: OrderHistoryReport },
		{ path: '/cost-centres', component: CostCentres },
		{ path: '/cost-centres/edit/:id', component: EditCostCentre },
		{ path: '/cost-centres/create', component: CreateCostCentre },
	];

	const clientAdminRoutes = [
		{ path: '/users', component: Users },
		{ path: '/users/:id', component: Users },
		{ path: '/users/edit/:id', component: EditUser },
		{ path: '/client-settings', component: ClientSettings },
	];

	const clientRoutes = [];

	const authRoutes = [
		{ path: '/orders/create-order', component: CreateOrder },
		{ path: '/orders/hire/:id', component: HireOrder },
		{ path: '/orders/hire/:id/:summary', component: HireOrder },
		{ path: '/orders/order-history', component: OrderHistory },
		{ path: '/orders/view-open-orders', component: OpenOrders },
		{ path: '/orders/view-open-orders/:id', component: OpenOrders },
		{ path: '/stock/manage/:id', component: ManageStock },
		{ path: '/stock/create', component: ManageStock },
		{ path: '/stock-control/dispatch', component: Dispatch },
		{ path: '/stock-control/dispatch/:id', component: Dispatch },
		{ path: '/stock-control/receive', component: Receive },
		{ path: '/stock-control/receive/:id', component: Receive },
		{ path: '/dashboard', component: Dashboard },
		{ path: '/stock/view-stock', component: ViewStock },
		{ path: '/stock/stock-take', component: StockTake },
		{ path: '/cost-centre-payments', component: Payments },
	];
	const { isAdmin, isClient, isApproved, isClientAdmin, isArchived } =
		useAuth();

	const routes = (routeArr, name) => {
		return routeArr.map((route, idx) => (
			<Route
				key={`${idx}-name`}
				exact
				path={route.path}
				component={route.component}
			/>
		));
	};

	return isApproved === undefined ? (
		<Switch>
			<Route exact path="/" component={LoginPage} />
			<Route exact path="/login/:company" component={LoginPage} />
		</Switch>
	) : !isApproved ? (
		<Switch>
			<Route exact path="/" component={LoginPage} />
			<Route exact path="/login/:company" component={LoginPage} />

			<Route exact path="/dashboard" component={NotApproved} />
		</Switch>
	) : (
		<Switch>
			{isAdmin && routes(adminRoutes, 'admin')}
			{(isClientAdmin || isAdmin) && routes(clientAdminRoutes, 'clientAdmin')}
			{(isClient || isClientAdmin || isAdmin) && routes(clientRoutes, 'client')}
			{routes(authRoutes, 'auth')}

			<Redirect to="/dashboard" />
		</Switch>
	);
}
