export const ORDER_STATUSES = {
	DRAFT: 'draft',
	PENDING: 'pending',
	APPROVED: 'approved',
	DISPATCHING: 'dispatching',
	DISPATCHED: 'dispatched',
	DECLINED: 'declined',
};

export const IS_ORDER_EDITABLE = [ORDER_STATUSES.DRAFT, ORDER_STATUSES.PENDING];
