import React, { useState } from 'react';
import { Form, Message, Icon } from 'semantic-ui-react';
import TextInput from '../../app/components/Form/TextInput';
import { useForm } from '../../app/forms/useForm';
import { useFirebase } from '../../context/Firebase';
import styled from 'styled-components';
import ClientButton from '../../app/layout/ui/genericStyles/ClientButton';
import { toastr } from 'react-redux-toastr';
import axios from 'axios';

export default function ForgotPasswordForm() {
	const formFields = [
		{
			name: 'email',
			title: 'Email',
			type: 'email',
			validate: (x) => {
				var re =
					/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
				return re.test(x);
			},
			error: 'Must be valid email address',
			placeholder: 'example@turnkey.com',
		},
	];

	const { formValid, inputProps, formattedValues, values } =
		useForm(formFields);
	const [error, setError] = useState();
	const [loading, setLoading] = useState(false);

	const firebase = useFirebase();

	const handleSubmit = () => {
		setLoading(true);
		console.log(formattedValues.email, 'dd');
		axios
			.post(
				`${process.env.REACT_APP_CLOUD_FUNCTIONS}/app/changePassword`,
				{ email: formattedValues.email },
				{
					headers: {
						'Content-Type': 'application/json',
					},
				}
			)
			.then((data) => {
				setLoading(false);
				toastr.success('Success', 'Password reset email sent');
			})
			.catch((error) => {
				console.log(error);
				setError(error);
				setLoading(false);
			});
	};
	const inputPropObj = { inputProps, values };
	return (
		<ForgotBlock>
			<Form>
				<Message info>
					<Icon name="info circle" />
					Enter your email address and we will send you a password reset email
				</Message>
				{formFields.map(({ title, name, placeholder }, idx) => (
					<TextInput
						key={idx}
						name={name}
						label={title}
						placeholder={placeholder}
						{...inputPropObj}
					/>
				))}
				{error && <Message negative>{error.message}</Message>}
				{loading ? (
					<ClientButton fluid loading>
						Loading
					</ClientButton>
				) : (
					<ClientButton
						fluid
						disabled={!formValid}
						onClick={() => handleSubmit()}
					>
						RESET PASSWORD
					</ClientButton>
				)}
			</Form>
		</ForgotBlock>
	);
}
const ForgotBlock = styled.div``;
