export default {
	'TK-BOSS-ADMIN': {
		primaryBg: '#760E00',
		primaryTxt: '#FFFFFF',
		secondaryBg: '#6A0C00',
		secondaryTxt: '#FFFFFF',
		loginBgImageUrl: '/assets/images/login-bg-turnkey.jpg',
		logoUrl: '/assets/images/logo-turnkey-dark.svg',
	},
	'TK-BOSS-LOCAL': {
		primaryBg: '#004572',
		primaryTxt: '#FFFFFF',
		secondaryBg: '#023C62',
		secondaryTxt: '#FFFFFF',
		loginBgImageUrl: '/assets/images/login-bg-mtn.jpg',
		logoUrl: '/assets/images/logo-capitec.png',
	},
	'TK-BOSS-MTN': {
		primaryBg: '#FFC200',
		primaryTxt: '#333',
		secondaryBg: '#FFCB29',
		secondaryTxt: '#333',
		loginBgImageUrl: '/assets/images/login-bg-mtn.jpg',
		logoUrl: '/assets/images/logo-mtn.png',
	},
	'TK-BOSS-CAPITEC': {
		primaryBg: '#004572',
		primaryTxt: '#FFFFFF',
		secondaryBg: '#023C62',
		secondaryTxt: '#FFFFFF',
		loginBgImageUrl: '/assets/images/login-bg-capitec.jpg',
		logoUrl: '/assets/images/logo-capitec.png',
	},
	'TK-BOSS-NBA': {
		primaryBg: '#006BBD',
		primaryTxt: '#FFFFFF',
		secondaryBg: '#0460A7',
		secondaryTxt: '#FFFFFF',
		loginBgImageUrl: '/assets/images/login-bg-nba.jpg',
		logoUrl: '/assets/images/logo-nba.png',
	},
	'TK-BOSS-SASOL': {
		primaryBg: '#033b61',
		primaryTxt: '#FFFFFF',
		secondaryBg: '#007db6',
		secondaryTxt: '#FFFFFF',
		loginBgImageUrl: '/assets/images/login-bg-sasol.jpg',
		logoUrl: '/assets/images/logo-sasol.png',
	},
	'TK-BOSS-DISCOVERY': {
		primaryBg: '#004c8e',
		primaryTxt: '#FFFFFF',
		secondaryBg: '#be8b60',
		secondaryTxt: '#333',
		loginBgImageUrl: '/assets/images/login-bg-discovery.jpg',
		logoUrl: '/assets/images/logo-discovery.jpg',
	},
	'TK-BOSS-MOMENTUM': {
		primaryBg: '#003366', // the primary colour used throughout the app
		primaryTxt: '#FFFFFF', // text colour used on top of the primary colour
		secondaryBg: '#3B4B6F', // the secondary colour used (darker version of primary)
		secondaryTxt: '#FFFFFF', // text colour used on top of the primary colour
		loginBgImageUrl: '/assets/images/login-bg-momentum.jpg',
		//background image for login page
		logoUrl: '/assets/images/logo-momentum.svg', // client logo
	},
};
