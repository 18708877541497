import React, { useEffect, useState } from 'react';
import LoadingComponent from '../layout/LoadingComponent';

const LazyLoader = ({ delay = 250, ...props }) => {
	const [show, setShow] = useState(false);

	useEffect(() => {
		const timeout = setTimeout(() => {
			setShow(true);
		}, delay);
		return () => {
			clearTimeout(timeout);
		};
	}, [delay]);

	return show ? <LoadingComponent {...props} /> : null;
};

export default LazyLoader;
