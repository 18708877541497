import React, { createContext, useEffect, useState, useContext } from 'react';
import { useFirebase } from '../Firebase';
import { toastr } from 'react-redux-toastr';
import { useAuth, useUser } from '../auth';
import { getObjFromLink, transformCollectionSnap } from '../helpers';
import { formatUpdateCostCentre } from '../stock/formatting';

const CostCentreContext = createContext({});

export const CostCentreProvider = ({ children }) => {
	const [costCentres, setCostCentres] = useState([]);
	const { companyLink, isApproved, isArchived } = useAuth();
	const user = useUser();
	const compObj = companyLink ? getObjFromLink(companyLink) : {};
	const firebase = useFirebase();
	const compId = compObj.id ? compObj.id : '';
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const unsubscribe =
			compId !== '' && isApproved && !isArchived
				? firebase.costCentres(compId).onSnapshot((snap) => {
						setCostCentres(transformCollectionSnap(snap));
						setLoading(false);
				  })
				: () => console.log('waiting for user to load');
		return () => unsubscribe();
	}, [firebase, compId, isApproved, isArchived]);

	const createCostCentre = (compId, costCentre) => {
		return firebase
			.createCostCentre(compId, formatUpdateCostCentre(costCentre, user))
			.then(() => {
				toastr.success('Success', 'Cost Centre created');
			});
	};

	const createCostCentreBatch = (compId, centreArray) => {
		const formattedArray = centreArray.map((centre) =>
			formatUpdateCostCentre(centre, user)
		);
		return firebase.createCostCentreBatch(compId, formattedArray).then(() => {
			toastr.success('Success', 'Cost Centres created');
		});
	};

	const updateCostCentre = (compId, costCentre) => {
		firebase
			.updateCostCentre(compId, formatUpdateCostCentre(costCentre, user))
			.then(() => {
				toastr.success('Success', 'Cost Centre updated');
			});
	};

	const expenseCodes = [
		{
			code: '77701000',
			description: 'Intermediary Promotions',
		},
		{
			code: '77701200',
			description: 'Provider/Supplier',
		},
		{
			code: '96070100',
			description: 'Entertainment',
		},
		{
			code: '76002700',
			description: 'Staff Entertainment',
		},
		{
			code: '77700400',
			description: 'Branding',
		},
	];

	// const assignOrderToCentre = (order, compId, costCentre) => {
	// 	console.log(order);
	// 	console.log(compId);
	// 	console.log(costCentre);
	// 	firebase
	// 		.assignOrderToCentre(
	// 			order,
	// 			compId,
	// 			formatUpdateCostCentre(costCentre, user)
	// 		)
	// 		.then(() => {
	// 			toastr.success('Success', 'Order assigned to cost centre');
	// 		});
	// };

	const archiveCostCentre = (costCentre) => {
		return firebase.archiveCostCentre(compId, costCentre).then(() => {
			toastr.success(
				'Success',
				`Cost centre has been ${
					!costCentre.isArchived ? 'archived' : 'reactivated'
				}`
			);
		});
	};

	return (
		<CostCentreContext.Provider
			value={{
				loading,
				costCentres,
				createCostCentre,
				updateCostCentre,
				// assignOrderToCentre,
				archiveCostCentre,
				expenseCodes,
				createCostCentreBatch,
			}}
		>
			{children}
		</CostCentreContext.Provider>
	);
};

export const CostCentreConsumer = CostCentreContext.Consumer;

export const useCostCentres = () => useContext(CostCentreContext);
