import React, { createContext, useState, useEffect, useContext } from 'react';
import { OrdersProvider, useOrders } from '../orders';
import { PaymentsProvider, usePayments } from '../payments';
import { StockProvider, useStock } from '../stock';
import { LocationsProvider, useLocations } from '../locations';
import { BrandingGroupsProvider, useBrandingGroups } from '../brandingGroups';
import { VariableSizesProvider, useVariableSizes } from '../variableSizes';
import { CompaniesProvider, useCompanies } from '../companies';
import { UsersProvider, useUsers } from '../users';
import { SettingsProvider, useSettings } from '../settings';
import { useAuth } from '../auth';
import { CostCentreProvider } from '../costCentres';

export const DataProvider = ({ children }) => {
	return (
		<SettingsProvider>
			<UsersProvider>
				<CompaniesProvider>
					<BrandingGroupsProvider>
						<LocationsProvider>
							<VariableSizesProvider>
								<StockProvider>
									<CostCentreProvider>
										<OrdersProvider>
											<PaymentsProvider>
												<LoadingProvider>{children}</LoadingProvider>
											</PaymentsProvider>
										</OrdersProvider>
									</CostCentreProvider>
								</StockProvider>
							</VariableSizesProvider>
						</LocationsProvider>
					</BrandingGroupsProvider>
				</CompaniesProvider>
			</UsersProvider>
		</SettingsProvider>
	);
};

const LoadingContext = createContext({});

export const LoadingProvider = ({ children }) => {
	const users = useUsers();
	const companies = useCompanies();
	const brandingGroups = useBrandingGroups();
	const locations = useLocations();
	const variableSizes = useVariableSizes();
	const stock = useStock();
	const orders = useOrders();
	const settings = useSettings();
	const [loading, setLoading] = useState(true);
	const { companyLink } = useAuth();

	useEffect(() => {
		const loadingArr = [
			users.loading,
			companies.loading,
			brandingGroups.loading,
			locations.loading,
			variableSizes.loading,
			stock.loading,
			orders.loading,
			settings.loading,
		];

		setLoading(
			companyLink &&
				!loadingArr.every((l) => {
					return l === false;
				})
		);
	}, [
		users.loading,
		companies.loading,
		brandingGroups.loading,
		locations.loading,
		variableSizes.loading,
		stock.loading,
		orders.loading,
		settings.loading,
		companyLink,
	]);

	return (
		<LoadingContext.Provider value={{ loading }}>
			{children}
		</LoadingContext.Provider>
	);
};

// export const LoadingProvider = ({ children }) => {
// 	const users = useUsers();
// 	const companies = useCompanies();
// 	const brandingGroups = useBrandingGroups();
// 	const locations = useLocations();
// 	const variableSizes = useVariableSizes();
// 	const stock = useStock();
// 	const orders = useOrders();
// 	const payments = usePayments();
// 	const settings = useSettings();
// 	const [loading, setLoading] = useState(true);
// 	const { companyLink } = useAuth();

// 	useEffect(() => {
// 		const loadingArr = [
// 			users.loading,
// 			companies.loading,
// 			brandingGroups.loading,
// 			locations.loading,
// 			variableSizes.loading,
// 			stock.loading,
// 			orders.loading,
// 			payments.loading,
// 			settings.loading,
// 		];

// 		setLoading(
// 			companyLink &&
// 				!loadingArr.every((l) => {
// 					return l === false;
// 				})
// 		);
// 	}, [
// 		users.loading,
// 		companies.loading,
// 		brandingGroups.loading,
// 		locations.loading,
// 		variableSizes.loading,
// 		stock.loading,
// 		orders.loading,
// 		payments.loading,
// 		settings.loading,
// 		companyLink,
// 	]);

// 	return (
// 		<LoadingContext.Provider value={{ loading }}>
// 			{children}
// 		</LoadingContext.Provider>
// 	);
// };

export const LoadingConsumer = LoadingContext.Consumer;

export const useLoading = () => useContext(LoadingContext);
