export const places = [
	{
		PlaceName: 'ABBOTSDALE',
		Code: '7300    ',
		Hub: 'CPT',
		Ring: 1,
		OpsHub: 'CPT',
		Dist: 50,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'ABBOTSFORD, East London',
		Code: '5241    ',
		Hub: 'ELS',
		Ring: 0,
		OpsHub: 'ELS',
		Dist: 10,
		AreaName: 'East London Central',
		Surch: null,
	},
	{
		PlaceName: 'ABBOTSFORD, Johannesburg',
		Code: '2192    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'ABBOTSPOORT',
		Code: '0608    ',
		Hub: 'PTG',
		Ring: 2,
		OpsHub: 'JNB',
		Dist: 300,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'ABERDEEN',
		Code: '6270    ',
		Hub: 'PLZ',
		Ring: 1,
		OpsHub: 'PLZ',
		Dist: 290,
		AreaName: 'Port Elizabeth Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'ACORN, Pinetown',
		Code: '3610',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'ACORNHOEK',
		Code: '1360    ',
		Hub: 'NLP',
		Ring: 2,
		OpsHub: 'JNB',
		Dist: 310,
		AreaName: 'Johannesburg Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'ACTIVIA PARK, Elandsfontein',
		Code: '1429    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'ACTONVILLE Boxes',
		Code: '1506    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'ACTONVILLE, Benoni',
		Code: '1500',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'ADAMAYVIEW, Klerksdorp',
		Code: '2571    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 150,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'ADCOCK VALE Ext, Port Elizabeth',
		Code: '6045    ',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'ADCOCK VALE, Port Elizabeth',
		Code: '6001    ',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'ADDINGTON, Durban',
		Code: '4001    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'ADDNEY',
		Code: '0712    ',
		Hub: 'PTG',
		Ring: 2,
		OpsHub: 'JNB',
		Dist: 400,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'ADDO',
		Code: '6105    ',
		Hub: 'PLZ',
		Ring: 1,
		OpsHub: 'PLZ',
		Dist: 60,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'ADELAIDE',
		Code: '5760    ',
		Hub: 'PLZ',
		Ring: 2,
		OpsHub: 'PLZ',
		Dist: 200,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'ADENDORP',
		Code: '6282    ',
		Hub: 'PLZ',
		Ring: 2,
		OpsHub: 'PLZ',
		Dist: 280,
		AreaName: 'Port Elizabeth Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'ADRIAANSE, Matroosfontein',
		Code: '7490    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'AERORAND, Middelburg',
		Code: '1055    ',
		Hub: 'MBG',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 140,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'AEROTON, Johannesburg',
		Code: '2190    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'AFGUNS, Ellisras',
		Code: '0534    ',
		Hub: 'PRY',
		Ring: 4,
		OpsHub: 'PRY',
		Dist: 180,
		AreaName: 'Pretoria Regional',
		Surch: null,
	},
	{
		PlaceName: 'AGAVIA, Krugersdorp',
		Code: '1739    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'AIRDLIN, Sunninghill',
		Code: '2157    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'AIRFIELD, Benoni',
		Code: '1501    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'AIRLIE, Constantia',
		Code: '7806    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'AIRPORT INDUSTRIA, Cape Town',
		Code: '7490    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'AKASIA, Potgietersrus',
		Code: '0601    ',
		Hub: 'PTG',
		Ring: 3,
		OpsHub: 'JNB',
		Dist: 300,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'AKASIA, Pretoria North',
		Code: '0601',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'AKASIAPARK Boxes',
		Code: '7415    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'AKASIAPARK, Goodwood',
		Code: '7460    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'AKASIAPARKKAMP, Goodwood',
		Code: '7460    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'AKKERHOF',
		Code: '0006    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'ALABAMA, Klerksdorp',
		Code: '2571    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 150,
		AreaName: 'Johannesburg Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'ALAN MANOR, Johannesburg',
		Code: '2091    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'ALBANY, Pinetown',
		Code: '3610',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'ALBERANTE Ext 1, Alberton',
		Code: '1449    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'ALBERMARLE & Ext 1',
		Code: '1401    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'ALBERT ROAD, SALT RIVER',
		Code: '7930    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'ALBERTINIA',
		Code: '6795    ',
		Hub: 'GRJ',
		Ring: 1,
		OpsHub: 'GRJ',
		Dist: 90,
		AreaName: 'George Regional',
		Surch: null,
	},
	{
		PlaceName: 'ALBERTON Boxes',
		Code: '1450    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'ALBERTON Ext 28, Alberton',
		Code: '1449    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'ALBERTON NORTH Boxes',
		Code: '1456    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'ALBERTON NORTH, Alberton',
		Code: '1449    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'ALBERTON, Johannesburg',
		Code: '1449    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'ALBERTON-NOORD Busse',
		Code: '1456    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'ALBERTON-NOORD, Alberton',
		Code: '1449    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'ALBERTSDAL & Ext 6 &',
		Code: '1449    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'ALBERTSKROON, Johannesburg',
		Code: '2195    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'ALBERTSVILLE, Johannesburg',
		Code: '2195    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'ALBERTSVILLE, Port Shepstone',
		Code: '4240    ',
		Hub: 'DUR',
		Ring: 3,
		OpsHub: 'DUR',
		Dist: 130,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'ALBERTWEG, SOUTRIVIER',
		Code: '7930    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'ALBERTYNSHOF, Kimberley',
		Code: '8301    ',
		Hub: 'KIM',
		Ring: 0,
		OpsHub: 'KIM',
		Dist: 20,
		AreaName: 'Kimberley Central',
		Surch: null,
	},
	{
		PlaceName: 'ALBOWVILLE, Rugby',
		Code: '7405    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'ALDARAPARK, Randburg',
		Code: '2194    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'ALEXANDER BAY',
		Code: '8290    ',
		Hub: 'CPT',
		Ring: 2,
		OpsHub: 'CPT',
		Dist: 860,
		AreaName: 'Cape Town Regional',
		Surch: 'Far Outlying',
	},
	{
		PlaceName: 'ALEXANDER PARK, Pinetown',
		Code: '3610',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'ALEXANDERBAAI',
		Code: '8290    ',
		Hub: 'CPT',
		Ring: 2,
		OpsHub: 'CPT',
		Dist: 860,
		AreaName: 'Cape Town Regional',
		Surch: 'Far Outlying',
	},
	{
		PlaceName: 'ALEXANDRA Boxes',
		Code: '2014    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'ALEXANDRA INRIGTING, Maitland',
		Code: '7405    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'ALEXANDRA INSTITUTE, Maitland',
		Code: '7405    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'ALEXANDRA, Johannesburg',
		Code: '2090    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'ALEXANDRA, Pietermaritzburg',
		Code: '3200',
		Hub: 'PMB',
		Ring: 0,
		OpsHub: 'PMB',
		Dist: 75,
		AreaName: 'Pietermaritzburg Central',
		Surch: null,
	},
	{
		PlaceName: 'ALEXANDRIA',
		Code: '6185    ',
		Hub: 'PLZ',
		Ring: 2,
		OpsHub: 'PLZ',
		Dist: 110,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'ALGOAPARK Boxes',
		Code: '6005    ',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'ALGOAPARK, Port Elizabeth',
		Code: '6001    ',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'ALICE',
		Code: '5700',
		Hub: 'ELS',
		Ring: 2,
		OpsHub: 'ELS',
		Dist: 100,
		AreaName: 'East London Regional',
		Surch: null,
	},
	{
		PlaceName: 'ALICEDALE',
		Code: '6135    ',
		Hub: 'PLZ',
		Ring: 2,
		OpsHub: 'PLZ',
		Dist: 120,
		AreaName: 'Port Elizabeth Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'ALICEDALE Est/Lgd, Athlone',
		Code: '7764    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'ALIWAL NORTH',
		Code: '9750',
		Hub: 'BFN',
		Ring: 1,
		OpsHub: 'BFN',
		Dist: 180,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'ALIWAL-NOORD',
		Code: '9750',
		Hub: 'BFN',
		Ring: 1,
		OpsHub: 'BFN',
		Dist: 180,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'ALKANTRANT',
		Code: '0005    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'ALLANDALE EXT 11, Midrand',
		Code: '1682',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'ALLANDALE EXT 12, Midrand',
		Code: '1682',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'ALLANDALE HEIGHTS, Pietermaritzburg',
		Code: '3201    ',
		Hub: 'PMB',
		Ring: 1,
		OpsHub: 'PMB',
		Dist: 75,
		AreaName: 'Pietermaritzburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'ALLANDALE, Midrand',
		Code: '1685',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'ALLANDALE, Pietermaritzburg',
		Code: '3201    ',
		Hub: 'PMB',
		Ring: 1,
		OpsHub: 'PMB',
		Dist: 75,
		AreaName: 'Pietermaritzburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'ALLANRIDGE',
		Code: '9490    ',
		Hub: 'WEL',
		Ring: 3,
		OpsHub: 'JNB',
		Dist: 170,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'ALLDAYS',
		Code: '0909    ',
		Hub: 'PTG',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 300,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'ALLEN GROVE, Kempton Park',
		Code: '1619    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: "ALLEN'S NEK, Roodepoort",
		Code: '1737    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'ALLENBY Est/Lgd, Retreat',
		Code: '7945    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'ALLENDALE, Retreat',
		Code: '7945    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'ALMA',
		Code: '0512    ',
		Hub: 'PRY',
		Ring: 4,
		OpsHub: 'PRY',
		Dist: 180,
		AreaName: 'Pretoria Regional',
		Surch: null,
	},
	{
		PlaceName: 'ALOEVALE, Queenstown',
		Code: '5320    ',
		Hub: 'ELS',
		Ring: 4,
		OpsHub: 'ELS',
		Dist: 210,
		AreaName: 'East London Regional',
		Surch: null,
	},
	{
		PlaceName: 'ALPHEN Est/Lgd, Constantia',
		Code: '7800    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'ALPHEN PARK, Benoni',
		Code: '1501    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'ALPHEN RISE, Durbanville',
		Code: '7550',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'ALPHENPARK, Pretoria',
		Code: '0081    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'ALPHINE PARK, Mitchells Plain',
		Code: '7785    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'ALRAPARK Boxes',
		Code: '1495    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'ALRAPARK, Nigel',
		Code: '1490    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 45,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'ALRODE',
		Code: '1449    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'ALRODE Boxes',
		Code: '1451    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'ALRODE, Alberton',
		Code: '1449    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'ALTON, Richards Bay',
		Code: '3900',
		Hub: 'RCB',
		Ring: 1,
		OpsHub: 'RCB',
		Dist: 200,
		AreaName: 'Richards Bay',
		Surch: null,
	},
	{
		PlaceName: 'ALTONA, Cape Farms',
		Code: '7300',
		Hub: 'CPT',
		Ring: 1,
		OpsHub: 'CPT',
		Dist: 50,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'ALUNSA',
		Code: '1785    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 50,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'ALVERSTONE, Pinetown',
		Code: '3610',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'AMAJUBA PARK, Newcastle',
		Code: '2940    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 320,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'AMAJUBA, Newcastle',
		Code: '2955    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 330,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'AMALGAM, Johannesburg',
		Code: '2092    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'AMALIA',
		Code: '2786    ',
		Hub: 'VRY',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 440,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'AMALINDA Boxes',
		Code: '5252    ',
		Hub: 'ELS',
		Ring: 0,
		OpsHub: 'ELS',
		Dist: 10,
		AreaName: 'East London Central',
		Surch: null,
	},
	{
		PlaceName: 'AMALINDA, East London',
		Code: '5247    ',
		Hub: 'ELS',
		Ring: 0,
		OpsHub: 'ELS',
		Dist: 10,
		AreaName: 'East London Central',
		Surch: null,
	},
	{
		PlaceName: 'AMANDAGLEN, Durbanville',
		Code: '7550    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'AMANDELBULT, Chromite',
		Code: '0362    ',
		Hub: 'JNB',
		Ring: 2,
		OpsHub: 'JNB',
		Dist: 120,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'AMANDELKROON, Kuilsrivier',
		Code: '7580    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'AMANDELRUG, Kuilsriver',
		Code: '7580    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'AMANDELSIG, Kuilsriver',
		Code: '7580    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'AMANZIMTOTI, KwaZuluNatal',
		Code: '4126',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'AMANZIMYAMA',
		Code: '4399',
		Hub: 'DUR',
		Ring: 1,
		OpsHub: 'DUR',
		Dist: 70,
		AreaName: 'Durban Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'AMATIKULU',
		Code: '3801    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 170,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'AMSTELHOF, Paarl',
		Code: '7646    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 60,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'AMSTERDAM, Randburg',
		Code: '2194    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'AMSTERDAMHOEK, Port Elizabeth',
		Code: '6210    ',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'ANDERBOLT, Boksburg',
		Code: '1459    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'ANERLEY',
		Code: '4230    ',
		Hub: 'DUR',
		Ring: 2,
		OpsHub: 'DUR',
		Dist: 120,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'ANNADALE, Pietersburg',
		Code: '0699',
		Hub: 'PTG',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 400,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'ANNATY BANK, Tokai',
		Code: '7945    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'ANNEX PARK, Germiston',
		Code: '1401    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'ANNLIN & Ext/Uit 1, Pretoria',
		Code: '0182    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'ANSFRERE Boxes',
		Code: '1711    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'ANSFRERE, Florida',
		Code: '1709    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'ANZAC, Brakpan',
		Code: '1540    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'APEL',
		Code: '0739    ',
		Hub: 'MPG',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 300,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'APEX INDUSTRIAL, Brakpan',
		Code: '1540    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'APPLE ORCHARDS SlHs/Khw,',
		Code: '1876    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 30,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'APPLE PARK, Krugersdorp',
		Code: '1739    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'AQUADENE, Richards Bay',
		Code: '3900',
		Hub: 'RCB',
		Ring: 1,
		OpsHub: 'RCB',
		Dist: 200,
		AreaName: 'Richards Bay',
		Surch: null,
	},
	{
		PlaceName: 'AQUAPARK, Tzaneen',
		Code: '0850',
		Hub: 'PTG',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 350,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'ARABIEDAM',
		Code: '0464    ',
		Hub: 'PRY',
		Ring: 4,
		OpsHub: 'PRY',
		Dist: 180,
		AreaName: 'Pretoria Regional',
		Surch: null,
	},
	{
		PlaceName: 'ARAUNA, Brackenfell',
		Code: '7560    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'ARBEX, Richards Bay',
		Code: '3904    ',
		Hub: 'DUR',
		Ring: 1,
		OpsHub: 'RCB',
		Dist: 200,
		AreaName: 'Richards Bay',
		Surch: null,
	},
	{
		PlaceName: 'ARBOR PARK, Newcastle',
		Code: '2954    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 330,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'ARBORETUM Boxes',
		Code: '9305    ',
		Hub: 'BFN',
		Ring: 0,
		OpsHub: 'BFN',
		Dist: 30,
		AreaName: 'Bloemfontein Central',
		Surch: null,
	},
	{
		PlaceName: 'ARBORETUM, Bloemfontein',
		Code: '9301',
		Hub: 'BFN',
		Ring: 0,
		OpsHub: 'BFN',
		Dist: 30,
		AreaName: 'Bloemfontein Central',
		Surch: null,
	},
	{
		PlaceName: 'ARBORETUM,Richards Bay',
		Code: '3900',
		Hub: 'RCB',
		Ring: 1,
		OpsHub: 'RCB',
		Dist: 200,
		AreaName: 'Richards Bay',
		Surch: null,
	},
	{
		PlaceName: 'ARBORPARK, Tzaneen',
		Code: '0850',
		Hub: 'PTG',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 350,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'ARCADIA, East London',
		Code: '5201    ',
		Hub: 'ELS',
		Ring: 0,
		OpsHub: 'ELS',
		Dist: 10,
		AreaName: 'East London Central',
		Surch: null,
	},
	{
		PlaceName: 'ARCADIA, Humansdorp',
		Code: '6300    ',
		Hub: 'PLZ',
		Ring: 2,
		OpsHub: 'PLZ',
		Dist: 100,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'ARCADIA, Port Elizabeth',
		Code: '6059    ',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'ARCADIA, Pretoria',
		Code: '0083    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'ARCON PARK Boxes',
		Code: '1937    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 60,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'ARCON PARK, Vereeniging',
		Code: '1939    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 60,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'ARDLEIGH PARK, Retreat',
		Code: '7945    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'ARENA PARK, Chatsworth',
		Code: '4092',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'ARGYLE, Johannesburg',
		Code: '2001    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'ARMADALE, Johannesburg',
		Code: '2013    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'ARNISTON',
		Code: '7280',
		Hub: 'CPT',
		Ring: 1,
		OpsHub: 'CPT',
		Dist: 210,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'ARNOLDTON, East London',
		Code: '5247    ',
		Hub: 'ELS',
		Ring: 0,
		OpsHub: 'ELS',
		Dist: 10,
		AreaName: 'East London Central',
		Surch: 'Township',
	},
	{
		PlaceName: "ARTHUR'S PLACE, Johannesburg",
		Code: '2091    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'ASHBURNHAM, Kimberley',
		Code: '8301    ',
		Hub: 'KIM',
		Ring: 0,
		OpsHub: 'KIM',
		Dist: 20,
		AreaName: 'Kimberley Central',
		Surch: null,
	},
	{
		PlaceName: 'ASHBURTON, Pietermaritzburg',
		Code: '3201    ',
		Hub: 'PMB',
		Ring: 1,
		OpsHub: 'PMB',
		Dist: 75,
		AreaName: 'Pietermaritzburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'ASHDOWN, Plessislaer',
		Code: '4500    ',
		Hub: 'DUR',
		Ring: 2,
		OpsHub: 'DUR',
		Dist: 100,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'ASHERVILLE, Durban',
		Code: '4091',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'ASHERVILLE, Graaff-Reinet',
		Code: '6280    ',
		Hub: 'PLZ',
		Ring: 4,
		OpsHub: 'PLZ',
		Dist: 290,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'ASHLEA GARDENS, Pretoria',
		Code: '0081    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'ASHLEY, Pinetown',
		Code: '3610',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'ASHTON',
		Code: '6715    ',
		Hub: 'CPT',
		Ring: 1,
		OpsHub: 'CPT',
		Dist: 200,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'ASHWOOD Boxes',
		Code: '3605    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'ASHWOOD, Pinetown',
		Code: '3610',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'ASIATIC BAZAAR, Germiston',
		Code: '1401    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'ASKRAAL',
		Code: '6762    ',
		Hub: 'GRJ',
		Ring: 4,
		OpsHub: 'GRJ',
		Dist: 160,
		AreaName: 'George Regional',
		Surch: null,
	},
	{
		PlaceName: 'ASPEN HEIGHTS, Port Elizabeth',
		Code: '6059    ',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'ASPEN HILLS, Johannesburg',
		Code: '2059',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'ASSAGAY, Hillcrest',
		Code: '3650    ',
		Hub: 'DUR',
		Ring: 1,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'ASSEGAI, Durban',
		Code: '3610',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'ASTON BAY, Jeffreys Bay',
		Code: '6330    ',
		Hub: 'PLZ',
		Ring: 2,
		OpsHub: 'PLZ',
		Dist: 90,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'ASTON MANOR Boxes',
		Code: '1630    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'ASTON MANOR, Kempton Park',
		Code: '1619    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'ATAMELANG, Mafikeng',
		Code: '2732    ',
		Hub: 'PTG',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 360,
		AreaName: 'Johannesburg Regional',
		Surch: 'Far Outlying',
	},
	{
		PlaceName: 'ATHLONE Cape Town (Boxes)',
		Code: '7760    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'ATHLONE INDUSTRIA, Athlone',
		Code: '7764    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'ATHLONE PARK, Amanzimtoti',
		Code: '4126',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'ATHLONE, Cape Town',
		Code: '7764    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'ATHLONE, Durban',
		Code: '4051',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'ATHLONE, Pietermaritzburg',
		Code: '3201    ',
		Hub: 'PMB',
		Ring: 1,
		OpsHub: 'PMB',
		Dist: 75,
		AreaName: 'Pietermaritzburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'ATHOLHURST, Johannesburg',
		Code: '2196    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'ATHOLHURSTON, Johannesburg',
		Code: '2196    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'ATHOLL Ext 12, Strath',
		Code: '2031    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'ATHOLL GARDENS, Johannesburg',
		Code: '2196    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'ATHOLL HEIGHTS, Westville',
		Code: '3629',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'ATHOLL, Johannesburg',
		Code: '2196    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'ATLANTIS',
		Code: '7349    ',
		Hub: 'CPT',
		Ring: 1,
		OpsHub: 'CPT',
		Dist: 50,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'ATLASVILLE & Ext 1',
		Code: '1459    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'ATLASVILLE Boxes',
		Code: '1465    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'ATOK',
		Code: '0749    ',
		Hub: 'MPG',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 300,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'ATOOM',
		Code: '0514    ',
		Hub: 'PRY',
		Ring: 4,
		OpsHub: 'PRY',
		Dist: 180,
		AreaName: 'Pretoria Regional',
		Surch: null,
	},
	{
		PlaceName: 'ATTERIDGEVILLE, Pretoria',
		Code: '0008    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'AUCKLAND PARK Boxes',
		Code: '2006    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'AUCKLAND PARK, Johannesburg',
		Code: '2092    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'AUDAS Est/Lgd, Somerset West',
		Code: '7130    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 30,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'AURORA',
		Code: '7325    ',
		Hub: 'CPT',
		Ring: 3,
		OpsHub: 'CPT',
		Dist: 130,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'AURORA Est/Lgd, Durbanville',
		Code: '7550    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'AURORA, Durbanville',
		Code: '7550',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'AUSTERVILLE Boxes',
		Code: '4005    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'AUSTERVILLE, Durban',
		Code: '4052    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'AUSTINVILLE, Blackheath',
		Code: '7581    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'AVALANO Tsp/Dgd, Krugersdorp',
		Code: '1739    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'AVALON ESTATE, Durbanville',
		Code: '7550',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'AVENUE HEIGHTS, Durbanville',
		Code: '7550',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'AVOCA HILLS, Durban North',
		Code: '4051',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'AVOCA, Durban North',
		Code: '4051',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'AVONDALE Est/Lgd, Parow',
		Code: '7500    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'AVONDALE ROAD, Durban',
		Code: '4001',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'AVONDALE, Atlantis',
		Code: '7349    ',
		Hub: 'CPT',
		Ring: 1,
		OpsHub: 'CPT',
		Dist: 50,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'AVONDSTEL, Bellville',
		Code: '7530    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'AVONTUUR',
		Code: '6490    ',
		Hub: 'GRJ',
		Ring: 1,
		OpsHub: 'GRJ',
		Dist: 70,
		AreaName: 'George Regional',
		Surch: null,
	},
	{
		PlaceName: 'AVONWOOD, Elsiesriver',
		Code: '7490    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'AZAADVILLE',
		Code: '1750    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'AZIATIC BAZAAR, Pretoria',
		Code: '0183    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'BAARDSKEERDERSBOS',
		Code: '7271    ',
		Hub: 'CPT',
		Ring: 3,
		OpsHub: 'CPT',
		Dist: 130,
		AreaName: 'Cape Town Regional',
		Surch: 'Far Outlying',
	},
	{
		PlaceName: 'BABANANGO',
		Code: '3850    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 250,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'BABELEGI',
		Code: '0407',
		Hub: 'PRY',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 100,
		AreaName: 'Johannesburg Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'BABIRWA',
		Code: '0716    ',
		Hub: 'JNB',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 300,
		AreaName: 'Johannesburg Regional',
		Surch: 'Far Outlying',
	},
	{
		PlaceName: 'BADPLAAS',
		Code: '1190    ',
		Hub: 'NLP',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 250,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'BAGLEYSTON, Johannesburg',
		Code: '2192    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BAILEYS MUCKLENEUK, Pretoria',
		Code: '0181    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'BAILLIE PARK Boxes',
		Code: '2526    ',
		Hub: 'JNB',
		Ring: 2,
		OpsHub: 'JNB',
		Dist: 100,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'BAILLIE PARK, Potchefstroom',
		Code: '2520    ',
		Hub: 'POT',
		Ring: 3,
		OpsHub: 'JNB',
		Dist: 150,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: "BAIN'S VLEI",
		Code: '9338    ',
		Hub: 'BFN',
		Ring: 0,
		OpsHub: 'BFN',
		Dist: 30,
		AreaName: 'Bloemfontein Central',
		Surch: null,
	},
	{
		PlaceName: 'BAKENBERG',
		Code: '0611    ',
		Hub: 'PTG',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 300,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'BAKERTON, Payneville',
		Code: '1565    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BAKERVILLE GARDENS, Durban',
		Code: '4037',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'BAKERVILLE, Durban',
		Code: '4037',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'BAKERVILLE, Klerksdorp',
		Code: '2742    ',
		Hub: 'JNB',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 290,
		AreaName: 'Johannesburg Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'BAKKERSHOOGTE, Somerset West',
		Code: '7130    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 30,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'BAKKERSVILLE, Plumstead',
		Code: '7800    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'BAKONE',
		Code: '0746    ',
		Hub: 'PTG',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 300,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'BAKOVEN, Cape Town',
		Code: '8005',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'BALFOUR',
		Code: '2410    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 90,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'BALGOWAN',
		Code: '3275    ',
		Hub: 'HSM',
		Ring: 3,
		OpsHub: 'JNB',
		Dist: 150,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'BALLENGEICH',
		Code: '2942    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 330,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'BALLITO, KwaZuluNatal',
		Code: '4399',
		Hub: 'DUR',
		Ring: 1,
		OpsHub: 'DUR',
		Dist: 70,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'BALLOT VIEW, George',
		Code: '6529    ',
		Hub: 'GRJ',
		Ring: 0,
		OpsHub: 'GRJ',
		Dist: 10,
		AreaName: 'George Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'BALMORAL (Tvl)',
		Code: '1037    ',
		Hub: 'MBG',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 140,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'BALMORAL Est/Lgd, Lansdowne',
		Code: '7780    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'BALMORAL, Queenstown',
		Code: '5320    ',
		Hub: 'ELS',
		Ring: 4,
		OpsHub: 'ELS',
		Dist: 210,
		AreaName: 'East London Regional',
		Surch: null,
	},
	{
		PlaceName: 'BALTIMORE',
		Code: '0619    ',
		Hub: 'PRY',
		Ring: 4,
		OpsHub: 'PRY',
		Dist: 300,
		AreaName: 'Pretoria Regional',
		Surch: null,
	},
	{
		PlaceName: 'BALVINIE, Elsiesriver',
		Code: '7490    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'BANDELIERKOP',
		Code: '0800    ',
		Hub: 'PTG',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 300,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'BANHOEK, Stellenbosch',
		Code: '7600    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 60,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'BANTRY BAY, Cape Town',
		Code: '8005',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'BAPSFONTEIN, Petit',
		Code: '1512    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'BARAGWANATH, Johannesburg',
		Code: '2013    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BARANDAS',
		Code: '6480    ',
		Hub: 'GRJ',
		Ring: 2,
		OpsHub: 'GRJ',
		Dist: 90,
		AreaName: 'George Regional',
		Surch: null,
	},
	{
		PlaceName: 'BARBAROSA Est/Lgd, Constantia',
		Code: '7800    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'BARBEQUE DOWNS, Midrand',
		Code: '1684',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BARBERSPAN',
		Code: '2765    ',
		Hub: 'VRY',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 340,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'BARBERTON',
		Code: '1300    ',
		Hub: 'NLP',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 350,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'BARDENE, Boksburg',
		Code: '1459    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BARKAROSA, Plumstead',
		Code: '7800    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'BARKLY EAST',
		Code: '5580    ',
		Hub: 'ELS',
		Ring: 4,
		OpsHub: 'ELS',
		Dist: 350,
		AreaName: 'East London Regional',
		Surch: null,
	},
	{
		PlaceName: 'BARKLY WEST',
		Code: '8375    ',
		Hub: 'KIM',
		Ring: 4,
		OpsHub: 'KIM',
		Dist: 230,
		AreaName: 'Kimberley Regional',
		Surch: null,
	},
	{
		PlaceName: 'BARKLY-OOS',
		Code: '5580    ',
		Hub: 'ELS',
		Ring: 4,
		OpsHub: 'ELS',
		Dist: 350,
		AreaName: 'East London Regional',
		Surch: null,
	},
	{
		PlaceName: 'BARKLY-WES',
		Code: '8375    ',
		Hub: 'KIM',
		Ring: 4,
		OpsHub: 'KIM',
		Dist: 230,
		AreaName: 'Kimberley Regional',
		Surch: null,
	},
	{
		PlaceName: 'BARLOW PARK, Wendywood',
		Code: '2148    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BARODA',
		Code: '5882    ',
		Hub: 'PLZ',
		Ring: 4,
		OpsHub: 'PLZ',
		Dist: 290,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'BARRY HERTZOG PARK, Newcastle',
		Code: '2940    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 320,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'BARRYDALE',
		Code: '6750    ',
		Hub: 'CPT',
		Ring: 4,
		OpsHub: 'CPT',
		Dist: 270,
		AreaName: 'Cape Town Regional',
		Surch: 'Far Outlying',
	},
	{
		PlaceName: 'BARTLETTS, Boksburg',
		Code: '1459    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BARVALE',
		Code: '1202    ',
		Hub: 'NLP',
		Ring: 0,
		OpsHub: 'NLP',
		Dist: 310,
		AreaName: 'Nelspruit Central',
		Surch: null,
	},
	{
		PlaceName: 'BARVALLEN, Primrose',
		Code: '1401    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BASOTHONG SECTION, Tokoza',
		Code: '1426',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 60,
		AreaName: 'Johannesburg Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'BASSONIA, Glenvista',
		Code: '2061    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BATHO, Bloemfontein',
		Code: '9323    ',
		Hub: 'BFN',
		Ring: 0,
		OpsHub: 'BFN',
		Dist: 30,
		AreaName: 'Bloemfontein Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'BATHURST',
		Code: '6166    ',
		Hub: 'PLZ',
		Ring: 4,
		OpsHub: 'PLZ',
		Dist: 190,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'BAY VIEW, Hartenbos',
		Code: '6520    ',
		Hub: 'GRJ',
		Ring: 1,
		OpsHub: 'GRJ',
		Dist: 50,
		AreaName: 'George Regional',
		Surch: null,
	},
	{
		PlaceName: 'BAY VIEW, Mossel Bay',
		Code: '6506',
		Hub: 'GRJ',
		Ring: 1,
		OpsHub: 'GRJ',
		Dist: 60,
		AreaName: 'George Regional',
		Surch: null,
	},
	{
		PlaceName: 'BAY VIEW, MosselBay',
		Code: '6506',
		Hub: 'GRJ',
		Ring: 1,
		OpsHub: 'GRJ',
		Dist: 60,
		AreaName: 'George Regional',
		Surch: null,
	},
	{
		PlaceName: 'BAYALA',
		Code: '3966    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 300,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'BAYNESFIELD',
		Code: '3770    ',
		Hub: 'DUR',
		Ring: 1,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'BAYRIDGE, Tableview',
		Code: '7441    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'BAYSVILLE, East London',
		Code: '5241    ',
		Hub: 'ELS',
		Ring: 0,
		OpsHub: 'ELS',
		Dist: 10,
		AreaName: 'East London Central',
		Surch: null,
	},
	{
		PlaceName: 'BAYSWATER, Bloemfontein',
		Code: '9301',
		Hub: 'BFN',
		Ring: 0,
		OpsHub: 'BFN',
		Dist: 30,
		AreaName: 'Bloemfontein Central',
		Surch: null,
	},
	{
		PlaceName: 'BAYVIEW, Chatsworth',
		Code: '4092',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'BAZLEY BEACH',
		Code: '4220',
		Hub: 'DUR',
		Ring: 1,
		OpsHub: 'DUR',
		Dist: 85,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'BEACH Est/Lgd, Houtbay',
		Code: '7800    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 40,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'BEACH, Durban',
		Code: '4001',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'BEACH, East London',
		Code: '5201    ',
		Hub: 'ELS',
		Ring: 0,
		OpsHub: 'ELS',
		Dist: 10,
		AreaName: 'East London Central',
		Surch: null,
	},
	{
		PlaceName: 'BEACHVIEW, Port Elizabeth',
		Code: '6070',
		Hub: 'PLZ',
		Ring: 1,
		OpsHub: 'PLZ',
		Dist: 50,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'BEACHWOOD, Durban North',
		Code: '4051',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'BEACON BAY  East London',
		Code: '5241    ',
		Hub: 'ELS',
		Ring: 0,
		OpsHub: 'ELS',
		Dist: 10,
		AreaName: 'East London Central',
		Surch: null,
	},
	{
		PlaceName: 'BEACON BAY Boxes',
		Code: '5205    ',
		Hub: 'ELS',
		Ring: 0,
		OpsHub: 'ELS',
		Dist: 10,
		AreaName: 'East London Central',
		Surch: null,
	},
	{
		PlaceName: 'BEACON BAY VALLEY, East London',
		Code: '5241    ',
		Hub: 'ELS',
		Ring: 0,
		OpsHub: 'ELS',
		Dist: 10,
		AreaName: 'East London Central',
		Surch: null,
	},
	{
		PlaceName: 'BEACON HILL, Atlantis',
		Code: '7349',
		Hub: 'CPT',
		Ring: 1,
		OpsHub: 'CPT',
		Dist: 50,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'BEACON VALLEY, Mitchells Plain',
		Code: '7785    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'BEACONBAAI Busse',
		Code: '5205    ',
		Hub: 'ELS',
		Ring: 0,
		OpsHub: 'ELS',
		Dist: 10,
		AreaName: 'East London Central',
		Surch: null,
	},
	{
		PlaceName: 'BEACONBAAI, East-London',
		Code: '5241    ',
		Hub: 'ELS',
		Ring: 0,
		OpsHub: 'ELS',
		Dist: 10,
		AreaName: 'East London Central',
		Surch: null,
	},
	{
		PlaceName: 'BEACONBAAIVALLEI, East-London',
		Code: '5241    ',
		Hub: 'ELS',
		Ring: 0,
		OpsHub: 'ELS',
		Dist: 10,
		AreaName: 'East London Central',
		Surch: null,
	},
	{
		PlaceName: 'BEACONSFIELD Boxes',
		Code: '8315    ',
		Hub: 'KIM',
		Ring: 0,
		OpsHub: 'KIM',
		Dist: 20,
		AreaName: 'Kimberley Central',
		Surch: null,
	},
	{
		PlaceName: 'BEACONSFIELD, Kimberley',
		Code: '8301    ',
		Hub: 'KIM',
		Ring: 0,
		OpsHub: 'KIM',
		Dist: 20,
		AreaName: 'Kimberley Central',
		Surch: null,
	},
	{
		PlaceName: 'BEACONSFIELD, Vereeniging',
		Code: '1939    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 60,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BEACONVALE Est/Lgd, Parow',
		Code: '7500    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'BEAUFORT WEST',
		Code: '6970    ',
		Hub: 'GRJ',
		Ring: 1,
		OpsHub: 'GRJ',
		Dist: 470,
		AreaName: 'George Regional',
		Surch: null,
	},
	{
		PlaceName: 'BEAUFORT-WEST',
		Code: '6970    ',
		Hub: 'GRJ',
		Ring: 4,
		OpsHub: 'GRJ',
		Dist: 470,
		AreaName: 'George Regional',
		Surch: null,
	},
	{
		PlaceName: 'BEAUMONT, Parys',
		Code: '9585    ',
		Hub: 'JNB',
		Ring: 3,
		OpsHub: 'JNB',
		Dist: 130,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'BEAUTY',
		Code: '0538    ',
		Hub: 'PRY',
		Ring: 4,
		OpsHub: 'PRY',
		Dist: 180,
		AreaName: 'Pretoria Regional',
		Surch: null,
	},
	{
		PlaceName: 'BEDELIA, Welkom',
		Code: '9459    ',
		Hub: 'WEL',
		Ring: 3,
		OpsHub: 'JNB',
		Dist: 150,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BEDFORD GARDENS, Bedfordview',
		Code: '2008    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BEDFORDPARK, Bedfordview',
		Code: '2008    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BEDFORDVIEW, Johannesburg',
		Code: '2008    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BEDWORTH PARK, Vereeniging',
		Code: '1940    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 60,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'BEESTEKRAAL',
		Code: '0255    ',
		Hub: 'RBG',
		Ring: 2,
		OpsHub: 'JNB',
		Dist: 100,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'BEKKERSDAL, Westonaria',
		Code: '1780    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 50,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'BELA-BELA,Warmbad',
		Code: '0480    ',
		Hub: 'PRY',
		Ring: 2,
		OpsHub: 'PRY',
		Dist: 180,
		AreaName: 'Pretoria Regional',
		Surch: null,
	},
	{
		PlaceName: 'BELABELA, Warmbad (TT)',
		Code: '0480',
		Hub: 'PRY',
		Ring: 1,
		OpsHub: 'PRY',
		Dist: 180,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'BELFAST',
		Code: '1100    ',
		Hub: 'MBG',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 220,
		AreaName: 'Johannesburg Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'BELFAST (Tvl)',
		Code: '1100    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 220,
		AreaName: 'Johannesburg Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'BELFORT Est/Lgd, Pietermaritzburg',
		Code: '3201    ',
		Hub: 'PMB',
		Ring: 1,
		OpsHub: 'PMB',
		Dist: 75,
		AreaName: 'Pietermaritzburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'BELGRAVIA Est/Lgd, Athlone',
		Code: '7764    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'BELGRAVIA, Bellville',
		Code: '7530    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'BELGRAVIA, East London',
		Code: '5201    ',
		Hub: 'ELS',
		Ring: 0,
		OpsHub: 'ELS',
		Dist: 10,
		AreaName: 'East London Central',
		Surch: null,
	},
	{
		PlaceName: 'BELGRAVIA, Johannesburg',
		Code: '2094    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BELGRAVIA, Kimberley',
		Code: '8301    ',
		Hub: 'KIM',
		Ring: 0,
		OpsHub: 'KIM',
		Dist: 20,
		AreaName: 'Kimberley Central',
		Surch: null,
	},
	{
		PlaceName: 'BELHAR Boxes',
		Code: '7507    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'BELHAR, Matroosfontein',
		Code: '7490    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'BELHAR,Cape Town',
		Code: '7493',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'BELLA VISTA Boxes',
		Code: '6837    ',
		Hub: 'CPT',
		Ring: 2,
		OpsHub: 'CPT',
		Dist: 120,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'BELLA VISTA, Ceres',
		Code: '6835    ',
		Hub: 'CPT',
		Ring: 2,
		OpsHub: 'CPT',
		Dist: 120,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'BELLAIR Boxes',
		Code: '4006    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'BELLAIR Est/Lgd, Bellville',
		Code: '7530    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'BELLAIR, Durban',
		Code: '4094    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'BELLAVISTA Est/Lgd, Johannesburg',
		Code: '2091    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BELLAVISTA SOUTH, Johannesburg',
		Code: '2091    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BELLAVISTA-SUID, Johannesburg',
		Code: '2091    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BELLE OMBRE',
		Code: '0142    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'BELLE OMBRE, Constantia',
		Code: '7800    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'BELLEVUE CENTRAL, Johannesburg',
		Code: '2198    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BELLEVUE EAST, Johannesburg',
		Code: '2198    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BELLEVUE SENTRAAL, Johannesburg',
		Code: '2198    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BELLEVUE, Johannesburg',
		Code: '2198    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BELLEVUE, Pietermaritzburg',
		Code: '3201    ',
		Hub: 'PMB',
		Ring: 1,
		OpsHub: 'PMB',
		Dist: 75,
		AreaName: 'Pietermaritzburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'BELLEVUE-OOS, Johannesburg',
		Code: '2198    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BELLGATE, Tongaat',
		Code: '4399',
		Hub: 'DUR',
		Ring: 1,
		OpsHub: 'DUR',
		Dist: 70,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'BELLRAIL, Bellville',
		Code: '7530    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'BELLVIEW, Wellington',
		Code: '7655    ',
		Hub: 'CPT',
		Ring: 2,
		OpsHub: 'CPT',
		Dist: 90,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'BELLVILLE',
		Code: '7530    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'BELLVILLE  Streets',
		Code: '7530    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'BELLVILLE Boxes',
		Code: '7535    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'BELLVILLE SOUTH, Bellville',
		Code: '7530    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'BELLVILLE-SUID, Bellville',
		Code: '7530    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'BELMONT',
		Code: '8720    ',
		Hub: 'BFN',
		Ring: 4,
		OpsHub: 'BFN',
		Dist: 230,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'BELMONT PARK, Kraaifontein',
		Code: '7570    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'BELTHORNE Est/Lgd, Lansdowne',
		Code: '7780    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'BELVEDERE ZONE 2',
		Code: '4399',
		Hub: 'DUR',
		Ring: 1,
		OpsHub: 'DUR',
		Dist: 70,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'BELVEDERE ZONE 4',
		Code: '4399',
		Hub: 'DUR',
		Ring: 1,
		OpsHub: 'DUR',
		Dist: 70,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'BELVEDERE, Hillcrest',
		Code: '3650',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'BELVEDERE, Tongaat',
		Code: '4399',
		Hub: 'DUR',
		Ring: 1,
		OpsHub: 'DUR',
		Dist: 70,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'BELVINIE Est/Lgd, Matroos',
		Code: '7490    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'BEN KAMMA, Port Elizabeth',
		Code: '6025',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'BEN SCHOEMAN DOCKS, Cape Town',
		Code: '8001    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'BEN SCHOEMAN DOKKE, Kaapstad',
		Code: '8001    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'BENDOR PARK & Ext/Uit 1,',
		Code: '0699',
		Hub: 'PTG',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 400,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BENMORE',
		Code: '2010    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BENMORE GARDENS, Johannesburg',
		Code: '2196    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BENNO PARK, Kuilsriver',
		Code: '7580    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'BENONI Boxes',
		Code: '1506',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BENONI Ext/Uit 4, Benoni',
		Code: '1501    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BENONI NORTH, Benoni',
		Code: '1501    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BENONI SOUTH Boxes',
		Code: '1502    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BENONI SOUTH, Benoni',
		Code: '1501    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BENONI WEST Boxes',
		Code: '1503    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BENONI WEST, Benoni',
		Code: '1501    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BENONI, Johannesburg',
		Code: '1501    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BENONI-NOORD, Benoni',
		Code: '1501    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BENONI-SUID Busse',
		Code: '1502    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BENONI-SUID, Benoni',
		Code: '1501    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BENONI-WES Busse',
		Code: '1503    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BENONI-WES, Benoni',
		Code: '1501    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BENORYN Boxes',
		Code: '1504    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BENORYN, Benoni',
		Code: '1501    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BENROSE',
		Code: '2094    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BENROSE Boxes',
		Code: '2011    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BERARIO, Johannesburg',
		Code: '2195    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BEREA PARK, Pretoria',
		Code: '0002    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'BEREA ROAD, Durban',
		Code: '4007    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'BEREA ROAD, Durban',
		Code: '4001',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'BEREA WEST, Westville',
		Code: '3629',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'BEREA, Durban',
		Code: '4001    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'BEREA, East London',
		Code: '5241    ',
		Hub: 'ELS',
		Ring: 0,
		OpsHub: 'ELS',
		Dist: 10,
		AreaName: 'East London Central',
		Surch: null,
	},
	{
		PlaceName: 'BEREA, Johannesburg',
		Code: '2198    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BEREA-WES, Westville',
		Code: '329',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'BEREAVILLE',
		Code: '7232    ',
		Hub: 'CPT',
		Ring: 2,
		OpsHub: 'CPT',
		Dist: 110,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'BEREAWEG, Durban',
		Code: '4007    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'BERGBRON & Ext/Uit 1 - 3',
		Code: '1709    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BERGSIG, Caledon',
		Code: '7230    ',
		Hub: 'CPT',
		Ring: 2,
		OpsHub: 'CPT',
		Dist: 100,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'BERGSIG, Durbanville',
		Code: '7550    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'BERGSIG, George',
		Code: '6529    ',
		Hub: 'GRJ',
		Ring: 0,
		OpsHub: 'GRJ',
		Dist: 10,
		AreaName: 'George Central',
		Surch: null,
	},
	{
		PlaceName: 'BERGSIG, Harrismith',
		Code: '9880    ',
		Hub: 'HSM',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 300,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'BERGSIG, Heidelberg (Tvl)',
		Code: '2400    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 70,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'BERGSIG, Kirkwood',
		Code: '6120    ',
		Hub: 'PLZ',
		Ring: 2,
		OpsHub: 'PLZ',
		Dist: 90,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'BERGSIG, Montagu',
		Code: '6720    ',
		Hub: 'CPT',
		Ring: 4,
		OpsHub: 'CPT',
		Dist: 200,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'BERGSIG, Queenstown',
		Code: '5320    ',
		Hub: 'ELS',
		Ring: 4,
		OpsHub: 'ELS',
		Dist: 210,
		AreaName: 'East London Regional',
		Surch: null,
	},
	{
		PlaceName: 'BERGSIG, Worcester',
		Code: '6850    ',
		Hub: 'CPT',
		Ring: 2,
		OpsHub: 'CPT',
		Dist: 120,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'BERGVILLE',
		Code: '3350    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 250,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'BERGVLEI Boxes',
		Code: '2012    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BERGVLIET Boxes',
		Code: '7864    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'BERGVLIET, Cape Town',
		Code: '7945    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'BERGZICHT, Muizenberg',
		Code: '7945    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'BERKSHIRE DOWNS, Pinetown',
		Code: '3610',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'BERLIN ',
		Code: '5660    ',
		Hub: 'ELS',
		Ring: 1,
		OpsHub: 'ELS',
		Dist: 50,
		AreaName: 'East London Regional',
		Surch: null,
	},
	{
		PlaceName: 'BERNADINO HEIGHTS, Kraaifontein',
		Code: '7570    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'BEROMA, Bellville',
		Code: '7530    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'BERTON PARK, Boksburg',
		Code: '1459    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BERTRAMS, Johannesburg',
		Code: '2094    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BERTSHAM',
		Code: '2013    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BESTERS',
		Code: '3371    ',
		Hub: 'HSM',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 260,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'BETHAL NORTH Boxes',
		Code: '2311    ',
		Hub: 'JNB',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 190,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'BETHAL-NOORD Busse',
		Code: '2311    ',
		Hub: 'JNB',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 190,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'BETHANIE, Durbanville',
		Code: '7550',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'BETHANIE, North West',
		Code: '0260',
		Hub: 'RBG',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 120,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'BETHELSDORP, Port Elizabeth',
		Code: '6059    ',
		Hub: 'PLZ',
		Ring: 1,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'BETHLEHEM Streets',
		Code: '9700',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 230,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'BETHULIE',
		Code: '9992    ',
		Hub: 'BFN',
		Ring: 4,
		OpsHub: 'BFN',
		Dist: 210,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: "BETTY'S BAY",
		Code: '7141    ',
		Hub: 'CPT',
		Ring: 1,
		OpsHub: 'CPT',
		Dist: 50,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'BETTYSBAAI',
		Code: '7141    ',
		Hub: 'CPT',
		Ring: 1,
		OpsHub: 'CPT',
		Dist: 50,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'BEVERLEY GARDENS, Randburg',
		Code: '2194    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BEVERLEY GROVE, Port Elizabeth',
		Code: '6070',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'BEVERLEY HILLS, Port Elizabeth',
		Code: '6020    ',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'BEVERLEY HILLS, Westville',
		Code: '3629',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'BEVERLY PARK, Eersterivie',
		Code: '7100    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 15,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'BEYERSPARK & Ext/Uit 3, 6',
		Code: '1459    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BEZUIDENHOUTS VALLEY, Johannesburg',
		Code: '2094    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BEZUIDENHOUTSVALLEI, Johannesburg',
		Code: '2094    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BHEKUZULU, Vryheid',
		Code: '3100    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 330,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'BHONGWENI, Kokstad',
		Code: '4700    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 220,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'BIERSPRUIT, Rustenburg',
		Code: '0300    ',
		Hub: 'JNB',
		Ring: 2,
		OpsHub: 'JNB',
		Dist: 120,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BIESIESFONTEIN',
		Code: '8203    ',
		Hub: 'WEL',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 400,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'BIESIESVLEI',
		Code: '2755    ',
		Hub: 'JNB',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 300,
		AreaName: 'Johannesburg Regional',
		Surch: 'Far Outlying',
	},
	{
		PlaceName: 'BIG BAY, Cape Town',
		Code: '7441    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'BINGLEY',
		Code: '0436    ',
		Hub: 'PRY',
		Ring: 4,
		OpsHub: 'PRY',
		Dist: 180,
		AreaName: 'Pretoria Regional',
		Surch: null,
	},
	{
		PlaceName: 'BIRCH ACRES & Ext 3,',
		Code: '1619    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BIRCHLEIGH & Ext 6, Kempton',
		Code: '1619    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BIRCHLEIGH Boxes',
		Code: '1621    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BIRCHLEIGH NORTH & Ext 1',
		Code: '1619    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BIRCHLEIGH-NOORD & Uit 1',
		Code: '1619    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BIRDHAVEN, Johannesburg',
		Code: '2196    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BIRNAM PARK',
		Code: '2015    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BIRNAM, Johannesburg',
		Code: '2196    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BISHO',
		Code: '5605',
		Hub: 'ELS',
		Ring: 1,
		OpsHub: 'ELS',
		Dist: 50,
		AreaName: 'East London Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'BISHO,Eastern Cape',
		Code: '5605',
		Hub: 'ELS',
		Ring: 1,
		OpsHub: 'ELS',
		Dist: 70,
		AreaName: 'East London Regional',
		Surch: null,
	},
	{
		PlaceName: 'BISHOP LAVIS, Lavistown',
		Code: '7490    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'BISHOPSOURT Est/Lgd, Claremont',
		Code: '7700    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'BISHOPSATE, Durban',
		Code: '4001',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'BISLEY HEIGHTS, Pietermaritzburg',
		Code: '3201    ',
		Hub: 'PMB',
		Ring: 1,
		OpsHub: 'PMB',
		Dist: 75,
		AreaName: 'Pietermaritzburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'BISLEY VALLEY, Pietermaritzburg',
		Code: '3201    ',
		Hub: 'PMB',
		Ring: 1,
		OpsHub: 'PMB',
		Dist: 75,
		AreaName: 'Pietermaritzburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'BITTERFONTEIN',
		Code: '8200    ',
		Hub: 'CPT',
		Ring: 4,
		OpsHub: 'CPT',
		Dist: 400,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'BIZANA',
		Code: '4800',
		Hub: 'ELS',
		Ring: 4,
		OpsHub: 'ELS',
		Dist: 205,
		AreaName: 'East London Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'BLACK RIVER, Rondebosch',
		Code: '7700    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'BLACK ROCK, Mqanduli',
		Code: '5080',
		Hub: 'ELS',
		Ring: 1,
		OpsHub: 'ELS',
		Dist: 180,
		AreaName: 'East London Regional',
		Surch: null,
	},
	{
		PlaceName: 'BLACKBURN, Durban',
		Code: '4319',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'BLACKHEATH',
		Code: '2195    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BLACKHEATH , Johannesburg',
		Code: '2195    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BLACKHEATH, Cape Town',
		Code: '7580',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'BLACKHILL',
		Code: '1032    ',
		Hub: 'MBG',
		Ring: 3,
		OpsHub: 'JNB',
		Dist: 140,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'BLACKHURST, Durban',
		Code: '4001    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'BLACKRIDGE, Pietermaritzburg',
		Code: '3200',
		Hub: 'PMB',
		Ring: 0,
		OpsHub: 'PMB',
		Dist: 75,
		AreaName: 'Pietermaritzburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BLAIR PARK, Plumstead',
		Code: '7800    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'BLAIRGOWRIE, Johannesburg',
		Code: '2194    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BLANCHEVILLE, Witbank',
		Code: '1035    ',
		Hub: 'MBG',
		Ring: 3,
		OpsHub: 'JNB',
		Dist: 140,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'BLANCO, George',
		Code: '6529',
		Hub: 'GRJ',
		Ring: 0,
		OpsHub: 'GRJ',
		Dist: 10,
		AreaName: 'George Central',
		Surch: null,
	},
	{
		PlaceName: 'BLANDFORD RIDGE, Bryanston',
		Code: '2021    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BLANWICK PARK, Springs',
		Code: '1559    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BLIKFONTEIN',
		Code: '8378    ',
		Hub: 'BFN',
		Ring: 4,
		OpsHub: 'BFN',
		Dist: 400,
		AreaName: 'Bloemfontein Regional',
		Surch: 'Far Outlying',
	},
	{
		PlaceName: 'BLOEDRIVIER',
		Code: '3102    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 300,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'BLOEKOMVILLE, Klerksdorp',
		Code: '2571    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 150,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'BLOEMENDAL, Port Elizabeth',
		Code: '6059    ',
		Hub: 'PLZ',
		Ring: 1,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'BLOEMFONTEIN',
		Code: '9301',
		Hub: 'BFN',
		Ring: 0,
		OpsHub: 'BFN',
		Dist: 30,
		AreaName: 'Bloemfontein Central',
		Surch: null,
	},
	{
		PlaceName: 'BLOEMHOF, Bellville',
		Code: '7530    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'BLOEMHOF, North West',
		Code: '2660    ',
		Hub: 'WEL',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 400,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'BLOEMSMOND',
		Code: '8810    ',
		Hub: 'KIM',
		Ring: 3,
		OpsHub: 'BFN',
		Dist: 20,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'BLOEMSPRUIT',
		Code: '9364    ',
		Hub: 'BFN',
		Ring: 0,
		OpsHub: 'BFN',
		Dist: 30,
		AreaName: 'Bloemfontein Central',
		Surch: null,
	},
	{
		PlaceName: 'BLOMANDA, Bloemfontein',
		Code: '9323    ',
		Hub: 'BFN',
		Ring: 0,
		OpsHub: 'BFN',
		Dist: 30,
		AreaName: 'Bloemfontein Central',
		Surch: null,
	},
	{
		PlaceName: 'BLOMMENDAL, Bellville',
		Code: '7530    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'BLOMTUIN, Bellville',
		Code: '7530    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'BLOMVLEI Est/Lgd, Lansdowne',
		Code: '7780    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'BLOMVLEI, Bellville',
		Code: '7530    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'BLOOD RIVER',
		Code: '3102    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 300,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'BLOUBERG, LIMPOPO',
		Code: '0902',
		Hub: 'JNB',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 500,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'BLOUBERG,Cape Town',
		Code: '7441',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'BLOUBERGRANT Boxes',
		Code: '7443    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'BLOUBERGRANT, Cape Town',
		Code: '7441    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'BLOUBERGRANT, Jukskeipark',
		Code: '2153    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BLOUBERGRIDGE, Tableview',
		Code: '7441',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'BLOUBERGRISE, Tableview',
		Code: '7441    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'BLOUBERGSTRAND ',
		Code: '7441    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'BLOUBERGSTRAND Boxes',
		Code: '7436    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'BLOUBOSRAND, Randburg',
		Code: '2188',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BLUE DOWNS, Eersterivier',
		Code: '7100    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 15,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'BLUE HEAVEN, Bryanston',
		Code: '2021    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BLUE HEIGHTS, Westville',
		Code: '3629',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'BLUE HILLS, Halfway House',
		Code: '1685',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BLUE HORIZON BAY',
		Code: '6070',
		Hub: 'PLZ',
		Ring: 1,
		OpsHub: 'PLZ',
		Dist: 40,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'BLUE HORIZON, Mitchells Plain',
		Code: '7785    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'BLUE RIDGE, Durban North',
		Code: '4051',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'BLUE RISE, Queenstown',
		Code: '5320    ',
		Hub: 'ELS',
		Ring: 4,
		OpsHub: 'ELS',
		Dist: 210,
		AreaName: 'East London Regional',
		Surch: null,
	},
	{
		PlaceName: 'BLUEGUM, Athlone',
		Code: '7764    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'BLUEWATER BAY, Port Elizabeth',
		Code: '6210    ',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'BLUFF',
		Code: '4052    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'BLUFF, Durban',
		Code: '4052    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'BLYDEVILLE, Lichtenburg',
		Code: '2740    ',
		Hub: 'NWP',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 270,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'BLYTHEDALE BEACH, Stanger',
		Code: '4450    ',
		Hub: 'DUR',
		Ring: 2,
		OpsHub: 'DUR',
		Dist: 120,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'BLYVOORUITSIG',
		Code: '2504    ',
		Hub: 'JNB',
		Ring: 2,
		OpsHub: 'JNB',
		Dist: 100,
		AreaName: 'Johannesburg Regional',
		Surch: 'Mines/Farms/Power Plants',
	},
	{
		PlaceName: 'BO OAKDALE, Bellville',
		Code: '7530',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'BO-DORP, George',
		Code: '6529',
		Hub: 'GRJ',
		Ring: 0,
		OpsHub: 'GRJ',
		Dist: 10,
		AreaName: 'George Central',
		Surch: null,
	},
	{
		PlaceName: 'BO-KAAP, Cape Town',
		Code: '8001',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 0,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'BOARDWALK MEANDER, Pretoria',
		Code: '0081',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Regional',
		Surch: null,
	},
	{
		PlaceName: 'BOAST VILLAGE, Port Elizabeth',
		Code: '6200    ',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'BOATLAKE VILLAGE, Benoni',
		Code: '1501    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BOCHABELLA, Bloemfontein',
		Code: '9323    ',
		Hub: 'BFN',
		Ring: 2,
		OpsHub: 'BFN',
		Dist: 30,
		AreaName: 'Bloemfontein Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'BOCHUM',
		Code: '0790    ',
		Hub: 'PTG',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 300,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'BOEKENHOUT',
		Code: '0561    ',
		Hub: 'PRY',
		Ring: 4,
		OpsHub: 'PRY',
		Dist: 180,
		AreaName: 'Pretoria Regional',
		Surch: null,
	},
	{
		PlaceName: 'BOEKENHOUT, Rustenburg',
		Code: '0300    ',
		Hub: 'JNB',
		Ring: 2,
		OpsHub: 'JNB',
		Dist: 120,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BOESMANSKOP',
		Code: '9951    ',
		Hub: 'BFN',
		Ring: 4,
		OpsHub: 'BFN',
		Dist: 180,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'BOESMANSRIVIERMOND',
		Code: '6190    ',
		Hub: 'PLZ',
		Ring: 3,
		OpsHub: 'PLZ',
		Dist: 160,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'BOETRAND, Klerksdorp',
		Code: '2571    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 150,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'BOGGOMSBAAI',
		Code: '6511',
		Hub: 'GRJ',
		Ring: 1,
		OpsHub: 'GRJ',
		Dist: 75,
		AreaName: 'George Regional',
		Surch: null,
	},
	{
		PlaceName: 'BOICHOKO, Postmasburg',
		Code: '8420    ',
		Hub: 'BFN',
		Ring: 4,
		OpsHub: 'BFN',
		Dist: 570,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'BOIKANYO',
		Code: '2625    ',
		Hub: 'JNB',
		Ring: 3,
		OpsHub: 'JNB',
		Dist: 120,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'BOIKETLO',
		Code: '2537    ',
		Hub: 'JNB',
		Ring: 3,
		OpsHub: 'JNB',
		Dist: 150,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'BOIKHUTSONG, Wolmaransstad',
		Code: '2630    ',
		Hub: 'JNB',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 320,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'BOIPATONG',
		Code: '1901    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 60,
		AreaName: 'Johannesburg Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'BOKMAKIERIE, Athlone',
		Code: '7764    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'BOKSBURG Boxes',
		Code: '1460    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BOKSBURG EAST, Boksburg',
		Code: '1459    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BOKSBURG NORTH Boxes',
		Code: '1461    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BOKSBURG NORTH, Boksburg',
		Code: '1459    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BOKSBURG SOUTH, Boksburg',
		Code: '1459    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BOKSBURG WEST, Boksburg',
		Code: '1459    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BOKSBURG, Johannesburg',
		Code: '1459    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BOKSBURG-NOORD Busse',
		Code: '1461    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BOKSBURG-NOORD, Boksburg',
		Code: '1459    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BOKSBURG-OOS, Boksburg',
		Code: '1459    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BOKSBURG-SUID, Boksburg',
		Code: '1459    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BOKSBURG-WES, Boksburg',
		Code: '1459    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BOLEU',
		Code: '0474    ',
		Hub: 'PRY',
		Ring: 4,
		OpsHub: 'PRY',
		Dist: 180,
		AreaName: 'Pretoria Regional',
		Surch: null,
	},
	{
		PlaceName: 'BOLO RESERVE',
		Code: '4935    ',
		Hub: 'ELS',
		Ring: 2,
		OpsHub: 'ELS',
		Dist: 100,
		AreaName: 'East London Regional',
		Surch: null,
	},
	{
		PlaceName: 'BOLOKANANG, Petrusburg',
		Code: '9932    ',
		Hub: 'BFN',
		Ring: 1,
		OpsHub: 'BFN',
		Dist: 80,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'BOLTONIA, Krugersdorp',
		Code: '1739    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BOMBAL Est/Lgd, Ottery',
		Code: '7800    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'BOMBAY HEIGHTS, Pietermaritzburg',
		Code: '3201    ',
		Hub: 'PMB',
		Ring: 1,
		OpsHub: 'PMB',
		Dist: 75,
		AreaName: 'Pietermaritzburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'BON ACCORD',
		Code: '0009    ',
		Hub: 'PRY',
		Ring: 1,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Regional',
		Surch: null,
	},
	{
		PlaceName: 'BONAERO PARK & Ext, Kempton Park',
		Code: '1619    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BONAERO PARK Boxes',
		Code: '1622    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BONAERO PARK Ext 1',
		Code: '1619    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BONGOLETHU, Bridgmanville',
		Code: '6621    ',
		Hub: 'GRJ',
		Ring: 1,
		OpsHub: 'GRJ',
		Dist: 70,
		AreaName: 'George Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'BONGWENI, Colesberg',
		Code: '5980    ',
		Hub: 'BFN',
		Ring: 4,
		OpsHub: 'BFN',
		Dist: 280,
		AreaName: 'Bloemfontein Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'BONGWENI, Khayelitsha',
		Code: '7784    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'BONGWENI, Randfontein',
		Code: '1760    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'BONISANI',
		Code: '0927    ',
		Hub: 'JNB',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 300,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'BONNIE BRAE, Kraaifontein',
		Code: '7570',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'BONNIE DOONE, East London',
		Code: '5241    ',
		Hub: 'ELS',
		Ring: 0,
		OpsHub: 'ELS',
		Dist: 10,
		AreaName: 'East London Central',
		Surch: null,
	},
	{
		PlaceName: 'BONNIEBROOK, Kraaifontein',
		Code: '7570    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'BONNIEVALE',
		Code: '6730    ',
		Hub: 'CPT',
		Ring: 1,
		OpsHub: 'CPT',
		Dist: 180,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'BONNIEVALE, Boksburg',
		Code: '1459    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BONNY BROOK, Kraaifontein',
		Code: '7570    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'BONTEHEUWEL, Cape Town',
		Code: '7764    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'BONTHEUWEL Boxes',
		Code: '7763    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'BONZA BAY, East London',
		Code: '5241    ',
		Hub: 'ELS',
		Ring: 0,
		OpsHub: 'ELS',
		Dist: 10,
		AreaName: 'East London Central',
		Surch: null,
	},
	{
		PlaceName: 'BONZABAAI, Oos-Londen',
		Code: '5241    ',
		Hub: 'ELS',
		Ring: 0,
		OpsHub: 'ELS',
		Dist: 10,
		AreaName: 'East London Central',
		Surch: null,
	},
	{
		PlaceName: 'BOONS',
		Code: '2807    ',
		Hub: 'RBG',
		Ring: 3,
		OpsHub: 'JNB',
		Dist: 130,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'BOOTH AANSLUITING, Durban',
		Code: '4094    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'BOOTH JUNCTION, Durban',
		Code: '4094    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'BOOYSENS Boxes',
		Code: '2016    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BOOYSENS PARK, Port Elizabeth',
		Code: '6059    ',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'BOOYSENS RESERVE',
		Code: '2091    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BOOYSENS, Johannesburg',
		Code: '2091    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BOOYSENS, Pretoria',
		Code: '0082    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'BOPHUTHATSWANA',
		Code: '1911',
		Hub: 'JNB',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 0,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'BORCHARDS, George',
		Code: '6529',
		Hub: 'GRJ',
		Ring: 0,
		OpsHub: 'GRJ',
		Dist: 10,
		AreaName: 'George Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'BORDEAUX, Randburg',
		Code: '2194    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BOROLELO, Swartruggens',
		Code: '2835    ',
		Hub: 'RBG',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 210,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'BOS EN DAL, George',
		Code: '6529',
		Hub: 'GRJ',
		Ring: 0,
		OpsHub: 'GRJ',
		Dist: 10,
		AreaName: 'George Central',
		Surch: null,
	},
	{
		PlaceName: 'BOSBELL Est/Lgd, Bellville',
		Code: '7530    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'BOSBOKRAND',
		Code: '1280    ',
		Hub: 'NLP',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 310,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'BOSCHENDAL, Retreat',
		Code: '7945    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'BOSHOEK',
		Code: '0301    ',
		Hub: 'RBG',
		Ring: 2,
		OpsHub: 'JNB',
		Dist: 120,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'BOSHOF',
		Code: '8340    ',
		Hub: 'KIM',
		Ring: 4,
		OpsHub: 'KIM',
		Dist: 200,
		AreaName: 'Kimberley Regional',
		Surch: null,
	},
	{
		PlaceName: 'BOSKLOOF ECO ESTATE, Somerset West',
		Code: '7135',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 50,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'BOSKOP',
		Code: '2528    ',
		Hub: 'JNB',
		Ring: 3,
		OpsHub: 'JNB',
		Dist: 150,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'BOSKRUIN & Ext, Bromhof',
		Code: '2154    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BOSMONT, Johannesburg',
		Code: '2093    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BOSONIA, Kuilsrivier',
		Code: '7580    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'BOSPOORT',
		Code: '2730    ',
		Hub: 'PRY',
		Ring: 4,
		OpsHub: 'PRY',
		Dist: 300,
		AreaName: 'Pretoria Regional',
		Surch: null,
	},
	{
		PlaceName: 'BOSTON',
		Code: '3211    ',
		Hub: 'DUR',
		Ring: 2,
		OpsHub: 'DUR',
		Dist: 100,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'BOSTON Est/Lgd, Bellville',
		Code: '7530    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'BOSVILLE, Middelburg (Tvl)',
		Code: '1050    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 140,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'BOT RIVER',
		Code: '7185    ',
		Hub: 'CPT',
		Ring: 1,
		OpsHub: 'CPT',
		Dist: 70,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'BOTANIC GARDENS, Durban',
		Code: '4001    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'BOTHA',
		Code: '6857    ',
		Hub: 'CPT',
		Ring: 4,
		OpsHub: 'CPT',
		Dist: 230,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: "BOTHA'S HILL",
		Code: '3660    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 30,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'BOTHASIG Boxes',
		Code: '7406    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'BOTHASIG, Cape Town',
		Code: '7441    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'BOTHAVILLE',
		Code: '9660    ',
		Hub: 'BFN',
		Ring: 4,
		OpsHub: 'BFN',
		Dist: 270,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'BOTLENG, Delmas',
		Code: '2210    ',
		Hub: 'MBG',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 50,
		AreaName: 'Johannesburg Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'BOTRIVIER',
		Code: '7185    ',
		Hub: 'CPT',
		Ring: 1,
		OpsHub: 'CPT',
		Dist: 70,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'BOTSHABELO',
		Code: '9781',
		Hub: 'BFN',
		Ring: 1,
		OpsHub: 'BFN',
		Dist: 80,
		AreaName: 'Bloemfontein Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'BOTSHABELO  ',
		Code: '9781    ',
		Hub: 'BFN',
		Ring: 1,
		OpsHub: 'BFN',
		Dist: 80,
		AreaName: 'Bloemfontein Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'BOTTERKLOOF',
		Code: '6774    ',
		Hub: 'GRJ',
		Ring: 4,
		OpsHub: 'GRJ',
		Dist: 150,
		AreaName: 'George Regional',
		Surch: null,
	},
	{
		PlaceName: 'BOTTLEBRUSH, Chatsworth',
		Code: '4092',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'BOTTLEBRUSH, Richards Bay',
		Code: '3903    ',
		Hub: 'DUR',
		Ring: 1,
		OpsHub: 'RCB',
		Dist: 200,
		AreaName: 'Richards Bay',
		Surch: null,
	},
	{
		PlaceName: 'BOUGHTON, Pietermaritzburg',
		Code: '3201    ',
		Hub: 'PMB',
		Ring: 1,
		OpsHub: 'PMB',
		Dist: 75,
		AreaName: 'Pietermaritzburg Regional',
		Surch: null,
	},
	{
		PlaceName: "BOURKE'S LUCK",
		Code: '1272    ',
		Hub: 'JNB',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 310,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'BOYNE',
		Code: '0728    ',
		Hub: 'PTG',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 300,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'BRAAMFISHER',
		Code: '1875',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'BRAAMFONTEIN Boxes',
		Code: '2017    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BRAAMFONTEIN, Johannesburg',
		Code: '2001    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BRAAMPARK, Johannesburg',
		Code: '2001',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BRACKEN GARDENS, Alberton',
		Code: '1449    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BRACKENDOWNS ',
		Code: '1448',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BRACKENDOWNS EXT 1',
		Code: '1448',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BRACKENDOWNS EXT 2',
		Code: '1448',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BRACKENDOWNS EXT 3',
		Code: '1448',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BRACKENDOWNS EXT 4',
		Code: '1448',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BRACKENDOWNS EXT 5',
		Code: '1448',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BRACKENFELL',
		Code: '7560    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'BRACKENFELL SOUTH, Cape Town',
		Code: '7560',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 30,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'BRACKENFELL, Cape Town',
		Code: '7560    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'BRACKENHURST & Ext A',
		Code: '1449    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BRACKENHURST Ext 2, A',
		Code: '1449    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BRACKVILLE, Brackenfell',
		Code: '7560    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'BRAELYN HEIGHTS, East London',
		Code: '5209    ',
		Hub: 'ELS',
		Ring: 0,
		OpsHub: 'ELS',
		Dist: 10,
		AreaName: 'East London Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'BRAELYN, East London',
		Code: '5201    ',
		Hub: 'ELS',
		Ring: 0,
		OpsHub: 'ELS',
		Dist: 10,
		AreaName: 'East London Central',
		Surch: null,
	},
	{
		PlaceName: 'BRAEMAR',
		Code: '4202    ',
		Hub: 'DUR',
		Ring: 1,
		OpsHub: 'DUR',
		Dist: 40,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'BRAKE VILLAGE, Tongaat',
		Code: '4399',
		Hub: 'DUR',
		Ring: 1,
		OpsHub: 'DUR',
		Dist: 70,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'BRAKPAN (S)',
		Code: '1540',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'BRAKPAN NORTH',
		Code: '1545    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BRAKPAN, Johannesburg',
		Code: '1540    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BRAKPAN-NOORD',
		Code: '1545    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BRAMHOPE, Port Elizabeth',
		Code: '6025',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'BRAMLEY Boxes',
		Code: '2018    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BRAMLEY GARDENS, Johannesburg',
		Code: '2090    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BRAMLEY MANOR, Johannesburg',
		Code: '2090    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BRAMLEY NORTH, Johannesburg',
		Code: '2090    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BRAMLEY PARK, Johannesburg',
		Code: '2090    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BRAMLEY RESERVE, Johannesburg',
		Code: '2090    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BRAMLEY VIEW, Johannesburg',
		Code: '2090    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BRAMLEY, Johannesburg',
		Code: '2090    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BRAMLEY-NOORD, Johannesburg',
		Code: '2090    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: "BRAND'S POST",
		Code: '9871    ',
		Hub: 'BFN',
		Ring: 4,
		OpsHub: 'BFN',
		Dist: 270,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'BRANDFORT',
		Code: '9400    ',
		Hub: 'BFN',
		Ring: 1,
		OpsHub: 'BFN',
		Dist: 70,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'BRANDHOF Boxes',
		Code: '9324    ',
		Hub: 'BFN',
		Ring: 0,
		OpsHub: 'BFN',
		Dist: 30,
		AreaName: 'Bloemfontein Central',
		Surch: null,
	},
	{
		PlaceName: 'BRANDHOF, Bloemfontein',
		Code: '9301',
		Hub: 'BFN',
		Ring: 0,
		OpsHub: 'BFN',
		Dist: 30,
		AreaName: 'Bloemfontein Central',
		Surch: null,
	},
	{
		PlaceName: 'BRANDPAN',
		Code: '9471    ',
		Hub: 'BFN',
		Ring: 3,
		OpsHub: 'BFN',
		Dist: 160,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'BRANDVLEI',
		Code: '8915',
		Hub: 'CPT',
		Ring: 4,
		OpsHub: 'CPT',
		Dist: 500,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'BRANDWAG',
		Code: '6507    ',
		Hub: 'GRJ',
		Ring: 1,
		OpsHub: 'GRJ',
		Dist: 60,
		AreaName: 'George Regional',
		Surch: null,
	},
	{
		PlaceName: 'BRANDWAG, Bloemfontein',
		Code: '9301',
		Hub: 'BFN',
		Ring: 0,
		OpsHub: 'BFN',
		Dist: 30,
		AreaName: 'Bloemfontein Central',
		Surch: null,
	},
	{
		PlaceName: 'BRANDWAG, Kuilsrivier',
		Code: '7580    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'BRANDWAG, Macassar',
		Code: '7130    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 30,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'BRANDWAG, Stellenbosch',
		Code: '7600    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 60,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'BRANDWAG, Worcester',
		Code: '6850    ',
		Hub: 'CPT',
		Ring: 2,
		OpsHub: 'CPT',
		Dist: 120,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'BRANDWOOD, Kuilsrivier',
		Code: '7580    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'BRAY',
		Code: '8620    ',
		Hub: 'BFN',
		Ring: 4,
		OpsHub: 'BFN',
		Dist: 450,
		AreaName: 'Bloemfontein Regional',
		Surch: 'Far Outlying',
	},
	{
		PlaceName: 'BREAUNANDA Ext/Uit 1 - 3,',
		Code: '1724    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BREAUNANDA, Krugersdorp',
		Code: '1739    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BRECKNOCK, Bryanston',
		Code: '2021    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BREDASDORP',
		Code: '7280    ',
		Hub: 'CPT',
		Ring: 3,
		OpsHub: 'CPT',
		Dist: 190,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'BREDELL',
		Code: '1623    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BREEDE RIVER',
		Code: '6858    ',
		Hub: 'CPT',
		Ring: 4,
		OpsHub: 'CPT',
		Dist: 230,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'BREERIVIER',
		Code: '6858    ',
		Hub: 'CPT',
		Ring: 4,
		OpsHub: 'CPT',
		Dist: 230,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: "BREIDBACH, King William's",
		Code: '5600    ',
		Hub: 'ELS',
		Ring: 1,
		OpsHub: 'ELS',
		Dist: 70,
		AreaName: 'East London Regional',
		Surch: null,
	},
	{
		PlaceName: 'BRENCANIA, Brakpan',
		Code: '1540    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BRENDAVERE, Bryanston',
		Code: '2021    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BRENTHURST & Ext/Uit 1',
		Code: '1540    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BRENTHURST Boxes',
		Code: '1542    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BRENTPARK, Kroonstad',
		Code: '9499    ',
		Hub: 'BFN',
		Ring: 3,
		OpsHub: 'BFN',
		Dist: 170,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'BRENTWOOD PARK & Ext',
		Code: '1501    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BRENTWOOD PARK Boxes',
		Code: '1505    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BRENTWOOD PARK, Durbanville',
		Code: '7550    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'BRENTWOOD PARK, Port Elizabeth',
		Code: '6025',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'BRENTWOOD VILLAGE, Table View',
		Code: '7441    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'BRENTWOODPARK, Eersteriver',
		Code: '7100    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 15,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'BRIARDENE, Durban North',
		Code: '4051',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'BRIDGEBANK, Somerset West',
		Code: '7130    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 30,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'BRIDGEMEAD, Port Elizabeth',
		Code: '6025',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'BRIDGETOWN, Athlone',
		Code: '7764    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'BRIDGMANVILLE',
		Code: '6621    ',
		Hub: 'GRJ',
		Ring: 1,
		OpsHub: 'GRJ',
		Dist: 70,
		AreaName: 'George Regional',
		Surch: null,
	},
	{
		PlaceName: 'BRIDGTON Boxes',
		Code: '6623    ',
		Hub: 'GRJ',
		Ring: 1,
		OpsHub: 'GRJ',
		Dist: 70,
		AreaName: 'George Regional',
		Surch: null,
	},
	{
		PlaceName: 'BRIDGTON, Oudtshoorn',
		Code: '6620    ',
		Hub: 'GRJ',
		Ring: 1,
		OpsHub: 'GRJ',
		Dist: 70,
		AreaName: 'George Regional',
		Surch: null,
	},
	{
		PlaceName: 'BRIGHTON BEACH Boxes',
		Code: '4009    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'BRIGHTON BEACH, Durban',
		Code: '4052    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'BRIGHTSIDE, Ladybrand',
		Code: '9745    ',
		Hub: 'BFN',
		Ring: 3,
		OpsHub: 'BFN',
		Dist: 130,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'BRITS',
		Code: '0250    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 90,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'BRITS Ext/Uit 11, Brits',
		Code: '0250    ',
		Hub: 'JNB',
		Ring: 2,
		OpsHub: 'JNB',
		Dist: 90,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'BRITSTOWN',
		Code: '8970    ',
		Hub: 'KIM',
		Ring: 4,
		OpsHub: 'KIM',
		Dist: 400,
		AreaName: 'Kimberley Regional',
		Surch: null,
	},
	{
		PlaceName: 'BRITTANIA BAY',
		Code: '7282',
		Hub: 'CPT',
		Ring: 1,
		OpsHub: 'CPT',
		Dist: 170,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'BRIXTON Boxes',
		Code: '2019    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BRIXTON, Johannesburg',
		Code: '2092    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BRIZA Est/Lgd, Somerset West',
		Code: '7130    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 30,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'BROADACRES, Bryanston',
		Code: '2021',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BROADLANDS, Mount Edgecombe',
		Code: '4068',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'BROADWAY, Johannesburg',
		Code: '2020    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BROADWOOD, Port Elizabeth',
		Code: '6070',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'BROEDERSPUT',
		Code: '8624    ',
		Hub: 'BFN',
		Ring: 4,
		OpsHub: 'BFN',
		Dist: 450,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'BROEDERSTROOM',
		Code: '0240    ',
		Hub: 'PRY',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 100,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'BROMBEEK',
		Code: '0906    ',
		Hub: 'JNB',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 300,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'BROMHOF Boxes',
		Code: '2154    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BROMHOF, Randburg',
		Code: '2154    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BRONBERG CLOSE, Pretoria',
		Code: '0081',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'BRONBERRIK, Verwoerdburg',
		Code: '0157    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'BRONKHORSTSPRUIT',
		Code: '1020    ',
		Hub: 'PRY',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 90,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'BRONVILLE',
		Code: '9473    ',
		Hub: 'WEL',
		Ring: 3,
		OpsHub: 'JNB',
		Dist: 160,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'BROOKDALE, Phoenix',
		Code: '4068',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'BROOKLYN, Pretoria',
		Code: '0181    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'BROOKLYN, Ysterplaat',
		Code: '7405    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'BROOKLYN,Cape Town',
		Code: '7405',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'BROOKMEAD, East London',
		Code: '5247    ',
		Hub: 'ELS',
		Ring: 0,
		OpsHub: 'ELS',
		Dist: 10,
		AreaName: 'East London Central',
		Surch: null,
	},
	{
		PlaceName: 'BROOKVILLE, East London',
		Code: '5201    ',
		Hub: 'ELS',
		Ring: 0,
		OpsHub: 'ELS',
		Dist: 10,
		AreaName: 'East London Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'BRUMA, Johannesburg',
		Code: '2198    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BRUMMERIA & Ext 2,',
		Code: '0184    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'BRUNTVILLE, Mooirivier',
		Code: '3300    ',
		Hub: 'HSM',
		Ring: 3,
		OpsHub: 'JNB',
		Dist: 160,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'BRUYNS HILL',
		Code: '3452    ',
		Hub: 'DUR',
		Ring: 2,
		OpsHub: 'DUR',
		Dist: 110,
		AreaName: 'Durban Regional',
		Surch: 'Far Outlying',
	},
	{
		PlaceName: 'BRYANBRINK, Randburg',
		Code: '2194    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BRYANSTON Boxes',
		Code: '2021    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BRYANSTON EAST, Sloane Park',
		Code: '2152    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BRYANSTON Ext/Uit 3 & 5, Randburg',
		Code: '2194    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BRYANSTON Ext/Uit 32, Petervale',
		Code: '2151    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BRYANSTON Ext/Uit 4, 7, 1',
		Code: '2021    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BRYANSTON Ext/Uitmerview',
		Code: '2060    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BRYANSTON MANOR, Randburg',
		Code: '2194    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BRYANSTON SHOPPING CENTRE',
		Code: '2021    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BRYANSTON WEST, Cramerview',
		Code: '2060    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BRYANSTON, Johannesburg',
		Code: '2191    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BRYANSTON-OOS, Sloane Park',
		Code: '2152    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BRYANSTON-WES, Cramerview',
		Code: '2060    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BRYMORE, Port Elizabeth',
		Code: '6025',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'BRYNTIRION, Pretoria',
		Code: '0083    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'BUCCLEUCH, Sandton',
		Code: '2090',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BUCHANAN PARK, Durban',
		Code: '4001',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'BUCKINGHAM Est/Lgd, Rondebosch',
		Code: '7780    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'BUELINGTON GARDENS, Queensburgh',
		Code: '4093',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'BUENA VISTA, Amanzimtoti',
		Code: '4126',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'BUFFALO FLATS, East London',
		Code: '5209    ',
		Hub: 'ELS',
		Ring: 0,
		OpsHub: 'ELS',
		Dist: 10,
		AreaName: 'East London Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'BUFFALO PASS HEIGHTS, East London',
		Code: '5247    ',
		Hub: 'ELS',
		Ring: 0,
		OpsHub: 'ELS',
		Dist: 10,
		AreaName: 'East London Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'BUFFALO Tsp/Dgd, East London',
		Code: '5201    ',
		Hub: 'ELS',
		Ring: 0,
		OpsHub: 'ELS',
		Dist: 10,
		AreaName: 'East London Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'BUFFELJAGS RIVER',
		Code: '6742    ',
		Hub: 'CPT',
		Ring: 4,
		OpsHub: 'CPT',
		Dist: 220,
		AreaName: 'Cape Town Regional',
		Surch: 'Far Outlying',
	},
	{
		PlaceName: 'BUFFELJAGSRIVIER',
		Code: '6742    ',
		Hub: 'CPT',
		Ring: 4,
		OpsHub: 'CPT',
		Dist: 220,
		AreaName: 'Cape Town Regional',
		Surch: 'Far Outlying',
	},
	{
		PlaceName: 'BUFFELS BOSCH',
		Code: '4093',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'BUFFELSDALE, Tongaat',
		Code: '4399',
		Hub: 'DUR',
		Ring: 1,
		OpsHub: 'DUR',
		Dist: 70,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'BUFFELSPOORT',
		Code: '0284',
		Hub: 'PRY',
		Ring: 4,
		OpsHub: 'PRY',
		Dist: 110,
		AreaName: 'Pretoria Regional',
		Surch: null,
	},
	{
		PlaceName: 'BUHRMANNSDRIF',
		Code: '2867    ',
		Hub: 'JNB',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 320,
		AreaName: 'Johannesburg Regional',
		Surch: 'Far Outlying',
	},
	{
		PlaceName: 'BUITENZORG VILLAGE, Table View',
		Code: '7441    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'BULEMBU AIRPORT',
		Code: '9996    ',
		Hub: 'ELS',
		Ring: 1,
		OpsHub: 'ELS',
		Dist: 60,
		AreaName: 'East London Regional',
		Surch: null,
	},
	{
		PlaceName: 'BULGE RIVER',
		Code: '0531    ',
		Hub: 'PRY',
		Ring: 4,
		OpsHub: 'PRY',
		Dist: 180,
		AreaName: 'Pretoria Regional',
		Surch: null,
	},
	{
		PlaceName: 'BULGERIVIER',
		Code: '0531    ',
		Hub: 'PRY',
		Ring: 4,
		OpsHub: 'PRY',
		Dist: 180,
		AreaName: 'Pretoria Regional',
		Surch: null,
	},
	{
		PlaceName: 'BULL HILL',
		Code: '8535    ',
		Hub: 'BFN',
		Ring: 4,
		OpsHub: 'BFN',
		Dist: 200,
		AreaName: 'Bloemfontein Regional',
		Surch: 'Far Outlying',
	},
	{
		PlaceName: 'BULTFONTEIN, Freestate',
		Code: '9670    ',
		Hub: 'WEL',
		Ring: 2,
		OpsHub: 'JNB',
		Dist: 70,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'BULTHOUDERSIG',
		Code: '6971    ',
		Hub: 'CPT',
		Ring: 4,
		OpsHub: 'CPT',
		Dist: 470,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'BULTIES Est/Lgd, Somerset West',
		Code: '7130    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 30,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'BULWER',
		Code: '4575    ',
		Hub: 'DUR',
		Ring: 2,
		OpsHub: 'DUR',
		Dist: 80,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'BULWOOD',
		Code: '4010    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'BUNKERS HILL, East London',
		Code: '5241    ',
		Hub: 'ELS',
		Ring: 0,
		OpsHub: 'ELS',
		Dist: 10,
		AreaName: 'East London Central',
		Surch: null,
	},
	{
		PlaceName: 'BURBREEZE, Maidstone',
		Code: '4399',
		Hub: 'DUR',
		Ring: 1,
		OpsHub: 'DUR',
		Dist: 70,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'BURGERSDORP ',
		Code: '9744    ',
		Hub: 'BFN',
		Ring: 4,
		OpsHub: 'BFN',
		Dist: 360,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'BURGERSDORP, Johannesburg',
		Code: '2092    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BURGERSFORT',
		Code: '1150    ',
		Hub: 'MPG',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 300,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'BURGERSHOOP, Krugersdorp',
		Code: '1739    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BURGERSPARK',
		Code: '0012    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'BURGUNDY ESTATE',
		Code: '7441',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'BURLINGTON GARDENS, Queensburgh',
		Code: '4093',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'BURLINGTON HEIGHTS, Queensburgh',
		Code: '4093',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'BURLINGTON HEIGHTS, Queensburgh',
		Code: '4093',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'BURNWOOD, Mayville',
		Code: '4091',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'BUSHBUCKRIDGE',
		Code: '1280    ',
		Hub: 'NLP',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 310,
		AreaName: 'Johannesburg Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'BUSHHILL, Bromhof',
		Code: '2154    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BUSHKOPPIES, Kliptown',
		Code: '1812    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 30,
		AreaName: 'Johannesburg Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'BUSHLANDS, Durban',
		Code: '4052    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'BUSHMANS RIVER MOUTH',
		Code: '6190    ',
		Hub: 'PLZ',
		Ring: 3,
		OpsHub: 'PLZ',
		Dist: 160,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'BUTTERWORTH',
		Code: '4960    ',
		Hub: 'ELS',
		Ring: 1,
		OpsHub: 'ELS',
		Dist: 130,
		AreaName: 'East London Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'BUURENDAL, Edenvale (Tvl)',
		Code: '1609    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'BUXEDENI',
		Code: '3938    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 300,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'BUYSDORP',
		Code: '0923    ',
		Hub: 'JNB',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 300,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'CALA',
		Code: '5455    ',
		Hub: 'ELS',
		Ring: 4,
		OpsHub: 'ELS',
		Dist: 180,
		AreaName: 'East London Regional',
		Surch: null,
	},
	{
		PlaceName: 'CALEDON',
		Code: '7230    ',
		Hub: 'CPT',
		Ring: 1,
		OpsHub: 'CPT',
		Dist: 100,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'CALEDON SQUARE, CAPE TOWN',
		Code: '7905    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'CALEDONPLEIN, Cape Town (boxes)',
		Code: '7905    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'CALITZDORP',
		Code: '6660    ',
		Hub: 'GRJ',
		Ring: 2,
		OpsHub: 'GRJ',
		Dist: 110,
		AreaName: 'George Regional',
		Surch: null,
	},
	{
		PlaceName: 'CALVINIA',
		Code: '8190    ',
		Hub: 'CPT',
		Ring: 4,
		OpsHub: 'CPT',
		Dist: 420,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'CAMBRIDGE Boxes',
		Code: '5206    ',
		Hub: 'ELS',
		Ring: 0,
		OpsHub: 'ELS',
		Dist: 10,
		AreaName: 'East London Central',
		Surch: null,
	},
	{
		PlaceName: 'CAMBRIDGE Est/Lgd, Milner',
		Code: '7441    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'CAMBRIDGE WEST Boxes',
		Code: '5207    ',
		Hub: 'ELS',
		Ring: 0,
		OpsHub: 'ELS',
		Dist: 10,
		AreaName: 'East London Central',
		Surch: null,
	},
	{
		PlaceName: 'CAMBRIDGE WEST, East London',
		Code: '5247    ',
		Hub: 'ELS',
		Ring: 0,
		OpsHub: 'ELS',
		Dist: 10,
		AreaName: 'East London Central',
		Surch: null,
	},
	{
		PlaceName: 'CAMBRIDGE, East London',
		Code: '5247    ',
		Hub: 'ELS',
		Ring: 0,
		OpsHub: 'ELS',
		Dist: 10,
		AreaName: 'East London Central',
		Surch: null,
	},
	{
		PlaceName: 'CAMBRIDGE, Milnerton',
		Code: '7441    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'CAMBRIDGE-WES Busse',
		Code: '5207    ',
		Hub: 'ELS',
		Ring: 0,
		OpsHub: 'ELS',
		Dist: 10,
		AreaName: 'East London Central',
		Surch: null,
	},
	{
		PlaceName: 'CAMBRIDGE-WES, Oos-Londen',
		Code: '5247    ',
		Hub: 'ELS',
		Ring: 0,
		OpsHub: 'ELS',
		Dist: 10,
		AreaName: 'East London Central',
		Surch: null,
	},
	{
		PlaceName: 'CAMELOT, Eersterivier',
		Code: '7100    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 15,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'CAMPBELL',
		Code: '8360    ',
		Hub: 'KIM',
		Ring: 4,
		OpsHub: 'KIM',
		Dist: 330,
		AreaName: 'Kimberley Regional',
		Surch: null,
	},
	{
		PlaceName: 'CAMPBELLDORP, Klerksdorp',
		Code: '2571    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 150,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'CAMPBELLSTOWN, Mount Edge',
		Code: '4300    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 50,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'CAMPERDOWN',
		Code: '3720    ',
		Hub: 'DUR',
		Ring: 1,
		OpsHub: 'DUR',
		Dist: 25,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'CAMPHERSDRIFT, George',
		Code: '6529',
		Hub: 'GRJ',
		Ring: 0,
		OpsHub: 'GRJ',
		Dist: 10,
		AreaName: 'George Central',
		Surch: null,
	},
	{
		PlaceName: 'CAMPS BAY Boxes',
		Code: '8040    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'CAMPS BAY, Cape Town',
		Code: '8005',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 30,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'CAMPSDRIFT, Pietermaritzburg',
		Code: '3201    ',
		Hub: 'PMB',
		Ring: 1,
		OpsHub: 'PMB',
		Dist: 75,
		AreaName: 'Pietermaritzburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'CANELANDS',
		Code: '4341    ',
		Hub: 'DUR',
		Ring: 1,
		OpsHub: 'DUR',
		Dist: 30,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'CANESIDE, Phoenix',
		Code: '4068',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'CAPE ST FRANCIS',
		Code: '6300    ',
		Hub: 'PLZ',
		Ring: 2,
		OpsHub: 'PLZ',
		Dist: 100,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'CAPE TOWN',
		Code: '8001',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 0,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'CAPE TOWN',
		Code: '8001    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'CAPE TOWN Boxes',
		Code: '8000    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'CAPE TOWN DEPOT',
		Code: '8001    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 0,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'CAPEST FRANCIS, Humansdorp',
		Code: '6300    ',
		Hub: 'PLZ',
		Ring: 2,
		OpsHub: 'PLZ',
		Dist: 100,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'CAPITAL PARK, Pretoria',
		Code: '0084    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'CAPRI VILLAGE,Fish Hoek',
		Code: '7975    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 60,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'CAPRICORN, Pietersburg',
		Code: '0699',
		Hub: 'PTG',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 400,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'CARAVELLE Boxes',
		Code: '7787    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'CARAVELLE, Cape Town',
		Code: '7785    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'CARENVALE, Roodepoort',
		Code: '1724    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'CARLETONVILLE',
		Code: '2500    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 100,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'CARLETONVILLE Ext 8,',
		Code: '2500    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 100,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'CARLSWALD ESTATE, Midrand',
		Code: '1684',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'CARLSWALD NORTH ESTATE, Midrand',
		Code: '1685',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'CARLSWALD, Midrand',
		Code: '1684',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'CARLTON CENTRE, Johannesburg',
		Code: '2001    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'CAROLWOOD, Fish Hoek',
		Code: '7975    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 60,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'CARRINGTON HEIGHTS, Durban',
		Code: '4001    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'CARTERS GLEN, Kimberley',
		Code: '8301    ',
		Hub: 'KIM',
		Ring: 0,
		OpsHub: 'KIM',
		Dist: 20,
		AreaName: 'Kimberley Central',
		Surch: null,
	},
	{
		PlaceName: 'CASARINA, Tongaat',
		Code: '4399',
		Hub: 'DUR',
		Ring: 1,
		OpsHub: 'DUR',
		Dist: 70,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'CASCADES (Boxes)',
		Code: '3202    ',
		Hub: 'DUR',
		Ring: 2,
		OpsHub: 'DUR',
		Dist: 85,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'CASHAN, Rustenburg',
		Code: '0300    ',
		Hub: 'JNB',
		Ring: 2,
		OpsHub: 'JNB',
		Dist: 120,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'CASON, Boksburg',
		Code: '1459    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'CASSANDRA, Kimberley',
		Code: '8301    ',
		Hub: 'KIM',
		Ring: 0,
		OpsHub: 'KIM',
		Dist: 20,
		AreaName: 'Kimberley Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'CASSELDALE, Springs',
		Code: '1559    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'CASTLE ROCK, Simonstown',
		Code: '7995    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 70,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'CASTLETOWN Boxes',
		Code: '7832    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'CASTLETOWN, Plumstead',
		Code: '7800    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'CASTLEVIEW, Germiston',
		Code: '1401    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'CATHCART',
		Code: '5310    ',
		Hub: 'ELS',
		Ring: 1,
		OpsHub: 'ELS',
		Dist: 140,
		AreaName: 'East London Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'CATHEDRAL PEAK',
		Code: '3342    ',
		Hub: 'HSM',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 250,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'CATHKIN Est/Lgd, Athlone',
		Code: '7764    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'CATHKIN PARK',
		Code: '3310',
		Hub: 'DUR',
		Ring: 1,
		OpsHub: 'DUR',
		Dist: 225,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'CATO CREST, Mayville',
		Code: '4091',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'CATO MANOR',
		Code: '4091',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'CATO RIDGE',
		Code: '3680    ',
		Hub: 'DUR',
		Ring: 1,
		OpsHub: 'DUR',
		Dist: 30,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'CAVENDISH, Mobeni',
		Code: '4092',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'CE 1 - 5, Vanderbijlpark',
		Code: '1911    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 60,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'CEDARVILLE',
		Code: '4720    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 260,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'CEDERBERG',
		Code: '8136    ',
		Hub: 'CPT',
		Ring: 4,
		OpsHub: 'CPT',
		Dist: 240,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'CENTENARY PARK, Phoenix',
		Code: '4068',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'CENTOCOW',
		Code: '4601    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 200,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'CENTRAHIL',
		Code: '6006    ',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'CENTRAL WESTERN JABAVU, Pimville',
		Code: '1808    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 50,
		AreaName: 'Johannesburg Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'CENTRAL, Cape Town',
		Code: '8001',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 0,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'CENTRAL, East London',
		Code: '5201    ',
		Hub: 'ELS',
		Ring: 0,
		OpsHub: 'ELS',
		Dist: 10,
		AreaName: 'East London Central',
		Surch: null,
	},
	{
		PlaceName: 'CENTRAL, Empangeni',
		Code: '3880',
		Hub: 'DUR',
		Ring: 1,
		OpsHub: 'DUR',
		Dist: 180,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'CENTRAL, Port Elizabeth',
		Code: '6001    ',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'CENTURION, Gauteng',
		Code: '0157    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'CENTURY CITY, Cape Town',
		Code: '7441    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'CERES',
		Code: '6835    ',
		Hub: 'CPT',
		Ring: 1,
		OpsHub: 'CPT',
		Dist: 120,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'CERUTIVILLE, Nigel',
		Code: '1490    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 45,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'CEZA',
		Code: '3866    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 250,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'CHAMDOR, Krugersdorp',
		Code: '1739    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'CHANTECLER, Durbanville',
		Code: '7550    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'CHARLES LLOYD Tsp/Dgd, East London',
		Code: '5209    ',
		Hub: 'ELS',
		Ring: 0,
		OpsHub: 'ELS',
		Dist: 10,
		AreaName: 'East London Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'CHARLESTON HILL Boxes',
		Code: '7621    ',
		Hub: 'CPT',
		Ring: 1,
		OpsHub: 'CPT',
		Dist: 60,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'CHARLESTON HILL, Paarl',
		Code: '7646    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 60,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'CHARLESTOWN',
		Code: '2473    ',
		Hub: 'JNB',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 290,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'CHARLESVILLE, Jagersfontein',
		Code: '9974    ',
		Hub: 'BFN',
		Ring: 3,
		OpsHub: 'BFN',
		Dist: 140,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'CHARLESVILLE, Matroosfontein',
		Code: '7490    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'CHARLO & Ext/Uit 5, Port Elizabeth',
		Code: '6070',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'CHARTERSTON, Nigel',
		Code: '1490    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 45,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'CHARTWELL',
		Code: '1748    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'CHARUGO Est/Ldg, Grassy Park',
		Code: '7945    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'CHASE VALLEY DOWNS,Pietermaritzburg',
		Code: '3201    ',
		Hub: 'PMB',
		Ring: 1,
		OpsHub: 'PMB',
		Dist: 75,
		AreaName: 'Pietermaritzburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'CHASE VALLEY HEIGHTS',
		Code: '3201    ',
		Hub: 'PMB',
		Ring: 1,
		OpsHub: 'PMB',
		Dist: 75,
		AreaName: 'Pietermaritzburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'CHASE VALLEY, Pietermaritzburg',
		Code: '3201    ',
		Hub: 'PMB',
		Ring: 1,
		OpsHub: 'PMB',
		Dist: 75,
		AreaName: 'Pietermaritzburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'CHASEDENE, Pietermaritzburg',
		Code: '3201    ',
		Hub: 'PMB',
		Ring: 1,
		OpsHub: 'PMB',
		Dist: 75,
		AreaName: 'Pietermaritzburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'CHATSGLEN',
		Code: '4012    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'CHATSWORTH Boxes',
		Code: '4030    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'CHATSWORTH, Durban',
		Code: '4092',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'CHATTY, Port Elizabeth',
		Code: '6059    ',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'CHELMSFORD PARK, Gillitts',
		Code: '3610',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'CHELMSFORDVILLE, Gillitts',
		Code: '3610',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'CHELSEA Est/Lgd, Lansdown',
		Code: '7780    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'CHELTONDALE, Johannesburg',
		Code: '2192    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'CHEMLSFORD HEIGHTS, Tongaat',
		Code: '4399',
		Hub: 'DUR',
		Ring: 1,
		OpsHub: 'DUR',
		Dist: 70,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'CHEMPET Boxes',
		Code: '7442    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'CHEMPET, Milnerton',
		Code: '7441    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'CHERRYWOOD ESTATE, Durbanville',
		Code: '7550',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'CHESTERVILLE EXT 1',
		Code: '4091',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'CHESTERVILLE EXT 2',
		Code: '4091',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'CHESTERVILLE EXT 3',
		Code: '4091',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'CHESTERVILLE, Durban',
		Code: '4091',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: "CHETTY'S HILL, Tongaat",
		Code: '4399',
		Hub: 'DUR',
		Ring: 1,
		OpsHub: 'DUR',
		Dist: 70,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'CHILTERN HILLS, Westville',
		Code: '3629',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'CHISELHURST & Ext/Uit, Ea',
		Code: '5247    ',
		Hub: 'ELS',
		Ring: 0,
		OpsHub: 'ELS',
		Dist: 10,
		AreaName: 'East London Central',
		Surch: null,
	},
	{
		PlaceName: 'CHISELHURST Ext/Uit 3, Ea',
		Code: '5247    ',
		Hub: 'ELS',
		Ring: 0,
		OpsHub: 'ELS',
		Dist: 10,
		AreaName: 'East London Central',
		Surch: null,
	},
	{
		PlaceName: 'CHISLEHURSTON, Johannesburg',
		Code: '2196    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'CHLOORKOP',
		Code: '1624    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'CHRISMAR Est/Lgd, Bellville',
		Code: '7530    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'CHRISSIEFONTEIN',
		Code: '1963    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 60,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'CHRISTAVILLE ESTATE, Durbanville',
		Code: '7550',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'CHRISTIANA',
		Code: '2680    ',
		Hub: 'KIM',
		Ring: 1,
		OpsHub: 'KIM',
		Dist: 140,
		AreaName: 'Kimberley Regional',
		Surch: null,
	},
	{
		PlaceName: 'CHRISTOBURG, Pretoria',
		Code: '0183    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'CHRISVILLE & Ext/Uit 1,',
		Code: '2091    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'CHROMITE',
		Code: '0362    ',
		Hub: 'JNB',
		Ring: 2,
		OpsHub: 'JNB',
		Dist: 120,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'CHROOMPARK, Potgietersrus',
		Code: '0600    ',
		Hub: 'PTG',
		Ring: 3,
		OpsHub: 'JNB',
		Dist: 300,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'CHUENESPOORT',
		Code: '0745    ',
		Hub: 'PTG',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 300,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'CHURCHILL Est/Lgd, Parow',
		Code: '7500    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'CINDA PARK Boxes',
		Code: '1463    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'CINDA PARK, Boksburg',
		Code: '1459    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'CINDERELLA, Boksburg',
		Code: '1459    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'CINTSA EAST',
		Code: '5275    ',
		Hub: 'ELS',
		Ring: 0,
		OpsHub: 'ELS',
		Dist: 10,
		AreaName: 'East London Central',
		Surch: null,
	},
	{
		PlaceName: 'CINTSA-OOS',
		Code: '5275    ',
		Hub: 'ELS',
		Ring: 0,
		OpsHub: 'ELS',
		Dist: 10,
		AreaName: 'East London Central',
		Surch: null,
	},
	{
		PlaceName: 'CITRUSDAL',
		Code: '7340    ',
		Hub: 'CPT',
		Ring: 1,
		OpsHub: 'CPT',
		Dist: 190,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'CITY DEEP Boxes',
		Code: '2049    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'CITY DEEP, Johannesburg',
		Code: '2001    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'CITY WEST, Johannesburg',
		Code: '2092    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'CLAIREWOOD, Eersterivier',
		Code: '7100    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 15,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'CLAIRWOOD, Durban',
		Code: '4052    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'CLAM HALL, Parow',
		Code: '7500    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'CLANOR',
		Code: '1351    ',
		Hub: 'JNB',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 310,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'CLANSTHAL, Umkomaas',
		Code: '4171',
		Hub: 'DUR',
		Ring: 1,
		OpsHub: 'DUR',
		Dist: 70,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'CLANWILLIAM',
		Code: '8135    ',
		Hub: 'CPT',
		Ring: 1,
		OpsHub: 'CPT',
		Dist: 250,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'CLARA ANNA FONTEIN, Durbanville',
		Code: '7550',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'CLARE Est/Lgd, Durban',
		Code: '4091',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'CLAREDON, Pietermaritzburg',
		Code: '3201    ',
		Hub: 'PMB',
		Ring: 1,
		OpsHub: 'PMB',
		Dist: 75,
		AreaName: 'Pietermaritzburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'CLAREINCH Boxes',
		Code: '7740    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'CLAREINCH Streets',
		Code: '7700    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'CLAREMONT, Cape Town',
		Code: '7708    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'CLAREMONT, Cape Town',
		Code: '7708    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'CLAREMONT, Johannesburg',
		Code: '2092    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'CLAREMONT, Pretoria',
		Code: '0082    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'CLARENDON Est/Lgd, Mowbra',
		Code: '7700    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'CLARENDON, Pietermaritzburg',
		Code: '3201    ',
		Hub: 'PMB',
		Ring: 1,
		OpsHub: 'PMB',
		Dist: 75,
		AreaName: 'Pietermaritzburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'CLARENS PARK, Edenvale',
		Code: '1609    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'CLARIDGE',
		Code: '3400    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 270,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'CLARKES Est/Lgd, Elsiesriver',
		Code: '7490    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'CLAUDIUS, Laudium',
		Code: '0037    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'CLAYFIELD PHASE 1',
		Code: '4068',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'CLAYFIELD PHASE 4',
		Code: '4068',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'CLAYFIELD, Phoenix',
		Code: '4068',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'CLAYVILLE & Ext/Uit 7 & 1',
		Code: '1665    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'CLAYVILLE EAST, Olifantsfontein',
		Code: '1665    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'CLAYVILLE, Olifantsfontein',
		Code: '1666    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'CLEARY Est/Lgd, Port Elizabeth',
		Code: '6059    ',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'CLELAND, Pietermaritzburg',
		Code: '3201    ',
		Hub: 'PMB',
		Ring: 1,
		OpsHub: 'PMB',
		Dist: 75,
		AreaName: 'Pietermaritzburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'CLERMONT, Pinetown',
		Code: '3610',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'CLERNAVILLE Boxes',
		Code: '3602    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'CLERNAVILLE, Pinetown',
		Code: '3610',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'CLEVEDEN, Johannesburg',
		Code: '2094    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'CLEVELAND Boxes',
		Code: '2022    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'CLEVELAND, Johannesburg',
		Code: '2094    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'CLEWER',
		Code: '1036    ',
		Hub: 'MBG',
		Ring: 3,
		OpsHub: 'JNB',
		Dist: 140,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'CLIFFDALE, Out West Durban',
		Code: '3700',
		Hub: 'DUR',
		Ring: 1,
		OpsHub: 'DUR',
		Dist: 60,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'CLIFTON PARK, East London',
		Code: '5247    ',
		Hub: 'ELS',
		Ring: 0,
		OpsHub: 'ELS',
		Dist: 10,
		AreaName: 'East London Central',
		Surch: null,
	},
	{
		PlaceName: 'CLIFTON PARK, Gillitts',
		Code: '3610',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'CLIFTON, Cape Town',
		Code: '8005',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'CLOCOLAN',
		Code: '9735    ',
		Hub: 'BFN',
		Ring: 4,
		OpsHub: 'BFN',
		Dist: 170,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'CLOETESVILLE Boxes',
		Code: '7611    ',
		Hub: 'CPT',
		Ring: 1,
		OpsHub: 'CPT',
		Dist: 60,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'CLOETESVILLE, Stellenbosch',
		Code: '7600    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 60,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'CLOVELLY, Fish Hoek',
		Code: '7975    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 60,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'CLOVERDENE, Putfontein',
		Code: '1513    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'CLUBVIEW EAST, Verwoerdburg',
		Code: '0157    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'CLUBVIEW WEST, Verwoerdburg',
		Code: '0157    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'CLUBVIEW, Fish Hoek',
		Code: '7975    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 60,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'CLUBVIEW, Verwoerdburg',
		Code: '0157    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'CLUBVILLE, Middelburg',
		Code: '1050    ',
		Hub: 'MBG',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 140,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'CLYDESDALE, Pretoria',
		Code: '0002    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'CLYNTON, Johannesburg',
		Code: '2196    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'COALVILLE',
		Code: '1033    ',
		Hub: 'MBG',
		Ring: 3,
		OpsHub: 'JNB',
		Dist: 140,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'COEGA',
		Code: '6100    ',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 30,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'COETZENBURG Boxes',
		Code: '7602    ',
		Hub: 'CPT',
		Ring: 1,
		OpsHub: 'CPT',
		Dist: 60,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'COETZENBURG, Stellenbosch',
		Code: '7600    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 60,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'COETZERSDAM',
		Code: '8611    ',
		Hub: 'BFN',
		Ring: 4,
		OpsHub: 'BFN',
		Dist: 350,
		AreaName: 'Bloemfontein Regional',
		Surch: 'Far Outlying',
	},
	{
		PlaceName: 'COFFEE BAY,Mqanduli',
		Code: '5082',
		Hub: 'ELS',
		Ring: 1,
		OpsHub: 'ELS',
		Dist: 180,
		AreaName: 'East London Regional',
		Surch: null,
	},
	{
		PlaceName: 'COFIMVABA',
		Code: '5380',
		Hub: 'ELS',
		Ring: 4,
		OpsHub: 'ELS',
		Dist: 120,
		AreaName: 'East London Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'COLBYN, Pretoria',
		Code: '0083    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'COLCHESTER',
		Code: '6175    ',
		Hub: 'PLZ',
		Ring: 1,
		OpsHub: 'PLZ',
		Dist: 50,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'COLDSTREAM',
		Code: '6311    ',
		Hub: 'PLZ',
		Ring: 4,
		OpsHub: 'PLZ',
		Dist: 230,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'COLENSO',
		Code: '3360    ',
		Hub: 'HSM',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 230,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'COLESBERG',
		Code: '5980    ',
		Hub: 'BFN',
		Ring: 4,
		OpsHub: 'BFN',
		Dist: 280,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'COLESKEPLAAS',
		Code: '6689    ',
		Hub: 'GRJ',
		Ring: 4,
		OpsHub: 'GRJ',
		Dist: 200,
		AreaName: 'George Regional',
		Surch: null,
	},
	{
		PlaceName: 'COLIGNY',
		Code: '2725    ',
		Hub: 'JNB',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 250,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'COLLEN GLEN,Port Elizabeth',
		Code: '6018',
		Hub: 'PLZ',
		Ring: 1,
		OpsHub: 'PLZ',
		Dist: 50,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'COLLERVILLE, Klerksdorp',
		Code: '2571    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 150,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'COLLONDALE, East London',
		Code: '5201    ',
		Hub: 'ELS',
		Ring: 0,
		OpsHub: 'ELS',
		Dist: 10,
		AreaName: 'East London Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'COLORADO PARK, Mitchells Plain',
		Code: '7785    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'COLVILLE, Kimberley',
		Code: '8301    ',
		Hub: 'KIM',
		Ring: 0,
		OpsHub: 'KIM',
		Dist: 20,
		AreaName: 'Kimberley Central',
		Surch: null,
	},
	{
		PlaceName: 'COMET, Boksburg',
		Code: '1459    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'COMMONDALE',
		Code: '2385    ',
		Hub: 'JNB',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 320,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'COMPTONVILLE, Johannesburg',
		Code: '2064    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'CONERA Boxes',
		Code: '9431    ',
		Hub: 'BFN',
		Ring: 2,
		OpsHub: 'BFN',
		Dist: 20,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'CONERA, Virginia (OFS)',
		Code: '9430    ',
		Hub: 'BFN',
		Ring: 2,
		OpsHub: 'BFN',
		Dist: 20,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'CONGELLA Boxes',
		Code: '4013    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'CONGELLA, Durban',
		Code: '4001    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'CONISTON PARK, Retreat',
		Code: '7945    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'CONISTON, Constantia',
		Code: '7800    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'CONISTON, Verulam',
		Code: '4340    ',
		Hub: 'DUR',
		Ring: 1,
		OpsHub: 'DUR',
		Dist: 50,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'CONNAUGHT Est/Lgd, Elsies',
		Code: '7490    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'CONSOLIDATED MAIN REEF, F',
		Code: '1709    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'CONSORT PARK, Retreat',
		Code: '7945    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'CONSTANTIA',
		Code: '7806    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'CONSTANTIA HEIGHTS, Cape Town',
		Code: '7806',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'CONSTANTIA HILL Est/Lgd,C',
		Code: '7800    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'CONSTANTIA KLOOF, Florida',
		Code: '1709    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'CONSTANTIA, Cape Town',
		Code: '7800    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'CONSTANTIA, East London',
		Code: '5247    ',
		Hub: 'ELS',
		Ring: 0,
		OpsHub: 'ELS',
		Dist: 10,
		AreaName: 'East London Central',
		Surch: null,
	},
	{
		PlaceName: 'CONSTANTIA, Lengau',
		Code: '9499    ',
		Hub: 'BFN',
		Ring: 3,
		OpsHub: 'BFN',
		Dist: 170,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'CONSTANTIAPARK, Pretoria',
		Code: '0010    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'CONSTANTIAVALE, Constantia',
		Code: '7800    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'CONTERMANSKLOOF',
		Code: '7441',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'CONVILLE, George',
		Code: '6529    ',
		Hub: 'GRJ',
		Ring: 0,
		OpsHub: 'GRJ',
		Dist: 10,
		AreaName: 'George Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'CONVILLE, George',
		Code: '6529',
		Hub: 'GRJ',
		Ring: 0,
		OpsHub: 'GRJ',
		Dist: 10,
		AreaName: 'George Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'COOKHOUSE',
		Code: '5820    ',
		Hub: 'PLZ',
		Ring: 4,
		OpsHub: 'PLZ',
		Dist: 180,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: "COOPER'S KLOOF",
		Code: '6007    ',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'CORLETT GARDENS, Johannesburg',
		Code: '2090    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'CORNUBIA, Mount Edgecombe',
		Code: '4339',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 20,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'CORNUTA, Retreat',
		Code: '7945    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'CORONA',
		Code: '0254    ',
		Hub: 'RBG',
		Ring: 2,
		OpsHub: 'JNB',
		Dist: 100,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'CORONATION',
		Code: '3107    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 250,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'CORONATIONVILLE, Johannesburg',
		Code: '2093    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'CORRIEMOOR, Johannesburg',
		Code: '2195    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'COSMO,Johannesburg',
		Code: '2188',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'COSTA SARDA, Knysna',
		Code: '6571',
		Hub: 'GRJ',
		Ring: 1,
		OpsHub: 'GRJ',
		Dist: 80,
		AreaName: 'George Regional',
		Surch: null,
	},
	{
		PlaceName: 'COTSWOLD & Ext/Uit, Port Elizabeth',
		Code: '6045    ',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'COTSWOLD Ext/Uit 2, Port Elizabeth',
		Code: '6045    ',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'COTTESLOE, Johannesburg',
		Code: '2092    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'COTTONDALE',
		Code: '1361    ',
		Hub: 'NLP',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 310,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'COUNTRY LIFE PARK, Cramerview',
		Code: '2060    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'COUNTRY VIEW EXT 1, Midrand',
		Code: '1687',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'COUNTRY VIEW EXT 3, Midrand',
		Code: '1687',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'COUNTRY VIEW, Midrand',
		Code: '1687',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'COURTRAI, Paarl',
		Code: '7646    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 60,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'COWDRAY PARK, Cramerview',
		Code: '2060    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: "COWIE'S HILL PARK, Pinetown",
		Code: '3610',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: "COWIE'S HILL, Pinetown",
		Code: '3610',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'CRADOCK',
		Code: '5880    ',
		Hub: 'PLZ',
		Ring: 1,
		OpsHub: 'PLZ',
		Dist: 260,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'CRADOCK PLACE, Port Elizabeth',
		Code: '6001    ',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'CRAIGAVON, Bryanston',
		Code: '2021    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'CRAIGBAIN Tsp/Dgd, Port Elizabeth',
		Code: '6001    ',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'CRAIGHALL Boxes',
		Code: '2024    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'CRAIGHALL PARK, Johannesburg',
		Code: '2196    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'CRAIGHALL, Johannesburg',
		Code: '2196    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'CRAMERVIEW, Bryanston',
		Code: '2060    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'CRAMOND',
		Code: '3420    ',
		Hub: 'DUR',
		Ring: 2,
		OpsHub: 'DUR',
		Dist: 100,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'CRANBROOKVALE, Verwoerdburg',
		Code: '0157    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'CRAVENBY Boxes',
		Code: '7508    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'CRAVENBY Est/Lgd, Elsiesriver',
		Code: '7490    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'CRAVENBY, Matroosfontein',
		Code: '7490    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'CRAWFORD Boxes',
		Code: '7770    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'CRAWFORD, Cape Town',
		Code: '7764    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'CRECY',
		Code: '0562    ',
		Hub: 'PRY',
		Ring: 4,
		OpsHub: 'PRY',
		Dist: 180,
		AreaName: 'Pretoria Regional',
		Surch: null,
	},
	{
		PlaceName: 'CREIGHTON',
		Code: '3263',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 200,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'CRESCENT WOOD COUNTRY ESTATE, Midrand',
		Code: '1685',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'CRESSLAWNS, Kempton Park',
		Code: '1619    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'CRESTA & Ext/Uit 2, Randburg',
		Code: '2194    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'CRESTA Boxes',
		Code: '2118    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'CRESTA, Johannesburg',
		Code: '2194    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'CRESTONHILL, Primrose',
		Code: '1401    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'CRESTVIEW, Hillcrest',
		Code: '3610',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'CRESTVIEW, Port Elizabeth',
		Code: '6025',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'CRESWELL PARK, Roodepoort',
		Code: '1724    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'CROESUS, Johannesburg',
		Code: '2093    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'CROFTDENE, Chatsworth',
		Code: '4092',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'CROMEVILLE, Middelburg',
		Code: '1050    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 140,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'CROSBY, Johannesburg',
		Code: '2092    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'CROSSMOOR, Chatsworth',
		Code: '4092',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'CROSSROADS, Cape Town',
		Code: '7753    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'CROSSROADS, Gatesville',
		Code: '7764    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'CROWN GARDENS, Johannesburg',
		Code: '2091    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'CROWN MINES Boxes',
		Code: '2025    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'CROWN MINES, Johannesburg',
		Code: '2092    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'CROWTHONE, Midrand',
		Code: '1682',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'CROYDON OLIVE ESTATE, Somerset West',
		Code: '7130',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 50,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'CROYDON VINEYARD ESTATE, Somerset West',
		Code: '7140',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 50,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'CROYDON, Kempton Park',
		Code: '1619    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'CRUYWAGENPARK, Elsburg',
		Code: '1407    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'CRYSBESTOS',
		Code: '1307    ',
		Hub: 'JNB',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 310,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'CRYSTAL GARDENS, Johannesburg',
		Code: '2090    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'CRYSTAL PARK',
		Code: '1515    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'CRYSTAL PARK Boxes',
		Code: '1515    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'CRYSTAL PARK Ext/Uit 2 &',
		Code: '1515    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'CULEMBEECK, Roodepoort',
		Code: '1724    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'CULEMBORGPARK, Randfontein',
		Code: '1760    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'CULLDENE, Cradock',
		Code: '5880    ',
		Hub: 'PLZ',
		Ring: 4,
		OpsHub: 'PLZ',
		Dist: 260,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'CULLINAN',
		Code: '1000    ',
		Hub: 'PRY',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 90,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'CUMBERWOOD Boxes',
		Code: '3235    ',
		Hub: 'DUR',
		Ring: 1,
		OpsHub: 'DUR',
		Dist: 100,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'CUMBERWOOD, Pietermaritzburg',
		Code: '3235',
		Hub: 'DUR',
		Ring: 1,
		OpsHub: 'DUR',
		Dist: 75,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'CW 1 - 6, Vanderbijlpark',
		Code: '1911    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 60,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'CYRILDENE Boxes',
		Code: '2026    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'CYRILDENE, Johannesburg',
		Code: '2198    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: "D'URBANVALE",
		Code: '7550',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'DA GAMA PARK, Simonstad',
		Code: '7995    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 70,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'DA GAMASKOP Boxes',
		Code: '6501    ',
		Hub: 'GRJ',
		Ring: 1,
		OpsHub: 'GRJ',
		Dist: 60,
		AreaName: 'George Regional',
		Surch: null,
	},
	{
		PlaceName: 'DA GAMASKOP, Mosselbaai',
		Code: '6506',
		Hub: 'GRJ',
		Ring: 1,
		OpsHub: 'GRJ',
		Dist: 60,
		AreaName: 'George Regional',
		Surch: null,
	},
	{
		PlaceName: 'DADAVILLE, Vereeniging',
		Code: '1939    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 60,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'DAGBREEK, Welkom',
		Code: '9459    ',
		Hub: 'WEL',
		Ring: 3,
		OpsHub: 'JNB',
		Dist: 150,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'DAGGAFONTEIN & Ext/Uit 1,',
		Code: '1559    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'DAGGAFONTEIN Boxes',
		Code: '1573    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'DAINFERN,Bryanston',
		Code: '2055',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'DAL FOUCHE, Springs',
		Code: '1559    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'DAL JOSAFAT, Paarl',
		Code: '7646    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 60,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'DALBRIDGE Boxes',
		Code: '4014    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'DALBRIDGE, Durban',
		Code: '4001    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'DALECROSS, Johannesburg',
		Code: '2196    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'DALPARK & Ext/Uit 1, 6 &',
		Code: '1540    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'DALPARK Boxes',
		Code: '1543    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'DALROPARK, Eersterivier',
		Code: '7100    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 15,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'DALSIG, Stellenbosch',
		Code: '7600    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 60,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'DALTON',
		Code: '3470    ',
		Hub: 'DUR',
		Ring: 1,
		OpsHub: 'DUR',
		Dist: 120,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'DALVIEW Boxes',
		Code: '1544    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'DALVIEW, Brakpan',
		Code: '1540    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'DAN PIENAAR, Bloemfontein',
		Code: '9301',
		Hub: 'BFN',
		Ring: 0,
		OpsHub: 'BFN',
		Dist: 30,
		AreaName: 'Bloemfontein Central',
		Surch: null,
	},
	{
		PlaceName: 'DAN PIENAARVILLE, Krugers',
		Code: '1739    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'DANA BAY, Mosselbay',
		Code: '6510    ',
		Hub: 'GRJ',
		Ring: 1,
		OpsHub: 'GRJ',
		Dist: 60,
		AreaName: 'George Regional',
		Surch: null,
	},
	{
		PlaceName: 'DANARAND, Kuilsrivier',
		Code: '7580    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'DANCKERTVILLE Boxes',
		Code: '7305    ',
		Hub: 'CPT',
		Ring: 1,
		OpsHub: 'CPT',
		Dist: 50,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'DANCKERTVILLE, Malmesbury',
		Code: '7300    ',
		Hub: 'CPT',
		Ring: 1,
		OpsHub: 'CPT',
		Dist: 60,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'DANENA, Bellville',
		Code: '7550',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'DANHOF Boxes',
		Code: '9310    ',
		Hub: 'BFN',
		Ring: 0,
		OpsHub: 'BFN',
		Dist: 30,
		AreaName: 'Bloemfontein Central',
		Surch: null,
	},
	{
		PlaceName: 'DANHOF, Bloemfontein',
		Code: '9301',
		Hub: 'BFN',
		Ring: 0,
		OpsHub: 'BFN',
		Dist: 30,
		AreaName: 'Bloemfontein Central',
		Surch: null,
	},
	{
		PlaceName: 'DANIA PARK, Primrose',
		Code: '1401    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'DANIEL BRINKPARK, Randburg',
		Code: '2194    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'DANIELSKUIL',
		Code: '8405    ',
		Hub: 'KUR',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 410,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'DANNHAUSER',
		Code: '3080    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 320,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'DANVILLE, Pretoria',
		Code: '0183    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'DARGLE',
		Code: '3265    ',
		Hub: 'DUR',
		Ring: 3,
		OpsHub: 'DUR',
		Dist: 130,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'DARLING',
		Code: '7345    ',
		Hub: 'CPT',
		Ring: 1,
		OpsHub: 'CPT',
		Dist: 80,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'DARNALL',
		Code: '4480    ',
		Hub: 'DUR',
		Ring: 3,
		OpsHub: 'DUR',
		Dist: 140,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'DARRENWOOD & Ext/Uit 2, R',
		Code: '2194    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'DASPOORT, Pretoria',
		Code: '0082    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'DASSENBERG Boxes',
		Code: '7350    ',
		Hub: 'CPT',
		Ring: 1,
		OpsHub: 'CPT',
		Dist: 50,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'DASSENBERG Streets',
		Code: '7349    ',
		Hub: 'CPT',
		Ring: 1,
		OpsHub: 'CPT',
		Dist: 50,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'DASSENHOEK, Nagina',
		Code: '3604    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'DASSENHOEK, Pinetown',
		Code: '3610',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'DASSIERAND, Potchefstroom',
		Code: '2520    ',
		Hub: 'POT',
		Ring: 3,
		OpsHub: 'JNB',
		Dist: 150,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'DAVANNA, Klerksdorp',
		Code: '2571    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 150,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'DAVENPORT',
		Code: '4001    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'DAVEYTON, Benoni',
		Code: '1507',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'DAVIDSONVILLE & Ext/Uit 2',
		Code: '1724    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'DAVIDSONVILLE NORTH, Roodeport',
		Code: '1724    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'DAVIDSONVILLE-NOORD, Roodeport',
		Code: '1724    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'DAWID MALANVILLE, Kroonstad',
		Code: '9499    ',
		Hub: 'BFN',
		Ring: 3,
		OpsHub: 'BFN',
		Dist: 170,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'DAWIDSBURG, Welkom',
		Code: '9459    ',
		Hub: 'WEL',
		Ring: 3,
		OpsHub: 'JNB',
		Dist: 150,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'DAWKINSVILLE, Klerksdorp',
		Code: '2571    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 150,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'DAWN CREST, Verulam',
		Code: '4340    ',
		Hub: 'DUR',
		Ring: 1,
		OpsHub: 'DUR',
		Dist: 50,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'DAWN CREST, Westville',
		Code: '3629',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'DAWN PARK & Ext/Uit 2, 4,',
		Code: '1459    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'DAWN VIEW, Primrose',
		Code: '1401    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'DAWN, East London',
		Code: '5247    ',
		Hub: 'ELS',
		Ring: 0,
		OpsHub: 'ELS',
		Dist: 10,
		AreaName: 'East London Central',
		Surch: null,
	},
	{
		PlaceName: 'DAWNCLIFFE, Westville',
		Code: '3629',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'DAYANGLEN, Boksburg',
		Code: '1459    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'DE AAR',
		Code: '7000    ',
		Hub: 'KIM',
		Ring: 1,
		OpsHub: 'KIM',
		Dist: 500,
		AreaName: 'Kimberley Regional',
		Surch: null,
	},
	{
		PlaceName: 'DE BEERS, Kimberley',
		Code: '8301    ',
		Hub: 'KIM',
		Ring: 0,
		OpsHub: 'KIM',
		Dist: 20,
		AreaName: 'Kimberley Central',
		Surch: null,
	},
	{
		PlaceName: 'DE BEERS, Pretoria',
		Code: '0181    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'DE BEERSHOOGTE, Barkly West',
		Code: '8375    ',
		Hub: 'BFN',
		Ring: 4,
		OpsHub: 'BFN',
		Dist: 230,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'DE BRON, Bellville',
		Code: '7530',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'DE BRUG',
		Code: '9928    ',
		Hub: 'BFN',
		Ring: 1,
		OpsHub: 'BFN',
		Dist: 50,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'DE BRUINPARK, Germiston',
		Code: '1401    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'DE CLERQVILLE, Klerksdorp',
		Code: '2571    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 150,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'DE DEUR',
		Code: '1884    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 30,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'DE DOORNS',
		Code: '6875    ',
		Hub: 'CPT',
		Ring: 3,
		OpsHub: 'CPT',
		Dist: 150,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'DE DUIN, Parow',
		Code: '7500    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'DE HOEK',
		Code: '7321',
		Hub: 'CPT',
		Ring: 1,
		OpsHub: 'CPT',
		Dist: 50,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'DE KELDERS',
		Code: '7220',
		Hub: 'CPT',
		Ring: 1,
		OpsHub: 'CPT',
		Dist: 162,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'DE KLERKSHOF, Edenvale',
		Code: '1609',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'DE KUILEN, Kuilsrivier',
		Code: '7580    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'DE MIST, Uitenhage',
		Code: '6230    ',
		Hub: 'PLZ',
		Ring: 1,
		OpsHub: 'PLZ',
		Dist: 50,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'DE OUDE SPRUIT, Brackenfell',
		Code: '7579',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'DE RUST',
		Code: '6650    ',
		Hub: 'GRJ',
		Ring: 1,
		OpsHub: 'GRJ',
		Dist: 60,
		AreaName: 'George Regional',
		Surch: null,
	},
	{
		PlaceName: 'DE TIJGER',
		Code: '7502    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'DE TIJGERPARK, Parow',
		Code: '7500    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'DE WATERKANT, Cape Town',
		Code: '8001',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'DE WET',
		Code: '6853    ',
		Hub: 'CPT',
		Ring: 2,
		OpsHub: 'CPT',
		Dist: 120,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'DE WILDT',
		Code: '0251    ',
		Hub: 'JNB',
		Ring: 2,
		OpsHub: 'JNB',
		Dist: 90,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'DE ZALZE, Stellenbosch',
		Code: '7600',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 30,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'DEACONVILLE, Macassar',
		Code: '7130    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 30,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'DEAL PARTY, Port Elizabeth',
		Code: '6001    ',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'DEALESVILLE',
		Code: '9348    ',
		Hub: 'BFN',
		Ring: 1,
		OpsHub: 'BFN',
		Dist: 30,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'DEELKRAAL, Carletonville',
		Code: '2500    ',
		Hub: 'POT',
		Ring: 2,
		OpsHub: 'JNB',
		Dist: 100,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'DEEMOUNT',
		Code: '4681    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 180,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'DEEP SOUTH',
		Code: '2536    ',
		Hub: 'JNB',
		Ring: 3,
		OpsHub: 'JNB',
		Dist: 150,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'DEEPDALE',
		Code: '4521    ',
		Hub: 'DUR',
		Ring: 2,
		OpsHub: 'DUR',
		Dist: 100,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'DEEPMEADOW, Meadowlands',
		Code: '1852    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 30,
		AreaName: 'Johannesburg Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'DEER PARK',
		Code: '0852    ',
		Hub: 'JNB',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 300,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'DEERNESS, Pretoria',
		Code: '0084    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'DEFENCE FORCE AREA, Pretoria',
		Code: '0002    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'DEJERRING HEIGHTS, Pietermaritzburg',
		Code: '3201    ',
		Hub: 'PMB',
		Ring: 1,
		OpsHub: 'PMB',
		Dist: 75,
		AreaName: 'Pietermaritzburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'DEKLERKSHOF, Edenvale',
		Code: '1609    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'DEL JUDOR & Ext/Uit 1,',
		Code: '1035    ',
		Hub: 'MBG',
		Ring: 3,
		OpsHub: 'JNB',
		Dist: 140,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'DELAHAYE Est/Lgd, Bellville',
		Code: '7530    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'DELAREY, Florida',
		Code: '1709    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'DELAREYVILLE',
		Code: '2770    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 360,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'DELFT, Eersterivier',
		Code: '7100    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 15,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'DELMAS',
		Code: '2210    ',
		Hub: 'MBG',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 50,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'DELMENVILLE Boxes',
		Code: '1403    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'DELMENVILLE, Germiston',
		Code: '1401    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'DELMORE',
		Code: '1404    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'DELPORTON, Krugersdorp',
		Code: '1739    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'DELPORTSHOOP',
		Code: '8377    ',
		Hub: 'BFN',
		Ring: 4,
		OpsHub: 'BFN',
		Dist: 300,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'DELVILLE PARK, Pacaltsdorp',
		Code: '6529',
		Hub: 'GRJ',
		Ring: 0,
		OpsHub: 'GRJ',
		Dist: 10,
		AreaName: 'George Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'DELVILLE, Germiston',
		Code: '1401    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'DELVILLE, Pacaltsdorp',
		Code: '6529',
		Hub: 'GRJ',
		Ring: 0,
		OpsHub: 'GRJ',
		Dist: 10,
		AreaName: 'George Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'DEMAT, Chatsworth',
		Code: '4092',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'DENDRON',
		Code: '0715    ',
		Hub: 'PTG',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 400,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'DENEYSVILLE',
		Code: '1932    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 60,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'DENLEE, Germiston',
		Code: '1401    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'DENNE-OORD, George',
		Code: '6529    ',
		Hub: 'GRJ',
		Ring: 0,
		OpsHub: 'GRJ',
		Dist: 10,
		AreaName: 'George Central',
		Surch: null,
	},
	{
		PlaceName: 'DENNEBURG, Paarl',
		Code: '7646    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 60,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'DENNEDAL, Tokai',
		Code: '7945    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'DENNEGEUR, Somerset West',
		Code: '7135',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 50,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'DENNEHOF, Johannesburg',
		Code: '2196    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'DENNEMERE, Blackheath',
		Code: '7581    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'DENNESIG Boxes',
		Code: '7601    ',
		Hub: 'CPT',
		Ring: 1,
		OpsHub: 'CPT',
		Dist: 60,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'DENNESIG, Middelburg',
		Code: '1050    ',
		Hub: 'MBG',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 140,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'DENNESIG, Stellenbosch',
		Code: '7600    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 60,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'DENNILTON',
		Code: '1030    ',
		Hub: 'PRY',
		Ring: 2,
		OpsHub: 'PRY',
		Dist: 90,
		AreaName: 'Pretoria Regional',
		Surch: null,
	},
	{
		PlaceName: 'DENNILTON, Groblersdal',
		Code: '1030    ',
		Hub: 'PRY',
		Ring: 1,
		OpsHub: 'PRY',
		Dist: 0,
		AreaName: 'Pretoria Regional',
		Surch: null,
	},
	{
		PlaceName: 'DENNY DALTON',
		Code: '3837    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 250,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'DENVER Boxes',
		Code: '2027    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'DENVER PARK, George',
		Code: '6529',
		Hub: 'GRJ',
		Ring: 0,
		OpsHub: 'GRJ',
		Dist: 10,
		AreaName: 'George Central',
		Surch: null,
	},
	{
		PlaceName: 'DENVER, Johannesburg',
		Code: '2094    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'DERBY',
		Code: '2820    ',
		Hub: 'RBG',
		Ring: 3,
		OpsHub: 'JNB',
		Dist: 150,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'DERDEPOORT, Pretoria',
		Code: '0002    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 50,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'DERSLEY Boxes',
		Code: '1569    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'DERSLEY, Springs',
		Code: '1559    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'DERWENT Est/Lgd, Ottery',
		Code: '7800    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'DES HAMPTON, Kuilsrivier',
		Code: '7580    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'DESAINAGAR',
		Code: '4405    ',
		Hub: 'DUR',
		Ring: 1,
		OpsHub: 'DUR',
		Dist: 80,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'DESAINAGAR, Tongaat',
		Code: '4399',
		Hub: 'DUR',
		Ring: 1,
		OpsHub: 'DUR',
		Dist: 70,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'DESPATCH',
		Code: '6220',
		Hub: 'PLZ',
		Ring: 1,
		OpsHub: 'PLZ',
		Dist: 30,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: "DEVIL'S PEAK",
		Code: '8001',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: "DEVIL'S PEAK ESTATE, Cape Town",
		Code: '8001',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'DEVLAND, Johannesburg',
		Code: '2013    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'DEVON PARK, Eersterivier',
		Code: '7100    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 15,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'DEVONDALE',
		Code: '8602    ',
		Hub: 'BFN',
		Ring: 4,
		OpsHub: 'BFN',
		Dist: 340,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'DEWALT HATTINGHPARK, Benoni',
		Code: '1501    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'DEWETSDORP',
		Code: '9940    ',
		Hub: 'BFN',
		Ring: 2,
		OpsHub: 'BFN',
		Dist: 90,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'DEWETSHOF, Johannesburg',
		Code: '2198    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'DEWITSRUS, Germiston',
		Code: '1401    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'DF MALAN AIRPORT',
		Code: '7525    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'DF MALAN INDUSTRIAL AREA,',
		Code: '7490    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'DF MALAN INDUSTRIELE GEBI',
		Code: '7490    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'DF MALANLUGHAWE',
		Code: '7525    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'DIAZVILLE Boxes',
		Code: '7397    ',
		Hub: 'CPT',
		Ring: 3,
		OpsHub: 'CPT',
		Dist: 150,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'DIAZVILLE, Saldanha',
		Code: '7395    ',
		Hub: 'CPT',
		Ring: 3,
		OpsHub: 'CPT',
		Dist: 150,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'DIBASABOPHELO',
		Code: '0457    ',
		Hub: 'PRY',
		Ring: 4,
		OpsHub: 'PRY',
		Dist: 180,
		AreaName: 'Pretoria Regional',
		Surch: null,
	},
	{
		PlaceName: 'DIBENG',
		Code: '8463    ',
		Hub: 'KUR',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 440,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: "DIDO VALLEY, Simon's Town",
		Code: '7995    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 70,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'DIDOVALLEI, Simonstad',
		Code: '7995    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 70,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'DIE BOORD, Stellenbosch',
		Code: '7600    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 60,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'DIE BULTE, Vredendal',
		Code: '8160    ',
		Hub: 'CPT',
		Ring: 4,
		OpsHub: 'CPT',
		Dist: 320,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'DIE HEUWEL, Witbank',
		Code: '1042    ',
		Hub: 'JNB',
		Ring: 3,
		OpsHub: 'JNB',
		Dist: 140,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'DIE WILGERS, Pretoria',
		Code: '0041    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'DIE WINGERD, Somerset West',
		Code: '7130    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 30,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'DIEP RIVER Boxes',
		Code: '7856    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'DIEP RIVER, Cape Town',
		Code: '7800    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'DIEPKLOOF & Ext/Uit, Orla',
		Code: '1804    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 50,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'DIEPKLOOF Boxes',
		Code: '1864    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 30,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'DIEPKLOOF HOSTEL, Orlando',
		Code: '1804    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 50,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'DIEPKLOOF ZONE/SONE 1 - 1',
		Code: '1804    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 50,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'DIEPKLOOF,Johannesburg',
		Code: '1862',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 30,
		AreaName: 'Johannesburg Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'DIEPRIVIER Busse',
		Code: '7856    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'DIEPRIVIER Strate',
		Code: '7800    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'DIEPSLOOT',
		Code: '2189',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'DIFATENG',
		Code: '2560    ',
		Hub: 'JNB',
		Ring: 3,
		OpsHub: 'JNB',
		Dist: 150,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'DIJONG',
		Code: '2556    ',
		Hub: 'JNB',
		Ring: 3,
		OpsHub: 'JNB',
		Dist: 150,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'DIKGAKENG',
		Code: '9872    ',
		Hub: 'BFN',
		Ring: 4,
		OpsHub: 'BFN',
		Dist: 270,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'DIKGALE',
		Code: '0721    ',
		Hub: 'PTG',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 300,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'DIMBAZA',
		Code: '5671',
		Hub: 'ELS',
		Ring: 4,
		OpsHub: 'ELS',
		Dist: 120,
		AreaName: 'East London Regional',
		Surch: null,
	},
	{
		PlaceName: 'DINGLETON',
		Code: '8445    ',
		Hub: 'KUR',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 500,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'DINWIDDIE Boxes',
		Code: '1405    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'DINWIDDIE, Germiston',
		Code: '1401    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'DISCOVERY & Ext/Uit 11,',
		Code: '1709    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'DISKOBOLOS',
		Code: '8325    ',
		Hub: 'KIM',
		Ring: 0,
		OpsHub: 'KIM',
		Dist: 20,
		AreaName: 'Kimberley Central',
		Surch: null,
	},
	{
		PlaceName: 'DISTRIKDORP, Krugersdorp',
		Code: '1739    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'DLALELANI',
		Code: '1770    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'DLAMINI & Ext/Uit 1 - 10,',
		Code: '1818    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 30,
		AreaName: 'Johannesburg Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'DLOLWANA',
		Code: '3554    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 200,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'DOBSONVILLE',
		Code: '1865    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 30,
		AreaName: 'Johannesburg Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'DOBSONVILLE Ext/Uit 2, Dobsonvile',
		Code: '1865    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 30,
		AreaName: 'Johannesburg Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'DOBSONVILLE Ext/Uit, Dobsonville',
		Code: '1865    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 30,
		AreaName: 'Johannesburg Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'DOMINIONVILLE',
		Code: '2578    ',
		Hub: 'JNB',
		Ring: 3,
		OpsHub: 'JNB',
		Dist: 150,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'DONKIN HILL',
		Code: '6010    ',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'DONNYBROOK',
		Code: '4560    ',
		Hub: 'DUR',
		Ring: 3,
		OpsHub: 'DUR',
		Dist: 150,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'DOON HEIGHTS, Amanzimtoti',
		Code: '4126',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'DOONSIDE Boxes',
		Code: '4135    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'DOONSIDE, Amanzimtoti',
		Code: '4126',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'DOONSIDE,Durban',
		Code: '4126',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 70,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'DOOR-DE-KRAAL, Bellville',
		Code: '7530    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'DOORDRIFT Est/Lgd, Plumstead',
		Code: '7800    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'DOORN, Welkom',
		Code: '9459    ',
		Hub: 'WEL',
		Ring: 3,
		OpsHub: 'JNB',
		Dist: 150,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'DOORNFONTEIN Boxes',
		Code: '2028    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'DOORNFONTEIN NORTH, Johannesburg',
		Code: '2094    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'DOORNFONTEIN, Johannesburg',
		Code: '2094    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'DOORNFONTEIN-NOORD, Johannesburg',
		Code: '2094    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'DOORNHOOGTE Est/Lgd, Athlone',
		Code: '7764    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'DOORNKLOOF, Moorreesburg',
		Code: '7310    ',
		Hub: 'CPT',
		Ring: 2,
		OpsHub: 'CPT',
		Dist: 100,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'DOORNKOP Boxes',
		Code: '1723    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'DOORNKOP, Kearsney',
		Code: '4453    ',
		Hub: 'DUR',
		Ring: 3,
		OpsHub: 'DUR',
		Dist: 140,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'DOORNKOP, Roodepoort',
		Code: '1724    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'DOORNKOP,Donsonville',
		Code: '1874',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 30,
		AreaName: 'Johannesburg Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'DOORNPOORT',
		Code: '0186    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'DOORNPOORT, Pretoria',
		Code: '0017    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'DORANDIA & Ext/Uit 10 & 1',
		Code: '0182    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'DORBANK',
		Code: '6622    ',
		Hub: 'GRJ',
		Ring: 1,
		OpsHub: 'GRJ',
		Dist: 70,
		AreaName: 'George Regional',
		Surch: null,
	},
	{
		PlaceName: 'DORCHESTER HEIGHTS, East London',
		Code: '5247    ',
		Hub: 'ELS',
		Ring: 0,
		OpsHub: 'ELS',
		Dist: 10,
		AreaName: 'East London Central',
		Surch: null,
	},
	{
		PlaceName: 'DORDRECHT',
		Code: '5435    ',
		Hub: 'ELS',
		Ring: 4,
		OpsHub: 'ELS',
		Dist: 270,
		AreaName: 'East London Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'DORELAN, Johannesburg',
		Code: '2090    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'DORHILL Est/Lgd, Somerset West',
		Code: '7130    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 30,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'DORING BAY',
		Code: '8151    ',
		Hub: 'CPT',
		Ring: 4,
		OpsHub: 'CPT',
		Dist: 300,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'DORINGBAAI',
		Code: '8151    ',
		Hub: 'CPT',
		Ring: 4,
		OpsHub: 'CPT',
		Dist: 300,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'DORINGBOS',
		Code: '8137    ',
		Hub: 'CPT',
		Ring: 4,
		OpsHub: 'CPT',
		Dist: 290,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'DORINGKLOOF, Verwoerdburg',
		Code: '0157    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'DORINGKOP, Stanger',
		Code: '4449',
		Hub: 'DUR',
		Ring: 1,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'DORINGKRUIN, Klerksdorp',
		Code: '2576    ',
		Hub: 'JNB',
		Ring: 3,
		OpsHub: 'JNB',
		Dist: 150,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'DORINGPOORT',
		Code: '2827    ',
		Hub: 'RBG',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 180,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'DORMEHLSDRIFT, George',
		Code: '6529',
		Hub: 'GRJ',
		Ring: 0,
		OpsHub: 'GRJ',
		Dist: 10,
		AreaName: 'George Central',
		Surch: null,
	},
	{
		PlaceName: 'DORMERTON Boxes',
		Code: '4015    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'DORMERTON, Durban',
		Code: '4091',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'DORPSPRUIT Boxes',
		Code: '3206    ',
		Hub: 'DUR',
		Ring: 2,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'DORPSPRUIT, Pietermaritzburg',
		Code: '3201    ',
		Hub: 'PMB',
		Ring: 1,
		OpsHub: 'PMB',
		Dist: 75,
		AreaName: 'Pietermaritzburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'DOUGLAS',
		Code: '8730    ',
		Hub: 'KIM',
		Ring: 4,
		OpsHub: 'KIM',
		Dist: 320,
		AreaName: 'Kimberley Regional',
		Surch: null,
	},
	{
		PlaceName: 'DOUGLASDALE Ext/Uit 13, 1',
		Code: '2055    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'DOUGLASDALE Ext/Uit 4, Sloane Park',
		Code: '2152    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'DOUGLASDALE Ext/Uit 9, Cramerview',
		Code: '2060    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'DOUGLASDALE, Bryanston',
		Code: '2021    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'DOVEHOUSE, Gillitts',
		Code: '3610',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'DOWERGLEN, Edenvale',
		Code: '1609    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'DOWERVILLE, Port Elizabeth',
		Code: '6001    ',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'DR BRAUN Tsp/Dgd, Uitenhage',
		Code: '6230    ',
		Hub: 'PLZ',
		Ring: 1,
		OpsHub: 'PLZ',
		Dist: 50,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'DRAAIKRAAL',
		Code: '1112    ',
		Hub: 'NLP',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 260,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'DRAGON PEAKS',
		Code: '3331    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 200,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'DRAKENSBURG',
		Code: '3340',
		Hub: 'DUR',
		Ring: 1,
		OpsHub: 'DUR',
		Dist: 210,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'DRAYCOTT',
		Code: '3312    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 190,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'DREW',
		Code: '6741    ',
		Hub: 'CPT',
		Ring: 4,
		OpsHub: 'CPT',
		Dist: 230,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'DREYERSDAL Est/Lgd, Bergvliet',
		Code: '7945    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'DRIE RIVIERE',
		Code: '1935    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 60,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'DRIE RIVIERE & Uit 1,',
		Code: '1939    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 60,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'DRIE RIVIERE-OOS,',
		Code: '1941    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 60,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'DRIEANKERBAAI',
		Code: '8005',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'DRIEFONTEIN, Boksburg',
		Code: '1459    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'DRIEFONTEIN, Muldersdrif',
		Code: '1747    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'DRIEFONTEIN, Randburg',
		Code: '2194    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'DRIEFONVILLE',
		Code: '2506    ',
		Hub: 'JNB',
		Ring: 2,
		OpsHub: 'JNB',
		Dist: 100,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'DRIEHOEK, Germiston',
		Code: '1401    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'DRIEKOP',
		Code: '1129    ',
		Hub: 'MPG',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 300,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'DRIFTSANDS, Port Elizabeth',
		Code: '6001    ',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'DRIVE INN, Tongaat',
		Code: '4399',
		Hub: 'DUR',
		Ring: 1,
		OpsHub: 'DUR',
		Dist: 70,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'DROEVLAKTE',
		Code: '6773    ',
		Hub: 'GRJ',
		Ring: 4,
		OpsHub: 'GRJ',
		Dist: 125,
		AreaName: 'George Regional',
		Surch: null,
	},
	{
		PlaceName: 'DROSTDY',
		Code: '6822    ',
		Hub: 'CPT',
		Ring: 2,
		OpsHub: 'CPT',
		Dist: 100,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'DROSTDY PARK, Kuilsrivier',
		Code: '7580    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'DROSTE PARK, Johannesburg',
		Code: '2094    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'DRUIWEVLEI, Manenberg',
		Code: '7764    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'DRUMBLAIR, Retreat',
		Code: '7945    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: "DRUMMOND, Botha's Hill",
		Code: '3660    ',
		Hub: 'DUR',
		Ring: 1,
		OpsHub: 'DUR',
		Dist: 30,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'DRUSANA Boxes',
		Code: '9311    ',
		Hub: 'BFN',
		Ring: 0,
		OpsHub: 'BFN',
		Dist: 30,
		AreaName: 'Bloemfontein Central',
		Surch: null,
	},
	{
		PlaceName: 'DU TOIT/STA, Stellenbosch',
		Code: '7600    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 60,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'DUBE & Ext/Uit, Meadowlands',
		Code: '1852    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 30,
		AreaName: 'Johannesburg Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'DUBE HOSTEL, Meadowlands',
		Code: '1852    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 30,
		AreaName: 'Johannesburg Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'DUBE VILLAGE, Meadowlands',
		Code: '1852    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 30,
		AreaName: 'Johannesburg Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'DUBE VILLAGE, Soweto',
		Code: '1800',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 50,
		AreaName: 'Johannesburg Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'DUDUZA, (Boxes)',
		Code: '1494    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'DUDUZA, Nigel',
		Code: '1490    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 45,
		AreaName: 'Johannesburg Regional',
		Surch: 'Township',
	},
	{
		PlaceName: "DUFF'S ROAD, Durban North",
		Code: '4051',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'DUFFIELD, Lichtenburg',
		Code: '2740    ',
		Hub: 'NWP',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 270,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'DUFFWEG, Durban',
		Code: '4051',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'DUINEFONTEIN, Manenberg',
		Code: '7764    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'DUIWELSKLOOF',
		Code: '0835    ',
		Hub: 'PTG',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 300,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'DUKATHOLE Boxes',
		Code: '5531    ',
		Hub: 'ELS',
		Ring: 4,
		OpsHub: 'ELS',
		Dist: 360,
		AreaName: 'East London Regional',
		Surch: null,
	},
	{
		PlaceName: 'DUKATHOLE, Aliwal North',
		Code: '5530    ',
		Hub: 'ELS',
		Ring: 4,
		OpsHub: 'BFN',
		Dist: 360,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'DUKUDUKU, Mtubatuba',
		Code: '3936',
		Hub: 'DUR',
		Ring: 1,
		OpsHub: 'DUR',
		Dist: 300,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'DULLSTROOM',
		Code: '1110    ',
		Hub: 'MPG',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 250,
		AreaName: 'Johannesburg Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'DUNCANVILLE, Vereeniging',
		Code: '1939    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 60,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'DUNDEE',
		Code: '3000    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 300,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'DUNHILL, Johannesburg',
		Code: '2192    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'DUNKELD WEST, Johannesburg',
		Code: '2196    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'DUNKELD, Johannesburg',
		Code: '2196    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'DUNMADELEY, Boksburg',
		Code: '1459    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'DUNNOTTAR, Nigel',
		Code: '1496',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'DUNOON, Cape Town',
		Code: '7441',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'DUNSEVERN, Johannesburg',
		Code: '2090    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'DUNSWART Boxes',
		Code: '1508    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'DUNSWART, Benoni',
		Code: '1501    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'DUNVEGAN, Edenvale',
		Code: '1609    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'DUNVERIA, Pietermaritzburg',
		Code: '3201    ',
		Hub: 'PMB',
		Ring: 1,
		OpsHub: 'PMB',
		Dist: 75,
		AreaName: 'Pietermaritzburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'DURBAN CENTRAL',
		Code: '4001    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'DURBAN DEEP, Roodepoort',
		Code: '1724    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'DURBAN NORTH',
		Code: '4051',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'DURBAN VILLAS, Durbanville',
		Code: '7550    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'DURBAN-NOORD, Durban',
		Code: '4051',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'DURBANVILLE COUNTRY ESTSATE, Durbanville',
		Code: '7550',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'DURBANVILLE Ext/Uit 13,',
		Code: '7550    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'DURBANVILLE HILLS, Durbanville',
		Code: '7550    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'DURBANVILLE INDUSTRIA, Durbanville',
		Code: '7550',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'DURBANVILLE MEADOWS, Durbanville',
		Code: '7550',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'DURBANVILLE, Cape Town',
		Code: '7550    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'DURBELL, Durbanville',
		Code: '7550    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'DURMONTE, Durbanville',
		Code: '7550',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'DURNACOL',
		Code: '3082    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 300,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'DURRHEIM Boxes',
		Code: '7491    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'DURRHEIM, Matroosfontein',
		Code: '7490    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'DUXBERRY,Johannesburg',
		Code: '2191',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'DUXBURY, Riverclub',
		Code: '2149    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'DUYNEFONTEIN, Melkbosstrand',
		Code: '7441    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'DWAALBOOM',
		Code: '0319    ',
		Hub: 'PRY',
		Ring: 2,
		OpsHub: 'PRY',
		Dist: 120,
		AreaName: 'Pretoria Regional',
		Surch: null,
	},
	{
		PlaceName: 'DWARS RIVER',
		Code: '0812    ',
		Hub: 'MPG',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 300,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'DWARSKERSBOS',
		Code: '7365',
		Hub: 'CPT',
		Ring: 1,
		OpsHub: 'CPT',
		Dist: 175,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'DWARSRIVIER',
		Code: '0812    ',
		Hub: 'MPG',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 300,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'DWESHULA',
		Code: '4247    ',
		Hub: 'DUR',
		Ring: 2,
		OpsHub: 'DUR',
		Dist: 110,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'DYASONSKLIP',
		Code: '8805    ',
		Hub: 'KIM',
		Ring: 3,
		OpsHub: 'BFN',
		Dist: 25,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'DYNAMITE FACTORY',
		Code: '7120    ',
		Hub: 'CPT',
		Ring: 1,
		OpsHub: 'CPT',
		Dist: 30,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'DYSSELSDORP',
		Code: '6628    ',
		Hub: 'GRJ',
		Ring: 1,
		OpsHub: 'GRJ',
		Dist: 60,
		AreaName: 'George Regional',
		Surch: null,
	},
	{
		PlaceName: 'DZANANI, Sibasa',
		Code: '0955',
		Hub: 'PTG',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 200,
		AreaName: 'Johannesburg Regional',
		Surch: 'Township',
	},
	{
		PlaceName: "EAGLE'S NEST, Plumstead",
		Code: '7800    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'EARLS COURT, George',
		Code: '6529',
		Hub: 'GRJ',
		Ring: 0,
		OpsHub: 'GRJ',
		Dist: 10,
		AreaName: 'George Central',
		Surch: null,
	},
	{
		PlaceName: 'EARLSFIELD, Durban',
		Code: '4037',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'EAST BANK, Johannesburg',
		Code: '2090    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'EAST END, DURBAN',
		Code: '4018    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'EAST HAVEN, Retreat',
		Code: '7945    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'EAST LONDON',
		Code: '5201    ',
		Hub: 'ELS',
		Ring: 0,
		OpsHub: 'ELS',
		Dist: 10,
		AreaName: 'East London Central',
		Surch: null,
	},
	{
		PlaceName: 'EAST LONDON Boxes',
		Code: '5200    ',
		Hub: 'ELS',
		Ring: 0,
		OpsHub: 'ELS',
		Dist: 10,
		AreaName: 'East London Central',
		Surch: null,
	},
	{
		PlaceName: 'EAST LYNNE, Pretoria',
		Code: '0039',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'EAST PARK, Kagiso',
		Code: '1744    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'EAST QUEENSBURGH Est/Lgd,',
		Code: '4093',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'EAST RAND Boxes',
		Code: '1462    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'EAST RAND, Boksburg',
		Code: '1459    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'EAST ROCK, Durbanville',
		Code: '7550    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'EAST TOWN, Johannesburg',
		Code: '2195    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'EAST VILLAGE, Boksburg',
		Code: '1459    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'EASTBURY, Phoenix',
		Code: '4068',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'EASTCLIFF, Hermanus',
		Code: '7200    ',
		Hub: 'CPT',
		Ring: 2,
		OpsHub: 'CPT',
		Dist: 100,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'EASTCLIFF, Johannesburg',
		Code: '2190    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'EASTDENE, Middelburg',
		Code: '1050    ',
		Hub: 'MBG',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 140,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'EASTERN EXTENTION, George',
		Code: '6529    ',
		Hub: 'GRJ',
		Ring: 0,
		OpsHub: 'GRJ',
		Dist: 10,
		AreaName: 'George Central',
		Surch: null,
	},
	{
		PlaceName: 'EASTERN EXTENTION, Krugersdorp',
		Code: '1739    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'EASTFIELD, Rusloo',
		Code: '1468    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'EASTGATE Boxes',
		Code: '2103    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'EASTGATE EXT',
		Code: '2090    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'EASTGATE EXT 11, 12, 13',
		Code: '2090    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'EASTGATE EXT 18',
		Code: '2090    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'EASTGATE EXT 3, 4, 6,',
		Code: '2148',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'EASTGATE EXT 8',
		Code: '2090    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'EASTGATE EXT 9',
		Code: '2090    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'EASTLAKE ISLAND, Muizenberg',
		Code: '7945    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'EASTLAKE VILLAGE, Muizenberg',
		Code: '7945    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'EASTLAKE, Muizenberg',
		Code: '7945    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'EASTLEIGH, Edenvale',
		Code: '1609    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'EASTRIDGE, Mitchells Plain',
		Code: '7785    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'EASTVALE, Springs',
		Code: '1559    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'EASTWOLDS',
		Code: '4562    ',
		Hub: 'DUR',
		Ring: 3,
		OpsHub: 'DUR',
		Dist: 150,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'EASTWOOD, Pietermaritzburg',
		Code: '3201    ',
		Hub: 'PMB',
		Ring: 1,
		OpsHub: 'PMB',
		Dist: 75,
		AreaName: 'Pietermaritzburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'EBENHAESER',
		Code: '8149    ',
		Hub: 'CPT',
		Ring: 4,
		OpsHub: 'CPT',
		Dist: 330,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'EBILANYONI',
		Code: '3185    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 400,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'EBUHLENI',
		Code: '1909    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 60,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'EDELWEISS & Ext/Uit, Springs',
		Code: '1559    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'EDELWEISS Ext/Uit 1, Springs',
		Code: '1559    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'EDEN GLEN Boxes',
		Code: '1613    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'EDEN PARK',
		Code: '1455    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'EDEN PARK Ext/Uit 1,',
		Code: '1455    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'EDEN PARK, Brackenfell',
		Code: '7560    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'EDEN, George',
		Code: '6529',
		Hub: 'GRJ',
		Ring: 0,
		OpsHub: 'GRJ',
		Dist: 10,
		AreaName: 'George Central',
		Surch: null,
	},
	{
		PlaceName: 'EDENBURG, Bloemfontein',
		Code: '9908    ',
		Hub: 'BFN',
		Ring: 1,
		OpsHub: 'BFN',
		Dist: 100,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'EDENBURG, Johannesburg',
		Code: '2128',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'EDENBURGH Est/Lgd, Claremont',
		Code: '7700    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'EDENDALE, Edenvale',
		Code: '1609    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'EDENDALE, N',
		Code: '4505    ',
		Hub: 'DUR',
		Ring: 2,
		OpsHub: 'DUR',
		Dist: 20,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'EDENGLEN, Edenvale',
		Code: '1609    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'EDENVALE, Johannesburg',
		Code: '1609    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'EDENVALE, T Boxes',
		Code: '1610    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'EDENVALE, T Streets',
		Code: '1609    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'EDENVILLE',
		Code: '9535    ',
		Hub: 'WEL',
		Ring: 3,
		OpsHub: 'JNB',
		Dist: 170,
		AreaName: 'Johannesburg Regional',
		Surch: 'Far Outlying',
	},
	{
		PlaceName: 'EDGEMEAD Boxes',
		Code: '7407    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'EDGEMEAD, Cape Town',
		Code: '7441    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'EDLEEN Boxes',
		Code: '1625    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'EDLEEN, Kempton Park',
		Code: '1619    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: "EDONQABA, King William's",
		Code: '5600    ',
		Hub: 'ELS',
		Ring: 1,
		OpsHub: 'ELS',
		Dist: 70,
		AreaName: 'East London Regional',
		Surch: null,
	},
	{
		PlaceName: 'EDUAN PARK, Pietersburg',
		Code: '0699',
		Hub: 'PTG',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 400,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'EENDEKUIL',
		Code: '7335    ',
		Hub: 'CPT',
		Ring: 4,
		OpsHub: 'CPT',
		Dist: 180,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'EERSTE RIVER',
		Code: '7100    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 35,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'EERSTEGOUD',
		Code: '0701    ',
		Hub: 'PTG',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 400,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'EERSTEMYN Boxes',
		Code: '9466    ',
		Hub: 'BFN',
		Ring: 3,
		OpsHub: 'BFN',
		Dist: 150,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'EERSTEMYN, Welkom',
		Code: '9459    ',
		Hub: 'WEL',
		Ring: 3,
		OpsHub: 'JNB',
		Dist: 150,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'EERSTERIVIER',
		Code: '7100    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 35,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'EFFINGHAM HEIGHTS, Durban',
		Code: '4051',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'EGAGASINI',
		Code: '3972    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 400,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'EHRLICHPARK Boxes',
		Code: '9312    ',
		Hub: 'BFN',
		Ring: 0,
		OpsHub: 'BFN',
		Dist: 30,
		AreaName: 'Bloemfontein Central',
		Surch: null,
	},
	{
		PlaceName: 'EHRLICHPARK, Bloemfontein',
		Code: '9301',
		Hub: 'BFN',
		Ring: 0,
		OpsHub: 'BFN',
		Dist: 30,
		AreaName: 'Bloemfontein Central',
		Surch: null,
	},
	{
		PlaceName: 'EIKEFONTEIN Boxes',
		Code: '7571    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'EIKEFONTEIN, Kraaifontein',
		Code: '7570    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'EIKENBOSCH ESTATE, Kuilsriver',
		Code: '7580',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'EIKENBOSCH, Bellville',
		Code: '7530',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'EIKENHOF, Johannesburg',
		Code: '1872    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 30,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'EKANGALA',
		Code: '1021    ',
		Hub: 'PRY',
		Ring: 2,
		OpsHub: 'JNB',
		Dist: 90,
		AreaName: 'Johannesburg Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'EKKLESIA, Pretoria',
		Code: '0186    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'EKUPHUMLENI, Khayelitsha',
		Code: '7784    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'EKWENDENI, Pomeroy',
		Code: '3020    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 220,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'ELANDIA, Klerksdorp',
		Code: '2571    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 150,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'ELANDIA, Kroonstad',
		Code: '9499    ',
		Hub: 'BFN',
		Ring: 3,
		OpsHub: 'BFN',
		Dist: 170,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'ELANDS BAY',
		Code: '8110    ',
		Hub: 'CPT',
		Ring: 4,
		OpsHub: 'CPT',
		Dist: 200,
		AreaName: 'Cape Town Regional',
		Surch: 'Far Outlying',
	},
	{
		PlaceName: 'ELANDSBAAI',
		Code: '8110    ',
		Hub: 'CPT',
		Ring: 4,
		OpsHub: 'CPT',
		Dist: 200,
		AreaName: 'Cape Town Regional',
		Surch: 'Far Outlying',
	},
	{
		PlaceName: 'ELANDSFONTEIN',
		Code: '1406    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'ELANDSFONTEIN RAIL, Elandsfontein',
		Code: '1406    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'ELANDSFONTEIN SPOOR, Elandfontein',
		Code: '1406    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'ELANDSGOUD, Carletonville',
		Code: '2508    ',
		Hub: 'JNB',
		Ring: 2,
		OpsHub: 'JNB',
		Dist: 100,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'ELANDSHAVEN, Germiston',
		Code: '1401    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'ELANDSHEUWEL, Klerksdorp',
		Code: '2571    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 150,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'ELANDSHOEK',
		Code: '1208    ',
		Hub: 'NLP',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 310,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'ELANDSKOP',
		Code: '4540    ',
		Hub: 'DUR',
		Ring: 3,
		OpsHub: 'DUR',
		Dist: 140,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'ELANDSKRAAL',
		Code: '3017    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 250,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'ELANDSLAAGTE',
		Code: '2900    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 280,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'ELANDSPARK, Johannesburg',
		Code: '2197    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'ELANDSPOORT, Pretoria',
		Code: '0183    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'ELANDSRAND, Brits',
		Code: '0250    ',
		Hub: 'JNB',
		Ring: 2,
		OpsHub: 'JNB',
		Dist: 90,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'ELANDSRAND, Carletonville',
		Code: '2500    ',
		Hub: 'POT',
		Ring: 2,
		OpsHub: 'JNB',
		Dist: 100,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'ELARDUSPARK & Ext/Uit 1',
		Code: '0181    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'ELCEDES, Johannesburg',
		Code: '2094    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'ELDORADOPARK ',
		Code: '1812    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 30,
		AreaName: 'Johannesburg Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'ELDORADOPARK Boxes',
		Code: '1813    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 30,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'ELDORADOPARK Ext/Uit 1',
		Code: '1812    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 30,
		AreaName: 'Johannesburg Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'ELDORAIGNE & Ext/Uit 1, 3',
		Code: '0157    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'ELECTRAPARK, Kimberley',
		Code: '8301    ',
		Hub: 'KIM',
		Ring: 0,
		OpsHub: 'KIM',
		Dist: 20,
		AreaName: 'Kimberley Central',
		Surch: null,
	},
	{
		PlaceName: 'ELECTRIC CITY, Eersteriver',
		Code: '7100    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 15,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'ELECTRON, Johannesburg',
		Code: '2197    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'ELEPHANT PARK',
		Code: '6109    ',
		Hub: 'PLZ',
		Ring: 1,
		OpsHub: 'PLZ',
		Dist: 60,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'ELFINDALE Est/Lgd, Bergvliet',
		Code: '7945    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'ELGIN',
		Code: '7180    ',
		Hub: 'CPT',
		Ring: 1,
		OpsHub: 'CPT',
		Dist: 60,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'ELIM',
		Code: '7284',
		Hub: 'CPT',
		Ring: 4,
		OpsHub: 'CPT',
		Dist: 120,
		AreaName: 'Cape Town Regional',
		Surch: 'Far Outlying',
	},
	{
		PlaceName: 'ELIM Cape Town',
		Code: '7284    ',
		Hub: 'CPT',
		Ring: 3,
		OpsHub: 'CPT',
		Dist: 150,
		AreaName: 'Cape Town Regional',
		Surch: 'Far Outlying',
	},
	{
		PlaceName: 'ELIM HOSPITAL,',
		Code: '0960    ',
		Hub: 'JNB',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 300,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'ELIM, KuilsRiver',
		Code: '7580    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'ELLADOONE, Johannesburg',
		Code: '2197    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'ELLATON, Klerksdorp',
		Code: '2571    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 150,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'ELLIOT',
		Code: '5460    ',
		Hub: 'ELS',
		Ring: 4,
		OpsHub: 'ELS',
		Dist: 250,
		AreaName: 'East London Regional',
		Surch: null,
	},
	{
		PlaceName: 'ELLIOTDALE',
		Code: '5070    ',
		Hub: 'ELS',
		Ring: 1,
		OpsHub: 'ELS',
		Dist: 250,
		AreaName: 'East London Regional',
		Surch: null,
	},
	{
		PlaceName: 'ELLIS PARK, Durban North',
		Code: '4051',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'ELLIS PARK, Johannesburg',
		Code: '2094    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'ELLISRAS',
		Code: '0555    ',
		Hub: 'PRY',
		Ring: 4,
		OpsHub: 'PRY',
		Dist: 180,
		AreaName: 'Pretoria Regional',
		Surch: null,
	},
	{
		PlaceName: 'ELLISRAS (TT)',
		Code: '0555',
		Hub: 'PRY',
		Ring: 4,
		OpsHub: 'PRY',
		Dist: 180,
		AreaName: 'Pretoria Regional',
		Surch: null,
	},
	{
		PlaceName: 'ELMA PARK, Edenvale',
		Code: '1609    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'ELMESTON',
		Code: '0532    ',
		Hub: 'PRY',
		Ring: 4,
		OpsHub: 'PRY',
		Dist: 180,
		AreaName: 'Pretoria Regional',
		Surch: null,
	},
	{
		PlaceName: 'ELNOR Est/Lgd, Matroosfon',
		Code: '7490    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'ELOFF',
		Code: '2211    ',
		Hub: 'MBG',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 50,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'ELOFFSDAL, Pretoria',
		Code: '0084    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'ELONWABENI',
		Code: '7791    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'ELRICHT Est/Lgd, Paarl',
		Code: '7646    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 60,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'ELSBURG',
		Code: '1407    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'ELSENBURG',
		Code: '7607    ',
		Hub: 'CPT',
		Ring: 1,
		OpsHub: 'CPT',
		Dist: 60,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: "ELSIE'S RIVER Boxes",
		Code: '7480    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: "ELSIES BAY, Simon's Town",
		Code: '7995    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 70,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'ELSIES RIVER ',
		Code: '7490    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'ELSIESBAAI, Simonstown',
		Code: '7995    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 70,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'ELSIESHOF, Johannesburg',
		Code: '2094    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'ELSIESRIVIER Busse',
		Code: '7480    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'ELSIESRIVIER Strate',
		Code: '7490    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'ELSPARK Boxes',
		Code: '1418    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'ELSPARK, Elsburg',
		Code: '1407    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'ELSPARK,Germiston',
		Code: '1428',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'ELTIVILLAS, Louis Trichar',
		Code: '0920    ',
		Hub: 'JNB',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 400,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'ELTONHILL, Johannesburg',
		Code: '2196    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'ELTORO PARK, Kimberley',
		Code: '8301    ',
		Hub: 'KIM',
		Ring: 0,
		OpsHub: 'KIM',
		Dist: 20,
		AreaName: 'Kimberley Central',
		Surch: null,
	},
	{
		PlaceName: 'ELUKWATINI',
		Code: '0890    ',
		Hub: 'JNB',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 420,
		AreaName: 'Johannesburg Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'ELUNDINI, Port Elizabeth',
		Code: '6200    ',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'ELWYN PARK, Crawford',
		Code: '7764    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'EMANSENSENI, Hillcrest',
		Code: '3610',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'EMBERTON, Gillitts',
		Code: '3610',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'EMDENI & Ext/Uit, kwaXuma',
		Code: '1868    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 30,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'EMDENI NORTH, kwaXuma',
		Code: '1868    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 30,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'EMDENI SOUTH, kwaXuma',
		Code: '1868    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 30,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'EMDENI-NOORD, kwaXuma',
		Code: '1868    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 30,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'EMDENI-SUID, kwaXuma',
		Code: '1868    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 30,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'EMERALD HILL',
		Code: '6011    ',
		Hub: 'PLZ',
		Ring: 1,
		OpsHub: 'PLZ',
		Dist: 60,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'EMJINDINI, Barberton',
		Code: '1300    ',
		Hub: 'JNB',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 350,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'EMMARENTIA & Ext/Uit,',
		Code: '2195    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'EMMARENTIA Boxes',
		Code: '2029    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'EMONA, Tongaat',
		Code: '4399',
		Hub: 'DUR',
		Ring: 1,
		OpsHub: 'DUR',
		Dist: 70,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'EMPANGELA',
		Code: '3880',
		Hub: 'DUR',
		Ring: 1,
		OpsHub: 'DUR',
		Dist: 180,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'EMPANGENI',
		Code: '3880',
		Hub: 'DUR',
		Ring: 1,
		OpsHub: 'DUR',
		Dist: 180,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'EMPANGENI EXT 17',
		Code: '3880',
		Hub: 'DUR',
		Ring: 1,
		OpsHub: 'DUR',
		Dist: 200,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'EMPANGENI EXT 38',
		Code: '3880',
		Hub: 'DUR',
		Ring: 1,
		OpsHub: 'DUR',
		Dist: 200,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'EMPANGENI STATION',
		Code: '3880',
		Hub: 'DUR',
		Ring: 1,
		OpsHub: 'DUR',
		Dist: 180,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'ENGCOBO',
		Code: '5050    ',
		Hub: 'ELS',
		Ring: 4,
		OpsHub: 'ELS',
		Dist: 160,
		AreaName: 'East London Regional',
		Surch: null,
	},
	{
		PlaceName: 'ENHLALAKAHLE, Greytown',
		Code: '3500    ',
		Hub: 'DUR',
		Ring: 3,
		OpsHub: 'DUR',
		Dist: 150,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'ENKANYEZI, Colenso',
		Code: '3360    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 230,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'ENKELBULT',
		Code: '0556    ',
		Hub: 'PRY',
		Ring: 4,
		OpsHub: 'PRY',
		Dist: 180,
		AreaName: 'Pretoria Regional',
		Surch: null,
	},
	{
		PlaceName: 'ENNERDALE',
		Code: '1826    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 60,
		AreaName: 'Johannesburg Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'ENNERDALE, Odin Park',
		Code: '1825    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 30,
		AreaName: 'Johannesburg Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'ENON',
		Code: '6125    ',
		Hub: 'PLZ',
		Ring: 2,
		OpsHub: 'PLZ',
		Dist: 90,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'ENTABENI',
		Code: '0307    ',
		Hub: 'RBG',
		Ring: 2,
		OpsHub: 'JNB',
		Dist: 120,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'EPPINDUST',
		Code: '7475    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'EPPING FOREST Est/Lgd,',
		Code: '7490    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'EPPING INDUSTRI#LE GEBIED',
		Code: '7460    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'EPPING INDUSTRIAL AREA,',
		Code: '7460    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'EPSOM DOWNS, Sloane Park',
		Code: '2152    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'EPSWORTH, Pietermaritzburg',
		Code: '3201    ',
		Hub: 'PMB',
		Ring: 1,
		OpsHub: 'PMB',
		Dist: 75,
		AreaName: 'Pietermaritzburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'EQUESTRIA, Pretoria',
		Code: '0184',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'ERASMIA, Pretoria',
		Code: '0183    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'ERASMUSKLOOF ',
		Code: '0153    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'ERASMUSRAND, Pretoria',
		Code: '0181    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'ERIC DODD, Uitenhage',
		Code: '6230    ',
		Hub: 'PLZ',
		Ring: 1,
		OpsHub: 'PLZ',
		Dist: 50,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'ERIC LOUWBURG',
		Code: '6972    ',
		Hub: 'CPT',
		Ring: 4,
		OpsHub: 'CPT',
		Dist: 470,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'ERICA Est/Lgd, Elsies River',
		Code: '7490    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'ERNESTVILLE, Kimberley',
		Code: '8301    ',
		Hub: 'KIM',
		Ring: 0,
		OpsHub: 'KIM',
		Dist: 20,
		AreaName: 'Kimberley Central',
		Surch: null,
	},
	{
		PlaceName: 'ESCOMBE, Queensburgh',
		Code: '4093',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'ESHOWE',
		Code: '3815    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 200,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'ESPAGSDRIF',
		Code: '8551    ',
		Hub: 'BFN',
		Ring: 4,
		OpsHub: 'BFN',
		Dist: 210,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'ESPLANADE, Durban',
		Code: '4001',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'ESSELEN PARK, Worcester',
		Code: '6850    ',
		Hub: 'CPT',
		Ring: 2,
		OpsHub: 'CPT',
		Dist: 120,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'ESSELENPARK',
		Code: '1626    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'ESSENWOOD, Durban',
		Code: '4001',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'ESSEXVALE, Port Elizabeth',
		Code: '6070',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'ESSEXWOLD, Bedfordview',
		Code: '2008    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'ESTADEAL',
		Code: '6012    ',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'ESTCOURT, KwaZuluNatal',
		Code: '3310    ',
		Hub: 'HSM',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 190,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'ESTERA, Elsburg',
		Code: '1407    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'ESTERPARK & Ext/Uit 1,',
		Code: '1619    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'ESTOIRE, Bloemfontein',
		Code: '9301',
		Hub: 'BFN',
		Ring: 0,
		OpsHub: 'BFN',
		Dist: 30,
		AreaName: 'Bloemfontein Central',
		Surch: null,
	},
	{
		PlaceName: 'ESTON',
		Code: '3740    ',
		Hub: 'DUR',
		Ring: 1,
		OpsHub: 'DUR',
		Dist: 60,
		AreaName: 'Durban Regional',
		Surch: 'Far Outlying',
	},
	{
		PlaceName: 'ETWATWA, Daveyton',
		Code: '1507    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'EUREKA Est/Lgd, Elsiesriver',
		Code: '7490    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'EUREKA, Burgersdorp',
		Code: '5520    ',
		Hub: 'ELS',
		Ring: 4,
		OpsHub: 'ELS',
		Dist: 360,
		AreaName: 'East London Regional',
		Surch: null,
	},
	{
		PlaceName: 'EVANS PARK, Johannesburg',
		Code: '2091    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'EVATON, Residensia',
		Code: '1980    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 60,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'EVELEIGH, Boksburg',
		Code: '1459    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'EVEREST, Tokoza',
		Code: '1421    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'EVERGLEN, Durbanville',
		Code: '7550    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'EVERGREEN,Fish Hoek',
		Code: '7975    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 60,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'EVERSDAL HEIGHTS, Durbanville',
		Code: '7550    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'EVERSDAL, Durbanville',
		Code: '7550    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'EVERTON, Gillitts',
		Code: '3610',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'EXCELSIOR',
		Code: '9760    ',
		Hub: 'BFN',
		Ring: 3,
		OpsHub: 'BFN',
		Dist: 130,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'EXCOM',
		Code: '2023    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'EXTON ROAD, BLOEMFONTEIN',
		Code: '9313    ',
		Hub: 'BFN',
		Ring: 0,
		OpsHub: 'BFN',
		Dist: 30,
		AreaName: 'Bloemfontein Central',
		Surch: null,
	},
	{
		PlaceName: 'EXTONWEG, BLOEMFONTEIN',
		Code: '9313    ',
		Hub: 'BFN',
		Ring: 0,
		OpsHub: 'BFN',
		Dist: 30,
		AreaName: 'Bloemfontein Central',
		Surch: null,
	},
	{
		PlaceName: 'EZAKHENI',
		Code: '2940    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 320,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'FABRICIA, Kimberley',
		Code: '8301    ',
		Hub: 'KIM',
		Ring: 0,
		OpsHub: 'KIM',
		Dist: 20,
		AreaName: 'Kimberley Central',
		Surch: null,
	},
	{
		PlaceName: 'FACTORIA, Krugersdorp',
		Code: '1739    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'FACTRETON, Maitland',
		Code: '7405    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'FAERIE GLEN ',
		Code: '0081    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'FAERIE GLEN, Pretoria',
		Code: '0081    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'FAERIE KNOWE, Fish Hoek',
		Code: '7975    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 60,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'FAIRBREEZE, Tongaat',
		Code: '4399',
		Hub: 'DUR',
		Ring: 1,
		OpsHub: 'DUR',
		Dist: 70,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'FAIRBRIDGE Est/Lgd, Maitland',
		Code: '7405    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'FAIRBRIDGE HEIGHTS,',
		Code: '6230    ',
		Hub: 'PLZ',
		Ring: 1,
		OpsHub: 'PLZ',
		Dist: 50,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'FAIRFIELD Est/Lgd, Parow',
		Code: '7500    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'FAIRLAND Boxes',
		Code: '2030    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'FAIRLAND, Johannesburg',
		Code: '2195    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'FAIRMEAD, Pietermaritzburg',
		Code: '3201    ',
		Hub: 'PMB',
		Ring: 1,
		OpsHub: 'PMB',
		Dist: 75,
		AreaName: 'Pietermaritzburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'FAIRMOUNT RIDGE, Johannesburg',
		Code: '2192    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'FAIRMOUNT, Johannesburg',
		Code: '2192    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'FAIRTREES, Durbanville',
		Code: '7550    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'FAIRVALE, Johannesburg',
		Code: '2192    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'FAIRVIEW Est/Lgd, Plumstead',
		Code: '7800    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'FAIRVIEW, Barkly East/',
		Code: '5580    ',
		Hub: 'ELS',
		Ring: 4,
		OpsHub: 'ELS',
		Dist: 350,
		AreaName: 'East London Regional',
		Surch: null,
	},
	{
		PlaceName: 'FAIRVIEW, Bloemfontein',
		Code: '9301',
		Hub: 'BFN',
		Ring: 0,
		OpsHub: 'BFN',
		Dist: 30,
		AreaName: 'Bloemfontein Central',
		Surch: null,
	},
	{
		PlaceName: 'FAIRVIEW, Empangeni',
		Code: '3880',
		Hub: 'DUR',
		Ring: 1,
		OpsHub: 'DUR',
		Dist: 180,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'FAIRVIEW, Faerie Glen',
		Code: '0043    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'FAIRVIEW, Johannesburg',
		Code: '2094    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'FAIRVIEW, Port Elizabeth',
		Code: '6070',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'FAIRWAYS, Johannesburg',
		Code: '2196    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'FAIRWAYS, Wynberg CPT',
		Code: '7800    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'FAIRWOOD, Johannesburg',
		Code: '2192    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'FAIRYGLEN, Worcester',
		Code: '6850    ',
		Hub: 'CPT',
		Ring: 2,
		OpsHub: 'CPT',
		Dist: 120,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'FALCONRIDGE, Vereeniging',
		Code: '1939    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 60,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'FALLODEN PARK, Durban',
		Code: '4094    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'FALSE BAY, Muizenberg',
		Code: '7945    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'FAMO PARK, Pietersburg',
		Code: '0699',
		Hub: 'PTG',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 400,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'FARMEDGE, Retreat',
		Code: '7945    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'FARMSIDE, Retreat',
		Code: '7945    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'FARNINGHAM RIDGE, Pinetown',
		Code: '3610',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'FARRAR PARK, Boksburg',
		Code: '1459    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'FARRARMERE & Ext/Uit 21,',
		Code: '1501    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'FARRARMERE Boxes',
		Code: '1518    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'FAUNA PARK, Pietersburg',
		Code: '0699',
		Hub: 'PTG',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 400,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'FAUNA PARK, Virginia',
		Code: '9431    ',
		Hub: 'BFN',
		Ring: 2,
		OpsHub: 'BFN',
		Dist: 20,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'FAUNA, Bloemfontein',
		Code: '9301',
		Hub: 'BFN',
		Ring: 0,
		OpsHub: 'BFN',
		Dist: 30,
		AreaName: 'Bloemfontein Central',
		Surch: null,
	},
	{
		PlaceName: 'FAUNASIG Boxes',
		Code: '9325    ',
		Hub: 'BFN',
		Ring: 0,
		OpsHub: 'BFN',
		Dist: 30,
		AreaName: 'Bloemfontein Central',
		Surch: null,
	},
	{
		PlaceName: 'FAUNASIG, Bloemfontein',
		Code: '9301',
		Hub: 'BFN',
		Ring: 0,
		OpsHub: 'BFN',
		Dist: 30,
		AreaName: 'Bloemfontein Central',
		Surch: null,
	},
	{
		PlaceName: 'FAURE',
		Code: '7131    ',
		Hub: 'CPT',
		Ring: 1,
		OpsHub: 'CPT',
		Dist: 30,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'FAURESMITH',
		Code: '9978    ',
		Hub: 'BFN',
		Ring: 3,
		OpsHub: 'BFN',
		Dist: 150,
		AreaName: 'Bloemfontein Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'FAWN LEAS',
		Code: '3475    ',
		Hub: 'DUR',
		Ring: 2,
		OpsHub: 'DUR',
		Dist: 110,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'FEATHERBROOKE,Krugersdorp',
		Code: '1746',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'FELIXTON',
		Code: '3875    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 230,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'FELLSIDE, Johannesburg',
		Code: '2192    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'FERGUSON Tsp/Dgd, Port Elizabeth',
		Code: '6001    ',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'FERNDALE & Ext/Uit 3 & 6,',
		Code: '2194    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'FERNDALE Boxes',
		Code: '2160    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'FERNDALE Est/CPT,Newlands',
		Code: '7700    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'FERNDALE RIDGE, Randburg',
		Code: '2194    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'FERNDALE, Brackenfell',
		Code: '7560    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'FERNDALE, Randburg',
		Code: '2194    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'FERNESS Est/Lgd, Ottery',
		Code: '7800    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'FERNGLEN, Port Elizabeth',
		Code: '6045    ',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'FERNRIDGE, George',
		Code: '6529',
		Hub: 'GRJ',
		Ring: 0,
		OpsHub: 'GRJ',
		Dist: 10,
		AreaName: 'George Central',
		Surch: null,
	},
	{
		PlaceName: 'FERNWOOD Est/Lgd,Newlands',
		Code: '7700    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'FERRAX, Newcastle',
		Code: '2953    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 330,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'FERREIRASDORP Boxes',
		Code: '2048    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'FERREIRASDORP, Johannesburg',
		Code: '2001    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'FERROBANK',
		Code: '1035    ',
		Hub: 'MBG',
		Ring: 3,
		OpsHub: 'JNB',
		Dist: 140,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'FERRYVALE, Nigel',
		Code: '1490    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 45,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'FICHARDTPARK, Bloemfontein',
		Code: '9301',
		Hub: 'BFN',
		Ring: 0,
		OpsHub: 'BFN',
		Dist: 30,
		AreaName: 'Bloemfontein Central',
		Surch: null,
	},
	{
		PlaceName: 'FIELDS HILL, Pinetown',
		Code: '3610',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'FILLIAN PARK, Pietermaritzburg',
		Code: '3201    ',
		Hub: 'PMB',
		Ring: 1,
		OpsHub: 'PMB',
		Dist: 75,
		AreaName: 'Pietermaritzburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'FINSBURY, Randfontein',
		Code: '1760    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'FIONA Est/Lgd, Ysterplaat',
		Code: '7405    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'FIRGROVE Boxes',
		Code: '7110    ',
		Hub: 'CPT',
		Ring: 1,
		OpsHub: 'CPT',
		Dist: 30,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'FIRGROVE, Cape Town',
		Code: '7130    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 30,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'FIRWOOD Est/Lgd, Queensburg',
		Code: '4093',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'FISANTEKRAAL, Durbanville',
		Code: '7550    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'FISH HOEK',
		Code: '7975    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 60,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'FISH HOEK (S)',
		Code: '7975',
		Hub: 'CPT',
		Ring: 1,
		OpsHub: 'CPT',
		Dist: 60,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'FISH RIVER',
		Code: '5883    ',
		Hub: 'PLZ',
		Ring: 1,
		OpsHub: 'PLZ',
		Dist: 300,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'FISHER HAVEN',
		Code: '6571',
		Hub: 'GRJ',
		Ring: 1,
		OpsHub: 'GRJ',
		Dist: 80,
		AreaName: 'George Regional',
		Surch: null,
	},
	{
		PlaceName: "FISHER'S HILL Boxes",
		Code: '1408    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: "FISHER'S HILL, Germiston",
		Code: '1401    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'FISHERHAVEN',
		Code: '7200',
		Hub: 'CPT',
		Ring: 1,
		OpsHub: 'CPT',
		Dist: 110,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'FLAGSTAFF',
		Code: '4810',
		Hub: 'ELS',
		Ring: 4,
		OpsHub: 'ELS',
		Dist: 250,
		AreaName: 'East London Regional',
		Surch: null,
	},
	{
		PlaceName: 'FLAMBOYANT PARK, Queensburg',
		Code: '4093',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'FLAMINGO HEIGHTS, Tongaat',
		Code: '4399',
		Hub: 'DUR',
		Ring: 1,
		OpsHub: 'DUR',
		Dist: 70,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'FLAMINGO PARK, Welkom',
		Code: '9459    ',
		Hub: 'WEL',
		Ring: 3,
		OpsHub: 'JNB',
		Dist: 150,
		AreaName: 'Johannesburg Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'FLAMINGO VILLAGE, Milnerton',
		Code: '7441    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'FLAMINGOVLEI, Table View',
		Code: '7441    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'FLAMWOOD Boxes',
		Code: '2572    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 150,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'FLAMWOOD, Klerksdorp',
		Code: '2571    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 150,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'FLEURDAL, Bloemfontein',
		Code: '9301',
		Hub: 'BFN',
		Ring: 0,
		OpsHub: 'BFN',
		Dist: 30,
		AreaName: 'Bloemfontein Central',
		Surch: null,
	},
	{
		PlaceName: 'FLEURHOF, Florida',
		Code: '1709    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'FLIMIEDA, Klerksdorp',
		Code: '2571    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 150,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'FLINTDALE Est/Lgd, Southfield',
		Code: '7800    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'FLOORS, Kimberley',
		Code: '8301    ',
		Hub: 'KIM',
		Ring: 0,
		OpsHub: 'KIM',
		Dist: 20,
		AreaName: 'Kimberley Central',
		Surch: null,
	},
	{
		PlaceName: 'FLORA GARDENS, Vanderbijlpark',
		Code: '1911    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 60,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'FLORA, Bloemfontein',
		Code: '9301',
		Hub: 'BFN',
		Ring: 0,
		OpsHub: 'BFN',
		Dist: 30,
		AreaName: 'Bloemfontein Central',
		Surch: null,
	},
	{
		PlaceName: 'FLORACLIFFE, Florida',
		Code: '1709    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'FLORAPARK, Pietersburg',
		Code: '0699',
		Hub: 'PTG',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 400,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'FLORAPARK, Tzaneen',
		Code: '0850',
		Hub: 'PTG',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 350,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'FLORAUNA, Pretoria',
		Code: '0182    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'FLORENTIA, Alberton',
		Code: '1449    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'FLOREX Boxes',
		Code: '1714    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'FLOREX, Florida',
		Code: '1709    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'FLORIAN PARK, Worcester',
		Code: '6850    ',
		Hub: 'CPT',
		Ring: 2,
		OpsHub: 'CPT',
		Dist: 120,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'FLORIANVILLE Boxes',
		Code: '8320    ',
		Hub: 'KIM',
		Ring: 0,
		OpsHub: 'KIM',
		Dist: 20,
		AreaName: 'Kimberley Central',
		Surch: null,
	},
	{
		PlaceName: 'FLORIANVILLE, Kimberley',
		Code: '8301    ',
		Hub: 'KIM',
		Ring: 0,
		OpsHub: 'KIM',
		Dist: 20,
		AreaName: 'Kimberley Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'FLORIDA',
		Code: '1709    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'FLORIDA Boxes',
		Code: '1710    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'FLORIDA Est/Lgd, Ravensme',
		Code: '7490    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'FLORIDA Ext/Uit 3 & 11,',
		Code: '1709    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'FLORIDA Ext/Uit, Florida',
		Code: '1709    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'FLORIDA GLEN, Florida',
		Code: '1709    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'FLORIDA HILLS & Ext/Uit 2',
		Code: '1709    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'FLORIDA HILLS Boxes',
		Code: '1716    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'FLORIDA LAKE, Florida',
		Code: '1709    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'FLORIDA MEER, Florida',
		Code: '1709    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'FLORIDA NORTH, Florida',
		Code: '1709    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'FLORIDA PARK, Florida',
		Code: '1709    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'FLORIDA ROAD, DURBAN',
		Code: '4019    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'FLORIDA Streets',
		Code: '1709    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'FLORIDA VIEW, Florida',
		Code: '1709    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'FLORIDA-NOORD, Florida',
		Code: '1709    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'FLORIDAWEG, DURBAN',
		Code: '4019    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'FLORODALE, Durbanville',
		Code: '7550    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'FLOROPARK,Gariepdam',
		Code: '9922',
		Hub: 'BFN',
		Ring: 1,
		OpsHub: 'BFN',
		Dist: 50,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'FOCHVILLE',
		Code: '2515    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 100,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'FONTAINEBLEAU',
		Code: '2032    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'FONTAINEBLEAU, Randburg',
		Code: '2194    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'FONTEINRIET Boxes',
		Code: '1464    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'FONTEINRIET, Boksburg',
		Code: '1459    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'FORBESDALE, Johannesburg',
		Code: '2192    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'FORDERVILLE, Estcourt',
		Code: '3310    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 190,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'FORDSBURG Boxes',
		Code: '2033    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'FORDSBURG, Johannesburg',
		Code: '2092    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'FORDVILLE, New Brighton',
		Code: '6200    ',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'FORESHORE, Cape Town',
		Code: '8001',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'FOREST DENE, Pinetown',
		Code: '3610',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'FOREST GLADE, Tokai',
		Code: '7945    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'FOREST HAVEN, Phoenix',
		Code: '4068',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'FOREST HILL, Johannesburg',
		Code: '2190    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'FOREST HILL, Port Elizabeth',
		Code: '6001    ',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'FOREST HILLS, Pinetown',
		Code: '3610',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'FOREST TOWN, Johannesburg',
		Code: '2193    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'FOREST VILLAGE, Eersteriver',
		Code: '7100    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 15,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'FORMAIN, Johannesburg',
		Code: '2090    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'FORT BEAUFORT',
		Code: '5720    ',
		Hub: 'ELS',
		Ring: 4,
		OpsHub: 'ELS',
		Dist: 170,
		AreaName: 'East London Regional',
		Surch: null,
	},
	{
		PlaceName: 'FORT GALE, UMTATA',
		Code: '5100',
		Hub: 'ELS',
		Ring: 1,
		OpsHub: 'ELS',
		Dist: 180,
		AreaName: 'East London Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'FORT JACKSON',
		Code: '5605',
		Hub: 'ELS',
		Ring: 1,
		OpsHub: 'ELS',
		Dist: 60,
		AreaName: 'East London Regional',
		Surch: null,
	},
	{
		PlaceName: 'FOSA, Durban',
		Code: '4037',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'FOUNTAIN VILLAGE, Eersterfontein',
		Code: '7100    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 15,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'FOUR WAYS, Bryanston',
		Code: '2191    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'FOURWAYS, Johannesburg',
		Code: '2191    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'FOURWAYS, Randburg',
		Code: '2191    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'FRAMESBY & Ext/Uit, Port Elizabeth',
		Code: '6045    ',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'FRAMESBY Ext/Uit 1, Port Elizabeth',
		Code: '6045    ',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'FRAMTON, Johannesburg',
		Code: '2091    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'FRANCIS EVATT PARK, Port Elizabeth',
		Code: '6025',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'FRANKLIN',
		Code: '4610    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 200,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'FRANKLIN, Johannesburg',
		Code: '2195    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'FRANSCHHOEK',
		Code: '7690    ',
		Hub: 'CPT',
		Ring: 1,
		OpsHub: 'CPT',
		Dist: 90,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'FRANSKRAAL',
		Code: '7220',
		Hub: 'CPT',
		Ring: 1,
		OpsHub: 'CPT',
		Dist: 170,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: "FRASER'S CAMP",
		Code: '6152    ',
		Hub: 'PLZ',
		Ring: 4,
		OpsHub: 'PLZ',
		Dist: 190,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'FRASER, Tongaat',
		Code: '4399',
		Hub: 'DUR',
		Ring: 1,
		OpsHub: 'DUR',
		Dist: 70,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'FRASERBURG',
		Code: '6960    ',
		Hub: 'CPT',
		Ring: 4,
		OpsHub: 'CPT',
		Dist: 450,
		AreaName: 'Cape Town Regional',
		Surch: 'Far Outlying',
	},
	{
		PlaceName: 'FREDERIKSTAD/STA, Boskop',
		Code: '2528    ',
		Hub: 'JNB',
		Ring: 3,
		OpsHub: 'JNB',
		Dist: 150,
		AreaName: 'Johannesburg Regional',
		Surch: 'Far Outlying',
	},
	{
		PlaceName: 'FREEMANVILLE Boxes',
		Code: '2573    ',
		Hub: 'JNB',
		Ring: 3,
		OpsHub: 'JNB',
		Dist: 150,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'FREEMANVILLE, Klerksdorp',
		Code: '2571    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 150,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'FREEWAY PARK & Ext/Uit 1,',
		Code: '1459    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'FRERE Est/Lgd, Rondebosch',
		Code: '7780    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'FRESNAYE, Cape Town',
		Code: '8005',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'FRIEMERSHEIM',
		Code: '6526    ',
		Hub: 'GRJ',
		Ring: 1,
		OpsHub: 'GRJ',
		Dist: 50,
		AreaName: 'George Regional',
		Surch: null,
	},
	{
		PlaceName: 'FRIERSDALE',
		Code: '8861    ',
		Hub: 'BFN',
		Ring: 4,
		OpsHub: 'BFN',
		Dist: 580,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'FROGGY POND, Simonstad',
		Code: '7995    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 70,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'FROGMORE Est/Lgd, Retreat',
		Code: '7945    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'FRYLINCKSPAN',
		Code: '8466    ',
		Hub: 'BFN',
		Ring: 4,
		OpsHub: 'BFN',
		Dist: 400,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'FULLARTON',
		Code: '6681    ',
		Hub: 'GRJ',
		Ring: 3,
		OpsHub: 'GRJ',
		Dist: 160,
		AreaName: 'George Regional',
		Surch: null,
	},
	{
		PlaceName: 'FUMANI',
		Code: '0937    ',
		Hub: 'JNB',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 300,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'FUTURA & Ext/Uit 18, Pietersburg',
		Code: '0699',
		Hub: 'PTG',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 400,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'FYNNLAND Boxes',
		Code: '4020    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'FYNNLAND, Durban',
		Code: '4052    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'GA-RANKUWA',
		Code: '0208    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 0,
		AreaName: 'Johannesburg Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'GALEHEATH, Durban',
		Code: '4001',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'GALESHEWE Boxes',
		Code: '8330    ',
		Hub: 'KIM',
		Ring: 0,
		OpsHub: 'KIM',
		Dist: 20,
		AreaName: 'Kimberley Central',
		Surch: null,
	},
	{
		PlaceName: 'GALESHEWE VILLAGE, Mankurawane',
		Code: '8345    ',
		Hub: 'KIM',
		Ring: 4,
		OpsHub: 'KIM',
		Dist: 200,
		AreaName: 'Kimberley Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'GALESHEWE, Mankurwane',
		Code: '8345    ',
		Hub: 'KIM',
		Ring: 4,
		OpsHub: 'KIM',
		Dist: 200,
		AreaName: 'Kimberley Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'GALLEON Est/Lgd, Milnerton',
		Code: '7441    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'GALLO MANOR Boxes',
		Code: '2052    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'GALLO MANOR Ext/Uit 3, Wendywood',
		Code: '2148    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'GALLO MANOR, Johannesburg',
		Code: '2052    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'GAMALAKHE',
		Code: '4249    ',
		Hub: 'DUR',
		Ring: 2,
		OpsHub: 'DUR',
		Dist: 110,
		AreaName: 'Durban Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'GAMATLALA, Bakone',
		Code: '0746    ',
		Hub: 'JNB',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 300,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'GAMBLE, Uitenhage',
		Code: '6241    ',
		Hub: 'PLZ',
		Ring: 1,
		OpsHub: 'PLZ',
		Dist: 50,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'GAMBLEVILLE',
		Code: '6241    ',
		Hub: 'PLZ',
		Ring: 1,
		OpsHub: 'PLZ',
		Dist: 50,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'GAMTOOS',
		Code: '6360    ',
		Hub: 'PLZ',
		Ring: 2,
		OpsHub: 'PLZ',
		Dist: 100,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: "GANDHI'S HILL, Tongaat",
		Code: '4399',
		Hub: 'DUR',
		Ring: 1,
		OpsHub: 'DUR',
		Dist: 70,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'GANDHINAGAR, Tongaat',
		Code: '4399',
		Hub: 'DUR',
		Ring: 1,
		OpsHub: 'DUR',
		Dist: 70,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'GANS BAY',
		Code: '7220    ',
		Hub: 'CPT',
		Ring: 2,
		OpsHub: 'CPT',
		Dist: 120,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'GANSBAAI',
		Code: '7220    ',
		Hub: 'CPT',
		Ring: 2,
		OpsHub: 'CPT',
		Dist: 120,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'GANSPAN',
		Code: '8552    ',
		Hub: 'VRY',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 230,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'GANYESA',
		Code: '8613',
		Hub: 'VRY',
		Ring: 2,
		OpsHub: 'JNB',
		Dist: 170,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'GARAGAMS',
		Code: '8223    ',
		Hub: 'CPT',
		Ring: 4,
		OpsHub: 'CPT',
		Dist: 580,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'GARDEN CITY, Milnerton',
		Code: '7441    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'GARDEN VIEW',
		Code: '2047    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'GARDEN VILLAGE, Daveyton',
		Code: '1507    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'GARDEN VILLAGE, Maitland',
		Code: '7405    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'GARDEN VILLAGE, Somerset',
		Code: '7130    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 30,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'GARDENIA PARK, Bloemfontein',
		Code: '9301',
		Hub: 'BFN',
		Ring: 0,
		OpsHub: 'BFN',
		Dist: 30,
		AreaName: 'Bloemfontein Central',
		Surch: null,
	},
	{
		PlaceName: 'GARDENS, Cape Town',
		Code: '8001',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'GARDENS, Johannesburg',
		Code: '2192    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'GARIEPWATER, Grootdrink',
		Code: '8822    ',
		Hub: 'BFN',
		Ring: 4,
		OpsHub: 'BFN',
		Dist: 570,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'GARIES',
		Code: '8220    ',
		Hub: 'CPT',
		Ring: 4,
		OpsHub: 'CPT',
		Dist: 520,
		AreaName: 'Cape Town Regional',
		Surch: 'Far Outlying',
	},
	{
		PlaceName: 'GARLANDDALE, Lansdowne',
		Code: '7780    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'GARLMORE, Pinetown',
		Code: '3610',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'GARSFONTEIN EAST, Pretoria',
		Code: '0042    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'GARSFONTEIN WEST, Pretoria',
		Code: '0042    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'GARSFONTEIN, Pretoria',
		Code: '0042    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'GASTROW, Strand',
		Code: '7140    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 30,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'GATELY, East London',
		Code: '5201    ',
		Hub: 'ELS',
		Ring: 0,
		OpsHub: 'ELS',
		Dist: 10,
		AreaName: 'East London Central',
		Surch: null,
	},
	{
		PlaceName: 'GATESVILLE, Cape Town',
		Code: '7764    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'GAYLANDS, Constantia',
		Code: '7800    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'GAYLEE, Blackheath',
		Code: '7581    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'GEDENK',
		Code: '0537    ',
		Hub: 'PRY',
		Ring: 4,
		OpsHub: 'PRY',
		Dist: 180,
		AreaName: 'Pretoria Regional',
		Surch: null,
	},
	{
		PlaceName: 'GEDULD & Ext/Uit, Springs',
		Code: '1559    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'GEDULD Boxes',
		Code: '1562    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'GEDULD Ext/Uit 1, Springs',
		Code: '1559    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'GEELHOUTPARK & Uit, Rustenburg',
		Code: '0300    ',
		Hub: 'JNB',
		Ring: 2,
		OpsHub: 'JNB',
		Dist: 120,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'GEELHOUTPARK Uit 4, Rustenburg',
		Code: '0300    ',
		Hub: 'JNB',
		Ring: 2,
		OpsHub: 'JNB',
		Dist: 120,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'GELDENHUYS SlHs/Khw, Primrose',
		Code: '1401    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'GELUKSBURG',
		Code: '3377    ',
		Hub: 'HSM',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 270,
		AreaName: 'Johannesburg Regional',
		Surch: 'Far Outlying',
	},
	{
		PlaceName: 'GELUKSDAL',
		Code: '1546    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'GELUKSPRUIT',
		Code: '8812    ',
		Hub: 'BFN',
		Ring: 4,
		OpsHub: 'BFN',
		Dist: 620,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'GELUKWAARTS,Kroonstad',
		Code: '9499    ',
		Hub: 'BFN',
		Ring: 3,
		OpsHub: 'BFN',
		Dist: 170,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'GELVAN PARK, Port Elizabeth',
		Code: '6020    ',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'GELVANDALE Boxes',
		Code: '6016    ',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'GELVANDALE, Port Elizabeth',
		Code: '6020    ',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'GEMDENE, Kimberley',
		Code: '8301    ',
		Hub: 'KIM',
		Ring: 0,
		OpsHub: 'KIM',
		Dist: 20,
		AreaName: 'Kimberley Central',
		Surch: null,
	},
	{
		PlaceName: 'GENADENDAL',
		Code: '7234    ',
		Hub: 'CPT',
		Ring: 3,
		OpsHub: 'CPT',
		Dist: 130,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'GENAZZANO, Tongaat',
		Code: '4399',
		Hub: 'DUR',
		Ring: 1,
		OpsHub: 'DUR',
		Dist: 70,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'GENERAAL ALBERTSPARK, Alberton',
		Code: '1449    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'GENERAAL DE WET, Bloemfontein',
		Code: '9301',
		Hub: 'BFN',
		Ring: 0,
		OpsHub: 'BFN',
		Dist: 30,
		AreaName: 'Bloemfontein Central',
		Surch: null,
	},
	{
		PlaceName: 'GENERAAL KEMP HEUWEL, Pretoria',
		Code: '0187    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'GENEVAFONTEIN, George',
		Code: '6529    ',
		Hub: 'GRJ',
		Ring: 0,
		OpsHub: 'GRJ',
		Dist: 10,
		AreaName: 'George Central',
		Surch: null,
	},
	{
		PlaceName: 'GEORGE',
		Code: '6529    ',
		Hub: 'GRJ',
		Ring: 0,
		OpsHub: 'GRJ',
		Dist: 10,
		AreaName: 'George Central',
		Surch: null,
	},
	{
		PlaceName: 'GEORGE Boxes',
		Code: '6530    ',
		Hub: 'GRJ',
		Ring: 0,
		OpsHub: 'GRJ',
		Dist: 10,
		AreaName: 'George Central',
		Surch: null,
	},
	{
		PlaceName: 'GEORGE EAST Boxes',
		Code: '6539    ',
		Hub: 'GRJ',
		Ring: 0,
		OpsHub: 'GRJ',
		Dist: 10,
		AreaName: 'George Central',
		Surch: null,
	},
	{
		PlaceName: 'GEORGE EAST, George',
		Code: '6529    ',
		Hub: 'GRJ',
		Ring: 0,
		OpsHub: 'GRJ',
		Dist: 10,
		AreaName: 'George Central',
		Surch: null,
	},
	{
		PlaceName: 'GEORGE GOCH, Johannesburg',
		Code: '2094    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'GEORGE SOUTH, George',
		Code: '6529    ',
		Hub: 'GRJ',
		Ring: 0,
		OpsHub: 'GRJ',
		Dist: 10,
		AreaName: 'George Central',
		Surch: null,
	},
	{
		PlaceName: 'GEORGE-INDUSTRIA Boxes',
		Code: '6536    ',
		Hub: 'GRJ',
		Ring: 0,
		OpsHub: 'GRJ',
		Dist: 10,
		AreaName: 'George Central',
		Surch: null,
	},
	{
		PlaceName: 'GEORGE-OOS Busse',
		Code: '6539    ',
		Hub: 'GRJ',
		Ring: 0,
		OpsHub: 'GRJ',
		Dist: 10,
		AreaName: 'George Central',
		Surch: null,
	},
	{
		PlaceName: 'GEORGEDALE',
		Code: '3710    ',
		Hub: 'DUR',
		Ring: 1,
		OpsHub: 'DUR',
		Dist: 50,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'GEORGETOWN, Germiston',
		Code: '1401    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'GEORGEVILLE, Pretoria',
		Code: '0184    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'GEORGINIA, Roodepoort',
		Code: '1724    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'GEQINDLEBE, Tongaat',
		Code: '4399',
		Hub: 'DUR',
		Ring: 1,
		OpsHub: 'DUR',
		Dist: 70,
		AreaName: 'Durban Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'GERALD SMITH, Uitenhage',
		Code: '6241    ',
		Hub: 'PLZ',
		Ring: 1,
		OpsHub: 'PLZ',
		Dist: 50,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'GERDAU',
		Code: '2729    ',
		Hub: 'JNB',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 300,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'GERDVIEW, Primrose',
		Code: '1401    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'GERMEX Boxes',
		Code: '1409    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'GERMEX, Germiston',
		Code: '1401    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'GERMISTON Boxes',
		Code: '1400    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'GERMISTON EAST, Germiston',
		Code: '1401    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'GERMISTON NORTH, Germiston',
		Code: '1401    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'GERMISTON SOUTH Boxes',
		Code: '1411    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'GERMISTON SOUTH, Germiston',
		Code: '1401    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'GERMISTON WEST, Germiston',
		Code: '1401    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'GERMISTON, Johannesburg',
		Code: '1401    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'GERMISTON-NOORD, Germiston',
		Code: '1401    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'GERMISTON-OOS, Germiston',
		Code: '1401    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'GERMISTON-SUID Busse',
		Code: '1411    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'GERMISTON-SUID, Germiston',
		Code: '1401    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'GERMISTON-WES, Germiston',
		Code: '1401    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'GEVANGENISTERREIN, Kroonstad',
		Code: '9499    ',
		Hub: 'BFN',
		Ring: 3,
		OpsHub: 'BFN',
		Dist: 170,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'GEVANGENISTERREIN, Pretoria',
		Code: '0002    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'GEYSDORP',
		Code: '2771    ',
		Hub: 'JNB',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 350,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'GEZINA, Pretoria',
		Code: '0084    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'GEZINSILA, Eshowe',
		Code: '3815    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 200,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'GEZUBUSO',
		Code: '4515    ',
		Hub: 'DUR',
		Ring: 2,
		OpsHub: 'DUR',
		Dist: 100,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'GHOLFSIG, Middelburg',
		Code: '1050    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 140,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'GIBBONSVILLE, Rugby',
		Code: '7405    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'GIJANYO, Isipingo',
		Code: '4133',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'GIJIMA, Lamontville',
		Code: '4027    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'GILLITTS (Natal) Boxes',
		Code: '3603    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'GILLITTS, Pinetown',
		Code: '3610',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'GILLITTS, Port Elizabeth',
		Code: '6070',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'GILLVIEW, Johannesburg',
		Code: '2091    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'GINGINDLOVU',
		Code: '3800    ',
		Hub: 'DUR',
		Ring: 2,
		OpsHub: 'DUR',
		Dist: 170,
		AreaName: 'Durban Regional',
		Surch: 'Township',
	},
	{
		PlaceName: "GINSBURG, King William's",
		Code: '5600    ',
		Hub: 'ELS',
		Ring: 1,
		OpsHub: 'ELS',
		Dist: 70,
		AreaName: 'East London Regional',
		Surch: null,
	},
	{
		PlaceName: 'GIYANI',
		Code: '0826    ',
		Hub: 'PTG',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 170,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'GLEBELANDS, Ntokozweni',
		Code: '4066    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'GLEEMOOR, Athlone',
		Code: '7764    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'GLEN ALPHINE Est/Lgd,Constantia',
		Code: '7800    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'GLEN ANIL, Durban',
		Code: '4051',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'GLEN ATHOLL, Johannesburg',
		Code: '2090    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'GLEN AUSTIN, Halfway House',
		Code: '1685',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'GLEN BARRIE, George',
		Code: '6529',
		Hub: 'GRJ',
		Ring: 0,
		OpsHub: 'GRJ',
		Dist: 10,
		AreaName: 'George Central',
		Surch: null,
	},
	{
		PlaceName: 'GLEN COWIE',
		Code: '1061    ',
		Hub: 'MPG',
		Ring: 2,
		OpsHub: 'JNB',
		Dist: 140,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'GLEN ERASMIA,Kempton Park',
		Code: '1619',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'GLEN HARMONY Boxes',
		Code: '9435    ',
		Hub: 'BFN',
		Ring: 2,
		OpsHub: 'BFN',
		Dist: 20,
		AreaName: 'Bloemfontein Central',
		Surch: null,
	},
	{
		PlaceName: 'GLEN HARMONY, Virginia',
		Code: '9430    ',
		Hub: 'BFN',
		Ring: 2,
		OpsHub: 'BFN',
		Dist: 20,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'GLEN HURD, Port Elizabeth',
		Code: '6045    ',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'GLEN IVE, Bellville',
		Code: '7530    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'GLEN IVE, Durbanville',
		Code: '7550    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'GLEN IVE, Plumstead',
		Code: '7800    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'GLEN KAY, Johannesburg',
		Code: '2192    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'GLEN LAURISTON, Valhalla',
		Code: '0185    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'GLEN LEA, Florida',
		Code: '1709    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'GLEN NERINE, Bryanston',
		Code: '2021    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'GLENABBOT, Constantia',
		Code: '7800    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'GLENADRIENNE, Johannesburg',
		Code: '2196    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'GLENANDA NORTH, Johannesburg',
		Code: '2091    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'GLENANDA SOUTH, Johannesburg',
		Code: '2091    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'GLENANDA, Johannesburg',
		Code: '2091    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'GLENANDA-NOORD, Johannesburg',
		Code: '2091    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'GLENANDA-SUID, Johannesburg',
		Code: '2091    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'GLENASHLEY',
		Code: '4051',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'GLENASHLEY Boxes',
		Code: '4022    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'GLENCAIRN HEIGHTS, Simons Town',
		Code: '7975',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 70,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'GLENCAIRN, Simons Town',
		Code: '7975',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 70,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'GLENCOE',
		Code: '2930    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 310,
		AreaName: 'Durban Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'GLENCONNOR',
		Code: '6234    ',
		Hub: 'PLZ',
		Ring: 2,
		OpsHub: 'PLZ',
		Dist: 100,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'GLENDALE Est/Lgd, Plumstead',
		Code: '7945    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'GLENDALE Est/Lgd, Stanger',
		Code: '4450    ',
		Hub: 'DUR',
		Ring: 2,
		OpsHub: 'DUR',
		Dist: 120,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'GLENDALE GARDENS, Queensburgh',
		Code: '4093',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'GLENDALE, Durbanville',
		Code: '7550    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'GLENDINNINGVALE, Port Elizabeth',
		Code: '6001    ',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'GLENDOWER, Edenvale',
		Code: '1609    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'GLENESK, Johannesburg',
		Code: '2190    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'GLENHARVIE',
		Code: '1786    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 50,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'GLENHAVEN Est/Lgd, Bellville',
		Code: '7530    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'GLENHAZEL, Johannesburg',
		Code: '2192    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'GLENHILLS, Durban',
		Code: '4051',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'GLENIFFER, Cramerview',
		Code: '2060    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'GLENLILLY Est/Lgd, Parow',
		Code: '7500    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'GLENMAAR, Athlone',
		Code: '7764    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'GLENMARAIS & Ext/Uit,',
		Code: '1619    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'GLENMARAIS Ext/Uit 1,',
		Code: '1619    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'GLENMILL',
		Code: '4452    ',
		Hub: 'DUR',
		Ring: 3,
		OpsHub: 'DUR',
		Dist: 140,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'GLENMORE, Durban',
		Code: '4001    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'GLENNIE, Durbanville',
		Code: '7550    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'GLENPARK, Pinetown',
		Code: '3610',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'GLENROY PARK, Port Elizabeth',
		Code: '6025',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'GLENSAN, Johannesburg',
		Code: '2192    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'GLENSIDE',
		Code: '3477    ',
		Hub: 'DUR',
		Ring: 2,
		OpsHub: 'DUR',
		Dist: 110,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'GLENSTANTIA, Pretoria',
		Code: '0010    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'GLENTANA, Groot-brakrivier',
		Code: '6525',
		Hub: 'GRJ',
		Ring: 1,
		OpsHub: 'GRJ',
		Dist: 50,
		AreaName: 'George Regional',
		Surch: null,
	},
	{
		PlaceName: 'GLENVARLOCH, Nigel',
		Code: '1490    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 45,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'GLENVISTA Boxes',
		Code: '2058    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'GLENVISTA, Johannesburg',
		Code: '2091    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'GLENWOOD, Durban',
		Code: '4001    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'GLENWOOD, Pietermaritzburg',
		Code: '3201    ',
		Hub: 'PMB',
		Ring: 1,
		OpsHub: 'PMB',
		Dist: 75,
		AreaName: 'Pietermaritzburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'GLENWOOD, Plumstead',
		Code: '7800    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'GLOSDERRY',
		Code: '7702    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'GOEDE HOOP, Strand',
		Code: '7140',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 50,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'GOEDEBURG & Ext/Uit 3,',
		Code: '1501    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'GOEDEDACHT, Kroonstad',
		Code: '9499    ',
		Hub: 'BFN',
		Ring: 3,
		OpsHub: 'BFN',
		Dist: 170,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'GOEDEHOOP, Pretoria',
		Code: '0002    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'GOEDEMOED, Durbanville',
		Code: '7550    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'GOEDVERWAG',
		Code: '7323    ',
		Hub: 'CPT',
		Ring: 3,
		OpsHub: 'CPT',
		Dist: 130,
		AreaName: 'Cape Town Regional',
		Surch: 'Far Outlying',
	},
	{
		PlaceName: 'GOLD REEF CITY',
		Code: '2159    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'GOLDEN ACRE, Somerset West',
		Code: '7130',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 40,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'GOLDEN HARVEST, Randburg',
		Code: '2194    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'GOLDEN VALLEY ',
		Code: '5821    ',
		Hub: 'PLZ',
		Ring: 4,
		OpsHub: 'PLZ',
		Dist: 170,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'GOLDWATER, Port Elizabeth',
		Code: '6070',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'GOLELA',
		Code: '3990    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 420,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'GOLF Est/Lgd, Wynberg',
		Code: '7800    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'GOLF LINKS Est/Lgd, Wynberg',
		Code: '7800    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'GOLF PARK, Meyerton',
		Code: '1960    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 60,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'GOMPIES',
		Code: '0631    ',
		Hub: 'PRY',
		Ring: 4,
		OpsHub: 'PRY',
		Dist: 300,
		AreaName: 'Pretoria Regional',
		Surch: null,
	},
	{
		PlaceName: 'GOMPO TOWN, East London',
		Code: '5209    ',
		Hub: 'ELS',
		Ring: 0,
		OpsHub: 'ELS',
		Dist: 10,
		AreaName: 'East London Central',
		Surch: null,
	},
	{
		PlaceName: 'GONUBIE',
		Code: '5256    ',
		Hub: 'ELS',
		Ring: 0,
		OpsHub: 'ELS',
		Dist: 10,
		AreaName: 'East London Central',
		Surch: null,
	},
	{
		PlaceName: 'GOODHOPE MODEL VILLAGE,',
		Code: '7405    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'GOODWOOD PARK, Goodwood',
		Code: '7460    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'GOODWOOD WEST, Goodwood',
		Code: '7460    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'GOODWOOD, Cape Town',
		Code: '7460    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'GOODWOOD-WES, Goodwood',
		Code: '7460    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: "GORDON'S BAY",
		Code: '7150    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 50,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: "GORDON'S BAY (S)",
		Code: '7150    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 50,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'GOSFORTH PARK, Rand Airport',
		Code: '1419    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'GOUDA',
		Code: '6821    ',
		Hub: 'CPT',
		Ring: 2,
		OpsHub: 'CPT',
		Dist: 100,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'GOUDINI ROAD',
		Code: '6856    ',
		Hub: 'CPT',
		Ring: 2,
		OpsHub: 'CPT',
		Dist: 120,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'GOUDINIWEG',
		Code: '6856    ',
		Hub: 'CPT',
		Ring: 2,
		OpsHub: 'CPT',
		Dist: 120,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'GOUDVELD',
		Code: '2507    ',
		Hub: 'JNB',
		Ring: 2,
		OpsHub: 'JNB',
		Dist: 100,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'GOURITSMOND',
		Code: '6796    ',
		Hub: 'GRJ',
		Ring: 3,
		OpsHub: 'GRJ',
		Dist: 90,
		AreaName: 'George Regional',
		Surch: null,
	},
	{
		PlaceName: 'GOVERNMENT VILLAGE, Benoni',
		Code: '1501    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'GRAAFF-REINET',
		Code: '6280    ',
		Hub: 'PLZ',
		Ring: 1,
		OpsHub: 'PLZ',
		Dist: 290,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'GRAAFWATER',
		Code: '8120    ',
		Hub: 'CPT',
		Ring: 2,
		OpsHub: 'CPT',
		Dist: 200,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'GRABOUW',
		Code: '7160    ',
		Hub: 'CPT',
		Ring: 1,
		OpsHub: 'CPT',
		Dist: 60,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'GRAHAMDALE, Bellville',
		Code: '7530    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'GRAHAMSTAD',
		Code: '6140    ',
		Hub: 'PLZ',
		Ring: 1,
		OpsHub: 'PLZ',
		Dist: 170,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'GRAHAMSTOWN',
		Code: '6140    ',
		Hub: 'PLZ',
		Ring: 1,
		OpsHub: 'PLZ',
		Dist: 170,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'GRAND CENTRAL, Midrand',
		Code: '1685',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'GRANGE, Pietermaritzburg',
		Code: '3201    ',
		Hub: 'PMB',
		Ring: 1,
		OpsHub: 'PMB',
		Dist: 75,
		AreaName: 'Pietermaritzburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'GRANGER BAY',
		Code: '8005',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'GRANT PARK',
		Code: '2051    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'GRANTHAM EXT 22',
		Code: '3880',
		Hub: 'DUR',
		Ring: 1,
		OpsHub: 'DUR',
		Dist: 180,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'GRANTHAM PARK, Empangeni',
		Code: '3880',
		Hub: 'DUR',
		Ring: 1,
		OpsHub: 'DUR',
		Dist: 180,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'GRANTHAM, Empangeni',
		Code: '3880',
		Hub: 'DUR',
		Ring: 1,
		OpsHub: 'DUR',
		Dist: 180,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'GRASKOP',
		Code: '1270    ',
		Hub: 'MPG',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 350,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'GRASMERE',
		Code: '1828    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 30,
		AreaName: 'Johannesburg Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'GRASSY PARK Boxes',
		Code: '7888    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'GRASSY PARK, Cape Town',
		Code: '7945    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'GRAVELOTTE',
		Code: '0895    ',
		Hub: 'PTG',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 300,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'GRAYLANDS, Constantia',
		Code: '7806',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'GRAYLEIGH, Westville',
		Code: '3629',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'GREAT BRAK RIVER',
		Code: '6525    ',
		Hub: 'GRJ',
		Ring: 1,
		OpsHub: 'GRJ',
		Dist: 50,
		AreaName: 'George Regional',
		Surch: null,
	},
	{
		PlaceName: 'GREEN POINT',
		Code: '8005',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'GREEN POINT Boxes',
		Code: '8051    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'GREEN POINT, Cape Town',
		Code: '8005',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'GREEN POINT, Khayelitsha',
		Code: '7784',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'GREENACRES Boxes',
		Code: '6057    ',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'GREENACRES, Port Elizabeth',
		Code: '6045    ',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'GREENBURY, Phoenix',
		Code: '4068',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'GREENBUSHES',
		Code: '6390    ',
		Hub: 'PLZ',
		Ring: 2,
		OpsHub: 'PLZ',
		Dist: 90,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'GREENFIELD Est/Lgd, Rondebosch',
		Code: '7780    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'GREENFIELD, Blackheath',
		Code: '7581    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'GREENFIELDS Boxes',
		Code: '5208    ',
		Hub: 'ELS',
		Ring: 0,
		OpsHub: 'ELS',
		Dist: 10,
		AreaName: 'East London Central',
		Surch: null,
	},
	{
		PlaceName: 'GREENFIELDS, East London',
		Code: '5201    ',
		Hub: 'ELS',
		Ring: 0,
		OpsHub: 'ELS',
		Dist: 10,
		AreaName: 'East London Central',
		Surch: null,
	},
	{
		PlaceName: 'GREENHAVEN, Athlone',
		Code: '7764    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'GREENHILL VILLAGE, Mitchels Plain',
		Code: '7785    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'GREENHILLS Boxes',
		Code: '1767    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'GREENHILLS, Paarl',
		Code: '7646    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 60,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'GREENHILLS, Randfontein',
		Code: '1760    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'GREENOCK PARK, Port Elizabeth',
		Code: '6025',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'GREENPOINT, Kimberley',
		Code: '8301',
		Hub: 'KIM',
		Ring: 1,
		OpsHub: 'KIM',
		Dist: 20,
		AreaName: 'Kimberley Regional',
		Surch: null,
	},
	{
		PlaceName: 'GREENPOINT, Petrusville',
		Code: '8770',
		Hub: 'BFN',
		Ring: 4,
		OpsHub: 'BFN',
		Dist: 250,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'GREENSHIELDS PARK, Port Elizabeth',
		Code: '6070',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'GREENSIDE & Ext/Uit, Johannesburg',
		Code: '2193    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'GREENSIDE Boxes',
		Code: '2034    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'GREENSIDE EAST, Johannesburg',
		Code: '2193    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'GREENSIDE, Kimberley',
		Code: '8301    ',
		Hub: 'KIM',
		Ring: 0,
		OpsHub: 'KIM',
		Dist: 20,
		AreaName: 'Kimberley Central',
		Surch: null,
	},
	{
		PlaceName: 'GREENSIDE-OOS, Johannesburg',
		Code: '2193    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'GREENSTONE HILL, Johannesburg',
		Code: '1609',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'GREENVILLE, Darling',
		Code: '7345    ',
		Hub: 'CPT',
		Ring: 1,
		OpsHub: 'CPT',
		Dist: 80,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'GREENWAY RISE, Somerset West',
		Code: '6665',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 50,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'GREENWOOD PARK, Durban',
		Code: '4051',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'GRESSWOLD, Johannesburg',
		Code: '2090    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'GREYLINGSTAD',
		Code: '2415    ',
		Hub: 'JNB',
		Ring: 2,
		OpsHub: 'JNB',
		Dist: 120,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'GREYMONT Boxes',
		Code: '2035    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'GREYMONT, Johannesburg',
		Code: '2195    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'GREYTON, C/K',
		Code: '7233    ',
		Hub: 'CPT',
		Ring: 3,
		OpsHub: 'CPT',
		Dist: 130,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'GREYTOWN, N',
		Code: '3500    ',
		Hub: 'HSM',
		Ring: 3,
		OpsHub: 'JNB',
		Dist: 150,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'GREYVILLE Boxes',
		Code: '4023    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'GREYVILLE, Durban',
		Code: '4001    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'GREYVILLE, Lenasia',
		Code: '1820    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 30,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'GRIEKWASTAD',
		Code: '8365    ',
		Hub: 'KIM',
		Ring: 4,
		OpsHub: 'KIM',
		Dist: 410,
		AreaName: 'Kimberley Regional',
		Surch: null,
	},
	{
		PlaceName: 'GRIMBEEKPARK, Potchefstroon',
		Code: '2520    ',
		Hub: 'POT',
		Ring: 3,
		OpsHub: 'JNB',
		Dist: 150,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'GRIQUATOWN',
		Code: '8365    ',
		Hub: 'BFN',
		Ring: 4,
		OpsHub: 'BFN',
		Dist: 410,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'GROBLERSDAL',
		Code: '0470    ',
		Hub: 'PRY',
		Ring: 1,
		OpsHub: 'PRY',
		Dist: 180,
		AreaName: 'Pretoria Regional',
		Surch: null,
	},
	{
		PlaceName: 'GROBLERSPARK & Ext/Uit 9,',
		Code: '1724    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'GROENDAL, Franschhoek',
		Code: '7690    ',
		Hub: 'CPT',
		Ring: 2,
		OpsHub: 'CPT',
		Dist: 90,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'GROENENDAL, Bellville',
		Code: '7530    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'GROENEWEIDE, Boksburg',
		Code: '1459    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'GROENKLOOF, Pretoria',
		Code: '0181    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'GROENPUNT Busse',
		Code: '8051    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'GROENPUNT, Kaapstad',
		Code: '8005',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'GROENVALLEI, Bellville',
		Code: '7530    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'GROENVLEI, Bloemfontein',
		Code: '9301',
		Hub: 'BFN',
		Ring: 0,
		OpsHub: 'BFN',
		Dist: 20,
		AreaName: 'Bloemfontein Central',
		Surch: null,
	},
	{
		PlaceName: 'GROENVLEI, Lansdowne',
		Code: '7780    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'GROENVLEI, Paarl',
		Code: '7646    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 60,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'GROOT JONGENSFONTEIN, Stilbaai',
		Code: '6675',
		Hub: 'GRJ',
		Ring: 4,
		OpsHub: 'GRJ',
		Dist: 100,
		AreaName: 'George Regional',
		Surch: null,
	},
	{
		PlaceName: 'GROOT-BRAKRIVIER',
		Code: '6525    ',
		Hub: 'GRJ',
		Ring: 1,
		OpsHub: 'GRJ',
		Dist: 50,
		AreaName: 'George Regional',
		Surch: null,
	},
	{
		PlaceName: 'GROOT-DRAKENSTEIN',
		Code: '7680    ',
		Hub: 'CPT',
		Ring: 2,
		OpsHub: 'CPT',
		Dist: 90,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'GROOT-MARICO',
		Code: '2850    ',
		Hub: 'RBG',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 270,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'GROOTBRAKRIVER',
		Code: '6525    ',
		Hub: 'GRJ',
		Ring: 1,
		OpsHub: 'GRJ',
		Dist: 60,
		AreaName: 'George Regional',
		Surch: null,
	},
	{
		PlaceName: 'GROOTDERM',
		Code: '8291    ',
		Hub: 'CPT',
		Ring: 4,
		OpsHub: 'CPT',
		Dist: 900,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'GROOTDRIF',
		Code: '8172    ',
		Hub: 'CPT',
		Ring: 4,
		OpsHub: 'CPT',
		Dist: 350,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'GROOTE SCHUUR Boxes',
		Code: '7937    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'GROOTE SCHUUR HOSPITAL',
		Code: '7925    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'GROOTE SCHUURHOSPITAAL, O',
		Code: '7925    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'GROOTFONTEIN COUNTRY ESTATES',
		Code: '0081',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 30,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'GROOTHOEKHOSPITAAL',
		Code: '0628    ',
		Hub: 'PRY',
		Ring: 1,
		OpsHub: 'PRY',
		Dist: 50,
		AreaName: 'Pretoria Regional',
		Surch: null,
	},
	{
		PlaceName: 'GROOTSPRUIT',
		Code: '3181    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 400,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'GROOTVLEI',
		Code: '2420    ',
		Hub: 'JNB',
		Ring: 2,
		OpsHub: 'JNB',
		Dist: 100,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'GROOTVLEI, Bloemfontein',
		Code: '9301',
		Hub: 'BFN',
		Ring: 0,
		OpsHub: 'BFN',
		Dist: 30,
		AreaName: 'Bloemfontein Central',
		Surch: null,
	},
	{
		PlaceName: 'GROOTVLEI, Pyramid',
		Code: '0120    ',
		Hub: 'PRY',
		Ring: 2,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Regional',
		Surch: null,
	},
	{
		PlaceName: 'GROOTVLEI, Springs',
		Code: '1559    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'GROSVENOR, Durban',
		Code: '4052    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'GROVE END, Phoenix',
		Code: '4068',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'GUGULETU',
		Code: '7750    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'GUGULETU, Mtubatuba',
		Code: '3935',
		Hub: 'DUR',
		Ring: 1,
		OpsHub: 'DUR',
		Dist: 300,
		AreaName: 'Durban Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'GULU, East London',
		Code: '5200',
		Hub: 'ELS',
		Ring: 0,
		OpsHub: 'ELS',
		Dist: 20,
		AreaName: 'East London Regional',
		Surch: 'Mines/Farms/Power Plants',
	},
	{
		PlaceName: 'GUMTREE',
		Code: '9731    ',
		Hub: 'BFN',
		Ring: 4,
		OpsHub: 'BFN',
		Dist: 170,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'Ga-SEHUNELO Boxes',
		Code: '9315    ',
		Hub: 'BFN',
		Ring: 0,
		OpsHub: 'BFN',
		Dist: 30,
		AreaName: 'Bloemfontein Central',
		Surch: null,
	},
	{
		PlaceName: 'Ga-SEHUNELO, Bloemfontein',
		Code: '9323    ',
		Hub: 'BFN',
		Ring: 0,
		OpsHub: 'BFN',
		Dist: 30,
		AreaName: 'Bloemfontein Central',
		Surch: null,
	},
	{
		PlaceName: 'Ga-TLHOSE',
		Code: '8461    ',
		Hub: 'BFN',
		Ring: 4,
		OpsHub: 'BFN',
		Dist: 420,
		AreaName: 'Bloemfontein Regional',
		Surch: 'Far Outlying',
	},
	{
		PlaceName: 'GaKGAPANE',
		Code: '0838    ',
		Hub: 'PTG',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 300,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'GaLEBELO',
		Code: '0615    ',
		Hub: 'PTG',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 300,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'GaMAROTA',
		Code: '1151    ',
		Hub: 'JNB',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 300,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'GaMOTHIBA',
		Code: '0726    ',
		Hub: 'PTG',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 300,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'GaNKWANA',
		Code: '0740    ',
		Hub: 'MPG',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 300,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'GaRAKGWADI',
		Code: '1068    ',
		Hub: 'JNB',
		Ring: 2,
		OpsHub: 'JNB',
		Dist: 140,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'HA-RAMOSA',
		Code: '2516    ',
		Hub: 'JNB',
		Ring: 2,
		OpsHub: 'JNB',
		Dist: 100,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'HAARLEM',
		Code: '6467    ',
		Hub: 'GRJ',
		Ring: 2,
		OpsHub: 'GRJ',
		Dist: 110,
		AreaName: 'George Regional',
		Surch: null,
	},
	{
		PlaceName: 'HADDON, Johannesburg',
		Code: '2190    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'HADISON PARK Boxes',
		Code: '8306    ',
		Hub: 'KIM',
		Ring: 0,
		OpsHub: 'KIM',
		Dist: 20,
		AreaName: 'Kimberley Central',
		Surch: null,
	},
	{
		PlaceName: 'HADISON PARK, Kimberley',
		Code: '8301    ',
		Hub: 'KIM',
		Ring: 0,
		OpsHub: 'KIM',
		Dist: 20,
		AreaName: 'Kimberley Central',
		Surch: null,
	},
	{
		PlaceName: 'HAENERTSBURG',
		Code: '0730    ',
		Hub: 'PTG',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 300,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'HAGA-HAGA',
		Code: '5272    ',
		Hub: 'ELS',
		Ring: 0,
		OpsHub: 'ELS',
		Dist: 10,
		AreaName: 'East London Central',
		Surch: null,
	},
	{
		PlaceName: 'HALCYON DRIFT',
		Code: '5483    ',
		Hub: 'ELS',
		Ring: 4,
		OpsHub: 'ELS',
		Dist: 300,
		AreaName: 'East London Regional',
		Surch: null,
	},
	{
		PlaceName: 'HALFMANSHOF',
		Code: '6811    ',
		Hub: 'CPT',
		Ring: 2,
		OpsHub: 'CPT',
		Dist: 100,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'HALFWAY GARDENS, Johannesburg',
		Code: '1686',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'HALFWAY HOUSE, Midrand',
		Code: '1685',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'HAMBANATI, Tongaat',
		Code: '4399',
		Hub: 'DUR',
		Ring: 1,
		OpsHub: 'DUR',
		Dist: 70,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'HAMBERG Boxes',
		Code: '1726    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'HAMBERG, Roodepoort',
		Code: '1724    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'HAMILTON Est/Lgd, Eersterfontein',
		Code: '7100    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 15,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'HAMILTON, Bloemfontein',
		Code: '9301',
		Hub: 'BFN',
		Ring: 0,
		OpsHub: 'BFN',
		Dist: 30,
		AreaName: 'Bloemfontein Central',
		Surch: null,
	},
	{
		PlaceName: 'HAMMANSKRAAL',
		Code: '0401    ',
		Hub: 'PRY',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 50,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'HAMMANSKRAAL EXT 6',
		Code: '0407',
		Hub: 'PRY',
		Ring: 1,
		OpsHub: 'PRY',
		Dist: 50,
		AreaName: 'Pretoria Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'HAMMARSDALE',
		Code: '3700    ',
		Hub: 'DUR',
		Ring: 1,
		OpsHub: 'DUR',
		Dist: 60,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'HAMPTON Est/Lgd, Lansdown',
		Code: '7780    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'HANKEY',
		Code: '6350    ',
		Hub: 'PLZ',
		Ring: 1,
		OpsHub: 'PLZ',
		Dist: 80,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'HANOVER',
		Code: '7005',
		Hub: 'BFN',
		Ring: 4,
		OpsHub: 'BFN',
		Dist: 380,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'HANOVER PARK Boxes',
		Code: '7782    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'HANOVER PARK, Athlone',
		Code: '7780    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'HANTAM Boxes',
		Code: '8191    ',
		Hub: 'CPT',
		Ring: 4,
		OpsHub: 'CPT',
		Dist: 420,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'HANTAM, Calvinia',
		Code: '8190    ',
		Hub: 'CPT',
		Ring: 4,
		OpsHub: 'CPT',
		Dist: 420,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'HAPPY VALLEY, De Aar',
		Code: '7000    ',
		Hub: 'BFN',
		Ring: 4,
		OpsHub: 'BFN',
		Dist: 500,
		AreaName: 'Bloemfontein Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'HAPPY VALLEY, Durban',
		Code: '4052    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'HARBURG',
		Code: '3479    ',
		Hub: 'DUR',
		Ring: 2,
		OpsHub: 'DUR',
		Dist: 120,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'HARDALE',
		Code: '5481    ',
		Hub: 'ELS',
		Ring: 4,
		OpsHub: 'ELS',
		Dist: 290,
		AreaName: 'East London Regional',
		Surch: null,
	},
	{
		PlaceName: 'HARDEVLEI, Retreat',
		Code: '7945    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'HARDING',
		Code: '4680    ',
		Hub: 'DUR',
		Ring: 1,
		OpsHub: 'DUR',
		Dist: 180,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'HARDWICK Est/Lgd,Newlands',
		Code: '7700    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'HARFIELD VILLAGE, Claremont',
		Code: '7700    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'HARINAGAR, Queensburgh',
		Code: '4093',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'HARKERVILLE',
		Code: '6604    ',
		Hub: 'GRJ',
		Ring: 2,
		OpsHub: 'GRJ',
		Dist: 110,
		AreaName: 'George Regional',
		Surch: null,
	},
	{
		PlaceName: 'HARMELIA & Ext/Uit 1 & 11',
		Code: '1406    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'HARMELIA, Edenvale (Tvl)',
		Code: '1609    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'HARMONIE, Burgersdorp',
		Code: '5520    ',
		Hub: 'ELS',
		Ring: 4,
		OpsHub: 'ELS',
		Dist: 360,
		AreaName: 'East London Regional',
		Surch: null,
	},
	{
		PlaceName: 'HARMONY PARK, George',
		Code: '6529',
		Hub: 'GRJ',
		Ring: 0,
		OpsHub: 'GRJ',
		Dist: 10,
		AreaName: 'George Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'HARRISMITH',
		Code: '9880    ',
		Hub: 'HSM',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 300,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'HARTBEESFONTEIN',
		Code: '2600    ',
		Hub: 'JNB',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 200,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'HARTBEESHOEK, Pretoria',
		Code: '0182    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Regional',
		Surch: 'Far Outlying',
	},
	{
		PlaceName: 'HARTBEESPOORT',
		Code: '0216    ',
		Hub: 'JNB',
		Ring: 2,
		OpsHub: 'JNB',
		Dist: 100,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'HARTENBOS',
		Code: '6520    ',
		Hub: 'GRJ',
		Ring: 1,
		OpsHub: 'GRJ',
		Dist: 50,
		AreaName: 'George Regional',
		Surch: null,
	},
	{
		PlaceName: 'HARTFONTEIN',
		Code: '0001    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 35,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'HARTLAND',
		Code: '3184    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 400,
		AreaName: 'Durban Regional',
		Surch: 'Far Outlying',
	},
	{
		PlaceName: 'HARTSVALLEI',
		Code: '8534    ',
		Hub: 'BFN',
		Ring: 4,
		OpsHub: 'BFN',
		Dist: 200,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'HARTSWATER',
		Code: '8570    ',
		Hub: 'VRY',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 250,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'HATFIELD, Pretoria',
		Code: '0083    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'HATTINGSPRUIT',
		Code: '3081    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 310,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'HATTON Est/Lgd, Athlone',
		Code: '7764    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'HATTON Est/Lgd, Pinetown',
		Code: '3610',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'HAUPTVILLE, Constantia',
		Code: '7800    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'HAVEN HILLS, East London',
		Code: '5247    ',
		Hub: 'ELS',
		Ring: 0,
		OpsHub: 'ELS',
		Dist: 10,
		AreaName: 'East London Central',
		Surch: null,
	},
	{
		PlaceName: 'HAVENSCREST, Tongaat',
		Code: '4399',
		Hub: 'DUR',
		Ring: 1,
		OpsHub: 'DUR',
		Dist: 70,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'HAVENSIDE, Durban',
		Code: '4092',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'HAWKINS Est/Lgd, Johannesburg',
		Code: '2192    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'HAWSTON',
		Code: '7202    ',
		Hub: 'CPT',
		Ring: 2,
		OpsHub: 'CPT',
		Dist: 90,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'HAWTHORNE Est/Lgd, Retreat',
		Code: '7945    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'HAY PADDOCK, Pietermaritzberg',
		Code: '3201    ',
		Hub: 'PMB',
		Ring: 1,
		OpsHub: 'PMB',
		Dist: 75,
		AreaName: 'Pietermaritzburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'HAYFIELDS, Pietermaritzburg',
		Code: '3201',
		Hub: 'PMB',
		Ring: 1,
		OpsHub: 'PMB',
		Dist: 75,
		AreaName: 'Pietermaritzburg Central',
		Surch: null,
	},
	{
		PlaceName: 'HAZELDENE, Germiston',
		Code: '1401    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'HAZELMERE, Pietermaritzburg',
		Code: '3201    ',
		Hub: 'PMB',
		Ring: 1,
		OpsHub: 'PMB',
		Dist: 75,
		AreaName: 'Pietermaritzburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'HAZELWOOD, Pretoria',
		Code: '0081    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'HAZELWOOD, uMzinto',
		Code: '4200    ',
		Hub: 'DUR',
		Ring: 1,
		OpsHub: 'DUR',
		Dist: 30,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'HAZENDAL Est/Lgd, Athlone',
		Code: '7764    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'HAZENJACHT',
		Code: '6627    ',
		Hub: 'GRJ',
		Ring: 1,
		OpsHub: 'GRJ',
		Dist: 70,
		AreaName: 'George Regional',
		Surch: null,
	},
	{
		PlaceName: 'HAZYVIEW',
		Code: '1242    ',
		Hub: 'NLP',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 330,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'HEATH PARK, Saltville',
		Code: '6059    ',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'HEATHERLANDS, George',
		Code: '6529    ',
		Hub: 'GRJ',
		Ring: 0,
		OpsHub: 'GRJ',
		Dist: 10,
		AreaName: 'George Central',
		Surch: null,
	},
	{
		PlaceName: 'HEATHERLEY Est/Lgd, Lansdowne',
		Code: '7780    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'HEATHFIELD, Retreat',
		Code: '7945    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'HEATLIEVALE',
		Code: '6851    ',
		Hub: 'CPT',
		Ring: 2,
		OpsHub: 'CPT',
		Dist: 120,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'HEATONVILLE',
		Code: '3881    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 250,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'HEBRON, Pretoria',
		Code: '0209',
		Hub: 'PRY',
		Ring: 1,
		OpsHub: 'PRY',
		Dist: 50,
		AreaName: 'Pretoria Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'HECTORSPRUIT',
		Code: '1330    ',
		Hub: 'NLP',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 310,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'HECTORTON, Randfontein',
		Code: '1760    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'HEEMSTEDE, Bellville',
		Code: '7530',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 30,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'HEIDEDAL',
		Code: '9306    ',
		Hub: 'BFN',
		Ring: 0,
		OpsHub: 'BFN',
		Dist: 30,
		AreaName: 'Bloemfontein Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'HEIDEKOPPIE, Brackenfell',
		Code: '7560    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'HEIDELBERG, Gauteng',
		Code: '1411    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 70,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'HEIDELBERG, Western Cape',
		Code: '6665    ',
		Hub: 'GRJ',
		Ring: 4,
		OpsHub: 'GRJ',
		Dist: 150,
		AreaName: 'George Regional',
		Surch: null,
	},
	{
		PlaceName: 'HEIDELBURG,Johannesburg',
		Code: '1441',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 70,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'HEIDERAND, Mossel Bay',
		Code: '6506',
		Hub: 'GRJ',
		Ring: 1,
		OpsHub: 'GRJ',
		Dist: 60,
		AreaName: 'George Regional',
		Surch: null,
	},
	{
		PlaceName: 'HEIDERAND, MosselBay',
		Code: '6506',
		Hub: 'GRJ',
		Ring: 1,
		OpsHub: 'GRJ',
		Dist: 60,
		AreaName: 'George Regional',
		Surch: null,
	},
	{
		PlaceName: 'HEIDEVELD, Athlone',
		Code: '7764    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'HEILBRON',
		Code: '9650    ',
		Hub: 'BFN',
		Ring: 4,
		OpsHub: 'BFN',
		Dist: 270,
		AreaName: 'Bloemfontein Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'HEKPOORT',
		Code: '2800    ',
		Hub: 'JNB',
		Ring: 2,
		OpsHub: 'JNB',
		Dist: 90,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'HELDERBERG Boxes',
		Code: '7135    ',
		Hub: 'CPT',
		Ring: 1,
		OpsHub: 'CPT',
		Dist: 30,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'HELDERBERG, Somerset West',
		Code: '7130    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 30,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'HELDERKRUIN & Ext/Uit 2,',
		Code: '1724    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'HELDERKRUIN Boxes',
		Code: '1733    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'HELDERRANT, Somerset West',
		Code: '7130    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 30,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'HELDERVIEW, Somerset West',
		Code: '7130    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 30,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'HELDERVUE, Somerset West',
		Code: '7130',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 25,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'HELDERZICHT, Somerset West',
		Code: '7130    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 30,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'HELENA HOOGTE, Somerset West',
		Code: '7130    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 30,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'HELENVALE, Port Elizabeth',
		Code: '6020    ',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'HELIKONHOOGTE, Bloemfontein',
		Code: '9301',
		Hub: 'BFN',
		Ring: 0,
		OpsHub: 'BFN',
		Dist: 30,
		AreaName: 'Bloemfontein Central',
		Surch: null,
	},
	{
		PlaceName: 'HELIKONPARK, Randfontein',
		Code: '1760    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'HELLING',
		Code: '2036    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'HENBYL Boxes',
		Code: '1903    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 60,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'HENBYL, Vanderbijlpark',
		Code: '1911    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 60,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'HENLEY ON KLIP',
		Code: '1962    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 60,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'HENNENMAN',
		Code: '9445    ',
		Hub: 'WEL',
		Ring: 3,
		OpsHub: 'JNB',
		Dist: 150,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'HENNOPSEER, Verwoerdburg',
		Code: '0157    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'HENNOPSARK & Ext/Uit 1,',
		Code: '0157    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'HENNOPSARK, Centurion',
		Code: '0157    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'HENVILLE, Germiston',
		Code: '1406    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'HERBERTSDALE',
		Code: '6505    ',
		Hub: 'GRJ',
		Ring: 2,
		OpsHub: 'GRJ',
		Dist: 90,
		AreaName: 'George Regional',
		Surch: 'Far Outlying',
	},
	{
		PlaceName: 'HERCULES, Pretoria',
		Code: '0082    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'HEREFORD, Groblersdal',
		Code: '0470    ',
		Hub: 'PRY',
		Ring: 4,
		OpsHub: 'PRY',
		Dist: 180,
		AreaName: 'Pretoria Regional',
		Surch: null,
	},
	{
		PlaceName: 'HERIOTDALE, Johannesburg',
		Code: '2094    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'HERLEAR, Kimberley',
		Code: '8301    ',
		Hub: 'KIM',
		Ring: 0,
		OpsHub: 'KIM',
		Dist: 20,
		AreaName: 'Kimberley Central',
		Surch: null,
	},
	{
		PlaceName: 'HERMANNSBURG',
		Code: '3508    ',
		Hub: 'DUR',
		Ring: 3,
		OpsHub: 'DUR',
		Dist: 150,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'HERMANSTAD, Pretoria',
		Code: '0082    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'HERMANUS, Western Cape',
		Code: '7200    ',
		Hub: 'CPT',
		Ring: 1,
		OpsHub: 'CPT',
		Dist: 100,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'HERMINA, Westonaria',
		Code: '1788    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 50,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'HERMON',
		Code: '6802    ',
		Hub: 'CPT',
		Ring: 2,
		OpsHub: 'CPT',
		Dist: 90,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'HEROLD, George',
		Code: '6529',
		Hub: 'GRJ',
		Ring: 1,
		OpsHub: 'GRJ',
		Dist: 10,
		AreaName: 'George Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'HEROLDVILLE, Johannesburg',
		Code: '2197    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'HERTZOGVILLE',
		Code: '9482',
		Hub: 'WEL',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 400,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'HERZLIA',
		Code: '8006    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'HESTIAPARK, Karenpark',
		Code: '0118    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'HET KRUIS',
		Code: '8100    ',
		Hub: 'CPT',
		Ring: 4,
		OpsHub: 'CPT',
		Dist: 180,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'HEUNINGKLIP, Krugersdorp',
		Code: '1739    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'HEUNINGNESKLOOF',
		Code: '8715    ',
		Hub: 'BFN',
		Ring: 4,
		OpsHub: 'BFN',
		Dist: 210,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'HEUNINGSPRUIT',
		Code: '9505    ',
		Hub: 'WEL',
		Ring: 3,
		OpsHub: 'JNB',
		Dist: 170,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'HEUWELKRUIN',
		Code: '6571',
		Hub: 'GRJ',
		Ring: 1,
		OpsHub: 'GRJ',
		Dist: 80,
		AreaName: 'George Regional',
		Surch: null,
	},
	{
		PlaceName: 'HEUWELOORD, Wierdapark',
		Code: '0149    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'HEUWELSIG, Bloemfontein',
		Code: '9301',
		Hub: 'BFN',
		Ring: 0,
		OpsHub: 'BFN',
		Dist: 30,
		AreaName: 'Bloemfontein Central',
		Surch: null,
	},
	{
		PlaceName: 'HEUWELSIG, Kimberley',
		Code: '8301    ',
		Hub: 'KIM',
		Ring: 0,
		OpsHub: 'KIM',
		Dist: 20,
		AreaName: 'Kimberley Central',
		Surch: null,
	},
	{
		PlaceName: 'HEX RIVER',
		Code: '6855    ',
		Hub: 'CPT',
		Ring: 2,
		OpsHub: 'CPT',
		Dist: 120,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'HEXRIVIER',
		Code: '6855    ',
		Hub: 'CPT',
		Ring: 2,
		OpsHub: 'CPT',
		Dist: 120,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'HIBBERDENE',
		Code: '4220    ',
		Hub: 'DUR',
		Ring: 2,
		OpsHub: 'DUR',
		Dist: 100,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'HIDDINGH Est/Lgd,Newlands',
		Code: '7700    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'HIGGOVALE, Cape Town',
		Code: '8001',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'HIGH CAPE',
		Code: '8001',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'HIGH PLACES, Durbanville',
		Code: '7550    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'HIGH PLACES, Eersterivier',
		Code: '7100    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 15,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'HIGH STREET, Tongaat',
		Code: '4399',
		Hub: 'DUR',
		Ring: 1,
		OpsHub: 'DUR',
		Dist: 70,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'HIGHBURY, Kuilsrivier',
		Code: '7580    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'HIGHFLATS',
		Code: '4640    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 180,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'HIGHGATE, East London',
		Code: '5247    ',
		Hub: 'ELS',
		Ring: 0,
		OpsHub: 'ELS',
		Dist: 10,
		AreaName: 'East London Central',
		Surch: null,
	},
	{
		PlaceName: 'HIGHGATE, Eersterivier',
		Code: '7100    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 15,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'HIGHLANDS Est/Lgd, Cape Town',
		Code: '8001',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'HIGHLANDS HILL, Pinetown',
		Code: '3610',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'HIGHLANDS NORTH & Ext,',
		Code: '2192    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'HIGHLANDS NORTH Boxes',
		Code: '2037    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'HIGHLANDS NORTH Ext 1,',
		Code: '2192    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'HIGHLANDS, Johannesburg',
		Code: '2198    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'HIGHLANDS-NOORD & Uit,',
		Code: '2192    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'HIGHLANDS-NOORD Busse',
		Code: '2037    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'HIGHLANDS-NOORD Uit 1,',
		Code: '2192    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'HIGHVELD EX7,Pretoria',
		Code: '0157',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'HIGHWAY GARDENS, Edenvale',
		Code: '1609    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'HILDASIA',
		Code: '5284    ',
		Hub: 'ELS',
		Ring: 0,
		OpsHub: 'ELS',
		Dist: 10,
		AreaName: 'East London Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'HILL Ext/Uit, Johannesburg',
		Code: '2197    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'HILL SIXTY, Grahamstown',
		Code: '6140    ',
		Hub: 'PLZ',
		Ring: 4,
		OpsHub: 'PLZ',
		Dist: 170,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'HILLARY Boxes',
		Code: '4024    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'HILLARY, Durban',
		Code: '4094    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'HILLARY, Durban',
		Code: '4094',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 20,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'HILLBROW Boxes',
		Code: '2038    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'HILLBROW, Johannesburg',
		Code: '2001    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'HILLCREST (Natal) Boxes',
		Code: '3650    ',
		Hub: 'DUR',
		Ring: 1,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'HILLCREST PARK, Pinetown',
		Code: '3610',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'HILLCREST, Blue Downs',
		Code: '7100    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 15,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'HILLCREST, Kimberley',
		Code: '8301    ',
		Hub: 'KIM',
		Ring: 0,
		OpsHub: 'KIM',
		Dist: 20,
		AreaName: 'Kimberley Central',
		Surch: null,
	},
	{
		PlaceName: 'HILLCREST, Pinetown',
		Code: '3610',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'HILLCREST, Pretoria',
		Code: '0083    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'HILLCREST, Putfontein',
		Code: '1513    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'HILLCREST, Wellington',
		Code: '7655    ',
		Hub: 'CPT',
		Ring: 2,
		OpsHub: 'CPT',
		Dist: 90,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'HILLDENE, Hillcrest',
		Code: '3610',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'HILLRISE, Bellville',
		Code: '7530    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'HILLSBORO, Bloemfontein',
		Code: '9301',
		Hub: 'BFN',
		Ring: 0,
		OpsHub: 'BFN',
		Dist: 30,
		AreaName: 'Bloemfontein Central',
		Surch: null,
	},
	{
		PlaceName: 'HILLSHAVEN, Westonaria',
		Code: '1789    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 50,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'HILLSIDE, Beaufort West',
		Code: '6970    ',
		Hub: 'CPT',
		Ring: 4,
		OpsHub: 'CPT',
		Dist: 470,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'HILLSIDE, Bloemfontein',
		Code: '9301',
		Hub: 'BFN',
		Ring: 0,
		OpsHub: 'BFN',
		Dist: 30,
		AreaName: 'Bloemfontein Central',
		Surch: null,
	},
	{
		PlaceName: 'HILLSIDE, Kocksvlei',
		Code: '1764    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'HILLSIDE, Port Elizabeth',
		Code: '6059    ',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'HILLSIDE, Retreat',
		Code: '7945    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'HILLTOP GARDENS, Pinetown',
		Code: '3610',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'HILLVIEW, Eersterivier',
		Code: '7100    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 15,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'HILLVIEW, Germiston',
		Code: '1401    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'HILLVIEW, Queensburgh',
		Code: '4093',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'HILTON',
		Code: '3245    ',
		Hub: 'DUR',
		Ring: 1,
		OpsHub: 'DUR',
		Dist: null,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'HILTON, Aliwal North',
		Code: '5530    ',
		Hub: 'ELS',
		Ring: 4,
		OpsHub: 'BFN',
		Dist: 360,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'HILTON, Bloemfontein',
		Code: '9301',
		Hub: 'BFN',
		Ring: 0,
		OpsHub: 'BFN',
		Dist: 30,
		AreaName: 'Bloemfontein Central',
		Surch: null,
	},
	{
		PlaceName: 'HILTON, Pietermaritzburg',
		Code: '3245    ',
		Hub: 'DUR',
		Ring: 2,
		OpsHub: 'DUR',
		Dist: 100,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'HIMEVILLE',
		Code: '4585    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 200,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'HINDLE PARK, Eersterivier',
		Code: '7100    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 15,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'HINDLE PARK, Kuilsrivier',
		Code: '7580    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'HIPPOMERE, Durban',
		Code: '4091',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'HLABISA',
		Code: '3937    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 320,
		AreaName: 'Durban Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'HLOBANE',
		Code: '3145    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 330,
		AreaName: 'Durban Regional',
		Surch: 'Far Outlying',
	},
	{
		PlaceName: 'HLUHLUWE',
		Code: '3960    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 300,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'HLUKU',
		Code: '4688    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 180,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'HLUVUKANI',
		Code: '1363    ',
		Hub: 'NLP',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 310,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'HO#VELDRIF Boxes',
		Code: '2305    ',
		Hub: 'JNB',
		Ring: 3,
		OpsHub: 'JNB',
		Dist: 150,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'HOBHOUSE',
		Code: '9740    ',
		Hub: 'BFN',
		Ring: 4,
		OpsHub: 'BFN',
		Dist: 170,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'HOEDSPRUIT',
		Code: '1380    ',
		Hub: 'NLP',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 310,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'HOEKWIL',
		Code: '6538    ',
		Hub: 'GRJ',
		Ring: 1,
		OpsHub: 'GRJ',
		Dist: 10,
		AreaName: 'George Regional',
		Surch: null,
	},
	{
		PlaceName: 'HOFMEYR',
		Code: '5930    ',
		Hub: 'PLZ',
		Ring: 4,
		OpsHub: 'PLZ',
		Dist: 340,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'HOGSBACK',
		Code: '5721    ',
		Hub: 'ELS',
		Ring: 3,
		OpsHub: 'ELS',
		Dist: 140,
		AreaName: 'East London Regional',
		Surch: null,
	},
	{
		PlaceName: 'HOHEIZEN, Bellville',
		Code: '7530    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'HOHENORT, Constantia',
		Code: '7800    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'HOHOZA',
		Code: '3018    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 250,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'HOLDINGS',
		Code: '9472    ',
		Hub: 'BFN',
		Ring: 3,
		OpsHub: 'BFN',
		Dist: 160,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'HOLLAND PARK, Port Elizabeth',
		Code: '6001    ',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'HOLPAN',
		Code: '8505    ',
		Hub: 'KIM',
		Ring: 4,
		OpsHub: 'KIM',
		Dist: 400,
		AreaName: 'Kimberley Regional',
		Surch: null,
	},
	{
		PlaceName: 'HOMELAKE, Randfontein',
		Code: '1760    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'HOMER, Vereeniging',
		Code: '1939    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 60,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'HOMESTEAD APPLE ORCHARDS',
		Code: '1876    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 30,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'HOMESTEAD Boxes',
		Code: '1412    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'HOMESTEAD PARK, Johannesburg',
		Code: '2092    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'HOMESTEAD, Germiston',
		Code: '1401    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'HOMESTEAD, Kimberley',
		Code: '8301    ',
		Hub: 'KIM',
		Ring: 0,
		OpsHub: 'KIM',
		Dist: 20,
		AreaName: 'Kimberley Central',
		Surch: null,
	},
	{
		PlaceName: 'HOMEVALE, Kimberley',
		Code: '8301    ',
		Hub: 'KIM',
		Ring: 0,
		OpsHub: 'KIM',
		Dist: 20,
		AreaName: 'Kimberley Central',
		Surch: null,
	},
	{
		PlaceName: 'HONDEKLIP BAY',
		Code: '8222    ',
		Hub: 'CPT',
		Ring: 4,
		OpsHub: 'CPT',
		Dist: 580,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'HONDEKLIPBAAI',
		Code: '8222    ',
		Hub: 'CPT',
		Ring: 4,
		OpsHub: 'CPT',
		Dist: 580,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'HONEYDEW, Johannesburg',
		Code: '2040    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'HONEYHILLS, Roodepoort',
		Code: '1724    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'HOOFSTRAAT, PAARL',
		Code: '7622    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 60,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'HOOGSTEDE, Brackenfell',
		Code: '7560    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'HOOPSAD ',
		Code: '9479',
		Hub: 'WEL',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 250,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'HOPEFIELD',
		Code: '7355    ',
		Hub: 'CPT',
		Ring: 2,
		OpsHub: 'CPT',
		Dist: 120,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'HOPETOWN ',
		Code: '8750    ',
		Hub: 'BFN',
		Ring: 4,
		OpsHub: 'BFN',
		Dist: 310,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'HORISON & Ext/Uit, Roodeport',
		Code: '1724    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'HORISONPARK, Roodepoort',
		Code: '1724    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'HORIZON VIEW, Roodepoort',
		Code: '1724    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'HORNLEE Boxes',
		Code: '6583    ',
		Hub: 'GRJ',
		Ring: 1,
		OpsHub: 'GRJ',
		Dist: 50,
		AreaName: 'George Regional',
		Surch: null,
	},
	{
		PlaceName: 'HORNLEE, Knysna',
		Code: '6571',
		Hub: 'GRJ',
		Ring: 1,
		OpsHub: 'GRJ',
		Dist: 80,
		AreaName: 'George Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'HOSPITAALPARK, Bloemfontein',
		Code: '9301',
		Hub: 'BFN',
		Ring: 0,
		OpsHub: 'BFN',
		Dist: 30,
		AreaName: 'Bloemfontein Central',
		Surch: null,
	},
	{
		PlaceName: 'HOSPITAALPARK, Ladysmith',
		Code: '3370    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 250,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'HOSPITAALPARK, Odendaalsrus',
		Code: '9480    ',
		Hub: 'BFN',
		Ring: 3,
		OpsHub: 'BFN',
		Dist: 170,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'HOSPITAALPARK, Pietersburg',
		Code: '0699',
		Hub: 'PTG',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 400,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'HOSPITAL HILL, Beaufort West',
		Code: '6970    ',
		Hub: 'CPT',
		Ring: 4,
		OpsHub: 'CPT',
		Dist: 470,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'HOSPITAL HILL, Johannesburg',
		Code: '2001    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'HOSPITAL PARK, Worcester',
		Code: '6850    ',
		Hub: 'CPT',
		Ring: 2,
		OpsHub: 'CPT',
		Dist: 120,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'HOTAZEL',
		Code: '8490    ',
		Hub: 'KUR',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 400,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'HOUGHTON Boxes',
		Code: '2041    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'HOUGHTON Est/Lgd, Johannesburg',
		Code: '2198    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'HOUGHTON, Johannesburg',
		Code: '2198    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'HOUT BAY ',
		Code: '7806',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 40,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'HOUT BAY (S)',
		Code: '7800',
		Hub: 'CPT',
		Ring: 1,
		OpsHub: 'CPT',
		Dist: 40,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'HOUT BAY Boxes',
		Code: '7872    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 40,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'HOUT BAY HEIGHTS Est, Hout Bay',
		Code: '7806',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 40,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'HOUTBAAI Busse',
		Code: '7872    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 40,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'HOUTBAAI HEIGHTS Lgd, HoutBay',
		Code: '7806',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 40,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'HOUTBAAI Strate',
		Code: '7806',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 40,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'HOUTBOSDORP',
		Code: '0725    ',
		Hub: 'JNB',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 300,
		AreaName: 'Johannesburg Regional',
		Surch: 'Far Outlying',
	},
	{
		PlaceName: 'HOWARD PLACE',
		Code: '7450    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'HOWARDENE, Durban',
		Code: '4001    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'HOWICK',
		Code: '3290    ',
		Hub: 'DUR',
		Ring: 3,
		OpsHub: 'DUR',
		Dist: 150,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'HOWICK-WEST, Howick',
		Code: '3290    ',
		Hub: 'DUR',
		Ring: 3,
		OpsHub: 'DUR',
		Dist: 150,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'HOY Tsp/Dgd, Port Elizabeth',
		Code: '6001    ',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'HUGENOOT Busse',
		Code: '7645    ',
		Hub: 'CPT',
		Ring: 1,
		OpsHub: 'CPT',
		Dist: 60,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'HUGENOOT Strate',
		Code: '7646    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 60,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'HUGUENOT',
		Code: '7646    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 60,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'HUGUENOT Boxes',
		Code: '7645    ',
		Hub: 'CPT',
		Ring: 1,
		OpsHub: 'CPT',
		Dist: 60,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'HUMANSDORP',
		Code: '6300    ',
		Hub: 'PLZ',
		Ring: 2,
		OpsHub: 'PLZ',
		Dist: 100,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'HUMERAIL, Port Elizabeth',
		Code: '6001    ',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'HUMEWOOD & Ext/Uit,',
		Code: '6001    ',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'HUMEWOOD Boxes',
		Code: '6013    ',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'HUNTERS HILL, Randburg',
		Code: '2194    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'HUNTERS RETREAT,Port Elizabeth',
		Code: '6025',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'HURL PARK, Johannesburg',
		Code: '2196    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'HURLEY Est/Lgd, Mowbray',
		Code: '7700    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'HURLINGHAM & Ext, Sandton',
		Code: '2196',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'HURLINGHAM & Ext/Uit 5,',
		Code: '2196    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'HURLINGHAM GARDEN, Johannesburg',
		Code: '2196    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'HURLINGHAM MANOR, Johannesburg',
		Code: '2196    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'HURLYVALE Boxes',
		Code: '1611    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'HURLYVALE, Edenvale',
		Code: '1609    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'HURSTHILL, Johannesburg',
		Code: '2092    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'HUTCHINSON',
		Code: '7080    ',
		Hub: 'BFN',
		Ring: 4,
		OpsHub: 'BFN',
		Dist: 500,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'HUTTENHOOGTE, Newcastle',
		Code: '2956    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 330,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'HYDE PARK, Johannesburg',
		Code: '2196    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'HYDE PARK, Ladysmith',
		Code: '3370    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 250,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'IBAXA, Tembisa',
		Code: '1628    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'IDA',
		Code: '5446    ',
		Hub: 'ELS',
		Ring: 4,
		OpsHub: 'ELS',
		Dist: 250,
		AreaName: 'East London Regional',
		Surch: null,
	},
	{
		PlaceName: 'IDASVALLEI Boxes',
		Code: '7609    ',
		Hub: 'CPT',
		Ring: 1,
		OpsHub: 'CPT',
		Dist: 60,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'IDASVALLEI, Stellenbosch',
		Code: '7600    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 60,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'IDUTYWA',
		Code: '9995    ',
		Hub: 'ELS',
		Ring: 4,
		OpsHub: 'ELS',
		Dist: 120,
		AreaName: 'East London Regional',
		Surch: null,
	},
	{
		PlaceName: 'IFAFA BEACH',
		Code: '4185    ',
		Hub: 'DUR',
		Ring: 1,
		OpsHub: 'DUR',
		Dist: 70,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'IFAFI Boxes',
		Code: '0260    ',
		Hub: 'JNB',
		Ring: 2,
		OpsHub: 'JNB',
		Dist: 100,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'IFAFI, Brits',
		Code: '0260    ',
		Hub: 'JNB',
		Ring: 2,
		OpsHub: 'JNB',
		Dist: 100,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'IKAGENG, Potchefstroom',
		Code: '2520    ',
		Hub: 'JNB',
		Ring: 3,
		OpsHub: 'JNB',
		Dist: 150,
		AreaName: 'Johannesburg Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'IKHUTSENG, Warrenton',
		Code: '8530    ',
		Hub: 'BFN',
		Ring: 4,
		OpsHub: 'BFN',
		Dist: 170,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'IKWEZI PARK, Khayelitsha',
		Code: '7784    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'IKWEZI, Umtata',
		Code: '5100',
		Hub: 'ELS',
		Ring: 1,
		OpsHub: 'ELS',
		Dist: 180,
		AreaName: 'East London Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'ILLIONDALE, Edenvale',
		Code: '1609    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'ILLOVO (Natal) Boxes',
		Code: '4150    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 30,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'ILLOVO BEACH Boxes',
		Code: '4155    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 30,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'ILLOVO BEACH, Amanzimtoti',
		Code: '4126',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'ILLOVO GLEN, Amanzimtoti',
		Code: '4126',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'ILLOVO PHASE 1, Amanzimtoti',
		Code: '4126',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'ILLOVO, Amanzimtoti',
		Code: '4126',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'ILLOVO, Johannesburg',
		Code: '2196    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'IMBONGOZI',
		Code: '1333    ',
		Hub: 'JNB',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 310,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: "IMHOFF'S GIFT, Fish Hoek",
		Code: '7975',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 50,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'IMIZAMO YETHU, Hout Bay',
		Code: '7806',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'IMPALA PARK, Potgietersrus',
		Code: '0600    ',
		Hub: 'PTG',
		Ring: 3,
		OpsHub: 'JNB',
		Dist: 300,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'IMPALAPARK Boxes',
		Code: '1472    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'IMPALAPARK, Boksburg',
		Code: '1459    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'IMPENDLE',
		Code: '4545    ',
		Hub: 'DUR',
		Ring: 3,
		OpsHub: 'DUR',
		Dist: 150,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'IMPENDLE,Pietermaritzburg',
		Code: '3227',
		Hub: 'DUR',
		Ring: 2,
		OpsHub: 'DUR',
		Dist: 100,
		AreaName: 'Durban Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'INADAN, Johannesburg',
		Code: '2196    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'INANDA (Ntl)',
		Code: '4310    ',
		Hub: 'DUR',
		Ring: 1,
		OpsHub: 'DUR',
		Dist: 50,
		AreaName: 'Durban Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'INANDA, Johannesburg',
		Code: '2196    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'INANDAS, Johannesburg',
		Code: '2196    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'INCHANGA',
		Code: '3670    ',
		Hub: 'DUR',
		Ring: 1,
		OpsHub: 'DUR',
		Dist: 60,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'INDERMARK',
		Code: '0717    ',
		Hub: 'PTG',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 300,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'INDUSTRIA Boxes',
		Code: '2042    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'INDUSTRIA NORTH, Florida',
		Code: '1709    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'INDUSTRIA NORTH, Maraisburg',
		Code: '1709    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'INDUSTRIA WEST, Johannesburg',
		Code: '2093    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'INDUSTRIA, Athlone',
		Code: '7764    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'INDUSTRIA, Johannesburg',
		Code: '2093    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'INDUSTRIA-NOORD, Florida',
		Code: '1709    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'INDUSTRIA-NOORD, Maraisburg',
		Code: '1709    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'INDUSTRIA-WES, Johannesburg',
		Code: '2093    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'INDUSTRIAL AREA, Lichtenburg',
		Code: '2740    ',
		Hub: 'NWP',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 270,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'INDUSTRIAL AREA, Welkom',
		Code: '9459    ',
		Hub: 'WEL',
		Ring: 3,
		OpsHub: 'JNB',
		Dist: 150,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'INDUSTRIELE GEBIED, Lichtenburg',
		Code: '2740    ',
		Hub: 'NWP',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 270,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'INDUSTRIELE GEBIED, Welkom',
		Code: '9459    ',
		Hub: 'WEL',
		Ring: 3,
		OpsHub: 'JNB',
		Dist: 150,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'INDUSTRIES EAST, Germiston',
		Code: '1401    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'INDUSTRIES WEST, Germiston',
		Code: '1401    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'INDUSTRIES-OOS, Germiston',
		Code: '1401    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'INDUSTRIES-WES, Germiston',
		Code: '1401    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'INDWE',
		Code: '5445    ',
		Hub: 'ELS',
		Ring: 4,
		OpsHub: 'ELS',
		Dist: 250,
		AreaName: 'East London Regional',
		Surch: null,
	},
	{
		PlaceName: 'INGWAVUMA',
		Code: '3968    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 300,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'INNESDALE',
		Code: '0031    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'INNESFREE Est/Lgd, Gatesville',
		Code: '7764    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'INTABAZWE, Harrismith',
		Code: '9880',
		Hub: 'HSM',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 300,
		AreaName: 'Johannesburg Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'INTULISO',
		Code: '2561    ',
		Hub: 'JNB',
		Ring: 3,
		OpsHub: 'JNB',
		Dist: 150,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'INYALA PARK, Empangeni',
		Code: '3880',
		Hub: 'DUR',
		Ring: 1,
		OpsHub: 'DUR',
		Dist: 180,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'INYANGA, Randburg',
		Code: '2194    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'IPELEGENG, Schweizer-Rene',
		Code: '2780    ',
		Hub: 'VRY',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 420,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'IPOPENG, Fauresmith',
		Code: '9978    ',
		Hub: 'BFN',
		Ring: 3,
		OpsHub: 'BFN',
		Dist: 150,
		AreaName: 'Bloemfontein Regional',
		Surch: 'Far Outlying',
	},
	{
		PlaceName: 'IPOPENG, Galeshewe',
		Code: '8330    ',
		Hub: 'KIM',
		Ring: 0,
		OpsHub: 'KIM',
		Dist: 20,
		AreaName: 'Kimberley Central',
		Surch: null,
	},
	{
		PlaceName: 'IRENE FARM VILLAGES, Centurion',
		Code: '0133    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'IRENE, Centurion',
		Code: '0157',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 40,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'IRENEPARK, Klerksdorp',
		Code: '2571    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 150,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'IRONSIDE',
		Code: '1984',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'ISANDO',
		Code: '1600    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 5,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'ISANDOVALE, Edenvale',
		Code: '1609    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'ISIPINGO',
		Code: '4133',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'ISIPINGO BEACH',
		Code: '4133',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'ISIPINGO HILLS, Isipingo',
		Code: '4133',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'ISITHEBE',
		Code: '4490    ',
		Hub: 'DUR',
		Ring: 3,
		OpsHub: 'DUR',
		Dist: 160,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'ISLAND VIEW, Durban',
		Code: '4052    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'ITSOKOLELE, Matatiele',
		Code: '4730    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 290,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'IVY PARK, Pietersburg',
		Code: '0699',
		Hub: 'PTG',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 400,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'IVY PARK, Pinetown',
		Code: '3610',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'IVYDALE, Pietersburg',
		Code: '0699',
		Hub: 'PTG',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 400,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'IXOPO',
		Code: '4630    ',
		Hub: 'DUR',
		Ring: 2,
		OpsHub: 'DUR',
		Dist: 80,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'IZINGA PARK, Durban',
		Code: '4319',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'IZINGOLWENI',
		Code: '4260    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 135,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'JABAVU, Pimville',
		Code: '1808    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 50,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'JABULANI & Ext/Uit 2, kwaXuma',
		Code: '1868    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 30,
		AreaName: 'Johannesburg Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'JABULANI Ext/Uit 2, kwaXuma',
		Code: '1868    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 30,
		AreaName: 'Johannesburg Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'JABULANI HOSTEL, kwaXuma',
		Code: '1868    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 30,
		AreaName: 'Johannesburg Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'JABULANI SOUTH, kwaXuma',
		Code: '1868    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 30,
		AreaName: 'Johannesburg Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'JABULANI-SUID, kwaXuma',
		Code: '1868    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 30,
		AreaName: 'Johannesburg Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'JACANLEE, Randburg',
		Code: '2194    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'JACOBS',
		Code: '4052    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'JACOBS BAY',
		Code: '7382',
		Hub: 'CPT',
		Ring: 1,
		OpsHub: 'CPT',
		Dist: 150,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'JACOBS Boxes',
		Code: '4026    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'JACOBSDAL',
		Code: '8710    ',
		Hub: 'KIM',
		Ring: 4,
		OpsHub: 'KIM',
		Dist: 190,
		AreaName: 'Kimberley Regional',
		Surch: null,
	},
	{
		PlaceName: 'JACOBSPARK, Boksburg',
		Code: '1459    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'JAGERSFONTEIN',
		Code: '9974    ',
		Hub: 'BFN',
		Ring: 3,
		OpsHub: 'BFN',
		Dist: 140,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'JAGERSRUST',
		Code: '3354    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 260,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'JAMESON PARK',
		Code: '1492    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: "JAMESON'S DRIFT",
		Code: '3553    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 170,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'JAMESTOWN',
		Code: '5510    ',
		Hub: 'ELS',
		Ring: 4,
		OpsHub: 'ELS',
		Dist: 330,
		AreaName: 'East London Regional',
		Surch: null,
	},
	{
		PlaceName: 'JAMESTOWN, Stellenbosch',
		Code: '7600    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 60,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'JAN CILLIERSPARK, Welkom',
		Code: '9459    ',
		Hub: 'WEL',
		Ring: 3,
		OpsHub: 'JNB',
		Dist: 150,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'JAN HOFMEYER, Johannesburg',
		Code: '2092    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'JAN KEMPDORP',
		Code: '8550    ',
		Hub: 'VRY',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 210,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'JAN NIEMANDPARK, Pretoria',
		Code: '0186    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'JAN ROZ, Tongaat',
		Code: '4399',
		Hub: 'DUR',
		Ring: 1,
		OpsHub: 'DUR',
		Dist: 70,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'JAN SMUTS AIRPORT',
		Code: '1627    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'JAN SMUTSLUGHAWE',
		Code: '1627    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'JAN SMUTSVILLE, Boksburg',
		Code: '1459    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'JANALET, Pietersburg',
		Code: '0699',
		Hub: 'PTG',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 400,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'JANE FURSE HOSPITAL',
		Code: '1085    ',
		Hub: 'MPG',
		Ring: 2,
		OpsHub: 'JNB',
		Dist: 140,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'JANSENDAL, Uitenhage',
		Code: '6230    ',
		Hub: 'PLZ',
		Ring: 1,
		OpsHub: 'PLZ',
		Dist: 50,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'JANSENPARK, Boksburg',
		Code: '1459    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'JANSENVILLE',
		Code: '6265    ',
		Hub: 'PLZ',
		Ring: 4,
		OpsHub: 'PLZ',
		Dist: 200,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'JARMAN Tsp/Dgd, Port Elizabeth',
		Code: '6020    ',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'JATNIEL, Benoni',
		Code: '1509    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: "JEFFREY'S BAY, Eastern Cape",
		Code: '6330    ',
		Hub: 'PLZ',
		Ring: 2,
		OpsHub: 'PLZ',
		Dist: 90,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'JEFFREYSBAAI',
		Code: '6330    ',
		Hub: 'PLZ',
		Ring: 2,
		OpsHub: 'PLZ',
		Dist: 90,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'JEPPESTOWN Boxes',
		Code: '2043    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'JEPPESTOWN, Johannesburg',
		Code: '2094    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'JET PARK, Johannesburg',
		Code: '1459    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'JIM FOUCHEPARK, Welkom',
		Code: '9459    ',
		Hub: 'WEL',
		Ring: 3,
		OpsHub: 'JNB',
		Dist: 150,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'JOANSVILLE PARK, Bergvliet',
		Code: '7945    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'JOE SLOVO PARK, Milnerton',
		Code: '6665',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'JOHANNESBURG',
		Code: '2001    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'JOHANNESBURG  Streets',
		Code: '2001    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'JOHANNESBURG Boxes',
		Code: '2000    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'JOHANNESBURG DEPOT',
		Code: '2001    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 0,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'JOHANNESBURG NORTH, Jukskeipark',
		Code: '2153    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'JOHANNESBURG-NOORD, Jukskiepark',
		Code: '2153    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: "JOHNSON'S POST, George Regional",
		Code: '6504    ',
		Hub: 'GRJ',
		Ring: 2,
		OpsHub: 'GRJ',
		Dist: 90,
		AreaName: 'George Regional',
		Surch: null,
	},
	{
		PlaceName: 'JOLIVET',
		Code: '4205    ',
		Hub: 'DUR',
		Ring: 1,
		OpsHub: 'DUR',
		Dist: 40,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'JONES Est/Lgd, Plumstead',
		Code: '7800    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'JOOSTENBERGVLAKTE, Kraaifontein',
		Code: '7570    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'JOOSTENVILLE, Bellville',
		Code: '7530    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'JORDAANPARK, Heidelberg',
		Code: '2400    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 70,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'JORDANIA, Kroonstad',
		Code: '9499    ',
		Hub: 'BFN',
		Ring: 3,
		OpsHub: 'BFN',
		Dist: 170,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'JOUBERTINA',
		Code: '6410    ',
		Hub: 'PLZ',
		Ring: 4,
		OpsHub: 'PLZ',
		Dist: 210,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'JOUBERTON',
		Code: '2574    ',
		Hub: 'JNB',
		Ring: 3,
		OpsHub: 'JNB',
		Dist: 150,
		AreaName: 'Johannesburg Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'JOUBERTON Ext/Uit 6, Jouberton',
		Code: '2574    ',
		Hub: 'JNB',
		Ring: 3,
		OpsHub: 'JNB',
		Dist: 150,
		AreaName: 'Johannesburg Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'JOUBERTON Tsp/Dgd, Jouberton',
		Code: '2574    ',
		Hub: 'JNB',
		Ring: 3,
		OpsHub: 'JNB',
		Dist: 150,
		AreaName: 'Johannesburg Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'JOUBERTPARK Boxes',
		Code: '2044    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'JOUBERTPARK, Bellville',
		Code: '7530    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'JOUBERTPARK, Johannesburg',
		Code: '2001    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'JOZINI',
		Code: '3969    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 400,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: "JUDITH'S PAARL, Johannesberg",
		Code: '2094    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'JUKSKEIPARK Boxes',
		Code: '2153    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'JUKSKEIPARK, Randburg',
		Code: '2153    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'JULES STREET, JOHANNESBURG',
		Code: '2046    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'JULESSTRAAT, JOHANNESBURG',
		Code: '2046    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'JUNCTION HILL, Germiston',
		Code: '1401    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'JUNO',
		Code: '0748    ',
		Hub: 'JNB',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 300,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'JUPITER, Johannesburg',
		Code: '2094    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'KAALFONTEIN, Tembisa',
		Code: '1632',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'KAAP DIE GOEIE HOOP NATUU',
		Code: '7995    ',
		Hub: 'CPT',
		Ring: 1,
		OpsHub: 'CPT',
		Dist: 70,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'KAAPMUIDEN',
		Code: '1295    ',
		Hub: 'NLP',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 350,
		AreaName: 'Johannesburg Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'KAAPSTAD ',
		Code: '8001',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'KAAPSTAD Busse',
		Code: '8000    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'KAAPZICHT, Parow',
		Code: '7500    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'KABAH, Uitenhage',
		Code: '6230    ',
		Hub: 'PLZ',
		Ring: 1,
		OpsHub: 'PLZ',
		Dist: 50,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'KABEGA Ext/Uit, Port Elizabeth',
		Code: '6025',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'KABEGA PARK, Port Elizabeth',
		Code: '6025',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'KABOKWENI',
		Code: '1245    ',
		Hub: 'NLP',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 310,
		AreaName: 'Johannesburg Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'KAFFERRIVIER',
		Code: '9900    ',
		Hub: 'BFN',
		Ring: 1,
		OpsHub: 'BFN',
		Dist: 50,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'KAFFIR RIVER',
		Code: '9900    ',
		Hub: 'BFN',
		Ring: 1,
		OpsHub: 'BFN',
		Dist: 50,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'KAGISANONG Boxes',
		Code: '9309    ',
		Hub: 'BFN',
		Ring: 0,
		OpsHub: 'BFN',
		Dist: 30,
		AreaName: 'Bloemfontein Central',
		Surch: null,
	},
	{
		PlaceName: 'KAGISANONG, Bloemfontein',
		Code: '9323    ',
		Hub: 'BFN',
		Ring: 0,
		OpsHub: 'BFN',
		Dist: 30,
		AreaName: 'Bloemfontein Central',
		Surch: null,
	},
	{
		PlaceName: 'KAGISO',
		Code: '1744    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'KAGISO 1 & 2, Kagiso',
		Code: '1744    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'KALBASKRAAL',
		Code: '7302    ',
		Hub: 'CPT',
		Ring: 1,
		OpsHub: 'CPT',
		Dist: 50,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'KALK BAY Boxes',
		Code: '7990    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 60,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'KALK BAY, Fish Hoek',
		Code: '7975    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 60,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'KALKBAAI Busse',
		Code: '7990    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 60,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'KALKBAAI, Vishoek',
		Code: '7975    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 60,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'KALKBANK',
		Code: '0713    ',
		Hub: 'PTG',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 400,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'KALKKUIL',
		Code: '9481    ',
		Hub: 'BFN',
		Ring: 3,
		OpsHub: 'BFN',
		Dist: 170,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'KALKSTEENFONTEIN',
		Code: '7490    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'KALWENI HEIGHTS, Durban',
		Code: '4051',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'KAMAQHEKEZA,Nelspruit',
		Code: '1346',
		Hub: 'JNB',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 310,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'KAMEEL',
		Code: '8603    ',
		Hub: 'BFN',
		Ring: 4,
		OpsHub: 'BFN',
		Dist: 340,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'KAMEELDRIFT EAST,Pretoria',
		Code: '0184',
		Hub: 'PRY',
		Ring: 1,
		OpsHub: 'PRY',
		Dist: 40,
		AreaName: 'Pretoria Regional',
		Surch: null,
	},
	{
		PlaceName: 'KAMIESBERG',
		Code: '8261    ',
		Hub: 'CPT',
		Ring: 4,
		OpsHub: 'CPT',
		Dist: 600,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'KAMIESKROON',
		Code: '8241    ',
		Hub: 'CPT',
		Ring: 4,
		OpsHub: 'CPT',
		Dist: 570,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'KAMMA PARK, Port Elizabeth',
		Code: '6070',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'KAMMA RIDGE, Port Elizabeth',
		Code: '6025',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'KAMMELDRIFT,Pretoria',
		Code: '0184',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Regional',
		Surch: null,
	},
	{
		PlaceName: 'KAMPSBAAI Busse',
		Code: '8040    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'KAMPSBAAI, Kaapstad',
		Code: '8005',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 30,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'KANANA, Orkney',
		Code: '2620    ',
		Hub: 'JNB',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 220,
		AreaName: 'Johannesburg Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'KANANA, Polokwane',
		Code: '0714',
		Hub: 'PTG',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 100,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'KANONBERG, Bellville',
		Code: '7530',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'KANONIERSPARK, Potchefstroon',
		Code: '2520    ',
		Hub: 'POT',
		Ring: 3,
		OpsHub: 'JNB',
		Dist: 150,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'KANONKOP, Middelburg',
		Code: '1050    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 140,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'KAPTEINSBAAI, Melkbosstrand',
		Code: '7441    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'KARATARA',
		Code: '6580    ',
		Hub: 'GRJ',
		Ring: 1,
		OpsHub: 'GRJ',
		Dist: 50,
		AreaName: 'George Regional',
		Surch: null,
	},
	{
		PlaceName: 'KAREEDOUW',
		Code: '6400    ',
		Hub: 'PLZ',
		Ring: 3,
		OpsHub: 'PLZ',
		Dist: 160,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'KAREEVILLE, De Aar',
		Code: '7000    ',
		Hub: 'BFN',
		Ring: 4,
		OpsHub: 'BFN',
		Dist: 500,
		AreaName: 'Bloemfontein Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'KARENPARK, Pretoria',
		Code: '0118    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'KARINDAL, Stellenbosch',
		Code: '7600    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 60,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'KARINO',
		Code: '1204    ',
		Hub: 'NLP',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 310,
		AreaName: 'Johannesburg Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'KARL BREMER',
		Code: '7531    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'KARL BREMER HOSPITAL, Belville',
		Code: '7530    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'KARL BREMERHOSPITAAL, Belville',
		Code: '7530    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'KARLIENPARK, Kimberley',
		Code: '8301    ',
		Hub: 'KIM',
		Ring: 0,
		OpsHub: 'KIM',
		Dist: 20,
		AreaName: 'Kimberley Central',
		Surch: null,
	},
	{
		PlaceName: 'KARLIENPARK, Rustenburg',
		Code: '0300    ',
		Hub: 'JNB',
		Ring: 2,
		OpsHub: 'JNB',
		Dist: 120,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'KAROS',
		Code: '8819    ',
		Hub: 'BFN',
		Ring: 4,
		OpsHub: 'BFN',
		Dist: 560,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'KARRIDENE, Illovo Beach',
		Code: '4126',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'KASSELSVLEI Boxes',
		Code: '7533    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'KASSELSVLEI, Bellville',
		Code: '7530    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'KASTEELSIG, Wingate Park',
		Code: '0153    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'KATHU Boxes',
		Code: '8446    ',
		Hub: 'KUR',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 510,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'KATHU, Northern Cape',
		Code: '8446    ',
		Hub: 'KUR',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 510,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'KATLEHONG',
		Code: '1431',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 30,
		AreaName: 'Johannesburg Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'KATLEHONG,Johannesburg',
		Code: '1431',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 30,
		AreaName: 'Johannesburg Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'KATZENBERG',
		Code: '7348    ',
		Hub: 'CPT',
		Ring: 1,
		OpsHub: 'CPT',
		Dist: 50,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'KAYAMANDI, Stellenbosch',
		Code: '7600    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 60,
		AreaName: 'Cape Town Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'KAYDALE, Jameson Park',
		Code: '1492    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'KEARSNEY',
		Code: '4453    ',
		Hub: 'DUR',
		Ring: 3,
		OpsHub: 'DUR',
		Dist: 140,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'KEDIKETSE',
		Code: '0454    ',
		Hub: 'PRY',
		Ring: 4,
		OpsHub: 'PRY',
		Dist: 180,
		AreaName: 'Pretoria Regional',
		Surch: null,
	},
	{
		PlaceName: 'KEI MOUTH',
		Code: '5260    ',
		Hub: 'ELS',
		Ring: 1,
		OpsHub: 'ELS',
		Dist: 10,
		AreaName: 'East London Regional',
		Surch: null,
	},
	{
		PlaceName: 'KEI ROAD',
		Code: '4920    ',
		Hub: 'ELS',
		Ring: 1,
		OpsHub: 'ELS',
		Dist: 70,
		AreaName: 'East London Regional',
		Surch: null,
	},
	{
		PlaceName: 'KEISKAMMAHOEK',
		Code: '5680',
		Hub: 'ELS',
		Ring: 1,
		OpsHub: 'ELS',
		Dist: 120,
		AreaName: 'East London Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'KEIWEG',
		Code: '4920    ',
		Hub: 'ELS',
		Ring: 1,
		OpsHub: 'ELS',
		Dist: 70,
		AreaName: 'East London Regional',
		Surch: null,
	},
	{
		PlaceName: 'KELLAND, Randburg',
		Code: '2194    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'KELSO',
		Code: '4183    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 40,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'KELVIN',
		Code: '2054    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'KELVIN Boxes',
		Code: '2054    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'KELVINVIEW, Kelvin',
		Code: '2054    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'KEMPENVILLE, Bellville',
		Code: '7530    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'KEMPTON PARK Ext/Uit 3 -',
		Code: '1619    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'KEMPTON PARK WEST, Kempton Park',
		Code: '1619    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'KEMPTON PARK, Johannesburg',
		Code: '1620    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'KEMPTON PARK-WES, Kempton Park',
		Code: '1619    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'KEMPTONPARK',
		Code: '1619    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'KEMSLEY PARK, Port Elizabeth',
		Code: '6001    ',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'KENDAL',
		Code: '2225    ',
		Hub: 'MBG',
		Ring: 2,
		OpsHub: 'JNB',
		Dist: 120,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'KENDALWOOD, Plumstead',
		Code: '7800    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'KENDREW',
		Code: '6283    ',
		Hub: 'PLZ',
		Ring: 4,
		OpsHub: 'PLZ',
		Dist: 260,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'KENEVER, Durbanville',
		Code: '7550    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'KENGIES, Sandton',
		Code: '2021',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'KENGRAY',
		Code: '2100    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'KENILWORTH, Cape Town',
		Code: '7700    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'KENILWORTH, Cape Town,  Boxes',
		Code: '7745    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'KENILWORTH, Johannesburg, Streets',
		Code: '2190    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'KENILWORTH, Kimberley',
		Code: '8301    ',
		Hub: 'KIM',
		Ring: 0,
		OpsHub: 'KIM',
		Dist: 20,
		AreaName: 'Kimberley Central',
		Surch: null,
	},
	{
		PlaceName: 'KENLEAF & Ext/Uit 1, Brakpan',
		Code: '1540    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'KENMARE & Ext/Uit 1, Krugersdorp',
		Code: '1739    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'KENMARE Boxes',
		Code: '1745    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'KENNETH GARDENS, Durban',
		Code: '4001    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'KENRIDGE GLENN, Bellville',
		Code: '7530    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'KENRIDGE HEIGHTS, Durbanville',
		Code: '7550    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'KENRIDGE, Bellville',
		Code: '7530    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'KENRIDGE, Durbanville',
		Code: '7550    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'KENSINGTON B, Randburg',
		Code: '2194    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'KENSINGTON, Johannesburg',
		Code: '2094    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'KENSINGTON, Maitland',
		Code: '7405    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'KENSINGTON, Port Elizabeth',
		Code: '6001    ',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'KENTANI',
		Code: '4980',
		Hub: 'ELS',
		Ring: 1,
		OpsHub: 'ELS',
		Dist: 150,
		AreaName: 'East London Regional',
		Surch: null,
	},
	{
		PlaceName: 'KENTON ON SEA',
		Code: '6191    ',
		Hub: 'PLZ',
		Ring: 3,
		OpsHub: 'PLZ',
		Dist: 160,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'KENTVIEW, Johannesburg',
		Code: '2196    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'KENVILLE, Durban',
		Code: '4051',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'KENWOOD, Rondebosch',
		Code: '7700    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'KENWYN Boxes',
		Code: '7790    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'KENWYN, Cape Town',
		Code: '7764    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'KERNKRAG, Melkbosstrand',
		Code: '7441    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'KERNKRAG, Table View',
		Code: '7440    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'KESTELL',
		Code: '9860    ',
		Hub: 'HSM',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 260,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'KESTELLHOF, Kimberley',
		Code: '8301    ',
		Hub: 'KIM',
		Ring: 0,
		OpsHub: 'KIM',
		Dist: 20,
		AreaName: 'Kimberley Central',
		Surch: null,
	},
	{
		PlaceName: 'KEW Tsp/Dgd, Johannesburg',
		Code: '2090    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'KEWTOWN, Athlone',
		Code: '7764    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'KGAKALA, Leeudoringstad',
		Code: '2640    ',
		Hub: 'JNB',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 320,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'KHALETHU, Middelburg',
		Code: '1050    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 140,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'KHARWASTAN, Chatsworth',
		Code: '4092',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'KHAYELITSHA',
		Code: '7784    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 30,
		AreaName: 'Cape Town Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'KHAYELITSHA, KwaNobuhle',
		Code: '6242    ',
		Hub: 'PLZ',
		Ring: 1,
		OpsHub: 'PLZ',
		Dist: 60,
		AreaName: 'Port Elizabeth Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'KHOMANANI',
		Code: '0933    ',
		Hub: 'JNB',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 300,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'KHOTSO, Orlando',
		Code: '1804    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 50,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'KHOTSONG, Bothaville',
		Code: '9660    ',
		Hub: 'BFN',
		Ring: 4,
		OpsHub: 'BFN',
		Dist: 270,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'KHUMA, Stilfontein',
		Code: '2550    ',
		Hub: 'JNB',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 170,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'KHUMALO VALLEY, Katlehong',
		Code: '1832    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 30,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'KHUTSONG, Carletonville',
		Code: '2500    ',
		Hub: 'JNB',
		Ring: 2,
		OpsHub: 'JNB',
		Dist: 100,
		AreaName: 'Johannesburg Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'KHYBER ROCK, Sunninghill',
		Code: '2157    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'KIBLER HEIGHTS, Johannesburg',
		Code: '2091    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'KIBLER PARK Boxes',
		Code: '2053    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'KIBLER PARK, Johannesburg',
		Code: '2091    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: "KIDD'S BEACH",
		Code: '5264    ',
		Hub: 'ELS',
		Ring: 0,
		OpsHub: 'ELS',
		Dist: 10,
		AreaName: 'East London Central',
		Surch: null,
	},
	{
		PlaceName: 'KIEPERSOL',
		Code: '1241    ',
		Hub: 'NLP',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 310,
		AreaName: 'Johannesburg Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'KIESEL',
		Code: '0382    ',
		Hub: 'PRY',
		Ring: 2,
		OpsHub: 'PRY',
		Dist: 120,
		AreaName: 'Pretoria Regional',
		Surch: null,
	},
	{
		PlaceName: 'KIESERVILLE, Lichtenburg',
		Code: '2740    ',
		Hub: 'NWP',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 270,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'KILDARE, Empangeni',
		Code: '3880',
		Hub: 'DUR',
		Ring: 1,
		OpsHub: 'DUR',
		Dist: 180,
		AreaName: 'Durban Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'KILDRUMMY, Paulshof',
		Code: '2056    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'KILFENORA, Benoni',
		Code: '1501    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'KILLARNEY GARDENS, Cape Town',
		Code: '7441    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'KILLARNEY, Johannesburg',
		Code: '2193    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'KILNER PARK & Ext/Uit,',
		Code: '0186    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'KIMBERLEY',
		Code: '8301    ',
		Hub: 'KIM',
		Ring: 0,
		OpsHub: 'KIM',
		Dist: 20,
		AreaName: 'Kimberley Central',
		Surch: null,
	},
	{
		PlaceName: 'KIMBERLEY (S)',
		Code: '8301',
		Hub: 'BFN',
		Ring: 1,
		OpsHub: 'BFN',
		Dist: 170,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'KIMBERLEY Boxes',
		Code: '8300    ',
		Hub: 'KIM',
		Ring: 0,
		OpsHub: 'KIM',
		Dist: 20,
		AreaName: 'Kimberley Central',
		Surch: null,
	},
	{
		PlaceName: 'KIMBERLEY NORTH, Kimberley',
		Code: '8301    ',
		Hub: 'KIM',
		Ring: 0,
		OpsHub: 'KIM',
		Dist: 20,
		AreaName: 'Kimberley Central',
		Surch: null,
	},
	{
		PlaceName: 'KIMBERLEY-NOORD, Kimberley',
		Code: '8301    ',
		Hub: 'KIM',
		Ring: 0,
		OpsHub: 'KIM',
		Dist: 20,
		AreaName: 'Kimberley Central',
		Surch: null,
	},
	{
		PlaceName: 'KIMDUSTRIA, Kimberley',
		Code: '8301    ',
		Hub: 'KIM',
		Ring: 0,
		OpsHub: 'KIM',
		Dist: 20,
		AreaName: 'Kimberley Central',
		Surch: null,
	},
	{
		PlaceName: 'KINE PARK, Brackenfell',
		Code: '7560    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'KING GEORGE PARK, George',
		Code: '6529',
		Hub: 'GRJ',
		Ring: 0,
		OpsHub: 'GRJ',
		Dist: 10,
		AreaName: 'George Central',
		Surch: null,
	},
	{
		PlaceName: 'KINGFISHER Est/Lgd, South',
		Code: '7800    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'KINGS REST, Durban',
		Code: '4052    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'KINGS VIEW, Durban',
		Code: '4052    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'KINGSBURGH, Amanzimtoti',
		Code: '4126',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'KINGSBURY PARK, Rondebosch',
		Code: '7700    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'KINGSLEY',
		Code: '3002    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 320,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'KINGSTON, Bellville',
		Code: '7530    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'KINGSWOOD',
		Code: '2661    ',
		Hub: 'WEL',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 360,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'KINGWILLIAMSTOWN, Eastern Cape',
		Code: '5600    ',
		Hub: 'ELS',
		Ring: 1,
		OpsHub: 'ELS',
		Dist: 70,
		AreaName: 'East London Regional',
		Surch: null,
	},
	{
		PlaceName: 'KINKELBOS',
		Code: '6188    ',
		Hub: 'PLZ',
		Ring: 2,
		OpsHub: 'PLZ',
		Dist: 110,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'KIRKNEY, Pretoria',
		Code: '0182',
		Hub: 'PRY',
		Ring: 1,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Regional',
		Surch: null,
	},
	{
		PlaceName: 'KIRKWOOD',
		Code: '6120    ',
		Hub: 'PLZ',
		Ring: 2,
		OpsHub: 'PLZ',
		Dist: 90,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'KIRKWOOD GARDENS, Durban',
		Code: '4001    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'KIRSTENBOSCH, Claremont',
		Code: '7700    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'KIRSTENHOF, Kimberley',
		Code: '8301    ',
		Hub: 'KIM',
		Ring: 0,
		OpsHub: 'KIM',
		Dist: 20,
		AreaName: 'Kimberley Central',
		Surch: null,
	},
	{
		PlaceName: 'KIRSTENHOF, Retreat',
		Code: '7945    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'KLAARSTROOM',
		Code: '6932    ',
		Hub: 'CPT',
		Ring: 4,
		OpsHub: 'CPT',
		Dist: 400,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'KLAARWATER EXT 2',
		Code: '3609',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'KLAARWATER, Pinetown',
		Code: '3609',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'KLAAS VOOGDS RIVER',
		Code: '6707    ',
		Hub: 'CPT',
		Ring: 4,
		OpsHub: 'CPT',
		Dist: 180,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'KLAAS VOOGDSRIVIER',
		Code: '6707    ',
		Hub: 'CPT',
		Ring: 4,
		OpsHub: 'CPT',
		Dist: 180,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'KLAASENBOSCH, Constantia',
		Code: '7800    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'KLAASJAGERSBERG, Simonstown',
		Code: '7995    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 70,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'KLAPMUTS',
		Code: '7625    ',
		Hub: 'CPT',
		Ring: 1,
		OpsHub: 'CPT',
		Dist: 60,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'KLASERIE',
		Code: '1381    ',
		Hub: 'NLP',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 310,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'KLAWER',
		Code: '8145    ',
		Hub: 'CPT',
		Ring: 4,
		OpsHub: 'CPT',
		Dist: 300,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'KLEIN DREYERSDAL, Bergvliet',
		Code: '7945    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'KLEIN PANORAMA, Parow',
		Code: '7500    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'KLEIN RIVER',
		Code: '6351    ',
		Hub: 'PLZ',
		Ring: 1,
		OpsHub: 'PLZ',
		Dist: 80,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'KLEIN TURF HALL Est/Lgd,',
		Code: '7780    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'KLEIN WELGEMOED, Bellville',
		Code: '7530    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'KLEIN-BRAKRIVIER',
		Code: '6503    ',
		Hub: 'GRJ',
		Ring: 1,
		OpsHub: 'GRJ',
		Dist: 50,
		AreaName: 'George Regional',
		Surch: null,
	},
	{
		PlaceName: 'KLEIN-DRAKENSTEIN',
		Code: '7628    ',
		Hub: 'CPT',
		Ring: 1,
		OpsHub: 'CPT',
		Dist: 60,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'KLEIN-NEDERBURG, Paarl',
		Code: '7646    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 60,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'KLEINBAAI',
		Code: '7220',
		Hub: 'CPT',
		Ring: 1,
		OpsHub: 'CPT',
		Dist: 170,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'KLEINBOS',
		Code: '6310    ',
		Hub: 'PLZ',
		Ring: 4,
		OpsHub: 'PLZ',
		Dist: 230,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'KLEINBOSCH, PAROW',
		Code: '7500',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'KLEINGELUK, Stellenbosch',
		Code: '7600    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 60,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'KLEINMOND',
		Code: '7195    ',
		Hub: 'CPT',
		Ring: 1,
		OpsHub: 'CPT',
		Dist: 60,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'KLEINPOORT',
		Code: '6236    ',
		Hub: 'PLZ',
		Ring: 3,
		OpsHub: 'PLZ',
		Dist: 130,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'KLEINRIVIER',
		Code: '6351    ',
		Hub: 'PLZ',
		Ring: 1,
		OpsHub: 'PLZ',
		Dist: 80,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'KLEINSEE',
		Code: '8282    ',
		Hub: 'CPT',
		Ring: 4,
		OpsHub: 'CPT',
		Dist: 670,
		AreaName: 'Cape Town Regional',
		Surch: 'Far Outlying',
	},
	{
		PlaceName: 'KLEINSKOOL, Port Elizabeth',
		Code: '6020    ',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'KLEINVLEI, Eersteriver',
		Code: '7100    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 15,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'KLEINZEE',
		Code: '8282',
		Hub: 'CPT',
		Ring: 1,
		OpsHub: 'CPT',
		Dist: null,
		AreaName: 'Cape Town Regional',
		Surch: 'Far Outlying',
	},
	{
		PlaceName: 'KLERKSDORP',
		Code: '2571    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 150,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'KLERKSDORP Boxes',
		Code: '2570    ',
		Hub: 'JNB',
		Ring: 3,
		OpsHub: 'JNB',
		Dist: 150,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'KLEVEHILL PARK, Petervale',
		Code: '2151    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'KLIP RIVER',
		Code: '1836    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 50,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'KLIPBANKFONTEIN',
		Code: '2836    ',
		Hub: 'RBG',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 210,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'KLIPDALE',
		Code: '7283    ',
		Hub: 'CPT',
		Ring: 3,
		OpsHub: 'CPT',
		Dist: 150,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'KLIPFONTEIN',
		Code: '5824    ',
		Hub: 'PLZ',
		Ring: 2,
		OpsHub: 'PLZ',
		Dist: 120,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'KLIPFONTEIN NO 4, Randburg',
		Code: '2194    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'KLIPFONTEIN, Boksburg',
		Code: '1459    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'KLIPHEUWEL',
		Code: '7303    ',
		Hub: 'CPT',
		Ring: 1,
		OpsHub: 'CPT',
		Dist: 50,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'KLIPHEUWEL, Malmesbury',
		Code: '7303',
		Hub: 'CPT',
		Ring: 1,
		OpsHub: 'CPT',
		Dist: 50,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'KLIPKOP, Parow',
		Code: '7501',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 30,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'KLIPPLAAT',
		Code: '6255    ',
		Hub: 'PLZ',
		Ring: 4,
		OpsHub: 'PLZ',
		Dist: 210,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'KLIPPOORTJIE, Germiston',
		Code: '1401    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'KLIPRAND',
		Code: '8205    ',
		Hub: 'CPT',
		Ring: 4,
		OpsHub: 'CPT',
		Dist: 400,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'KLIPRIVIER',
		Code: '1836    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 50,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'KLIPRIVIERSBERG Est/Lgd,',
		Code: '2197    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'KLIPSPRUIT & Ext/Uit,',
		Code: '1808    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 50,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'KLIPSPRUIT WEST & Ext 1 &',
		Code: '1812    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 30,
		AreaName: 'Johannesburg Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'KLIPSPRUIT-WES & Uit 1 &',
		Code: '1812    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 30,
		AreaName: 'Johannesburg Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'KLIPTOWN',
		Code: '1812    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 30,
		AreaName: 'Johannesburg Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'KLISSERVILLE, Kimberley',
		Code: '8301    ',
		Hub: 'KIM',
		Ring: 0,
		OpsHub: 'KIM',
		Dist: 20,
		AreaName: 'Kimberley Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'KLOOF Boxes',
		Code: '3640    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'KLOOF STREET, CAPE TOWN',
		Code: '8008    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'KLOOF, Pinetown',
		Code: '3610',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'KLOOFENDAL, Florida',
		Code: '1709    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'KLOOFNEK, Cape Town',
		Code: '8001',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'KLOOFSIG, Centurion',
		Code: '0157',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'KLOOFSIG, Verwoerdburg',
		Code: '0157    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'KLOOFSTRAAT, KAAPSTAD',
		Code: '8008    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'KLOPPERPARK, Germiston',
		Code: '1406    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'KNAPDAAR',
		Code: '5521    ',
		Hub: 'ELS',
		Ring: 4,
		OpsHub: 'ELS',
		Dist: 400,
		AreaName: 'East London Regional',
		Surch: null,
	},
	{
		PlaceName: 'KNIGHTS,Germiston',
		Code: '1401',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'KNOPPIESLAAGTE',
		Code: '1685',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'KNYSNA',
		Code: '6571',
		Hub: 'GRJ',
		Ring: 1,
		OpsHub: 'GRJ',
		Dist: 80,
		AreaName: 'George Regional',
		Surch: null,
	},
	{
		PlaceName: 'KOCKSOORD, Randfontein',
		Code: '1764    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'KOCKSPARK Boxes',
		Code: '2523    ',
		Hub: 'JNB',
		Ring: 2,
		OpsHub: 'JNB',
		Dist: 100,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'KOCKSPARK, Potchefstroom',
		Code: '2520    ',
		Hub: 'POT',
		Ring: 3,
		OpsHub: 'JNB',
		Dist: 150,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'KOCKSVLEI Boxes',
		Code: '1764    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'KOCKSVLEI, Randfontein',
		Code: '1764    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'KOEBERG PARK Est/Lgd, Maitland',
		Code: '7405    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'KOEBERG Tsp/Dgd, Melkbosstrand',
		Code: '7441    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'KOEDOESKOP',
		Code: '0361    ',
		Hub: 'JNB',
		Ring: 2,
		OpsHub: 'JNB',
		Dist: 120,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'KOEDOESPOORT, Pretoria',
		Code: '0186    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'KOEKEMOER',
		Code: '2553    ',
		Hub: 'JNB',
		Ring: 3,
		OpsHub: 'JNB',
		Dist: 150,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'KOEKENAAP',
		Code: '8146    ',
		Hub: 'CPT',
		Ring: 4,
		OpsHub: 'CPT',
		Dist: 300,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'KOELEMANSRUS',
		Code: '0629    ',
		Hub: 'PRY',
		Ring: 4,
		OpsHub: 'PRY',
		Dist: 300,
		AreaName: 'Pretoria Regional',
		Surch: null,
	},
	{
		PlaceName: 'KOELENHOF',
		Code: '7605    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 60,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'KOFFIEFONTEIN',
		Code: '9986    ',
		Hub: 'KIM',
		Ring: 4,
		OpsHub: 'KIM',
		Dist: 170,
		AreaName: 'Kimberley Regional',
		Surch: null,
	},
	{
		PlaceName: 'KOGGELFONTEIN, Simonstad',
		Code: '7995    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 70,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'KOINGNAAS',
		Code: '8249    ',
		Hub: 'CPT',
		Ring: 4,
		OpsHub: 'CPT',
		Dist: 620,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'KOKOSI, Fochville',
		Code: '2515    ',
		Hub: 'JNB',
		Ring: 2,
		OpsHub: 'JNB',
		Dist: 100,
		AreaName: 'Johannesburg Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'KOKSTAD',
		Code: '4700    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 220,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'KOLOTI',
		Code: '0709    ',
		Hub: 'PTG',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 400,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'KOMAGGAS',
		Code: '8242    ',
		Hub: 'CPT',
		Ring: 4,
		OpsHub: 'CPT',
		Dist: 610,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'KOMANI Boxes',
		Code: '5322    ',
		Hub: 'ELS',
		Ring: 4,
		OpsHub: 'ELS',
		Dist: 200,
		AreaName: 'East London Regional',
		Surch: null,
	},
	{
		PlaceName: 'KOMANI PARK, Queenstown',
		Code: '5320    ',
		Hub: 'ELS',
		Ring: 4,
		OpsHub: 'ELS',
		Dist: 210,
		AreaName: 'East London Regional',
		Surch: null,
	},
	{
		PlaceName: 'KOMANI, Queenstown',
		Code: '5320    ',
		Hub: 'ELS',
		Ring: 4,
		OpsHub: 'ELS',
		Dist: 210,
		AreaName: 'East London Regional',
		Surch: null,
	},
	{
		PlaceName: 'KOMATIPOORT',
		Code: '1340    ',
		Hub: 'NLP',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 100,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'KOMGA',
		Code: '4950    ',
		Hub: 'ELS',
		Ring: 1,
		OpsHub: 'ELS',
		Dist: 70,
		AreaName: 'East London Regional',
		Surch: null,
	},
	{
		PlaceName: 'KOMMADAGGA',
		Code: '5800    ',
		Hub: 'PLZ',
		Ring: 3,
		OpsHub: 'PLZ',
		Dist: 140,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'KOMMETJIE (S)',
		Code: '7975',
		Hub: 'CPT',
		Ring: 1,
		OpsHub: 'CPT',
		Dist: 60,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'KOMMETJIE Boxes',
		Code: '7976    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 60,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'KOMMETJIE, Cape Town',
		Code: '7975    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 60,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'KOOKHUIS',
		Code: '5820    ',
		Hub: 'PLZ',
		Ring: 4,
		OpsHub: 'PLZ',
		Dist: 180,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'KOOKRUS, Rothdene',
		Code: '1964    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 60,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'KOOPMANSFONTEIN',
		Code: '8391    ',
		Hub: 'BFN',
		Ring: 4,
		OpsHub: 'BFN',
		Dist: 340,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'KOPELA, Barberspan',
		Code: '2765    ',
		Hub: 'VRY',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 340,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'KOPERBERG Boxes',
		Code: '8250    ',
		Hub: 'CPT',
		Ring: 4,
		OpsHub: 'CPT',
		Dist: 620,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'KOPPIES',
		Code: '9540    ',
		Hub: 'WEL',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 170,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'KORINGBERG',
		Code: '7312    ',
		Hub: 'CPT',
		Ring: 3,
		OpsHub: 'CPT',
		Dist: 130,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'KORINGPUNT',
		Code: '0632    ',
		Hub: 'PRY',
		Ring: 4,
		OpsHub: 'PRY',
		Dist: 300,
		AreaName: 'Pretoria Regional',
		Surch: null,
	},
	{
		PlaceName: 'KORSTEN Boxes',
		Code: '6014    ',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'KORSTEN, Port Elizabeth',
		Code: '6020    ',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'KOSMOS, Brits',
		Code: '0261    ',
		Hub: 'JNB',
		Ring: 2,
		OpsHub: 'JNB',
		Dist: 100,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'KOSMOSDAL, Centurion',
		Code: '0157',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'KOSMOSDAL, SAMRAND',
		Code: '0157    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'KOSTER',
		Code: '2825    ',
		Hub: 'RBG',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 180,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'KOTZESHOOP',
		Code: '8246    ',
		Hub: 'CPT',
		Ring: 4,
		OpsHub: 'CPT',
		Dist: 730,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'KOTZESRUS',
		Code: '8204    ',
		Hub: 'CPT',
		Ring: 4,
		OpsHub: 'CPT',
		Dist: 400,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'KOUE-BOKKEVELD',
		Code: '6836    ',
		Hub: 'CPT',
		Ring: 2,
		OpsHub: 'CPT',
		Dist: 120,
		AreaName: 'Cape Town Regional',
		Surch: 'Far Outlying',
	},
	{
		PlaceName: 'KOWIE WEST Boxes',
		Code: '6171    ',
		Hub: 'PLZ',
		Ring: 4,
		OpsHub: 'PLZ',
		Dist: 190,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'KOWIE WEST, Port Alfred',
		Code: '6170    ',
		Hub: 'PLZ',
		Ring: 4,
		OpsHub: 'PLZ',
		Dist: 190,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'KOWIE-WES Busse',
		Code: '6171    ',
		Hub: 'PLZ',
		Ring: 4,
		OpsHub: 'PLZ',
		Dist: 190,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'KOWIE-WES, Port Alfred',
		Code: '6170    ',
		Hub: 'PLZ',
		Ring: 4,
		OpsHub: 'PLZ',
		Dist: 190,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'KRAAIFONTEIN ',
		Code: '7570    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'KRAAIFONTEIN Boxes',
		Code: '7569    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'KRAANKUIL',
		Code: '8760    ',
		Hub: 'BFN',
		Ring: 4,
		OpsHub: 'BFN',
		Dist: 310,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'KRAGBRON',
		Code: '9560    ',
		Hub: 'JNB',
		Ring: 2,
		OpsHub: 'JNB',
		Dist: 110,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'KRAGGAKAMMA PARK, Port Elizabeth',
		Code: '6070',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'KRAKEEL RIVER',
		Code: '6430    ',
		Hub: 'PLZ',
		Ring: 4,
		OpsHub: 'PLZ',
		Dist: 220,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'KRAKEELRIVIER',
		Code: '6430    ',
		Hub: 'PLZ',
		Ring: 4,
		OpsHub: 'PLZ',
		Dist: 220,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'KRAMERVILLE, Johannesburg',
		Code: '2090',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'KRANSKOP',
		Code: '3550    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 170,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'KREUPELBOSCH, Bergvliet',
		Code: '7945    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'KRIEL Boxes',
		Code: '2271    ',
		Hub: 'JNB',
		Ring: 3,
		OpsHub: 'JNB',
		Dist: 130,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'KRIGEVILLE, Stellenbosch',
		Code: '7600    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 60,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'KROM RIVER, Stellenbosch',
		Code: '7600    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 60,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'KROMBOOM Est/Lgd, Lansdowne',
		Code: '7780    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'KROMRIVIER, Stellenbosch',
		Code: '7600    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 60,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'KROONDAL',
		Code: '0350    ',
		Hub: 'JNB',
		Ring: 2,
		OpsHub: 'JNB',
		Dist: 120,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'KROONHEUWEL Boxes',
		Code: '9501    ',
		Hub: 'BFN',
		Ring: 3,
		OpsHub: 'BFN',
		Dist: 170,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'KROONHEUWEL Streets',
		Code: '9499    ',
		Hub: 'BFN',
		Ring: 3,
		OpsHub: 'BFN',
		Dist: 170,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'KROONSTAD',
		Code: '9499    ',
		Hub: 'WEL',
		Ring: 3,
		OpsHub: 'JNB',
		Dist: 170,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'KROONSTAD Boxes',
		Code: '9500    ',
		Hub: 'BFN',
		Ring: 3,
		OpsHub: 'BFN',
		Dist: 170,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'KROONVALE Boxes',
		Code: '6281    ',
		Hub: 'PLZ',
		Ring: 4,
		OpsHub: 'PLZ',
		Dist: 280,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'KROONVALE, Graaff-Reinet',
		Code: '6280    ',
		Hub: 'PLZ',
		Ring: 4,
		OpsHub: 'PLZ',
		Dist: 290,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'KRUGERSDORP',
		Code: '1739    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'KRUGERSDORP (S)',
		Code: '1739',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'KRUGERSDORP Boxes',
		Code: '1740    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'KRUGERSDORP NORTH Boxes',
		Code: '1741    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'KRUGERSDORP NORTH, Krugersdorp',
		Code: '1739    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'KRUGERSDORP-NOORD Busse',
		Code: '1741    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'KRUGERSDORP-NOORD, Krugersdorp',
		Code: '1739    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'KRUGERSRUS, Springs',
		Code: '1559    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'KRUINHOF, Elandsfontein',
		Code: '1406    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'KRUIS RIVER',
		Code: '6633    ',
		Hub: 'GRJ',
		Ring: 1,
		OpsHub: 'GRJ',
		Dist: 80,
		AreaName: 'George Regional',
		Surch: null,
	},
	{
		PlaceName: 'KRUISFONTEIN, Rosslyn',
		Code: '0200    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'KRUISRIVIER',
		Code: '6633    ',
		Hub: 'GRJ',
		Ring: 1,
		OpsHub: 'GRJ',
		Dist: 80,
		AreaName: 'George Regional',
		Surch: null,
	},
	{
		PlaceName: 'KUBOES',
		Code: '8292    ',
		Hub: 'CPT',
		Ring: 4,
		OpsHub: 'CPT',
		Dist: 900,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'KUDUBE',
		Code: '0401    ',
		Hub: 'PRY',
		Ring: 1,
		OpsHub: 'PRY',
		Dist: 50,
		AreaName: 'Pretoria Regional',
		Surch: null,
	},
	{
		PlaceName: 'KUILS RIVER',
		Code: '7580    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'KUILSRIVIER, Cape Town',
		Code: '7580    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'KULEKA, Empangeni',
		Code: '3880',
		Hub: 'DUR',
		Ring: 1,
		OpsHub: 'DUR',
		Dist: 180,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'KUNENE PARK, Port Elizabeth',
		Code: '6025',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'KURUMAN',
		Code: '8460    ',
		Hub: 'KUR',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 420,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'KUTLWANONG, Odendaalsrus',
		Code: '9480    ',
		Hub: 'WEL',
		Ring: 3,
		OpsHub: 'JNB',
		Dist: 170,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'KWA DUKUZA',
		Code: '4450',
		Hub: 'DUR',
		Ring: 2,
		OpsHub: 'DUR',
		Dist: 120,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'KWAGGAFONTEIN',
		Code: '0540    ',
		Hub: 'MBG',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 150,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'KWAGGAFONTEIN,Bloemfontein',
		Code: '9301',
		Hub: 'BFN',
		Ring: 0,
		OpsHub: 'BFN',
		Dist: 30,
		AreaName: 'Bloemfontein Central',
		Surch: null,
	},
	{
		PlaceName: 'KWAGGASRAND, Pretoria-West',
		Code: '0183    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'KWAGUQA, Witbank',
		Code: '1034',
		Hub: 'MBG',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 140,
		AreaName: 'Johannesburg Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'KWAGUQA,Witbank',
		Code: '1073',
		Hub: 'JNB',
		Ring: 3,
		OpsHub: 'JNB',
		Dist: 140,
		AreaName: 'Johannesburg Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'KWAMASHU',
		Code: '4359    ',
		Hub: 'DUR',
		Ring: 1,
		OpsHub: 'DUR',
		Dist: 50,
		AreaName: 'Durban Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'KWAMBONAMBI',
		Code: '3915    ',
		Hub: 'DUR',
		Ring: 1,
		OpsHub: 'DUR',
		Dist: 200,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'KWAMHLANGA',
		Code: '1022',
		Hub: 'MBG',
		Ring: 2,
		OpsHub: 'JNB',
		Dist: 90,
		AreaName: 'Johannesburg Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'KWANDEBELE',
		Code: '1022    ',
		Hub: 'JNB',
		Ring: 2,
		OpsHub: 'JNB',
		Dist: 90,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'KWANDENGEZI, Pinetown',
		Code: '3607',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'KWANOBUHLE',
		Code: '6242    ',
		Hub: 'PLZ',
		Ring: 1,
		OpsHub: 'PLZ',
		Dist: 60,
		AreaName: 'Port Elizabeth Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'KWANOKUTHULA, Plettenberg bay',
		Code: '6600',
		Hub: 'GRJ',
		Ring: 1,
		OpsHub: 'GRJ',
		Dist: 110,
		AreaName: 'George Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'KWANONQABA,Mossel bay',
		Code: '6506',
		Hub: 'GRJ',
		Ring: 1,
		OpsHub: 'GRJ',
		Dist: 60,
		AreaName: 'George Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'KWELERA',
		Code: '5259',
		Hub: 'ELS',
		Ring: 1,
		OpsHub: 'ELS',
		Dist: 35,
		AreaName: 'East London Regional',
		Surch: null,
	},
	{
		PlaceName: 'KWENZEKILE, Katlehong',
		Code: '1434    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 30,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'KWEZI, Worcester',
		Code: '6850    ',
		Hub: 'CPT',
		Ring: 2,
		OpsHub: 'CPT',
		Dist: 120,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'KYA SANDS',
		Code: '2169    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'KYALAMI AGRICULTURAL HOLDINGS, Midrand',
		Code: '1684',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'KYALAMI ESTATE, Midrand',
		Code: '1684',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'KYALAMI HEIGHTS, Midrand',
		Code: '1684',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'KYALAMI HILLS, Midrand',
		Code: '1684',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'KYALAMI PARK, Midrand',
		Code: '1684',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'KYALAMI, Midrand',
		Code: '1684',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: "L'AGULHAS, BREDASDORP",
		Code: '7280    ',
		Hub: 'CPT',
		Ring: 1,
		OpsHub: 'CPT',
		Dist: 160,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'LA COLLINE, Stellenbosch',
		Code: '7600    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 60,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'LA CONCORDE, Pretoria',
		Code: '0184    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'LA CONCORDIA, Somerset West',
		Code: '7130    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 30,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'LA LUCIA RIDGE, Durban',
		Code: '4051',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'LA LUCIA, Durban',
		Code: '4051',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'LA MERCY, Desainagar',
		Code: '4399',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 80,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'LA MONTAGNE, Pretoria',
		Code: '0184    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'LA MOTTE',
		Code: '7691    ',
		Hub: 'CPT',
		Ring: 2,
		OpsHub: 'CPT',
		Dist: 90,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'LA ROCHELLE, Bellville',
		Code: '7530    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'LA ROCHELLE, Johannesburg',
		Code: '2190    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'LA ROCHELLE, Port Elizabeth',
		Code: '6070',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'LA SANDRA, Somerset West',
		Code: '7130    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 30,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'LAAIPLEK',
		Code: '7370    ',
		Hub: 'CPT',
		Ring: 3,
		OpsHub: 'CPT',
		Dist: 160,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'LABIANCE Est/Lgd, Bellville',
		Code: '7530    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'LABRAM, Kimberley',
		Code: '8301    ',
		Hub: 'KIM',
		Ring: 0,
		OpsHub: 'KIM',
		Dist: 20,
		AreaName: 'Kimberley Central',
		Surch: null,
	},
	{
		PlaceName: 'LADANNA Boxes',
		Code: '0704    ',
		Hub: 'PTG',
		Ring: 4,
		OpsHub: 'PTG',
		Dist: 400,
		AreaName: 'Pietersburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'LADANNA, Pietersburg',
		Code: '0699',
		Hub: 'PTG',
		Ring: 0,
		OpsHub: 'PTG',
		Dist: 400,
		AreaName: 'Pietersburg Central',
		Surch: null,
	},
	{
		PlaceName: 'LADINE Ext/Uit 16, Pietersburg',
		Code: '0699',
		Hub: 'PTG',
		Ring: 0,
		OpsHub: 'PTG',
		Dist: 400,
		AreaName: 'Pietersburg Central',
		Surch: null,
	},
	{
		PlaceName: 'LADISMITH (CP)',
		Code: '6885    ',
		Hub: 'CPT',
		Ring: 4,
		OpsHub: 'CPT',
		Dist: 125,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'LADY FRERE',
		Code: '5410',
		Hub: 'ELS',
		Ring: 4,
		OpsHub: 'ELS',
		Dist: 250,
		AreaName: 'East London Regional',
		Surch: null,
	},
	{
		PlaceName: 'LADY GREY',
		Code: '9755',
		Hub: 'BFN',
		Ring: 4,
		OpsHub: 'BFN',
		Dist: 360,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'LADYBRAND',
		Code: '9745    ',
		Hub: 'BFN',
		Ring: 3,
		OpsHub: 'BFN',
		Dist: 130,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'LADYSMITH (NTL)',
		Code: '3370    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 250,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'LADYSMITH, KwaZuluNatal',
		Code: '3370    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 240,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'LAERSDRIF',
		Code: '1065    ',
		Hub: 'MBG',
		Ring: 2,
		OpsHub: 'JNB',
		Dist: 140,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'LAEZONIA',
		Code: '0026    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'LAGOON VIEW, Amanzimtoti',
		Code: '4126',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'LAHOFF, Klerksdorp',
		Code: '2571    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 150,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'LAINGSBURG',
		Code: '6900    ',
		Hub: 'GRJ',
		Ring: 4,
		OpsHub: 'GRJ',
		Dist: 310,
		AreaName: 'George Regional',
		Surch: 'Far Outlying',
	},
	{
		PlaceName: 'LAINGVILLE, St Helenabaai',
		Code: '7390    ',
		Hub: 'CPT',
		Ring: 4,
		OpsHub: 'CPT',
		Dist: 170,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'LAKEFIELD & Ext/Uit 9 & 2',
		Code: '1501    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'LAKESIDE Boxes',
		Code: '7955    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'LAKESIDE, Modderfontein',
		Code: '1645    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'LAKESIDE, Muizenberg',
		Code: '7945    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'LAKESIDE, Pinetown',
		Code: '3610',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'LAKEVIEW, Bloemfontein',
		Code: '9301',
		Hub: 'BFN',
		Ring: 0,
		OpsHub: 'BFN',
		Dist: 30,
		AreaName: 'Bloemfontein Central',
		Surch: null,
	},
	{
		PlaceName: 'LAKEVIEW, Johannesburg',
		Code: '2091    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'LAKEVIEW, Welkom',
		Code: '9459    ',
		Hub: 'WEL',
		Ring: 3,
		OpsHub: 'JNB',
		Dist: 150,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: "LAMBERT'S BAY",
		Code: '8130    ',
		Hub: 'CPT',
		Ring: 4,
		OpsHub: 'CPT',
		Dist: 260,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'LAMBERTSBAAI',
		Code: '8130    ',
		Hub: 'CPT',
		Ring: 4,
		OpsHub: 'CPT',
		Dist: 260,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'LAMBERTSBAY',
		Code: '8130    ',
		Hub: 'CPT',
		Ring: 4,
		OpsHub: 'CPT',
		Dist: 260,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'LAMBRECHTSDRIF',
		Code: '8820    ',
		Hub: 'BFN',
		Ring: 4,
		OpsHub: 'BFN',
		Dist: 570,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'LAMBTON Boxes',
		Code: '1414    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'LAMBTON GARDENS, Elsburg',
		Code: '1407    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'LAMBTON, Germiston',
		Code: '1401    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'LAMONTVILLE',
		Code: '4027    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'LANCASTER EAST, Krugersdorp',
		Code: '1739    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'LANCASTER WEST, Krugersdorp',
		Code: '1739    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'LANCASTER-OOS, Krugersdorp',
		Code: '1739    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'LANCASTER-WES, Krugersdorp',
		Code: '1739    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'LAND EN ZEEZICHT, Somerset west',
		Code: '7130    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 30,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'LANDSTEAD, Plumstead',
		Code: '7800    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'LANGA',
		Code: '7455    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'LANGA, Uitenhage',
		Code: '6230    ',
		Hub: 'PLZ',
		Ring: 1,
		OpsHub: 'PLZ',
		Dist: 50,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'LANGEBAAN',
		Code: '7357    ',
		Hub: 'CPT',
		Ring: 2,
		OpsHub: 'CPT',
		Dist: 120,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'LANGEBAAN ROAD',
		Code: '7375    ',
		Hub: 'CPT',
		Ring: 2,
		OpsHub: 'CPT',
		Dist: 120,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'LANGEBAANWEG',
		Code: '7375    ',
		Hub: 'CPT',
		Ring: 2,
		OpsHub: 'CPT',
		Dist: 120,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'LANGEBERG HILLS, Durbanville',
		Code: '7550    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'LANGEBERG RAND, Durbanville',
		Code: '7550    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'LANGEBERG RIDGE, Durbanville',
		Code: '7550    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'LANGEBERG VILLAGE, Durbanville',
		Code: '7550    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'LANGENHOVENPARK, Bloemfontein',
		Code: '9330    ',
		Hub: 'BFN',
		Ring: 0,
		OpsHub: 'BFN',
		Dist: 30,
		AreaName: 'Bloemfontein Central',
		Surch: null,
	},
	{
		PlaceName: 'LANGKRANS',
		Code: '3114    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 250,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'LANGLAAGTE Boxes',
		Code: '2102    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'LANGLAAGTE NORTH, Johannesburg',
		Code: '2092    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'LANGLAAGTE, Johannesburg',
		Code: '2092    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'LANGLAAGTE-NOORD, Johannesburg',
		Code: '2092    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'LANGVERWAG',
		Code: '6632    ',
		Hub: 'GRJ',
		Ring: 1,
		OpsHub: 'GRJ',
		Dist: 80,
		AreaName: 'George Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'LANSDOWNE',
		Code: '7780    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'LANSERIA',
		Code: '1748    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'LANSTA, Rondebosch',
		Code: '7700    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'LANTANA, Paarl',
		Code: '7646    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 60,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'LARRENDALE, Brakpan',
		Code: '1540    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'LAS VAGAS, Mitchells Plain',
		Code: '7785    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'LAUDINA',
		Code: '6463    ',
		Hub: 'GRJ',
		Ring: 1,
		OpsHub: 'GRJ',
		Dist: 80,
		AreaName: 'George Regional',
		Surch: null,
	},
	{
		PlaceName: 'LAUDIUM',
		Code: '0037    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'LAUDIUM Ext/Uit 2 & 3,',
		Code: '0037    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'LAURENDALE Est/Lgd, Bellville',
		Code: '7530    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'LAURIEN CREST Boxes',
		Code: '4028    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'LAURIEN CREST, Durban',
		Code: '4051',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'LAVALIA, Geogre',
		Code: '6529',
		Hub: 'GRJ',
		Ring: 0,
		OpsHub: 'GRJ',
		Dist: 10,
		AreaName: 'George Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'LAVENDER HILL Boxes',
		Code: '7948    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'LAVENDER HILL, Cape Town',
		Code: '7945    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'LAVENDER HILL, Retreat',
		Code: '7945    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'LAVENDER VALLEY Ext/Uit,',
		Code: '6140    ',
		Hub: 'PLZ',
		Ring: 4,
		OpsHub: 'PLZ',
		Dist: 170,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'LAVERSBURG, Nigel',
		Code: '1490    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 45,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'LAVISTOWN Boxes',
		Code: '7465    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'LAVISTOWN Streets',
		Code: '7490    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'LAVISTOWN, Cape Town',
		Code: '7490',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'LAWAAIKAMP, George',
		Code: '6529',
		Hub: 'GRJ',
		Ring: 0,
		OpsHub: 'GRJ',
		Dist: 10,
		AreaName: 'George Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'LAWLEY',
		Code: '1824    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 30,
		AreaName: 'Johannesburg Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'LAWRENCE VILLAGE, Maitlan',
		Code: '7405    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'LAXMI, PIETERMARITZBURG',
		Code: '3207    ',
		Hub: 'DUR',
		Ring: 2,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'LEA PLACE, Port Elizabeth',
		Code: '6001    ',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'LEACHVILLE, Brakpan',
		Code: '1540    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'LEAGLEN, Florida',
		Code: '1709    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'LEAHOLM, Bryanston',
		Code: '2021    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'LEBOGANG, Ottosdal',
		Code: '2610    ',
		Hub: 'JNB',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 300,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'LEBOWAKGOMO',
		Code: '0737    ',
		Hub: 'PTG',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 300,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'LEERKRANS',
		Code: '8818    ',
		Hub: 'BFN',
		Ring: 4,
		OpsHub: 'BFN',
		Dist: 570,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'LEEU-GAMKA',
		Code: '6950    ',
		Hub: 'CPT',
		Ring: 4,
		OpsHub: 'CPT',
		Dist: 450,
		AreaName: 'Cape Town Regional',
		Surch: 'Far Outlying',
	},
	{
		PlaceName: 'LEEUDORINGSTAD',
		Code: '2640    ',
		Hub: 'JNB',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 320,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'LEEUHOF, Vereeniging',
		Code: '1939    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 60,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'LEEUPOORT',
		Code: '0486    ',
		Hub: 'PRY',
		Ring: 4,
		OpsHub: 'PRY',
		Dist: 200,
		AreaName: 'Pretoria Regional',
		Surch: null,
	},
	{
		PlaceName: 'LEFALANE',
		Code: '0741    ',
		Hub: 'PTG',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 300,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'LEHURUTSHE',
		Code: '2880    ',
		Hub: 'RBG',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: null,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'LEIGHTON GARDENS, King Williams Town',
		Code: '5601',
		Hub: 'ELS',
		Ring: 1,
		OpsHub: 'ELS',
		Dist: 70,
		AreaName: 'East London Regional',
		Surch: null,
	},
	{
		PlaceName: 'LEIPOLDTVILLE Cape Town',
		Code: '8122    ',
		Hub: 'CPT',
		Ring: 4,
		OpsHub: 'CPT',
		Dist: 200,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'LEISURE BAY, Knysna',
		Code: '6571',
		Hub: 'GRJ',
		Ring: 1,
		OpsHub: 'GRJ',
		Dist: 80,
		AreaName: 'George Regional',
		Surch: null,
	},
	{
		PlaceName: 'LEISURE ISLE, Knysna',
		Code: '6571',
		Hub: 'GRJ',
		Ring: 1,
		OpsHub: 'GRJ',
		Dist: 80,
		AreaName: 'George Regional',
		Surch: null,
	},
	{
		PlaceName: 'LEKAZI, kaNyamazane',
		Code: '1214    ',
		Hub: 'JNB',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 310,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'LEKKERPLAAS',
		Code: '1784    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 50,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'LEKKERSING',
		Code: '8283    ',
		Hub: 'CPT',
		Ring: 4,
		OpsHub: 'CPT',
		Dist: 750,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'LEMOENKLOOF, Paarl',
		Code: '7646',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 60,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'LENASIA',
		Code: '1827    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 30,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'LENASIA EXT',
		Code: '1827',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 30,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'LENASIA EXT 1',
		Code: '1827',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 30,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'LENASIA EXT 10',
		Code: '1827',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 30,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'LENASIA EXT 11',
		Code: '1827    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 30,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'LENASIA EXT 11A',
		Code: '1827',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 30,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'LENASIA EXT 11B',
		Code: '1827',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 30,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'LENASIA EXT 12',
		Code: '1827',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 30,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'LENASIA EXT 13',
		Code: '1827',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 30,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'LENASIA EXT 17',
		Code: '1827',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 30,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'LENASIA EXT 2',
		Code: '1827',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 30,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'LENASIA EXT 3',
		Code: '1827',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 30,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'LENASIA EXT 4',
		Code: '1827',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 30,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'LENASIA EXT 5',
		Code: '1827',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 30,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'LENASIA EXT 6',
		Code: '1827',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 30,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'LENASIA EXT 7',
		Code: '1827',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 30,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'LENASIA EXT 8',
		Code: '1827',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 30,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'LENASIA EXT 9',
		Code: '1827',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 30,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'LENASIA NORTH',
		Code: '1827',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 30,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'LENASIA NTN CENTRE',
		Code: '1827',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 30,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'LENASIA SOUTH',
		Code: '1829    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 30,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'LENASIA SOUTH EXT',
		Code: '1829',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 30,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'LENASIA SOUTH EXT 1',
		Code: '1829',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 30,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'LENASIA SOUTH EXT 10',
		Code: '1829',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 30,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'LENASIA SOUTH EXT 11',
		Code: '1829',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 30,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'LENASIA SOUTH EXT 13',
		Code: '1829',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 30,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'LENASIA SOUTH EXT 2',
		Code: '1829',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 30,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'LENASIA SOUTH EXT 3',
		Code: '1829',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 30,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'LENASIA SOUTH EXT 4',
		Code: '1829',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 30,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'LENASIA SOUTH EXT 5',
		Code: '1829',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 30,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'LENASIA SOUTH EXT 5',
		Code: '1829',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 30,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'LENASIA SOUTH EXT 6',
		Code: '1829',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'LENASIA SOUTH EXT 7',
		Code: '1829',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 30,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'LENASIA SOUTH EXT 8',
		Code: '1829',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 30,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'LENASIA SOUTH EXT 9',
		Code: '1829',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'LENASIA, Phoenix',
		Code: '4068',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'LENBERRY, Lansdowne',
		Code: '7780    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'LENGAU Boxes',
		Code: '9503    ',
		Hub: 'BFN',
		Ring: 3,
		OpsHub: 'BFN',
		Dist: 170,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'LENGAU Streets',
		Code: '9499    ',
		Hub: 'BFN',
		Ring: 3,
		OpsHub: 'BFN',
		Dist: 170,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'LENHAM, Phoenix',
		Code: '4068',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'LENNOXTON, Newcastle',
		Code: '2958    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 330,
		AreaName: 'Durban Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'LENTEGEUR Boxes',
		Code: '7786    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'LENTEGEUR, Cape Town',
		Code: '7785    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'LENYENYE',
		Code: '0857    ',
		Hub: 'PTG',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 300,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'LENZ',
		Code: '1822    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 30,
		AreaName: 'Johannesburg Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'LEONARD Est/Lgd, Milnerton',
		Code: '7441    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'LEONDALE Boxes',
		Code: '1424    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'LEONDALE, Elsiesrivier',
		Code: '7490    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'LEONDALE, Germiston',
		Code: '1401    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'LEPHALALE (Ellisras)',
		Code: '0555    ',
		Hub: 'PRY',
		Ring: 4,
		OpsHub: 'PRY',
		Dist: 180,
		AreaName: 'Pretoria Regional',
		Surch: null,
	},
	{
		PlaceName: 'LEPHALALE (TT)',
		Code: '0555    ',
		Hub: 'PRY',
		Ring: 4,
		OpsHub: 'PRY',
		Dist: 180,
		AreaName: 'Pretoria Regional',
		Surch: null,
	},
	{
		PlaceName: 'LERAATSFONTEIN Boxes',
		Code: '1038    ',
		Hub: 'JNB',
		Ring: 3,
		OpsHub: 'JNB',
		Dist: 140,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'LERAATSFONTEIN, Witbank',
		Code: '1035    ',
		Hub: 'MBG',
		Ring: 3,
		OpsHub: 'JNB',
		Dist: 140,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'LERATO',
		Code: '2880    ',
		Hub: 'RBG',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: null,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'LES MARAIS, Pretoria',
		Code: '0084    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'LESEDI',
		Code: '2525    ',
		Hub: 'JNB',
		Ring: 2,
		OpsHub: 'JNB',
		Dist: 100,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'LESHWANE',
		Code: '0724    ',
		Hub: 'PTG',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 300,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'LETABA',
		Code: '0870    ',
		Hub: 'PTG',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 300,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'LETHABONG, Johannesburg',
		Code: '2090',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'LETLHABILE',
		Code: '0264    ',
		Hub: 'JNB',
		Ring: 2,
		OpsHub: 'JNB',
		Dist: 100,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'LETSATSING',
		Code: '2503    ',
		Hub: 'JNB',
		Ring: 2,
		OpsHub: 'JNB',
		Dist: 100,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'LETSITELE',
		Code: '0885    ',
		Hub: 'PTG',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 300,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'LEVUBU',
		Code: '0929    ',
		Hub: 'PTG',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 300,
		AreaName: 'Johannesburg Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'LEVYVALE, Uitenhage',
		Code: '6230    ',
		Hub: 'PLZ',
		Ring: 1,
		OpsHub: 'PLZ',
		Dist: 50,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'LEWISHAM, Krugersdorp',
		Code: '1739    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'LIBANON Boxes',
		Code: '1781    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 50,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'LIBANON, Westonaria',
		Code: '1780    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 50,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'LIBODE',
		Code: '5160    ',
		Hub: 'ELS',
		Ring: 1,
		OpsHub: 'ELS',
		Dist: null,
		AreaName: 'East London Regional',
		Surch: null,
	},
	{
		PlaceName: 'LIBRADENE, Boksburg',
		Code: '1459    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'LICHTENBURG',
		Code: '2740    ',
		Hub: 'NWP',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 270,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'LIDGETTON',
		Code: '3270    ',
		Hub: 'DUR',
		Ring: 3,
		OpsHub: 'DUR',
		Dist: 150,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'LIDIDA, Stellenbosch',
		Code: '7600    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 60,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'LIEBEN GLEN, Bellville',
		Code: '7530    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'LIEFDE EN VREDE, Johannesburg South',
		Code: '2190',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: null,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'LIESBEEK',
		Code: '7710    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'LILIANTON, Boksburg',
		Code: '1459    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'LILYVALE, Putfontein',
		Code: '1513    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'LIMBURG',
		Code: '0613    ',
		Hub: 'PTG',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 300,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'LIME ACRES',
		Code: '8410    ',
		Hub: 'KUR',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 410,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'LIMPOPO,Limpopo',
		Code: '0902',
		Hub: 'PTG',
		Ring: 4,
		OpsHub: 'PTG',
		Dist: 500,
		AreaName: 'Pietersburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'LIMPOPODRAAI',
		Code: '0536    ',
		Hub: 'PRY',
		Ring: 4,
		OpsHub: 'PRY',
		Dist: 180,
		AreaName: 'Pretoria Regional',
		Surch: null,
	},
	{
		PlaceName: 'LINBRO PARK, Bramley',
		Code: '2065    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'LINCOLN Est/Lgd, Lansdown',
		Code: '7780    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'LINCOLN MEAD, Pietermaritzburg',
		Code: '3201    ',
		Hub: 'PMB',
		Ring: 1,
		OpsHub: 'PMB',
		Dist: 75,
		AreaName: 'Pietermaritzburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'LINDBERGH PARK, Johannesburg',
		Code: '2190    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'LINDEN Boxes',
		Code: '2104    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'LINDEN Ext/Uit, Randburg',
		Code: '2194    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'LINDEN, Johannesburg',
		Code: '2195    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'LINDENE, Kimberley',
		Code: '8301    ',
		Hub: 'KIM',
		Ring: 0,
		OpsHub: 'KIM',
		Dist: 20,
		AreaName: 'Kimberley Central',
		Surch: null,
	},
	{
		PlaceName: 'LINDHAVEN Ext/Uit 3, Roodeport',
		Code: '1724    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'LINDHAVEN PARK, Roodeport',
		Code: '1724    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'LINDHAVEN, Roodepoort',
		Code: '1724    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'LINGELETHU WEST Boxes',
		Code: '7765    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'LINGELETHU WEST, Mitchells Plain',
		Code: '7785    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'LINGELETHU-WES Busse',
		Code: '7765    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'LINGELETHU-WES, Mitchells Plain',
		Code: '7785    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'LINGELIHLE Boxes',
		Code: '5881    ',
		Hub: 'PLZ',
		Ring: 4,
		OpsHub: 'PLZ',
		Dist: 260,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'LINGELIHLE, Cradock',
		Code: '5880    ',
		Hub: 'PLZ',
		Ring: 4,
		OpsHub: 'PLZ',
		Dist: 260,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'LINK HILLS',
		Code: '3652    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 20,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'LINKS, Somerset West',
		Code: '7130    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 30,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'LINKSFIELD NORTH, Johannesburg',
		Code: '2192    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'LINKSFIELD RIDGE, Johannesburg',
		Code: '2198    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'LINKSFIELD, Johannesburg',
		Code: '2192    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'LINKSFIELD-NOORD, Johannesburg',
		Code: '2192    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'LINKSIDE, Port Elizabeth',
		Code: '6001    ',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'LINKSVIEW, Benoni',
		Code: '1501    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'LINMEYER Boxes',
		Code: '2105    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'LINMEYER, Johannesburg',
		Code: '2190    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'LINTON GRANGE Boxes',
		Code: '6015    ',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'LINTON GRANGE, Port Elizabeth',
		Code: '6025',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'LIONS RIVER',
		Code: '3260    ',
		Hub: 'DUR',
		Ring: 3,
		OpsHub: 'DUR',
		Dist: 130,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'LIONSRIVIER',
		Code: '3260    ',
		Hub: 'DUR',
		Ring: 3,
		OpsHub: 'DUR',
		Dist: 130,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'LIPHAKOENG',
		Code: '2554    ',
		Hub: 'JNB',
		Ring: 3,
		OpsHub: 'JNB',
		Dist: 150,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'LISTERWOOD, Port Elizabeth',
		Code: '6001    ',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'LITTLE BRAK RIVER',
		Code: '6503    ',
		Hub: 'GRJ',
		Ring: 1,
		OpsHub: 'GRJ',
		Dist: 50,
		AreaName: 'George Regional',
		Surch: null,
	},
	{
		PlaceName: 'LITTLE CHELSEA,EMERALD HILL,Port Elizabeth',
		Code: '6070',
		Hub: 'PLZ',
		Ring: 1,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'LITTLE FALLS,Roodepoort',
		Code: '1724',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'LITTLEFILLAN, Morningside',
		Code: '2057    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'LLANDUDNO Cape Town',
		Code: '7800    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'LODEYKO, Springs',
		Code: '1559    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'LOEHILL,Johannesburg',
		Code: '2191',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'LOERIE',
		Code: '6370    ',
		Hub: 'PLZ',
		Ring: 1,
		OpsHub: 'PLZ',
		Dist: 80,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'LOERIE PARK, George',
		Code: '6529',
		Hub: 'GRJ',
		Ring: 0,
		OpsHub: 'GRJ',
		Dist: 10,
		AreaName: 'George Central',
		Surch: null,
	},
	{
		PlaceName: 'LOERIESFONTEIN',
		Code: '8185    ',
		Hub: 'CPT',
		Ring: 4,
		OpsHub: 'CPT',
		Dist: 390,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'LOEVENSTEIN, Bellville',
		Code: '7530    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'LOHATHLA',
		Code: '8420    ',
		Hub: 'KUR',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 590,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'LOMBARDY EAST, Johannesburg',
		Code: '2090    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'LOMBARDY WEST, Johannesburg',
		Code: '2090    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'LOMBARDY, Johannesburg',
		Code: '2090    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'LOMBARDY-OOS, Johannesburg',
		Code: '2090    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'LOMBARDY-WES, Johannesburg',
		Code: '2090    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'LONDON VILLAGE, Mitchells Plain',
		Code: '7785    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'LONEHILL, Bryanston',
		Code: '2062    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'LONG BEACH, Kommetjie',
		Code: '7975',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 50,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'LONG HOPE',
		Code: '5822    ',
		Hub: 'PLZ',
		Ring: 3,
		OpsHub: 'PLZ',
		Dist: 160,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'LONGCROFT, Phoenix',
		Code: '4068',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'LONGDALE, Johannesburg',
		Code: '2093    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'LONGDOWN Est/Lgd, Somerset West',
		Code: '7130    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 30,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'LONGLANDS',
		Code: '8376    ',
		Hub: 'BFN',
		Ring: 4,
		OpsHub: 'BFN',
		Dist: 270,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'LONSDALE',
		Code: '0710    ',
		Hub: 'PTG',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 400,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'LORATO, Zeerust',
		Code: '2865    ',
		Hub: 'RBG',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 320,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'LORENTZVILLE, Johannesburg',
		Code: '2094    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'LORRAINE MANOR, Port Elizabeth',
		Code: '6070',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'LORRAINE, Port Elizabeth',
		Code: '6070',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'LOSKOP',
		Code: '3330    ',
		Hub: 'HSM',
		Ring: 2,
		OpsHub: 'JNB',
		Dist: 200,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'LOTUS GARDENS, Pretoria',
		Code: '0008',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'LOTUS PARK, Isipingo',
		Code: '4133',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'LOTUS RIVER, Cape Town',
		Code: '7945    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'LOTUSRIVIER Busse',
		Code: '7805    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'LOTUSRIVIER Strate',
		Code: '7945    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'LOTUSVILLE, Aberdeen',
		Code: '6270    ',
		Hub: 'PLZ',
		Ring: 4,
		OpsHub: 'PLZ',
		Dist: 290,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'LOTUSVILLE, Verulam',
		Code: '4340    ',
		Hub: 'DUR',
		Ring: 1,
		OpsHub: 'DUR',
		Dist: 50,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'LOUGRIN, Johannesburg',
		Code: '2013    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'LOUIS BOTHA AIRPORT',
		Code: '4029    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'LOUIS BOTHALUGHAWE',
		Code: '4029    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'LOUIS TRICHARDT',
		Code: '0920    ',
		Hub: 'PTG',
		Ring: 4,
		OpsHub: 'PTG',
		Dist: 400,
		AreaName: 'Pietersburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'LOUMAR Est/Lgd, Bellville',
		Code: '7530    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'LOUTERWATER',
		Code: '6435    ',
		Hub: 'PLZ',
		Ring: 4,
		OpsHub: 'PLZ',
		Dist: 220,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: "LOUW'S BUSH, Elsiesrivier",
		Code: '7490    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: "LOUW'S CREEK",
		Code: '1302    ',
		Hub: 'JNB',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 310,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'LOUWLARDIA EXT 34, Centurion',
		Code: '0157',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'LOUWLARDIA, Centurion',
		Code: '0157',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'LOUWNA',
		Code: '8610    ',
		Hub: 'VRY',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 340,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'LOUWSBURG',
		Code: '3150    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 350,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'LOUWVILLE Boxes',
		Code: '7383    ',
		Hub: 'CPT',
		Ring: 4,
		OpsHub: 'CPT',
		Dist: 170,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'LOUWVILLE, Vredenburg',
		Code: '7380    ',
		Hub: 'CPT',
		Ring: 3,
		OpsHub: 'CPT',
		Dist: 160,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'LOVEMORE HEIGHTS, Port Elizabeth',
		Code: '6070',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'LOVEMORE PARK, Port Elizabeth',
		Code: '6070',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'LOWER HOUGHTON, Johannesburg',
		Code: '2198    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'LOWER VREDE, Cape Town',
		Code: '8001',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 0,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'LOWRYVILLE, Colesberg',
		Code: '5980    ',
		Hub: 'BFN',
		Ring: 4,
		OpsHub: 'BFN',
		Dist: 280,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'LOXTON',
		Code: '6985    ',
		Hub: 'BFN',
		Ring: 4,
		OpsHub: 'BFN',
		Dist: 500,
		AreaName: 'Bloemfontein Regional',
		Surch: 'Far Outlying',
	},
	{
		PlaceName: 'LUCKHOFF',
		Code: '9982    ',
		Hub: 'KIM',
		Ring: 4,
		OpsHub: 'KIM',
		Dist: 210,
		AreaName: 'Kimberley Regional',
		Surch: null,
	},
	{
		PlaceName: 'LUGANDA,Durban',
		Code: '3609',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'LUIPAARDSVLEI Boxes',
		Code: '1743    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'LUIPAARDSVLEI, Krugersdorp',
		Code: '1739    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'LUKASRAND, Pretoria',
		Code: '0181    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'LULEKANI',
		Code: '1392    ',
		Hub: 'PTG',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 310,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'LULET',
		Code: '6687    ',
		Hub: 'GRJ',
		Ring: 4,
		OpsHub: 'GRJ',
		Dist: 200,
		AreaName: 'George Regional',
		Surch: null,
	},
	{
		PlaceName: 'LUMIER Boxes',
		Code: '1905    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 60,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'LUMIER, Vanderbijlpark',
		Code: '1911    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 60,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'LUNEBURG',
		Code: '3183    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 400,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'LUSAKA, Chatsworth',
		Code: '4092',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'LUSIKISIKI',
		Code: '9995    ',
		Hub: 'ELS',
		Ring: 4,
		OpsHub: 'ELS',
		Dist: 240,
		AreaName: 'East London Regional',
		Surch: null,
	},
	{
		PlaceName: 'LUTZVILLE',
		Code: '8165    ',
		Hub: 'CPT',
		Ring: 4,
		OpsHub: 'CPT',
		Dist: 330,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'LYDENBURG',
		Code: '1120    ',
		Hub: 'MPG',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 350,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'LYDIANA, Pretoria',
		Code: '0184    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'LYME PARK & Ext/Uit,',
		Code: '2060    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'LYME PARK Ext/Uit 2 - 4,',
		Code: '2060    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'LYNCHFIELD, Bloemfontein',
		Code: '9364    ',
		Hub: 'BFN',
		Ring: 0,
		OpsHub: 'BFN',
		Dist: 30,
		AreaName: 'Bloemfontein Central',
		Surch: null,
	},
	{
		PlaceName: 'LYNDHURST Boxes',
		Code: '2106    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'LYNDHURST, Johannesburg',
		Code: '2192    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'LYNDHURST, Kimberley',
		Code: '8301    ',
		Hub: 'KIM',
		Ring: 0,
		OpsHub: 'KIM',
		Dist: 20,
		AreaName: 'Kimberley Central',
		Surch: null,
	},
	{
		PlaceName: 'LYNEDOCH',
		Code: '7603    ',
		Hub: 'CPT',
		Ring: 1,
		OpsHub: 'CPT',
		Dist: 60,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'LYNFIELD PARK, Pietermarizburg',
		Code: '3201    ',
		Hub: 'PMB',
		Ring: 1,
		OpsHub: 'PMB',
		Dist: 75,
		AreaName: 'Pietermaritzburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'LYNFRAE Est/Lgd, Newlands',
		Code: '7700    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'LYNHURST PARK, Pinetown',
		Code: '3610',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'LYNN EAST ',
		Code: '0040    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: "LYNN'S VIEW, Somerset West",
		Code: '7130',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 50,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'LYNNRODENE, Pretoria',
		Code: '0081    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'LYNNVILLE, Witbank',
		Code: '1035    ',
		Hub: 'MBG',
		Ring: 3,
		OpsHub: 'JNB',
		Dist: 140,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'LYNNWOOD GLEN, Pretoria',
		Code: '0081    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'LYNNWOOD MANOR, Pretoria',
		Code: '0081    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'LYNNWOOD PARK, Pretoria',
		Code: '0081    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'LYNNWOOD RIDGE, Pretoria',
		Code: '0081    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'LYNNWOOD, Pretoria',
		Code: '0081    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'LYNNWOOD, Table View',
		Code: '7441    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'LYNNWOODRIF, Pretoria',
		Code: '0081    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'LYNROY, Pietermaritzburg',
		Code: '3201    ',
		Hub: 'PMB',
		Ring: 1,
		OpsHub: 'PMB',
		Dist: 75,
		AreaName: 'Pietermaritzburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'LYTTELTON MANOR & Ext/Uit',
		Code: '0157    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'LYTTELTON, Verwoerdburg',
		Code: '0157    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'MAANDAGSHOEK',
		Code: '1152    ',
		Hub: 'JNB',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 300,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'MAANHAARRAND',
		Code: '2809    ',
		Hub: 'RBG',
		Ring: 2,
		OpsHub: 'JNB',
		Dist: 100,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'MAASSTROOM',
		Code: '0623    ',
		Hub: 'PRY',
		Ring: 4,
		OpsHub: 'PRY',
		Dist: 300,
		AreaName: 'Pretoria Regional',
		Surch: null,
	},
	{
		PlaceName: 'MABALIA, Alrode',
		Code: '1449    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'MABATHO',
		Code: '2735    ',
		Hub: 'PRY',
		Ring: 4,
		OpsHub: 'PRY',
		Dist: 250,
		AreaName: 'Pretoria Regional',
		Surch: null,
	},
	{
		PlaceName: 'MABILLE PARK, Kuilsrivier',
		Code: '7580    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'MABOPANE',
		Code: '0190',
		Hub: 'PRY',
		Ring: 1,
		OpsHub: 'PRY',
		Dist: 80,
		AreaName: 'Pretoria Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'MABUYA PARK, Rusloo',
		Code: '1468    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'MACASSAR Boxes',
		Code: '7134    ',
		Hub: 'CPT',
		Ring: 1,
		OpsHub: 'CPT',
		Dist: 30,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'MACASSAR, Somerset West',
		Code: '7130    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 30,
		AreaName: 'Cape Town Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'MACHADODORP',
		Code: '1170    ',
		Hub: 'MBG',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 350,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'MACKENZIE PARK & Ext/Uit',
		Code: '1501    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'MACKENZIEVILLE, Nigel',
		Code: '1490    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 45,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'MACLEANTOWN',
		Code: '5280    ',
		Hub: 'ELS',
		Ring: 0,
		OpsHub: 'ELS',
		Dist: 10,
		AreaName: 'East London Central',
		Surch: null,
	},
	{
		PlaceName: 'MACLEANVILLE, Port Elizabeth',
		Code: '6001    ',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'MACLEAR',
		Code: '5480    ',
		Hub: 'ELS',
		Ring: 4,
		OpsHub: 'ELS',
		Dist: 290,
		AreaName: 'East London Regional',
		Surch: null,
	},
	{
		PlaceName: 'MADADENI',
		Code: '2951    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 330,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'MADIBA, Chatsworth',
		Code: '4092',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'MAFATSANA',
		Code: '1981    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 60,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'MAFIKENG',
		Code: '2745    ',
		Hub: 'JNB',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 360,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'MAFIKENG (TT)',
		Code: '2745',
		Hub: 'JNB',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 360,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'MAGALIES VIEW Ext 4 & 15,',
		Code: '2067    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'MAGALIES VIEW, Bryanston',
		Code: '2067    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'MAGALIESBURG',
		Code: '2805    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 80,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'MAGALIESKRUIN, Pretoria',
		Code: '0150    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'MAGALIESSIG Uit 4 & 15,',
		Code: '2067    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'MAGALIESSIG, Bryanston',
		Code: '2067    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'MAGAWENI, Tongaat',
		Code: '4399',
		Hub: 'DUR',
		Ring: 1,
		OpsHub: 'DUR',
		Dist: 70,
		AreaName: 'Durban Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'MAGELEMBE',
		Code: '0466    ',
		Hub: 'PRY',
		Ring: 4,
		OpsHub: 'PRY',
		Dist: 180,
		AreaName: 'Pretoria Regional',
		Surch: null,
	},
	{
		PlaceName: 'MAGHEIGHTS, Katlehong',
		Code: '1832    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 30,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'MAGOEBASKLOOF',
		Code: '0731    ',
		Hub: 'PTG',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 300,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'MAGOGOE',
		Code: '2769',
		Hub: 'NWP',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 300,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'MAGOGONG',
		Code: '8575    ',
		Hub: 'VRY',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 260,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'MAGOGONG, North West',
		Code: '8575',
		Hub: 'VRY',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 260,
		AreaName: 'Johannesburg Regional',
		Surch: 'Far Outlying',
	},
	{
		PlaceName: 'MAHLABATINI',
		Code: '3865    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 250,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'MAHOGANY RIDGE, Pinetown',
		Code: '3608',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 20,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'MAHWELERENG',
		Code: '0626    ',
		Hub: 'PRY',
		Ring: 4,
		OpsHub: 'PRY',
		Dist: 300,
		AreaName: 'Pretoria Regional',
		Surch: null,
	},
	{
		PlaceName: 'MAIDSTONE',
		Code: '4399',
		Hub: 'DUR',
		Ring: 1,
		OpsHub: 'DUR',
		Dist: 80,
		AreaName: 'Durban Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'MAILULU, Rusloo',
		Code: '1468    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'MAIN STREET, PAARL',
		Code: '7622    ',
		Hub: 'CPT',
		Ring: 1,
		OpsHub: 'CPT',
		Dist: 60,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'MAITLAND',
		Code: '7405    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'MAJA',
		Code: '0719    ',
		Hub: 'PTG',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 300,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'MAKADIKWE',
		Code: '1062    ',
		Hub: 'JNB',
		Ring: 2,
		OpsHub: 'JNB',
		Dist: 140,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'MAKAPANSTAD',
		Code: '0401',
		Hub: 'PRY',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 50,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'MAKHADO (Louis Trichardt)',
		Code: '0920    ',
		Hub: 'PTG',
		Ring: 4,
		OpsHub: 'PTG',
		Dist: 400,
		AreaName: 'Pietersburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'MAKHAYA, Khayelitsha',
		Code: '7784    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'MAKOKSKRAAL',
		Code: '2712    ',
		Hub: 'POT',
		Ring: 3,
		OpsHub: 'JNB',
		Dist: 160,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'MAKOPPA',
		Code: '0381    ',
		Hub: 'PRY',
		Ring: 2,
		OpsHub: 'PRY',
		Dist: 120,
		AreaName: 'Pretoria Regional',
		Surch: null,
	},
	{
		PlaceName: 'MAKWASSIE',
		Code: '2650    ',
		Hub: 'JNB',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 340,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'MALABAR, Port Elizabeth',
		Code: '6020    ',
		Hub: 'PLZ',
		Ring: 1,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'MALAGAS PARK, Milnerton',
		Code: '7441    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'MALAMALA',
		Code: '1353    ',
		Hub: 'JNB',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 310,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'MALAMULELE',
		Code: '0982    ',
		Hub: 'PTG',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 300,
		AreaName: 'Johannesburg Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'MALAN, Wellington',
		Code: '7655    ',
		Hub: 'CPT',
		Ring: 2,
		OpsHub: 'CPT',
		Dist: 90,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'MALANSHOF & Ext/Uit 1 - 8',
		Code: '2194    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'MALATANE',
		Code: '0604    ',
		Hub: 'PTG',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 300,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'MALELANE',
		Code: '1320    ',
		Hub: 'NLP',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 310,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'MALIBU VILLAGE, Eersterivier',
		Code: '7100    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 15,
		AreaName: 'Cape Town Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'MALMESBURY',
		Code: '7300    ',
		Hub: 'CPT',
		Ring: 1,
		OpsHub: 'CPT',
		Dist: 67,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'MALUKAZI, Isipingo',
		Code: '4066',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'MALUNGA PARK, Guguletu',
		Code: '7750    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'MALUTI ',
		Code: '4740',
		Hub: 'DUR',
		Ring: 1,
		OpsHub: 'DUR',
		Dist: 350,
		AreaName: 'Durban Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'MALVERN',
		Code: '4055',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'MALVERN EAST Ext 1 & 3,',
		Code: '2008    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'MALVERN EAST Ext, Bedfordview',
		Code: '2008    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'MALVERN EAST, Germiston',
		Code: '1401',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'MALVERN EAST, Primrose',
		Code: '1401    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'MALVERN, Johannesburg',
		Code: '2094    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'MALVERN, N Boxes',
		Code: '4055    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'MALVERN, Queensburgh',
		Code: '4093',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'MALVERN-OOS Uit 1 & 3,',
		Code: '2008    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'MALVERN-OOS Uit, Bedfordview',
		Code: '2008    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'MALVERN-OOS, Primrose',
		Code: '1401    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'MAMELODI, Pretoria',
		Code: '0122    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'MAMRE',
		Code: '7347    ',
		Hub: 'CPT',
		Ring: 1,
		OpsHub: 'CPT',
		Dist: 50,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'MANABA BEACH',
		Code: '4276    ',
		Hub: 'DUR',
		Ring: 3,
		OpsHub: 'DUR',
		Dist: 160,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'MANAMAKGOTHENG, Rustenburg',
		Code: '0299',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 120,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'MANCORP MINE',
		Code: '8423    ',
		Hub: 'BFN',
		Ring: 4,
		OpsHub: 'BFN',
		Dist: 570,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'MANDALAY, Mitchells Plain',
		Code: '7785    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'MANDENI,Sundumbili',
		Code: '4491',
		Hub: 'DUR',
		Ring: 3,
		OpsHub: 'DUR',
		Dist: 160,
		AreaName: 'Durban Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'MANDINI',
		Code: '4490    ',
		Hub: 'DUR',
		Ring: 3,
		OpsHub: 'DUR',
		Dist: 160,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'MANENBERG Boxes',
		Code: '7767    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'MANENBERG, Cape Town',
		Code: '7764    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'MANGAUNG',
		Code: '9307    ',
		Hub: 'BFN',
		Ring: 0,
		OpsHub: 'BFN',
		Dist: 30,
		AreaName: 'Bloemfontein Central',
		Surch: null,
	},
	{
		PlaceName: 'MANGENI',
		Code: '3012    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 240,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'MANGOLD PARK, Port Elizabeth',
		Code: '6070',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'MANKURWANE',
		Code: '8345    ',
		Hub: 'BFN',
		Ring: 4,
		OpsHub: 'BFN',
		Dist: 200,
		AreaName: 'Bloemfontein Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'MANKWENG',
		Code: '0727    ',
		Hub: 'PTG',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 300,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'MANLEY FLATS',
		Code: '6145    ',
		Hub: 'PLZ',
		Ring: 4,
		OpsHub: 'PLZ',
		Dist: 170,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'MANOR GARDENS, Durban',
		Code: '4001    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'MANOR, Pietermaritzburg',
		Code: '3201    ',
		Hub: 'PMB',
		Ring: 1,
		OpsHub: 'PMB',
		Dist: 75,
		AreaName: 'Pietermaritzburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'MANORS, Pinetown',
		Code: '3610',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'MANUFACTA, Roodepoort',
		Code: '1724    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'MANYATSENG, Ladybrand',
		Code: '9745    ',
		Hub: 'BFN',
		Ring: 3,
		OpsHub: 'BFN',
		Dist: 130,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'MANYELETI',
		Code: '1362    ',
		Hub: 'JNB',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 310,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'MANZIL PARK, Klerksdorp',
		Code: '2571    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 150,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'MAOKENG, Kroonstad',
		Code: '9499    ',
		Hub: 'BFN',
		Ring: 3,
		OpsHub: 'BFN',
		Dist: 170,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'MAPELA',
		Code: '0610    ',
		Hub: 'PTG',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 300,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'MAPETLA & Ext/Uit, Tshiawelo',
		Code: '1818    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 30,
		AreaName: 'Johannesburg Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'MAPETLA EAST, Tshiawelo',
		Code: '1818    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 30,
		AreaName: 'Johannesburg Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'MAPETLA-OOS, Tshiawelo',
		Code: '1818    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 30,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'MAPUMULO',
		Code: '4470    ',
		Hub: 'DUR',
		Ring: 3,
		OpsHub: 'DUR',
		Dist: 140,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'MARA',
		Code: '0934    ',
		Hub: 'JNB',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 300,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'MARAFE, kwaXuma',
		Code: '1868    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 30,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'MARAIS STEYN PARK, Edenvale',
		Code: '1609    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'MARAIS Tsp/Dgd, Port Elizabeth',
		Code: '6001    ',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'MARAISBURG Boxes',
		Code: '1700    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'MARAISBURG, Florida',
		Code: '1709    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'MARBLE HALL',
		Code: '0450    ',
		Hub: 'PRY',
		Ring: 4,
		OpsHub: 'PRY',
		Dist: 180,
		AreaName: 'Pretoria Regional',
		Surch: null,
	},
	{
		PlaceName: 'MARBLE RAY',
		Code: '4037',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'MARBURG, Port Shepstone',
		Code: '4240    ',
		Hub: 'DUR',
		Ring: 3,
		OpsHub: 'DUR',
		Dist: 130,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'MARCHANTDALE, Port Elizabeth',
		Code: '6025',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'MARCONI BEAM, Milnerton',
		Code: '7441',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'MAREETSANE',
		Code: '8672    ',
		Hub: 'NWP',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 450,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'MARGATE',
		Code: '4275    ',
		Hub: 'DUR',
		Ring: 3,
		OpsHub: 'DUR',
		Dist: 150,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'MARIANN RIDGE, Pinetown',
		Code: '3610',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'MARIANNHILL Boxes',
		Code: '3601    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'MARIANNHILL PARK, Pinetown',
		Code: '3610',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'MARIANNHILL, Pinetown',
		Code: '3610',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'MARIANNRIF, Nagina',
		Code: '3604    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'MARIANNRIF, Pinetown',
		Code: '3610',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'MARIEPSKOP',
		Code: '1383    ',
		Hub: 'JNB',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 310,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'MARIKANA',
		Code: '0284    ',
		Hub: 'RBG',
		Ring: 2,
		OpsHub: 'JNB',
		Dist: 120,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'MARIMBA GARDENS, Rusloo',
		Code: '1468    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'MARINA BEACH',
		Code: '4281    ',
		Hub: 'DUR',
		Ring: 3,
		OpsHub: 'DUR',
		Dist: 160,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'MARINA DA GAMA, Muizenberg',
		Code: '7945    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'MARINA Est/Lgd, Retreat',
		Code: '7945    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'MARINA Est/Lgd, Somerset west',
		Code: '7130    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 30,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'MARINA MOORINGS, Muizenberg',
		Code: '7945    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'MARINDA PARK, Kuilsrivier',
		Code: '7580    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'MARINE PARADE, DURBAN Box',
		Code: '4056    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'MARINE PARADE, Durban',
		Code: '4001    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'MARINO HEIGHTS, Durban',
		Code: '4091',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'MARISHANE',
		Code: '1064    ',
		Hub: 'JNB',
		Ring: 2,
		OpsHub: 'JNB',
		Dist: 140,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'MARISTER, Bredell',
		Code: '1623    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'MARITE',
		Code: '1284    ',
		Hub: 'NLP',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 310,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'MARKEN',
		Code: '0605    ',
		Hub: 'PRY',
		Ring: 4,
		OpsHub: 'PRY',
		Dist: 300,
		AreaName: 'Pretoria Regional',
		Surch: null,
	},
	{
		PlaceName: 'MARKET SQUARE, GRAHAMSTOWN',
		Code: '6141    ',
		Hub: 'PLZ',
		Ring: 4,
		OpsHub: 'PLZ',
		Dist: 170,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'MARKET SQUARE, Kimberley',
		Code: '8301    ',
		Hub: 'KIM',
		Ring: 0,
		OpsHub: 'KIM',
		Dist: 20,
		AreaName: 'Kimberley Central',
		Surch: null,
	},
	{
		PlaceName: 'MARKET SQUARE, King William',
		Code: '5600    ',
		Hub: 'ELS',
		Ring: 1,
		OpsHub: 'ELS',
		Dist: 70,
		AreaName: 'East London Regional',
		Surch: null,
	},
	{
		PlaceName: 'MARKMAN Tsp/Dgd, Port Elizabeth',
		Code: '6210    ',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'MARKPLEIN, GRAHAMSTAD',
		Code: '6141    ',
		Hub: 'PLZ',
		Ring: 4,
		OpsHub: 'PLZ',
		Dist: 170,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'MARKPLEIN, Kimberley',
		Code: '8301    ',
		Hub: 'KIM',
		Ring: 0,
		OpsHub: 'KIM',
		Dist: 20,
		AreaName: 'Kimberley Central',
		Surch: null,
	},
	{
		PlaceName: "MARKPLEIN, King William's",
		Code: '5600    ',
		Hub: 'ELS',
		Ring: 1,
		OpsHub: 'ELS',
		Dist: 70,
		AreaName: 'East London Regional',
		Surch: null,
	},
	{
		PlaceName: 'MARLANDS, Primrose',
		Code: '1401    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'MARLBORO',
		Code: '2063    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'MARLBORO Boxes',
		Code: '2063    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'MARLBORO Ext/Uit 1, Johannesburg',
		Code: '2090    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'MARLBORO GARDENS, Marlboro',
		Code: '2063    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'MARLBORO TUINE, Marlboro',
		Code: '2063    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'MARLBOROUGH PARK, Brackenfell',
		Code: '7560    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'MARLBOROUGH PARK, Durban',
		Code: '4052    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'MARLBOROUGH PARK, Rondebosch',
		Code: '7700    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'MARNITZ',
		Code: '0620    ',
		Hub: 'PRY',
		Ring: 4,
		OpsHub: 'PRY',
		Dist: 300,
		AreaName: 'Pretoria Regional',
		Surch: null,
	},
	{
		PlaceName: 'MAROELANA, Pretoria',
		Code: '0081    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'MARSEILLES',
		Code: '9747    ',
		Hub: 'BFN',
		Ring: 3,
		OpsHub: 'BFN',
		Dist: 130,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'MARSHALLTOWN Boxes',
		Code: '2107    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'MARSHALLTOWN, Johannesburg',
		Code: '2001    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: "MARTIN'S VILLA, Somerset West",
		Code: '7130    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 30,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'MARTINDALE Cape Town',
		Code: '6146    ',
		Hub: 'PLZ',
		Ring: 4,
		OpsHub: 'PLZ',
		Dist: 170,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'MARTINDALE, Johannesburg',
		Code: '2092    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'MARULANENG',
		Code: '1067    ',
		Hub: 'NLP',
		Ring: 2,
		OpsHub: 'JNB',
		Dist: 140,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'MARVIN PARK, Somerset West',
		Code: '7130    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 30,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'MARYDALE, NOTHERN CAPE ',
		Code: '8910',
		Hub: 'BFN',
		Ring: 4,
		OpsHub: 'BFN',
		Dist: 560,
		AreaName: 'Bloemfontein Regional',
		Surch: 'Far Outlying',
	},
	{
		PlaceName: 'MARYVALE, Johannesburg',
		Code: '2192    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'MASEMOLA',
		Code: '1060    ',
		Hub: 'JNB',
		Ring: 2,
		OpsHub: 'JNB',
		Dist: 140,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'MASHAENG, Fouriesburg',
		Code: '9725    ',
		Hub: 'BFN',
		Ring: 4,
		OpsHub: 'BFN',
		Dist: 230,
		AreaName: 'Bloemfontein Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'MASHASHANE',
		Code: '0743    ',
		Hub: 'PTG',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 300,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'MASHISHING, Lydenburg',
		Code: '1123',
		Hub: 'JNB',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 350,
		AreaName: 'Johannesburg Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'MASILO, Theunissen',
		Code: '9410    ',
		Hub: 'BFN',
		Ring: 1,
		OpsHub: 'BFN',
		Dist: 55,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'MASIONS MILL, Pietermaritzburg',
		Code: '3201    ',
		Hub: 'PMB',
		Ring: 1,
		OpsHub: 'PMB',
		Dist: 75,
		AreaName: 'Pietermaritzburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'MASIPHUMELELE',
		Code: '7975',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 60,
		AreaName: 'Cape Town Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'MASOHENG',
		Code: '1908    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 60,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'MATAFFIN',
		Code: '1205    ',
		Hub: 'NLP',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 310,
		AreaName: 'Johannesburg Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'MATALENG, Barkly West',
		Code: '8375    ',
		Hub: 'BFN',
		Ring: 4,
		OpsHub: 'BFN',
		Dist: 230,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'MATATIELE',
		Code: '4730    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 290,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'MATHABATHA',
		Code: '0733    ',
		Hub: 'PTG',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 300,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'MATIMBA',
		Code: '0558    ',
		Hub: 'PRY',
		Ring: 4,
		OpsHub: 'PRY',
		Dist: 180,
		AreaName: 'Pretoria Regional',
		Surch: null,
	},
	{
		PlaceName: 'MATJIES RIVER',
		Code: '6634    ',
		Hub: 'GRJ',
		Ring: 2,
		OpsHub: 'GRJ',
		Dist: 100,
		AreaName: 'George Regional',
		Surch: null,
	},
	{
		PlaceName: 'MATJIESFONTEIN',
		Code: '6901    ',
		Hub: 'CPT',
		Ring: 4,
		OpsHub: 'CPT',
		Dist: 280,
		AreaName: 'Cape Town Regional',
		Surch: 'Far Outlying',
	},
	{
		PlaceName: 'MATJIESRIVIER',
		Code: '6634    ',
		Hub: 'GRJ',
		Ring: 2,
		OpsHub: 'GRJ',
		Dist: 100,
		AreaName: 'George Regional',
		Surch: null,
	},
	{
		PlaceName: 'MATOLA FARM, Pinetown',
		Code: '3610',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'MATROOSFONTEIN',
		Code: '7490    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'MATSULU',
		Code: '1203    ',
		Hub: 'NLP',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 310,
		AreaName: 'Johannesburg Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'MAX GOODMAN PARK, Johannesburg',
		Code: '2094    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'MAXAKENI',
		Code: '1283    ',
		Hub: 'JNB',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 310,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'MAYBERRY PARK & Ext/Uit 1',
		Code: '1449    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'MAYDON WHARF Boxes',
		Code: '4057    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'MAYDON WHARF, Durban',
		Code: '4001    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'MAYDONKAAI Busse',
		Code: '4057    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'MAYDONKAAI, Durban',
		Code: '4001    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'MAYFAIR NORTH, Johannesburg',
		Code: '2092    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'MAYFAIR WEST, Johannesburg',
		Code: '2092    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'MAYFAIR, JOHANNESBURG Box',
		Code: '2108    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'MAYFAIR, Johannesburg',
		Code: '2092    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'MAYFAIR-NOORD, Johannesburg',
		Code: '2092    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'MAYFAIR-WES, Johannesburg',
		Code: '2092    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'MAYFIELD PARK, Johannesburg',
		Code: '2091    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'MAYNARDVILLE, Wynberg',
		Code: '7800    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: "MAYOR'S WALK Boxes",
		Code: '3208    ',
		Hub: 'DUR',
		Ring: 2,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: "MAYOR'S WALK, Pietermaritzburg",
		Code: '3201    ',
		Hub: 'PMB',
		Ring: 1,
		OpsHub: 'PMB',
		Dist: 75,
		AreaName: 'Pietermaritzburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'MAYVILLE Boxes',
		Code: '4058    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'MAYVILLE, Durban',
		Code: '4091',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'MAYVILLE, Pretoria',
		Code: '0084    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'MAZENZELE',
		Code: '2624    ',
		Hub: 'JNB',
		Ring: 3,
		OpsHub: 'JNB',
		Dist: 120,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'MBAZWANA',
		Code: '3974    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 400,
		AreaName: 'Durban Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'MBEKWENI',
		Code: '7626    ',
		Hub: 'CPT',
		Ring: 1,
		OpsHub: 'CPT',
		Dist: 60,
		AreaName: 'Cape Town Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'MDANTSANE',
		Code: '5219',
		Hub: 'ELS',
		Ring: 0,
		OpsHub: 'ELS',
		Dist: 40,
		AreaName: 'East London Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'MEADOW Est/Lgd, Rondebosch',
		Code: '7700    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'MEADOWBROOK, Edenvale',
		Code: '1609    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'MEADOWDALE, GERMISTON',
		Code: '1401    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'MEADOWHURST, Jukskeipark',
		Code: '2153    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'MEADOWLANDS Boxes',
		Code: '1851    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 30,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'MEADOWLANDS Ext/Uit, Meadlands',
		Code: '1852    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 30,
		AreaName: 'Johannesburg Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'MEADOWLANDS, Soweto',
		Code: '1852    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 30,
		AreaName: 'Johannesburg Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'MEADOWRIDGE, Plumstead',
		Code: '7800    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'MEDICO',
		Code: '9319    ',
		Hub: 'BFN',
		Ring: 0,
		OpsHub: 'BFN',
		Dist: 30,
		AreaName: 'Bloemfontein Central',
		Surch: null,
	},
	{
		PlaceName: 'MEER EN SEE Boxes',
		Code: '3901    ',
		Hub: 'RCB',
		Ring: 1,
		OpsHub: 'RCB',
		Dist: 200,
		AreaName: 'Richards Bay',
		Surch: null,
	},
	{
		PlaceName: 'MEER EN SEE, Richards Bay',
		Code: '3901    ',
		Hub: 'RCB',
		Ring: 1,
		OpsHub: 'RCB',
		Dist: 200,
		AreaName: 'Richards Bay',
		Surch: null,
	},
	{
		PlaceName: 'MEERENDAL Est/Lgd, Retreat',
		Code: '7945    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'MEERHOF, Hartbeespoort',
		Code: '0216    ',
		Hub: 'JNB',
		Ring: 2,
		OpsHub: 'JNB',
		Dist: 100,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'MEGAWATT PARK, Sunninghill',
		Code: '2157    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'MEHLOMNYAMA',
		Code: '4246    ',
		Hub: 'DUR',
		Ring: 3,
		OpsHub: 'DUR',
		Dist: 130,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'MEIRINGSPARK, Klerksdorp',
		Code: '2571    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 150,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'MEIRINGSPARK, Worcester',
		Code: '6850    ',
		Hub: 'CPT',
		Ring: 2,
		OpsHub: 'CPT',
		Dist: 120,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'MELK RIVER',
		Code: '0541    ',
		Hub: 'PRY',
		Ring: 4,
		OpsHub: 'PRY',
		Dist: 180,
		AreaName: 'Pretoria Regional',
		Surch: null,
	},
	{
		PlaceName: 'MELKBOSSTRAND ',
		Code: '7441    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'MELKBOSSTRAND (S)',
		Code: '7441',
		Hub: 'CPT',
		Ring: 1,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'MELKBOSSTRAND Boxes',
		Code: '7437    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'MELKRIVIER',
		Code: '0541    ',
		Hub: 'PRY',
		Ring: 4,
		OpsHub: 'PRY',
		Dist: 180,
		AreaName: 'Pretoria Regional',
		Surch: null,
	},
	{
		PlaceName: 'MELMOTH',
		Code: '3835    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 240,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'MELODIE, Hartbeespoort',
		Code: '0216    ',
		Hub: 'JNB',
		Ring: 2,
		OpsHub: 'JNB',
		Dist: 100,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'MELODING, Virginia',
		Code: '9430    ',
		Hub: 'WEL',
		Ring: 2,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'MELON',
		Code: '6365    ',
		Hub: 'PLZ',
		Ring: 2,
		OpsHub: 'PLZ',
		Dist: 100,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'MELOPARK, Lansdowne',
		Code: '7780    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'MELROSE ARCH, Johannesburg',
		Code: '2196    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'MELROSE ESTATE, Johannesburg',
		Code: '2196    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'MELROSE GARDENS, Johannesburg',
		Code: '2196    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'MELROSE NORTH, Johannesburg',
		Code: '2196    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'MELROSE, Johannesburg',
		Code: '2196    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'MELTON ROSE Boxes',
		Code: '7101    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 15,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'MELTON ROSE, Eersterivier',
		Code: '7100    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 15,
		AreaName: 'Cape Town Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'MELTONWOLD',
		Code: '7071    ',
		Hub: 'BFN',
		Ring: 4,
		OpsHub: 'BFN',
		Dist: 500,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'MELVILLE Boxes',
		Code: '2109    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'MELVILLE, Johannesburg',
		Code: '2092    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'MELVILLE, Stanger',
		Code: '4450    ',
		Hub: 'DUR',
		Ring: 2,
		OpsHub: 'DUR',
		Dist: 120,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'MEMEL',
		Code: '2970    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 340,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'MEMORIAL PARK, Chatsworth',
		Code: '4092',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'MENLO PARK, Pretoria',
		Code: '0081    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'MENLYN, Pretoria',
		Code: '0181    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'MERAFONG Boxes',
		Code: '9483    ',
		Hub: 'BFN',
		Ring: 3,
		OpsHub: 'BFN',
		Dist: 170,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'MERAFONG, Odendaalsrus',
		Code: '9480    ',
		Hub: 'BFN',
		Ring: 3,
		OpsHub: 'BFN',
		Dist: 170,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'MEREBANK Boxes',
		Code: '4059    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'MEREBANK, Durban',
		Code: '4052    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'MEREDALE & Ext/Uit 2 & 4,',
		Code: '2091    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'MERENCOR',
		Code: '8465    ',
		Hub: 'BFN',
		Ring: 4,
		OpsHub: 'BFN',
		Dist: 400,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'MEREWENT, Durban',
		Code: '4052    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'MERRIESPRUIT Boxes',
		Code: '9433    ',
		Hub: 'BFN',
		Ring: 2,
		OpsHub: 'BFN',
		Dist: 20,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'MERRIESPRUIT, Virginia',
		Code: '9430    ',
		Hub: 'BFN',
		Ring: 2,
		OpsHub: 'BFN',
		Dist: 20,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'MERRIMAN',
		Code: '7011    ',
		Hub: 'BFN',
		Ring: 4,
		OpsHub: 'BFN',
		Dist: 500,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'MERRIVALE',
		Code: '3291    ',
		Hub: 'DUR',
		Ring: 3,
		OpsHub: 'DUR',
		Dist: 150,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'MERROW DOWN, Bryanston',
		Code: '2021    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'MERRYDALE PARK, Mitchells Plain',
		Code: '7785    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'MERRYDALE, Mitchells Plain',
		Code: '7785    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'MERWEVILLE',
		Code: '6940    ',
		Hub: 'CPT',
		Ring: 4,
		OpsHub: 'CPT',
		Dist: 450,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'MESSINA',
		Code: '0900    ',
		Hub: 'PTG',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 500,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'METRO Tsp/Dgd, Paardeneiland',
		Code: '7405    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'METSIMATSHO',
		Code: '9873    ',
		Hub: 'BFN',
		Ring: 4,
		OpsHub: 'BFN',
		Dist: 270,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'MEULSTRAAT, KAAPSTAD',
		Code: '8010    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'MEYERHOF, Bellville',
		Code: '7530    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'MEYERSDAL, Alberton',
		Code: '1449    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'MEYERSPARK & Ext/Uit 8,',
		Code: '0184    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'MEYERTON PARK, Meyerton',
		Code: '1960    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 60,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'MEYERTON, Gauteng',
		Code: '1960    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 60,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'MEYERTON,Randvaal',
		Code: '1873',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 60,
		AreaName: 'Johannesburg Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'MFOLOZI, Mtubatuba',
		Code: '3925    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 300,
		AreaName: 'Durban Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'MFULENI, Cape Town',
		Code: '7100',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'MFUME',
		Code: '4160    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 30,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'MGWALI',
		Code: '4934    ',
		Hub: 'ELS',
		Ring: 2,
		OpsHub: 'ELS',
		Dist: 100,
		AreaName: 'East London Regional',
		Surch: null,
	},
	{
		PlaceName: 'MHLUNGWANE',
		Code: '3121    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 230,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'MHLUZI Boxes',
		Code: '1053    ',
		Hub: 'JNB',
		Ring: 2,
		OpsHub: 'JNB',
		Dist: 140,
		AreaName: 'Johannesburg Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'MHLUZI, Middelburg (Tvl)',
		Code: '1050    ',
		Hub: 'MBG',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 140,
		AreaName: 'Johannesburg Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'MICA',
		Code: '1382    ',
		Hub: 'JNB',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 310,
		AreaName: 'Johannesburg Regional',
		Surch: 'Far Outlying',
	},
	{
		PlaceName: 'MICHAUSDAL Boxes',
		Code: '5884    ',
		Hub: 'PLZ',
		Ring: 4,
		OpsHub: 'PLZ',
		Dist: 300,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'MICHAUSDAL, Cradock',
		Code: '5880    ',
		Hub: 'PLZ',
		Ring: 4,
		OpsHub: 'PLZ',
		Dist: 260,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'MICOR, Johannesburg',
		Code: '2092    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'MID ILLOVO',
		Code: '3750    ',
		Hub: 'DUR',
		Ring: 1,
		OpsHub: 'DUR',
		Dist: 60,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'MID-ENNERDALE, Odin Park',
		Code: '1825    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 30,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'MIDDELBURG (TT)',
		Code: '1055',
		Hub: 'MBG',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 140,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'MIDDELBURG SOUTH, Middelburg',
		Code: '1050    ',
		Hub: 'MBG',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 140,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'MIDDELBURG, Eastern Cape',
		Code: '5900    ',
		Hub: 'PLZ',
		Ring: 4,
		OpsHub: 'PLZ',
		Dist: 350,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'MIDDELBURG, Mpumalanga ',
		Code: '1055    ',
		Hub: 'MBG',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 140,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'MIDDELBURG-SUID, Middelburg',
		Code: '1050    ',
		Hub: 'MBG',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 140,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'MIDDELPLAAS',
		Code: '6630    ',
		Hub: 'GRJ',
		Ring: 1,
		OpsHub: 'GRJ',
		Dist: 80,
		AreaName: 'George Regional',
		Surch: null,
	},
	{
		PlaceName: 'MIDDELPOS',
		Code: '8193    ',
		Hub: 'CPT',
		Ring: 4,
		OpsHub: 'CPT',
		Dist: 350,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'MIDDLETON',
		Code: '5810    ',
		Hub: 'PLZ',
		Ring: 3,
		OpsHub: 'PLZ',
		Dist: 150,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'MIDDLETON, Caledon',
		Code: '7230    ',
		Hub: 'CPT',
		Ring: 2,
		OpsHub: 'CPT',
		Dist: 100,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'MIDHURST Est/Lgd, Constantia',
		Code: '7800    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'MIDLANDS ESTATE, Midrand',
		Code: '1682',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'MIDRAND EXT 10, Johannesburg',
		Code: '1682',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'MIDRAND EXT 14, Johannesburg',
		Code: '1682',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'MIDRAND EXT 7, Johannesburg',
		Code: '1682',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'MIDRAND EXT 9, Johannesburg',
		Code: '1682',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'MIDRAND, Johannesburg',
		Code: '1682',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'MIDRIDGE PARK, Midrand',
		Code: '1682',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'MIDROS, Middelburg',
		Code: '5900    ',
		Hub: 'PLZ',
		Ring: 4,
		OpsHub: 'PLZ',
		Dist: 350,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'MIDSTREAM ESTATE, Olifantsfontein',
		Code: '1692',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'MIEDERPARK,',
		Code: '2527    ',
		Hub: 'JNB',
		Ring: 3,
		OpsHub: 'JNB',
		Dist: 150,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'MIEDERPARK, Potchefstroom',
		Code: '2531    ',
		Hub: 'JNB',
		Ring: 3,
		OpsHub: 'JNB',
		Dist: 150,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'MIGDOL',
		Code: '2775    ',
		Hub: 'VRY',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 380,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'MIKRO PARK, Kuilsrivier',
		Code: '7580    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'MILL HILL & Ext/Uit,',
		Code: '2060    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'MILL PARK, Port Elizabeth',
		Code: '6001    ',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'MILL STREET, CAPE TOWN',
		Code: '8010    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'MILLARD GRANGE, Port Elizabeth',
		Code: '6001    ',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'MILLER',
		Code: '6682    ',
		Hub: 'GRJ',
		Ring: 3,
		OpsHub: 'GRJ',
		Dist: 160,
		AreaName: 'George Regional',
		Surch: null,
	},
	{
		PlaceName: 'MILLERS POINT, Simonstown',
		Code: '7995    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 70,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'MILLSITE, Robinson (Tvl)',
		Code: '1761    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'MILNAVAIR Boxes',
		Code: '7396    ',
		Hub: 'CPT',
		Ring: 3,
		OpsHub: 'CPT',
		Dist: 150,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'MILNAVAIR, Saldanha Bay',
		Code: '7395    ',
		Hub: 'CPT',
		Ring: 3,
		OpsHub: 'CPT',
		Dist: 150,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'MILNER Est/Lgd, East London',
		Code: '5201    ',
		Hub: 'ELS',
		Ring: 0,
		OpsHub: 'ELS',
		Dist: 10,
		AreaName: 'East London Central',
		Surch: null,
	},
	{
		PlaceName: 'MILNER Est/Lgd, Lansdowne',
		Code: '7780    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'MILNERTON ',
		Code: '7441    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'MILNERTON Boxes',
		Code: '7435    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'MILNERTON, Cape Town',
		Code: '7441    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'MIMOSA PARK, Elsburg',
		Code: '1407    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'MIMOSA, Bellville',
		Code: '7530    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'MINDALORE NORTH, Krugersdorp',
		Code: '1739    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'MINDALORE, Krugersdorp',
		Code: '1739    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'MINDALORE-NOORD, Krugersdorp',
		Code: '1739    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'MINERALIA,Middelburg',
		Code: '1050',
		Hub: 'MBG',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 140,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'MINERALIA,Middelburg',
		Code: '1050',
		Hub: 'MBG',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 140,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'MINERVA PARK, East London',
		Code: '5247    ',
		Hub: 'ELS',
		Ring: 0,
		OpsHub: 'ELS',
		Dist: 10,
		AreaName: 'East London Central',
		Surch: null,
	},
	{
		PlaceName: 'MINNEBRON Boxes',
		Code: '1549    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'MINNEBRON, Brakpan',
		Code: '1549    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'MINT VILLAGE, Kimberley',
		Code: '8301    ',
		Hub: 'KIM',
		Ring: 0,
		OpsHub: 'KIM',
		Dist: 20,
		AreaName: 'Kimberley Central',
		Surch: null,
	},
	{
		PlaceName: 'MIRAMAR, Port Elizabeth',
		Code: '6070',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'MISGUND',
		Code: '6440    ',
		Hub: 'PLZ',
		Ring: 4,
		OpsHub: 'PLZ',
		Dist: 230,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'MISSIONVALE, Port Elizabeth',
		Code: '6020    ',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'MITCHELLS PLAIN, Cape Town',
		Code: '7785    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'MKHUHLU',
		Code: '1246    ',
		Hub: 'JNB',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 310,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'MKONDENI, Pietermaritzburg',
		Code: '3200',
		Hub: 'PMB',
		Ring: 0,
		OpsHub: 'PMB',
		Dist: 75,
		AreaName: 'Pietermaritzburg Central',
		Surch: null,
	},
	{
		PlaceName: 'MKONJANE',
		Code: '3007    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 300,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'MKUZE',
		Code: '3965    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 300,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'MLAMBO',
		Code: '3975    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 400,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'MLUNGISI',
		Code: '5321    ',
		Hub: 'ELS',
		Ring: 4,
		OpsHub: 'ELS',
		Dist: 200,
		AreaName: 'East London Regional',
		Surch: null,
	},
	{
		PlaceName: 'MMABATHO, Mafikeng',
		Code: '2790    ',
		Hub: 'JNB',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 360,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'MMAFEFE',
		Code: '0738    ',
		Hub: 'PTG',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 300,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'MNANDI, Somerset East',
		Code: '5850    ',
		Hub: 'PLZ',
		Ring: 4,
		OpsHub: 'PLZ',
		Dist: 190,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'MNANDI, Wierdapark',
		Code: '0149    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'MNATO, Ntokozweni',
		Code: '4066    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'MOBENI',
		Code: '4052    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'MOBENI Boxes',
		Code: '4060    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'MOBENI HEIGHTS, Durban',
		Code: '4092',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'MOBENI, Durban',
		Code: '4052    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'MODDER EAST, Springs',
		Code: '1559    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'MODDER RIVER',
		Code: '8700    ',
		Hub: 'KIM',
		Ring: 4,
		OpsHub: 'KIM',
		Dist: 200,
		AreaName: 'Kimberley Regional',
		Surch: null,
	},
	{
		PlaceName: 'MODDER-OOS, Springs',
		Code: '1559    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'MODDERBEE, Benoni',
		Code: '1501    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'MODDERDAM, Lavistown',
		Code: '7490    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'MODDERFONTEIN',
		Code: '1645    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'MODDERFONTEIN SlHs/Khw,',
		Code: '2065    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'MODDERPOORT',
		Code: '9746    ',
		Hub: 'BFN',
		Ring: 3,
		OpsHub: 'BFN',
		Dist: 130,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'MODDERRIVIER',
		Code: '8700    ',
		Hub: 'KIM',
		Ring: 4,
		OpsHub: 'KIM',
		Dist: 200,
		AreaName: 'Kimberley Regional',
		Surch: null,
	},
	{
		PlaceName: 'MODDERSPRUIT',
		Code: '0274',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 120,
		AreaName: 'Johannesburg Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'MODEL VILLAGE, Johannesburg',
		Code: '2091    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'MODELKLOOF, Ladysmith',
		Code: '3370    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 250,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'MODELPARK, Witbank',
		Code: '1035    ',
		Hub: 'MBG',
		Ring: 3,
		OpsHub: 'JNB',
		Dist: 140,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'MODIMOLE',
		Code: '0510',
		Hub: 'PRY',
		Ring: 4,
		OpsHub: 'PRY',
		Dist: 180,
		AreaName: 'Pretoria Regional',
		Surch: null,
	},
	{
		PlaceName: 'MODJADJI',
		Code: '0837    ',
		Hub: 'PTG',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 300,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'MOEDWIL',
		Code: '0315    ',
		Hub: 'RBG',
		Ring: 2,
		OpsHub: 'JNB',
		Dist: 120,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'MOETLADIMO',
		Code: '0891    ',
		Hub: 'JNB',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 300,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'MOFFAT VIEW, Johannesburg',
		Code: '2197    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'MOFOLO CENTRAL, Meadowlands',
		Code: '1852    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 30,
		AreaName: 'Johannesburg Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'MOFOLO NORTH, Meadowlands',
		Code: '1852    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 30,
		AreaName: 'Johannesburg Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'MOFOLO SOUTH, Meadowlands',
		Code: '1852    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 30,
		AreaName: 'Johannesburg Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'MOFOLO VILLAGE, Meadowlands',
		Code: '1852    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 30,
		AreaName: 'Johannesburg Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'MOFOLO-NOORD, Meadowlands',
		Code: '1852    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 30,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'MOFOLO-SUID, Meadowlands',
		Code: '1852    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 30,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'MOGALE,Johannesburg',
		Code: '1739',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'MOGANYAKA',
		Code: '0459    ',
		Hub: 'PRY',
		Ring: 4,
		OpsHub: 'PRY',
		Dist: 180,
		AreaName: 'Pretoria Regional',
		Surch: null,
	},
	{
		PlaceName: 'MOGODUMO',
		Code: '0735    ',
		Hub: 'PTG',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 300,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'MOGUL PARK, Kimberley',
		Code: '8301    ',
		Hub: 'KIM',
		Ring: 0,
		OpsHub: 'KIM',
		Dist: 20,
		AreaName: 'Kimberley Central',
		Surch: null,
	},
	{
		PlaceName: 'MOGWASE',
		Code: '0302    ',
		Hub: 'PRY',
		Ring: 2,
		OpsHub: 'PRY',
		Dist: 80,
		AreaName: 'Pretoria Regional',
		Surch: null,
	},
	{
		PlaceName: 'MOHADIN, Potchefstroom',
		Code: '2520    ',
		Hub: 'POT',
		Ring: 3,
		OpsHub: 'JNB',
		Dist: 150,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'MOHLAKENG',
		Code: '1766    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'MOIKOTSO, Lichtenburg',
		Code: '2740    ',
		Hub: 'NWP',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 270,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'MOKODUMELA',
		Code: '9868    ',
		Hub: 'BFN',
		Ring: 4,
		OpsHub: 'BFN',
		Dist: 270,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'MOKOPANE',
		Code: '0601    ',
		Hub: 'PTG',
		Ring: 1,
		OpsHub: 'PTG',
		Dist: 250,
		AreaName: 'Pietersburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'MOKOPANE (Potgietersrus)',
		Code: '0601    ',
		Hub: 'PTG',
		Ring: 3,
		OpsHub: 'JNB',
		Dist: 300,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'MOKOPANE (TT)',
		Code: '0601',
		Hub: 'PTG',
		Ring: 1,
		OpsHub: 'PTG',
		Dist: 250,
		AreaName: 'Pietersburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'MOLAPO & Ext/Uit, Tshiawelo',
		Code: '1818    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 30,
		AreaName: 'Johannesburg Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'MOLAPO VILLAGE, Tshiawelo',
		Code: '1818    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 30,
		AreaName: 'Johannesburg Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'MOLEN DRIFT',
		Code: '6537    ',
		Hub: 'GRJ',
		Ring: 0,
		OpsHub: 'GRJ',
		Dist: 10,
		AreaName: 'George Central',
		Surch: null,
	},
	{
		PlaceName: 'MOLENVLIET, Mowbray',
		Code: '7700    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'MOLETSANE, kwaXuma',
		Code: '1868    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 30,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'MOLOPO RIVER',
		Code: '8680    ',
		Hub: 'BFN',
		Ring: 4,
		OpsHub: 'BFN',
		Dist: 450,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'MOLOPO,Rustenburg',
		Code: '2755',
		Hub: 'RBG',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 300,
		AreaName: 'Johannesburg Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'MOLOPORIVIER',
		Code: '8680    ',
		Hub: 'BFN',
		Ring: 4,
		OpsHub: 'BFN',
		Dist: 450,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'MOLOTO',
		Code: '1002    ',
		Hub: 'MBG',
		Ring: 2,
		OpsHub: 'JNB',
		Dist: 90,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'MOLOTOTSI',
		Code: '0827    ',
		Hub: 'JNB',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 300,
		AreaName: 'Johannesburg Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'MOLSVLEI',
		Code: '8202    ',
		Hub: 'CPT',
		Ring: 4,
		OpsHub: 'CPT',
		Dist: 400,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'MOLTENO',
		Code: '5500    ',
		Hub: 'ELS',
		Ring: 4,
		OpsHub: 'ELS',
		Dist: 310,
		AreaName: 'East London Regional',
		Surch: null,
	},
	{
		PlaceName: 'MON REPOS, Durbanville',
		Code: '7550    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'MONAVONI, Wierdapark',
		Code: '0149    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'MONDEOR Boxes',
		Code: '2110    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'MONDEOR, Johannesburg',
		Code: '2091    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'MONT-AUX-SOURCES',
		Code: '3353    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 260,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'MONTAGU',
		Code: '6720    ',
		Hub: 'CPT',
		Ring: 2,
		OpsHub: 'CPT',
		Dist: 200,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'MONTAGU Est/Lgd, Grassy Park',
		Code: '7945    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'MONTAGU GIFTS Est/Lgd,',
		Code: '7945    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'MONTAGUE GARDENS',
		Code: '7441    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'MONTAGUE GARDENS, Milnerton',
		Code: '7441    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'MONTAGUE TUINE, Milnerton',
		Code: '7441    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'MONTANA PARK, Pretoria',
		Code: '0182',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'MONTANA, De Aar',
		Code: '7000    ',
		Hub: 'BFN',
		Ring: 4,
		OpsHub: 'BFN',
		Dist: 500,
		AreaName: 'Bloemfontein Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'MONTANA, Matroosfontein',
		Code: '7490    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'MONTANA, Pretoria',
		Code: '0151    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'MONTCLAIR Boxes',
		Code: '4061    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'MONTCLAIR, Durban',
		Code: '4004',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'MONTCLAIR, Johannesburg',
		Code: '2092    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'MONTCLAIR, Mitchells Plain',
		Code: '7785    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'MONTCLAIR, Somerset West',
		Code: '7130    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 30,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'MONTE SERENO, Somerset West',
		Code: '7130',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 40,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'MONTE VIDEO, Matroosfontein',
		Code: '7490    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'MONTE VISTA Boxes',
		Code: '7461    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'MONTE VISTA, Cape Town',
		Code: '7460    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'MONTEREY Est/Lgd, Constantia',
		Code: '7800    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'MONTFORD, Chatsworth',
		Code: '4092',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'MONTGOMERY PARK, Johannesburg',
		Code: '2195    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'MONTPARK, Johannesburg',
		Code: '2195    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'MONTROSE Est/Lgd, Plumstead',
		Code: '7800    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'MONTROSE, Pietermaritzburg',
		Code: '3200',
		Hub: 'PMB',
		Ring: 0,
		OpsHub: 'PMB',
		Dist: 75,
		AreaName: 'Pietermaritzburg Central',
		Surch: null,
	},
	{
		PlaceName: 'MONTROUX, Johannesburg',
		Code: '2195    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'MONUMENT & Ext/Uit, Krugersdorp',
		Code: '1739    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'MONUMENT HEIGHTS, Kimberley',
		Code: '8301    ',
		Hub: 'KIM',
		Ring: 0,
		OpsHub: 'KIM',
		Dist: 20,
		AreaName: 'Kimberley Central',
		Surch: null,
	},
	{
		PlaceName: 'MONUMENTPARK & Ext/Uit 2',
		Code: '0181    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'MOODIEHILL, Morningside',
		Code: '2057    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'MOOI RIVER',
		Code: '3300    ',
		Hub: 'HSM',
		Ring: 3,
		OpsHub: 'JNB',
		Dist: 160,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'MOOIGEZIGHT, Bellville',
		Code: '7530    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'MOOIKLOOF RIDGE, Pretoria',
		Code: '0081',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'MOOIKLOOF, Pretoria',
		Code: '0081',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'MOOINOOI',
		Code: '0325    ',
		Hub: 'JNB',
		Ring: 2,
		OpsHub: 'JNB',
		Dist: 120,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'MOOIPLAAS',
		Code: '5288    ',
		Hub: 'ELS',
		Ring: 0,
		OpsHub: 'ELS',
		Dist: 10,
		AreaName: 'East London Central',
		Surch: null,
	},
	{
		PlaceName: 'MOOIRIVIER',
		Code: '3300    ',
		Hub: 'HSM',
		Ring: 3,
		OpsHub: 'JNB',
		Dist: 160,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'MOOIVALLEIPARK, Potchefstroon',
		Code: '2520    ',
		Hub: 'POT',
		Ring: 3,
		OpsHub: 'JNB',
		Dist: 150,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'MOOIVERWACHT, Bellville',
		Code: '7530    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'MOOKETSI',
		Code: '0825    ',
		Hub: 'PTG',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 300,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'MOOKGOPONG',
		Code: '0560',
		Hub: 'PRY',
		Ring: 4,
		OpsHub: 'PRY',
		Dist: 180,
		AreaName: 'Pretoria Regional',
		Surch: null,
	},
	{
		PlaceName: 'MOOLENBERG Est/Lgd, Rondebosch',
		Code: '7700    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'MOORREESBURG',
		Code: '7310    ',
		Hub: 'CPT',
		Ring: 1,
		OpsHub: 'CPT',
		Dist: 100,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'MOORTON, Chatsworth',
		Code: '4092',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'MOPANE',
		Code: '0925    ',
		Hub: 'JNB',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 300,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'MORAVIA',
		Code: '7322    ',
		Hub: 'CPT',
		Ring: 3,
		OpsHub: 'CPT',
		Dist: 130,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'MOREGLOED, Pietersburg',
		Code: '0699',
		Hub: 'PTG',
		Ring: 0,
		OpsHub: 'PTG',
		Dist: 400,
		AreaName: 'Pietersburg Central',
		Surch: null,
	},
	{
		PlaceName: 'MOREGLOED, Pretoria',
		Code: '0186    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'MOREGROVE, Port Elizabeth',
		Code: '6025',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'MOREHILL & Ext/Uit 2,',
		Code: '1501    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'MORELETAPARK, Pretoria',
		Code: '0044    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'MORESKOF Boxes',
		Code: '9462    ',
		Hub: 'BFN',
		Ring: 3,
		OpsHub: 'BFN',
		Dist: 150,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'MORESKOF, Welkom',
		Code: '9459    ',
		Hub: 'WEL',
		Ring: 3,
		OpsHub: 'JNB',
		Dist: 150,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'MORET, Randburg',
		Code: '2194    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'MOREWAG, Kroonstad',
		Code: '9499    ',
		Hub: 'BFN',
		Ring: 3,
		OpsHub: 'BFN',
		Dist: 170,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: "MORGAN'S BAY",
		Code: '5292    ',
		Hub: 'ELS',
		Ring: 0,
		OpsHub: 'ELS',
		Dist: 90,
		AreaName: 'East London Regional',
		Surch: null,
	},
	{
		PlaceName: 'MORGANRIDGE & Ext/Uit 2,',
		Code: '1459    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'MORGANSBAAI',
		Code: '5292    ',
		Hub: 'ELS',
		Ring: 0,
		OpsHub: 'ELS',
		Dist: 10,
		AreaName: 'East London Central',
		Surch: null,
	},
	{
		PlaceName: 'MORGENSTER, Brackenfell',
		Code: '7560    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'MORGENSTER, Mitchells Plain',
		Code: '7785    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'MORGENZON HEIGHTS Brackenfell',
		Code: '7560    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'MORNING HILL, Bedfordview',
		Code: '2008    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'MORNINGSIDE HILLS, Morningside',
		Code: '2057    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'MORNINGSIDE MANOR,',
		Code: '2052    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'MORNINGSIDE SUMMIT, Morningside',
		Code: '2057    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'MORNINGSIDE, Bryanston',
		Code: '2057    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'MORNINGSIDE, Durban',
		Code: '4001    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'MORNINGSIDE, East London',
		Code: '5201    ',
		Hub: 'ELS',
		Ring: 0,
		OpsHub: 'ELS',
		Dist: 10,
		AreaName: 'East London Central',
		Surch: null,
	},
	{
		PlaceName: 'MORNINGSIDE, Maitland',
		Code: '7405    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'MORNINGSIDE, Port Elizabeth',
		Code: '6025',
		Hub: 'PLZ',
		Ring: 1,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'MORNINGSIDE, Somerset West',
		Code: '7130    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 30,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'MORNINGSIDE, Tokai',
		Code: '7945    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'MORNINGSTAR, Diepriver',
		Code: '7800    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'MORNINGSTAR, Durbanville',
		Code: '7550    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'MOROKA & Ext/Uit, Tshiawelo',
		Code: '1818    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 30,
		AreaName: 'Johannesburg Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'MOROKA Boxes',
		Code: '1860    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 30,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'MOROKA NORTH, Tshiawelo',
		Code: '1818    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 30,
		AreaName: 'Johannesburg Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'MOROKA-NOORD, Tshiawelo',
		Code: '1818    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 30,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'MOROLONG',
		Code: '0433    ',
		Hub: 'PRY',
		Ring: 4,
		OpsHub: 'PRY',
		Dist: 180,
		AreaName: 'Pretoria Regional',
		Surch: null,
	},
	{
		PlaceName: 'MORRISON',
		Code: '4226    ',
		Hub: 'DUR',
		Ring: 2,
		OpsHub: 'DUR',
		Dist: 110,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'MORTIMER',
		Code: '5870    ',
		Hub: 'PLZ',
		Ring: 4,
		OpsHub: 'PLZ',
		Dist: 240,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'MORULENG',
		Code: '0318',
		Hub: 'PRY',
		Ring: 1,
		OpsHub: 'PRY',
		Dist: 203,
		AreaName: 'Pretoria Regional',
		Surch: null,
	},
	{
		PlaceName: 'MOSCOW,Durban',
		Code: '4066',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'MOSELEY PARK, Pinetown',
		Code: '3610',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'MOSELEY, Queensburgh',
		Code: '4093',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'MOSELVILLE',
		Code: '6232    ',
		Hub: 'PLZ',
		Ring: 1,
		OpsHub: 'PLZ',
		Dist: 60,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'MOSORONI',
		Code: '0874    ',
		Hub: 'JNB',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 300,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'MOSSEL BAY',
		Code: '6506',
		Hub: 'GRJ',
		Ring: 1,
		OpsHub: 'GRJ',
		Dist: 60,
		AreaName: 'George Regional',
		Surch: null,
	},
	{
		PlaceName: 'MOSSELBAAI',
		Code: '6506',
		Hub: 'GRJ',
		Ring: 1,
		OpsHub: 'GRJ',
		Dist: 60,
		AreaName: 'George Regional',
		Surch: null,
	},
	{
		PlaceName: 'MOSTERTSDRIFT, Stellenbocsh',
		Code: '7600    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 60,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'MOSTYN PARK, Honeydew',
		Code: '2040    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'MOTEBONG',
		Code: '2514    ',
		Hub: 'JNB',
		Ring: 2,
		OpsHub: 'JNB',
		Dist: 100,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'MOTETEMA',
		Code: '0473    ',
		Hub: 'PRY',
		Ring: 4,
		OpsHub: 'PRY',
		Dist: 180,
		AreaName: 'Pretoria Regional',
		Surch: null,
	},
	{
		PlaceName: 'MOTHERWELL',
		Code: '6210    ',
		Hub: 'PLZ',
		Ring: 1,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'MOTHIBISTAD',
		Code: '8460    ',
		Hub: 'KUR',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 235,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'MOTHUTLUNG, Brits',
		Code: '0250    ',
		Hub: 'JNB',
		Ring: 2,
		OpsHub: 'JNB',
		Dist: 90,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'MOTORTOWN',
		Code: '2111    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'MOTSETHABONG',
		Code: '9463    ',
		Hub: 'WEL',
		Ring: 3,
		OpsHub: 'WEL',
		Dist: 150,
		AreaName: 'Welkom Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'MOUILLE POINT, Cape Town',
		Code: '8005',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'MOUNT AYLIFF',
		Code: '4735    ',
		Hub: 'DUR',
		Ring: 1,
		OpsHub: 'DUR',
		Dist: null,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'MOUNT CROIX, Port Elizabeth',
		Code: '6001    ',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'MOUNT EDGECOMBE, Durban',
		Code: '4302',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 30,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'MOUNT FLETCHER',
		Code: '9995    ',
		Hub: 'ELS',
		Ring: 4,
		OpsHub: 'ELS',
		Dist: 300,
		AreaName: 'East London Regional',
		Surch: null,
	},
	{
		PlaceName: 'MOUNT FRERE',
		Code: '9995    ',
		Hub: 'ELS',
		Ring: 4,
		OpsHub: 'ELS',
		Dist: 250,
		AreaName: 'East London Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'MOUNT MORIAH, KwaMashu',
		Code: '4051',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 25,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'MOUNT PLEASANT, Hermanus',
		Code: '7200    ',
		Hub: 'CPT',
		Ring: 2,
		OpsHub: 'CPT',
		Dist: 100,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'MOUNT PLEASANT, Port Elizabeth',
		Code: '6070',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'MOUNT ROAD TOWNSHIP,',
		Code: '6001    ',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'MOUNT VERNON, Durban',
		Code: '4094    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'MOUNT VIEW, Lansdowne',
		Code: '7764    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'MOUNT VIEW, Verulam',
		Code: '4340    ',
		Hub: 'DUR',
		Ring: 1,
		OpsHub: 'DUR',
		Dist: 50,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'MOUNTAIN GREEN VILLAGE, Retreat',
		Code: '7945    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'MOUNTAIN GREEN, Retreat',
		Code: '7945    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'MOUNTAIN RISE, Pietermarizburg',
		Code: '3201    ',
		Hub: 'PMB',
		Ring: 1,
		OpsHub: 'PMB',
		Dist: 75,
		AreaName: 'Pietermaritzburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'MOUNTAIN VIEW Est/Lgd, Milnerton',
		Code: '7441    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'MOUNTAIN VIEW VILLAGE, Retreat',
		Code: '7945    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'MOUNTAIN VIEW, Johannesburg',
		Code: '2192    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'MOUNTAIN VIEW, Middelburg',
		Code: '1050    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 140,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'MOUNTAIN VIEW, Paarl',
		Code: '7646    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 60,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'MOUNTAIN VIEW, Pretoria',
		Code: '0082    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'MOUNTAIN VIEW, Uitenhage',
		Code: '6230    ',
		Hub: 'PLZ',
		Ring: 1,
		OpsHub: 'PLZ',
		Dist: 50,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'MOUNTVIEW, Milnerton',
		Code: '7441    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'MOUTH VIEW, Pietermaritzburg',
		Code: '3201    ',
		Hub: 'PMB',
		Ring: 1,
		OpsHub: 'PMB',
		Dist: 75,
		AreaName: 'Pietermaritzburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'MOWBRAY',
		Code: '7700    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'MOWBRAY Boxes',
		Code: '7705    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'MOWBRAY, Rondebosch',
		Code: '7700    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'MOYAMKHULU',
		Code: '2519    ',
		Hub: 'JNB',
		Ring: 2,
		OpsHub: 'JNB',
		Dist: 100,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'MOZODO, Meadowlands',
		Code: '1852    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 30,
		AreaName: 'Johannesburg Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'MPHAHLELE',
		Code: '0736    ',
		Hub: 'PTG',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 300,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'MPHATLALATSANE',
		Code: '1769    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'MPHOGODIBA',
		Code: '0732    ',
		Hub: 'PTG',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 300,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'MPOLWENI',
		Code: '3430    ',
		Hub: 'DUR',
		Ring: 2,
		OpsHub: 'DUR',
		Dist: 100,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'MPUDULLE',
		Code: '1057    ',
		Hub: 'JNB',
		Ring: 2,
		OpsHub: 'JNB',
		Dist: 140,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'MPUNZI DRIFT',
		Code: '4261    ',
		Hub: 'DUR',
		Ring: 3,
		OpsHub: 'DUR',
		Dist: 160,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'MQANDULI',
		Code: '5080',
		Hub: 'ELS',
		Ring: 1,
		OpsHub: 'ELS',
		Dist: 180,
		AreaName: 'East London Regional',
		Surch: null,
	},
	{
		PlaceName: 'MSELENI, Flagstaff',
		Code: '4810',
		Hub: 'DUR',
		Ring: 1,
		OpsHub: 'DUR',
		Dist: 360,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'MSHENGUVILLE, Meadowlands',
		Code: '1852    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 30,
		AreaName: 'Johannesburg Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'MSINDO',
		Code: '0456    ',
		Hub: 'PRY',
		Ring: 4,
		OpsHub: 'PRY',
		Dist: 180,
		AreaName: 'Pretoria Regional',
		Surch: null,
	},
	{
		PlaceName: 'MSOGWABA',
		Code: '1215    ',
		Hub: 'NLP',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 310,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'MTAMVUNA',
		Code: '4683    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 180,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'MTHAMBOTHINI',
		Code: '0462    ',
		Hub: 'PRY',
		Ring: 4,
		OpsHub: 'PRY',
		Dist: 180,
		AreaName: 'Pretoria Regional',
		Surch: null,
	},
	{
		PlaceName: 'MTHATHA',
		Code: '5100',
		Hub: 'ELS',
		Ring: 1,
		OpsHub: 'ELS',
		Dist: 180,
		AreaName: 'East London Regional',
		Surch: null,
	},
	{
		PlaceName: 'MTUBATUBA',
		Code: '3935    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 300,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'MTUNZINI',
		Code: '3867    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 200,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'MTWALUME',
		Code: '4186    ',
		Hub: 'DUR',
		Ring: 1,
		OpsHub: 'DUR',
		Dist: 80,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'MUCKLENEUK, Pretoria',
		Code: '0002    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'MUDEN',
		Code: '3501    ',
		Hub: 'DUR',
		Ring: 3,
		OpsHub: 'DUR',
		Dist: 150,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'MUGENA',
		Code: '1366    ',
		Hub: 'JNB',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 310,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'MUIZENBERG Boxes',
		Code: '7950    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'MUIZENBERG, Cape Town',
		Code: '7945    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'MULBARTON Ext/Uit 1 - 4,',
		Code: '2059    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'MULBARTON SOUTH, Glenvista',
		Code: '2059    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'MULBARTON, Glenvista',
		Code: '2059    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'MULBARTON,Johannesburg',
		Code: '2190',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'MULBARTON-SUID, Glenvista',
		Code: '2059    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'MULBERRY PARK, Pinetown',
		Code: '4093',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'MULDERSDRIFT, Krugersdorp',
		Code: '1739',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'MUNNIK',
		Code: '0703    ',
		Hub: 'PTG',
		Ring: 4,
		OpsHub: 'PTG',
		Dist: 400,
		AreaName: 'Pietersburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'MUNSIEVILLE, Krugersdorp',
		Code: '1739    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'MUNSTER',
		Code: '4278    ',
		Hub: 'DUR',
		Ring: 3,
		OpsHub: 'DUR',
		Dist: 160,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'MURCHISON',
		Code: '4250    ',
		Hub: 'DUR',
		Ring: 2,
		OpsHub: 'DUR',
		Dist: 110,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'MURDOCH VALLEY, Simonstown',
		Code: '7995    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 70,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'MURRAYFIELD & Ext/Uit 1,',
		Code: '0184    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'MURRAYSBURG',
		Code: '6995    ',
		Hub: 'BFN',
		Ring: 4,
		OpsHub: 'BFN',
		Dist: 500,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'MUSGRAVE ROAD, DURBAN',
		Code: '4062    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'MUSGRAVE, Berea',
		Code: '4001    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'MUSGRAVEWEG, DURBAN',
		Code: '4062    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'MUSHWELLDALE, Boksburg',
		Code: '1459    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'MUSINA, Limpopo',
		Code: '0900',
		Hub: 'PTG',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 500,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'MUTHATHI',
		Code: '0976    ',
		Hub: 'PTG',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 300,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'MUTUAL/STA, Maitland',
		Code: '7405    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'MUZIMUHLE Boxes',
		Code: '2627    ',
		Hub: 'JNB',
		Ring: 3,
		OpsHub: 'JNB',
		Dist: 120,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'MUZIMUHLE, Orkney',
		Code: '2620    ',
		Hub: 'JNB',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 220,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'MVUNYANA',
		Code: '3120    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 230,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'MZAMOMHLE Boxes',
		Code: '5522    ',
		Hub: 'ELS',
		Ring: 4,
		OpsHub: 'ELS',
		Dist: 400,
		AreaName: 'East London Regional',
		Surch: null,
	},
	{
		PlaceName: 'MZAMOMHLE, Burgersdorp',
		Code: '5520    ',
		Hub: 'ELS',
		Ring: 4,
		OpsHub: 'ELS',
		Dist: 360,
		AreaName: 'East London Regional',
		Surch: null,
	},
	{
		PlaceName: 'MZINGIZI, Cedarville',
		Code: '4720    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 260,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'MZINI',
		Code: '1768    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'MZOMUSHA,Durban',
		Code: '4310',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 50,
		AreaName: 'Durban Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'McGREGOR',
		Code: '6708    ',
		Hub: 'CPT',
		Ring: 4,
		OpsHub: 'CPT',
		Dist: 180,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'McNAMEE VILLAGE, Port Elizabeth',
		Code: '6200    ',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'McNAUGHTON, Uitenhage',
		Code: '6241    ',
		Hub: 'PLZ',
		Ring: 1,
		OpsHub: 'PLZ',
		Dist: 50,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'NABOOMSPRUIT',
		Code: '0560    ',
		Hub: 'PRY',
		Ring: 4,
		OpsHub: 'PRY',
		Dist: 180,
		AreaName: 'Pretoria Regional',
		Surch: null,
	},
	{
		PlaceName: 'NAGINA',
		Code: '3604    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'NAGLEWOOD',
		Code: '4063    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'NAHOON Boxes',
		Code: '5210    ',
		Hub: 'ELS',
		Ring: 0,
		OpsHub: 'ELS',
		Dist: 10,
		AreaName: 'East London Central',
		Surch: null,
	},
	{
		PlaceName: 'NAHOON DAM, East London',
		Code: '5201    ',
		Hub: 'ELS',
		Ring: 0,
		OpsHub: 'ELS',
		Dist: 10,
		AreaName: 'East London Central',
		Surch: null,
	},
	{
		PlaceName: 'NAHOON VALLEY PARK, East London',
		Code: '5241    ',
		Hub: 'ELS',
		Ring: 0,
		OpsHub: 'ELS',
		Dist: 10,
		AreaName: 'East London Central',
		Surch: null,
	},
	{
		PlaceName: 'NAHOON VALLEY, East London',
		Code: '5241    ',
		Hub: 'ELS',
		Ring: 0,
		OpsHub: 'ELS',
		Dist: 10,
		AreaName: 'East London Central',
		Surch: null,
	},
	{
		PlaceName: 'NAHOON, East London',
		Code: '5241    ',
		Hub: 'ELS',
		Ring: 0,
		OpsHub: 'ELS',
		Dist: 10,
		AreaName: 'East London Central',
		Surch: null,
	},
	{
		PlaceName: 'NALEDI',
		Code: '1861    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 30,
		AreaName: 'Johannesburg Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'NALEDI & Ext/Uit, kwaXuma',
		Code: '1868    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 30,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'NAMAKGALE',
		Code: '1391    ',
		Hub: 'PTG',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 310,
		AreaName: 'Johannesburg Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'NANCEFIELD HOSTEL, Pimville',
		Code: '1808    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 50,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'NANCEFIELD INDUSTRIAL SIT',
		Code: '1812    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 30,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'NANCEFIELD, Kliptown',
		Code: '1812    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 30,
		AreaName: 'Johannesburg Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'NAPIER',
		Code: '7270    ',
		Hub: 'CPT',
		Ring: 3,
		OpsHub: 'CPT',
		Dist: 150,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'NAPIERVILLE, Pietermaritzburg',
		Code: '3201    ',
		Hub: 'PMB',
		Ring: 1,
		OpsHub: 'PMB',
		Dist: 75,
		AreaName: 'Pietermaritzburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'NARUNA Est/Lgd, Southfield',
		Code: '7800    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'NASHVILLE, Pimville',
		Code: '1808    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 50,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'NASREC, Johannesburg',
		Code: '2091',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 0,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'NATALSPRUIT',
		Code: '1449    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: 'Township',
	},
	{
		PlaceName: "NATURE'S VALLEY, Somerset west",
		Code: '7130    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 30,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'NATURENA,',
		Code: '2064    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'NATURES VALLEY, Durbanville',
		Code: '7550',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 30,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'NAUDEVILLE, Welkom',
		Code: '9459    ',
		Hub: 'WEL',
		Ring: 3,
		OpsHub: 'JNB',
		Dist: 150,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'NAVORSDORP, Pretoria',
		Code: '0184    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'NAZARETH, Middelburg',
		Code: '1050    ',
		Hub: 'MBG',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 140,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'NCANARA',
		Code: '6180    ',
		Hub: 'PLZ',
		Ring: 1,
		OpsHub: 'PLZ',
		Dist: 50,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'NCANDU PARK, Newcastle',
		Code: '2940    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 320,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'NDABENI',
		Code: '7405    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'NDULI, Ceres',
		Code: '6835',
		Hub: 'CPT',
		Ring: 1,
		OpsHub: 'CPT',
		Dist: 120,
		AreaName: 'Cape Town Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'NDULINDE',
		Code: '3803    ',
		Hub: 'DUR',
		Ring: 3,
		OpsHub: 'DUR',
		Dist: 160,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'NDUNDULU',
		Code: '3817    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 240,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'NDWEDWE',
		Code: '4342    ',
		Hub: 'DUR',
		Ring: 1,
		OpsHub: 'DUR',
		Dist: 80,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'NEANDERTAL',
		Code: '0714    ',
		Hub: 'PTG',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 400,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'NEAVE Tsp/Dgd, Port Elizabeth',
		Code: '6020    ',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'NEBO',
		Code: '1059    ',
		Hub: 'MPG',
		Ring: 2,
		OpsHub: 'JNB',
		Dist: 140,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'NEDERBERG, Kuilsriver',
		Code: '7580    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'NEDERBURG Boxes',
		Code: '7627    ',
		Hub: 'CPT',
		Ring: 1,
		OpsHub: 'CPT',
		Dist: 60,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'NEDERBURG, Paarl',
		Code: '7646    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 60,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'NEHINA Est/Lgd, Plumstead',
		Code: '7800    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'NEILERSDRIF',
		Code: '8862    ',
		Hub: 'BFN',
		Ring: 4,
		OpsHub: 'BFN',
		Dist: 580,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'NEL ACRES',
		Code: '1200',
		Hub: 'NLP',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'NELSPOORT',
		Code: '6973    ',
		Hub: 'CPT',
		Ring: 4,
		OpsHub: 'CPT',
		Dist: 490,
		AreaName: 'Cape Town Regional',
		Surch: 'Far Outlying',
	},
	{
		PlaceName: 'NELSPRUIT',
		Code: '1201',
		Hub: 'NLP',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'NELSPRUIT EXT',
		Code: '1201',
		Hub: 'NLP',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'NELSPRUIT EXT 15',
		Code: '1201',
		Hub: 'NLP',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'NELSPRUIT EXT 2',
		Code: '1201',
		Hub: 'NLP',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'NELSPRUIT EXT 21',
		Code: '1201',
		Hub: 'NLP',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'NELSPRUIT EXT 4',
		Code: '1201',
		Hub: 'NLP',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'NELSPRUIT EXT 5',
		Code: '1201',
		Hub: 'NLP',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'NELSPRUIT EXT 6',
		Code: '1201',
		Hub: 'NLP',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'NELSPRUIT EXT 9 ',
		Code: '1201',
		Hub: 'NLP',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'NELSVILLE, Nelspruit',
		Code: '1201',
		Hub: 'NLP',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 360,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'NERINA, Durbanville',
		Code: '7550    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'NERISSA Est/Lgd, Lansdown',
		Code: '7780    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'NERISSA, Rondebosch',
		Code: '7780    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'NESERHOF, Klerksdorp',
		Code: '2571    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 150,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'NETHERBY',
		Code: '4550    ',
		Hub: 'DUR',
		Ring: 3,
		OpsHub: 'DUR',
		Dist: 150,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'NETREG, Bontheuwel',
		Code: '7764    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'NEW BATAVIA, Rondebosch',
		Code: '7700    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'NEW BRIGHTON',
		Code: '6200    ',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'NEW BRIGHTON VILLAGE,',
		Code: '6205    ',
		Hub: 'PLZ',
		Ring: 1,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'NEW BRIGHTON, De Aar',
		Code: '7000    ',
		Hub: 'BFN',
		Ring: 4,
		OpsHub: 'BFN',
		Dist: 500,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'NEW BRIGHTON, Johannesburg',
		Code: '2196    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'NEW BRIGHTON, Somerset East',
		Code: '5850    ',
		Hub: 'PLZ',
		Ring: 4,
		OpsHub: 'PLZ',
		Dist: 190,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'NEW CANADA, Johannesburg',
		Code: '2093    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'NEW CENTRE, Johannesburg',
		Code: '2001    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'NEW CLYDESDALE, Van Dyksd',
		Code: '2245    ',
		Hub: 'MBG',
		Ring: 2,
		OpsHub: 'JNB',
		Dist: 120,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'NEW CROSS ROADS, Guguletu',
		Code: '7755    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'NEW DAWN PARK, Durban',
		Code: '4037',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'NEW DOORNFONTEIN, Johannesburg',
		Code: '2094    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'NEW GERMANY Boxes',
		Code: '3620    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'NEW GERMANY, Durban',
		Code: '3610',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'NEW GREEN POINT, Khayelitsha',
		Code: '7784',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'NEW HANOVER',
		Code: '3440    ',
		Hub: 'DUR',
		Ring: 1,
		OpsHub: 'DUR',
		Dist: 35,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'NEW LOCATION, Brandfortein',
		Code: '9400    ',
		Hub: 'BFN',
		Ring: 1,
		OpsHub: 'BFN',
		Dist: 70,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'NEW LOCATION, Randfontein',
		Code: '1760    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'NEW MACASSAR VILLAGE',
		Code: '7130    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 30,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'NEW MACHAVIE',
		Code: '2529    ',
		Hub: 'JNB',
		Ring: 3,
		OpsHub: 'JNB',
		Dist: 150,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'NEW MODDER, Benoni',
		Code: '1501    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'NEW MUCKLENEUK, Pretoria',
		Code: '0181    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'NEW ORLEANS, Paarl',
		Code: '7646    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 60,
		AreaName: 'Cape Town Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'NEW PARK, Kimberley',
		Code: '8301    ',
		Hub: 'KIM',
		Ring: 0,
		OpsHub: 'KIM',
		Dist: 20,
		AreaName: 'Kimberley Central',
		Surch: null,
	},
	{
		PlaceName: 'NEW REDRUTH, Alberton',
		Code: '1449    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'NEW REST, Queenstown',
		Code: '5320    ',
		Hub: 'ELS',
		Ring: 4,
		OpsHub: 'ELS',
		Dist: 210,
		AreaName: 'East London Regional',
		Surch: null,
	},
	{
		PlaceName: 'NEW STATE AREAS, Springs',
		Code: '1559    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'NEW TAFELSIG, Mitchells Plain',
		Code: '7785    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'NEW Tsp/Dgd, Kimberley',
		Code: '8301    ',
		Hub: 'KIM',
		Ring: 0,
		OpsHub: 'KIM',
		Dist: 20,
		AreaName: 'Kimberley Central',
		Surch: null,
	},
	{
		PlaceName: 'NEW WOODLANDS, Mitchells Plain',
		Code: '7785    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'NEWARK, Darnall',
		Code: '4480    ',
		Hub: 'DUR',
		Ring: 3,
		OpsHub: 'DUR',
		Dist: 140,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'NEWCASTLE Boxes',
		Code: '2940    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 320,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'NEWCASTLE, KwaZuluNatal',
		Code: '2940    ',
		Hub: 'DUR',
		Ring: 1,
		OpsHub: 'DUR',
		Dist: 320,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'NEWCLARE Boxes',
		Code: '2112    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'NEWCLARE, Johannesburg',
		Code: '2093    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'NEWFIELDS, Athlone',
		Code: '7764    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'NEWHOLME, Pietermaritzburg',
		Code: '3201    ',
		Hub: 'PMB',
		Ring: 1,
		OpsHub: 'PMB',
		Dist: 75,
		AreaName: 'Pietermaritzburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'NEWLANDS EAST, Durban',
		Code: '4037',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'NEWLANDS WEST, Durban',
		Code: '4037',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'NEWLANDS, Beaufort West',
		Code: '6970    ',
		Hub: 'CPT',
		Ring: 4,
		OpsHub: 'CPT',
		Dist: 470,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'NEWLANDS, Cape Town',
		Code: '7700',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'NEWLANDS, Cape Town (Streets)',
		Code: '7700    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'NEWLANDS, Durban North',
		Code: '4037',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'NEWLANDS, Johannesburg',
		Code: '2092    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'NEWLANDS, Pretoria',
		Code: '0081    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'NEWLANDS-OOS, Durban',
		Code: '4037',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'NEWLANDS-WES, Durban',
		Code: '4037',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'NEWMARKET, Alberton',
		Code: '1449    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'NEWTON KLOOF, Wellington',
		Code: '7655    ',
		Hub: 'CPT',
		Ring: 2,
		OpsHub: 'CPT',
		Dist: 90,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'NEWTON PARK',
		Code: '6045    ',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'NEWTON PARK Boxes',
		Code: '6055    ',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'NEWTON PARK, Port Elizabeth',
		Code: '6045    ',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'NEWTON, Kimberley',
		Code: '8301    ',
		Hub: 'KIM',
		Ring: 0,
		OpsHub: 'KIM',
		Dist: 20,
		AreaName: 'Kimberley Central',
		Surch: null,
	},
	{
		PlaceName: 'NEWTOWN Boxes',
		Code: '2113    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'NEWTOWN, Durbanville',
		Code: '7550    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'NEWTOWN, Fort Beaufort',
		Code: '5720    ',
		Hub: 'ELS',
		Ring: 4,
		OpsHub: 'ELS',
		Dist: 170,
		AreaName: 'East London Regional',
		Surch: null,
	},
	{
		PlaceName: 'NEWTOWN, Inanda (Ntl)',
		Code: '4310    ',
		Hub: 'DUR',
		Ring: 1,
		OpsHub: 'DUR',
		Dist: 50,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'NEWTOWN, Johannesburg',
		Code: '2001    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'NEWTOWN, Stanger',
		Code: '4449',
		Hub: 'DUR',
		Ring: 1,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'NEWTOWN, Tongaat',
		Code: '4399',
		Hub: 'DUR',
		Ring: 1,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'NEWTOWN, Wellington',
		Code: '7655    ',
		Hub: 'CPT',
		Ring: 2,
		OpsHub: 'CPT',
		Dist: 90,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'NEWVILLE',
		Code: '2114    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'NGODWANA',
		Code: '1209    ',
		Hub: 'NLP',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 310,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'NGODWANA',
		Code: '1209',
		Hub: 'JNB',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 310,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'NGOME',
		Code: '3111    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 270,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'NGULA',
		Code: '3378    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 270,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'NHLAZATSHE',
		Code: '3116    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 230,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'NICOMAR, Pretoria',
		Code: '0082    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'NIEKERKSHOOP',
		Code: '8930    ',
		Hub: 'BFN',
		Ring: 4,
		OpsHub: 'BFN',
		Dist: 540,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'NIETVERDIEND',
		Code: '2874    ',
		Hub: 'RBG',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 400,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'NIEU-BETHESDA',
		Code: '6286    ',
		Hub: 'PLZ',
		Ring: 4,
		OpsHub: 'PLZ',
		Dist: 330,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'NIEUVELD',
		Code: '6976    ',
		Hub: 'CPT',
		Ring: 4,
		OpsHub: 'CPT',
		Dist: 490,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'NIEUWOUDTVILLE',
		Code: '8180    ',
		Hub: 'CPT',
		Ring: 4,
		OpsHub: 'CPT',
		Dist: 360,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'NIGEL',
		Code: '1490',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 45,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'NIMRODPARK, Kempton Park',
		Code: '1619    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'NINAPARK, Pretoria',
		Code: '0156    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'NIRVANA Est/Lgd, Plumstead',
		Code: '7800    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'NIRVANA HILLS, Northdene',
		Code: '4093',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'NIRVANA, Pietersburg',
		Code: '0699',
		Hub: 'PTG',
		Ring: 0,
		OpsHub: 'PTG',
		Dist: 400,
		AreaName: 'Pietersburg Central',
		Surch: null,
	},
	{
		PlaceName: 'NKANDE',
		Code: '3024    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 220,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'NKANDLA',
		Code: '3855    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 240,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'NKOWANKOWA',
		Code: '0870',
		Hub: 'PTG',
		Ring: 2,
		OpsHub: 'PTG',
		Dist: 100,
		AreaName: 'Pietersburg Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'NKURI',
		Code: '0858    ',
		Hub: 'JNB',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 300,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'NKWALINI',
		Code: '3816    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 230,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'NKWE',
		Code: '0616    ',
		Hub: 'PTG',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 300,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'NOBEL',
		Code: '1655    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'NOBEL PARK, Bellville',
		Code: '7530    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'NOENIEPUT',
		Code: '8813    ',
		Hub: 'BFN',
		Ring: 4,
		OpsHub: 'BFN',
		Dist: 680,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'NOLDICK, Meyerton',
		Code: '1960    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 60,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'NOLL',
		Code: '6462    ',
		Hub: 'GRJ',
		Ring: 1,
		OpsHub: 'GRJ',
		Dist: 60,
		AreaName: 'George Regional',
		Surch: null,
	},
	{
		PlaceName: 'NONDWENI',
		Code: '3137    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 230,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'NONGOMA',
		Code: '3950    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 350,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'NONZWAKAZI Boxes',
		Code: '7002    ',
		Hub: 'BFN',
		Ring: 4,
		OpsHub: 'BFN',
		Dist: 500,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'NONZWAKAZI, De Aar',
		Code: '7000    ',
		Hub: 'BFN',
		Ring: 4,
		OpsHub: 'BFN',
		Dist: 500,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'NOOITGEDACHT',
		Code: '2125    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 30,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'NOOITGEDACHT, Lavistown',
		Code: '7490    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'NOORDBRUG Boxes',
		Code: '2522    ',
		Hub: 'JNB',
		Ring: 2,
		OpsHub: 'JNB',
		Dist: 100,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'NOORDBRUG, Potchefstroom',
		Code: '2520    ',
		Hub: 'POT',
		Ring: 3,
		OpsHub: 'JNB',
		Dist: 150,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'NOORDEINDE, Port Elizabeth, Boxes',
		Code: '6056    ',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'NOORDEINDE, Port Elizabeth, Street',
		Code: '6001    ',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'NOORDER-PAARL Boxes',
		Code: '7623    ',
		Hub: 'CPT',
		Ring: 1,
		OpsHub: 'CPT',
		Dist: 60,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'NOORDER-PAARL Streets',
		Code: '7646    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 60,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'NOORDGESIG, Orlando',
		Code: '1804    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 50,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'NOORDHANG, Jukskeipark',
		Code: '2153    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'NOORDHEUWEL & Ext/Uit 2,',
		Code: '1739    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'NOORDHEUWEL, Alexandria',
		Code: '6185    ',
		Hub: 'PLZ',
		Ring: 2,
		OpsHub: 'PLZ',
		Dist: 110,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'NOORDHOEK Cape Town,  Box',
		Code: '7985    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 60,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'NOORDHOEK, Bloemfontein',
		Code: '9301',
		Hub: 'BFN',
		Ring: 0,
		OpsHub: 'BFN',
		Dist: 30,
		AreaName: 'Bloemfontein Central',
		Surch: null,
	},
	{
		PlaceName: 'NOORDHOEK, Cape Town',
		Code: '7975    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 60,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'NOORDHOEK, Kroonstad',
		Code: '9499    ',
		Hub: 'BFN',
		Ring: 3,
		OpsHub: 'BFN',
		Dist: 170,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'NOORDHOEK, Velddrif',
		Code: '7370    ',
		Hub: 'CPT',
		Ring: 3,
		OpsHub: 'CPT',
		Dist: 160,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'NOORDKAAP',
		Code: '1304    ',
		Hub: 'NLP',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 310,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'NOORDSIG, Empangeni',
		Code: '3880',
		Hub: 'DUR',
		Ring: 1,
		OpsHub: 'DUR',
		Dist: 180,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'NOORDWYK Ext/Uit 1 & 16,',
		Code: '1687',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'NOORDWYK, Halfway House',
		Code: '1687',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'NORFOLK PARK, Tokai',
		Code: '7945    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'NORKEM PARK & Ext/Uit 1 -',
		Code: '1619    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'NORKEM PARK Boxes',
		Code: '1631    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'NORSCOT, Four Ways',
		Code: '2055    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'NORTH BANK, East London',
		Code: '5201    ',
		Hub: 'ELS',
		Ring: 0,
		OpsHub: 'ELS',
		Dist: 10,
		AreaName: 'East London Central',
		Surch: null,
	},
	{
		PlaceName: 'NORTH BEACH, Durban',
		Code: '4001    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'NORTH END, East London',
		Code: '5201    ',
		Hub: 'ELS',
		Ring: 0,
		OpsHub: 'ELS',
		Dist: 10,
		AreaName: 'East London Central',
		Surch: null,
	},
	{
		PlaceName: 'NORTH END, Port Elizabeth',
		Code: '6001    ',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'NORTH END, Port Elizabeth, Boxes',
		Code: '6056    ',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'NORTH GATE Est/Lgd, Parow',
		Code: '7500    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'NORTH PINE, Brackenfell',
		Code: '7560    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'NORTH RIDING',
		Code: '2169',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'NORTH RIDING Boxes',
		Code: '2162    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'NORTH RIDING, Randburg',
		Code: '2169',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'NORTH SHORE Est/Lgd, Hout Bay',
		Code: '7800    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 40,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'NORTHAM',
		Code: '0360    ',
		Hub: 'JNB',
		Ring: 2,
		OpsHub: 'JNB',
		Dist: 120,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'NORTHCLIFF Boxes',
		Code: '2115    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'NORTHCLIFF Ext 19, Florida',
		Code: '1709',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'NORTHCLIFF VIEW, Johannesburg',
		Code: '2195    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'NORTHCLIFF, Hermanus',
		Code: '7200    ',
		Hub: 'CPT',
		Ring: 2,
		OpsHub: 'CPT',
		Dist: 100,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'NORTHCLIFF, Johannesburg',
		Code: '2195',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'NORTHCREST, UMTATA',
		Code: '5100',
		Hub: 'ELS',
		Ring: 1,
		OpsHub: 'ELS',
		Dist: 180,
		AreaName: 'East London Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'NORTHCROFT, Durban',
		Code: '4068',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'NORTHDALE, Pietermaritzburg',
		Code: '3201    ',
		Hub: 'PMB',
		Ring: 0,
		OpsHub: 'PMB',
		Dist: 75,
		AreaName: 'Pietermaritzburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'NORTHDENE Boxes',
		Code: '4064    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'NORTHDENE PARK Est/Lgd,',
		Code: '4093',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'NORTHDENE PARK, Queensburgh',
		Code: '4093',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'NORTHDENE, Brakpan',
		Code: '1540    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'NORTHDENE, Queensburgh',
		Code: '4093',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'NORTHDENE, Vanderbijlpark',
		Code: '1911    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 60,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'NORTHERN ACRES, Johannesburg',
		Code: '2196    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'NORTHERN PARK, Pietermaritzburg',
		Code: '3201    ',
		Hub: 'PMB',
		Ring: 1,
		OpsHub: 'PMB',
		Dist: 75,
		AreaName: 'Pietermaritzburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'NORTHGATE ESTATE, Brooklyn',
		Code: '7441',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'NORTHGATE, Randburg',
		Code: '2194',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'NORTHHILL, Benoni',
		Code: '1501    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'NORTHLANDS (Tvl)',
		Code: '2116    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'NORTHLANDS PARK, Stanger',
		Code: '4449',
		Hub: 'DUR',
		Ring: 1,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'NORTHLANDS, Johannesburg',
		Code: '2196    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'NORTHMEAD, BENONI Boxes',
		Code: '1511    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'NORTHMEAD, Benoni',
		Code: '1501    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'NORTHVIEW ESTATE, Johannesburg',
		Code: '1687',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'NORTHVIEW, Johannesburg',
		Code: '2192    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'NORTHVIEW, Kimberley',
		Code: '8301    ',
		Hub: 'KIM',
		Ring: 0,
		OpsHub: 'KIM',
		Dist: 20,
		AreaName: 'Kimberley Central',
		Surch: null,
	},
	{
		PlaceName: 'NORTHWAY Boxes',
		Code: '4065    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'NORTHWAY, Durban',
		Code: '4051',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'NORTHWOLD, Johannesburg',
		Code: '2188',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: "NORTON'S HOME ESTATES, Benoni",
		Code: '1501',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'NORTON, Germiston',
		Code: '1401    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'NORVALSPONT',
		Code: '9797',
		Hub: 'BFN',
		Ring: 4,
		OpsHub: 'BFN',
		Dist: 230,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'NORWOOD Boxes',
		Code: '2117    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'NORWOOD Est/Lgd, Elsiesriver',
		Code: '7490    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'NORWOOD, Johannesburg',
		Code: '2192    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'NOTTINGHAM ROAD',
		Code: '3280    ',
		Hub: 'DUR',
		Ring: 3,
		OpsHub: 'DUR',
		Dist: 150,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'NOTTINGHAMWEG',
		Code: '3280    ',
		Hub: 'DUR',
		Ring: 3,
		OpsHub: 'DUR',
		Dist: 150,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'NOUPOORT',
		Code: '5950    ',
		Hub: 'BFN',
		Ring: 4,
		OpsHub: 'BFN',
		Dist: 340,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'NOYCEDALE, Nigel',
		Code: '1490    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 45,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'NQABENI',
		Code: '4682    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 180,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'NQUTU',
		Code: '3135    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 230,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'NSELENI',
		Code: '3882    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 250,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'NSINGWENI',
		Code: '3806    ',
		Hub: 'DUR',
		Ring: 3,
		OpsHub: 'DUR',
		Dist: 160,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'NTABEBOMVU',
		Code: '3008    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 320,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'NTLAFATSONG',
		Code: '9476    ',
		Hub: 'BFN',
		Ring: 3,
		OpsHub: 'BFN',
		Dist: 160,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'NTLANZAME, Tshiawelo',
		Code: '1818    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 30,
		AreaName: 'Johannesburg Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'NTOKOZWENI',
		Code: '4066    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'NTSIMA',
		Code: '0711    ',
		Hub: 'PTG',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 400,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'NTUMENI',
		Code: '3830    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 220,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'NTUNJAMBILI',
		Code: '3560    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 200,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'NTUZUMA, kwaMashu',
		Code: '4360    ',
		Hub: 'DUR',
		Ring: 1,
		OpsHub: 'DUR',
		Dist: 30,
		AreaName: 'Durban Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'NUFFIELD, Springs',
		Code: '1559    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'NUWE-SMITSDORP',
		Code: '0706    ',
		Hub: 'PTG',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 400,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'NUWEDORP, Darling',
		Code: '7345    ',
		Hub: 'CPT',
		Ring: 1,
		OpsHub: 'CPT',
		Dist: 80,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'NUWEDORP, Springs',
		Code: '1559    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'NUWEDORPSGEBIED, Kimberley',
		Code: '8301    ',
		Hub: 'KIM',
		Ring: 0,
		OpsHub: 'KIM',
		Dist: 20,
		AreaName: 'Kimberley Central',
		Surch: null,
	},
	{
		PlaceName: 'NUWELAND ,Cape Town, Boxes',
		Code: '7725    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'NUWELAND, Cape Town, streets',
		Code: '7700    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'NUWERUS',
		Code: '8201    ',
		Hub: 'CPT',
		Ring: 4,
		OpsHub: 'CPT',
		Dist: 400,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'NWAMANUNGU',
		Code: '0932    ',
		Hub: 'JNB',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 300,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'NWAMITWA',
		Code: '0871    ',
		Hub: 'JNB',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 300,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'NYANGA',
		Code: '7755    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'NYANGA EAST, Nyanga',
		Code: '7755    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'NYANGA-OOS, Nyanga',
		Code: '7755    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'NYANYADU',
		Code: '3004    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 320,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'NYLSTROOM',
		Code: '0510    ',
		Hub: 'PRY',
		Ring: 4,
		OpsHub: 'PRY',
		Dist: 180,
		AreaName: 'Pretoria Regional',
		Surch: null,
	},
	{
		PlaceName: 'NYONI',
		Code: '3802    ',
		Hub: 'DUR',
		Ring: 3,
		OpsHub: 'DUR',
		Dist: 160,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: "O'KENNEDYVILLE, Bellville",
		Code: '7530    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: "O'KENNEDYVILLE, Durbanville",
		Code: '7550',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: "O'KIEP",
		Code: '8270    ',
		Hub: 'CPT',
		Ring: 4,
		OpsHub: 'CPT',
		Dist: 660,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'OAK GLEN, Bellville',
		Code: '7530    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'OAK PARK, Pietermaritzburg',
		Code: '3201    ',
		Hub: 'PMB',
		Ring: 1,
		OpsHub: 'PMB',
		Dist: 75,
		AreaName: 'Pietermaritzburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'OAK VIEW, Pietermaritzburg',
		Code: '3201    ',
		Hub: 'PMB',
		Ring: 1,
		OpsHub: 'PMB',
		Dist: 75,
		AreaName: 'Pietermaritzburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'OAKDALE Boxes',
		Code: '7534    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'OAKDALE Est/Lgd, Bellville',
		Code: '7530    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'OAKDALE, Bellville',
		Code: '7530    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'OAKDENE, Johannesburg',
		Code: '2190    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'OAKDENE, Kuilsriver',
		Code: '7580    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'OAKLANDS, Johannesburg',
		Code: '2192    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'OAKLANDS, Krugersdorp',
		Code: '1739    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'OAKLANDS, Rondebosch',
		Code: '7700    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'OAKLANDS, Verulam',
		Code: '4340    ',
		Hub: 'DUR',
		Ring: 1,
		OpsHub: 'DUR',
		Dist: 50,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'OAKLY, Plumstead',
		Code: '7800    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'OAKRIDGE Ext/Uit, Plumstead',
		Code: '7800    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'OATLANDS SlHs/Khw, Krugersdorp',
		Code: '1739    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'OATLANDS, Grahamstown',
		Code: '6140    ',
		Hub: 'PLZ',
		Ring: 4,
		OpsHub: 'PLZ',
		Dist: 170,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'OBERHOLZER Boxes',
		Code: '2502    ',
		Hub: 'JNB',
		Ring: 2,
		OpsHub: 'JNB',
		Dist: 100,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'OBERHOLZER, Carletonville',
		Code: '2500    ',
		Hub: 'JNB',
		Ring: 2,
		OpsHub: 'JNB',
		Dist: 100,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'OBSERVATION HILL, Ladysmith',
		Code: '3370    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 250,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'OBSERVATORY, Cape Town',
		Code: '7925    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'OBSERVATORY, Cape Town',
		Code: '7935    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'OBSERVATORY, Johannesburg',
		Code: '2198    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'OCEAN VIEW, East London',
		Code: '5247    ',
		Hub: 'ELS',
		Ring: 0,
		OpsHub: 'ELS',
		Dist: 10,
		AreaName: 'East London Central',
		Surch: null,
	},
	{
		PlaceName: 'OCEAN VIEW, Fish Hoek',
		Code: '7975    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 60,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'ODENDAALSRUS',
		Code: '9480    ',
		Hub: 'WEL',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 170,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'ODIN PARK',
		Code: '1825    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 30,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'OERDERPARK, Randburg',
		Code: '2194    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'OFCOLACO',
		Code: '0854    ',
		Hub: 'JNB',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 300,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'OGIES',
		Code: '2230    ',
		Hub: 'MBG',
		Ring: 2,
		OpsHub: 'JNB',
		Dist: 120,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'OHRIGSTAD',
		Code: '1122    ',
		Hub: 'MPG',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 350,
		AreaName: 'Johannesburg Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'OLD OAK',
		Code: '7537    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'OLIEVENHOUTBOSCH,Centurion',
		Code: '0187',
		Hub: 'PRY',
		Ring: 1,
		OpsHub: 'PRY',
		Dist: 80,
		AreaName: 'Pretoria Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'OLIFANTPARK',
		Code: '6109    ',
		Hub: 'PLZ',
		Ring: 1,
		OpsHub: 'PLZ',
		Dist: 60,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'OLIFANTSFONTEIN',
		Code: '1665    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'OLIFANTSHOEK',
		Code: '8450    ',
		Hub: 'KUR',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 520,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'OLIVANNA, Krugersdorp',
		Code: '1739    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'OLIVEDALE, Randburg',
		Code: '2158    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'OLIVINE Est/Lgd, Lansdowne',
		Code: '7780    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'OLYMPUS AH, Pretoria',
		Code: '0081',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'ONDER-SMOORDRIF',
		Code: '5827    ',
		Hub: 'PLZ',
		Ring: 4,
		OpsHub: 'PLZ',
		Dist: 200,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'ONDERPAPEGAAIBERG, Stellenbosch',
		Code: '7600    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 60,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'ONRUS RIVER',
		Code: '7201    ',
		Hub: 'CPT',
		Ring: 2,
		OpsHub: 'CPT',
		Dist: 100,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'ONRUSRIVIER',
		Code: '7201    ',
		Hub: 'CPT',
		Ring: 2,
		OpsHub: 'CPT',
		Dist: 100,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'ONTDEKKERSPARK, Florida',
		Code: '1709    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'ONVERWACHT, Ellisras',
		Code: '0557    ',
		Hub: 'PRY',
		Ring: 4,
		OpsHub: 'PRY',
		Dist: 180,
		AreaName: 'Pretoria Regional',
		Surch: null,
	},
	{
		PlaceName: 'ONVERWACHT, Strand',
		Code: '7140',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 50,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'ONZE MOLEN, Durbanville',
		Code: '7550    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'OOS-DRIEFONTEIN, Carleton',
		Code: '2500    ',
		Hub: 'POT',
		Ring: 2,
		OpsHub: 'JNB',
		Dist: 100,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'OOS-EINDE, Bloemfontein',
		Code: '9301',
		Hub: 'BFN',
		Ring: 0,
		OpsHub: 'BFN',
		Dist: 30,
		AreaName: 'Bloemfontein Central',
		Surch: null,
	},
	{
		PlaceName: 'OOS-EINDE, Rustenburg',
		Code: '0300    ',
		Hub: 'JNB',
		Ring: 2,
		OpsHub: 'JNB',
		Dist: 120,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'OOS-LONDEN Busse',
		Code: '5200    ',
		Hub: 'ELS',
		Ring: 0,
		OpsHub: 'ELS',
		Dist: 10,
		AreaName: 'East London Central',
		Surch: null,
	},
	{
		PlaceName: 'OOS-LONDEN Strate',
		Code: '5201    ',
		Hub: 'ELS',
		Ring: 0,
		OpsHub: 'ELS',
		Dist: 10,
		AreaName: 'East London Central',
		Surch: null,
	},
	{
		PlaceName: 'OOS-RAND Busse',
		Code: '1462    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'OOS-RAND, Boksburg',
		Code: '1459    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'OOSEINDE, DURBAN',
		Code: '4018    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'OOSPOORT, Johannesburg',
		Code: '2094    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'OOSTERMOED',
		Code: '0321    ',
		Hub: 'PRY',
		Ring: 2,
		OpsHub: 'PRY',
		Dist: 120,
		AreaName: 'Pretoria Regional',
		Surch: null,
	},
	{
		PlaceName: 'OOSTERSEE Est/Lgd, Parow',
		Code: '7500    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'OPHIRTON, Johannesburg',
		Code: '2091    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'OPKOMS, Bloemfontein',
		Code: '9306    ',
		Hub: 'BFN',
		Ring: 0,
		OpsHub: 'BFN',
		Dist: 30,
		AreaName: 'Bloemfontein Central',
		Surch: null,
	},
	{
		PlaceName: 'OPPERMANS',
		Code: '9987    ',
		Hub: 'BFN',
		Ring: 4,
		OpsHub: 'BFN',
		Dist: 180,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'OPWAG',
		Code: '8851    ',
		Hub: 'BFN',
		Ring: 4,
		OpsHub: 'BFN',
		Dist: 570,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'ORANGE FARM,VAAL',
		Code: '1805',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 50,
		AreaName: 'Johannesburg Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'ORANGE GROVE Boxes',
		Code: '2119    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'ORANGE GROVE, East London',
		Code: '5201    ',
		Hub: 'ELS',
		Ring: 0,
		OpsHub: 'ELS',
		Dist: 10,
		AreaName: 'East London Central',
		Surch: null,
	},
	{
		PlaceName: 'ORANGE GROVE, Johannesburg',
		Code: '2192    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'ORANGIA, Welkom',
		Code: '9459    ',
		Hub: 'WEL',
		Ring: 3,
		OpsHub: 'JNB',
		Dist: 150,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'ORANIA',
		Code: '8752',
		Hub: 'KIM',
		Ring: 4,
		OpsHub: 'KIM',
		Dist: 230,
		AreaName: 'Kimberley Regional',
		Surch: null,
	},
	{
		PlaceName: 'ORANJEHOF, Springs',
		Code: '1559    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'ORANJEKRUIN Boxes',
		Code: '8807    ',
		Hub: 'KIM',
		Ring: 3,
		OpsHub: 'BFN',
		Dist: 20,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'ORANJESIG, Bloemfontein',
		Code: '9301',
		Hub: 'BFN',
		Ring: 0,
		OpsHub: 'BFN',
		Dist: 30,
		AreaName: 'Bloemfontein Central',
		Surch: null,
	},
	{
		PlaceName: 'ORANJEVILLE',
		Code: '1995    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 60,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'ORANJEZICHT, Cape Town',
		Code: '8001',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'ORCHARD VILLAGE, Retreat',
		Code: '7945    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'ORCHARD, Cape Town',
		Code: '6870    ',
		Hub: 'CPT',
		Ring: 3,
		OpsHub: 'CPT',
		Dist: 150,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'ORCHARDS Ext/Uit 2, Pretoria',
		Code: '0002    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'ORCHARDS, Johannesburg',
		Code: '2192    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'ORIBI Boxes',
		Code: '3205    ',
		Hub: 'DUR',
		Ring: 2,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'ORIBI, Pietermaritzburg',
		Code: '3201    ',
		Hub: 'PMB',
		Ring: 1,
		OpsHub: 'PMB',
		Dist: 75,
		AreaName: 'Pietermaritzburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'ORIEL, Bedfordview',
		Code: '2008    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'ORIENT HEIGHTS, Pietermaritzburg',
		Code: '3201    ',
		Hub: 'PMB',
		Ring: 1,
		OpsHub: 'PMB',
		Dist: 75,
		AreaName: 'Pietermaritzburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'ORIENT HILLS, Isipingo',
		Code: '4133',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'ORIENT PARK, Isipingo',
		Code: '4110    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'ORKNEY',
		Code: '2620    ',
		Hub: 'JNB',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 220,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'ORLANDO Boxes',
		Code: '1803    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 50,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'ORLANDO EAST, Orlando',
		Code: '1804    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 50,
		AreaName: 'Johannesburg Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'ORLANDO Streets',
		Code: '1804    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 50,
		AreaName: 'Johannesburg Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'ORLANDO WEST, Orlando',
		Code: '1804    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 50,
		AreaName: 'Johannesburg Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'ORLANDO-OOS, Orlando',
		Code: '1804    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 50,
		AreaName: 'Johannesburg Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'ORLANDO-WES, Orlando',
		Code: '1804    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 50,
		AreaName: 'Johannesburg Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'ORMONDE & Ext/Uit 1 & 3,',
		Code: '2091    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'ORNIHAVEN, Bryanston',
		Code: '2021    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'OSLO BEACH, Port Shepston',
		Code: '4240    ',
		Hub: 'DUR',
		Ring: 3,
		OpsHub: 'DUR',
		Dist: 130,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'OSUMMIT, Randburg',
		Code: '2194    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'OTTAWA, Verulam',
		Code: '4340    ',
		Hub: 'DUR',
		Ring: 1,
		OpsHub: 'DUR',
		Dist: 50,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'OTTERY Boxes',
		Code: '7808    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'OTTERY, Cape Town',
		Code: '7800    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'OTTOSDAL',
		Code: '2610    ',
		Hub: 'JNB',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 300,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'OTTOSHOOP',
		Code: '2866    ',
		Hub: 'NWP',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 320,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'OUBOSRAND',
		Code: '6303    ',
		Hub: 'PLZ',
		Ring: 4,
		OpsHub: 'PLZ',
		Dist: 170,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'OUDE MOLEN, Maitland',
		Code: '7405    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'OUDE WESTHOF, Bellville',
		Code: '7530',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'OUDEKRAAL, Cape Town',
		Code: '8005',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'OUDEWERFPARK, Parys',
		Code: '9585    ',
		Hub: 'JNB',
		Ring: 3,
		OpsHub: 'JNB',
		Dist: 130,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'OUDORP, Klerksdorp',
		Code: '2571    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 150,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'OUDORP, Springs',
		Code: '1559    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'OUDTSHOORN',
		Code: '6620    ',
		Hub: 'GRJ',
		Ring: 1,
		OpsHub: 'GRJ',
		Dist: 70,
		AreaName: 'George Regional',
		Surch: null,
	},
	{
		PlaceName: 'OUPLAAS, Knysna',
		Code: '6571',
		Hub: 'GRJ',
		Ring: 1,
		OpsHub: 'GRJ',
		Dist: 80,
		AreaName: 'George Regional',
		Surch: null,
	},
	{
		PlaceName: 'OUVOLK',
		Code: '2471    ',
		Hub: 'JNB',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 290,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'OVERBAKENS, Port Elizabeth',
		Code: '6070',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'OVERIJSSEL',
		Code: '0606    ',
		Hub: 'PTG',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 300,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'OVERKRUIN, Heidelberg',
		Code: '2400    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 70,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'OVERPORT Boxes',
		Code: '4067    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'OVERPORT, Durban',
		Code: '4091',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'OZWATINI',
		Code: '3476    ',
		Hub: 'DUR',
		Ring: 2,
		OpsHub: 'DUR',
		Dist: 110,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'PAARDEKRAAL Boxes',
		Code: '1752    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'PAARDEKRAAL, Krugersdorp',
		Code: '1739    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'PAARDEN EILAND, Maitland',
		Code: '7405    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'PAARDENEILAND Boxes',
		Code: '7420    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'PAARDENEILAND, Cape Town',
		Code: '7405    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'PAARDEVLEI, Somerset West',
		Code: '7130    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 30,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'PAARL',
		Code: '7646    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 60,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'PAARL Boxes',
		Code: '7620    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 60,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'PAARL EAST',
		Code: '7646    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 60,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'PAARL-OOS, Paarl',
		Code: '7646    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 60,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'PAARLSHOOP, Johannesburg',
		Code: '2092    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'PAARLVALLEI, Somerset West',
		Code: '7130    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 30,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'PAARLZICHT, Paarl',
		Code: '7646    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 60,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'PABALLONG',
		Code: '9470    ',
		Hub: 'BFN',
		Ring: 3,
		OpsHub: 'BFN',
		Dist: 160,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'PACALTSDORP',
		Code: '6529',
		Hub: 'GRJ',
		Ring: 0,
		OpsHub: 'GRJ',
		Dist: 10,
		AreaName: 'George Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'PADDOCK',
		Code: '4244    ',
		Hub: 'DUR',
		Ring: 3,
		OpsHub: 'DUR',
		Dist: 130,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'PADFIELD PARK, Pinetown',
		Code: '3610',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'PAGEVIEW, Johannesburg',
		Code: '2092    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'PALEISHEUWEL',
		Code: '8115    ',
		Hub: 'CPT',
		Ring: 4,
		OpsHub: 'CPT',
		Dist: 200,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'PALM PARK, Goodwood',
		Code: '7460    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'PALM RIDGE, Alberton',
		Code: '1458',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'PALM RIDGE, Port Elizabeth',
		Code: '6059    ',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'PALMIET, Durban',
		Code: '4091',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'PALMIETFONTEIN',
		Code: '9767',
		Hub: 'BFN',
		Ring: 3,
		OpsHub: 'BFN',
		Dist: null,
		AreaName: 'Bloemfontein Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'PALMLANDS, Bryanston',
		Code: '2021    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'PALMRIDGE, Eden Park',
		Code: '1455    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'PALMVIEW, Phoenix',
		Code: '4068',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'PAMPIERSTAD',
		Code: '8566    ',
		Hub: 'VRY',
		Ring: 3,
		OpsHub: 'JNB',
		Dist: 150,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'PANMURE, East London',
		Code: '5201    ',
		Hub: 'ELS',
		Ring: 0,
		OpsHub: 'ELS',
		Dist: 10,
		AreaName: 'East London Central',
		Surch: null,
	},
	{
		PlaceName: 'PANORAMA Boxes',
		Code: '7506    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'PANORAMA GARDENS, Pietermaritzburg',
		Code: '3201    ',
		Hub: 'PMB',
		Ring: 1,
		OpsHub: 'PMB',
		Dist: 75,
		AreaName: 'Pietermaritzburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'PANORAMA PARK, Amanzimtoti',
		Code: '4126',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'PANORAMA, Empangeni',
		Code: '3880',
		Hub: 'DUR',
		Ring: 1,
		OpsHub: 'DUR',
		Dist: 180,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'PANORAMA, Kroonstad',
		Code: '9499    ',
		Hub: 'BFN',
		Ring: 3,
		OpsHub: 'BFN',
		Dist: 170,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'PANORAMA, Parow',
		Code: '7500    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'PANORAMA, Worcester',
		Code: '6850    ',
		Hub: 'CPT',
		Ring: 2,
		OpsHub: 'CPT',
		Dist: 120,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'PANSIG Boxes',
		Code: '9465    ',
		Hub: 'BFN',
		Ring: 3,
		OpsHub: 'BFN',
		Dist: 150,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'PANSIG, Welkom',
		Code: '9459    ',
		Hub: 'WEL',
		Ring: 3,
		OpsHub: 'JNB',
		Dist: 150,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'PARADISE Est/Lgd,Newlands',
		Code: '7700    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'PARADISE PARK, Rusloo',
		Code: '1468    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'PARADISE VALLEY, Pinetown',
		Code: '3610',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'PARADISE, Knysna',
		Code: '6571',
		Hub: 'GRJ',
		Ring: 1,
		OpsHub: 'GRJ',
		Dist: 80,
		AreaName: 'George Regional',
		Surch: null,
	},
	{
		PlaceName: 'PARADYSKLOOF, Stellenbosch',
		Code: '7600    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 60,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'PARADYSSTRAND, Humansdorp',
		Code: '6300    ',
		Hub: 'PLZ',
		Ring: 2,
		OpsHub: 'PLZ',
		Dist: 100,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'PARBEL Est/Lgd, Parow',
		Code: '7500    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'PAREL VALLEI, Cape Town',
		Code: '7130',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 50,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'PARI PARK, Port Elizabeth',
		Code: '6070',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'PARK CENTRAL, Johannesburg',
		Code: '2001    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'PARK Est/Lgd, Rondebosch',
		Code: '7700    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'PARK ISLAND, Muizenberg',
		Code: '7945    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'PARK RYNIE',
		Code: '4182    ',
		Hub: 'DUR',
		Ring: 1,
		OpsHub: 'DUR',
		Dist: 40,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'PARK SOUTH Boxes',
		Code: '1910    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 60,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'PARK SOUTH, Vanderbijlpark',
		Code: '1911    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 60,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'PARK VIEW, Plumstead',
		Code: '7800    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'PARK WEST, Bloemfontein',
		Code: '9301',
		Hub: 'BFN',
		Ring: 0,
		OpsHub: 'BFN',
		Dist: 30,
		AreaName: 'Bloemfontein Central',
		Surch: null,
	},
	{
		PlaceName: 'PARKDENE, Boksburg',
		Code: '1459    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'PARKDENE, George',
		Code: '6529    ',
		Hub: 'GRJ',
		Ring: 0,
		OpsHub: 'GRJ',
		Dist: 10,
		AreaName: 'George Central',
		Surch: 'Township',
	},
	{
		PlaceName: "PARKER'S DAM",
		Code: '6861    ',
		Hub: 'CPT',
		Ring: 3,
		OpsHub: 'CPT',
		Dist: 150,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'PARKGATE, Table View',
		Code: '7441    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'PARKHAVEN, Boksburg',
		Code: '1459    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'PARKHILL GARDENS, Germiston',
		Code: '1401    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'PARKHILL, Durban',
		Code: '4051',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'PARKHURST Boxes',
		Code: '2120    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'PARKHURST, Johannesburg',
		Code: '2193    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'PARKHURST, Kraaifontein',
		Code: '7570    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'PARKLANDS, Cape Town',
		Code: '7441    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'PARKLANDS, Johannesburg',
		Code: '2121    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'PARKMORE, Johannesburg',
		Code: '2196    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'PARKRAND & Ext/Uit 1, Boksburg',
		Code: '1459    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'PARKRIDGE, East London',
		Code: '5209    ',
		Hub: 'ELS',
		Ring: 0,
		OpsHub: 'ELS',
		Dist: 10,
		AreaName: 'East London Central',
		Surch: null,
	},
	{
		PlaceName: 'PARKSIDE, East London',
		Code: '5209    ',
		Hub: 'ELS',
		Ring: 0,
		OpsHub: 'ELS',
		Dist: 10,
		AreaName: 'East London Central',
		Surch: null,
	},
	{
		PlaceName: 'PARKSIDE, Ladismith',
		Code: '6885    ',
		Hub: 'CPT',
		Ring: 4,
		OpsHub: 'CPT',
		Dist: 350,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'PARKSIDE, Port Elizabeth',
		Code: '6020    ',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'PARKSIDE, Queensburgh',
		Code: '4093',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'PARKTOWN',
		Code: '2193    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'PARKTOWN NORTH, Johannesburg',
		Code: '2193    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'PARKTOWN WEST, Johannesburg',
		Code: '2193    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'PARKTOWN, Athlone Cape Town',
		Code: '7764    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'PARKTOWN, Johannesburg',
		Code: '2193    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'PARKTOWN, Pretoria',
		Code: '0084    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'PARKTOWN-NOORD, Johannesburg',
		Code: '2193    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'PARKTOWN-WES, Johannesburg',
		Code: '2193    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'PARKVALE, Queenstown',
		Code: '5320    ',
		Hub: 'ELS',
		Ring: 4,
		OpsHub: 'ELS',
		Dist: 210,
		AreaName: 'East London Regional',
		Surch: null,
	},
	{
		PlaceName: 'PARKVIEW Boxes',
		Code: '2122    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'PARKVIEW VILLAGE, Durbanville',
		Code: '7550    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'PARKVIEW, Johannesburg',
		Code: '2193    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'PARKVIEW, Mitchells Plain',
		Code: '7785    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'PARKWES, Bloemfontein',
		Code: '9301',
		Hub: 'BFN',
		Ring: 0,
		OpsHub: 'BFN',
		Dist: 30,
		AreaName: 'Bloemfontein Central',
		Surch: null,
	},
	{
		PlaceName: 'PARKWOOD Est/Lgd, Retreat',
		Code: '7945    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'PARKWOOD, Johannesburg',
		Code: '2193    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'PARLEMENT',
		Code: '8007    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'PARLIAMENT',
		Code: '8007    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'PARLOCK, Durban',
		Code: '4037',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'PAROW EAST Boxes',
		Code: '7501    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'PAROW EAST, Parow',
		Code: '7500    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'PAROW INDUSTRIA, Cape Town',
		Code: '7490    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'PAROW NORTH, Parow',
		Code: '7500    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'PAROW, Cape Town',
		Code: '7500    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'PAROW-NOORD, Parow',
		Code: '7500    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'PAROW-OOS Busse',
		Code: '7501    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'PAROW-OOS, Parow',
		Code: '7500    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'PAROW-VALLEI Boxes',
		Code: '7503    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'PAROWVALLEI, Parow',
		Code: '7500    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: "PARSON'S VLEI, Port Elizabeth",
		Code: '6025',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'PARSONS HILL, Port Elizabeth',
		Code: '6001    ',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'PARSONSHEUWEL, Port Elizabeth',
		Code: '6001    ',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'PARYS, Free State',
		Code: '9585    ',
		Hub: 'JNB',
		Ring: 3,
		OpsHub: 'JNB',
		Dist: 130,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'PARYS, Western Cape',
		Code: '6836',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 170,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'PATENSIE',
		Code: '6335    ',
		Hub: 'PLZ',
		Ring: 2,
		OpsHub: 'PLZ',
		Dist: 90,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'PATERNOSTER',
		Code: '7381    ',
		Hub: 'CPT',
		Ring: 4,
		OpsHub: 'CPT',
		Dist: 170,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'PATERSON',
		Code: '6130    ',
		Hub: 'PLZ',
		Ring: 2,
		OpsHub: 'PLZ',
		Dist: 90,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'PATTERSON PARK, Johannesburg',
		Code: '2192    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'PAUL KRUGER RESORT, Springs',
		Code: '1559    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'PAUL KRUGEROORD, Springs',
		Code: '1559    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'PAULPIETERSBURG',
		Code: '3180    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 400,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'PAULSHOF Ext/Uit 8, Rivonia',
		Code: '2056    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'PAULSHOF, Rivonia',
		Code: '2056    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'PAULUSWEG',
		Code: '0814    ',
		Hub: 'JNB',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 300,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'PAVILION',
		Code: '3611    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'PAYNEVILLE',
		Code: '1565    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'PEACEHAVEN Boxes',
		Code: '1934    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 60,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'PEACEHAVEN, Pietermaritzburg',
		Code: '3201    ',
		Hub: 'PMB',
		Ring: 1,
		OpsHub: 'PMB',
		Dist: 75,
		AreaName: 'Pietermaritzburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'PEACEHAVEN, Vereeniging',
		Code: '1939    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 60,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'PEARL RISE, Somerset West',
		Code: '7130    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 30,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'PEARLY BEACH',
		Code: '7220',
		Hub: 'CPT',
		Ring: 1,
		OpsHub: 'CPT',
		Dist: 190,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'PEARSTON',
		Code: '5860    ',
		Hub: 'PLZ',
		Ring: 4,
		OpsHub: 'PLZ',
		Dist: 210,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'PEDDIE',
		Code: '5640',
		Hub: 'PLZ',
		Ring: 2,
		OpsHub: 'PLZ',
		Dist: null,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: "PEER'S HILL Est/Lgd, Fish Hoek",
		Code: '7975    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 60,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'PEERLESS PARK EAST, Kraaifontein',
		Code: '7570    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'PEERLESS PARK NORTH, Kraaifontein',
		Code: '7570    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'PEERLESS PARK, Kraaifontein',
		Code: '7570    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'PEERLESS PARK-00S, Kraaifontein',
		Code: '7570    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'PEERLESS PARK-NOORD, Kraaifontein',
		Code: '7570    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'PEFFERVILLE Boxes',
		Code: '5220    ',
		Hub: 'ELS',
		Ring: 0,
		OpsHub: 'ELS',
		Dist: 10,
		AreaName: 'East London Central',
		Surch: null,
	},
	{
		PlaceName: 'PEFFERVILLE, East London',
		Code: '5209    ',
		Hub: 'ELS',
		Ring: 0,
		OpsHub: 'ELS',
		Dist: 10,
		AreaName: 'East London Central',
		Surch: null,
	},
	{
		PlaceName: 'PEKALMY Est/Lgd, Bergvliet',
		Code: '7945    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'PELGRIMSRUS',
		Code: '1290    ',
		Hub: 'JNB',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 350,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'PELHAM, Pietermaritzburg',
		Code: '3201    ',
		Hub: 'PMB',
		Ring: 1,
		OpsHub: 'PMB',
		Dist: 75,
		AreaName: 'Pietermaritzburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'PELICAN PARK, Grassy Park',
		Code: '7945    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'PELINDABA',
		Code: '0216',
		Hub: 'PRY',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 80,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'PELLISSIER, Bloemfontein',
		Code: '9301',
		Hub: 'BFN',
		Ring: 0,
		OpsHub: 'BFN',
		Dist: 30,
		AreaName: 'Bloemfontein Central',
		Surch: null,
	},
	{
		PlaceName: 'PELZVALE, Randfontein',
		Code: '1760    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'PEMMO PARK, Pietersburg',
		Code: '0699    ',
		Hub: 'PTG',
		Ring: 0,
		OpsHub: 'PTG',
		Dist: 400,
		AreaName: 'Pietersburg Central',
		Surch: null,
	},
	{
		PlaceName: 'PENFORD, Uitenhage',
		Code: '6230    ',
		Hub: 'PLZ',
		Ring: 1,
		OpsHub: 'PLZ',
		Dist: 50,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'PENHILL PARK, Plumstead',
		Code: '7800    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'PENHILL, Eersteriver',
		Code: '7100    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 15,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'PENINAPARK, Pietersburg',
		Code: '0699',
		Hub: 'PTG',
		Ring: 0,
		OpsHub: 'PTG',
		Dist: 400,
		AreaName: 'Pietersburg Central',
		Surch: null,
	},
	{
		PlaceName: 'PENLYN Est/Lgd, Lansdowne',
		Code: '7780    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'PENNINGTON',
		Code: '4184    ',
		Hub: 'DUR',
		Ring: 1,
		OpsHub: 'DUR',
		Dist: 50,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'PENQUIN Est/Lgd, Retreat',
		Code: '7945    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'PENTAGONPARK, Bloemfontein',
		Code: '9301',
		Hub: 'BFN',
		Ring: 0,
		OpsHub: 'BFN',
		Dist: 30,
		AreaName: 'Bloemfontein Central',
		Surch: null,
	},
	{
		PlaceName: 'PENTRICH, Pietermaritzburg',
		Code: '3201    ',
		Hub: 'PMB',
		Ring: 1,
		OpsHub: 'PMB',
		Dist: 75,
		AreaName: 'Pietermaritzburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'PENVAAN',
		Code: '3106    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 250,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'PENZANCE Est/Lgd, Hout Bay',
		Code: '7800    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 40,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'PERCELIA Est/Lgd, Johannesburg',
		Code: '2192    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'PERDEBERG',
		Code: '9936    ',
		Hub: 'BFN',
		Ring: 4,
		OpsHub: 'BFN',
		Dist: 200,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'PERRIDGEVALE, Port Elizabeth',
		Code: '6001    ',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'PERSEQUOR, Pretoria',
		Code: '0020',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 50,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'PERSEVERANCE, Despatch',
		Code: '6220',
		Hub: 'PLZ',
		Ring: null,
		OpsHub: 'PLZ',
		Dist: 30,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'PERSIDA, Springs',
		Code: '1559    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'PESCODIA',
		Code: '8309    ',
		Hub: 'KIM',
		Ring: 0,
		OpsHub: 'KIM',
		Dist: 20,
		AreaName: 'Kimberley Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'PETERSFIELD & Ext/Uit,',
		Code: '1559    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'PETERSFIELD Boxes',
		Code: '1566    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'PETERVALE, Bryanston',
		Code: '2151    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'PETIT',
		Code: '1512    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'PETRUSBURG',
		Code: '9932    ',
		Hub: 'BFN',
		Ring: 1,
		OpsHub: 'BFN',
		Dist: 80,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'PETRUSVILLE',
		Code: '8770    ',
		Hub: 'KIM',
		Ring: 4,
		OpsHub: 'KIM',
		Dist: 250,
		AreaName: 'Kimberley Regional',
		Surch: null,
	},
	{
		PlaceName: 'PHAHAMENG, Bultfontein',
		Code: '9670    ',
		Hub: 'WEL',
		Ring: 2,
		OpsHub: 'JNB',
		Dist: 70,
		AreaName: 'Johannesburg Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'PHAHAMENG, Kagisanong',
		Code: '9323    ',
		Hub: 'BFN',
		Ring: 2,
		OpsHub: 'BFN',
		Dist: 30,
		AreaName: 'Bloemfontein Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'PHAHAMENG, Nylstroom',
		Code: '0510',
		Hub: 'PRY',
		Ring: 4,
		OpsHub: 'PRY',
		Dist: 180,
		AreaName: 'Pretoria Regional',
		Surch: null,
	},
	{
		PlaceName: 'PHAHAMENG, Virginia',
		Code: '9430',
		Hub: 'WEL',
		Ring: 2,
		OpsHub: 'JNB',
		Dist: 25,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'PHAHAMENG, Vredefort',
		Code: '9595',
		Hub: 'BFN',
		Ring: 2,
		OpsHub: 'BFN',
		Dist: 60,
		AreaName: 'Bloemfontein Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'PHAKAMISA, Bisho',
		Code: '5620',
		Hub: 'ELS',
		Ring: 1,
		OpsHub: 'ELS',
		Dist: 60,
		AreaName: 'East London Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'PHALABORWA',
		Code: '1389    ',
		Hub: 'PTG',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 310,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'PHANGWENI',
		Code: '0816    ',
		Hub: 'JNB',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 300,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'PHILADELPHIA',
		Code: '7304    ',
		Hub: 'CPT',
		Ring: 1,
		OpsHub: 'CPT',
		Dist: 50,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'PHILIPPI Boxes',
		Code: '7781    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'PHILIPPI, Cape Town',
		Code: '7785    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'PHILIPPOLIS',
		Code: '9970    ',
		Hub: 'BFN',
		Ring: 4,
		OpsHub: 'BFN',
		Dist: 210,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'PHILIPSTOWN',
		Code: '8795    ',
		Hub: 'BFN',
		Ring: 4,
		OpsHub: 'BFN',
		Dist: 300,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'PHILLIP FRAME PARK, Pinetown',
		Code: '3610',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'PHIRI, Tshiawelo',
		Code: '1818    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 30,
		AreaName: 'Johannesburg Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'PHIRIMA, Orlando',
		Code: '1804    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 50,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'PHIVA',
		Code: '1358',
		Hub: 'NLP',
		Ring: 4,
		OpsHub: 'NLP',
		Dist: 98,
		AreaName: 'Nelspruit Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'PHOENIX, Durban',
		Code: '4068    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'PHOFUNG',
		Code: '2511    ',
		Hub: 'JNB',
		Ring: 2,
		OpsHub: 'JNB',
		Dist: 100,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'PHOKENG',
		Code: '0300    ',
		Hub: 'JNB',
		Ring: 3,
		OpsHub: 'JNB',
		Dist: 150,
		AreaName: 'Johannesburg Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'PHOLA, Hazyview',
		Code: '1240',
		Hub: 'MBG',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: null,
		AreaName: 'Johannesburg Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'PHOLA, Ogies',
		Code: '2230    ',
		Hub: 'MBG',
		Ring: 2,
		OpsHub: 'JNB',
		Dist: 120,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'PHOMALONG, Lengau',
		Code: '9499    ',
		Hub: 'BFN',
		Ring: 3,
		OpsHub: 'BFN',
		Dist: 170,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'PHOMOLONG, Mankurwane',
		Code: '8345',
		Hub: 'WEL',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 420,
		AreaName: 'Johannesburg Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'PHUTHADITJHABA',
		Code: '9866    ',
		Hub: 'HSM',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 270,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: "PIENAAR'S RIVER",
		Code: '0420    ',
		Hub: 'PRY',
		Ring: 4,
		OpsHub: 'PRY',
		Dist: 180,
		AreaName: 'Pretoria Regional',
		Surch: null,
	},
	{
		PlaceName: 'PIENAARSDORP, Klerksdorp',
		Code: '2571    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 150,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'PIENAARSRIVIER',
		Code: '0420    ',
		Hub: 'PRY',
		Ring: 4,
		OpsHub: 'PRY',
		Dist: 180,
		AreaName: 'Pretoria Regional',
		Surch: null,
	},
	{
		PlaceName: 'PIERNEEFPARK, Johannesburg',
		Code: '2195    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'PIERNEEFRANT, Pretoria',
		Code: '0084    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'PIERRE VAN RYNEVELDPARK &',
		Code: '0157    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'PIET PLESSIS',
		Code: '8621    ',
		Hub: 'VRY',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 400,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'PIETERMARITZBURG',
		Code: '3200',
		Hub: 'PMB',
		Ring: 0,
		OpsHub: 'PMB',
		Dist: 80,
		AreaName: 'Pietermaritzburg Central',
		Surch: null,
	},
	{
		PlaceName: 'PIETERMARITZBURG',
		Code: '3200',
		Hub: 'PMB',
		Ring: 0,
		OpsHub: 'PMB',
		Dist: 80,
		AreaName: 'Pietermaritzburg Central',
		Surch: null,
	},
	{
		PlaceName: 'PIETERMARITZBURG (TT)',
		Code: '3201    ',
		Hub: 'DUR',
		Ring: 1,
		OpsHub: 'DUR',
		Dist: 80,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'PIETERSBURG',
		Code: '0699',
		Hub: 'PTG',
		Ring: 0,
		OpsHub: 'PTG',
		Dist: 20,
		AreaName: 'Pietersburg Central',
		Surch: null,
	},
	{
		PlaceName: 'PIETERSBURG (TT)',
		Code: '0699    ',
		Hub: 'PTG',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 330,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'PIETERSBURG Boxes',
		Code: '0700    ',
		Hub: 'PTG',
		Ring: 0,
		OpsHub: 'PTG',
		Dist: 400,
		AreaName: 'Pietersburg Central',
		Surch: null,
	},
	{
		PlaceName: 'PIETERSBURG NORTH Boxes',
		Code: '0750    ',
		Hub: 'PTG',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 300,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'PIETERSBURG NORTH, Pietersburg',
		Code: '0699',
		Hub: 'PTG',
		Ring: 0,
		OpsHub: 'PTG',
		Dist: 400,
		AreaName: 'Pietersburg Central',
		Surch: null,
	},
	{
		PlaceName: 'PIETERSBURG-NOORD Busse',
		Code: '0750    ',
		Hub: 'PTG',
		Ring: 4,
		OpsHub: 'PTG',
		Dist: 300,
		AreaName: 'Pietersburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'PIETERSBURG-NOORD, Pietersburg',
		Code: '0699',
		Hub: 'PTG',
		Ring: 0,
		OpsHub: 'PTG',
		Dist: 400,
		AreaName: 'Pietersburg Central',
		Surch: null,
	},
	{
		PlaceName: 'PIKETBERG',
		Code: '7320    ',
		Hub: 'CPT',
		Ring: 3,
		OpsHub: 'CPT',
		Dist: 130,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: "PILGRIM'S REST",
		Code: '1290    ',
		Hub: 'MPG',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 350,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'PIMVILLE',
		Code: '1808    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 50,
		AreaName: 'Johannesburg Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'PIMVILLE Ext/Uit 1 - 7, P',
		Code: '1808    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 50,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'PINATI Est/Lgd, Lansdowne',
		Code: '7780    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'PINE MEWS, Brackenfell',
		Code: '7560    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'PINE PARK, Randburg',
		Code: '2194    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'PINE SLOPES, Sandton',
		Code: '2194',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'PINEDGE, Maitland',
		Code: '7405    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'PINEGOWRIE',
		Code: '2123    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'PINEGROVE, Port Elizabeth',
		Code: '6070',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'PINEGROVE, Somerset West',
		Code: '7130    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 30,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'PINEHURST Est/Lgd, Constantia',
		Code: '7800    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'PINEHURST, Durbanville',
		Code: '7550',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'PINELANDS (Cape/Kaap) Str',
		Code: '7405    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'PINELANDS Cape Town,  Boxes',
		Code: '7430    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'PINELANDS, Cape Town',
		Code: '7405    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'PINELANDS, Pinetown',
		Code: '3610',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'PINELEA, Brackenfell',
		Code: '7560    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'PINETOWN Boxes',
		Code: '3600    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'PINETOWN, Durban',
		Code: '3610',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'PINEVIEW, Grabouw',
		Code: '7160    ',
		Hub: 'CPT',
		Ring: 1,
		OpsHub: 'CPT',
		Dist: 60,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'PINEWOOD Boxes',
		Code: '7161    ',
		Hub: 'CPT',
		Ring: 1,
		OpsHub: 'CPT',
		Dist: 60,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'PINEWOOD, Grabouw',
		Code: '7160    ',
		Hub: 'CPT',
		Ring: 1,
		OpsHub: 'CPT',
		Dist: 60,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'PIONEER PARK, Newcastle',
		Code: '2940    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 320,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'PIONEER, Johannesburg',
		Code: '2190    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'PIONIER PARK, Kuilsrivier',
		Code: '7580    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'PIRATE Est/Lgd, Athlone',
		Code: '7764    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'PIROWVILLE, Germiston',
		Code: '1401    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'PLAINS',
		Code: '4243    ',
		Hub: 'DUR',
		Ring: 3,
		OpsHub: 'DUR',
		Dist: 130,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'PLANKENBRUG, Stellenbosch',
		Code: '7600',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 50,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'PLANTASIE, Boksburg',
		Code: '1459    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'PLASTON',
		Code: '1244    ',
		Hub: 'NLP',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 310,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'PLATBAKKIES',
		Code: '8206    ',
		Hub: 'CPT',
		Ring: 4,
		OpsHub: 'CPT',
		Dist: 400,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'PLATINUM REEF',
		Code: '0602    ',
		Hub: 'PTG',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 300,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'PLATTEKLOOF GLEN, Goodwood',
		Code: '7460    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'PLATTEKLOOF, Cape Town',
		Code: '7500    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'PLATWAL',
		Code: '0304    ',
		Hub: 'RBG',
		Ring: 2,
		OpsHub: 'JNB',
		Dist: 120,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'PLESSISLAER',
		Code: '4500    ',
		Hub: 'DUR',
		Ring: 2,
		OpsHub: 'DUR',
		Dist: 100,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'PLETTENBERG BAY',
		Code: '6600    ',
		Hub: 'GRJ',
		Ring: 1,
		OpsHub: 'GRJ',
		Dist: 110,
		AreaName: 'George Regional',
		Surch: null,
	},
	{
		PlaceName: 'PLETTENBERGBAAI',
		Code: '6600    ',
		Hub: 'GRJ',
		Ring: 1,
		OpsHub: 'GRJ',
		Dist: 110,
		AreaName: 'George Regional',
		Surch: null,
	},
	{
		PlaceName: 'PLOOYSBURG',
		Code: '8350    ',
		Hub: 'BFN',
		Ring: 4,
		OpsHub: 'BFN',
		Dist: 270,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'PLOOYSVILLE AH, Midrand',
		Code: '1684',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'PLUMSTEAD',
		Code: '7800    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'PNIEL',
		Code: '7681    ',
		Hub: 'CPT',
		Ring: 2,
		OpsHub: 'CPT',
		Dist: 90,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'PODIPHATSHWA',
		Code: '0386    ',
		Hub: 'PRY',
		Ring: 2,
		OpsHub: 'PRY',
		Dist: 120,
		AreaName: 'Pretoria Regional',
		Surch: null,
	},
	{
		PlaceName: 'POELONG',
		Code: '9874    ',
		Hub: 'BFN',
		Ring: 4,
		OpsHub: 'BFN',
		Dist: 270,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'POINT WATERFRONT, Durban',
		Code: '4001',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'POINT, Durban',
		Code: '4001    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'POINT, NATAL Boxes',
		Code: '4069    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'POKWANI',
		Code: '8565    ',
		Hub: 'BFN',
		Ring: 4,
		OpsHub: 'BFN',
		Dist: 230,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'POLITSI',
		Code: '0851    ',
		Hub: 'PTG',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 300,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'POLLAK PARK, Springs',
		Code: '1559    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'POLOKWANE',
		Code: '0699',
		Hub: 'PTG',
		Ring: 0,
		OpsHub: 'PTG',
		Dist: 20,
		AreaName: 'Pietersburg Central',
		Surch: null,
	},
	{
		PlaceName: 'POLOKWANE (TT)',
		Code: '0699',
		Hub: 'PTG',
		Ring: 0,
		OpsHub: 'PTG',
		Dist: 20,
		AreaName: 'Pietersburg Central',
		Surch: null,
	},
	{
		PlaceName: 'POLOKWANE EXT 12',
		Code: '0700',
		Hub: 'PTG',
		Ring: 0,
		OpsHub: 'PTG',
		Dist: 20,
		AreaName: 'Pietersburg Central',
		Surch: null,
	},
	{
		PlaceName: 'POLSMOOR, Retreat',
		Code: '7945    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'POMEROY',
		Code: '3020    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 220,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'POMFRET',
		Code: '8619    ',
		Hub: 'VRY',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 450,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'POMONA, Kempton Park',
		Code: '1619    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'POMONA, Parys',
		Code: '9585    ',
		Hub: 'JNB',
		Ring: 3,
		OpsHub: 'JNB',
		Dist: 130,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'PONGOLA',
		Code: '3170    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 400,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'PONONG, Rusloo',
		Code: '1468    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'POORTVIEW, Honeydew',
		Code: '2040    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'PORT ALFRED',
		Code: '6170    ',
		Hub: 'PLZ',
		Ring: 4,
		OpsHub: 'PLZ',
		Dist: 190,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'PORT EDWARD',
		Code: '4295    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 180,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'PORT ELIZABETH',
		Code: '6001    ',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'PORT ELIZABETH Boxes',
		Code: '6000    ',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'PORT NOLLOTH',
		Code: '8280    ',
		Hub: 'CPT',
		Ring: 4,
		OpsHub: 'CPT',
		Dist: 760,
		AreaName: 'Cape Town Regional',
		Surch: 'Far Outlying',
	},
	{
		PlaceName: 'PORT OWEN',
		Code: '7365',
		Hub: 'CPT',
		Ring: 1,
		OpsHub: 'CPT',
		Dist: 160,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'PORT SHEPSTONE',
		Code: '4240    ',
		Hub: 'DUR',
		Ring: 1,
		OpsHub: 'DUR',
		Dist: 130,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'PORT ST. JOHNS',
		Code: '5120',
		Hub: 'ELS',
		Ring: 4,
		OpsHub: 'ELS',
		Dist: 250,
		AreaName: 'East London Regional',
		Surch: null,
	},
	{
		PlaceName: 'PORTAVIEW Est/Lgd, Athlone',
		Code: '7764    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'PORTERVILLE',
		Code: '6810    ',
		Hub: 'CPT',
		Ring: 3,
		OpsHub: 'CPT',
		Dist: 130,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'PORTIA Est/Lgd, Lansdowne',
		Code: '7780    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'PORTLANDS, Mitchells Plain',
		Code: '7785    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'POSINI',
		Code: '3804    ',
		Hub: 'DUR',
		Ring: 3,
		OpsHub: 'DUR',
		Dist: 160,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'POSTDENE, Postmasburg',
		Code: '8420    ',
		Hub: 'BFN',
		Ring: 4,
		OpsHub: 'BFN',
		Dist: 570,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'POSTMASBURG',
		Code: '8420    ',
		Hub: 'KUR',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 570,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'POTCHEFSTROOM',
		Code: '2531    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 150,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'POTCHEFSTROOM (TT) ',
		Code: '2531',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 150,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'POTCHEFSTROOM CAMP Boxes',
		Code: '2521    ',
		Hub: 'JNB',
		Ring: 3,
		OpsHub: 'JNB',
		Dist: 150,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'POTCHEFSTROOM CAMP,',
		Code: '2520    ',
		Hub: 'JNB',
		Ring: 3,
		OpsHub: 'JNB',
		Dist: 150,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'POTCHEFSTROOM Ext/Uit 16,',
		Code: '2520    ',
		Hub: 'JNB',
		Ring: 3,
		OpsHub: 'JNB',
		Dist: 150,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'POTCHEFSTROOMKAMP Busses',
		Code: '2521    ',
		Hub: 'JNB',
		Ring: 3,
		OpsHub: 'JNB',
		Dist: 150,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'POTCHEFSTROOMKAMP,',
		Code: '2520    ',
		Hub: 'JNB',
		Ring: 3,
		OpsHub: 'JNB',
		Dist: 150,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'POTFONTEIN',
		Code: '8785    ',
		Hub: 'BFN',
		Ring: 4,
		OpsHub: 'BFN',
		Dist: 320,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'POTGIETER STREET, PRETORIA',
		Code: '0113    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'POTGIETERSRUS',
		Code: '0600    ',
		Hub: 'PTG',
		Ring: 3,
		OpsHub: 'PTG',
		Dist: 300,
		AreaName: 'Pietersburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'POTGIETERSRUS (TT)',
		Code: '0600',
		Hub: 'JNB',
		Ring: 3,
		OpsHub: 'JNB',
		Dist: 300,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'POTSDAM, Milnerton',
		Code: '7441    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'POWER PARK, Johannesburg',
		Code: '2013    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'POWERVILLE, Vereeniging',
		Code: '1939    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 60,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'PRAEGVILLE, Randburg',
		Code: '2194    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'PREMIERPARK, Tzaneen',
		Code: '0850',
		Hub: 'PTG',
		Ring: 4,
		OpsHub: 'PTG',
		Dist: 350,
		AreaName: 'Pietersburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'PRESIDENSIA, Kroonstad',
		Code: '9499    ',
		Hub: 'BFN',
		Ring: 3,
		OpsHub: 'BFN',
		Dist: 170,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'PRESIDENT PARK, Halfway House',
		Code: '1685',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'PRESIDENT RIDGE & Ext 1,',
		Code: '2194    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'PRESIDENTRIF & Uit 1,',
		Code: '2194    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'PRESIDENTSDAM, Springs',
		Code: '1559    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'PRESTBURY, Pietermaritzburg',
		Code: '3201    ',
		Hub: 'PMB',
		Ring: 1,
		OpsHub: 'PMB',
		Dist: 75,
		AreaName: 'Pietermaritzburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'PRESTONDALE, Umhlanga',
		Code: '4319',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'PRETORIA',
		Code: '0002    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'PRETORIA CENTRAL, Pretoria',
		Code: '0002',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 0,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'PRETORIA GARDENS, Pretoria',
		Code: '0082    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'PRETORIA NORTH, Pretoria',
		Code: '0182    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'PRETORIA WEST, Pretoria',
		Code: '0183    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'PRETORIUSPARK, Garsfontein',
		Code: '0042    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'PRETORIUSRUS, Carletonville',
		Code: '2500    ',
		Hub: 'JNB',
		Ring: 2,
		OpsHub: 'JNB',
		Dist: 100,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'PRETORIUSSTAD, Nigel',
		Code: '1490    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 45,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'PRIESKA',
		Code: '8940    ',
		Hub: 'KIM',
		Ring: 1,
		OpsHub: 'KIM',
		Dist: 510,
		AreaName: 'Kimberley Regional',
		Surch: null,
	},
	{
		PlaceName: 'PRIMROSE & Ext/Uit 2,',
		Code: '1401    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'PRIMROSE Boxes',
		Code: '1416    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'PRIMROSE EAST, Germiston',
		Code: '1401    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'PRIMROSE HILL Boxes',
		Code: '1417    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'PRIMROSE HILL, Germiston',
		Code: '1401    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'PRIMROSE PARK, Manenberg',
		Code: '7764    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'PRIMROSE RIDGE, Primrose',
		Code: '1401    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'PRIMROSE-OOS, Germiston',
		Code: '1401    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'PRIMROSERIF, Primrose',
		Code: '1401    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'PRINCE ALBERT',
		Code: '6930    ',
		Hub: 'GRJ',
		Ring: 4,
		OpsHub: 'CPT',
		Dist: 120,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'PRINCE ALBERT ROAD',
		Code: '6931    ',
		Hub: 'GRJ',
		Ring: 4,
		OpsHub: 'CPT',
		Dist: 120,
		AreaName: 'Cape Town Regional',
		Surch: 'Far Outlying',
	},
	{
		PlaceName: 'PRINCE ALFRED HAMLET',
		Code: '6840    ',
		Hub: 'CPT',
		Ring: 3,
		OpsHub: 'CPT',
		Dist: 130,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: "PRINCE'S PARK, Pretoria",
		Code: '0002    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'PRINCESS AgHs/Lhw, Roodeport',
		Code: '1724    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'PRINCESS Tsp/Dgd, Roodepoort',
		Code: '1724    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'PRINCESSVLEI, Southfield',
		Code: '7800    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'PRINGLE BAY',
		Code: '7195    ',
		Hub: 'CPT',
		Ring: 1,
		OpsHub: 'CPT',
		Dist: 60,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'PRINS ALBERT',
		Code: '6930    ',
		Hub: 'GRJ',
		Ring: 4,
		OpsHub: 'CPT',
		Dist: 120,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'PRINS ALBERTWEG',
		Code: '6931    ',
		Hub: 'GRJ',
		Ring: 4,
		OpsHub: 'CPT',
		Dist: 120,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'PRISON AREA, Kroonstad',
		Code: '9499    ',
		Hub: 'BFN',
		Ring: 3,
		OpsHub: 'BFN',
		Dist: 170,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'PRISON AREA, Pretoria',
		Code: '0002    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'PROCLAMATION HILL, Pretoria',
		Code: '0183    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'PROKLAMASIEHEUWEL, Pretoria',
		Code: '0183    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'PROLECON, Johannesburg',
		Code: '2001    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'PROMOSA Boxes',
		Code: '2530    ',
		Hub: 'JNB',
		Ring: 3,
		OpsHub: 'JNB',
		Dist: 150,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'PROMOSA, Potchefstroom',
		Code: '2520    ',
		Hub: 'JNB',
		Ring: 3,
		OpsHub: 'JNB',
		Dist: 150,
		AreaName: 'Johannesburg Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'PROSPECTON, Isipingo',
		Code: '4133',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'PROTEA GLEN,Johannesburg',
		Code: '1818',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 50,
		AreaName: 'Johannesburg Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'PROTEA HEIGHTS, Brackenfell',
		Code: '7560    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'PROTEA NORTH, Tshiawelo',
		Code: '1818    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 30,
		AreaName: 'Johannesburg Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'PROTEA PARK, Atlantis',
		Code: '7349    ',
		Hub: 'CPT',
		Ring: 1,
		OpsHub: 'CPT',
		Dist: 50,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'PROTEA PARK, George',
		Code: '6529',
		Hub: 'GRJ',
		Ring: 0,
		OpsHub: 'GRJ',
		Dist: 10,
		AreaName: 'George Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'PROTEA PARK, Port Elizabeth',
		Code: '6070',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'PROTEA PARK, Port Shepston',
		Code: '4240    ',
		Hub: 'DUR',
		Ring: 3,
		OpsHub: 'DUR',
		Dist: 130,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'PROTEA PARK, Vryheid',
		Code: '3100    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 330,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'PROTEA RIDGE, Krugersdorp',
		Code: '1739    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'PROTEA SOUTH, Tshiawelo',
		Code: '1818    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 30,
		AreaName: 'Johannesburg Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'PROTEA VALLEY, Bellville',
		Code: '7530    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'PROTEA, Tshiawelo',
		Code: '1818    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 30,
		AreaName: 'Johannesburg Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'PROTEA-NOORD, Tshiawelo',
		Code: '1818    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 30,
		AreaName: 'Johannesburg Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'PROTEA-SUID, Tshiawelo',
		Code: '1818    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 30,
		AreaName: 'Johannesburg Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'PROTEAHOOGTE, Brackenfell',
		Code: '7560    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'PROTEAPARK Boxes',
		Code: '0305    ',
		Hub: 'JNB',
		Ring: 2,
		OpsHub: 'JNB',
		Dist: 120,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'PROTEAPARK, Rustenburg',
		Code: '0300    ',
		Hub: 'JNB',
		Ring: 2,
		OpsHub: 'JNB',
		Dist: 120,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'PROTEARIF, Krugersdorp',
		Code: '1739    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'PROTEAVALLEI, Bellville',
		Code: '7530    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'PROTEAVILLE Est/Lgd, Durban',
		Code: '7550    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'PROTEAVILLE, Bellville',
		Code: '7530    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'PROTEAVILLE, Britstown',
		Code: '8970    ',
		Hub: 'BFN',
		Ring: 4,
		OpsHub: 'BFN',
		Dist: 400,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'PROTEM',
		Code: '7281    ',
		Hub: 'CPT',
		Ring: 3,
		OpsHub: 'CPT',
		Dist: 160,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'PROVIDENTIA, Port Elizabeth',
		Code: '6070',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'PUDIMOE',
		Code: '8581',
		Hub: 'VRY',
		Ring: 3,
		OpsHub: 'JNB',
		Dist: 160,
		AreaName: 'Johannesburg Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'PUNTANS HILL, Durban',
		Code: '4091',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'PUNTS Est/Lgd, Dieprivier',
		Code: '7945    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'PUTCOTON, Johannesburg',
		Code: '2093    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'PUTFONTEIN',
		Code: '1513    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'Q-TOWN, Athlone Cape Town',
		Code: '7764    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'QEDUMONA',
		Code: '3136    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 230,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'QUALBERT',
		Code: '4078    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'QUDENI',
		Code: '3557    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 210,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'QUEENSBURGH Boxes',
		Code: '4070    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'QUEENSBURGH, Durban',
		Code: '4093',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'QUEENSHAVEN, Johannesburg',
		Code: '2197    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'QUEENSMEAD, Malvern',
		Code: '4093',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 50,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'QUEENSTOWN',
		Code: '5320    ',
		Hub: 'ELS',
		Ring: 1,
		OpsHub: 'ELS',
		Dist: 210,
		AreaName: 'East London Regional',
		Surch: null,
	},
	{
		PlaceName: 'QUEENSWOOD ',
		Code: '0186    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'QUELLERIEPARK, Krugersdorp',
		Code: '1739    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'QUELLERINA, Florida',
		Code: '1709    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'QUIGNEY Boxes',
		Code: '5211    ',
		Hub: 'ELS',
		Ring: 0,
		OpsHub: 'ELS',
		Dist: 10,
		AreaName: 'East London Central',
		Surch: null,
	},
	{
		PlaceName: 'QUIGNEY, East London',
		Code: '5201    ',
		Hub: 'ELS',
		Ring: 0,
		OpsHub: 'ELS',
		Dist: 10,
		AreaName: 'East London Central',
		Surch: null,
	},
	{
		PlaceName: 'QUMBU',
		Code: '5180',
		Hub: 'ELS',
		Ring: 1,
		OpsHub: 'ELS',
		Dist: 200,
		AreaName: 'East London Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'QWA QWA',
		Code: '9866    ',
		Hub: 'HSM',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 270,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'RAAPENBERG/STA, Pinelands',
		Code: '7405    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'RAAPKRAAL, Retreat',
		Code: '7945    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'RABIERIDGE, Halfway House',
		Code: '1685',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'RACEVIEW, Alberton',
		Code: '1449    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'RADIO UITKYK, Pretoria',
		Code: '0187    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'RADIOKOP',
		Code: '2040',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'RADITSHABA',
		Code: '0718    ',
		Hub: 'PTG',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 300,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'RADIUM',
		Code: '0483    ',
		Hub: 'PRY',
		Ring: 4,
		OpsHub: 'PRY',
		Dist: 200,
		AreaName: 'Pretoria Regional',
		Surch: null,
	},
	{
		PlaceName: 'RAEDENE Boxes',
		Code: '2124    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'RAEDENE, Johannesburg',
		Code: '2192    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'RAFENGOTSO, Deneysville',
		Code: '1932    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 60,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'RAILWAY RESERVE, Pretoria',
		Code: '0002    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'RAINBOW VALLEY, Kimberley',
		Code: '8301    ',
		Hub: 'KIM',
		Ring: 0,
		OpsHub: 'KIM',
		Dist: 20,
		AreaName: 'Kimberley Central',
		Surch: null,
	},
	{
		PlaceName: 'RAINBOW VALLEY, Lenasia',
		Code: '1820    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 30,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'RAINHAM, Phoenix',
		Code: '4068',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'RAISETHORPE, Pietermaritzburg',
		Code: '3201    ',
		Hub: 'PMB',
		Ring: 1,
		OpsHub: 'PMB',
		Dist: 75,
		AreaName: 'Pietermaritzburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'RAITHBY, Firgrove',
		Code: '7130    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 30,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'RAMAHUTSI',
		Code: '9789    ',
		Hub: 'BFN',
		Ring: 1,
		OpsHub: 'BFN',
		Dist: 80,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'RAMOKGOPA',
		Code: '0811    ',
		Hub: 'JNB',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 300,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'RAMSGATE',
		Code: '4285    ',
		Hub: 'DUR',
		Ring: 1,
		OpsHub: 'DUR',
		Dist: 160,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'RAND AIRPORT',
		Code: '1419    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'RAND COLLIERIES SH, Brakpan',
		Code: '1549',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'RAND LEASES MINE, Florida',
		Code: '1709    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'RAND SHOW',
		Code: '2147    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'RAND VIEW, Johannesburg',
		Code: '2198    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'RANDBURG Boxes',
		Code: '2125    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'RANDBURG, Johannesburg',
		Code: '2194    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'RANDFONTEIN',
		Code: '1759',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'RANDFONTEIN SOUTH, Randfontein',
		Code: '1760    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'RANDFONTEIN, Gauteng',
		Code: '1760    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'RANDGATE Boxes',
		Code: '1763    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'RANDGATE, Randfontein',
		Code: '1760    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'RANDHART & Ext/Uit,',
		Code: '1449    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'RANDHART Boxes',
		Code: '1457    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'RANDHART Ext/Uit 1 & 2, A',
		Code: '1449    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'RANDJESFONTEIN',
		Code: '1683',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'RANDJIES PARK, Halfway House',
		Code: '1685',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'RANDJIESLAAGTE, Johannesburg',
		Code: '2192    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'RANDLESPARK, Klerksdorp',
		Code: '2571    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 150,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'RANDLUGHAWE',
		Code: '1419    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'RANDPARK & Ext/Uit 2 - 5,',
		Code: '2194    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'RANDPARK Ext/Uit, Randburg',
		Code: '2194    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'RANDPARK RIDGE Ext 1 - 4,',
		Code: '2194    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'RANDPARK RIDGE Ext 6,',
		Code: '2154    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'RANDPARK RIDGE Ext5,7-15,1',
		Code: '2156    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'RANDPARK RIDGE, Randburg',
		Code: '2156    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'RANDPARKRIF Uit 1 - 4, 16',
		Code: '2194    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'RANDPARKRIF Uit 5, 7 - 15',
		Code: '2156    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'RANDPARKRIF Uit 6,',
		Code: '2154    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'RANDPARKRIF, Randburg',
		Code: '2156    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'RANDRUS, Durban',
		Code: '4091',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'RANDSKOU',
		Code: '2147    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'RANDVAAL',
		Code: '1962',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 60,
		AreaName: 'Johannesburg Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'RANELAGH, Johannesburg',
		Code: '2196    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'RANGER PARK, Fish Hoek',
		Code: '7975    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 60,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'RANGEVIEW, Krugersdorp',
		Code: '1739    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: "RANKIN'S PASS",
		Code: '0513    ',
		Hub: 'PRY',
		Ring: 4,
		OpsHub: 'PRY',
		Dist: 180,
		AreaName: 'Pretoria Regional',
		Surch: null,
	},
	{
		PlaceName: 'RANLEIGH, Johannesburg',
		Code: '2192    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'RANT-EN-DAL & Ext/Uit 1 ',
		Code: '1739    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'RANT-EN-DAL Boxes',
		Code: '1751    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'RASHOOP',
		Code: '0253    ',
		Hub: 'RBG',
		Ring: 2,
		OpsHub: 'JNB',
		Dist: 100,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'RASLOUW, Wierdapark',
		Code: '0149    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'RATANDA, Heidelberg (Tvl)',
		Code: '2400    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 70,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'RAUMARAISPARK, Johannesburg',
		Code: '2090    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'RAVENMOOR Boxes',
		Code: '1469    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'RAVENMOOR, Boksburg',
		Code: '1459    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'RAVENSKLIP, Boksburg',
		Code: '1459    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'RAVENSMEAD Boxes',
		Code: '7504    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'RAVENSMEAD, Matroosfontein',
		Code: '7490    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'RAVENSMEAD,Cape Town',
		Code: '7493',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'RAVENSWOOD & Ext/Uit 10,',
		Code: '1459    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'RAWSONVILLE',
		Code: '6845    ',
		Hub: 'CPT',
		Ring: 2,
		OpsHub: 'CPT',
		Dist: 100,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'RAYMOND Est/Lgd, Vrijzee',
		Code: '7460    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'RAYTON',
		Code: '1001    ',
		Hub: 'JNB',
		Ring: 2,
		OpsHub: 'JNB',
		Dist: 90,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'REAGILE, Koster',
		Code: '2825    ',
		Hub: 'RBG',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 180,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'REBONE',
		Code: '0617    ',
		Hub: 'PTG',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 300,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'RED HILL, Durban',
		Code: '4051',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'RED HILL, N Boxes',
		Code: '4071    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'RED HILL, SimonTown',
		Code: '7995    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 70,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'REDAN',
		Code: '1931    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 60,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'REDDERSBURG',
		Code: '9904    ',
		Hub: 'BFN',
		Ring: 1,
		OpsHub: 'BFN',
		Dist: 80,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'REDDINGSHOOP',
		Code: '0252    ',
		Hub: 'RBG',
		Ring: 2,
		OpsHub: 'JNB',
		Dist: 100,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'REDELINGHUYS',
		Code: '8105    ',
		Hub: 'CPT',
		Ring: 4,
		OpsHub: 'CPT',
		Dist: 160,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'REDFERN, Phoenix',
		Code: '4068',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'REDHOUSE VILLAGE, Port Elizabeth',
		Code: '6215    ',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 20,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'REDHOUSE, Port Elizabeth',
		Code: '6215',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 20,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'REEFHAVEN, Roodepoort',
		Code: '1724    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'REESTON, East London',
		Code: '5247    ',
		Hub: 'ELS',
		Ring: 0,
		OpsHub: 'ELS',
		Dist: 10,
		AreaName: 'East London Central',
		Surch: null,
	},
	{
		PlaceName: 'REGENCY, Johannesburg',
		Code: '2197    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'REGENT PARK, East London',
		Code: '5247    ',
		Hub: 'ELS',
		Ring: 0,
		OpsHub: 'ELS',
		Dist: 10,
		AreaName: 'East London Central',
		Surch: null,
	},
	{
		PlaceName: 'REGENT ROAD, SEA POINT',
		Code: '8061    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: "REGENT'S PARK Johannesburg",
		Code: '2197    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: "REGENT'S PARK, JOHANNESBURG",
		Code: '2126    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'REGENTWEG, SEEPUNT',
		Code: '8061    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'REIGERPARK & Ext/Uit 1 &',
		Code: '1459    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'REIGERPARK Boxes',
		Code: '1466    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'REITZPARK & Ext/Uit 18,',
		Code: '9459    ',
		Hub: 'WEL',
		Ring: 3,
		OpsHub: 'JNB',
		Dist: 150,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'REIVILO',
		Code: '8595    ',
		Hub: 'BFN',
		Ring: 4,
		OpsHub: 'BFN',
		Dist: 280,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'REMBRANDT RIDGE, Johannesburg',
		Code: '2090    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'REMBRANDTPARK, Johannesburg',
		Code: '2090    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'REMBRANDTRIF, Johannesburg',
		Code: '2090    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'RENISHAW',
		Code: '4181    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 30,
		AreaName: 'Durban Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'RENOSTERSPRUIT',
		Code: '9326',
		Hub: 'BFN',
		Ring: 2,
		OpsHub: 'BFN',
		Dist: 100,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'RENSBURG Boxes',
		Code: '2401    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 70,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'RENSBURG, Heidelberg',
		Code: '2400    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 70,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'RESERVOIR HILLS Boxes',
		Code: '4090    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'RESERVOIR HILLS, Durban',
		Code: '4091',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'RESERVOIR PARK, Rusloo',
		Code: '1468    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'RESERVOIR RIDGE, Kagiso',
		Code: '1744    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'RESIDENSIA',
		Code: '1980    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 60,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'RESIDENSIA, De Aar',
		Code: '7000    ',
		Hub: 'BFN',
		Ring: 4,
		OpsHub: 'BFN',
		Dist: 500,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'RESTHAVEN, Tokai',
		Code: '7945    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'RETIEFSPARK, Lichtenburg',
		Code: '2740    ',
		Hub: 'NWP',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 270,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'RETIEFVILLE, Port Elizabeth',
		Code: '6001    ',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'RETREAT Boxes',
		Code: '7965    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'RETREAT Streets',
		Code: '7945    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'RETREAT, Cape Town',
		Code: '7945    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'RETSWELELE, Kimberley',
		Code: '8345',
		Hub: 'KIM',
		Ring: 1,
		OpsHub: 'KIM',
		Dist: null,
		AreaName: 'Kimberley Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'REUNION, Isipingo',
		Code: '4133',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'REUVEN, Johannesburg',
		Code: '2091    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'REWLATCH, Johannesburg',
		Code: '2197    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'REYGERSDAL',
		Code: '7352    ',
		Hub: 'CPT',
		Ring: 2,
		OpsHub: 'CPT',
		Dist: 120,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'REYNOLDS VIEW, Johannesburg',
		Code: '2094    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'RHEEBOKSTRAND,',
		Code: '6503    ',
		Hub: 'GRJ',
		Ring: 1,
		OpsHub: 'GRJ',
		Dist: 50,
		AreaName: 'George Regional',
		Surch: null,
	},
	{
		PlaceName: 'RHEEDERPARK, Welkom',
		Code: '9459    ',
		Hub: 'WEL',
		Ring: 3,
		OpsHub: 'JNB',
		Dist: 150,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'RHEENENDAL',
		Code: '6576    ',
		Hub: 'GRJ',
		Ring: 1,
		OpsHub: 'GRJ',
		Dist: 50,
		AreaName: 'George Regional',
		Surch: null,
	},
	{
		PlaceName: 'RHINE ROAD, SEA POINT',
		Code: '8050    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'RHINEWEG, SEEPUNT',
		Code: '8050    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'RHODES',
		Code: '5582    ',
		Hub: 'ELS',
		Ring: 4,
		OpsHub: 'ELS',
		Dist: 350,
		AreaName: 'East London Regional',
		Surch: null,
	},
	{
		PlaceName: 'RHODES GIFT',
		Code: '7707    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'RHODES PARK, East London',
		Code: '5247    ',
		Hub: 'ELS',
		Ring: 0,
		OpsHub: 'ELS',
		Dist: 10,
		AreaName: 'East London Central',
		Surch: null,
	},
	{
		PlaceName: 'RHODESDENE, Kimberley',
		Code: '8301    ',
		Hub: 'KIM',
		Ring: 0,
		OpsHub: 'KIM',
		Dist: 20,
		AreaName: 'Kimberley Central',
		Surch: null,
	},
	{
		PlaceName: 'RHODESFIELD, Kempton Park',
		Code: '1619    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'RIAMARPARK,',
		Code: '1020    ',
		Hub: 'JNB',
		Ring: 2,
		OpsHub: 'JNB',
		Dist: 90,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'RICHARDS BAY, KwaZuluNatal',
		Code: '3900',
		Hub: 'RCB',
		Ring: 1,
		OpsHub: 'RCB',
		Dist: 200,
		AreaName: 'Richards Bay',
		Surch: null,
	},
	{
		PlaceName: 'RICHARDSBAAI',
		Code: '3900',
		Hub: 'RCB',
		Ring: 1,
		OpsHub: 'RCB',
		Dist: 200,
		AreaName: 'Richards Bay',
		Surch: null,
	},
	{
		PlaceName: 'RICHEM, Empangeni',
		Code: '3880',
		Hub: 'DUR',
		Ring: 1,
		OpsHub: 'DUR',
		Dist: 180,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'RICHMOND (NATAL)',
		Code: '3780    ',
		Hub: 'DUR',
		Ring: 1,
		OpsHub: 'DUR',
		Dist: 40,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'RICHMOND Est/Lgd, Goodwood',
		Code: '7460    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'RICHMOND PARK, Port Elizabeth',
		Code: '6001    ',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'RICHMOND PARK, Table View',
		Code: '7441    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'RICHMOND, Johannesburg',
		Code: '2092    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'RICHMOND, Northern Cape',
		Code: '7090',
		Hub: 'BFN',
		Ring: 4,
		OpsHub: 'BFN',
		Dist: 375,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'RICHWOOD, Milnerton',
		Code: '7441    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'RIDDERPARK Boxes',
		Code: '0303    ',
		Hub: 'JNB',
		Ring: 2,
		OpsHub: 'JNB',
		Dist: 120,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'RIDDERPARK, Rustenburg',
		Code: '0300    ',
		Hub: 'JNB',
		Ring: 2,
		OpsHub: 'JNB',
		Dist: 120,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'RIDGE Ext/Uit 4 & 7, Font',
		Code: '2032    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'RIDGE PARK, Pietermaritzburg',
		Code: '3201    ',
		Hub: 'PMB',
		Ring: 1,
		OpsHub: 'PMB',
		Dist: 75,
		AreaName: 'Pietermaritzburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'RIDGE ROAD, Amanzimtoti',
		Code: '4126',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'RIDGEVIEW Boxes',
		Code: '1701    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'RIDGEVIEW, Florida',
		Code: '1709    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'RIDGEWAY & Ext/Uit 3 - 5,',
		Code: '2091    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'RIDGEWORTH Est/Lgd, Bellville',
		Code: '7530    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'RIDLEY PARK, Queensburgh',
		Code: '4055',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'RIEBEECK EAST',
		Code: '5805    ',
		Hub: 'PLZ',
		Ring: 2,
		OpsHub: 'PLZ',
		Dist: 100,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'RIEBEECK WEST,Malmesbury',
		Code: '7306',
		Hub: 'CPT',
		Ring: 2,
		OpsHub: 'CPT',
		Dist: 90,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'RIEBEECK-KASTEEL',
		Code: '6801    ',
		Hub: 'CPT',
		Ring: 2,
		OpsHub: 'CPT',
		Dist: 90,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'RIEBEECK-OOS',
		Code: '5805    ',
		Hub: 'PLZ',
		Ring: 2,
		OpsHub: 'PLZ',
		Dist: 100,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'RIEBEECK-WES',
		Code: '6800    ',
		Hub: 'CPT',
		Ring: 2,
		OpsHub: 'CPT',
		Dist: 90,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'RIEBEECKHOOGTE Boxes',
		Code: '6231    ',
		Hub: 'PLZ',
		Ring: 1,
		OpsHub: 'PLZ',
		Dist: 50,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'RIEBEECKHOOGTE, Uitenhage',
		Code: '6230    ',
		Hub: 'PLZ',
		Ring: 1,
		OpsHub: 'PLZ',
		Dist: 50,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'RIEBEECKSTAD Boxes',
		Code: '9469    ',
		Hub: 'BFN',
		Ring: 3,
		OpsHub: 'BFN',
		Dist: 150,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'RIEBEECKSTAD, Welkom',
		Code: '9459    ',
		Hub: 'WEL',
		Ring: 3,
		OpsHub: 'JNB',
		Dist: 150,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'RIEPENPARK, Johannesburg',
		Code: '2196    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'RIETBRON',
		Code: '6685    ',
		Hub: 'GRJ',
		Ring: 4,
		OpsHub: 'GRJ',
		Dist: 180,
		AreaName: 'George Regional',
		Surch: null,
	},
	{
		PlaceName: 'RIETFONTEIN PARK, Springs',
		Code: '1559    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'RIETFONTEIN, Muldersdrift',
		Code: '1747    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'RIETFONTEIN, Pretoria',
		Code: '0084    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'RIETONDALE, Pretoria',
		Code: '0084    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'RIETVLEI, Rustenburg',
		Code: '0300    ',
		Hub: 'JNB',
		Ring: 2,
		OpsHub: 'JNB',
		Dist: 120,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'RISANA, Johannesburg',
		Code: '2197    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'RISECLIFF, Chatsworth',
		Code: '4092',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'RISIDALE, Johannesburg',
		Code: '2195    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'RISIVIEW, Fish Hoek',
		Code: '7975    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 60,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'RISIVILLE & Ext/Uit,',
		Code: '1939    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 60,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'RISPARK, Johannesburg',
		Code: '2053    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'RISSIK STREET, JOHANNESBURG',
		Code: '2127    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'RISSIKSTRAAT, JOHANNESBURG',
		Code: '2127    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'RITCHIE',
		Code: '8701    ',
		Hub: 'KIM',
		Ring: 4,
		OpsHub: 'KIM',
		Dist: 200,
		AreaName: 'Kimberley Regional',
		Surch: null,
	},
	{
		PlaceName: 'RIVER CLUB Ext/Uit 10,',
		Code: '2149    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'RIVER CLUB, Bryanston',
		Code: '2149    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'RIVER VIEW, Kokstad',
		Code: '4700    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 220,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'RIVER VIEW, Mtubatuba',
		Code: '3930    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 300,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'RIVER VIEW, Worcester',
		Code: '6850    ',
		Hub: 'CPT',
		Ring: 2,
		OpsHub: 'CPT',
		Dist: 120,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'RIVERCLUB, Fish Hoek',
		Code: '7975    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 60,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'RIVERDALE, Pretoria',
		Code: '0002    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'RIVERFIELD Boxes',
		Code: '1564    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'RIVERHORSE VALLEY EAST',
		Code: '4017',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'RIVERHORSE VALLEY IND PARK',
		Code: '4017',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'RIVERHORSE VALLEY, Durban',
		Code: '4017',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'RIVERLEA & Ext/Uit, Johannesburg',
		Code: '2093    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'RIVERLEA Ext/Uit 2 & 11,',
		Code: '2093    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'RIVERSDAL, Weskaap',
		Code: '6670',
		Hub: 'GRJ',
		Ring: 1,
		OpsHub: 'GRJ',
		Dist: 125,
		AreaName: 'George Regional',
		Surch: null,
	},
	{
		PlaceName: 'RIVERSDALE, Meyerton',
		Code: '1961',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 60,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'RIVERSDALE, Western Cape',
		Code: '6670',
		Hub: 'GRJ',
		Ring: 1,
		OpsHub: 'GRJ',
		Dist: 125,
		AreaName: 'George Regional',
		Surch: null,
	},
	{
		PlaceName: 'RIVERSIDE Est/Lgd, Lansdowne',
		Code: '7780    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'RIVERSIDE, Durban',
		Code: '4051',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'RIVERSIDE, Kroonstad',
		Code: '9499    ',
		Hub: 'BFN',
		Ring: 3,
		OpsHub: 'BFN',
		Dist: 170,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'RIVERSIDE, Little Brak River',
		Code: '6503    ',
		Hub: 'GRJ',
		Ring: 1,
		OpsHub: 'GRJ',
		Dist: 50,
		AreaName: 'George Regional',
		Surch: null,
	},
	{
		PlaceName: 'RIVERSIDE, Nelspruit',
		Code: '1226',
		Hub: 'NLP',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'RIVERSIDE, Pretoria',
		Code: '0186    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'RIVERTON, Matroosfontein',
		Code: '7490    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'RIVERTON/STA',
		Code: '8500    ',
		Hub: 'BFN',
		Ring: 4,
		OpsHub: 'BFN',
		Dist: 400,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'RIVIERA, Johannesburg',
		Code: '2193    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'RIVIERA, Kimberley',
		Code: '8301    ',
		Hub: 'KIM',
		Ring: 0,
		OpsHub: 'KIM',
		Dist: 20,
		AreaName: 'Kimberley Central',
		Surch: null,
	},
	{
		PlaceName: 'RIVIERA, Pretoria',
		Code: '0084    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'RIVIERSONDEREND',
		Code: '7250    ',
		Hub: 'CPT',
		Ring: 3,
		OpsHub: 'CPT',
		Dist: 140,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'RIVONIA   Boxes',
		Code: '2128    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'RIVONIA Ext/Uit 1 - 17,',
		Code: '2128    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'RIVONIA, Johannesburg',
		Code: '2128    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'RIXTON Est/Lgd, Lansdowne',
		Code: '7780    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'ROADSIDE',
		Code: '9855    ',
		Hub: 'BFN',
		Ring: 4,
		OpsHub: 'BFN',
		Dist: 200,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'ROADSIDE, King Williams Town',
		Code: '5601',
		Hub: 'ELS',
		Ring: 1,
		OpsHub: 'ELS',
		Dist: 70,
		AreaName: 'East London Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'ROBBEN ISLAND',
		Code: '7400    ',
		Hub: 'CPT',
		Ring: 4,
		OpsHub: 'CPT',
		Dist: 200,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'ROBBENEILAND',
		Code: '7400    ',
		Hub: 'CPT',
		Ring: 4,
		OpsHub: 'CPT',
		Dist: 200,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'ROBERTSHAM, Johannesburg',
		Code: '2091    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'ROBERTSON NORTH, Robertson',
		Code: '6705    ',
		Hub: 'CPT',
		Ring: 4,
		OpsHub: 'CPT',
		Dist: 180,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'ROBERTSON, C/K',
		Code: '6705    ',
		Hub: 'CPT',
		Ring: 2,
		OpsHub: 'CPT',
		Dist: 180,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'ROBERTSON, Western Cape',
		Code: '6705    ',
		Hub: 'CPT',
		Ring: 2,
		OpsHub: 'CPT',
		Dist: 180,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'ROBERTSON-NOORD, Robertson',
		Code: '6705    ',
		Hub: 'CPT',
		Ring: 4,
		OpsHub: 'CPT',
		Dist: 180,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'ROBERTVILLE, Florida',
		Code: '1709    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'ROBIN ACRES, Randburg',
		Code: '2194    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'ROBIN HILLS, Randburg',
		Code: '2194    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'ROBINDALE & Ext/Uit 1 & 4',
		Code: '2194    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'ROBINDALE Ext/Uit, Randburg',
		Code: '2194    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'ROBINPARK, Randfontein',
		Code: '1760    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'ROBINSON, Tvl',
		Code: '1761    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'ROBINVALE, Atlantis',
		Code: '7349    ',
		Hub: 'CPT',
		Ring: 1,
		OpsHub: 'CPT',
		Dist: 50,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'ROBSONVALE, Kimberley',
		Code: '8301    ',
		Hub: 'KIM',
		Ring: 0,
		OpsHub: 'KIM',
		Dist: 20,
		AreaName: 'Kimberley Central',
		Surch: null,
	},
	{
		PlaceName: 'ROCHDALE PARK Boxes',
		Code: '4034    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'ROCHESTER',
		Code: '7940    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'ROCKFORD, Phoenix',
		Code: '4068',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'ROCKLANDS, Bloemfontein',
		Code: '9309',
		Hub: 'BFN',
		Ring: 0,
		OpsHub: 'BFN',
		Dist: 30,
		AreaName: 'Bloemfontein Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'ROCKLANDS, Mitchells Plain',
		Code: '7785    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'ROCKLANDS, Uitenhage',
		Code: '6230    ',
		Hub: 'PLZ',
		Ring: 1,
		OpsHub: 'PLZ',
		Dist: 50,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'ROCKVILLE, Middelburg',
		Code: '1050    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 140,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'ROCKVILLE, Tshiawelo',
		Code: '1818    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 30,
		AreaName: 'Johannesburg Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'RODENBECK,Bloemfontein',
		Code: '9328',
		Hub: 'BFN',
		Ring: 2,
		OpsHub: 'BFN',
		Dist: 100,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'RODEWAL, Worcester',
		Code: '6850    ',
		Hub: 'CPT',
		Ring: 2,
		OpsHub: 'CPT',
		Dist: 120,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'ROEDTAN',
		Code: '0580    ',
		Hub: 'PRY',
		Ring: 4,
		OpsHub: 'PRY',
		Dist: 180,
		AreaName: 'Pretoria Regional',
		Surch: null,
	},
	{
		PlaceName: 'ROGGE BAY',
		Code: '8012    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'ROGGEBAAI',
		Code: '8012    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'ROMPEVALLEI, Lansdowne',
		Code: '7780    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'RONDEBOSCH',
		Code: '7700    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'RONDEBOSCH EAST, Lansdown',
		Code: '7780    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'RONDEBOSCH-OOS, Lansdowne',
		Code: '7780    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'RONDEBULT, Germiston',
		Code: '1423    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'RONDEVLEI',
		Code: '6541    ',
		Hub: 'GRJ',
		Ring: 1,
		OpsHub: 'GRJ',
		Dist: 50,
		AreaName: 'George Regional',
		Surch: null,
	},
	{
		PlaceName: 'RONDEVLEI, Retreat',
		Code: '7945    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'RONGINIA',
		Code: '1727    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'RONTREE Est/Lgd, Cape Town',
		Code: '8005',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'ROODEBULT, Germiston',
		Code: '1401    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'ROODEKOP, Germiston',
		Code: '1401    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'ROODEKRANS & Ext/Uit 2 ',
		Code: '1724    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'ROODEKRANS Ext/Uit,',
		Code: '1724    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'ROODEPAN, Kimberley',
		Code: '8309    ',
		Hub: 'KIM',
		Ring: 0,
		OpsHub: 'KIM',
		Dist: 20,
		AreaName: 'Kimberley Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'ROODEPLAAT,Pretoria',
		Code: '0186',
		Hub: 'PRY',
		Ring: 1,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Regional',
		Surch: null,
	},
	{
		PlaceName: 'ROODEPOORT Boxes',
		Code: '1725    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'ROODEPOORT NORTH, Roodeport',
		Code: '1724    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'ROODEPOORT WEST & Ext 2,',
		Code: '1724    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'ROODEPOORT, Gauteng',
		Code: '1724    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'ROODEPOORT-NOORD, Roodeport',
		Code: '1724    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'ROODEPOORT-WES & Uit 2,',
		Code: '1724    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'ROODEWAL, Bloemfontein',
		Code: '9301',
		Hub: 'BFN',
		Ring: 0,
		OpsHub: 'BFN',
		Dist: 30,
		AreaName: 'Bloemfontein Central',
		Surch: null,
	},
	{
		PlaceName: 'ROODTS GARDENS, Vereeniging',
		Code: '1939    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 60,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'ROODTSTUINE, Vereeniging',
		Code: '1939    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 60,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'ROOIBERG',
		Code: '0500    ',
		Hub: 'PRY',
		Ring: 4,
		OpsHub: 'PRY',
		Dist: 200,
		AreaName: 'Pretoria Regional',
		Surch: null,
	},
	{
		PlaceName: 'ROOIBOKKRAAL',
		Code: '0384    ',
		Hub: 'PRY',
		Ring: 2,
		OpsHub: 'PRY',
		Dist: 120,
		AreaName: 'Pretoria Regional',
		Surch: null,
	},
	{
		PlaceName: 'ROOIGROND',
		Code: '2743    ',
		Hub: 'JNB',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 340,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'ROOIHUISKRAAL',
		Code: '0154    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'ROOIHUISKRAAL Ext/Uit 1 ',
		Code: '0154    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'ROOIPANVILLE',
		Code: '8745    ',
		Hub: 'BFN',
		Ring: 4,
		OpsHub: 'BFN',
		Dist: 310,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'ROOIRIVIERRIF, George',
		Code: '6529',
		Hub: 'GRJ',
		Ring: 0,
		OpsHub: 'GRJ',
		Dist: 10,
		AreaName: 'George Central',
		Surch: null,
	},
	{
		PlaceName: 'ROOIWAL',
		Code: '9541    ',
		Hub: 'WEL',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 180,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'ROOKDALE',
		Code: '3351    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 250,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'ROOSENDAL, Delft',
		Code: '7100    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 15,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'ROOSEVELT PARK ',
		Code: '2195    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'ROOSEVELT PARK Boxes',
		Code: '2129    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'ROOSHEUWEL, Klerksdorp',
		Code: '2571    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 150,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'ROOSPARK, Bryanston',
		Code: '2021    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'ROOSSENEKAL',
		Code: '1066    ',
		Hub: 'MPG',
		Ring: 2,
		OpsHub: 'JNB',
		Dist: 140,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: "RORKE'S DRIFT",
		Code: '3016    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 260,
		AreaName: 'Durban Regional',
		Surch: 'Far Outlying',
	},
	{
		PlaceName: 'ROSE PARK, Ladysmith',
		Code: '3370    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 250,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'ROSEACRE, Johannesburg',
		Code: '2197    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'ROSEBANK, C/K Boxes',
		Code: '7720    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'ROSEBANK, Cape Town',
		Code: '7700    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'ROSEBANK, Johannesburg',
		Code: '2196    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'ROSEDALE PARK, East London',
		Code: '5247    ',
		Hub: 'ELS',
		Ring: 0,
		OpsHub: 'ELS',
		Dist: 10,
		AreaName: 'East London Central',
		Surch: null,
	},
	{
		PlaceName: 'ROSEDALE, Eersterivier',
		Code: '7100    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 15,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'ROSEDALE, Uitenhage',
		Code: '6241    ',
		Hub: 'PLZ',
		Ring: 1,
		OpsHub: 'PLZ',
		Dist: 50,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'ROSEDENE, Boksburg',
		Code: '1459    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'ROSEGLEN, Durban',
		Code: '4091',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'ROSEHILL, Durban',
		Code: '4051',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'ROSEMOOR, George',
		Code: '6529',
		Hub: 'GRJ',
		Ring: 0,
		OpsHub: 'GRJ',
		Dist: 10,
		AreaName: 'George Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'ROSEMOUNT, East London',
		Code: '5201    ',
		Hub: 'ELS',
		Ring: 0,
		OpsHub: 'ELS',
		Dist: 10,
		AreaName: 'East London Central',
		Surch: null,
	},
	{
		PlaceName: 'ROSENDAL, Durbanville',
		Code: '7550    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'ROSENEATH',
		Code: '4171    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 30,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'ROSENPARK, Durbanville',
		Code: '7550',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'ROSETTA',
		Code: '3301    ',
		Hub: 'HSM',
		Ring: 3,
		OpsHub: 'JNB',
		Dist: 160,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'ROSETTENVILLE Boxes',
		Code: '2130    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'ROSETTENVILLE Ext',
		Code: '2197    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'ROSETTENVILLE, Johannesburg',
		Code: '2190    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'ROSETTENVILLE,Johannesburg',
		Code: '2190',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'ROSEVILLE, Pretoria',
		Code: '0084    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'ROSHERVILLE, Germiston',
		Code: '1419    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'ROSHERVILLE,Johannesburg',
		Code: '2094',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'ROSHNEE, Vereeniging',
		Code: '1936    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 60,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'ROSMEAD',
		Code: '5910    ',
		Hub: 'PLZ',
		Ring: 4,
		OpsHub: 'PLZ',
		Dist: 350,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'ROSSBURGH Boxes',
		Code: '4072    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'ROSSBURGH, Durban',
		Code: '4094    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'ROSSLYN',
		Code: '0200    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'ROSSMORE, Johannesburg',
		Code: '2092    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'ROSSOUW',
		Code: '5437    ',
		Hub: 'ELS',
		Ring: 4,
		OpsHub: 'ELS',
		Dist: 280,
		AreaName: 'East London Regional',
		Surch: null,
	},
	{
		PlaceName: 'ROTHDENE',
		Code: '1964    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 60,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'ROUXVILLE (OFS/OVS)',
		Code: '9958    ',
		Hub: 'BFN',
		Ring: 4,
		OpsHub: 'BFN',
		Dist: 210,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'ROUXVILLE, Johannesburg',
		Code: '2192    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'ROWALLAN PARK, Port Elizabeth',
		Code: '6025',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'ROWHILL, Springs',
		Code: '1559    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'ROYAL ASCOT, Milnerton',
		Code: '7441',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'ROYLDENE, Kimberley',
		Code: '8301    ',
		Hub: 'KIM',
		Ring: 0,
		OpsHub: 'KIM',
		Dist: 20,
		AreaName: 'Kimberley Central',
		Surch: null,
	},
	{
		PlaceName: 'ROZANO',
		Code: '0723    ',
		Hub: 'PTG',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 300,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'ROZENDAL, Stellenbosch',
		Code: '7600    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 60,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'RUE MONT CLARE, Somerset West',
		Code: '7130    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 30,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'RUFANEVALE, Port Elizabeth',
		Code: '6001    ',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'RUGBY Boxes',
		Code: '7438    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'RUGBY, Maitland',
		Code: '7405    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'RUIMSIG, Roodepoort',
		Code: '1724',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'RUITERHOF, Randburg',
		Code: '2194    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'RUSLOO',
		Code: '1468    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'RUSOORD, Cradock',
		Code: '5880    ',
		Hub: 'PLZ',
		Ring: 4,
		OpsHub: 'PLZ',
		Dist: 260,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'RUST DE WINTER',
		Code: '0406    ',
		Hub: 'PRY',
		Ring: 4,
		OpsHub: 'PRY',
		Dist: 180,
		AreaName: 'Pretoria Regional',
		Surch: null,
	},
	{
		PlaceName: 'RUST TER VAAL, Vereeniging',
		Code: '1938    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 60,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'RUSTDAL, Blackheath (C/K)',
		Code: '7581    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'RUSTDALE Est/Lgd, Crawford',
		Code: '7780    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'RUSTDENE, Beaufort West',
		Code: '6970    ',
		Hub: 'CPT',
		Ring: 4,
		OpsHub: 'CPT',
		Dist: 470,
		AreaName: 'Cape Town Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'RUSTENBURG NORTH, Rustenburg',
		Code: '0300    ',
		Hub: 'JNB',
		Ring: 2,
		OpsHub: 'JNB',
		Dist: 120,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'RUSTENBURG, North West',
		Code: '0299    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 120,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'RUSTENBURG-NOORD, Rustenburg',
		Code: '0300    ',
		Hub: 'JNB',
		Ring: 2,
		OpsHub: 'JNB',
		Dist: 120,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'RUSTHOF, Strand',
		Code: '7140    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 30,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'RUSTIVIA, Primrose',
		Code: '1401    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'RUTVALE Est/Lgd, Crawford',
		Code: '7780    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'RUWARI, Brackenfell',
		Code: '7560    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'RUYTERWACHT Boxes',
		Code: '7470    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'RUYTERWACHT, Cape Town',
		Code: '7460    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'RYBELL',
		Code: '7761    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'RYDALVALE, Phoenix',
		Code: '4068',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'RYLANDS Est/Lgd, Athlone',
		Code: '7764    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'RYNFIELD & Ext/Uit 8, 10',
		Code: '1501    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'RYNFIELD Boxes',
		Code: '1514    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'RYNOUE (S)',
		Code: '0035',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 133,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'RYNPARK, Benoni',
		Code: '1501    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'RYNSOORD, Benoni',
		Code: '1501    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'RYSMIERBULT',
		Code: '2701    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 70,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'SAAIPLAAS, Virginia OFS',
		Code: '9430    ',
		Hub: 'BFN',
		Ring: 2,
		OpsHub: 'BFN',
		Dist: 25,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'SAAISKAG',
		Code: '9436    ',
		Hub: 'BFN',
		Ring: 4,
		OpsHub: 'BFN',
		Dist: 180,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'SAALSKOP',
		Code: '8825    ',
		Hub: 'BFN',
		Ring: 4,
		OpsHub: 'BFN',
		Dist: 570,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'SABIE',
		Code: '1260    ',
		Hub: 'MPG',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 350,
		AreaName: 'Johannesburg Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'SACKS CIRCLE, Bellville',
		Code: '7530    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'SAFARI GARDENS, Rustenburg',
		Code: '0300    ',
		Hub: 'JNB',
		Ring: 2,
		OpsHub: 'JNB',
		Dist: 120,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'SAFARITUINE, Rustenburg',
		Code: '0300    ',
		Hub: 'JNB',
		Ring: 2,
		OpsHub: 'JNB',
		Dist: 120,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'SAGEWOOD EXT 1, Midrand',
		Code: '1685',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'SAGEWOOD EXT 10, Midrand',
		Code: '1685',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'SAGEWOOD EXT 15, Midrand',
		Code: '1685',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'SAGEWOOD EXT 22, Midrand',
		Code: '1685',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'SAGEWOOD, Midrand',
		Code: '1685',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'SAICCOR VILLAGE, Umkomaas',
		Code: '4171',
		Hub: 'DUR',
		Ring: 1,
		OpsHub: 'DUR',
		Dist: 70,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'SALANG',
		Code: '2552    ',
		Hub: 'JNB',
		Ring: 3,
		OpsHub: 'JNB',
		Dist: 150,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'SALBERAU, Matroosfontein',
		Code: '7490    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'SALDANHA',
		Code: '7395    ',
		Hub: 'CPT',
		Ring: 3,
		OpsHub: 'CPT',
		Dist: 150,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'SALEM',
		Code: '6150    ',
		Hub: 'PLZ',
		Ring: 3,
		OpsHub: 'PLZ',
		Dist: 150,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'SALFRED, Bryanston',
		Code: '2021    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'SALIESHOEK, Pretoria',
		Code: '0184    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'SALISBURY CLAIMS, Johannesburg',
		Code: '2001    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'SALISBURY ISLAND',
		Code: '4020    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 15,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'SALISBURY PARK, Port Elizabeth',
		Code: '6070',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'SALSONEVILLE, Port Elizabeth',
		Code: '6059    ',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'SALT LAKE (TVL)',
		Code: '8731    ',
		Hub: 'BFN',
		Ring: 4,
		OpsHub: 'BFN',
		Dist: 310,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'SALT LAKE, Port Elizabeth',
		Code: '6059    ',
		Hub: 'PLZ',
		Ring: 1,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'SALT RIVER',
		Code: '7925    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'SALT ROCK',
		Code: '4391    ',
		Hub: 'DUR',
		Ring: 2,
		OpsHub: 'DUR',
		Dist: 90,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'SALTVILLE Boxes',
		Code: '6059    ',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'SALTVILLE, Port Elizabeth',
		Code: '6059    ',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'SALVATION',
		Code: '3110    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 250,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'SALVO PARK, Somerset West',
		Code: '7130    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 30,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'SALVOKOP, Pretoria',
		Code: '0002    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'SAMANAVILLE, Pietermaritzburg',
		Code: '3201    ',
		Hub: 'PMB',
		Ring: 1,
		OpsHub: 'PMB',
		Dist: 75,
		AreaName: 'Pietermaritzburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'SAMBO',
		Code: '0986    ',
		Hub: 'JNB',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 300,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'SAMBOKLAND, Rondebosch',
		Code: '7780    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'SAMORA MACHEL, Mitchells Plain',
		Code: '7784',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'SAMUNGU',
		Code: '3818    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 240,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'SANAA, Verulam',
		Code: '4340    ',
		Hub: 'DUR',
		Ring: 1,
		OpsHub: 'DUR',
		Dist: 50,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'SANCTOR, Port Elizabeth',
		Code: '6059    ',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'SANCTUARY PARK Est/Lgd,',
		Code: '4093',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'SANDANIA, Welkom',
		Code: '9459    ',
		Hub: 'WEL',
		Ring: 3,
		OpsHub: 'JNB',
		Dist: 150,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'SANDBAAI',
		Code: '7200',
		Hub: 'CPT',
		Ring: 1,
		OpsHub: 'CPT',
		Dist: 120,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'SANDBERG',
		Code: '8121    ',
		Hub: 'CPT',
		Ring: 4,
		OpsHub: 'CPT',
		Dist: 200,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'SANDBULT, Burgersdorp',
		Code: '5520    ',
		Hub: 'ELS',
		Ring: 4,
		OpsHub: 'ELS',
		Dist: 360,
		AreaName: 'East London Regional',
		Surch: null,
	},
	{
		PlaceName: 'SANDDRIFT, Milnerton',
		Code: '7441    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'SANDFONTEIN',
		Code: '0708    ',
		Hub: 'RBG',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 400,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'SANDHILLS',
		Code: '6854    ',
		Hub: 'CPT',
		Ring: 2,
		OpsHub: 'CPT',
		Dist: 120,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'SANDHURST, Elsiesrivier',
		Code: '7490    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'SANDHURST, Johannesburg',
		Code: '2196    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'SANDOWN',
		Code: '2196    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'SANDOWN Ext/Uit 2 & 4,',
		Code: '2196    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'SANDOWN Ext/Uit 6,7,9,10,',
		Code: '2031    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'SANDRIF, Brits',
		Code: '0250    ',
		Hub: 'JNB',
		Ring: 2,
		OpsHub: 'JNB',
		Dist: 90,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'SANDRINGHAM Boxes',
		Code: '2131    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'SANDRINGHAM, Johannesburg',
		Code: '2192    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'SANDRINGHAM, Queenstown',
		Code: '5320    ',
		Hub: 'ELS',
		Ring: 4,
		OpsHub: 'ELS',
		Dist: 210,
		AreaName: 'East London Regional',
		Surch: null,
	},
	{
		PlaceName: 'SANDTON Boxes',
		Code: '2146    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'SANDTON CITY, Sandton',
		Code: '2196    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'SANDTON, Johannesburg',
		Code: '2196    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'SANDVLAKTE',
		Code: '6688    ',
		Hub: 'GRJ',
		Ring: 4,
		OpsHub: 'GRJ',
		Dist: 200,
		AreaName: 'George Regional',
		Surch: null,
	},
	{
		PlaceName: 'SANDVLEI, Faure',
		Code: '7131    ',
		Hub: 'CPT',
		Ring: 1,
		OpsHub: 'CPT',
		Dist: 30,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'SANDVLEI, Muizenberg',
		Code: '7945    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'SANGROVE Est/Lgd, Rondebo',
		Code: '7700    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'SANLAMHOF',
		Code: '7532    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'SANNIESHOF',
		Code: '2760    ',
		Hub: 'JNB',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 300,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'SANRENIO, Mitchells Plain',
		Code: '7785    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'SANTAVILLE, Graaff-Reinet',
		Code: '6280    ',
		Hub: 'PLZ',
		Ring: 4,
		OpsHub: 'PLZ',
		Dist: 290,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'SANTOMBE',
		Code: '4687    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 180,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'SANTOSHAVEN, Mosselbaai',
		Code: '6509    ',
		Hub: 'GRJ',
		Ring: 1,
		OpsHub: 'GRJ',
		Dist: 60,
		AreaName: 'George Regional',
		Surch: null,
	},
	{
		PlaceName: 'SANTOY',
		Code: '8491    ',
		Hub: 'KUR',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 400,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'SAPKAMMA',
		Code: '6235    ',
		Hub: 'PLZ',
		Ring: 2,
		OpsHub: 'PLZ',
		Dist: 110,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'SAREPTA Boxes',
		Code: '7582    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'SAREPTA, Kuilsrivier',
		Code: '7580    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'SARNIA Boxes',
		Code: '3615    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'SARNIA, Pinetown',
		Code: '3610',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'SARON',
		Code: '6812    ',
		Hub: 'CPT',
		Ring: 2,
		OpsHub: 'CPT',
		Dist: 100,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'SASELAMANI',
		Code: '0928    ',
		Hub: 'PTG',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 300,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'SASOLBURG',
		Code: '1947    ',
		Hub: 'JNB',
		Ring: 2,
		OpsHub: 'JNB',
		Dist: 100,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'SAUER',
		Code: '7324    ',
		Hub: 'CPT',
		Ring: 3,
		OpsHub: 'CPT',
		Dist: 130,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'SAULSPOORT',
		Code: '0318',
		Hub: 'JNB',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 203,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'SAULSVILLE, Pretoria',
		Code: '0125',
		Hub: 'PRY',
		Ring: 1,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'SAVANNAH PARK, Chatsworth',
		Code: '4092',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'SAVANNAH PARK, Verwoerdburg',
		Code: '0157    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'SAVANNAH, Chatsworth',
		Code: '4092',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'SAVANNAH,Durban',
		Code: '3609',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'SAVOY Est/Lgd, Johannesburg',
		Code: '2090    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'SAWOTI',
		Code: '4203    ',
		Hub: 'DUR',
		Ring: 1,
		OpsHub: 'DUR',
		Dist: 40,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'SAXILBY, East London',
		Code: '5247    ',
		Hub: 'ELS',
		Ring: 0,
		OpsHub: 'ELS',
		Dist: 10,
		AreaName: 'East London Central',
		Surch: null,
	},
	{
		PlaceName: 'SAXONSEA, Atlantis',
		Code: '7349    ',
		Hub: 'CPT',
		Ring: 1,
		OpsHub: 'CPT',
		Dist: 50,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'SAXONWOLD',
		Code: '2196    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'SAXONWOLD Boxes',
		Code: '2132    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'SCARBOROUGH, Fish Hoek',
		Code: '7975    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 60,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'SCENERY PARK, EAST LONDON',
		Code: '5247',
		Hub: 'ELS',
		Ring: 0,
		OpsHub: 'ELS',
		Dist: 0,
		AreaName: 'East London Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'SCHAAPKRAAL, Ottery',
		Code: '7945    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'SCHAGEN',
		Code: '1207    ',
		Hub: 'NLP',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 310,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'SCHAUDERVILLE Boxes',
		Code: '6060    ',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'SCHAUDERVILLE, Port Elizabeth',
		Code: '6020    ',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'SCHOEMANSHOEK',
		Code: '6635    ',
		Hub: 'GRJ',
		Ring: 1,
		OpsHub: 'GRJ',
		Dist: 70,
		AreaName: 'George Regional',
		Surch: null,
	},
	{
		PlaceName: 'SCHOEMANSVILLE, Hartbeespoort',
		Code: '0216    ',
		Hub: 'JNB',
		Ring: 2,
		OpsHub: 'JNB',
		Dist: 100,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'SCHOENMAKERSKOP, Port Elizabeth',
		Code: '6070',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'SCHONKENVILLE, Parys',
		Code: '9585    ',
		Hub: 'JNB',
		Ring: 3,
		OpsHub: 'JNB',
		Dist: 130,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'SCHOONGEZICHT, Durbanville',
		Code: '7550    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'SCHOONGEZICHT, Witbank',
		Code: '1035    ',
		Hub: 'MBG',
		Ring: 3,
		OpsHub: 'JNB',
		Dist: 140,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'SCHORNVILLE, King William',
		Code: '5600    ',
		Hub: 'ELS',
		Ring: 1,
		OpsHub: 'ELS',
		Dist: 70,
		AreaName: 'East London Regional',
		Surch: null,
	},
	{
		PlaceName: 'SCHOTSCHEKLOOF, Cape Town',
		Code: '8001',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'SCHROEDERS',
		Code: '3471    ',
		Hub: 'DUR',
		Ring: 2,
		OpsHub: 'DUR',
		Dist: 120,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'SCHUINSHOOGTE, Ladysmith',
		Code: '3370    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 250,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'SCHWEIZER-RENEKE',
		Code: '2780    ',
		Hub: 'JNB',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 420,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'SCOTT ESTATE, Cape Town',
		Code: '7806',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'SCOTTBURGH',
		Code: '4180    ',
		Hub: 'DUR',
		Ring: 1,
		OpsHub: 'DUR',
		Dist: 160,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'SCOTTBURGH SOUTH, Scottburgh',
		Code: '4180    ',
		Hub: 'DUR',
		Ring: 1,
		OpsHub: 'DUR',
		Dist: 160,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'SCOTTBURGH-SUID, Scottburgh',
		Code: '4180    ',
		Hub: 'DUR',
		Ring: 1,
		OpsHub: 'DUR',
		Dist: 160,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'SCOTTSDENE, Kraaifontein',
		Code: '7570    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'SCOTTSVILLE & Ext/Uit,',
		Code: '3201    ',
		Hub: 'PMB',
		Ring: 1,
		OpsHub: 'PMB',
		Dist: 75,
		AreaName: 'Pietermaritzburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'SCOTTSVILLE Boxes',
		Code: '3209    ',
		Hub: 'DUR',
		Ring: 2,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'SCOTTSVILLE, Kraaifontein',
		Code: '7570    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'SE 1 - 7, Vanderbijlpark',
		Code: '1911    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 60,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'SEA COW LAKE, Durban',
		Code: '4051',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'SEA PARK',
		Code: '4241    ',
		Hub: 'DUR',
		Ring: 3,
		OpsHub: 'DUR',
		Dist: 130,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'SEA POINT Boxes',
		Code: '8060    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'SEA POINT, Cape Town',
		Code: '8005',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'SEA VIEW Boxes',
		Code: '4073    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'SEA VIEW, Durban',
		Code: '4094    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'SEA VIEW, Pacaltsdorp',
		Code: '6529',
		Hub: 'GRJ',
		Ring: 0,
		OpsHub: 'GRJ',
		Dist: 10,
		AreaName: 'George Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'SEA VIEW, Pacaltsdorp',
		Code: '6529',
		Hub: 'GRJ',
		Ring: 0,
		OpsHub: 'GRJ',
		Dist: 10,
		AreaName: 'George Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'SEA WIND, Retreat',
		Code: '7945    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'SEACREST, Mitchells Plain',
		Code: '7785    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'SEAFORTH, Simons Town',
		Code: '7995    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 70,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'SEATIDES, Desainagar',
		Code: '4405    ',
		Hub: 'DUR',
		Ring: 1,
		OpsHub: 'DUR',
		Dist: 80,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'SEATIDES, Tongaat',
		Code: '4399',
		Hub: 'DUR',
		Ring: 1,
		OpsHub: 'DUR',
		Dist: 70,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'SEAVIEW, Retreat',
		Code: '7945    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'SEBAYENG',
		Code: '0722    ',
		Hub: 'PTG',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 300,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'SEBENZA, Edenvale (Tvl)',
		Code: '1609    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'SEBOKENG',
		Code: '1982    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 60,
		AreaName: 'Johannesburg Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'SEDGEFIELD',
		Code: '6573    ',
		Hub: 'GRJ',
		Ring: 1,
		OpsHub: 'GRJ',
		Dist: 50,
		AreaName: 'George Regional',
		Surch: null,
	},
	{
		PlaceName: 'SEEMEEUPARK, Welkom',
		Code: '9459    ',
		Hub: 'WEL',
		Ring: 3,
		OpsHub: 'JNB',
		Dist: 150,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'SEEPUNT Busse',
		Code: '8060    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'SEEPUNT, Kaapstad',
		Code: '8005',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'SEESIG',
		Code: '7977    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 60,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'SEGOPJE',
		Code: '0744    ',
		Hub: 'PTG',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 300,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'SEINHEUWEL, Kaapstad',
		Code: '8001',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'SEISOVILLE, Lengau',
		Code: '9499    ',
		Hub: 'BFN',
		Ring: 3,
		OpsHub: 'BFN',
		Dist: 170,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'SEKHUKHUNE',
		Code: '1124    ',
		Hub: 'MPG',
		Ring: 3,
		OpsHub: 'JNB',
		Dist: 140,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'SEKWATI',
		Code: '1063    ',
		Hub: 'MPG',
		Ring: 2,
		OpsHub: 'JNB',
		Dist: 140,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'SELBORNE, East London',
		Code: '5201    ',
		Hub: 'ELS',
		Ring: 0,
		OpsHub: 'ELS',
		Dist: 10,
		AreaName: 'East London Central',
		Surch: null,
	},
	{
		PlaceName: 'SELBOURNE, Bellville',
		Code: '7530    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'SELBY',
		Code: '2001    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'SELBY Ext/Uit 5, 10, 12 &',
		Code: '2092    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'SELBY, Johannesburg',
		Code: '2001    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'SELCOURT & Ext/Uit 3,',
		Code: '1559    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'SELCOURT Boxes',
		Code: '1567    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'SELECTION PARK Boxes',
		Code: '1568    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'SELECTION PARK, Springs',
		Code: '1559    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'SELOBALA, Carolina',
		Code: '1185    ',
		Hub: 'JNB',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 250,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'SELONS RIVER',
		Code: '1054    ',
		Hub: 'MBG',
		Ring: 2,
		OpsHub: 'JNB',
		Dist: 140,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'SELONSRIVIER',
		Code: '1054    ',
		Hub: 'MBG',
		Ring: 2,
		OpsHub: 'JNB',
		Dist: 140,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'SELWYN, Florida',
		Code: '1709    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'SENDERWOOD Boxes',
		Code: '2145    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'SENDERWOOD, Bedfordview',
		Code: '2008    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'SENOANE, Tshiawelo',
		Code: '1818    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 30,
		AreaName: 'Johannesburg Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'SENTRAAL, Empangeni',
		Code: '3880',
		Hub: 'DUR',
		Ring: 1,
		OpsHub: 'DUR',
		Dist: 180,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'SENTRAAL, Kaapstad',
		Code: '8001',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'SENTRAAL, Pretoria',
		Code: '0002    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'SENTRUM',
		Code: '0385    ',
		Hub: 'PRY',
		Ring: 2,
		OpsHub: 'PRY',
		Dist: 120,
		AreaName: 'Pretoria Regional',
		Surch: null,
	},
	{
		PlaceName: 'SEPANAPUDI',
		Code: '0705    ',
		Hub: 'PTG',
		Ring: 0,
		OpsHub: 'PTG',
		Dist: 400,
		AreaName: 'Pietersburg Central',
		Surch: null,
	},
	{
		PlaceName: 'SES BRUGGE',
		Code: '8808    ',
		Hub: 'KIM',
		Ring: 3,
		OpsHub: 'BFN',
		Dist: 20,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'SESENG',
		Code: '8447    ',
		Hub: 'KUR',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 510,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'SESFONTEIN, Petit',
		Code: '1512    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'SESHEGO',
		Code: '0742    ',
		Hub: 'PTG',
		Ring: 4,
		OpsHub: 'PTG',
		Dist: 300,
		AreaName: 'Pietersburg Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'SETLAGOLE',
		Code: '8658    ',
		Hub: 'BFN',
		Ring: 4,
		OpsHub: 'BFN',
		Dist: 450,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'SETTLERS',
		Code: '0430    ',
		Hub: 'PRY',
		Ring: 4,
		OpsHub: 'PRY',
		Dist: 180,
		AreaName: 'Pretoria Regional',
		Surch: null,
	},
	{
		PlaceName: 'SEVENOAKS',
		Code: '3495    ',
		Hub: 'DUR',
		Ring: 3,
		OpsHub: 'DUR',
		Dist: 140,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'SEZELA',
		Code: '4215    ',
		Hub: 'DUR',
		Ring: 1,
		OpsHub: 'DUR',
		Dist: 50,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: "SHAKA'S HEAD",
		Code: '4381',
		Hub: 'DUR',
		Ring: 1,
		OpsHub: 'DUR',
		Dist: 80,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: "SHAKA'S ROCK",
		Code: '4399',
		Hub: 'DUR',
		Ring: 1,
		OpsHub: 'DUR',
		Dist: 60,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'SHAKASKRAAL',
		Code: '4430    ',
		Hub: 'DUR',
		Ring: 1,
		OpsHub: 'DUR',
		Dist: 70,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'SHALLCROSS Boxes',
		Code: '4079    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'SHALLCROSS, Queensburgh',
		Code: '4093',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'SHANNON, Bloemspruit',
		Code: '9364    ',
		Hub: 'BFN',
		Ring: 0,
		OpsHub: 'BFN',
		Dist: 30,
		AreaName: 'Bloemfontein Central',
		Surch: null,
	},
	{
		PlaceName: 'SHANVA HILLS, Durban North',
		Code: '4051',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'SHARKEVILLE, Stanger',
		Code: '4450    ',
		Hub: 'DUR',
		Ring: 2,
		OpsHub: 'DUR',
		Dist: 120,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'SHARON PARK, Dunnottar',
		Code: '1590    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'SHARON PARK,Nigel',
		Code: '1496',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 45,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'SHARONLEA, Olivedale',
		Code: '2158    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'SHARPEVILLE',
		Code: '1933    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 60,
		AreaName: 'Johannesburg Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'SHATALE',
		Code: '1282    ',
		Hub: 'JNB',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 310,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'SHAYANDIMA,Limpopo',
		Code: '0945',
		Hub: 'PTG',
		Ring: 1,
		OpsHub: 'PTG',
		Dist: 70,
		AreaName: 'Pietersburg Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'SHEARWATER, Port Elizabeth',
		Code: '6001    ',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'SHELLY BEACH',
		Code: '4265    ',
		Hub: 'DUR',
		Ring: 3,
		OpsHub: 'DUR',
		Dist: 140,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: "SHELLY BEACH, St Michael'",
		Code: '4265    ',
		Hub: 'DUR',
		Ring: 3,
		OpsHub: 'DUR',
		Dist: 140,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'SHEPSTONE',
		Code: '9413    ',
		Hub: 'BFN',
		Ring: 1,
		OpsHub: 'BFN',
		Dist: 50,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'SHERE, Pretoria',
		Code: '0184',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'SHERIDAN',
		Code: '9704    ',
		Hub: 'BFN',
		Ring: 4,
		OpsHub: 'BFN',
		Dist: 230,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'SHERIDON PARK, Steenberg',
		Code: '7945    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'SHERWOOD GARDENS, Brakpan',
		Code: '1545    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'SHERWOOD PARK, Dassenberg',
		Code: '7349    ',
		Hub: 'CPT',
		Ring: 1,
		OpsHub: 'CPT',
		Dist: 50,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'SHERWOOD PARK, Manenberg',
		Code: '7764    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'SHERWOOD, Durban',
		Code: '4091',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'SHERWOOD, Port Elizabeth',
		Code: '6025',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'SHILUVANE',
		Code: '0873    ',
		Hub: 'PTG',
		Ring: 4,
		OpsHub: 'PTG',
		Dist: 300,
		AreaName: 'Pietersburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'SHIMAWUSA, Giyani',
		Code: '0826    ',
		Hub: 'PTG',
		Ring: 4,
		OpsHub: 'PTG',
		Dist: 170,
		AreaName: 'Pietersburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'SHIRLEY PARK, Bellville',
		Code: '7530    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'SHONGWE MISSION',
		Code: '1331    ',
		Hub: 'JNB',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 310,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'SHONGWENI',
		Code: '3650    ',
		Hub: 'DUR',
		Ring: 1,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'SHORTLANDS, Durban',
		Code: '4052    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'SHORTS RETREAT, Pietermaritzburg',
		Code: '3201    ',
		Hub: 'PMB',
		Ring: 1,
		OpsHub: 'PMB',
		Dist: 75,
		AreaName: 'Pietermaritzburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'SHULTON PARK, Amanzimtoti',
		Code: '4126',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'SIBASA',
		Code: '0826    ',
		Hub: 'PTG',
		Ring: 4,
		OpsHub: 'PTG',
		Dist: 300,
		AreaName: 'Pietersburg Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'SIBHAYI',
		Code: '3967    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 300,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'SIBONGILE, Dundee',
		Code: '3000    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 300,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'SIDBURY',
		Code: '6131    ',
		Hub: 'PLZ',
		Ring: 2,
		OpsHub: 'PLZ',
		Dist: 110,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'SIDLAMAFA',
		Code: '1332    ',
		Hub: 'JNB',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 310,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'SIDNEYVALE, Matroosfontei',
		Code: '7490    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'SIDWELL Boxes',
		Code: '6061    ',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'SIDWELL, Port Elizabeth',
		Code: '6001    ',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'SIGNAL HILL, Cape Town',
		Code: '8001',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'SIGNAL HILL, Ladysmith',
		Code: '3370    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 250,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'SIGNAL HILL, Newcastle',
		Code: '2940    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 320,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'SIGNAL HILL, Pietermaritzburg',
		Code: '3201    ',
		Hub: 'PMB',
		Ring: 1,
		OpsHub: 'PMB',
		Dist: 75,
		AreaName: 'Pietermaritzburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'SILLERY, Constantia',
		Code: '7800    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'SILOAM',
		Code: '0993',
		Hub: 'PTG',
		Ring: 1,
		OpsHub: 'PTG',
		Dist: 163,
		AreaName: 'Pietersburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'SILUMA VIEW, Katlehong',
		Code: '1832    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 30,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'SILVAMONTE, Johannesburg',
		Code: '2192    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'SILVER LAKES, Pretoria',
		Code: '0081',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'SILVER OAKS, Kuilsrivier',
		Code: '7580    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'SILVER WILLOW, Mayville',
		Code: '4091',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'SILVERFIELDS PARK, Kruger',
		Code: '1739    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'SILVERFIELDS, Krugersdorp',
		Code: '1739    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'SILVERGLADE, Fish hoek',
		Code: '7975    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 60,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'SILVERGLEN, Chatsworth',
		Code: '4092',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'SILVERHURST Est/Lgd,',
		Code: '7800    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'SILVERSANDS, Eersterivier',
		Code: '7100    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 15,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'SILVERSANDS, Kuilsrivier',
		Code: '7580    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'SILVERTON',
		Code: '0184    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'SILVERTON, Pretoria',
		Code: '0184    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'SILVERTOWN, Athlone',
		Code: '7764    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'SILWOOD',
		Code: '7715    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'SIMBA, Strathavon',
		Code: '2031    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'SIMBOLIA, Springs',
		Code: '1559    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'SIMMERFIELD, Primrose',
		Code: '1401    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: "SIMON'S TOWN",
		Code: '7995    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 70,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: "SIMON'S TOWN (S)",
		Code: '7995',
		Hub: 'CPT',
		Ring: 1,
		OpsHub: 'CPT',
		Dist: 70,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'SIMONDIUM',
		Code: '7670    ',
		Hub: 'CPT',
		Ring: 2,
		OpsHub: 'CPT',
		Dist: 90,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'SIMONSBERG',
		Code: '7608    ',
		Hub: 'CPT',
		Ring: 1,
		OpsHub: 'CPT',
		Dist: 60,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'SIMONSTAD',
		Code: '7995    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 70,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'SIMONSWYK, Stellenbosch',
		Code: '7600    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 60,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'SINKSA BRIDGE',
		Code: '6535    ',
		Hub: 'GRJ',
		Ring: 1,
		OpsHub: 'GRJ',
		Dist: 10,
		AreaName: 'George Regional',
		Surch: null,
	},
	{
		PlaceName: 'SINKSABRUG',
		Code: '6535    ',
		Hub: 'GRJ',
		Ring: 1,
		OpsHub: 'GRJ',
		Dist: 10,
		AreaName: 'George Regional',
		Surch: null,
	},
	{
		PlaceName: 'SINOVILLE, Pretoria',
		Code: '0129',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'SINTHUMULE',
		Code: '0921    ',
		Hub: 'JNB',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 300,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'SIR LOWRY ROAD Cape Town',
		Code: '7925    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'SIR LOWRY ROAD, CAPE TOWN',
		Code: '7900    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: "SIR LOWRY'S PASS",
		Code: '7133    ',
		Hub: 'CPT',
		Ring: 1,
		OpsHub: 'CPT',
		Dist: 30,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'SIR LOWRYWEG, KAAPSTAD Boxes',
		Code: '7900    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'SIR LOWRYWEG, Kaapstad',
		Code: '7925    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'SITHEMBILE, Glencoe',
		Code: '2930    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 310,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'SIYABONGA',
		Code: '0306    ',
		Hub: 'RBG',
		Ring: 2,
		OpsHub: 'JNB',
		Dist: 120,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'SIYABUSWA',
		Code: '0472    ',
		Hub: 'PRY',
		Ring: 4,
		OpsHub: 'PRY',
		Dist: 180,
		AreaName: 'Pretoria Regional',
		Surch: null,
	},
	{
		PlaceName: 'SKANSKOP, Pretoria',
		Code: '0002    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'SKEERPOORT',
		Code: '0232    ',
		Hub: 'PRY',
		Ring: 2,
		OpsHub: 'JNB',
		Dist: 100,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'SKILPADSVLEI, Constantia',
		Code: '7880    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'SKILPADSVLEI, Durbanville',
		Code: '7550    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'SKOONSPRUIT',
		Code: '9663    ',
		Hub: 'BFN',
		Ring: 4,
		OpsHub: 'BFN',
		Dist: 270,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'SKUINSDRIF',
		Code: '2851    ',
		Hub: 'RBG',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 290,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'SKUKUZA',
		Code: '1350    ',
		Hub: 'NLP',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 310,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'SLANGKOP, Fish Hoek',
		Code: '7975    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 60,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'SLEUTELFONTEIN',
		Code: '0465    ',
		Hub: 'PRY',
		Ring: 4,
		OpsHub: 'PRY',
		Dist: 180,
		AreaName: 'Pretoria Regional',
		Surch: null,
	},
	{
		PlaceName: 'SLOANE PARK, Bryanston',
		Code: '2152    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'SLOVOVILLE, Soweto',
		Code: '1754',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 50,
		AreaName: 'Johannesburg Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'SLURRY',
		Code: '2895    ',
		Hub: 'JNB',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 330,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'SMITHFIELD',
		Code: '9966    ',
		Hub: 'BFN',
		Ring: 4,
		OpsHub: 'BFN',
		Dist: 170,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: "SMITSWINKEL BAY, Simon's Town",
		Code: '7995    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 70,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'SMITSWINKELBAAI, Simonstad',
		Code: '7995    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 70,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'SNELL PARADE, DURBAN Boxes',
		Code: '4074    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'SNELL PARADE, Durban',
		Code: '4001    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'SOBANTU, PIETERMARITZBURG',
		Code: '3210    ',
		Hub: 'DUR',
		Ring: 2,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'SODWANA BAY',
		Code: '3968    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 380,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'SOEBATSFONTEIN',
		Code: '8257    ',
		Hub: 'CPT',
		Ring: 4,
		OpsHub: 'CPT',
		Dist: 620,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'SOEKMEKAAR',
		Code: '0810    ',
		Hub: 'PTG',
		Ring: 4,
		OpsHub: 'PTG',
		Dist: 300,
		AreaName: 'Pietersburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'SOETEWEIDE, Stellenbosch',
		Code: '7600    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 60,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'SOETWATER, Fish Hoek',
		Code: '7975    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 60,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'SOLHEIM, De Aar',
		Code: '7000    ',
		Hub: 'BFN',
		Ring: 4,
		OpsHub: 'BFN',
		Dist: 500,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'SOLHEIM, Primrose',
		Code: '1401    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'SOLRIDGE, Cramerview',
		Code: '2060    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'SOMAROBOGO',
		Code: '0461    ',
		Hub: 'PRY',
		Ring: 4,
		OpsHub: 'PRY',
		Dist: 180,
		AreaName: 'Pretoria Regional',
		Surch: null,
	},
	{
		PlaceName: 'SOMERSET EAST',
		Code: '5850    ',
		Hub: 'PLZ',
		Ring: 4,
		OpsHub: 'PLZ',
		Dist: 190,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'SOMERSET HEIGHTS, Eersterfontein',
		Code: '7100    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 15,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'SOMERSET HEIGHTS, Grahamstown',
		Code: '6140    ',
		Hub: 'PLZ',
		Ring: 4,
		OpsHub: 'PLZ',
		Dist: 170,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'SOMERSET OAKS, Somerset West',
		Code: '7130    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 30,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'SOMERSET PARK, Retreat',
		Code: '7945    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'SOMERSET PARK, Somerset West',
		Code: '7130    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 30,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'SOMERSET PARK, Umhlanga',
		Code: '4319',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 20,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'SOMERSET PARK, Worcester',
		Code: '6850',
		Hub: 'CPT',
		Ring: 1,
		OpsHub: 'CPT',
		Dist: 110,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'SOMERSET ROAD, CAPE TOWN',
		Code: '8014    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'SOMERSET WEST Boxes',
		Code: '7129    ',
		Hub: 'CPT',
		Ring: 1,
		OpsHub: 'CPT',
		Dist: 30,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'SOMERSET WEST, Western Cape',
		Code: '7130    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 30,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'SOMERSET-OOS',
		Code: '5850    ',
		Hub: 'PLZ',
		Ring: 4,
		OpsHub: 'PLZ',
		Dist: 190,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'SOMERSET-WES Busse',
		Code: '7129    ',
		Hub: 'CPT',
		Ring: 1,
		OpsHub: 'CPT',
		Dist: 30,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'SOMERSET-WES Strate',
		Code: '7130    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'SOMERSETWEG, KAAPSTAD',
		Code: '8014    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'SOMKHELE, Mtubatuba',
		Code: '3935',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 300,
		AreaName: 'Durban Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'SONEIKE, Kuilsriver',
		Code: '7580    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'SONGLOED, Klerksdorp',
		Code: '2571    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 150,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'SONHEUWEL EXT',
		Code: '1201',
		Hub: 'NLP',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'SONHEUWEL EXT 1',
		Code: '1201',
		Hub: 'NLP',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'SONHEUWEL EXT 2',
		Code: '1201',
		Hub: 'NLP',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'SONHEUWEL, Nelspruit',
		Code: '1201',
		Hub: 'NLP',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'SONLANDPARK Boxes',
		Code: '1944    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 60,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'SONLANDPARK, Vereeniging',
		Code: '1939    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 60,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'SONNEDAL, Zandspruit',
		Code: '1749',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'SONNEDAL, Zandspruit',
		Code: '1749',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'SONNEGLANS, Olivedale',
		Code: '2158    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'SONNEKUIL, Kuilsriver',
		Code: '7580    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'SONNENDAL, Parow',
		Code: '7500    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'SONOP',
		Code: '0258    ',
		Hub: 'RBG',
		Ring: 2,
		OpsHub: 'JNB',
		Dist: 100,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'SONPARK, Nelspruit',
		Code: '1201',
		Hub: 'NLP',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'SONSIG, Durbanville',
		Code: '7550',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'SONSTRAAL HEIGHTS, Durbanville',
		Code: '7550',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'SONSTRAAL, Durbanville',
		Code: '7550    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'SOPHIATOWN, Johannesburg',
		Code: '2092',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'SOSHANGUVE',
		Code: '0152    ',
		Hub: 'PRY',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'SOUTH BEACH, Durban',
		Code: '4001    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'SOUTH CREST, Alberton',
		Code: '1449    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'SOUTH END, Port Elizabeth',
		Code: '6001    ',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'SOUTH END, Strand',
		Code: '7140    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 30,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'SOUTH HILLS Boxes',
		Code: '2136    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'SOUTH HILLS, Johannesburg',
		Code: '2197    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'SOUTH KENSINGTON, Johannesburg',
		Code: '2094    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'SOUTH RIDGE, Kimberley',
		Code: '8301    ',
		Hub: 'KIM',
		Ring: 0,
		OpsHub: 'KIM',
		Dist: 20,
		AreaName: 'Kimberley Central',
		Surch: null,
	},
	{
		PlaceName: 'SOUTH RIDGE, Verulam',
		Code: '4340    ',
		Hub: 'DUR',
		Ring: 1,
		OpsHub: 'DUR',
		Dist: 50,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'SOUTH RIDING, Johannesburg',
		Code: '2190    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'SOUTHBROOM',
		Code: '4277    ',
		Hub: 'DUR',
		Ring: 1,
		OpsHub: 'DUR',
		Dist: 160,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'SOUTHDALE Boxes',
		Code: '2135    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'SOUTHDALE, Johannesburg',
		Code: '2091    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'SOUTHDENE, Blyvooruitsig',
		Code: '2504    ',
		Hub: 'JNB',
		Ring: 2,
		OpsHub: 'JNB',
		Dist: 100,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'SOUTHDENE, Port Elizabeth',
		Code: '6001    ',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'SOUTHERN CROSS, Constantia',
		Code: '7800    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'SOUTHERN HILLS, Retreat',
		Code: '7945    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'SOUTHERNWOOD Boxes',
		Code: '5213    ',
		Hub: 'ELS',
		Ring: 0,
		OpsHub: 'ELS',
		Dist: 10,
		AreaName: 'East London Central',
		Surch: null,
	},
	{
		PlaceName: 'SOUTHERNWOOD, East London',
		Code: '5201    ',
		Hub: 'ELS',
		Ring: 0,
		OpsHub: 'ELS',
		Dist: 10,
		AreaName: 'East London Central',
		Surch: null,
	},
	{
		PlaceName: 'SOUTHERNWOOD, Umtata',
		Code: '5100',
		Hub: 'ELS',
		Ring: 1,
		OpsHub: 'ELS',
		Dist: 180,
		AreaName: 'East London Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'SOUTHFIELD Boxes',
		Code: '7880    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'SOUTHFIELD, Plumstead',
		Code: '7800    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'SOUTHPORT, Anerley',
		Code: '4230    ',
		Hub: 'DUR',
		Ring: 2,
		OpsHub: 'DUR',
		Dist: 120,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'SOUTHPORT, Fish Hoek',
		Code: '7975    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 60,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'SOUTPAN',
		Code: '9356    ',
		Hub: 'BFN',
		Ring: 1,
		OpsHub: 'BFN',
		Dist: 30,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'SOUTRIVIER',
		Code: '7925    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'SOVENGA',
		Code: '0727    ',
		Hub: 'PTG',
		Ring: 4,
		OpsHub: 'PTG',
		Dist: 300,
		AreaName: 'Pietersburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'SOVENGA, Port Shepstone',
		Code: '4240    ',
		Hub: 'DUR',
		Ring: 3,
		OpsHub: 'DUR',
		Dist: 130,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'SOWETO, Johannesburg',
		Code: '2001    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'SPANISH FARM, Somerset West',
		Code: '7130    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 30,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'SPARKS Est/Lgd, Durban',
		Code: '4091',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'SPARTAN, Johannesburg',
		Code: '1619    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'SPEARVILLE, Barberton',
		Code: '1300    ',
		Hub: 'JNB',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 350,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'SPES BONA, Johannesburg',
		Code: '2094    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'SPOORNET HEAD OFFICE,',
		Code: '2134    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'SPOORNET HOOFKANTOOR,',
		Code: '2134    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'SPOORWEGTERREIN, Pretoria',
		Code: '0002    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'SPRINCOL, Vereeniging',
		Code: '1939    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 60,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'SPRINGBOKPARK, Brackenfel',
		Code: '7560    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'SPRINGDALE, Port Elizabeth',
		Code: '6020    ',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'SPRINGFIELD Boxes',
		Code: '2137    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'SPRINGFIELD PARK, Durban North',
		Code: '4051',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'SPRINGFIELD, Durban',
		Code: '4091',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'SPRINGFIELD, Johannesburg',
		Code: '2190    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'SPRINGFIELD, Port Elizabeth',
		Code: '6070',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'SPRINGFONTEIN',
		Code: '9917    ',
		Hub: 'BFN',
		Ring: 4,
		OpsHub: 'BFN',
		Dist: 180,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'SPRINGS Boxes',
		Code: '1560    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'SPRINGS Ext/Uit 3, Springs',
		Code: '1559    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'SPRINGS Ext/Uit, Springs',
		Code: '1559    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'SPRINGS, Johannesburg',
		Code: '1559    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'SPRINGTOWN, Durban',
		Code: '4091',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'SPRINGVALE, Durban North',
		Code: '4051',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'SPRUITVIEW, Katlehong',
		Code: '1832    ',
		Hub: 'JNB',
		Ring: 2,
		OpsHub: 'JNB',
		Dist: 30,
		AreaName: 'Johannesburg Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'SQUARE HILL Boxes',
		Code: '7960    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'SQUARE HILL PARK, Kimberley',
		Code: '8301    ',
		Hub: 'KIM',
		Ring: 0,
		OpsHub: 'KIM',
		Dist: 20,
		AreaName: 'Kimberley Central',
		Surch: null,
	},
	{
		PlaceName: 'SQUARE HILL, Retreat',
		Code: '7945    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: "SQUIRE'S VILLAGE, Retreat",
		Code: '7945    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'ST ALBANS',
		Code: '6385    ',
		Hub: 'PLZ',
		Ring: 1,
		OpsHub: 'PLZ',
		Dist: 80,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'ST ANDREWS, Bedfordview',
		Code: '2008    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: "ST AUGUSTINE'S",
		Code: '3006    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 320,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'ST DUMA, Kuilsriver',
		Code: '7580    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: "ST FAITH'S",
		Code: '4642    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 180,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'ST FRANCIS BAY',
		Code: '6312    ',
		Hub: 'PLZ',
		Ring: 2,
		OpsHub: 'PLZ',
		Dist: 100,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'ST FRANCISBAAI',
		Code: '6312    ',
		Hub: 'PLZ',
		Ring: 2,
		OpsHub: 'PLZ',
		Dist: 100,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'ST HELENA BAY',
		Code: '7390    ',
		Hub: 'CPT',
		Ring: 4,
		OpsHub: 'CPT',
		Dist: 170,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'ST HELENA, Welkom',
		Code: '9459    ',
		Hub: 'WEL',
		Ring: 3,
		OpsHub: 'JNB',
		Dist: 150,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'ST HELENABAAI',
		Code: '7390    ',
		Hub: 'CPT',
		Ring: 4,
		OpsHub: 'CPT',
		Dist: 170,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'ST JAMES Boxes',
		Code: '7946    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'ST JAMES, Muizenberg',
		Code: '7945    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'ST KILDA, Brackenfell',
		Code: '7560    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'ST LUCIA ESTUARY, Mtubatuba',
		Code: '3936    ',
		Hub: 'DUR',
		Ring: 1,
		OpsHub: 'DUR',
		Dist: 300,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: "ST MICHAEL'S ON SEA",
		Code: '4265    ',
		Hub: 'DUR',
		Ring: 3,
		OpsHub: 'DUR',
		Dist: 150,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: "ST MICHAEL'S, Brackenfell",
		Code: '7560    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: "ST WINIFRED'S, Amanzimtot",
		Code: '4126',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'STAAL Boxes',
		Code: '1906    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 60,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'STAAL, Vanderbijlpark',
		Code: '1911    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 60,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'STAALVELD',
		Code: '1043    ',
		Hub: 'JNB',
		Ring: 3,
		OpsHub: 'JNB',
		Dist: 140,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'STAATSDORP, Benoni',
		Code: '1501    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'STAATSDORP, Germiston',
		Code: '1401    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'STAATSDORP, Vereeniging',
		Code: '1939    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 60,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'STAFFORD, Johannesburg',
		Code: '2197    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'STALPLEIN',
		Code: '8015    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'STAMFORD HILL Boxes',
		Code: '4025    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'STAMFORD HILL, Durban',
		Code: '4001    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'STAND Ext/Uit, Krugersdorp',
		Code: '1739    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'STANDFORD',
		Code: '7210    ',
		Hub: 'CPT',
		Ring: 2,
		OpsHub: 'CPT',
		Dist: 110,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'STANFORD',
		Code: '7210    ',
		Hub: 'CPT',
		Ring: 2,
		OpsHub: 'CPT',
		Dist: 110,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'STANGER',
		Code: '4450    ',
		Hub: 'DUR',
		Ring: 1,
		OpsHub: 'DUR',
		Dist: 120,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'STANMORE, Phoenix',
		Code: '4068',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'STARKEVILLE, Stanger',
		Code: '4450    ',
		Hub: 'DUR',
		Ring: 2,
		OpsHub: 'DUR',
		Dist: 120,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'STARWOOD, Phoenix',
		Code: '4068',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'STEADVILLE',
		Code: '3373    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 270,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'STEEL PARK, Vereeniging',
		Code: '1939    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 60,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'STEELEDALE, Johannesburg',
		Code: '2197    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'STEELPOORT',
		Code: '1133    ',
		Hub: 'MPG',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 300,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'STEENBERG Boxes',
		Code: '7947    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'STEENBERG, Cape Town',
		Code: '7945    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'STEENBOKPAN',
		Code: '0533    ',
		Hub: 'PRY',
		Ring: 4,
		OpsHub: 'PRY',
		Dist: 180,
		AreaName: 'Pretoria Regional',
		Surch: null,
	},
	{
		PlaceName: 'STEILTES, Nelspruit',
		Code: '1201',
		Hub: 'NLP',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'STELLA',
		Code: '8650    ',
		Hub: 'VRY',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 450,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'STELLA LONDT PARK, Port Elizabeth',
		Code: '6045    ',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'STELLENBERG, Durbanville',
		Code: '7550    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'STELLENBOSCH',
		Code: '7600    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 60,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'STELLENBOSCH Boxes',
		Code: '7599    ',
		Hub: 'CPT',
		Ring: 1,
		OpsHub: 'CPT',
		Dist: 60,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'STELLENBOSCH, Western Cape',
		Code: '7600',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 60,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'STELLENRIDGE, Bellville',
		Code: '7530    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'STELLENRYK, Durbanville',
		Code: '7550    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'STERKRIVIER',
		Code: '0630    ',
		Hub: 'PRY',
		Ring: 4,
		OpsHub: 'PRY',
		Dist: 300,
		AreaName: 'Pretoria Regional',
		Surch: null,
	},
	{
		PlaceName: 'STERKSPRUIT',
		Code: '9762    ',
		Hub: 'BFN',
		Ring: 1,
		OpsHub: 'BFN',
		Dist: 0,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'STERKSTROOM',
		Code: '5425    ',
		Hub: 'ELS',
		Ring: 4,
		OpsHub: 'ELS',
		Dist: 270,
		AreaName: 'East London Regional',
		Surch: null,
	},
	{
		PlaceName: 'STERPARK, Polokwane',
		Code: '0699',
		Hub: 'PTG',
		Ring: 0,
		OpsHub: 'PTG',
		Dist: 400,
		AreaName: 'Pietersburg Central',
		Surch: null,
	},
	{
		PlaceName: 'STERREWAG, Pretoria',
		Code: '0181',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'STEUNMEKAAR',
		Code: '9392    ',
		Hub: 'BFN',
		Ring: 1,
		OpsHub: 'BFN',
		Dist: 50,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'STEURHOF, Plumstead',
		Code: '7800    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'STEWARDBY, Lichtenburg',
		Code: '2740    ',
		Hub: 'NWP',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 270,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'STEWARDS, Benoni',
		Code: '1501    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'STEYNING',
		Code: '7730    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'STEYNPAN',
		Code: '9464    ',
		Hub: 'WEL',
		Ring: 3,
		OpsHub: 'WEL',
		Dist: 150,
		AreaName: 'Welkom Central',
		Surch: null,
	},
	{
		PlaceName: 'STEYNSBURG',
		Code: '5920    ',
		Hub: 'PLZ',
		Ring: 4,
		OpsHub: 'PLZ',
		Dist: 370,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'STEYNSRUS',
		Code: '9515    ',
		Hub: 'WEL',
		Ring: 3,
		OpsHub: 'JNB',
		Dist: 170,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'STEYNSRUST, Somerset West',
		Code: '7130    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 30,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'STEYNVILLE, Hopetown',
		Code: '8750    ',
		Hub: 'BFN',
		Ring: 4,
		OpsHub: 'BFN',
		Dist: 310,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'STEYTLER Tsp/Dgd, Port Elizabeth',
		Code: '6001    ',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'STEYTLERVILLE',
		Code: '6250    ',
		Hub: 'PLZ',
		Ring: 4,
		OpsHub: 'PLZ',
		Dist: 180,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'STEYTLERVILLE',
		Code: '6250    ',
		Hub: 'PLZ',
		Ring: 4,
		OpsHub: 'PLZ',
		Dist: 180,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'STIKLAND, Bellville',
		Code: '7530    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'STILBAAI',
		Code: '6785    ',
		Hub: 'GRJ',
		Ring: 4,
		OpsHub: 'GRJ',
		Dist: 160,
		AreaName: 'George Regional',
		Surch: null,
	},
	{
		PlaceName: 'STILBAAI-WES, Stilbaai',
		Code: '6785    ',
		Hub: 'GRJ',
		Ring: 4,
		OpsHub: 'GRJ',
		Dist: 160,
		AreaName: 'George Regional',
		Surch: null,
	},
	{
		PlaceName: 'STILFONTEIN',
		Code: '2550    ',
		Hub: 'JNB',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 170,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'STILFONTEIN EAST, Stilfontein',
		Code: '2550    ',
		Hub: 'JNB',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 170,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'STILFONTEIN-00S, Stilfontein',
		Code: '2550    ',
		Hub: 'JNB',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 170,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'STILL BAY',
		Code: '6785    ',
		Hub: 'GRJ',
		Ring: 4,
		OpsHub: 'GRJ',
		Dist: 160,
		AreaName: 'George Regional',
		Surch: null,
	},
	{
		PlaceName: 'STILL BAY WEST, Still Bay',
		Code: '6785    ',
		Hub: 'GRJ',
		Ring: 4,
		OpsHub: 'GRJ',
		Dist: 160,
		AreaName: 'George Regional',
		Surch: null,
	},
	{
		PlaceName: 'STIRLING & Ext/Uit, East London',
		Code: '5241    ',
		Hub: 'ELS',
		Ring: 0,
		OpsHub: 'ELS',
		Dist: 10,
		AreaName: 'East London Central',
		Surch: null,
	},
	{
		PlaceName: 'STOFFBERG',
		Code: '1056    ',
		Hub: 'MBG',
		Ring: 2,
		OpsHub: 'JNB',
		Dist: 140,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'STOMPNEUS BAY',
		Code: '7382    ',
		Hub: 'CPT',
		Ring: 4,
		OpsHub: 'CPT',
		Dist: 170,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'STOMPNEUSBAAI',
		Code: '7382    ',
		Hub: 'CPT',
		Ring: 4,
		OpsHub: 'CPT',
		Dist: 170,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'STONE BRIDGE, Phoenix',
		Code: '4068',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'STONEHILL Est/Lgd, Parowv',
		Code: '7500    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'STONEYDRIFT, East London',
		Code: '5247    ',
		Hub: 'ELS',
		Ring: 0,
		OpsHub: 'ELS',
		Dist: 10,
		AreaName: 'East London Central',
		Surch: null,
	},
	{
		PlaceName: 'STORMBERG',
		Code: '5501    ',
		Hub: 'ELS',
		Ring: 4,
		OpsHub: 'ELS',
		Dist: 330,
		AreaName: 'East London Regional',
		Surch: null,
	},
	{
		PlaceName: 'STORMS RIVER',
		Code: '6308    ',
		Hub: 'PLZ',
		Ring: 4,
		OpsHub: 'PLZ',
		Dist: 210,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'STORMSRIVIER',
		Code: '6308    ',
		Hub: 'PLZ',
		Ring: 4,
		OpsHub: 'PLZ',
		Dist: 210,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'STORMVILLE, Florida',
		Code: '1709    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'STRAND',
		Code: '7140    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 30,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'STRANDFONTEIN Boxes',
		Code: '7788    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'STRANDFONTEIN, Mitchells Plain',
		Code: '7785    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'STRANDFONTEIN, West Coast',
		Code: '8149',
		Hub: 'CPT',
		Ring: 1,
		OpsHub: 'CPT',
		Dist: 300,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'STRATFORD GREEN, Eersteriver',
		Code: '7100    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 15,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'STRATHAVON Ext/Uit 1 - 6,',
		Code: '2031    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'STRATHAVON Ext/Uit 17,18,',
		Code: '2031    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'STRATHAVON Ext/Uit 7, 12',
		Code: '2148    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'STRATHAVON, Bryanston',
		Code: '2031    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'STRAUSSBURG',
		Code: '8816    ',
		Hub: 'BFN',
		Ring: 4,
		OpsHub: 'BFN',
		Dist: 570,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'STRUANDALE, Port Elizabeth',
		Code: '6001    ',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'STRUBENSVALLEI Ext/Uit 1,',
		Code: '1735    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'STRUBENSVALLEI, Roodepoort',
		Code: '1735    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'STRUBENVALE Boxes',
		Code: '1570    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'STRUBENVALE, Springs',
		Code: '1559    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'STRUIS BAY',
		Code: '7285    ',
		Hub: 'CPT',
		Ring: 3,
		OpsHub: 'CPT',
		Dist: 160,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'STRUISBAAI',
		Code: '7285    ',
		Hub: 'CPT',
		Ring: 3,
		OpsHub: 'CPT',
		Dist: 160,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'STRUISBULT, Springs',
		Code: '1559    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'STRYDENBURG',
		Code: '8765    ',
		Hub: 'KIM',
		Ring: 4,
		OpsHub: 'KIM',
		Dist: 330,
		AreaName: 'Kimberley Regional',
		Surch: null,
	},
	{
		PlaceName: 'STRYDMAG',
		Code: '0455    ',
		Hub: 'PRY',
		Ring: 4,
		OpsHub: 'PRY',
		Dist: 180,
		AreaName: 'Pretoria Regional',
		Surch: null,
	},
	{
		PlaceName: 'STRYDOMPARK, Randburg',
		Code: '2194    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'STUART Tsp/Dgd, Port Elizabeth',
		Code: '6001    ',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: "STUART'S HILL, Somerset West",
		Code: '7130    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 30,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'STUDTIS',
		Code: '6686    ',
		Hub: 'GRJ',
		Ring: 4,
		OpsHub: 'GRJ',
		Dist: 200,
		AreaName: 'George Regional',
		Surch: null,
	},
	{
		PlaceName: 'STUIRMANSPAN',
		Code: '9475    ',
		Hub: 'BFN',
		Ring: 3,
		OpsHub: 'BFN',
		Dist: 160,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'STUTTERHEIM',
		Code: '4930    ',
		Hub: 'ELS',
		Ring: 2,
		OpsHub: 'ELS',
		Dist: 90,
		AreaName: 'East London Regional',
		Surch: null,
	},
	{
		PlaceName: 'SUIDEINDE, Port Elizabeth',
		Code: '6001    ',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'SUIDER-PAARL Boxes',
		Code: '7624    ',
		Hub: 'CPT',
		Ring: 1,
		OpsHub: 'CPT',
		Dist: 60,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'SUIDER-PAARL, Paarl',
		Code: '7646    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 60,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'SUIDERBERG, Pretoria',
		Code: '0082    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'SUIDEROORD, Johannesburg',
		Code: '2091    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'SUIDRAND, Kroonstad',
		Code: '9499    ',
		Hub: 'BFN',
		Ring: 3,
		OpsHub: 'BFN',
		Dist: 170,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'SUMMER GREENS, Milnerton',
		Code: '7441    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'SUMMERPRIDE, East London',
		Code: '5247    ',
		Hub: 'ELS',
		Ring: 0,
		OpsHub: 'ELS',
		Dist: 10,
		AreaName: 'East London Central',
		Surch: null,
	},
	{
		PlaceName: 'SUMMERSET, Midrand',
		Code: '1685',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'SUMMERSTRAND, Port Elizabeth',
		Code: '6001    ',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'SUMMERVILLE',
		Code: '6107    ',
		Hub: 'PLZ',
		Ring: 1,
		OpsHub: 'PLZ',
		Dist: 60,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'SUMMERVILLE, Kraaifontein',
		Code: '7570    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'SUMMERWOOD, Port Elizabeth',
		Code: '6001    ',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'SUN CITY',
		Code: '0316',
		Hub: 'JNB',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 130,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'SUN VALLEY, Mamelodi',
		Code: '0122',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'SUN VALLEY, Midrand',
		Code: '1684',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'SUN VALLEY,Fish Hoek',
		Code: '7975    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 60,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'SUNAIR PARK, Brakpan',
		Code: '1549    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'SUNBIRD PARK, Kuilsrivier',
		Code: '7580    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'SUNBIRDPARK, Eersterivier',
		Code: '7100    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 15,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'SUNBURY Est/Lgd, Heathfield',
		Code: '7945    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'SUNDERLANDRIDGE, Centurion',
		Code: '0157    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'SUNDOWNER & Ext/Uit, Randburg',
		Code: '2161    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'SUNDOWNER, Randburg',
		Code: '2161    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'SUNDRA',
		Code: '2200    ',
		Hub: 'MBG',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 50,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'SUNFORD, Phoenix',
		Code: '4068    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'SUNKIST Est/Lgd, Bellville',
		Code: '7530    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'SUNLAND',
		Code: '6115    ',
		Hub: 'PLZ',
		Ring: 1,
		OpsHub: 'PLZ',
		Dist: 60,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'SUNLANDS Est/Lgd, Kenwyn',
		Code: '7780    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'SUNNINGDALE & Ext/Uit 1,',
		Code: '2192    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'SUNNINGDALE PARK, Rivonia',
		Code: '2128    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'SUNNINGDALE RIDGE, Johannesburg',
		Code: '2192    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'SUNNINGDALE VILLAGE, Durban',
		Code: '4051',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'SUNNINGDALE, Cape Town',
		Code: '7441',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'SUNNINGDALE, Durban',
		Code: '4051',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'SUNNINGDALE, Table view',
		Code: '7441',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'SUNNINGDALERIF, Johannesburg',
		Code: '2192    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'SUNNINGHILL Boxes',
		Code: '2157    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'SUNNINGHILL Ext/Uit 4,',
		Code: '2157    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'SUNNINGHILL, Johannesburg',
		Code: '2157    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'SUNNY COVE, Fish Hoek',
		Code: '7975    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 60,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'SUNNYBRAE, Newlands',
		Code: '7700    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'SUNNYDALE, Eshowe',
		Code: '3815    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 200,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'SUNNYDALE, Fish Hoek',
		Code: '7975    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 60,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'SUNNYPARK, Amanzimtoti',
		Code: '4126',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'SUNNYRIDGE, East London',
		Code: '5201    ',
		Hub: 'ELS',
		Ring: 0,
		OpsHub: 'ELS',
		Dist: 10,
		AreaName: 'East London Central',
		Surch: null,
	},
	{
		PlaceName: 'SUNNYRIDGE, Newcastle',
		Code: '2953    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 330,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'SUNNYRIDGE, Primrose',
		Code: '1401    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'SUNNYROCK, Primrose',
		Code: '1401    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'SUNNYSIDE, Athlone',
		Code: '7764    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'SUNNYSIDE, Graaff-Reinet',
		Code: '6280    ',
		Hub: 'PLZ',
		Ring: 4,
		OpsHub: 'PLZ',
		Dist: 290,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'SUNNYSIDE, Johannesburg',
		Code: '2092    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'SUNNYSIDE, Parys',
		Code: '9585    ',
		Hub: 'JNB',
		Ring: 3,
		OpsHub: 'JNB',
		Dist: 130,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'SUNNYSIDE, Pietermaritzburg',
		Code: '3201    ',
		Hub: 'PMB',
		Ring: 1,
		OpsHub: 'PMB',
		Dist: 75,
		AreaName: 'Pietermaritzburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'SUNNYSIDE, Pretoria',
		Code: '0002    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'SUNRAY Est/Lgd, Bellville',
		Code: '7530    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'SUNRIDGE Est/Lgd, Table View',
		Code: '7441    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'SUNRIDGE PARK Boxes',
		Code: '6008    ',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'SUNRIDGE PARK, Port Elizabeth',
		Code: '6045    ',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'SUNRIDGE, Knysna',
		Code: '6571',
		Hub: 'GRJ',
		Ring: 1,
		OpsHub: 'GRJ',
		Dist: 80,
		AreaName: 'George Regional',
		Surch: null,
	},
	{
		PlaceName: 'SUNRISE ON SEA',
		Code: '5200',
		Hub: 'ELS',
		Ring: 3,
		OpsHub: 'ELS',
		Dist: 100,
		AreaName: 'East London Regional',
		Surch: null,
	},
	{
		PlaceName: 'SUNRISE VIEW, Katlehong',
		Code: '1832    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 30,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'SUNSET ACRES, Johannesburg',
		Code: '2196    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'SUNSET BEACH,Milnerton',
		Code: '7441',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'SUNVALLEY, Pimville',
		Code: '1808    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 50,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'SUNWARD PARK & Ext/Uit 1,',
		Code: '1459    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'SUNWARD PARK Boxes',
		Code: '1470    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'SUNWICH PORT, Anerley',
		Code: '4230    ',
		Hub: 'DUR',
		Ring: 2,
		OpsHub: 'DUR',
		Dist: 120,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'SUPERBIA Ext/Uit 20,',
		Code: '0699',
		Hub: 'PTG',
		Ring: 0,
		OpsHub: 'PTG',
		Dist: 400,
		AreaName: 'Pietersburg Central',
		Surch: null,
	},
	{
		PlaceName: 'SURPRISE FARM, Pinetown',
		Code: '3610',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'SURREY Est/Lgd, Athlone',
		Code: '7764    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'SURREYSIDE, Athlone',
		Code: '7764    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'SURWELL',
		Code: '7762    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'SUSWE',
		Code: '0612    ',
		Hub: 'PTG',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 300,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'SUTHERLAND',
		Code: '6920    ',
		Hub: 'GRJ',
		Ring: 4,
		OpsHub: 'GRJ',
		Dist: 350,
		AreaName: 'George Regional',
		Surch: 'Far Outlying',
	},
	{
		PlaceName: 'SUURBEKOM',
		Code: '1787    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 50,
		AreaName: 'Johannesburg Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'SUURBRAAK',
		Code: '6743    ',
		Hub: 'CPT',
		Ring: 4,
		OpsHub: 'CPT',
		Dist: 250,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'SUURGOUD, Lekkerplaas',
		Code: '1784    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 50,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'SWALESTOWN, Isipingo',
		Code: '4133',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'SWANEVILLE',
		Code: '1753',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 30,
		AreaName: 'Johannesburg Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'SWART-MFOLOZI',
		Code: '3115    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 250,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'SWARTBERG',
		Code: '4710    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 230,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'SWARTKLIP',
		Code: '0370    ',
		Hub: 'RBG',
		Ring: 2,
		OpsHub: 'JNB',
		Dist: 120,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'SWARTKLIP, Mitchells Plain',
		Code: '7785    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'SWARTKOPS',
		Code: '6210',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'SWARTKOPSExt/Uit 2, 4, 7',
		Code: '6210    ',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'SWARTKOPS Verwoerdburg',
		Code: '0157    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'SWARTRUGGENS',
		Code: '2835    ',
		Hub: 'RBG',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 210,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'SWARTWATER',
		Code: '0622    ',
		Hub: 'PRY',
		Ring: 4,
		OpsHub: 'PRY',
		Dist: 300,
		AreaName: 'Pretoria Regional',
		Surch: 'Far Outlying',
	},
	{
		PlaceName: 'SWEET VALLEY, Bergvliet',
		Code: '7945    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'SWEET WATERS, Walkerville',
		Code: '1876    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 30,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'SWELLENDAM',
		Code: '6740    ',
		Hub: 'CPT',
		Ring: 2,
		OpsHub: 'CPT',
		Dist: 230,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'SWINBURNE',
		Code: '3395    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 270,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'SYBRANDPARK, Rondebosch',
		Code: '7700    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'SYDENHAM EAST, Johannesburg',
		Code: '2192    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'SYDENHAM, Durban',
		Code: '4091',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'SYDENHAM, Johannesburg',
		Code: '2192    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'SYDENHAM, Port Elizabeth',
		Code: '6001    ',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'SYDENHAM-OOS, Johannesburg',
		Code: '2192    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'SYDNEYVALE, Lavistown',
		Code: '7490    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'SYFERBULT',
		Code: '2806    ',
		Hub: 'RBG',
		Ring: 2,
		OpsHub: 'JNB',
		Dist: 90,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'SYMHURST, Primrose',
		Code: '1401    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'SYMRIDGE Boxes',
		Code: '1420    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'SYMRIDGE, Germiston',
		Code: '1401    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'TABANKULU',
		Code: '5130',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 250,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'TABLE BAY HARBOUR, Cape Town',
		Code: '8001',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'TABLE VIEW Boxes',
		Code: '7439    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'TABLE VIEW, Cape Town',
		Code: '7441    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'TADCASTER',
		Code: '8560    ',
		Hub: 'BFN',
		Ring: 4,
		OpsHub: 'BFN',
		Dist: 230,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'TAFELBAAIHAWE, Kaapstad',
		Code: '8001',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'TAFELSIG, Mitchells Plain',
		Code: '7785    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'TALA VALLEY',
		Code: '3720',
		Hub: 'DUR',
		Ring: 1,
		OpsHub: 'DUR',
		Dist: 50,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'TALBOTON, Johannesburg',
		Code: '2192    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'TALBOTPARK, Boksburg',
		Code: '1473    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'TAMARA PARK, Verwoerdburg',
		Code: '0157    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'TAMBOERSKLOOF Boxes',
		Code: '8016    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'TAMBOERSKLOOF, Cape Town',
		Code: '8001',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'TAMSUI INDUSTRIA, George',
		Code: '6529    ',
		Hub: 'GRJ',
		Ring: 0,
		OpsHub: 'GRJ',
		Dist: 10,
		AreaName: 'George Central',
		Surch: null,
	},
	{
		PlaceName: 'TARA, Durbanville',
		Code: '7550    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'TARKASTAD',
		Code: '5370    ',
		Hub: 'ELS',
		Ring: 4,
		OpsHub: 'ELS',
		Dist: 270,
		AreaName: 'East London Regional',
		Surch: null,
	},
	{
		PlaceName: 'TARLTON,Krugersdorp',
		Code: '1739',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'TASBET PARK, Witbank',
		Code: '1040    ',
		Hub: 'JNB',
		Ring: 3,
		OpsHub: 'JNB',
		Dist: 140,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'TAUNG',
		Code: '8584    ',
		Hub: 'VRY',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 250,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'TAYBANK, Port Elizabeth',
		Code: '6025',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'TAYLORSHAM, Johannesburg',
		Code: '2197    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'TECHNIKON, Roodepoort',
		Code: '1724    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'TECOMA',
		Code: '5214    ',
		Hub: 'ELS',
		Ring: 0,
		OpsHub: 'ELS',
		Dist: 10,
		AreaName: 'East London Central',
		Surch: null,
	},
	{
		PlaceName: 'TEDSTONEVILLE, Elsburg',
		Code: '1407    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'TEMBA',
		Code: '0407',
		Hub: 'PRY',
		Ring: 3,
		OpsHub: 'JNB',
		Dist: 100,
		AreaName: 'Johannesburg Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'TEMBISA',
		Code: '1628    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'TEMBISA,Johannesburg',
		Code: '1632',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'TEMPE Boxes',
		Code: '9318    ',
		Hub: 'BFN',
		Ring: 0,
		OpsHub: 'BFN',
		Dist: 30,
		AreaName: 'Bloemfontein Central',
		Surch: null,
	},
	{
		PlaceName: 'TEMPE, Bloemfontein',
		Code: '9301',
		Hub: 'BFN',
		Ring: 0,
		OpsHub: 'BFN',
		Dist: 30,
		AreaName: 'Bloemfontein Central',
		Surch: null,
	},
	{
		PlaceName: 'TENNANTVILLE, Stellenbosch',
		Code: '7600    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 60,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'TERENURE, Kempton Park',
		Code: '1619    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'TERMINUS STREET, EAST LONDON',
		Code: '5215    ',
		Hub: 'ELS',
		Ring: 0,
		OpsHub: 'ELS',
		Dist: 10,
		AreaName: 'East London Central',
		Surch: null,
	},
	{
		PlaceName: 'TERMINUSSTRAAT, OOS-LONDEN',
		Code: '5215    ',
		Hub: 'ELS',
		Ring: 0,
		OpsHub: 'ELS',
		Dist: 10,
		AreaName: 'East London Central',
		Surch: null,
	},
	{
		PlaceName: 'THABA NCHU',
		Code: '9780',
		Hub: 'BFN',
		Ring: 2,
		OpsHub: 'BFN',
		Dist: 60,
		AreaName: 'Bloemfontein Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'THABA PHATSHWA',
		Code: '9771    ',
		Hub: 'BFN',
		Ring: 3,
		OpsHub: 'BFN',
		Dist: 130,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'THABA TSWANA',
		Code: '0401    ',
		Hub: 'PRY',
		Ring: 1,
		OpsHub: 'PRY',
		Dist: 50,
		AreaName: 'Pretoria Regional',
		Surch: null,
	},
	{
		PlaceName: 'THABANENG,Soweto',
		Code: '1807',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 50,
		AreaName: 'Johannesburg Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'THABAZIMBI',
		Code: '0380    ',
		Hub: 'PRY',
		Ring: 2,
		OpsHub: 'PRY',
		Dist: 330,
		AreaName: 'Pretoria Regional',
		Surch: null,
	},
	{
		PlaceName: 'THABONG, Motsethabong',
		Code: '9463    ',
		Hub: 'WEL',
		Ring: 3,
		OpsHub: 'JNB',
		Dist: 150,
		AreaName: 'Johannesburg Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'THAFAMASI',
		Code: '4343    ',
		Hub: 'DUR',
		Ring: 1,
		OpsHub: 'DUR',
		Dist: 80,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'THALMAN Est/Lgd, Bellville',
		Code: '7530    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'THE ACRES, Goodwood',
		Code: '7460    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'THE BOULDERS, SimonTown',
		Code: '7995    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 70,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'THE CRAGS',
		Code: '6602    ',
		Hub: 'GRJ',
		Ring: 2,
		OpsHub: 'GRJ',
		Dist: 110,
		AreaName: 'George Regional',
		Surch: null,
	},
	{
		PlaceName: 'THE CREST,Durbanville        ',
		Code: '7550',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'THE DOWNS, Manenberg',
		Code: '7764    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'THE DUNCAN VILLAGE Boxes',
		Code: '5216    ',
		Hub: 'ELS',
		Ring: 0,
		OpsHub: 'ELS',
		Dist: 10,
		AreaName: 'East London Central',
		Surch: null,
	},
	{
		PlaceName: 'THE DUNCAN VILLAGE, East',
		Code: '5209    ',
		Hub: 'ELS',
		Ring: 0,
		OpsHub: 'ELS',
		Dist: 10,
		AreaName: 'East London Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'THE HEADS, Knysna',
		Code: '6571',
		Hub: 'GRJ',
		Ring: 1,
		OpsHub: 'GRJ',
		Dist: 80,
		AreaName: 'George Regional',
		Surch: null,
	},
	{
		PlaceName: 'THE HILL, Johannesburg',
		Code: '2197    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'THE HILLS, Durbanville',
		Code: '7550    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'THE LEAGUES, Mitchells Plain',
		Code: '7785    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'THE OAKS, Chatsworth',
		Code: '4092',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'THE ORCHARDS, Rosslyn',
		Code: '0200    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'THE PLANETS, Benoni',
		Code: '1501    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'THE RANGE, Elsiesriver',
		Code: '7490    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'THE REEDS Ext/Uit 9, Verwoerdburg',
		Code: '0158    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'THE REEDS, Verwoerdburg',
		Code: '0158    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'THE RIDGE, Durbanville',
		Code: '7550    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'THE RIDGE, Stellenbosch',
		Code: '7600    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 60,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'THE SHIRES, Retreat',
		Code: '7945    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'THE STEWARDS, Benoni',
		Code: '1501    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'THE VILLAGE, Kraaifontein',
		Code: '7570    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'THE VINES Est/Lgd, Constantia',
		Code: '7800    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'THE WILGERS, Pretoria',
		Code: '0184',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'THE WILLOWS 340-JR, Pretoria',
		Code: '0184',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'THE WOLDS, Pinetown',
		Code: '3610',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'THEESCOMBE, Port Elizabeth',
		Code: '6070',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'THEMBALETHU, George',
		Code: '6529',
		Hub: 'GRJ',
		Ring: 0,
		OpsHub: 'GRJ',
		Dist: 10,
		AreaName: 'George Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'THEMBALETHU,George',
		Code: '6529',
		Hub: 'GRJ',
		Ring: 0,
		OpsHub: 'GRJ',
		Dist: 10,
		AreaName: 'George Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'THEMBISA, Middelburg',
		Code: '1050    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 140,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'THEODON',
		Code: '2402    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 80,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'THERESAPARK Ext/Uit 1 & 2',
		Code: '0155    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'THERESAPARK, Pretoria',
		Code: '0155    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'THERONVILLE',
		Code: '9702    ',
		Hub: 'BFN',
		Ring: 4,
		OpsHub: 'BFN',
		Dist: 230,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'THETA, Johannesburg',
		Code: '2013    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'THEUNISSEN',
		Code: '9410    ',
		Hub: 'WEL',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 55,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'THLABANE',
		Code: '0305    ',
		Hub: 'JNB',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 130,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'THOHOYANDOU',
		Code: '0999    ',
		Hub: 'PTG',
		Ring: 4,
		OpsHub: 'PTG',
		Dist: 400,
		AreaName: 'Pietersburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'THOKOZA',
		Code: '1426',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'THOLONGWE',
		Code: '0734    ',
		Hub: 'PTG',
		Ring: 4,
		OpsHub: 'PTG',
		Dist: 300,
		AreaName: 'Pietersburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'THOMAS RIVER',
		Code: '5311    ',
		Hub: 'ELS',
		Ring: 3,
		OpsHub: 'ELS',
		Dist: 140,
		AreaName: 'East London Regional',
		Surch: null,
	},
	{
		PlaceName: 'THOMASRIVIER',
		Code: '5311    ',
		Hub: 'ELS',
		Ring: 3,
		OpsHub: 'ELS',
		Dist: 140,
		AreaName: 'East London Regional',
		Surch: null,
	},
	{
		PlaceName: 'THOMASVILLE Est/Lgd, Rugby',
		Code: '7405    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'THORNHAM',
		Code: '6307    ',
		Hub: 'PLZ',
		Ring: 4,
		OpsHub: 'PLZ',
		Dist: 180,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'THORNHILL Est/Lgd, Athlone',
		Code: '7764    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'THORNHILL, Eastern Cape',
		Code: '6375    ',
		Hub: 'PLZ',
		Ring: 1,
		OpsHub: 'PLZ',
		Dist: 60,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'THORNHILL, Modderfontein',
		Code: '1645    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'THORNTON Boxes',
		Code: '7485    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'THORNTON, Cape Town',
		Code: '7460    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'THORNVILLE',
		Code: '3760    ',
		Hub: 'DUR',
		Ring: 1,
		OpsHub: 'DUR',
		Dist: 60,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'THREE ANCHOR BAY, Cape Town',
		Code: '8005',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'THREE RIVERS & Ext 1,',
		Code: '1939    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 60,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'THREE RIVERS Boxes',
		Code: '1935    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 60,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'THREE RIVERS EAST,',
		Code: '1941    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 60,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'THULAMAHASHE',
		Code: '1365    ',
		Hub: 'JNB',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 310,
		AreaName: 'Johannesburg Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'THULINI',
		Code: '3563    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 200,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'TIERFONTEIN',
		Code: '9681    ',
		Hub: 'BFN',
		Ring: 1,
		OpsHub: 'BFN',
		Dist: 35,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'TIERPOORT, Pretoria East',
		Code: '0056',
		Hub: 'PRY',
		Ring: 1,
		OpsHub: 'PRY',
		Dist: 40,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'TIGANE, Hartbeesfontein',
		Code: '2600    ',
		Hub: 'JNB',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 200,
		AreaName: 'Johannesburg Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'TILEBA, Pretoria-Noord',
		Code: '0182    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'TIMOUR HALL Est/Lgd, Plumstead',
		Code: '7800    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'TINMYNE',
		Code: '0603    ',
		Hub: 'PTG',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 300,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'TLADI, kwaXuma',
		Code: '1868    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 30,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'TLHABANE',
		Code: '0299    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 120,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'TLHABANE, Rustenburg',
		Code: '0300    ',
		Hub: 'JNB',
		Ring: 2,
		OpsHub: 'JNB',
		Dist: 120,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'TOEKOMSRUS Boxes',
		Code: '1765    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'TOEKOMSRUS, Oudtshoorn',
		Code: '6620    ',
		Hub: 'GRJ',
		Ring: 1,
		OpsHub: 'GRJ',
		Dist: 70,
		AreaName: 'George Regional',
		Surch: null,
	},
	{
		PlaceName: 'TOEKOMSRUS, Randfontein',
		Code: '1760    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'TOKAI Boxes',
		Code: '7966    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'TOKAI, Cape Town',
		Code: '7945    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'TOKOZA',
		Code: '1426',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 60,
		AreaName: 'Johannesburg Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'TOLWE',
		Code: '0690    ',
		Hub: 'PRY',
		Ring: 4,
		OpsHub: 'PRY',
		Dist: 300,
		AreaName: 'Pretoria Regional',
		Surch: null,
	},
	{
		PlaceName: 'TOM BURKE',
		Code: '0621    ',
		Hub: 'PRY',
		Ring: 4,
		OpsHub: 'PRY',
		Dist: 300,
		AreaName: 'Pretoria Regional',
		Surch: null,
	},
	{
		PlaceName: 'TONETTI',
		Code: '1303    ',
		Hub: 'JNB',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 310,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'TONETTI Ext/Uit 13, Tonet',
		Code: '1303    ',
		Hub: 'JNB',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 310,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'TONGA, Mpumalanga',
		Code: '1224',
		Hub: 'NLP',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 100,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'TONGAAT',
		Code: '4399',
		Hub: 'DUR',
		Ring: 1,
		OpsHub: 'DUR',
		Dist: 70,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'TONTELDOOS',
		Code: '1111    ',
		Hub: 'JNB',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 260,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'TORONGO Est/Lgd, Milnerton',
		Code: '7441    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'TOSCA',
		Code: '8618    ',
		Hub: 'VRY',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 400,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'TOTI HEIGHTS, Amanzimtoi',
		Code: '4126',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'TOUWS RIVER',
		Code: '6880    ',
		Hub: 'CPT',
		Ring: 4,
		OpsHub: 'CPT',
		Dist: 200,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'TOUWSRIVIER',
		Code: '6880    ',
		Hub: 'CPT',
		Ring: 4,
		OpsHub: 'CPT',
		Dist: 200,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'TOWERBY, Johannesburg',
		Code: '2190    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'TOWN BUSH VALLEY, Pietermaritzburg',
		Code: '3201    ',
		Hub: 'PMB',
		Ring: 1,
		OpsHub: 'PMB',
		Dist: 75,
		AreaName: 'Pietermaritzburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'TOWN VALLEY, Pietermaritzburg',
		Code: '3201    ',
		Hub: 'PMB',
		Ring: 1,
		OpsHub: 'PMB',
		Dist: 75,
		AreaName: 'Pietermaritzburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'TOWNSVIEW, Johannesburg',
		Code: '2190    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: "TRAPPE'S VALLEY",
		Code: '6147    ',
		Hub: 'PLZ',
		Ring: 4,
		OpsHub: 'PLZ',
		Dist: 190,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'TRAWAL',
		Code: '8147    ',
		Hub: 'CPT',
		Ring: 4,
		OpsHub: 'CPT',
		Dist: 290,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'TRAWALA, Plumstead',
		Code: '7800    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'TREEHAVEN, Port Elizabeth',
		Code: '6025',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'TREKKER Boxes',
		Code: '1547    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'TREKKER, Brakpan',
		Code: '1540    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'TRELAWNEY, Plumstead',
		Code: '7800    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'TRENANCE PARK, Verulam',
		Code: '4340    ',
		Hub: 'DUR',
		Ring: 1,
		OpsHub: 'DUR',
		Dist: 50,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'TRES JOLIE, Honeydew',
		Code: '2040    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'TREVES',
		Code: '0618    ',
		Hub: 'PTG',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 300,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'TREVOLEN, Port Elizabeth',
		Code: '6070',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'TRIANGLE FARM, Bellville',
		Code: '7530    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'TRICHARDTSDAL',
		Code: '0890    ',
		Hub: 'PTG',
		Ring: 4,
		OpsHub: 'PTG',
		Dist: 300,
		AreaName: 'Pietersburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'TRIOMF, Johannesburg',
		Code: '2092    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'TROJAN, Johannesburg',
		Code: '2190    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'TROMPSBURG',
		Code: '9913    ',
		Hub: 'BFN',
		Ring: 3,
		OpsHub: 'BFN',
		Dist: 150,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'TROVATO, Rondebosch',
		Code: '7700    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'TROYEVILLE Boxes',
		Code: '2139    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'TROYEVILLE, Johannesburg',
		Code: '2094    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'TROYEVILLE, kwaNobuhle',
		Code: '6242    ',
		Hub: 'PLZ',
		Ring: 1,
		OpsHub: 'PLZ',
		Dist: 60,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'TSAKANE',
		Code: '1548    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'TSATE',
		Code: '1121    ',
		Hub: 'JNB',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 260,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'TSHAKGOLO',
		Code: '9867    ',
		Hub: 'BFN',
		Ring: 4,
		OpsHub: 'BFN',
		Dist: 270,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'TSHAKHUMA',
		Code: '0951',
		Hub: 'PTG',
		Ring: 1,
		OpsHub: 'PRY',
		Dist: 170,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'TSHIAWELO & Ext/Uit, Johannesburg',
		Code: '1818    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 30,
		AreaName: 'Johannesburg Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'TSHIAWELO Boxes',
		Code: '1817    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 30,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'TSHIAWELO Streets',
		Code: '1818    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 30,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'TSHING, Ventersdorp',
		Code: '2710    ',
		Hub: 'JNB',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 170,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'TSHIPISE',
		Code: '0901    ',
		Hub: 'PTG',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 500,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'TSIMANYANE',
		Code: '0453    ',
		Hub: 'PRY',
		Ring: 4,
		OpsHub: 'PRY',
		Dist: 180,
		AreaName: 'Pretoria Regional',
		Surch: null,
	},
	{
		PlaceName: 'TSITSIKAMMA',
		Code: '6309    ',
		Hub: 'PLZ',
		Ring: 4,
		OpsHub: 'PLZ',
		Dist: 240,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'TSOLO',
		Code: '5170    ',
		Hub: 'ELS',
		Ring: 1,
		OpsHub: 'ELS',
		Dist: 260,
		AreaName: 'East London Regional',
		Surch: null,
	},
	{
		PlaceName: 'TSWELELANG,Wolmaransstad',
		Code: '2630',
		Hub: 'JNB',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 320,
		AreaName: 'Johannesburg Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'TUGELA',
		Code: '4451    ',
		Hub: 'DUR',
		Ring: 3,
		OpsHub: 'DUR',
		Dist: 140,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'TUGELA FERRY',
		Code: '3504    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 200,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'TUINE',
		Code: '8001',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'TUINHOF, Kroonstad',
		Code: '9499    ',
		Hub: 'BFN',
		Ring: 3,
		OpsHub: 'BFN',
		Dist: 170,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'TUINPLAAS',
		Code: '0437    ',
		Hub: 'PRY',
		Ring: 4,
		OpsHub: 'PRY',
		Dist: 180,
		AreaName: 'Pretoria Regional',
		Surch: null,
	},
	{
		PlaceName: 'TULBAGH, Port Elizabeth',
		Code: '6025',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'TULBAGH, WESTERN CAPE',
		Code: '6820    ',
		Hub: 'CPT',
		Ring: 2,
		OpsHub: 'CPT',
		Dist: 100,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'TULISA PARK & Ext/Uit 7,',
		Code: '2197    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'TUMAHOLE, Parys',
		Code: '9585    ',
		Hub: 'JNB',
		Ring: 3,
		OpsHub: 'JNB',
		Dist: 130,
		AreaName: 'Johannesburg Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'TURF CLUB, Johannesburg',
		Code: '2190    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'TURFFONTEIN Boxes',
		Code: '2140    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'TURFFONTEIN WEST, Johannesburg',
		Code: '2190    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'TURFFONTEIN, Johannesburg',
		Code: '2190    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'TURFFONTEIN-WES, Johannesburg',
		Code: '2190    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'TURFHALL Est/Lgd, Lansdowne',
		Code: '7780    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'TUSSENDAL, Bergvliet',
		Code: '7945    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'TWEE RIVIEREN, Geogr',
		Code: '6529',
		Hub: 'GRJ',
		Ring: 0,
		OpsHub: 'GRJ',
		Dist: 10,
		AreaName: 'George Central',
		Surch: null,
	},
	{
		PlaceName: 'TWEEDEMYN Boxes',
		Code: '9467    ',
		Hub: 'BFN',
		Ring: 3,
		OpsHub: 'BFN',
		Dist: 150,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'TWEEDEMYN, Welkom',
		Code: '9459    ',
		Hub: 'WEL',
		Ring: 3,
		OpsHub: 'JNB',
		Dist: 150,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'TWEEDIE',
		Code: '3255    ',
		Hub: 'DUR',
		Ring: 2,
		OpsHub: 'DUR',
		Dist: 100,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'TWEEFONTEIN, Coalville',
		Code: '1033    ',
		Hub: 'MBG',
		Ring: 3,
		OpsHub: 'JNB',
		Dist: 140,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'TWEESPRUIT',
		Code: '9770    ',
		Hub: 'BFN',
		Ring: 3,
		OpsHub: 'BFN',
		Dist: 130,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'TWENTY-FOUR RIVERS',
		Code: '0542    ',
		Hub: 'PRY',
		Ring: 4,
		OpsHub: 'PRY',
		Dist: 180,
		AreaName: 'Pretoria Regional',
		Surch: null,
	},
	{
		PlaceName: 'TYGER VALLEY, Bellville',
		Code: '7530    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'TYGER VALLEY, Pretoria',
		Code: '0084',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'TYGER WATERFRONT, Bellville',
		Code: '7530',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'TYGERBERG Boxes',
		Code: '7505    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'TYGERBERG HILLS, Bellville',
		Code: '7530    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'TYGERBERG, Parow',
		Code: '7500    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'TYGERDAL Boxes',
		Code: '7462    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'TYGERDAL, Goodwood',
		Code: '7460    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'TYGERHOF Estate',
		Code: '7441    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'TYGERPARK',
		Code: '7536    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'TYGERVALLEI, Bellville',
		Code: '7530    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'TYLDEN',
		Code: '5323    ',
		Hub: 'ELS',
		Ring: 4,
		OpsHub: 'ELS',
		Dist: 180,
		AreaName: 'East London Regional',
		Surch: null,
	},
	{
		PlaceName: 'TYOLORHA, George',
		Code: '6540    ',
		Hub: 'GRJ',
		Ring: 0,
		OpsHub: 'GRJ',
		Dist: 10,
		AreaName: 'George Central',
		Surch: null,
	},
	{
		PlaceName: 'TZANEEN',
		Code: '0850',
		Hub: 'PTG',
		Ring: 4,
		OpsHub: 'PTG',
		Dist: 350,
		AreaName: 'Pietersburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'TZANEEN (JNB)',
		Code: '0850',
		Hub: 'PTG',
		Ring: 1,
		OpsHub: 'PTG',
		Dist: 350,
		AreaName: 'Pietersburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'TZANEEN (TT)',
		Code: '0850',
		Hub: 'PTG',
		Ring: 1,
		OpsHub: 'PTG',
		Dist: 350,
		AreaName: 'Pietersburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'UBOMBO',
		Code: '3970    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 380,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'UGIE',
		Code: '5470    ',
		Hub: 'ELS',
		Ring: 4,
		OpsHub: 'ELS',
		Dist: 280,
		AreaName: 'East London Regional',
		Surch: null,
	},
	{
		PlaceName: 'UITENHAGE',
		Code: '6230    ',
		Hub: 'PLZ',
		Ring: 1,
		OpsHub: 'PLZ',
		Dist: 50,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'UITSIG, Bloemfontein',
		Code: '9301',
		Hub: 'BFN',
		Ring: 0,
		OpsHub: 'BFN',
		Dist: 30,
		AreaName: 'Bloemfontein Central',
		Surch: null,
	},
	{
		PlaceName: 'UITSIG, Kroonstad',
		Code: '9499    ',
		Hub: 'BFN',
		Ring: 3,
		OpsHub: 'BFN',
		Dist: 170,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'UITSIG, Mancorp Mine',
		Code: '8423    ',
		Hub: 'BFN',
		Ring: 4,
		OpsHub: 'BFN',
		Dist: 570,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'UITSIG, Ravensmead',
		Code: '7490    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'UITSIG, Wellington',
		Code: '7655    ',
		Hub: 'CPT',
		Ring: 2,
		OpsHub: 'CPT',
		Dist: 90,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'UITZICHT, Durbanville',
		Code: '7570    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 30,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'ULCO',
		Code: '8390    ',
		Hub: 'KIM',
		Ring: 4,
		OpsHub: 'KIM',
		Dist: 310,
		AreaName: 'Kimberley Regional',
		Surch: null,
	},
	{
		PlaceName: 'ULUNDI',
		Code: '3838    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 250,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'UMBILO Boxes',
		Code: '4075    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'UMBILO, Durban',
		Code: '4001    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'UMBOGINTWINI, KwaZuluNatal',
		Code: '4126',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'UMBUMBULU',
		Code: '4105    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'UMDLOTI BEACH',
		Code: '4350    ',
		Hub: 'DUR',
		Ring: 1,
		OpsHub: 'DUR',
		Dist: 50,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'UMGABABA, AMANZIMTOTI',
		Code: '4126',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'UMGENI Est/Lgd, Howick',
		Code: '3290    ',
		Hub: 'DUR',
		Ring: 3,
		OpsHub: 'DUR',
		Dist: 150,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'UMGENI HEIGHTS, Durban North',
		Code: '4051',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'UMGENI PARK, Durban North',
		Code: '4051',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'UMGENI, Durban',
		Code: '4001    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'UMGENIHOOGTE, Durban-Noord',
		Code: '4051',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'UMHLALI',
		Code: '4390    ',
		Hub: 'DUR',
		Ring: 1,
		OpsHub: 'DUR',
		Dist: 90,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'UMHLANGA RIDGE, Durban',
		Code: '4319',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 50,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'UMHLANGA ROCKS, Durban',
		Code: '4320    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 50,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'UMHLANGA, Durban',
		Code: '4320    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 50,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'UMHLATHUZE',
		Code: '3880',
		Hub: 'DUR',
		Ring: 1,
		OpsHub: 'DUR',
		Dist: 180,
		AreaName: 'Durban Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'UMHLATUZANA, Chatsworth',
		Code: '4092',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'UMKOMAAS',
		Code: '4171',
		Hub: 'DUR',
		Ring: 1,
		OpsHub: 'DUR',
		Dist: 70,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'UMLAAS ROAD',
		Code: '3730    ',
		Hub: 'DUR',
		Ring: 1,
		OpsHub: 'DUR',
		Dist: 40,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'UMLAASWEG',
		Code: '3730    ',
		Hub: 'DUR',
		Ring: 1,
		OpsHub: 'DUR',
		Dist: 40,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'UMLAZI Boxes',
		Code: '4031    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'UMLAZI, Durban',
		Code: '4066    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'UMTATA',
		Code: '5100',
		Hub: 'ELS',
		Ring: 1,
		OpsHub: 'ELS',
		Dist: 180,
		AreaName: 'East London Regional',
		Surch: null,
	},
	{
		PlaceName: 'UMTENTWENI',
		Code: '4235    ',
		Hub: 'DUR',
		Ring: 2,
		OpsHub: 'DUR',
		Dist: 120,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'UMVOTIKLOOF',
		Code: '3510    ',
		Hub: 'DUR',
		Ring: 3,
		OpsHub: 'DUR',
		Dist: 150,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'UMZIMKULU',
		Code: '4240',
		Hub: 'DUR',
		Ring: 2,
		OpsHub: 'DUR',
		Dist: 100,
		AreaName: 'Durban Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'UMZINTO',
		Code: '4200    ',
		Hub: 'DUR',
		Ring: 2,
		OpsHub: 'DUR',
		Dist: 70,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'UMZINTO/STA',
		Code: '4201    ',
		Hub: 'DUR',
		Ring: 2,
		OpsHub: 'DUR',
		Dist: 70,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'UMZUMBE',
		Code: '4225    ',
		Hub: 'DUR',
		Ring: 2,
		OpsHub: 'DUR',
		Dist: 110,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'UNDERBERG',
		Code: '4590    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 200,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'UNIBELL',
		Code: '7539    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'UNIEDAL Boxes',
		Code: '7612    ',
		Hub: 'CPT',
		Ring: 1,
		OpsHub: 'CPT',
		Dist: 60,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'UNIEDAL, Stellenbosch',
		Code: '7600    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 60,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'UNIEGEBOU, PRETORIA',
		Code: '0136    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'UNIEPARK, Stellenbosch',
		Code: '7600    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 60,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'UNIFIED Boxes',
		Code: '1713    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'UNIFIED, Florida',
		Code: '1709    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'UNIGRAY, Johannesburg',
		Code: '2197    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'UNION BUILDINGS, PRETORIA',
		Code: '0136    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'UNION SETTLEMENTS, Germiston',
		Code: '1401    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'UNION Tsp/Dgd, Germiston',
		Code: '1401    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'UNIONDALE',
		Code: '6460    ',
		Hub: 'GRJ',
		Ring: 2,
		OpsHub: 'GRJ',
		Dist: 100,
		AreaName: 'George Regional',
		Surch: null,
	},
	{
		PlaceName: 'UNISARAND',
		Code: '0148    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'UNISEL',
		Code: '9477    ',
		Hub: 'BFN',
		Ring: 3,
		OpsHub: 'BFN',
		Dist: 160,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'UNITAS PARK Ext/Uit 1,',
		Code: '1942    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 60,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'UNITAS PARK, Vereeniging',
		Code: '1942    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 60,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'UNIVERSITAS Boxes',
		Code: '9321    ',
		Hub: 'BFN',
		Ring: 0,
		OpsHub: 'BFN',
		Dist: 30,
		AreaName: 'Bloemfontein Central',
		Surch: null,
	},
	{
		PlaceName: 'UNIVERSITAS, Bloemfontein',
		Code: '9301',
		Hub: 'BFN',
		Ring: 0,
		OpsHub: 'BFN',
		Dist: 30,
		AreaName: 'Bloemfontein Central',
		Surch: null,
	},
	{
		PlaceName: 'UNIVERSITEIT VAN KAAPSTAD',
		Code: '7700    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'UNIVERSITEITSOORD, Stellenbocsh',
		Code: '7600    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 60,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'UNIVERSITY Est/Lgd, Woodstock',
		Code: '7925    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'UNIVERSITY OF CAPE TOWN,',
		Code: '7700    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'UPPER CLAREMONT',
		Code: '7708',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'UPPER FAIRFIELD Est/Lgd,',
		Code: '7500    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'UPPER FERNCLIFFE,',
		Code: '3201    ',
		Hub: 'PMB',
		Ring: 1,
		OpsHub: 'PMB',
		Dist: 75,
		AreaName: 'Pietermaritzburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'UPPER NSELENI',
		Code: '3884    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 250,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'UPPER TONGAAT',
		Code: '4402    ',
		Hub: 'DUR',
		Ring: 1,
		OpsHub: 'DUR',
		Dist: 70,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'UPPER WYNBERG, Plumstead',
		Code: '7800    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'URANIAVILLE, Klerksdorp',
		Code: '2571    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 150,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'URBANVILLE, George',
		Code: '6529    ',
		Hub: 'GRJ',
		Ring: 0,
		OpsHub: 'GRJ',
		Dist: 10,
		AreaName: 'George Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'URIONSKRAAL',
		Code: '8171    ',
		Hub: 'CPT',
		Ring: 4,
		OpsHub: 'CPT',
		Dist: 330,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'USUTU',
		Code: '0624    ',
		Hub: 'PRY',
		Ring: 4,
		OpsHub: 'PRY',
		Dist: 300,
		AreaName: 'Pretoria Regional',
		Surch: null,
	},
	{
		PlaceName: 'UTILITY, Kimberley',
		Code: '8301    ',
		Hub: 'KIM',
		Ring: 0,
		OpsHub: 'KIM',
		Dist: 20,
		AreaName: 'Kimberley Central',
		Surch: null,
	},
	{
		PlaceName: 'UTLWANENG, Christiana',
		Code: '2680    ',
		Hub: 'KIM',
		Ring: 4,
		OpsHub: 'KIM',
		Dist: 400,
		AreaName: 'Kimberley Regional',
		Surch: null,
	},
	{
		PlaceName: 'UTRECHT',
		Code: '2980    ',
		Hub: 'DUR',
		Ring: 2,
		OpsHub: 'DUR',
		Dist: 340,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'UVONGO',
		Code: '4270    ',
		Hub: 'DUR',
		Ring: 3,
		OpsHub: 'DUR',
		Dist: 150,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'VAAL REEF',
		Code: '2621    ',
		Hub: 'JNB',
		Ring: 3,
		OpsHub: 'JNB',
		Dist: 120,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'VAALBANK, Hlobane',
		Code: '3145    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 330,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'VAALBANK, Middelburg',
		Code: '1050    ',
		Hub: 'PRY',
		Ring: 1,
		OpsHub: 'PRY',
		Dist: 140,
		AreaName: 'Pretoria Regional',
		Surch: null,
	},
	{
		PlaceName: 'VAALDRIFT, Vereeniging',
		Code: '1939    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 60,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'VAALPARK Boxes',
		Code: '1948',
		Hub: 'JNB',
		Ring: 2,
		OpsHub: 'JNB',
		Dist: 100,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'VAALPARK, Sasolburg',
		Code: '1947',
		Hub: 'JNB',
		Ring: 2,
		OpsHub: 'JNB',
		Dist: 100,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'VAALTYN, Potgietersrus',
		Code: '0600    ',
		Hub: 'PTG',
		Ring: 3,
		OpsHub: 'JNB',
		Dist: 300,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'VAALWATER',
		Code: '0530    ',
		Hub: 'PRY',
		Ring: 4,
		OpsHub: 'PRY',
		Dist: 180,
		AreaName: 'Pretoria Regional',
		Surch: null,
	},
	{
		PlaceName: 'VAL-DE-GRACE, Pretoria',
		Code: '0184    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'VALBLUM',
		Code: '1783    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 50,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'VALDEZIA',
		Code: '0935    ',
		Hub: 'JNB',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 300,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'VALENCIA PARK, Nelspruit',
		Code: '1201',
		Hub: 'NLP',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'VALERIEDENE, Johannesburg',
		Code: '2195    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'VALHALLA PARK, Bontheuwel',
		Code: '7490    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'VALHALLA, Pretoria',
		Code: '0185    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'VALKENBERG, Observatory',
		Code: '7925    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'VALLEISIG, Uitenhage',
		Code: '6230    ',
		Hub: 'PLZ',
		Ring: 1,
		OpsHub: 'PLZ',
		Dist: 50,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'VALLEY Est/Lgd, Houtbay',
		Code: '7800    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 40,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'VALLIS',
		Code: '0138    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'VALMARY PARK, Durbanville',
		Code: '7550    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'VALSBAAI, Muizenberg',
		Code: '7945    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'VALYLAND Boxes',
		Code: '7978    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 60,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'VALYLAND, Fish Hoek',
		Code: '7975    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 60,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'VAN DER HEEVERSRUS',
		Code: '9491    ',
		Hub: 'BFN',
		Ring: 3,
		OpsHub: 'BFN',
		Dist: 170,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'VAN DER HOFFPARK,',
		Code: '2520    ',
		Hub: 'POT',
		Ring: 3,
		OpsHub: 'JNB',
		Dist: 150,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'VAN DER STEL, Port Elizabeth',
		Code: '6025',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'VAN DER STEL, Somerset West',
		Code: '7130    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 30,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'VAN DER WALTPARK, Durban',
		Code: '4052    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'VAN DYKPARK Boxes',
		Code: '1471    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'VAN DYKPARK, Boksburg',
		Code: '1459    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'VAN DYKSDRIF',
		Code: '2245    ',
		Hub: 'MBG',
		Ring: 2,
		OpsHub: 'JNB',
		Dist: 120,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'VAN REENEN',
		Code: '3372    ',
		Hub: 'HSM',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 300,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'VAN RHYN, Benoni',
		Code: '1501    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'VAN RIEBEECKPARK & Ext',
		Code: '1619    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'VAN RIEBEECKPARK Boxes',
		Code: '1629    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'VAN RIEBEECKPARK, Durban',
		Code: '4052    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'VAN RIEBEECKPARK, Ladysmith',
		Code: '3370    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 250,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'VAN RIEBEECKPARK, Worcester',
		Code: '6850    ',
		Hub: 'CPT',
		Ring: 2,
		OpsHub: 'CPT',
		Dist: 120,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'VAN RIEBEECKSTRAND, Melkbosstrand',
		Code: '7441    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'VAN STADENSRUS',
		Code: '9945    ',
		Hub: 'BFN',
		Ring: 3,
		OpsHub: 'BFN',
		Dist: 160,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'VAN WYKSDORP',
		Code: '6790    ',
		Hub: 'GRJ',
		Ring: 4,
		OpsHub: 'GRJ',
		Dist: 130,
		AreaName: 'George Regional',
		Surch: null,
	},
	{
		PlaceName: 'VAN WYKSVLEI',
		Code: '7050    ',
		Hub: 'BFN',
		Ring: 4,
		OpsHub: 'BFN',
		Dist: 500,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'VAN WYKSVLEI, Wellington',
		Code: '7655    ',
		Hub: 'CPT',
		Ring: 2,
		OpsHub: 'CPT',
		Dist: 90,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'VAN ZYLSRUS',
		Code: '8467    ',
		Hub: 'KUR',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 400,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'VANDERBIJLPARK Boxes',
		Code: '1900    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 60,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'VANDERBIJLPARK, Johannesburg',
		Code: '1911    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 60,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'VANDERKLOOF',
		Code: '8771    ',
		Hub: 'BFN',
		Ring: 4,
		OpsHub: 'BFN',
		Dist: 250,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'VANDERKLOOFDAM, Vanderkloof',
		Code: '8771    ',
		Hub: 'BFN',
		Ring: 4,
		OpsHub: 'BFN',
		Dist: 250,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'VANDERSTEL Boxes',
		Code: '7132    ',
		Hub: 'CPT',
		Ring: 1,
		OpsHub: 'CPT',
		Dist: 30,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'VANDIA GROVE, Randburg',
		Code: '2194    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'VANES Est/Lgd, Uitenhage',
		Code: '6230    ',
		Hub: 'PLZ',
		Ring: 1,
		OpsHub: 'PLZ',
		Dist: 50,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'VANGUARD Est/Lgd, Athlone',
		Code: '7764    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'VANRHYNSDORP',
		Code: '8170    ',
		Hub: 'CPT',
		Ring: 4,
		OpsHub: 'CPT',
		Dist: 330,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'VASCO, Goodwood',
		Code: '7460    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'VEEKRAAL',
		Code: '0729    ',
		Hub: 'PTG',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 300,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'VEEPLAAS, Port Elizabeth',
		Code: '6001    ',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'VEGANVIEW Boxes',
		Code: '1612    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'VELD EN VLEI, Richards Bay',
		Code: '3900',
		Hub: 'RCB',
		Ring: 1,
		OpsHub: 'RCB',
		Dist: 200,
		AreaName: 'Richards Bay',
		Surch: null,
	},
	{
		PlaceName: 'VELDDRIF',
		Code: '7365    ',
		Hub: 'CPT',
		Ring: 3,
		OpsHub: 'CPT',
		Dist: 160,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'VELDRIFT',
		Code: '7365    ',
		Hub: 'CPT',
		Ring: 3,
		OpsHub: 'CPT',
		Dist: 160,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'VENDA',
		Code: '0999    ',
		Hub: 'JNB',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 400,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'VENNING PARK',
		Code: '0139    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'VENTERSBURG',
		Code: '9450    ',
		Hub: 'WEL',
		Ring: 3,
		OpsHub: 'JNB',
		Dist: 150,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'VENTERSDORP',
		Code: '2710    ',
		Hub: 'JNB',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 170,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'VENTERSPOS Boxes',
		Code: '1782    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 50,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'VENTERSPOS, Westonaria',
		Code: '1780    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 50,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'VENTERSTAD',
		Code: '5990    ',
		Hub: 'BFN',
		Ring: 4,
		OpsHub: 'BFN',
		Dist: 250,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'VERDEDIGINGSTERREIN,',
		Code: '0002    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'VEREENIGING',
		Code: '1939    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 60,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'VEREENIGING Boxes',
		Code: '1930    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 60,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'VERGELEE',
		Code: '8622    ',
		Hub: 'VRY',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 450,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'VERGELEGEN, Port Elizabeth',
		Code: '6025',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'VERGENOEG, East London',
		Code: '5209    ',
		Hub: 'ELS',
		Ring: 0,
		OpsHub: 'ELS',
		Dist: 10,
		AreaName: 'East London Central',
		Surch: null,
	},
	{
		PlaceName: 'VERGENOEG, Galeshewe',
		Code: '8330    ',
		Hub: 'KIM',
		Ring: 0,
		OpsHub: 'KIM',
		Dist: 20,
		AreaName: 'Kimberley Central',
		Surch: null,
	},
	{
		PlaceName: 'VERGENOEG, Mankurwane',
		Code: '8345    ',
		Hub: 'BFN',
		Ring: 4,
		OpsHub: 'BFN',
		Dist: 200,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'VERGESIG, Durbanville',
		Code: '7550    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'VERKEERDEVLEI',
		Code: '9401    ',
		Hub: 'BFN',
		Ring: 1,
		OpsHub: 'BFN',
		Dist: 80,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'VERKYKERSKOP',
		Code: '9882    ',
		Hub: 'HSM',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 320,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'VERMAAKLIKHEID',
		Code: '6771    ',
		Hub: 'GRJ',
		Ring: 4,
		OpsHub: 'GRJ',
		Dist: 150,
		AreaName: 'George Regional',
		Surch: null,
	},
	{
		PlaceName: 'VERMAAS',
		Code: '2727    ',
		Hub: 'VRY',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 300,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'VERMONT',
		Code: '7200',
		Hub: 'CPT',
		Ring: 1,
		OpsHub: 'CPT',
		Dist: 120,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'VERNEUKPAN',
		Code: '7021    ',
		Hub: 'BFN',
		Ring: 4,
		OpsHub: 'BFN',
		Dist: 500,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'VERONA, Durbanville',
		Code: '7550    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'VERULAM',
		Code: '4340    ',
		Hub: 'DUR',
		Ring: 1,
		OpsHub: 'DUR',
		Dist: 50,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'VERULAM (TT)',
		Code: '4340',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 50,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'VERWOERDBURG',
		Code: '0157    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'VERWOERDDAM',
		Code: '9922    ',
		Hub: 'BFN',
		Ring: 4,
		OpsHub: 'BFN',
		Dist: 200,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'VERWOERDPARK, Alberton',
		Code: '1453    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'VERWOERDPARK, Kimberley',
		Code: '8301    ',
		Hub: 'KIM',
		Ring: 0,
		OpsHub: 'KIM',
		Dist: 20,
		AreaName: 'Kimberley Central',
		Surch: null,
	},
	{
		PlaceName: 'VHUFULI, Sibasa',
		Code: '0971',
		Hub: 'PTG',
		Ring: 1,
		OpsHub: 'PTG',
		Dist: 200,
		AreaName: 'Pietersburg Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'VICTORIA JUNCTION',
		Code: '8001',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 5,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'VICTORIA PARK, Port Elizabeth',
		Code: '6001    ',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'VICTORIA PARK, Queenstown',
		Code: '5320    ',
		Hub: 'ELS',
		Ring: 4,
		OpsHub: 'ELS',
		Dist: 210,
		AreaName: 'East London Regional',
		Surch: null,
	},
	{
		PlaceName: 'VICTORIA PARK, Worcester',
		Code: '6850    ',
		Hub: 'CPT',
		Ring: 2,
		OpsHub: 'CPT',
		Dist: 120,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'VICTORIA WEST',
		Code: '7070    ',
		Hub: 'BFN',
		Ring: 4,
		OpsHub: 'BFN',
		Dist: 500,
		AreaName: 'Bloemfontein Regional',
		Surch: 'Far Outlying',
	},
	{
		PlaceName: 'VICTORIA, Johannesburg',
		Code: '2192    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'VICTORIA-WES',
		Code: '7070    ',
		Hub: 'BFN',
		Ring: 4,
		OpsHub: 'BFN',
		Dist: 500,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'VICTORY PARK, Johannesburg',
		Code: '2195    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'VIER-EN-TWINTIG RIVIERE',
		Code: '0542    ',
		Hub: 'PRY',
		Ring: 4,
		OpsHub: 'PRY',
		Dist: 180,
		AreaName: 'Pretoria Regional',
		Surch: null,
	},
	{
		PlaceName: 'VIERFONTEIN, Klerksdorp',
		Code: '2615',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 150,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'VIERLANDEN, Durbanville',
		Code: '7550    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'VIEWCREST, Athlone',
		Code: '7764    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'VIEWCREST, Johannesburg',
		Code: '2192    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'VIKINGVALE, Port Elizabeth',
		Code: '6025',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'VILJOENSDRIF',
		Code: '9580    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 80,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'VILJOENSKROON',
		Code: '9520    ',
		Hub: 'BFN',
		Ring: 3,
		OpsHub: 'BFN',
		Dist: 170,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'VILLA LISA, Boksburg',
		Code: '1459    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'VILLA NORA',
		Code: '0607    ',
		Hub: 'PTG',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 300,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'VILLA ROSA, Verwoerdburg',
		Code: '0157    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'VILLAGE CLOSE, Durbanville',
		Code: '7550    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'VILLAGE DEEP, Johannesburg',
		Code: '2001    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'VILLAGE MAIN, Johannesburg',
		Code: '2001    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'VILLAGE OF HAPPINESS',
		Code: '4280    ',
		Hub: 'DUR',
		Ring: 3,
		OpsHub: 'DUR',
		Dist: 160,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'VILLIERIA, Pretoria',
		Code: '0186    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'VILLIERSDORP',
		Code: '7170    ',
		Hub: 'CPT',
		Ring: 1,
		OpsHub: 'CPT',
		Dist: 80,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'VINCENT Boxes',
		Code: '5217    ',
		Hub: 'ELS',
		Ring: 0,
		OpsHub: 'ELS',
		Dist: 10,
		AreaName: 'East London Central',
		Surch: null,
	},
	{
		PlaceName: 'VINCENT HEIGHTS EAST,',
		Code: '5241    ',
		Hub: 'ELS',
		Ring: 0,
		OpsHub: 'ELS',
		Dist: 10,
		AreaName: 'East London Central',
		Surch: null,
	},
	{
		PlaceName: 'VINCENT HEIGHTS WEST,',
		Code: '5247    ',
		Hub: 'ELS',
		Ring: 0,
		OpsHub: 'ELS',
		Dist: 10,
		AreaName: 'East London Central',
		Surch: null,
	},
	{
		PlaceName: 'VINCENT HEIGHTS, East London',
		Code: '5247    ',
		Hub: 'ELS',
		Ring: 0,
		OpsHub: 'ELS',
		Dist: 10,
		AreaName: 'East London Central',
		Surch: null,
	},
	{
		PlaceName: 'VINCENT HEIGHTS-OOS,',
		Code: '5241    ',
		Hub: 'ELS',
		Ring: 0,
		OpsHub: 'ELS',
		Dist: 10,
		AreaName: 'East London Central',
		Surch: null,
	},
	{
		PlaceName: 'VINCENT HEIGHTS-WES,',
		Code: '5247    ',
		Hub: 'ELS',
		Ring: 0,
		OpsHub: 'ELS',
		Dist: 10,
		AreaName: 'East London Central',
		Surch: null,
	},
	{
		PlaceName: 'VINCENT, East London',
		Code: '5247    ',
		Hub: 'ELS',
		Ring: 0,
		OpsHub: 'ELS',
		Dist: 10,
		AreaName: 'East London Central',
		Surch: null,
	},
	{
		PlaceName: 'VIRGINIA',
		Code: '9430    ',
		Hub: 'WEL',
		Ring: 2,
		OpsHub: 'JNB',
		Dist: 25,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'VIRGINIA, Durban',
		Code: '4051',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'VISAGIEPARK, Nigel',
		Code: '1490    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 45,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'VISGAT',
		Code: '0539    ',
		Hub: 'PRY',
		Ring: 4,
		OpsHub: 'PRY',
		Dist: 180,
		AreaName: 'Pretoria Regional',
		Surch: null,
	},
	{
		PlaceName: 'VISHOEK',
		Code: '7975    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 60,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'VISRIVIER',
		Code: '5883    ',
		Hub: 'PLZ',
		Ring: 4,
		OpsHub: 'PLZ',
		Dist: 300,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'VITA NOVA Est/Lgd, Tokai',
		Code: '7945    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'VIVO',
		Code: '0924    ',
		Hub: 'PTG',
		Ring: 4,
		OpsHub: 'PTG',
		Dist: 300,
		AreaName: 'Pietersburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'VLAEBERG',
		Code: '8018    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'VLAKDRIF',
		Code: '2808    ',
		Hub: 'RBG',
		Ring: 3,
		OpsHub: 'JNB',
		Dist: 130,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'VLAKFONTEIN, Roodepoort',
		Code: '1724    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'VLAKTEPLAAS',
		Code: '6631    ',
		Hub: 'GRJ',
		Ring: 1,
		OpsHub: 'GRJ',
		Dist: 80,
		AreaName: 'George Regional',
		Surch: null,
	},
	{
		PlaceName: 'VLEESBAAI',
		Code: '6504',
		Hub: 'GRJ',
		Ring: 1,
		OpsHub: 'GRJ',
		Dist: 70,
		AreaName: 'George Regional',
		Surch: null,
	},
	{
		PlaceName: 'VLEI PARK, Kraaifontein',
		Code: '7570    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'VLOTTENBURG',
		Code: '7604    ',
		Hub: 'CPT',
		Ring: 1,
		OpsHub: 'CPT',
		Dist: 60,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'VOELKLIP',
		Code: '7203    ',
		Hub: 'CPT',
		Ring: 2,
		OpsHub: 'CPT',
		Dist: 100,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'VOELVLEI, Kuilsriver',
		Code: '7580    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'VOGELFONTEIN, Boksburg',
		Code: '1459    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'VOLKSRUST',
		Code: '2470    ',
		Hub: 'JNB',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 290,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'VOLLGRAAFFSIG',
		Code: '8824    ',
		Hub: 'BFN',
		Ring: 4,
		OpsHub: 'BFN',
		Dist: 570,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'VOLMOED',
		Code: '6624    ',
		Hub: 'GRJ',
		Ring: 1,
		OpsHub: 'GRJ',
		Dist: 70,
		AreaName: 'George Regional',
		Surch: null,
	},
	{
		PlaceName: 'VOLTARGO',
		Code: '2226    ',
		Hub: 'MBG',
		Ring: 2,
		OpsHub: 'JNB',
		Dist: 120,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'VONGANI',
		Code: '0930    ',
		Hub: 'JNB',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 300,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'VOORBAAI, Mosselbay',
		Code: '6506',
		Hub: 'GRJ',
		Ring: 1,
		OpsHub: 'GRJ',
		Dist: 60,
		AreaName: 'George Regional',
		Surch: null,
	},
	{
		PlaceName: 'VOORREG',
		Code: '0451    ',
		Hub: 'PRY',
		Ring: 4,
		OpsHub: 'PRY',
		Dist: 180,
		AreaName: 'Pretoria Regional',
		Surch: null,
	},
	{
		PlaceName: 'VOORSPOED, Welkom',
		Code: '9459    ',
		Hub: 'WEL',
		Ring: 3,
		OpsHub: 'JNB',
		Dist: 150,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'VOORTREKKERHOOGTE, Pretoria',
		Code: '0187    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'VOORTREKKERSTRAND',
		Code: '4279    ',
		Hub: 'DUR',
		Ring: 3,
		OpsHub: 'DUR',
		Dist: 160,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'VOORUITSIG GEVANGENISTERR',
		Code: '9499    ',
		Hub: 'BFN',
		Ring: 3,
		OpsHub: 'BFN',
		Dist: 170,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'VOORWAARTS, Kroonstad',
		Code: '9499    ',
		Hub: 'BFN',
		Ring: 3,
		OpsHub: 'BFN',
		Dist: 170,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'VORENTOE',
		Code: '0259    ',
		Hub: 'RBG',
		Ring: 2,
		OpsHub: 'JNB',
		Dist: 100,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'VORNA VALLEY, Halfway House',
		Code: '1686',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'VORSTERSHOOP',
		Code: '8615    ',
		Hub: 'BFN',
		Ring: 4,
		OpsHub: 'BFN',
		Dist: 490,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'VORSTERSKROON, Nigel',
		Code: '1490    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 45,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'VOSBURG',
		Code: '8960    ',
		Hub: 'KIM',
		Ring: 4,
		OpsHub: 'KIM',
		Dist: 470,
		AreaName: 'Kimberley Regional',
		Surch: null,
	},
	{
		PlaceName: 'VOSLOORUS & Ext/Uit 2 - 1',
		Code: '1468    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'VREDEDORP Boxes',
		Code: '2141    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'VREDEDORP, Johannesburg',
		Code: '2092    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'VREDEFORT',
		Code: '9595    ',
		Hub: 'POT',
		Ring: 3,
		OpsHub: 'JNB',
		Dist: 140,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'VREDEHOEK',
		Code: '8001',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'VREDEKLOOF, Brackenfell',
		Code: '7560    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'VREDELUST Est/Lgd, Bellville',
		Code: '7530    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'VREDENBERG, Bellville',
		Code: '7530    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'VREDENBURG',
		Code: '7380    ',
		Hub: 'CPT',
		Ring: 1,
		OpsHub: 'CPT',
		Dist: 160,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'VREDENDAL',
		Code: '8160    ',
		Hub: 'CPT',
		Ring: 4,
		OpsHub: 'CPT',
		Dist: 320,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'VREDENDAL NORTH Boxes',
		Code: '8161    ',
		Hub: 'CPT',
		Ring: 4,
		OpsHub: 'CPT',
		Dist: 320,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'VREDENDAL NORTH, Vredendal',
		Code: '8160    ',
		Hub: 'CPT',
		Ring: 4,
		OpsHub: 'CPT',
		Dist: 320,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'VREDENDAL-NOORD Busse',
		Code: '8161    ',
		Hub: 'CPT',
		Ring: 4,
		OpsHub: 'CPT',
		Dist: 320,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'VREDENDAL-NOORD, Vredendal',
		Code: '8160    ',
		Hub: 'CPT',
		Ring: 4,
		OpsHub: 'CPT',
		Dist: 320,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'VREDEPARK, Johannesburg',
		Code: '2092    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'VRIJZEE Boxes',
		Code: '7495    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'VRIJZEE, Goodwood',
		Code: '7460    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'VRYBURG',
		Code: '8600    ',
		Hub: 'VRY',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 320,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'VRYHEID',
		Code: '3100    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 330,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'VRYKYK, Paarl',
		Code: '7646    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 60,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'VUKENZILE, Bloemfontein',
		Code: '9323    ',
		Hub: 'BFN',
		Ring: 0,
		OpsHub: 'BFN',
		Dist: 30,
		AreaName: 'Bloemfontein Central',
		Surch: null,
	},
	{
		PlaceName: 'VULCANIA, Brakpan',
		Code: '1540    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'VUMANHLAMVU',
		Code: '3858    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 240,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'VUYANI',
		Code: '0931    ',
		Hub: 'JNB',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 300,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'VUYOLWETHU, Steytlerville',
		Code: '6250    ',
		Hub: 'PLZ',
		Ring: 4,
		OpsHub: 'PLZ',
		Dist: 180,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'VYEBOOM,Grabouw',
		Code: '7171',
		Hub: 'CPT',
		Ring: 1,
		OpsHub: 'CPT',
		Dist: 60,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'VYGEBOOM, Durbanville',
		Code: '7550    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'WADEVILLE Boxes',
		Code: '1422    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'WADEVILLE, Germiston',
		Code: '1407    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'WAENHUISKRANS',
		Code: '7280',
		Hub: 'CPT',
		Ring: 1,
		OpsHub: 'CPT',
		Dist: 210,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'WAKEFIELD Est/Lgd, Gatesville',
		Code: '7764    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'WAKKERSTROOM',
		Code: '2480    ',
		Hub: 'JNB',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 290,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'WALDRIFT, Vereeniging',
		Code: '1939    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 60,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'WALKERVILLE, Meyerton',
		Code: '1961    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 60,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'WALLACE DENE, Kraaifontein',
		Code: '7570',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'WALMER Boxes',
		Code: '6065    ',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'WALMER DOWNS, Port Elizabeth',
		Code: '6070',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'WALMER ESTATE, Woodstock',
		Code: '7925    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'WALMER HEIGHTS, Port Elizabeth',
		Code: '6070',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'WALMER, Port Elizabeth',
		Code: '6070',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'WALMERHOOGTE, Port Elizabeth',
		Code: '6070',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'WALTLOO, Pretoria',
		Code: '0184    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'WAMAKERSVALLEI Boxes',
		Code: '7656    ',
		Hub: 'CPT',
		Ring: 2,
		OpsHub: 'CPT',
		Dist: 90,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'WAMAKERSVALLEI, Wellington',
		Code: '7655    ',
		Hub: 'CPT',
		Ring: 2,
		OpsHub: 'CPT',
		Dist: 90,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'WANDERERS VIEW, Johannesburg',
		Code: '2001    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'WANDSBECK',
		Code: '3631    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'WANNENBURG, Primrose',
		Code: '1401    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'WAPADRAND EXT3,Pretoria',
		Code: '0081',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'WARMBAD',
		Code: '0480    ',
		Hub: 'PRY',
		Ring: 4,
		OpsHub: 'PRY',
		Dist: 180,
		AreaName: 'Pretoria Regional',
		Surch: null,
	},
	{
		PlaceName: 'WARMBATHS',
		Code: '0480    ',
		Hub: 'PRY',
		Ring: 4,
		OpsHub: 'PRY',
		Dist: 180,
		AreaName: 'Pretoria Regional',
		Surch: null,
	},
	{
		PlaceName: 'WARMBATHS (TT)',
		Code: '0480',
		Hub: 'PRY',
		Ring: 4,
		OpsHub: 'PRY',
		Dist: 180,
		AreaName: 'Pretoria Regional',
		Surch: null,
	},
	{
		PlaceName: 'WARNER BEACH Boxes',
		Code: '4140    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'WARNER BEACH Streets',
		Code: '4126',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'WARRENLEA, Rondebosch',
		Code: '7700    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'WARRENTON',
		Code: '8530    ',
		Hub: 'KIM',
		Ring: 4,
		OpsHub: 'KIM',
		Dist: 170,
		AreaName: 'Kimberley Regional',
		Surch: null,
	},
	{
		PlaceName: 'WARRENTON, Stanger',
		Code: '4450    ',
		Hub: 'DUR',
		Ring: 2,
		OpsHub: 'DUR',
		Dist: 120,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'WARTBURG',
		Code: '3450    ',
		Hub: 'DUR',
		Ring: 2,
		OpsHub: 'DUR',
		Dist: 110,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'WASBANK',
		Code: '2920    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 310,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'WATERBERRY WOOD, Richard',
		Code: '3902    ',
		Hub: 'DUR',
		Ring: 1,
		OpsHub: 'RCB',
		Dist: 200,
		AreaName: 'Richards Bay',
		Surch: null,
	},
	{
		PlaceName: 'WATERFALL COUNTRY ESTATE, Midrand',
		Code: '1685',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'WATERFALL PARK, Mayville',
		Code: '4091',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'WATERFALL, Durban',
		Code: '3610',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 20,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'WATERFALL, Midrand',
		Code: '1685',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'WATERFRONT',
		Code: '8001',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'WATERFRONT Boxes',
		Code: '8002    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'WATERKLOOF AIR FORCE BASE',
		Code: '0157    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'WATERKLOOF GLEN, Pretoria',
		Code: '0010    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'WATERKLOOF HEIGHTS, Pretoria',
		Code: '0181    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'WATERKLOOF LUGMAGBASIS,',
		Code: '0157    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'WATERKLOOF RIDGE & Ext 2,',
		Code: '0181    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'WATERKLOOF, Bellville',
		Code: '7530    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'WATERKLOOF, Ellisras',
		Code: '0555    ',
		Hub: 'PRY',
		Ring: 4,
		OpsHub: 'PRY',
		Dist: 180,
		AreaName: 'Pretoria Regional',
		Surch: null,
	},
	{
		PlaceName: 'WATERKLOOF, Pretoria',
		Code: '0181    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'WATERKLOOF, Pretoria',
		Code: '0157    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'WATERKLOOF, Rustenburg',
		Code: '0300    ',
		Hub: 'JNB',
		Ring: 2,
		OpsHub: 'JNB',
		Dist: 120,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'WATERKLOOFPARK, Pretoria',
		Code: '0181    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'WATERKLOOFRIF & Uit 2,',
		Code: '0181    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'WATERPAN, Westonaria',
		Code: '1780    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 50,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'WATERPOORT',
		Code: '0905    ',
		Hub: 'PTG',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 300,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'WATERSKAG Boxes',
		Code: '9461    ',
		Hub: 'BFN',
		Ring: 3,
		OpsHub: 'BFN',
		Dist: 150,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'WATERSKAG, Welkom',
		Code: '9459    ',
		Hub: 'WEL',
		Ring: 3,
		OpsHub: 'JNB',
		Dist: 150,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'WATERVAL CITY, Midrand',
		Code: '1685',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'WATERVAL Est/Lgd, Johannesburg',
		Code: '2195    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'WATERVAL, Rustenburg',
		Code: '0300    ',
		Hub: 'JNB',
		Ring: 2,
		OpsHub: 'JNB',
		Dist: 120,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'WATERVAL-BOVEN',
		Code: '1195    ',
		Hub: 'NLP',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 250,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'WATSONIA, Tongaat',
		Code: '4399',
		Hub: 'DUR',
		Ring: 1,
		OpsHub: 'DUR',
		Dist: 70,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'WATTVILLE Boxes',
		Code: '1516    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'WATTVILLE, Benoni',
		Code: '1501    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'WAVE CREST, Mitchells Plain',
		Code: '7785    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'WAVERLEY, Bloemfontein',
		Code: '9301',
		Hub: 'BFN',
		Ring: 0,
		OpsHub: 'BFN',
		Dist: 30,
		AreaName: 'Bloemfontein Central',
		Surch: null,
	},
	{
		PlaceName: 'WAVERLEY, Johannesburg',
		Code: '2090    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'WAVERLEY, Pretoria',
		Code: '0186    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'WEAVIND PARK, Pretoria',
		Code: '0184    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'WEBBER, Germiston',
		Code: '1401    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'WEDELA, Carletonville',
		Code: '2500    ',
		Hub: 'JNB',
		Ring: 2,
		OpsHub: 'JNB',
		Dist: 100,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'WEENEN',
		Code: '3325    ',
		Hub: 'HSM',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 200,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'WEGDRAAI',
		Code: '8826    ',
		Hub: 'BFN',
		Ring: 4,
		OpsHub: 'BFN',
		Dist: 570,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'WELBEDACHT EAST, Chatsworth',
		Code: '4092',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'WELBEDACHT WEST, Chatsworth',
		Code: '4092',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'WELBEDACHT, Chatsworth',
		Code: '4092',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'WELBEDAG',
		Code: '6626    ',
		Hub: 'GRJ',
		Ring: 1,
		OpsHub: 'GRJ',
		Dist: 70,
		AreaName: 'George Regional',
		Surch: null,
	},
	{
		PlaceName: 'WELBEKEND',
		Code: '1517    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'WELCOME Est/Lgd, Athlone',
		Code: '7764    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'WELGEDACHT, Cape Town',
		Code: '7530',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'WELGEDACHT, Utrecht',
		Code: '2980    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 340,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'WELGEDAG Boxes',
		Code: '1572    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'WELGEDAG, Springs',
		Code: '1559    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'WELGEHEUWEL, Roodepoort',
		Code: '1724',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'WELGELEGEN & Ext/Uit 1,',
		Code: '0699',
		Hub: 'PTG',
		Ring: 0,
		OpsHub: 'PTG',
		Dist: 400,
		AreaName: 'Pietersburg Central',
		Surch: null,
	},
	{
		PlaceName: 'WELGELEGEN, Parow',
		Code: '7500    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'WELGELEGEN, Stellenbosch',
		Code: '7600    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 60,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'WELGEMOED, Bellville',
		Code: '7530    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'WELGEMOED, Bellville',
		Code: '7530',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'WELGEVONDEN ESTATE, Durbanville',
		Code: '7550',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'WELGEVONDEN ESTATE, Stellenbosch',
		Code: '7600',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 40,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'WELKOM',
		Code: '9459    ',
		Hub: 'WEL',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 150,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'WELKOM (T)',
		Code: '9459',
		Hub: 'WEL',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 150,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'WELKOM Boxes',
		Code: '9460    ',
		Hub: 'WEL',
		Ring: 3,
		OpsHub: 'JNB',
		Dist: 150,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'WELKOM Ext/Uit 19, Welkom',
		Code: '9459    ',
		Hub: 'WEL',
		Ring: 3,
		OpsHub: 'JNB',
		Dist: 150,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'WELLINGTON',
		Code: '7655    ',
		Hub: 'CPT',
		Ring: 1,
		OpsHub: 'CPT',
		Dist: 90,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'WELLINGTON/STA',
		Code: '7657    ',
		Hub: 'CPT',
		Ring: 1,
		OpsHub: 'CPT',
		Dist: 90,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'WELLWAY PARK EAST, Durbanville',
		Code: '7550    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'WELLWAY PARK, Durbanville',
		Code: '7550    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'WELLWAY PARK-OOS, Durbanville',
		Code: '7550    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'WELTEVREDE, Koppies',
		Code: '9540    ',
		Hub: 'JNB',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 170,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'WELTEVREDE, Plumstead',
		Code: '7800    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'WELTEVREDEN VALLEY,',
		Code: '7785    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'WELTEVREDENPARK & Ext/Uit',
		Code: '1709    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'WELTEVREDENPARK Boxes',
		Code: '1715    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'WELTRIX',
		Code: '9438    ',
		Hub: 'BFN',
		Ring: 4,
		OpsHub: 'BFN',
		Dist: 180,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'WELVERDIEND',
		Code: '2700    ',
		Hub: 'JNB',
		Ring: 2,
		OpsHub: 'JNB',
		Dist: 110,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'WEMBLEYPARK, Eersterivier',
		Code: '7100    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 15,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'WEMBLY PARK, Kuilsrivier',
		Code: '7580    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'WEMBLY, Pietermaritzburg',
		Code: '3201    ',
		Hub: 'PMB',
		Ring: 1,
		OpsHub: 'PMB',
		Dist: 75,
		AreaName: 'Pietermaritzburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'WEMMER, Johannesburg',
		Code: '2001    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'WENAKKER',
		Code: '9437    ',
		Hub: 'BFN',
		Ring: 4,
		OpsHub: 'BFN',
		Dist: 180,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'WENDYWOOD Boxes',
		Code: '2144    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'WENDYWOOD Ext/Uit 4, Bramley',
		Code: '2148    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'WENDYWOOD, Bramley',
		Code: '2148    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'WENTWORTH Boxes',
		Code: '4076    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'WENTWORTH PARK, Krugersdorp',
		Code: '1739    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'WENTWORTH, Durban',
		Code: '4052    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'WEPENER',
		Code: '9944    ',
		Hub: 'BFN',
		Ring: 3,
		OpsHub: 'BFN',
		Dist: 140,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'WERKGENOT, Ravensmead',
		Code: '7490    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'WES PARK, Plumstead',
		Code: '7800    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'WES-KRUGERSDORP Busse',
		Code: '1742    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'WES-KRUGERSDORP Strate',
		Code: '1739    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'WES-RAND',
		Code: '1746    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: "WESBANK, King William's",
		Code: '5600    ',
		Hub: 'ELS',
		Ring: 1,
		OpsHub: 'ELS',
		Dist: 70,
		AreaName: 'East London Regional',
		Surch: null,
	},
	{
		PlaceName: 'WESBANK, Oos-Londen',
		Code: '5201    ',
		Hub: 'ELS',
		Ring: 0,
		OpsHub: 'ELS',
		Dist: 10,
		AreaName: 'East London Central',
		Surch: null,
	},
	{
		PlaceName: 'WESBURG, Pietersburg',
		Code: '0699',
		Hub: 'PTG',
		Ring: 0,
		OpsHub: 'PTG',
		Dist: 400,
		AreaName: 'Pietersburg Central',
		Surch: null,
	},
	{
		PlaceName: 'WESCO PARK, Wendywood',
		Code: '2148    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'WESEINDE, DURBAN',
		Code: '4077    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'WESEINDE, Kimberley',
		Code: '8301    ',
		Hub: 'KIM',
		Ring: 0,
		OpsHub: 'KIM',
		Dist: 20,
		AreaName: 'Kimberley Central',
		Surch: null,
	},
	{
		PlaceName: 'WESFLEUR',
		Code: '7349    ',
		Hub: 'CPT',
		Ring: 1,
		OpsHub: 'CPT',
		Dist: 50,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'WESKANAAL',
		Code: '8533    ',
		Hub: 'BFN',
		Ring: 4,
		OpsHub: 'BFN',
		Dist: 180,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'WESPARK, Kroonstad',
		Code: '9499    ',
		Hub: 'BFN',
		Ring: 3,
		OpsHub: 'BFN',
		Dist: 170,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'WESPARK, Pretoria',
		Code: '0183    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'WESSELSBRON',
		Code: '9680    ',
		Hub: 'WEL',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 50,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'WEST ACRES, Nelspruit',
		Code: '1201',
		Hub: 'NLP',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'WEST BANK Boxes',
		Code: '5218    ',
		Hub: 'ELS',
		Ring: 0,
		OpsHub: 'ELS',
		Dist: 10,
		AreaName: 'East London Central',
		Surch: null,
	},
	{
		PlaceName: 'WEST BANK, East London',
		Code: '5201    ',
		Hub: 'ELS',
		Ring: 0,
		OpsHub: 'ELS',
		Dist: 10,
		AreaName: 'East London Central',
		Surch: null,
	},
	{
		PlaceName: "WEST BANK, King William's Town",
		Code: '5600    ',
		Hub: 'ELS',
		Ring: 1,
		OpsHub: 'ELS',
		Dist: 70,
		AreaName: 'East London Regional',
		Surch: null,
	},
	{
		PlaceName: 'WEST BANK, Malmesbury',
		Code: '7300    ',
		Hub: 'CPT',
		Ring: 1,
		OpsHub: 'CPT',
		Dist: 60,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'WEST BANK, Oudtshoorn',
		Code: '6620    ',
		Hub: 'GRJ',
		Ring: 1,
		OpsHub: 'GRJ',
		Dist: 70,
		AreaName: 'George Regional',
		Surch: null,
	},
	{
		PlaceName: 'WEST BEACH, Bloubergrant',
		Code: '7441    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'WEST END, DURBAN',
		Code: '4077    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'WEST END, Kimberley',
		Code: '8301    ',
		Hub: 'KIM',
		Ring: 0,
		OpsHub: 'KIM',
		Dist: 20,
		AreaName: 'Kimberley Central',
		Surch: null,
	},
	{
		PlaceName: 'WEST END, Parys',
		Code: '9585    ',
		Hub: 'JNB',
		Ring: 3,
		OpsHub: 'JNB',
		Dist: 130,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'WEST END, Pniel',
		Code: '7681    ',
		Hub: 'CPT',
		Ring: 2,
		OpsHub: 'CPT',
		Dist: 90,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'WEST END, Port Elizabeth',
		Code: '6059    ',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'WEST END, Postmasburg',
		Code: '8420',
		Hub: 'BFN',
		Ring: 4,
		OpsHub: 'BFN',
		Dist: 570,
		AreaName: 'Bloemfontein Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'WEST HILL, Grahamstown',
		Code: '6140    ',
		Hub: 'PLZ',
		Ring: 4,
		OpsHub: 'PLZ',
		Dist: 170,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'WEST HILL, Knysna',
		Code: '6571',
		Hub: 'GRJ',
		Ring: 1,
		OpsHub: 'GRJ',
		Dist: 80,
		AreaName: 'George Regional',
		Surch: null,
	},
	{
		PlaceName: 'WEST KRUGERSDORP Boxes',
		Code: '1742    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'WEST KRUGERSDORP Streets',
		Code: '1739    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'WEST PARK, Pretoria',
		Code: '0183    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'WEST PORGES',
		Code: '1762    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'WEST QUEENSBURGH Est/Lgd,',
		Code: '4093',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'WEST RAND',
		Code: '1746    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'WEST RIDGE, Durban',
		Code: '4091',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'WEST RIDING, Durban',
		Code: '4091',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'WEST RIDING, Pinetown',
		Code: '3610',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'WEST TURFFONTEIN, Johannesburg',
		Code: '2190    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'WESTBOURNE, Queenstown',
		Code: '5320    ',
		Hub: 'ELS',
		Ring: 4,
		OpsHub: 'ELS',
		Dist: 210,
		AreaName: 'East London Regional',
		Surch: null,
	},
	{
		PlaceName: 'WESTBROOK, Tongaat',
		Code: '4399',
		Hub: 'DUR',
		Ring: 1,
		OpsHub: 'DUR',
		Dist: 40,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'WESTBURY, Johannesburg',
		Code: '2093    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'WESTCLIFF, Chatsworth',
		Code: '4092',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'WESTCLIFF, Hermanus',
		Code: '7200    ',
		Hub: 'CPT',
		Ring: 2,
		OpsHub: 'CPT',
		Dist: 100,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'WESTCLIFFE & Ext/Uit, Orlando',
		Code: '1804    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 50,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'WESTCLIFFE LOCATION, Orlando',
		Code: '1804    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 50,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'WESTCLIFFE, Johannesburg',
		Code: '2193    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'WESTDENE, Benoni',
		Code: '1501    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'WESTDENE, Bloemfontein',
		Code: '9301',
		Hub: 'BFN',
		Ring: 0,
		OpsHub: 'BFN',
		Dist: 30,
		AreaName: 'Bloemfontein Central',
		Surch: null,
	},
	{
		PlaceName: 'WESTDENE, Johannesburg',
		Code: '2092    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'WESTENBURG Ext/Uit 1, 15,',
		Code: '0699',
		Hub: 'PTG',
		Ring: 0,
		OpsHub: 'PTG',
		Dist: 400,
		AreaName: 'Pietersburg Central',
		Surch: null,
	},
	{
		PlaceName: 'WESTERGLOOR, Randfontein',
		Code: '1760    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'WESTERING, Port Elizabeth',
		Code: '6025',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'WESTERN COLOURED TOWNSHIP',
		Code: '2093    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'WESTERN EXTENSION, Benoni',
		Code: '1501    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'WESTERN Ext/Uit, Krugersd',
		Code: '1739    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'WESTERN HILLS, Port Elizabeth',
		Code: '6025',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'WESTERN LEVELS',
		Code: '2501    ',
		Hub: 'JNB',
		Ring: 2,
		OpsHub: 'JNB',
		Dist: 100,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'WESTGATE',
		Code: '1734    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'WESTGATE, Johannesburg',
		Code: '2001    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'WESTGATE, Mitchells Plain',
		Code: '7785    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'WESTGATE, Pietermaritzburg',
		Code: '3201    ',
		Hub: 'PMB',
		Ring: 1,
		OpsHub: 'PMB',
		Dist: 75,
		AreaName: 'Pietermaritzburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'WESTHAM, Phoenix',
		Code: '4068',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'WESTHOVEN',
		Code: '2142    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'WESTLAKE Boxes',
		Code: '7967    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'WESTLAKE, Cape Town',
		Code: '7945    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'WESTMEAD',
		Code: '3610',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'WESTMEAD Boxes',
		Code: '3608    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'WESTMEAD, Pinetown',
		Code: '3610',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'WESTMINSTER',
		Code: '9765    ',
		Hub: 'BFN',
		Ring: 3,
		OpsHub: 'BFN',
		Dist: 130,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'WESTONARIA',
		Code: '1780    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 50,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'WESTRIDGE Cape Town, Boxes',
		Code: '7802    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'WESTRIDGE, Cape Town',
		Code: '7785    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'WESTRIDGE, Mossel Bay',
		Code: '6506',
		Hub: 'GRJ',
		Ring: 1,
		OpsHub: 'GRJ',
		Dist: 60,
		AreaName: 'George Regional',
		Surch: null,
	},
	{
		PlaceName: 'WESTRIDGE, MosselBay',
		Code: '6506',
		Hub: 'GRJ',
		Ring: 1,
		OpsHub: 'GRJ',
		Dist: 60,
		AreaName: 'George Regional',
		Surch: null,
	},
	{
		PlaceName: 'WESTRIDGE, Somerset West',
		Code: '7130    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 30,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'WESTRIF, Durban',
		Code: '4091',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'WESTS, Durban',
		Code: '4001    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'WESTVIEW & Ext/Uit,',
		Code: '6045    ',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'WESTVIEW, Somerset East',
		Code: '5850    ',
		Hub: 'PLZ',
		Ring: 4,
		OpsHub: 'PLZ',
		Dist: 190,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'WESTVILLE NORTH, Westville',
		Code: '3629',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'WESTVILLE, Durban',
		Code: '3629',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'WETTON, Kenwyn',
		Code: '7780    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'WEYBRIDGE PARK, Port Elizabeth',
		Code: '6070',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'WEZA',
		Code: '4685    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 180,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'WHEATFIELD Est/Lgd, Lansdowne',
		Code: '7780    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'WHETSTONE, Phoenix',
		Code: '4068',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'WHITE CITY, Ceres',
		Code: '6835    ',
		Hub: 'CPT',
		Ring: 2,
		OpsHub: 'CPT',
		Dist: 120,
		AreaName: 'Cape Town Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'WHITE CITY, Mankurwane',
		Code: '8345    ',
		Hub: 'BFN',
		Ring: 4,
		OpsHub: 'BFN',
		Dist: 200,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'WHITE CITY, Pimville',
		Code: '1808    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 50,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'WHITE CITY, Queenstown',
		Code: '5320    ',
		Hub: 'ELS',
		Ring: 4,
		OpsHub: 'ELS',
		Dist: 210,
		AreaName: 'East London Regional',
		Surch: null,
	},
	{
		PlaceName: 'WHITE CITY, Saldanha',
		Code: '7395    ',
		Hub: 'CPT',
		Ring: 3,
		OpsHub: 'CPT',
		Dist: 150,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'WHITE HOUSE PARK, Phoenix',
		Code: '4068    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'WHITE RIDGE, Florida',
		Code: '1709    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'WHITE RIVER (S)',
		Code: '1240',
		Hub: 'NLP',
		Ring: 4,
		OpsHub: 'NLP',
		Dist: 310,
		AreaName: 'Nelspruit Regional',
		Surch: null,
	},
	{
		PlaceName: 'WHITE RIVER, Nelspruit',
		Code: '1240    ',
		Hub: 'NLP',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 310,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'WHITEFIELD PARK, Amanzimtoti',
		Code: '4126',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'WHITESANDS',
		Code: '6761    ',
		Hub: 'GRJ',
		Ring: 4,
		OpsHub: 'GRJ',
		Dist: 150,
		AreaName: 'George Regional',
		Surch: null,
	},
	{
		PlaceName: 'WHITNEY GARDENS, Johannesburg',
		Code: '2090    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'WHITNEY GREEN, Milnerton',
		Code: '7441    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'WHITTLESEA',
		Code: '9996    ',
		Hub: 'ELS',
		Ring: 4,
		OpsHub: 'ELS',
		Dist: 140,
		AreaName: 'East London Regional',
		Surch: null,
	},
	{
		PlaceName: 'WIBSEY',
		Code: '1717    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'WIDENHAM, Umkomaas',
		Code: '4171',
		Hub: 'DUR',
		Ring: 1,
		OpsHub: 'DUR',
		Dist: 70,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'WIEGANDIA',
		Code: '9380    ',
		Hub: 'BFN',
		Ring: 1,
		OpsHub: 'BFN',
		Dist: 50,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'WIERDA VALLEY, Johannesburg',
		Code: '2196    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'WIERDAPARK Ext/Uit 2 & 5,',
		Code: '0149    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'WIERDAPARK, Verwoerdburg',
		Code: '0149    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'WILBERT, Edenvale, Tvl',
		Code: '1609    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'WILDERNESS',
		Code: '6560    ',
		Hub: 'GRJ',
		Ring: 1,
		OpsHub: 'GRJ',
		Dist: 50,
		AreaName: 'George Regional',
		Surch: null,
	},
	{
		PlaceName: 'WILDERNIS',
		Code: '6560    ',
		Hub: 'GRJ',
		Ring: 1,
		OpsHub: 'GRJ',
		Dist: 50,
		AreaName: 'George Regional',
		Surch: null,
	},
	{
		PlaceName: 'WILDWOODS, Mitchells Plain',
		Code: '7785    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'WILGEHEUWEL, Roodepoort',
		Code: '1736    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'WILGEHOF, Bloemfontein',
		Code: '9301',
		Hub: 'BFN',
		Ring: 0,
		OpsHub: 'BFN',
		Dist: 30,
		AreaName: 'Bloemfontein Central',
		Surch: null,
	},
	{
		PlaceName: 'WILGENHOF, Kroonstad',
		Code: '9499    ',
		Hub: 'BFN',
		Ring: 3,
		OpsHub: 'BFN',
		Dist: 170,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'WILGENHOUTSDRIF',
		Code: '8821    ',
		Hub: 'BFN',
		Ring: 4,
		OpsHub: 'BFN',
		Dist: 570,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'WILGERPARK, Harrismith',
		Code: '9880    ',
		Hub: 'HSM',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 300,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'WILISTON',
		Code: '8920',
		Hub: 'CPT',
		Ring: 1,
		OpsHub: 'CPT',
		Dist: 500,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'WILKEVILLE, Klerksdorp',
		Code: '2571    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 150,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'WILKOPPIES, Klerksdorp',
		Code: '2571    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 150,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'WILLAWAY, Midrand',
		Code: '1684',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'WILLISTON',
		Code: '7040    ',
		Hub: 'BFN',
		Ring: 4,
		OpsHub: 'BFN',
		Dist: 500,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'WILLOW GLEN, Port Elizabeth',
		Code: '6025',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'WILLOW GLEN, Pretoria',
		Code: '0081    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'WILLOW PARK, East London',
		Code: '5201    ',
		Hub: 'ELS',
		Ring: 0,
		OpsHub: 'ELS',
		Dist: 10,
		AreaName: 'East London Central',
		Surch: null,
	},
	{
		PlaceName: 'WILLOWDENE, Port Elizabet',
		Code: '6070',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'WILLOWGLADE, Dieprivier',
		Code: '7800    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'WILLOWGROVE, Port Elizabeth',
		Code: '6070',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'WILLOWILD, Johannesburg',
		Code: '2196    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'WILLOWMORE',
		Code: '6680    ',
		Hub: 'GRJ',
		Ring: 3,
		OpsHub: 'GRJ',
		Dist: 160,
		AreaName: 'George Regional',
		Surch: null,
	},
	{
		PlaceName: 'WILLOWS, BLOEMFONTEIN',
		Code: '9320    ',
		Hub: 'BFN',
		Ring: 0,
		OpsHub: 'BFN',
		Dist: 30,
		AreaName: 'Bloemfontein Central',
		Surch: null,
	},
	{
		PlaceName: 'WILLOWSTREAMS, Randburg',
		Code: '2194    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'WILLOWTON, Pietermaritzburg',
		Code: '3201    ',
		Hub: 'PMB',
		Ring: 1,
		OpsHub: 'PMB',
		Dist: 75,
		AreaName: 'Pietermaritzburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'WILLOWVALE',
		Code: '5040    ',
		Hub: 'ELS',
		Ring: 1,
		OpsHub: 'ELS',
		Dist: 200,
		AreaName: 'East London Regional',
		Surch: null,
	},
	{
		PlaceName: 'WILROPARK & Ext/Uit 3, 5,',
		Code: '1724    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'WILROPARK Boxes',
		Code: '1731    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'WILSONIA, East London',
		Code: '5247    ',
		Hub: 'ELS',
		Ring: 0,
		OpsHub: 'ELS',
		Dist: 10,
		AreaName: 'East London Central',
		Surch: null,
	},
	{
		PlaceName: 'WINCHESTER HILLS & Ext',
		Code: '2091    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'WINDERMERE, Durban',
		Code: '4001',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'WINDERMERE, Maitland',
		Code: '7405    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'WINDMEUL',
		Code: '7630    ',
		Hub: 'CPT',
		Ring: 1,
		OpsHub: 'CPT',
		Dist: 60,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'WINDMILL PARK, Boksburg',
		Code: '1459    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'WINDSOR EAST, Randburg',
		Code: '2194    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'WINDSOR Est/Lgd, Diepriver',
		Code: '7800    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'WINDSOR GLEN, Randburg',
		Code: '2194    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'WINDSOR PARK Randburg',
		Code: '2194    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'WINDSOR PARK, Despatch',
		Code: '6220',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 30,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'WINDSOR PARK, Diepriver',
		Code: '7800    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'WINDSOR PARK, Kraaifontein',
		Code: '7570    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'WINDSOR WEST, Randburg',
		Code: '2194    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'WINDSOR, Queenstown',
		Code: '5320    ',
		Hub: 'ELS',
		Ring: 4,
		OpsHub: 'ELS',
		Dist: 210,
		AreaName: 'East London Regional',
		Surch: null,
	},
	{
		PlaceName: 'WINDSOR, Randburg',
		Code: '2194    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'WINDSOR-OOS, Randburg',
		Code: '2194    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'WINDSOR-WES, Randburg',
		Code: '2194    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'WINDSORTON',
		Code: '8510    ',
		Hub: 'BFN',
		Ring: 4,
		OpsHub: 'BFN',
		Dist: 280,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'WINDVOGEL, Port Elizabeth',
		Code: '6059    ',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'WINGATE PARK, Pretoria',
		Code: '0153    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'WINGFIELD RADIO, Goodwood',
		Code: '7460    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'WINKLESPRUIT Boxes',
		Code: '4145    ',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 20,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'WINKLESPRUIT, KwaZuluNatal',
		Code: '4126',
		Hub: 'DUR',
		Ring: 1,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'WINSLEY, Bellville',
		Code: '7530    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'WINSTON PARK, Pinetown',
		Code: '3610',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'WINSTON RIDGE, Johannesburg',
		Code: '2196    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'WINTERSKLOOF',
		Code: '3240    ',
		Hub: 'DUR',
		Ring: 2,
		OpsHub: 'DUR',
		Dist: 100,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'WINTERSTRAND',
		Code: '5201',
		Hub: 'ELS',
		Ring: 1,
		OpsHub: 'ELS',
		Dist: 22,
		AreaName: 'East London Regional',
		Surch: null,
	},
	{
		PlaceName: 'WINTERTON',
		Code: '3340    ',
		Hub: 'HSM',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 210,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'WITBANK',
		Code: '1034    ',
		Hub: 'MBG',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 140,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'WITBANK Ext/Uit 8, 10, 12',
		Code: '1034    ',
		Hub: 'MBG',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 140,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'WITBEECK',
		Code: '1729    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'WITELSBOS',
		Code: '6304    ',
		Hub: 'PLZ',
		Ring: 4,
		OpsHub: 'PLZ',
		Dist: 170,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'WITFIELD Boxes',
		Code: '1467    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'WITFIELD, Boksburg',
		Code: '1459    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'WITKOPPEN,Johannesburg',
		Code: '2068',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'WITKOPPIE RIDGE, Boksburg',
		Code: '1459    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'WITKOPPIERIF, Boksburg',
		Code: '1459    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'WITMOS',
		Code: '5825    ',
		Hub: 'PLZ',
		Ring: 4,
		OpsHub: 'PLZ',
		Dist: 210,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'WITPOORTJIE & Ext/Uit 1 &',
		Code: '1724    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'WITRIVIER',
		Code: '1240    ',
		Hub: 'NLP',
		Ring: 4,
		OpsHub: 'NLP',
		Dist: 310,
		AreaName: 'Nelspruit Regional',
		Surch: null,
	},
	{
		PlaceName: 'WITS Boxes',
		Code: '2050    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'WITS, Johannesburg',
		Code: '2001    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'WITSAND',
		Code: '6761    ',
		Hub: 'GRJ',
		Ring: 4,
		OpsHub: 'GRJ',
		Dist: 150,
		AreaName: 'George Regional',
		Surch: null,
	},
	{
		PlaceName: 'WITSIESHOEK',
		Code: '9870    ',
		Hub: 'HSM',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 270,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'WITTEBOME Boxes',
		Code: '7840    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'WITTEBOME, Plumstead',
		Code: '7800    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'WITTEDRIF',
		Code: '6603    ',
		Hub: 'GRJ',
		Ring: 2,
		OpsHub: 'GRJ',
		Dist: 110,
		AreaName: 'George Regional',
		Surch: null,
	},
	{
		PlaceName: 'WOLHUTER, Johannesburg',
		Code: '2094    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'WOLMARANSSTAD',
		Code: '2630    ',
		Hub: 'JNB',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 320,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'WOLMER, Pretoria-Noord',
		Code: '0182    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'WOLSELEY',
		Code: '6830    ',
		Hub: 'CPT',
		Ring: 2,
		OpsHub: 'CPT',
		Dist: 90,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'WOLTEMADE Boxes',
		Code: '7445    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'WOLTEMADE, Maitland',
		Code: '7405    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'WOLWEFONTEIN',
		Code: '6237    ',
		Hub: 'PLZ',
		Ring: 3,
		OpsHub: 'PLZ',
		Dist: 140,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'WOLWEHOEK',
		Code: '9550    ',
		Hub: 'JNB',
		Ring: 2,
		OpsHub: 'JNB',
		Dist: 110,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'WONDERBOOM SOUTH, Pretoria',
		Code: '0084    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'WONDERBOOM, Pretoria',
		Code: '0182    ',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'WONDERFONTEIN',
		Code: '1101    ',
		Hub: 'MBG',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 210,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'WONDERVIEW, Port Elizabeth',
		Code: '6025',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'WOODBRIDGE ISLAND, Milnerton',
		Code: '7441    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'WOODBROOK, East London',
		Code: '5201    ',
		Hub: 'ELS',
		Ring: 0,
		OpsHub: 'ELS',
		Dist: 10,
		AreaName: 'East London Central',
		Surch: null,
	},
	{
		PlaceName: 'WOODBURY, Mitchells Plain',
		Code: '7785    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'WOODDALE Est/Lgd, Plumstead',
		Code: '7800    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'WOODHAVEN PARK, Durban',
		Code: '4004',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'WOODHAVEN, Durban',
		Code: '4004',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'WOODHILL GOLF ESTATE, Pretoria',
		Code: '0076',
		Hub: 'PRY',
		Ring: 0,
		OpsHub: 'PRY',
		Dist: 25,
		AreaName: 'Pretoria Central',
		Surch: null,
	},
	{
		PlaceName: 'WOODHURST, Chatsworth',
		Code: '4092',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'WOODLANDS PARK, Lansdowne',
		Code: '7780    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'WOODLANDS, Durban',
		Code: '4004',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'WOODLANDS, Mitchells Plain',
		Code: '7785    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'WOODLANDS, Paterson',
		Code: '6130    ',
		Hub: 'PLZ',
		Ring: 2,
		OpsHub: 'PLZ',
		Dist: 90,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'WOODLANDS, Pietermaritzburg',
		Code: '3201    ',
		Hub: 'PMB',
		Ring: 1,
		OpsHub: 'PMB',
		Dist: 75,
		AreaName: 'Pietermaritzburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'WOODLANDS, Port Elizabeth',
		Code: '6070',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'WOODLANDS, SimonsTown',
		Code: '7995    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 70,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'WOODLEIGH, East London',
		Code: '5241    ',
		Hub: 'ELS',
		Ring: 0,
		OpsHub: 'ELS',
		Dist: 10,
		AreaName: 'East London Central',
		Surch: null,
	},
	{
		PlaceName: 'WOODMEAD',
		Code: '2191    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'WOODMEAD EAST, Sunninghill',
		Code: '2157    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'WOODMEAD Ext/Uit 12,',
		Code: '2148    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'WOODMEAD, Johannesburg',
		Code: '2128    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'WOODMEAD-OOS, Sunninghill',
		Code: '2157    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'WOODMERE, Primrose',
		Code: '1401    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'WOODRIDGE, Mitchells Plain',
		Code: '7785    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'WOODSIDE, Pinetown',
		Code: '3629',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'WOODSTOCK',
		Code: '7925    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'WOODSTOCK Boxes',
		Code: '7915    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'WOODSTOCK, Cape Town',
		Code: '7925    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'WOODVIEW, Phoenix',
		Code: '4068',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'WOOLHOPE, Port Elizabeth',
		Code: '6020    ',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'WORCESTER, Western Cape',
		Code: '6850    ',
		Hub: 'CPT',
		Ring: 1,
		OpsHub: 'CPT',
		Dist: 120,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'WORLDS VIEW, Pietermaritzburg',
		Code: '3201    ',
		Hub: 'PMB',
		Ring: 1,
		OpsHub: 'PMB',
		Dist: 75,
		AreaName: 'Pietermaritzburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'WRENCHVILLE Boxes',
		Code: '8468    ',
		Hub: 'KUR',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 400,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'WRENCHVILLE, Kuruman',
		Code: '8460    ',
		Hub: 'KIM',
		Ring: 4,
		OpsHub: 'KIM',
		Dist: 420,
		AreaName: 'Kimberley Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'WRIGHT PARK, Springs',
		Code: '1559    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'WUPPERTAL',
		Code: '8138    ',
		Hub: 'CPT',
		Ring: 4,
		OpsHub: 'CPT',
		Dist: 260,
		AreaName: 'Cape Town Regional',
		Surch: 'Far Outlying',
	},
	{
		PlaceName: 'WYCHWOOD, Primrose',
		Code: '1401    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'WYDGELEE',
		Code: '7282    ',
		Hub: 'CPT',
		Ring: 3,
		OpsHub: 'CPT',
		Dist: 160,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'WYEBANK, Pinetown',
		Code: '3610',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'WYNBERG Cape Town, Boxes',
		Code: '7824    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'WYNBERG, Cape Town',
		Code: '7800    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'WYNBERG, Johannesburg',
		Code: '2090    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'WYNDOVER',
		Code: '7775    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'XABA Boxes',
		Code: '6233    ',
		Hub: 'PLZ',
		Ring: 1,
		OpsHub: 'PLZ',
		Dist: 80,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'XABA, Uitenhage',
		Code: '6230    ',
		Hub: 'PLZ',
		Ring: 1,
		OpsHub: 'PLZ',
		Dist: 50,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'XIGALO',
		Code: '0981    ',
		Hub: 'PTG',
		Ring: 4,
		OpsHub: 'PTG',
		Dist: 300,
		AreaName: 'Pietersburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'XIHOKO',
		Code: '0872    ',
		Hub: 'PTG',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 300,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'XIKUNDU',
		Code: '0980    ',
		Hub: 'PTG',
		Ring: 4,
		OpsHub: 'PTG',
		Dist: 300,
		AreaName: 'Pietersburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'XIMHUNGWE',
		Code: '1281    ',
		Hub: 'NLP',
		Ring: 4,
		OpsHub: 'NLP',
		Dist: 310,
		AreaName: 'Nelspruit Regional',
		Surch: null,
	},
	{
		PlaceName: 'XIPAME',
		Code: '0452    ',
		Hub: 'PRY',
		Ring: 4,
		OpsHub: 'PRY',
		Dist: 180,
		AreaName: 'Pretoria Regional',
		Surch: null,
	},
	{
		PlaceName: 'YELLOWWOOD PARK',
		Code: '4004',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: null,
	},
	{
		PlaceName: 'YELLOWWOOD PARK & Ext,',
		Code: '0300    ',
		Hub: 'JNB',
		Ring: 2,
		OpsHub: 'JNB',
		Dist: 120,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'YELLOWWOOD PARK Ext 4,',
		Code: '0300    ',
		Hub: 'JNB',
		Ring: 2,
		OpsHub: 'JNB',
		Dist: 120,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'YEOVILLE Boxes',
		Code: '2143    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'YEOVILLE, Johannesburg',
		Code: '2198    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'YORKSHIRE Est/Lgd, Lansdowne',
		Code: '7780    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'YOUNG PARK, Port Elizabeth',
		Code: '6001    ',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'YOUNG Tsp/Dgd, Port Elizabeth',
		Code: '6001    ',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'YOUNGSFIELD LIVING-QUARTE',
		Code: '7700    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'YOUNGSFIELD MILITARY CAMP',
		Code: '7780    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'YOUNGSFIELD MILITERE KAMP',
		Code: '7780    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'YOUNGSFIELD WOONKWARTIERE',
		Code: '7700    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'YSTERPLAAT AIR FORCE BASE',
		Code: '7405    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'YSTERPLAAT Boxes',
		Code: '7425    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'YSTERPLAAT LUGMAGBASIS,',
		Code: '7405    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'YSTERPLAAT, Maitland',
		Code: '7405    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'YZERFONTEIN',
		Code: '7351    ',
		Hub: 'CPT',
		Ring: 1,
		OpsHub: 'CPT',
		Dist: 80,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'ZAMDELA',
		Code: '1949',
		Hub: 'JNB',
		Ring: 2,
		OpsHub: 'JNB',
		Dist: 100,
		AreaName: 'Johannesburg Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'ZASTRON',
		Code: '9950    ',
		Hub: 'BFN',
		Ring: 4,
		OpsHub: 'BFN',
		Dist: 210,
		AreaName: 'Bloemfontein Regional',
		Surch: null,
	},
	{
		PlaceName: 'ZEBEDIELA',
		Code: '0627    ',
		Hub: 'PTG',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 300,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'ZEEKOEIVLEI, Grassy Park',
		Code: '7945    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'ZEERUST',
		Code: '2865    ',
		Hub: 'RBG',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 320,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'ZERILDA PARK, Retreat',
		Code: '7945    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'ZIMBALI',
		Code: '4399',
		Hub: 'DUR',
		Ring: 1,
		OpsHub: 'DUR',
		Dist: 50,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'ZINKWAZI, Darnall',
		Code: '4480    ',
		Hub: 'DUR',
		Ring: 3,
		OpsHub: 'DUR',
		Dist: 140,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'ZINNIAVILLE Boxes',
		Code: '0302    ',
		Hub: 'JNB',
		Ring: 2,
		OpsHub: 'JNB',
		Dist: 120,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'ZINNIAVILLE, Rustenburg',
		Code: '0300    ',
		Hub: 'JNB',
		Ring: 2,
		OpsHub: 'JNB',
		Dist: 120,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'ZOAR',
		Code: '6886    ',
		Hub: 'CPT',
		Ring: 4,
		OpsHub: 'CPT',
		Dist: 350,
		AreaName: 'Cape Town Regional',
		Surch: null,
	},
	{
		PlaceName: 'ZOLA & Ext/Uit, kwaXuma',
		Code: '1868    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 30,
		AreaName: 'Johannesburg Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'ZOLA NORTH, kwaXuma',
		Code: '1868    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 30,
		AreaName: 'Johannesburg Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'ZOLA-NOORD, kwaXuma',
		Code: '1868    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 30,
		AreaName: 'Johannesburg Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'ZOLANI, Ashton',
		Code: '6715    ',
		Hub: 'CPT',
		Ring: 4,
		OpsHub: 'CPT',
		Dist: 200,
		AreaName: 'Cape Town Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'ZONDI, kwaXuma',
		Code: '1868    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 30,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'ZONNEBLOEM, Woodstock',
		Code: '7925    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'ZONNEBLOM Ext/Uit 3,',
		Code: '2196    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 10,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'ZOO PARK, Kraaifontein',
		Code: '7570    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'ZORGVLIET, Ysterplaat',
		Code: '7405    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 10,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'ZUIPING',
		Code: '2622    ',
		Hub: 'JNB',
		Ring: 3,
		OpsHub: 'JNB',
		Dist: 120,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'ZULULAND',
		Code: '4240    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 150,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'ZUNCKELS',
		Code: '3341    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 250,
		AreaName: 'Durban Regional',
		Surch: null,
	},
	{
		PlaceName: 'ZUNEY',
		Code: '6187    ',
		Hub: 'PLZ',
		Ring: 2,
		OpsHub: 'PLZ',
		Dist: 110,
		AreaName: 'Port Elizabeth Regional',
		Surch: null,
	},
	{
		PlaceName: 'ZUURFONTEIN AgHs/Lhw,',
		Code: '1619    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 20,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'ZUURFONTEIN Boxes',
		Code: '1912    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 60,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'ZUURFONTEIN, Vanderbijlpark',
		Code: '1911    ',
		Hub: 'JNB',
		Ring: 1,
		OpsHub: 'JNB',
		Dist: 60,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'ZWAANSWYK Est/Lgd, Retreat',
		Code: '7945    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'ZWAANSWYK, Retreat',
		Code: '7945    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: null,
	},
	{
		PlaceName: 'ZWAVELPOORT,Pretoria',
		Code: '0081',
		Hub: 'PRY',
		Ring: 1,
		OpsHub: 'PRY',
		Dist: 20,
		AreaName: 'Pretoria Regional',
		Surch: null,
	},
	{
		PlaceName: 'ZWELETEMBA',
		Code: '6852    ',
		Hub: 'CPT',
		Ring: 2,
		OpsHub: 'CPT',
		Dist: 120,
		AreaName: 'Cape Town Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'ZWELITSHA',
		Code: '9999    ',
		Hub: 'ELS',
		Ring: 2,
		OpsHub: 'ELS',
		Dist: 60,
		AreaName: 'East London Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'ZWELITSHA, Nyanga',
		Code: '7755    ',
		Hub: 'CPT',
		Ring: 0,
		OpsHub: 'CPT',
		Dist: 20,
		AreaName: 'Cape Town Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'ZWIDE',
		Code: '6001    ',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'aMABELE',
		Code: '4931    ',
		Hub: 'ELS',
		Ring: 2,
		OpsHub: 'ELS',
		Dist: 90,
		AreaName: 'East London Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'eKHAYALIHLE',
		Code: '2510    ',
		Hub: 'JNB',
		Ring: 2,
		OpsHub: 'JNB',
		Dist: 100,
		AreaName: 'Johannesburg Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'eKULINDENI',
		Code: '1301    ',
		Hub: 'JNB',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 310,
		AreaName: 'Johannesburg Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'eLUKWATINI.',
		Code: '1192    ',
		Hub: 'NLP',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 250,
		AreaName: 'Johannesburg Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'eMALAHLENI, fna WITBANK',
		Code: '1039    ',
		Hub: 'MBG',
		Ring: 3,
		OpsHub: 'JNB',
		Dist: 140,
		AreaName: 'Johannesburg Regional',
		Surch: null,
	},
	{
		PlaceName: 'eMBALI',
		Code: '2513    ',
		Hub: 'JNB',
		Ring: 2,
		OpsHub: 'JNB',
		Dist: 100,
		AreaName: 'Johannesburg Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'eMLANJENI',
		Code: '0363    ',
		Hub: 'RBG',
		Ring: 2,
		OpsHub: 'JNB',
		Dist: 120,
		AreaName: 'Johannesburg Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'eMONDLO',
		Code: '3105    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 300,
		AreaName: 'Durban Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'eMPUMALANGA',
		Code: '0458    ',
		Hub: 'PRY',
		Ring: 4,
		OpsHub: 'PRY',
		Dist: 180,
		AreaName: 'Pretoria Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'eMPUNGA',
		Code: '3562    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 200,
		AreaName: 'Durban Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'eSIKHAWINI.',
		Code: '3887    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 250,
		AreaName: 'Durban Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'eZAKHENI.',
		Code: '3381    ',
		Hub: 'HSM',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 270,
		AreaName: 'Johannesburg Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'iMBALI',
		Code: '4503    ',
		Hub: 'DUR',
		Ring: 2,
		OpsHub: 'DUR',
		Dist: 100,
		AreaName: 'Durban Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'iNGOGO',
		Code: '2944    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 380,
		AreaName: 'Durban Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'iNGQAYIZIVELE',
		Code: '2957    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 330,
		AreaName: 'Durban Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'iZOTSHA',
		Code: '4242    ',
		Hub: 'DUR',
		Ring: 3,
		OpsHub: 'DUR',
		Dist: 130,
		AreaName: 'Durban Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'kaNYAMAZANE',
		Code: '1214    ',
		Hub: 'NLP',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 310,
		AreaName: 'Johannesburg Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'kwaBHUBESI',
		Code: '4684    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 180,
		AreaName: 'Durban Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'kwaDLANGEZWA, Empangeni',
		Code: '3886    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 160,
		AreaName: 'Durban Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'kwaDWESI, Zwide',
		Code: '6001    ',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'kwaFORD, Port Elizabeth',
		Code: '6200    ',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: null,
	},
	{
		PlaceName: 'kwaKWATSI, Koppies',
		Code: '9540    ',
		Hub: 'JNB',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 170,
		AreaName: 'Johannesburg Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'kwaLOYITI, East London',
		Code: '5209    ',
		Hub: 'ELS',
		Ring: 0,
		OpsHub: 'ELS',
		Dist: 10,
		AreaName: 'East London Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'kwaLUGEDLANE',
		Code: '1341    ',
		Hub: 'NLP',
		Ring: 4,
		OpsHub: 'JNB',
		Dist: 310,
		AreaName: 'Johannesburg Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'kwaMAGXAKI, Zwide',
		Code: '6001    ',
		Hub: 'PLZ',
		Ring: 0,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'kwaMAKUTHA, Amanzimtoti',
		Code: '4126',
		Hub: 'DUR',
		Ring: 0,
		OpsHub: 'DUR',
		Dist: 10,
		AreaName: 'Durban Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'kwaMASAKHANE',
		Code: '9474    ',
		Hub: 'BFN',
		Ring: 3,
		OpsHub: 'BFN',
		Dist: 160,
		AreaName: 'Bloemfontein Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'kwaMASHU',
		Code: '4359    ',
		Hub: 'DUR',
		Ring: 1,
		OpsHub: 'DUR',
		Dist: 50,
		AreaName: 'Durban Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'kwaMAYIBUYE',
		Code: '4643    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 180,
		AreaName: 'Durban Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'kwaMHLANGA',
		Code: '1022    ',
		Hub: 'JNB',
		Ring: 2,
		OpsHub: 'JNB',
		Dist: 90,
		AreaName: 'Johannesburg Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'kwaMSANE, Mtubatuba',
		Code: '3935    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 300,
		AreaName: 'Durban Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'kwaNGWANASE',
		Code: '3973    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 400,
		AreaName: 'Durban Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'kwaNONQABA',
		Code: '6502    ',
		Hub: 'GRJ',
		Ring: 1,
		OpsHub: 'GRJ',
		Dist: 50,
		AreaName: 'George Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'kwaSIZABANTU',
		Code: '3561    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 200,
		AreaName: 'Durban Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'kwaTHANDUXOLO',
		Code: '2509    ',
		Hub: 'JNB',
		Ring: 2,
		OpsHub: 'JNB',
		Dist: 100,
		AreaName: 'Johannesburg Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'kwaTHEMA',
		Code: '1563    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 40,
		AreaName: 'Johannesburg Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'kwaVUKILE',
		Code: '6143    ',
		Hub: 'PLZ',
		Ring: 4,
		OpsHub: 'PLZ',
		Dist: 170,
		AreaName: 'Port Elizabeth Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'kwaXUMA Boxes',
		Code: '1867    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 30,
		AreaName: 'Johannesburg Central',
		Surch: null,
	},
	{
		PlaceName: 'kwaXUMA Streets',
		Code: '1868    ',
		Hub: 'JNB',
		Ring: 0,
		OpsHub: 'JNB',
		Dist: 30,
		AreaName: 'Johannesburg Central',
		Surch: 'Township',
	},
	{
		PlaceName: 'kwaZAKHELE,Port Elizabeth',
		Code: '6205    ',
		Hub: 'PLZ',
		Ring: 1,
		OpsHub: 'PLZ',
		Dist: 10,
		AreaName: 'Port Elizabeth Regional',
		Surch: 'Township',
	},
	{
		PlaceName: 'oSIZWENI',
		Code: '2952    ',
		Hub: 'DUR',
		Ring: 4,
		OpsHub: 'DUR',
		Dist: 330,
		AreaName: 'Durban Regional',
		Surch: 'Township',
	},
];
