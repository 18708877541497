import React, {
	createContext,
	useContext,
	useState,
	useEffect,
	useMemo,
} from 'react';
import {
	transformCollectionSnap,
	getObjFromLink,
	getDistance,
	placesWithSelectedPostalCode,
	actualDeliveryPlaceInTheListOfPlaces,
	checkAreaName,
	deliveryFeeCalculator,
} from '../helpers';
import { useFirebase } from '../Firebase';
import { useUser, useAuth } from '../auth';
import {
	formatNewStock,
	formatUpdateStock,
	formatStockTakeStock,
	formatStockTake,
	formatStockItems,
	formatViewStockItems,
} from './formatting';
import { useBrandingGroups } from '../brandingGroups';
import { useSettings } from '../settings';
import { toastr } from 'react-redux-toastr';
import { useVariableSizes } from '../variableSizes';
import { DELIVERY_OPTIONS } from '../../features/Orders/constants';
import { CSVLink, CSVDownload } from 'react-csv';

import {
	addHours,
	differenceInHours,
	getMilliseconds,
	isBefore,
} from 'date-fns';
import moment from 'moment';

const StockContext = createContext({});

export const StockProvider = ({ children }) => {
	// const [deliveryPostalCode, setDeliveryPostalCode] = useState(null);
	const [stockData, setStockData] = useState(null);

	const user = useUser();
	const { settings, updateSettings, updateRatings } = useSettings();
	const { brandingGroups } = useBrandingGroups();
	const { companyLink, isAdmin, isClientAdmin, isApproved, isArchived } =
		useAuth();
	const compObj = companyLink ? getObjFromLink(companyLink) : {};
	const compId = compObj.id ? compObj.id : '';
	const firebase = useFirebase();
	const [stock, setStock] = useState([]);
	const [availableStock, setAvailableStock] = useState([]);
	const [availableViewStock, setAvailableViewStock] = useState([]);
	const [loading, setLoading] = useState(true);
	const { variableSizes } = useVariableSizes();
	const [viewStock, setViewStock] = useState([]);

	useEffect(() => {
		const unsubscribe =
			compId !== '' && isApproved && !isArchived
				? firebase.stock(compId).onSnapshot((snap) => {
						setStock(
							formatStockItems(transformCollectionSnap(snap), variableSizes)
						);
						setViewStock(
							formatViewStockItems(transformCollectionSnap(snap), variableSizes)
						);
						setLoading(false);
				  })
				: () => console.log('waiting for user to load');
		return () => unsubscribe();
	}, [firebase, compId, brandingGroups, isApproved, isArchived, variableSizes]);

	// useEffect(() => {
	// 	const unsubscribe =
	// 		compId !== ''
	// 			? firebase.stock(compId).onSnapshot((snap) => {
	// 					setStockData(
	// 						formatStockItems(transformCollectionSnap(snap), variableSizes)
	// 					);
	// 					// setStock(
	// 					// 	formatStockItems(transformCollectionSnap(snap), variableSizes)
	// 					// );
	// 					// setViewStock(
	// 					// 	formatViewStockItems(transformCollectionSnap(snap), variableSizes)
	// 					// );
	// 					setLoading(false);
	// 			  })
	// 			: () => console.log('waiting for user to load');
	// 	return () => unsubscribe();
	// }, [firebase, compId, brandingGroups, variableSizes]);
	// const headers = useMemo(() => {
	// 	return [
	// 		{ label: 'ID', key: 'id' },
	// 		{ label: 'DATE CREATED', key: 'dateCreated' },
	// 		{ label: 'name', key: 'name' },
	// 		{ label: 'CREATED BY', key: 'createdBy' },

	// 		{ label: 'LOCATIONS', key: 'locations' },

	// 		{ label: 'PRICE', key: 'price' },
	// 		{ label: 'STOCK TYPE', key: 'stockType' },
	// 		{ label: 'BRANDING GROUP', key: 'brandingGroup' },
	// 		{ label: 'ACTUAL DEPTH', key: 'actualDepth' },
	// 		{ label: 'ACTUAL HEIGHT', key: 'actualHeight' },
	// 		{ label: 'ACTUAL WIDTH', key: 'actualWidth' },

	// 		{ label: 'DESCRIPTION', key: 'description' },
	// 		{ label: 'ARCHIVED', key: 'isArchived' },

	// 	];
	// }, []);
	// // console.log(stockData, 'stock data');
	// const formattedData = useMemo(
	// 	() =>
	// 		stockData?.map((stock) => {
	// 			return {
	// 				actualDepth: stock.actualDepth ? stock.actualDepth : ' - ',
	// 				actualHeight: stock.actualHeight ? stock.actualHeight : ' - ',
	// 				actualWidth: stock.actualWidth ? stock.actualWidth : ' - ',
	// 				createdBy: stock.createdBy ? `${stock.createdBy.name}` : ' - ',
	// 				dateCreated: stock.dateCreated ? stock.dateCreated.toDate() : ' - ',
	// 				description: stock.description ? stock.description : ' - ',
	// 				id: stock.id ? stock.id : ' - ',
	// 				locations: stock.locations
	// 					? stock.locations.map((curr) => getObjFromLink(curr).name).join(',')
	// 					: ' - ',
	// 				name: stock.name ? stock.name : ' - ',
	// 				price: stock.price ? stock.price : ' - ',
	// 				stockType: stock.stockType ? stock.stockType : ' - ',
	// 				brandingGroup: stock.brandingGroupLink
	// 					? getObjFromLink(stock.brandingGroupLink).name
	// 					: ' - ',
	// 					isArchived: stock.isArchived
	// 					? 'YES'
	// 					: ' NO ',
	// 			};
	// 		}),
	// 	[stockData]
	// );
	// console.log(formattedData, 'from');
	useEffect(() => {
		const images = stock.reduce(
			(arr, item) => (item.imageLinks ? [...arr, ...item.imageLinks] : arr),
			[]
		);
	}, [stock]);

	useEffect(() => {
		if (isAdmin || isClientAdmin) {
			setAvailableStock(
				stock.filter((item) => {
					if (!settings.zeroQuantity) {
						return (
							item.quantities && item.quantities.every((q) => q.quantity > 0)
						);
					} else {
						return item.quantities;
					}
				})
			);
			setAvailableViewStock(
				viewStock.filter((item) => {
					if (!settings.zeroQuantity) {
						return (
							item.quantities && item.quantities.every((q) => q.quantity > 0)
						);
					} else {
						return item.quantities;
					}
				})
			);
		} else {
			if (user && user.locations && stock) {
				const filteredStock = stock
					.filter((item) => {
						if (item.locations) {
							return item.locations.some((location) =>
								user.locations.includes(location)
							);
						} else {
							const locations = item?.quantities?.map((stock) => {
								return stock.locationLink;
							});
							return locations?.some((location) =>
								user.locations.includes(location)
							);
						}
					})
					.filter((item) => {
						if (!settings.zeroQuantity) {
							return (
								item.quantities && item.quantities.every((q) => q.quantity > 0)
							);
						} else {
							return item.quantities;
						}
					});
				setAvailableStock(filteredStock);
			}
		}
	}, [stock, user, isAdmin, isClientAdmin, settings, viewStock]);

	const createStock = (stock) => {
		return firebase
			.createStock(compId, formatNewStock(stock, user))
			.then((stockID) => {
				toastr.success('Success', 'Stock item created');
				return stockID;
			});
	};

	const updateStock = (stock, showToast) => {
		if (Number(stock.price) < 0) {
			toastr.error('Error', 'Price may not be a negative number');
			return;
		}
		firebase.updateStock(compId, formatUpdateStock(stock, user)).then(() => {
			if (showToast || showToast === undefined) {
				toastr.success('Success', 'Stock item updated');
			}
		});
	};

	const getStockItem = (stockLink) => {
		const itemId = getObjFromLink(stockLink).id;
		return stock.find((s) => s.id === itemId);
	};

	const archiveStockItem = (stockId, archive) => {
		firebase
			.updateStock(
				compId,
				formatUpdateStock({ id: stockId, isArchived: archive }, user)
			)
			.then(() => {
				toastr.success(
					'Success',
					archive ? 'Stock Item Archived ' : 'Stock Item Reinstated '
				);
			});
	};

	const doStockTake = (stockItem, quantities) => {
		const unfilteredStockItem = stock.find((s) => s.id === stockItem.id);
		const otherLocations = unfilteredStockItem.quantities.filter((q) => {
			return q.locationLink !== quantities[0].locationLink;
		});
		const updatedStockItem = {
			...stockItem,
			quantities: [
				...otherLocations,
				...quantities.map((q) => ({
					locationLink: q.locationLink,
					size: q.size,
					quantity:
						q.counts && q.counts.goodCondition ? q.counts.goodCondition : 0,
				})),
			],
		};
		firebase
			.updateStock(
				compId,
				formatUpdateStock(formatStockTakeStock(updatedStockItem, user), user)
			)
			.then(
				firebase
					.stock(compId)
					.doc(stockItem.id)
					.collection('stockTake')
					.add(formatStockTake(quantities, user))
					.then(() => {
						toastr.success('Success', 'Stock Take complete');
					})
			);
	};

	const addStock = (stockItem, quantities, location) => {
		const fields = [
			{ label: 'New', name: 'new' },
			{ label: 'Repaired', name: 'repaired' },
			{ label: 'Found', name: 'found' },
		];
		const unfilteredStockItem = stock.find((s) => s.id === stockItem.id);
		const otherLocations = unfilteredStockItem.quantities
			? unfilteredStockItem.quantities.filter((q) => {
					return q.locationLink !== location;
			  })
			: [];
		const updatedStockItem = {
			...stockItem,
			quantities: [
				...otherLocations,
				...quantities.map((q) => ({
					locationLink: location,
					size: q.size,
					quantity:
						q.quantity +
						fields.reduce((count, field) => {
							if (
								q &&
								q.counts &&
								q.counts[field.name] &&
								q.counts[field.name] > 0
							) {
								return count + q.counts[field.name];
							} else {
								return count;
							}
						}, 0),
				})),
			],
		};
		const updatedQuantities = quantities.map((q) => ({
			locationLink: location,
			size: q.size,
			counts: q.counts,
			totalAdded: fields.reduce((count, field) => {
				if (q && q.counts && q.counts[field.name] && q.counts[field.name] > 0) {
					return count + q.counts[field.name];
				} else {
					return count;
				}
			}, 0),
			quantity:
				q.quantity +
				fields.reduce((count, field) => {
					if (
						q &&
						q.counts &&
						q.counts[field.name] &&
						q.counts[field.name] > 0
					) {
						return count + q.counts[field.name];
					} else {
						return count;
					}
				}, 0),
		}));
		firebase
			.stock(compId)
			.doc(updatedStockItem.id)
			.update(formatUpdateStock(updatedStockItem, user))
			.then(() => {
				const add = formatStockTake(updatedQuantities, user);

				firebase
					.stock(compId)
					.doc(stockItem.id)
					.collection('stockAdditions')
					.add(add)
					.then(() => {
						toastr.success('Success', 'Stock added');
					});
			});
	};

	const updateDispatchedStockItems = (orderStock) => {
		const stockIds = Array.from(
			new Set(
				orderStock.map((s) => {
					return getObjFromLink(s.stockLink).id;
				})
			)
		);
		const dispatchedStockItems = stock.filter((s) => stockIds.includes(s.id));

		return dispatchedStockItems.map((item) => {
			const orderItems = orderStock.filter(
				(s) => item.id === getObjFromLink(s.stockLink).id
			);

			if (item?.dispatchedQuantities?.length) {
				let quantities = item.dispatchedQuantities;
				orderItems.forEach((qty) => {
					const existingQty = item.dispatchedQuantities.find(
						(q) => q.locationLink === qty.locationLink && q.size === qty.size
					);
					if (existingQty) {
						const updatedQuantities = quantities.filter(
							(q) =>
								!(q.locationLink === qty.locationLink && q.size === qty.size)
						);
						quantities = [
							...updatedQuantities,
							{
								...existingQty,
								quantity: qty.quantity + existingQty.quantity,
							},
						];
					}
				});
				return {
					id: item.id,
					dispatchedQuantities: quantities.map((i) => ({
						locationLink: i.locationLink,
						quantity: i.quantity,
						size: i.size,
					})),
				};
			} else {
				return {
					id: item.id,
					dispatchedQuantities: orderItems.map((i) => ({
						locationLink: i.locationLink,
						quantity: i.quantity,
						size: i.size,
					})),
				};
			}
		});
	};

	const updateReceivedStockItems = (orderStock) => {
		const fields = [
			{ label: 'Good Condition', name: 'goodCondition' },
			{ label: 'Damaged', name: 'damaged' },
			{ label: 'Lost', name: 'lost' },
			{ label: 'Stolen', name: 'stolen' },
			{ label: 'Promo', name: 'promo' },
			{ label: 'Other', name: 'other' },
		];
		const stockIds = Array.from(
			new Set(
				orderStock.map((s) => {
					return s.id
						? s.id
						: s.stockLink
						? getObjFromLink(s.stockLink).id
						: null;
				})
			)
		);

		const receivedStockItems = stock.filter((s) => stockIds.includes(s.id));

		return receivedStockItems.map((item) => {
			const orderItems = orderStock.filter((s) =>
				s.id
					? item.id === s.id
					: s.stockLink
					? getObjFromLink(s.stockLink).id === item.id
					: false
			);

			let dispatchedQuantities = item.dispatchedQuantities || [];
			let quantities = item.quantities;

			orderItems.forEach((qty) => {
				const existingDispatchQty = item.dispatchedQuantities?.find(
					(q) => q.locationLink === qty.locationLink && q.size === qty.size
				);
				const existingQty = item.quantities?.find(
					(q) => q.locationLink === qty.locationLink && q.size === qty.size
				);

				if (existingDispatchQty) {
					const updatedQuantities = dispatchedQuantities?.filter(
						(q) => !(q.locationLink === qty.locationLink && q.size === qty.size)
					);

					const totalReceived = fields.reduce((count, { name }) => {
						if (qty.counts && qty.counts[name] && qty.counts[name] > 0) {
							return count + qty.counts[name];
						} else {
							return count;
						}
					}, 0);

					dispatchedQuantities = [
						...updatedQuantities,
						{
							...existingDispatchQty,
							quantity: existingDispatchQty.quantity - totalReceived,
						},
					];
				}
				if (existingQty) {
					const updatedQuantities = quantities.filter(
						(q) => !(q.locationLink === qty.locationLink && q.size === qty.size)
					);

					const totalToSubtract = fields.reduce((count, { name }) => {
						if (!(name === 'goodCondition' || name === 'other')) {
							if (qty.counts && qty.counts[name] && qty.counts[name] > 0) {
								return count + qty.counts[name];
							} else {
								return count;
							}
						} else {
							return count;
						}
					}, 0);

					quantities = [
						...updatedQuantities,
						{
							...existingQty,
							quantity: existingQty.quantity - totalToSubtract,
						},
					];
				}
			});

			return {
				id: item.id,
				dispatchedQuantities: dispatchedQuantities?.map((i) => ({
					locationLink: i.locationLink,
					quantity: i.quantity,
					size: i.size,
				})),
				quantities: quantities?.map((i) => ({
					locationLink: i.locationLink,
					quantity: i.quantity,
					size: i.size,
				})),
			};
		});
	};

	const getGroupedStockArray = (stockQuantities, property, stock) => {
		const groupedStockObj = stockQuantities.reduce((r, a) => {
			r[a[property]] = r[a[property]] || [];
			r[a[property]].push(a);
			return r;
		}, Object.create(null));

		const groupedStockArr = Object.keys(groupedStockObj).map((prop) => {
			if (stock) {
				return {
					[property]: prop,
					stockLink: `${stock.id}__${stock.name}`,
					quantities: groupedStockObj[prop],
				};
			} else {
				return {
					[property]: prop,
					quantities: groupedStockObj[prop],
				};
			}
		});
		return groupedStockArr;
	};

	const getStockTotals = (stockItem, location, noLocation = false) => {
		//Group stock by sizes

		let groupedStockArr = stockItem.quantities
			? getGroupedStockArray(
					stockItem.quantities.filter((q) =>
						location ? q.locationLink === location : q
					),
					'size',
					stockItem
			  )
			: [];
		// console.log(adminLocations, 'adminLocations');
		// if (adminLocations) {
		// 	const locationStockItemsQty = stockItem.quantities.filter((item) => {
		// 		const isFound = adminLocations.includes(item.locationLink);
		// 		console.log(isFound, 'isFound');
		// 		if (isFound) {
		// 			return true;
		// 		}
		// 		return false;
		// 	});
		// 	console.log(stockItem.quantities, 'gdgdgdg');
		// 	console.log(adminLocations, 'admin locations');
		// }
		if (noLocation) {
			groupedStockArr = stockItem.quantities
				? getGroupedStockArray(stockItem.quantities, 'size', stockItem)
				: [];
		}
		const groupedStockTotals = groupedStockArr.map((group) => {
			if (group.quantities) {
				return {
					...group,
					total: group.quantities.reduce(function (count, o) {
						return count + o.quantity;
					}, 0),
				};
			} else {
				return { ...group, total: 0 };
			}
		});
		return groupedStockTotals;
	};

	const getLocationTotals = (quantities) => {
		const total = quantities.reduce((count, item) => {
			return (count += item.quantity);
		}, 0);
		return total;
	};

	const getStockTotalsCart = (items, appliedDiscount) => {
		const totalItems = items.map((item) => {
			const stockObj = getObjFromLink(item.stockLink);
			const locationLink = item.locationLink;
			const unitPrice =
				stock.find((stockItem) => stockItem.id === stockObj.id)?.price || 0;

			let totalQuantity = 0;
			if (item.quantities) {
				item.quantities.forEach((i) => (totalQuantity += i.quantity));
			} else {
				totalQuantity += item.quantity;
			}
			return { ...stockObj, unitPrice, totalQuantity, locationLink };
		});

		const totalPrice = totalItems.reduce(
			(count, item2) => (count += item2.unitPrice * item2.totalQuantity),
			0
		);

		const cartObj = {
			totalPrice: appliedDiscount
				? totalPrice - appliedDiscount.sum
				: totalPrice,
			totalItems: [...totalItems],
		};
		return cartObj;
	};
	const getSavedStockTotalsCartForCheckout = (items, appliedDiscount) => {
		let totalItems = items.map((item) => {
			const stockObj = getObjFromLink(item.stockLink);
			const locationLink = item.locationLink;
			const unitPrice =
				stock.find((stockItem) => stockItem.id === stockObj.id)?.price || 0;

			let totalQuantity = 0;
			if (item.quantities) {
				item.quantities.forEach((i) => (totalQuantity += i.quantity));
			} else {
				totalQuantity += item.quantity;
			}
			return { ...stockObj, unitPrice, totalQuantity, locationLink };
		});
		if (items[0].id) {
			totalItems = items.map((item) => {
				const stockObj = getObjFromLink(item.stockLink);
				const locationLink = item.locationLink;
				const unitPrice =
					items.find((stockItem) => stockItem.id === stockObj.id)?.price || 0;

				let totalQuantity = item.quantity;

				return { ...stockObj, unitPrice, totalQuantity, locationLink };
			});
		}

		const totalPrice = totalItems.reduce(
			(count, item2) => (count += item2.unitPrice * item2.totalQuantity),
			0
		);

		const cartObj = {
			totalPrice: appliedDiscount
				? totalPrice - appliedDiscount.sum
				: totalPrice,
			totalItems: [...totalItems],
		};
		return cartObj;
	};
	const getStockTotalsSavedCart = (items, appliedDiscount, savedStock) => {
		let totalItems = items.map((item) => {
			const stockObj = getObjFromLink(item.stockLink);
			const locationLink = item.locationLink;
			const unitPrice =
				stock.find((stockItem) => stockItem.id === stockObj.id)?.price || 0;

			let totalQuantity = 0;
			if (item.quantities) {
				item.quantities.forEach((i) => (totalQuantity += i.quantity));
			} else {
				totalQuantity += item.quantity;
			}
			return { ...stockObj, unitPrice, totalQuantity, locationLink };
		});
		if (savedStock[0].id) {
			totalItems = items?.map((item) => {
				const stockObj = getObjFromLink(item.stockLink);
				const locationLink = item.locationLink;
				const unitPrice =
					savedStock?.find((stockItem) => stockItem.id === stockObj.id)
						?.price || 0;

				let totalQuantity = 0;
				if (item.quantities) {
					item.quantities.forEach((i) => (totalQuantity += i.quantity));
				} else {
					totalQuantity += item.quantity;
				}
				return { ...stockObj, unitPrice, totalQuantity, locationLink };
			});
		}

		const totalPrice = totalItems.reduce(
			(count, item2) => (count += item2.unitPrice * item2.totalQuantity),
			0
		);

		const cartObj = {
			totalPrice: appliedDiscount
				? totalPrice - appliedDiscount.sum
				: totalPrice,
			totalItems: [...totalItems],
		};
		return cartObj;
	};

	const getItemsDeliveryTotals = async (
		orderStock,
		deliveryCollectionAddress,
		warehouseAddress,
		sortedDeliverySettings,
		order
	) => {
		const deliveryDetails = {
			totalVolume: '',
			appliedVehicle: '',
			deliveryFee: '',
			distance: await getDistance(
				deliveryCollectionAddress,
				warehouseAddress
			).then((data) => data / 1000),
		};
		const warehousePostalCode = warehouseAddress
			.split(',')
			.filter((curr) => {
				const checkItAnumber = parseInt(curr.replace(/\s+/g, '')) + 1;

				return !isNaN(checkItAnumber) && curr.replace(/\s+/g, '').length === 4;
			})
			.join('')
			.trim();
		const deliveryPostalCode = deliveryCollectionAddress
			.split(',')
			.filter((curr) => {
				const checkItAnumber = parseInt(curr.replace(/\s+/g, '')) + 1;

				return !isNaN(checkItAnumber) && curr.replace(/\s+/g, '').length === 4;
			})
			.join('')
			.trim();
		console.log(deliveryPostalCode, 'delPostalCode');
		const stockList = orderStock.map((item) => item.quantities).flat();
		const typesOfDeliveries = settings?.deliveryRates;
		console.log(settings, 'settings');
		// const typesOfDeliveries = {
		// 	majortomajor: {
		// 		type: 'major to major',
		// 		overnight: {
		// 			service: 'ONX',
		// 			minim_for_kg: 86.67,
		// 			kgs: 2,
		// 			rate_per_kg_thereafter: 44.12,
		// 		},
		// 		forthyEightToSeventyTwoHours: {
		// 			service: 'ECO',
		// 			minim_for_kg: 104.82,
		// 			kgs: 10,
		// 			rate_per_kg_thereafter: 44.12,
		// 		},
		// 		sameDayExpress: {
		// 			service: 'SDX',
		// 			minim_for_kg: 643,
		// 			kgs: 2,
		// 			rate_per_kg_thereafter: 44.12,
		// 		},
		// 	},
		// 	majortooutLying: {
		// 		type: 'major to outlying',
		// 		twentyEightToFourtyEightHours: {
		// 			service: 'ONX',
		// 			minim_for_kg: 170.04,
		// 			kgs: 2,
		// 			rate_per_kg_thereafter: 53.06,
		// 		},
		// 		seventyToNinethySixHours: {
		// 			service: 'ECO',
		// 			minim_for_kg: 150.08,
		// 			kgs: 10,
		// 			rate_per_kg_thereafter: 53.06,
		// 		},
		// 	},
		// 	local: {
		// 		type: 'local',
		// 		overnight: {
		// 			service: 'ONX',
		// 			minim_for_kg: 75.11,
		// 			kgs: 2,
		// 			rate_per_kg_thereafter: 7.43,
		// 		},
		// 		twentyEightToFourtyEightHours: {
		// 			service: 'ECO',
		// 			minim_for_kg: 75.11,
		// 			kgs: 10,
		// 			rate_per_kg_thereafter: 7.43,
		// 		},
		// 		sameDayExpress: {
		// 			service: 'LSD',
		// 			minim_for_kg: 196.62,
		// 			kgs: 2,
		// 			rate_per_kg_thereafter: 13.11,
		// 		},
		// 	},
		// };
		const deliveryPlaceInTheList = actualDeliveryPlaceInTheListOfPlaces(
			deliveryPostalCode,
			deliveryCollectionAddress
		);
		console.log(deliveryPlaceInTheList, 'place in the list');
		console.log(deliveryPostalCode, 'postal code');
		console.log(deliveryCollectionAddress, 'collection adrr');
		const warehousePlaceInTheList = actualDeliveryPlaceInTheListOfPlaces(
			warehousePostalCode,
			warehouseAddress
		);
		let deliveryFee = null;
		if (typesOfDeliveries) {
			const deliveryType = (() => {
				if (
					checkAreaName(deliveryPlaceInTheList?.AreaName) === 'central' &&
					checkAreaName(warehousePlaceInTheList?.AreaName) === 'central'
				) {
					if (
						deliveryPlaceInTheList?.Hub.trim() ===
						warehousePlaceInTheList?.Hub.trim()
					) {
						return typesOfDeliveries.local;
					}
					return typesOfDeliveries.majortomajor;
				}
				if (
					checkAreaName(deliveryPlaceInTheList?.AreaName) === 'regional' &&
					checkAreaName(warehousePlaceInTheList?.AreaName) === 'central'
				) {
					return typesOfDeliveries.majortooutlying;
				}
			})();
			const totalWeight = stockList.reduce(
				(acc, item) =>
					+getStockItem(item.stockLink).weight
						? item.quantity * +getStockItem(item.stockLink).weight + acc
						: 0,
				0
			);

			const volumetricWeight = stockList.reduce((acc, item) => {
				const dimensionsProduct =
					+getStockItem(item.stockLink).actualDepth *
					+getStockItem(item.stockLink).actualHeight *
					+getStockItem(item.stockLink).actualWidth;
				const volumetricWeight = dimensionsProduct / 5000;
				const totalVolumetricWeight = volumetricWeight * item.quantity;
				return totalVolumetricWeight + acc;
			}, 0);

			const maximumWeight = Math.max(totalWeight, volumetricWeight);

			const differenceInDeliveryHours = differenceInHours(
				order?.dateUpdated?.toDate(),
				order?.dispatchDate?.toDate()
			);
			const updatedDatePlusFourtyEightHours = addHours(
				order?.dateUpdated?.toDate(),
				48
			);
			const updatedDatePlusFourtyEightHoursSettedToEleven = new Date(
				moment(updatedDatePlusFourtyEightHours).hour(11).minutes(0).seconds(0)
			);

			deliveryFee = (() => {
				let selectedService = {
					serviceName: '',
					totalPrice: 0,
					type: '',
					minimumForKg: 0,
					ratePerKgThereAfter: 0,
					minKg: 0,
					transit: '',
				};
				if (deliveryType?.type === 'major to major') {
					if (differenceInDeliveryHours > 48) {
						// const { totalPrice, serviceName } = deliveryFeeCalculator(
						// 	maximumWeight,
						// 	deliveryType.forthyEightToSeventyTwoHours

						// );

						selectedService = deliveryFeeCalculator(
							maximumWeight,
							deliveryType.forthyEightToSeventyTwoHours,
							'Major Center To major Center',
							'48-72HRS'
						);
					} else {
						if (
							isBefore(
								order.dispatchDate.toDate(),
								updatedDatePlusFourtyEightHoursSettedToEleven
							)
						) {
							selectedService = deliveryFeeCalculator(
								maximumWeight,
								deliveryType.sameDayExpress,
								'Major Center To major Center',
								'Same Day Express'
							);

							// selectedService = {
							// 	serviceName,
							// 	totalPrice,
							// };
						} else {
							selectedService = deliveryFeeCalculator(
								maximumWeight,
								deliveryType.overnight,
								'Major Center To major Center',
								'Over Night By 11h00'
							);

							// selectedService = {
							// 	serviceName,
							// 	totalPrice,
							// };
						}
					}
				} else if (deliveryType?.type === 'major to outlying') {
					if (differenceInDeliveryHours > 48) {
						selectedService = deliveryFeeCalculator(
							maximumWeight,
							deliveryType.seventyToNinethySixHours,
							'Major Center To Outlying Area',
							'72-96HRS'
						);

						// selectedService = {
						// 	serviceName,
						// 	totalPrice,
						// };
					} else {
						selectedService = deliveryFeeCalculator(
							maximumWeight,
							deliveryType.twentyEightToFourtyEightHours,
							'Major Center To Outlying Area',
							'24-48HRS'
						);

						// selectedService = {
						// 	serviceName,
						// 	totalPrice,
						// };
					}
				} else if (deliveryType?.type === 'local') {
					if (differenceInDeliveryHours > 24) {
						selectedService = deliveryFeeCalculator(
							maximumWeight,
							deliveryType.twentyEightToFourtyEightHours,
							'Local',
							'24-48HRS'
						);

						// selectedService = {
						// 	serviceName,
						// 	totalPrice,
						// };
					} else {
						if (
							isBefore(
								order.dispatchDate.toDate(),
								updatedDatePlusFourtyEightHoursSettedToEleven
							)
						) {
							selectedService = deliveryFeeCalculator(
								maximumWeight,
								deliveryType.sameDayExpress,
								'Local',
								'Same Day Express'
							);

							// selectedService = {
							// 	serviceName,
							// 	totalPrice,
							// };
						} else {
							selectedService = deliveryFeeCalculator(
								maximumWeight,
								deliveryType.overnight,
								'Local',
								'Over Night By 11h00'
							);

							// selectedService = {
							// 	serviceName,
							// 	totalPrice,
							// };
						}
					}
				}
				return selectedService;
			})();
		}

		// console.log(
		// 	new Date(
		// 		moment(updatedDatePlusFourtyEightHours).hour(11).minutes(0).seconds(0)
		// 	),
		// 	'jdjd'
		// );

		DELIVERY_OPTIONS.LOCAL_AREAS.map((area) => {
			if (deliveryCollectionAddress.includes(area)) {
				deliveryDetails.totalVolume = stockList.reduce(
					(sumVolume, itemVolume) => {
						if (itemVolume.transportSize) {
							const commonItemTransportSize =
								Object.values(itemVolume.transportSize).reduce(
									(param1, param2) => param1 * param2
								) * itemVolume.quantity;

							return sumVolume + commonItemTransportSize;
						}
					},
					0
				);
			}
		});
		if (deliveryFee) {
			deliveryDetails.deliveryFee = deliveryFee.totalPrice;
			deliveryDetails.service = deliveryFee.serviceName;
			deliveryDetails.minimumForKg = deliveryFee.minimumForKg;
			deliveryDetails.ratePerKgThereAfter = deliveryFee.ratePerKgThereAfter;
			deliveryDetails.minKg = deliveryFee.minKg;
			deliveryDetails.type = deliveryFee.type;
			deliveryDetails.transit = deliveryFee.transit;
		}

		if (deliveryDetails.totalVolume) {
			sortedDeliverySettings.map((option, idx) => {
				if (idx === 0 && deliveryDetails.totalVolume <= option?.capacity) {
					// deliveryDetails.appliedVehicle = option.vehicle;
					// if (deliveryDetails.distance > 100) {
					// 	deliveryDetails.deliveryFee = option.outOfRangePrice;
					// } else {
					// 	deliveryDetails.deliveryFee = option.inRangePrice;
					// }
				} else {
					if (
						deliveryDetails.totalVolume >
							sortedDeliverySettings[idx - 1]?.capacity &&
						deliveryDetails.totalVolume <= option?.capacity
					) {
						deliveryDetails.appliedVehicle = option.vehicle;
						if (deliveryDetails.distance > 100) {
							// deliveryDetails.deliveryFee = option.outOfRangePrice;
						} else {
							// deliveryDetails.deliveryFee = option.inRangePrice;
						}
					}
				}
			});

			return deliveryDetails;
		}
	};

	return (
		<StockContext.Provider
			value={{
				loading,
				stock,
				viewStock,
				availableStock,
				getStockItem,
				getStockTotals,
				getGroupedStockArray,
				updateDispatchedStockItems,
				updateReceivedStockItems,
				getLocationTotals,
				createStock,
				updateStock,
				archiveStockItem,
				doStockTake,
				addStock,
				getStockTotalsCart,
				getItemsDeliveryTotals,
				getStockTotalsSavedCart,
				getSavedStockTotalsCartForCheckout,
				availableViewStock,
				// formattedData,
				// headers,
				// setDeliveryPostalCode,
			}}
		>
			{children}
		</StockContext.Provider>
	);
};

export const StockConsumer = StockContext.Consumer;

export const useStock = () => useContext(StockContext);
