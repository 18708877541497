import { useState, useEffect, useCallback } from 'react';
import moment from 'moment';
import { useStock } from '../../context/stock/index';

export function useForm(formFields, initialValues) {
	const init = (arr) =>
		arr?.reduce(
			(acc, cur) => {
				let formValid = acc?.formValid;
				if (formValid && !cur?.optional) {
					formValid =
						initialValues && initialValues[cur.name]
							? cur?.validate(initialValues[cur.name])
							: cur?.value && cur.validate
							? cur?.validate(cur.value)
							: false;
				}

				return {
					...acc,
					formValid,
					[cur.name]: {
						value:
							initialValues && initialValues[cur.name]
								? cur.type === 'date'
									? moment(initialValues[cur.name]).isValid()
										? moment(initialValues[cur.name])
										: moment(initialValues[cur.name].toDate())
									: initialValues[cur.name]
								: cur.value
								? cur.value
								: '',
						validate: cur?.validate,
						error: cur?.error,
						valid: cur?.optional
							? true
							: initialValues && initialValues[cur?.name]
							? cur.type === 'date'
								? moment(initialValues[cur.name]).isValid()
									? cur.validate(moment(initialValues[cur.name]))
									: cur.validate(moment(initialValues[cur.name].toDate()))
								: cur.validate(initialValues[cur.name])
							: cur.value && cur.validate
							? cur.validate(cur.value)
							: false,
						touched:
							cur.touched !== undefined
								? cur.touched
								: cur.value
								? true
								: false,
						type: cur.type,
						blur: false,
						readOnly: cur.readOnly,
					},
				};
			},
			{ formValid: true }
		);

	const [values, setValues] = useState(init(formFields));
	const [isFormValid, setFormValid] = useState(true);

	useEffect(() => {
		const valid = Object.keys(values)
			.filter((field) => !values[field].optional)
			.filter((field) => field !== 'formValid')
			.every((field) => {
				return values[field].valid === true;
			});
		setFormValid(valid);
	}, [formFields, values]);

	const inputProps = (name) => ({
		name,
		type: values[name].type,
		onChange:
			values[name].type === 'select' ||
			values[name].type === 'multiselect' ||
			values[name].type === 'radio'
				? handleSemanticChange
				: values[name].type === 'checkbox'
				? handleCheckboxChange
				: handleChange,
		value: values[name].value,
		className: touchedInvalid(name)
			? 'invalid'
			: touchedValid(name)
			? 'valid'
			: '',
		onBlur: handleBlur,
		readOnly: values[name].readOnly,
	});

	const updateValue = (name, value, valid) => {
		setValues((v) => {
			return {
				...v,
				[name]: { ...values[name], ...value },
				formValid: valid,
			};
		});
	};

	const validateField = (name, value) => {
		return values[name].validate ? values[name].validate(value) : true;
	};

	const checkValidFormForFeild = (name, valid) => {
		return formFields
			.filter(({ optional }) => !optional)
			.every((field) =>
				field.name === name ? valid : values[field.name].valid
			);
	};

	const handleCheckboxChange = (e, { name }) => {
		e.persist();
		const valid = validateField(name, !values[name].value);
		const formValid = checkValidFormForFeild(name, valid);

		const updatedValue = {
			value: !values[name].value,
			touched: true,
			valid,
		};

		updateValue(name, updatedValue, formValid);
	};

	const handleChange = (e) => {
		e.persist();

		const valid = validateField(e.target.name, e.target.value);
		const formValid = checkValidFormForFeild(e.target.name, valid);

		const updatedValue = {
			value: e.target.value,
			touched: true,
			valid,
		};

		updateValue(e.target.name, updatedValue, formValid);
	};

	const handleSemanticChange = (e, { name, value }) => {
		const valid = validateField(name, value);
		const formValid = checkValidFormForFeild(name, valid);
		const updatedValue = {
			value: value,
			touched: true,
			valid,
		};
		updateValue(name, updatedValue, formValid);
	};

	const handleLocationChange = (address, name) => {
		const postalAdress = address?.addressObject?.address_components.find(
			(component) => component.types.includes('postal_code')
		);

		const province = address?.addressObject?.address_components.find(
			(component) =>
				component.types.includes('administrative_area_level_1') &&
				component.types.includes('political')
		);
		const provinceName = province && province.long_name;
		let fullAddress;
		if (provinceName) {
			fullAddress = `${address.addressObject.formatted_address}, ${provinceName}`;
		} else {
			fullAddress = address.addressObject.formatted_address;
		}
		const valid = validateField(name, fullAddress);
		const formValid = checkValidFormForFeild(name, valid);
		const updatedValue = {
			value: fullAddress,
			touched: true,
			valid,
		};
		updateValue(name, updatedValue, formValid);
	};

	const handleDateChange = ({ date, name }) => {
		const valid = validateField(name, date);
		const formValid = checkValidFormForFeild(name, valid);

		const updatedValue = {
			value: date,
			touched: true,
			valid: valid,
		};

		updateValue(name, updatedValue, formValid);
	};

	const handleBlur = (e) => {
		const name = e.target.name;
		if (name) {
			setValues((v) => ({ ...v, [name]: { ...v[name], blur: true } }));
		}
	};

	const reset = () => setValues(init(formFields));

	const showError = (name) => {
		if (values[name].touched && !values[name].valid) {
			return true;
		}
		return false;
	};

	const showErrorIfInvalid = (name) => {
		if (values[name].touched && !values[name].valid && values[name].blur) {
			return values[name].error;
		}
		return false;
	};

	const fieldValid = (name) => values[name].valid;
	const fieldTouched = (name) => values[name].touched;

	const valueOf = (name) => values[name].value;

	const touchedInvalid = (name) => fieldTouched(name) && !fieldValid(name);
	const touchedValid = (name) => fieldTouched(name) && fieldValid(name);

	const getformattedValues = () => {
		const valuesForFormatting = {};

		Object.keys(values)
			.filter((key) => values[key].value)
			.forEach((key) => {
				valuesForFormatting[key] = values[key];
			});

		const formattedValues = {};

		Object.keys(valuesForFormatting).forEach((key) => {
			if (values[key].type === 'date') {
				formattedValues[key] = moment(values[key].value).toDate();
			} else {
				formattedValues[key] = values[key].value;
			}
		});
		return formattedValues;
	};

	// Allows for the manual handling of data, negates the event property
	const handleChangeWithoutEvent = useCallback(
		(name, value) => {
			const valid = validateField(name, value);
			const formValid = checkValidFormForFeild(name, valid);

			const updatedValue = { value: value, touched: true, valid };

			updateValue(name, updatedValue, formValid);
		},
		[checkValidFormForFeild, updateValue, validateField]
	);

	const formattedValues = getformattedValues();

	return {
		values,
		valueOf,
		handleChange,
		showError,
		inputProps,
		fieldValid,
		fieldTouched,
		touchedValid,
		formValid: values.formValid,
		showErrorIfInvalid,
		reset,
		formattedValues,
		handleLocationChange,
		handleDateChange,
		isFormValid,
		handleChangeWithoutEvent,
	};
}
