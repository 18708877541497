import React, { useState, useEffect } from 'react';
import { ToastProvider } from 'react-toast-notifications';
import { ThemeProvider } from 'styled-components';
import { theme, StyledPage, GlobalStyle } from '../styles/theme';
import Router from './Router';
import { FirebaseProvider } from '../context/Firebase';
import { AuthProvider, useAuth } from '../context/auth';
import { DataProvider } from '../context/data';
import { getObjFromLink } from '../context/helpers';
import companyThemes from './config/companyThemes';
import ErrorBoundary from './components/ErrorBoundary';
import CheckVersion from './components/CheckVersion';

const StyledApp = () => {
	const { companyLink } = useAuth();
	const [compTheme, setCompTheme] = useState(theme);

	useEffect(() => {
		const link = localStorage.getItem('companyLink');
		if (companyLink) {
			const companyObj = getObjFromLink(companyLink);
			setCompTheme((prev) => ({
				...prev,
				...companyThemes[companyObj.id],
			}));
		} else if (link) {
			const companyObj = getObjFromLink(link);
			setCompTheme((prev) => ({
				...prev,
				...companyThemes[companyObj.id],
			}));
		}
	}, [companyLink]);

	return (
		<ThemeProvider theme={compTheme}>
			<ToastProvider>
				<StyledPage>
					{/* <CheckVersion /> */}
					<GlobalStyle />
					<Router />
				</StyledPage>
			</ToastProvider>
		</ThemeProvider>
	);
};

const App = () => {
	return (
		<ErrorBoundary>
			<FirebaseProvider>
				<AuthProvider>
					<DataProvider>
						<StyledApp />
					</DataProvider>
				</AuthProvider>
			</FirebaseProvider>
		</ErrorBoundary>
	);
};

export default App;
