export const CHECKOUT_PROCESS = {
	ORDER_DETAILS: 1,
	DELIVERY_DETAILS: 2,
	ORDER_CART: 3,
	CONFIRM_ORDER: 4,
	CHECKOUT: 5,
};

export const PAYMENT_METHODS = {
	PAYFAST: {
		shortName: 'PayFast',
		label: 'PayFast',
	},
	EFT: {
		shortName: 'EFT',
		label: 'Electronic Funds Transfer (EFT)',
	},
	COSTCENTRE: {
		shortName: 'CC',
		label: 'Cost Centre (CC)',
	},
};

export const DELIVERY_OPTIONS = {
	LOCAL_AREAS: ['Gauteng', 'KwaZulu-Natal', 'Western Cape'],
};

export const DEFAULT_TRANSPORT_SIZE = {
	depth: '50',
	transportHeight: '50',
	transportWidth: '50',
};
