import React, { createContext, useContext, useState, useEffect } from 'react';
import { transformCollectionSnap, getObjFromLink } from '../helpers';
import { useFirebase } from '../Firebase';
import { useUser, useAuth } from '../auth';
import { toastr } from 'react-redux-toastr';
import { formatNewSize, formatSize } from './formatting.js';

const VariableSizesContext = createContext({});

export const VariableSizesProvider = ({ children }) => {
	const user = useUser();
	const { companyLink, isApproved, isArchived } = useAuth();
	const compObj = companyLink ? getObjFromLink(companyLink) : {};
	const compId = compObj.id ? compObj.id : '';
	const firebase = useFirebase();
	const [variableSizes, setVariableSizes] = useState([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const unsubscribe =
			compId !== '' && isApproved && !isArchived
				? firebase.variableSizes(compId).onSnapshot((snap) => {
						setVariableSizes(transformCollectionSnap(snap));
						setLoading(false);
				  })
				: () => console.log('waiting for user to load');
		return () => unsubscribe();
	}, [firebase, compId, isApproved, isArchived]);

	const addSize = (size) => {
		firebase
			.variableSizes(compId)
			.add(formatNewSize(size, user))
			.then(() => {
				toastr.success('Success', 'New size has been added');
			});
	};

	const updateSizesOrder = async (sizes) => {
		const batch = firebase.db.batch();
		sizes.forEach((size, idx) => {
			batch.update(
				firebase.variableSizes(compId).doc(size.id),
				formatSize({ ...size, magnitude: idx }, user)
			);
		});
		await batch.commit().then(() => {
			toastr.success('Success', 'Size order has been updated');
		});
	};

	return (
		<VariableSizesContext.Provider
			value={{ variableSizes, loading, addSize, updateSizesOrder }}
		>
			{children}
		</VariableSizesContext.Provider>
	);
};

export const VariableSizesConsumer = VariableSizesContext.Consumer;

export const useVariableSizes = () => useContext(VariableSizesContext);
