export const formatSettings = (settings, profile) => {
	return {
		...settings,
		dateUpdated: new Date(),
		lastWorkedOnBy: {
			name: profile.nameDisplay,
			id: profile.id,
			email: profile.email
		}
	};
};
