import React, {
	createContext,
	useContext,
	useState,
	useEffect,
	useCallback,
} from 'react';
import {
	transformCollectionSnap,
	getObjFromLink,
	showPriceForOrder,
} from '../helpers';
import { useFirebase } from '../Firebase';
import { useUser, useAuth } from '../auth';
import {
	formatOrderData,
	formatNewOrder,
	formatNewOrderData,
	formatOrderDates,
	formatDeliveryNote,
	formatDispatchOrderData,
	formatReceiveList,
	formatReceiveOrderData,
	formatNotePrint,
	formatIncomingOrders,
} from './formatting';
import { formatUpdateStock } from '../stock/formatting';
import { toastr } from 'react-redux-toastr';
import { useStock } from '../stock';
import { areIntervalsOverlapping, add } from 'date-fns';
import { useSettings } from '../settings';
import orderInvoiceWithPricePrint from '../../features/Orders/OrderInvoice/OrderInvoiceWithPrice';
import orderInvoiceNoPricePrint from '../../features/Orders/OrderInvoice/OrderInvoiceNoPrice';
import companyThemes from '../../app/config/companyThemes';
import html2pdf from 'html2pdf.js';

const OrdersContext = createContext({});

export const OrdersProvider = ({ children }) => {
	const user = useUser();
	const { settings } = useSettings();
	const { companyLink, isApproved, isArchived } = useAuth();
	const compObj = companyLink ? getObjFromLink(companyLink) : {};
	const compId = compObj.id ? compObj.id : '';
	const firebase = useFirebase();
	const [orders, setOrders] = useState([]);
	const [allOrders, setAllOrders] = useState([]);
	const [loading, setLoading] = useState(true);
	const [monthlyOrders, setMonthlyOrders] = useState([]);
	const {
		availableStock,
		stock,
		updateDispatchedStockItems,
		updateReceivedStockItems,
		getLocationTotals,
		getStockTotalsCart,
		availableViewStock,
	} = useStock();
	const getDatedAvailableStock = useCallback(
		({ startDate, endDate, order, orders }) => {
			if (orders) {
				//Get orders that overlap with dates set for order
				const overlappingOrders = orders.filter((o) => {
					if (o.id !== order.id) {
						if (
							o.status === 'draft' ||
							o.status === 'pending' ||
							o.status === 'approved' ||
							o.status === 'dispatched'
						) {
							if (o.dispatchDate && o.returnDate) {
								try {
									return areIntervalsOverlapping(
										{
											start: new Date(startDate.toDate()),
											end: add(new Date(endDate.toDate()), {
												hours: settings.bufferTime,
											}),
										},
										{
											start: new Date(o.dispatchDate.toDate()),
											end: add(new Date(o.returnDate.toDate()), {
												hours: settings.bufferTime,
											}),
										},
										{ inclusive: true }
									);
								} catch (err) {
									return false;
								}
							} else {
								return false;
							}
						} else {
							return false;
						}
					} else {
						return false;
					}
				});

				//format stock for overlapping orders
				const overlappingOrderStock = overlappingOrders
					.map((o) => o.stock)
					.reduce((arr, item) => [...arr, ...item], [])
					.reduce((obj, item) => {
						return {
							...obj,
							[`${item.stockLink}__${item.size}`]: obj[
								`${item.stockLink}__${item.size}`
							]
								? obj[`${item.stockLink}__${item.size}`] + item.quantity
								: item.quantity,
						};
					}, {});

				const updatedStock = availableStock.map((stockItem) => {
					const quantities = stockItem.quantities.reduce((arr, q) => {
						if (
							overlappingOrderStock[
								`${stockItem.id}__${stockItem.name}__${q.size}`
							]
						) {
							return [
								...arr,
								{
									...q,
									quantity:
										q.quantity -
										overlappingOrderStock[
											`${stockItem.id}__${stockItem.name}__${q.size}`
										],
								},
							];
						} else return [...arr, q];
					}, []);
					return { ...stockItem, quantities };
				});

				//subtract overlapping stock from available stock

				return updatedStock;
			}
		},
		[availableStock, settings.bufferTime]
	);
	const getAllOrders = useCallback(async () => {
		try {
			const snap = await await firebase.orders(compId).get();

			const data = formatIncomingOrders(
				transformCollectionSnap(snap),
				getDatedAvailableStock,
				user,
				true,
				stock
			);
			return Promise.resolve(data);
		} catch (error) {
			return Promise.reject(error);
		}
	}, [firebase, compId, getDatedAvailableStock, stock, user]);
	const getOrders = useCallback(async () => {
		try {
			const snap = await await firebase.orders(compId).get();

			const data = formatIncomingOrders(
				transformCollectionSnap(snap),
				getDatedAvailableStock,
				user
			);
			return Promise.resolve(data);
		} catch (error) {
			return Promise.reject(error);
		}
	}, [firebase, compId, getDatedAvailableStock, user]);
	useEffect(() => {
		const unsubscribe =
			compId !== '' && isApproved && !isArchived
				? firebase.orders(compId).onSnapshot((snap) => {
						console.log('first');
						setOrders(
							formatIncomingOrders(
								transformCollectionSnap(snap),
								getDatedAvailableStock,
								user
							)
						);
						setAllOrders(
							formatIncomingOrders(
								transformCollectionSnap(snap),
								getDatedAvailableStock,
								user,
								true,
								stock
							)
						);
						setLoading(false);
				  })
				: () => console.log('waiting for user to load');
		return () => unsubscribe();
	}, [
		firebase,
		compId,
		isApproved,
		isArchived,
		getDatedAvailableStock,
		user,
		stock,
	]);

	// useEffect(() => {
	// 	const receivedOrders = orders.filter((o) => o.status === 'received');

	// 	const notePromises = receivedOrders.map(async (o) => {
	// 		const snap = await firebase.order(compId, o.id).collection('notes').get();
	// 		return { ...o, notes: transformCollectionSnap(snap) };
	// 	});
	// 	Promise.all(notePromises).then((ordersWithNotes) => {
	// 		const noReceive = ordersWithNotes.filter(
	// 			(order) => !order.notes.find((n) => n.id.includes('receive'))
	// 		);
	// 		const locations = noReceive.reduce(
	// 			(obj, order) => ({
	// 				...obj,
	// 				g91YnfejF9cuFwChJwqR__Johannesburg:
	// 					obj['g91YnfejF9cuFwChJwqR__Johannesburg'] +
	// 					(order.receivedLocations.includes(
	// 						'g91YnfejF9cuFwChJwqR__Johannesburg'
	// 					)
	// 						? 1
	// 						: 0),
	// 				'th1W6n0tBXL9htlL1jqx__Cape Town Warehouse':
	// 					obj['th1W6n0tBXL9htlL1jqx__Cape Town Warehouse'] +
	// 					(order.receivedLocations.includes(
	// 						'th1W6n0tBXL9htlL1jqx__Cape Town Warehouse'
	// 					)
	// 						? 1
	// 						: 0),
	// 			}),
	// 			{
	// 				g91YnfejF9cuFwChJwqR__Johannesburg: 0,
	// 				'th1W6n0tBXL9htlL1jqx__Cape Town Warehouse': 0,
	// 			}
	// 		);
	// 	});
	// }, [orders, firebase, compId]);

	const getLastOrder = async () => {
		const snap = await firebase
			.orders(compId)
			.orderBy('dateCreated', 'desc')
			.limit(1)
			.get();
		return transformCollectionSnap(snap);
	};

	const createOrder = async (type, invoice) => {
		const lastOrder = await getLastOrder();

		const values = formatNewOrder({
			type,
			companyLink,
			lastOrder,
			invoice,
		});
		const order = formatNewOrderData(values, user);

		return firebase.createOrder(compId, order).then((orderID) => {
			toastr.success('Success', 'Order created');
			return orderID;
		});
	};

	const assignOrderToCentre = async (order, showToast) => {
		return firebase.updateOrder(compId, order).then(() => {
			if (showToast || showToast === undefined) {
				toastr.success('Success', 'Order updated');
			}
		});
	};

	const assignToEft = async (order, showToast) => {
		return firebase.updateOrder(compId, order).then(() => {
			if (showToast || showToast === undefined) {
				toastr.success('Success', 'Order updated');
			}
		});
	};

	const updateOrderStatus = async (order, status, showToast) => {
		return firebase
			.updateOrder(compId, { ...order, status: status })
			.then(() => {
				if (showToast || showToast === undefined) {
					toastr.success('Success', `Order ${status}`);
				}
			});
	};

	const updateOrder = async (order, showToast) => {
		const dates = formatOrderDates(order);

		const orderWithDates = { ...order, ...dates };

		return firebase
			.updateOrder(compId, formatOrderData(orderWithDates, user))
			.then(() => {
				if (showToast || showToast === undefined) {
					toastr.success('Success', 'Order updated');
				}
			});
	};

	const addPickingList = (order) => {
		const locationLink = order.stock[0].locationLink;
		firebase
			.updateOrder(
				compId,
				formatOrderData(
					{
						pickingListPrintedLocations:
							firebase.firestore.FieldValue.arrayUnion(locationLink),
						id: order.id,
					},
					user
				)
			)
			.then(() => {
				firebase
					.order(compId, order.id)
					.collection('notes')
					.doc(`pickingList__${locationLink}`)
					.set(formatNotePrint(user, locationLink), { merge: true })
					.then(() => {
						toastr.success('Success', 'Picking list added');
					});
			});
	};

	const addDeliveryNote = (order) => {
		const locationLink = order.stock[0].locationLink;
		firebase
			.updateOrder(
				compId,
				formatOrderData(
					{
						deliveryNotePrintedLocations:
							firebase.firestore.FieldValue.arrayUnion(locationLink),
						id: order.id,
					},
					user
				)
			)
			.then(() => {
				firebase
					.order(compId, order.id)
					.collection('notes')
					.doc(`deliveryNote__${locationLink}`)
					.set(formatNotePrint(user, locationLink), { merge: true })
					.then(() => {
						toastr.success('Success', 'Delivery Note added');
					});
			});
	};

	const formatInvoice = (invoiceComponent) => {
		return new Promise((resolve, reject) =>
			html2pdf()
				.from(invoiceComponent)
				.set({
					pagebreak: { mode: 'legacy' },
				})
				.outputPdf()
				.then((res) => resolve(res))
				.catch((err) => reject(err))
		);
	};

	const addInvoice = async (order, locationStockArr, locationDetails) => {
		const items = getStockTotalsCart(order.stock);
		const total = getLocationTotals(order.stock);
		const logo = companyThemes[getObjFromLink(companyLink).id].logoUrl;
		let usedLocations = locationStockArr.map(
			(location) => location.locationLink
		);
		const isPriceForOrder = showPriceForOrder(
			settings.allowPrices,
			order.allowPrices,
			order.status
		);

		let invoice;
		let formattedInvoice;

		if (isPriceForOrder) {
			formattedInvoice = await formatInvoice(
				orderInvoiceWithPricePrint(
					order,
					total,
					locationDetails,
					items,
					usedLocations,
					logo
				)
			);
		} else {
			formattedInvoice = await formatInvoice(
				orderInvoiceNoPricePrint(
					order,
					total,
					locationDetails,
					usedLocations,
					logo
				)
			);
		}

		invoice = btoa(formattedInvoice);
		return invoice;
	};

	const dispatchOrder = async (order, orderStock, locationLink) => {
		const unfilteredOrder = orders.find((o) => o.id === order.id);
		const batch = firebase.firestore().batch();
		const updatesDispatchedStockItems = updateDispatchedStockItems(orderStock);

		updatesDispatchedStockItems.map(async (stock) => {
			const formattedStock = formatUpdateStock(stock, user);
			const stockRef = firebase
				.firestore()
				.collection('companies')
				.doc(compId)
				.collection('stock')
				.doc(formattedStock.id);

			await batch.update(stockRef, formattedStock);
		});

		const deliveryNoteRef = firebase
			.order(compId, order.id)
			.collection('notes')
			.doc(`deliveryNote__${locationLink}`);
		batch.set(
			deliveryNoteRef,
			formatDeliveryNote(user, locationLink, orderStock),
			{
				merge: true,
			}
		);

		const orderRef = firebase.order(compId, order.id);
		batch.update(
			orderRef,
			formatOrderData(
				formatDispatchOrderData(unfilteredOrder, firebase, locationLink),
				user
			)
		);

		await batch
			.commit()
			.then(() => {
				toastr.success('Success', 'Order updated');
			})
			.catch((err) => {
				console.log(err);
				toastr.success('Oops', 'Something when wrong, please try again');
			});
	};

	const receiveOrder = async (order, orderStock, locationLink) => {
		const unfilteredOrder = orders.find((o) => o.id === order.id);
		const batch = firebase.firestore().batch();
		const updatedReceivedStockItems = updateReceivedStockItems(orderStock);

		updatedReceivedStockItems.forEach(async (stock) => {
			const formattedStock = formatUpdateStock(stock, user);
			const stockRef = firebase
				.firestore()
				.collection('companies')
				.doc(compId)
				.collection('stock')
				.doc(formattedStock.id);

			await batch.update(stockRef, formattedStock);
		});

		const receiveListRef = firebase
			.order(compId, order.id)
			.collection('notes')
			.doc(`receiveList__${locationLink}`);
		batch.set(
			receiveListRef,
			formatReceiveList(user, locationLink, orderStock),
			{
				merge: true,
			}
		);

		const orderRef = firebase.order(compId, order.id);
		batch.update(
			orderRef,
			formatOrderData(
				formatReceiveOrderData(unfilteredOrder, firebase, locationLink),
				user
			)
		);

		await batch
			.commit()
			.then(() => {
				toastr.success('Success', 'Order updated');
			})
			.catch((err) => {
				console.log(err);
				toastr.success('Oops', 'Something when wrong, please try again');
			});
	};

	const archiveOrder = async (order) => {
		firebase
			.updateOrder(
				compId,
				formatOrderData(
					{ ...order, status: 'archived', isArchived: true },
					user
				)
			)

			.then(() => {
				toastr.success('Success', 'Order Archived');
			});
	};

	const getOrderNotes = async (orderId) => {
		const snap = await firebase
			.order(compId, orderId)
			.collection('notes')
			.get();
		return transformCollectionSnap(snap);
	};

	const handlemonthlyOrders = useCallback(() => {
		const unsubscribe =
			compId !== '' && isApproved && !isArchived
				? firebase.orders(compId).onSnapshot((snap) => {
						// setOrders(
						// 	formatIncomingOrders(
						// 		transformCollectionSnap(snap),
						// 		getDatedAvailableStock,
						// 		user
						// 	)
						// );
						setMonthlyOrders(
							formatIncomingOrders(
								transformCollectionSnap(snap),
								getDatedAvailableStock,
								user,
								true,
								stock
							)
						);
						setLoading(false);
				  })
				: () => console.log('waiting for user to load');
		return () => unsubscribe();
	}, [
		compId,
		firebase,
		isApproved,
		isArchived,
		getDatedAvailableStock,
		stock,
		user,
	]);

	return (
		<OrdersContext.Provider
			value={{
				loading,
				orders,
				createOrder,
				updateOrder,
				updateOrderStatus,
				dispatchOrder,
				receiveOrder,
				addPickingList,
				addDeliveryNote,
				getDatedAvailableStock,
				archiveOrder,
				addInvoice,
				getOrderNotes,
				allOrders,
				assignOrderToCentre,
				assignToEft,
				monthlyOrders,
				handlemonthlyOrders,
				getAllOrders,
			}}
		>
			{children}
		</OrdersContext.Provider>
	);
};

export const OrdersConsumer = OrdersContext.Consumer;

export const useOrders = () => useContext(OrdersContext);
