import React, { createContext, useContext, useState, useEffect } from 'react';
import {
	transformDocSnap,
	getObjFromLink,
	transformCollectionSnap,
} from '../helpers';
import { useFirebase } from '../Firebase';
import { useUser, useAuth } from '../auth';
import { formatSettings } from './formatting';
import { toastr } from 'react-redux-toastr';

const SettingsContext = createContext({});

export const SettingsProvider = ({ children }) => {
	const { companyLink, isApproved, isArchived } = useAuth();
	const user = useUser();
	const compObj = companyLink ? getObjFromLink(companyLink) : {};
	const compId = compObj.id ? compObj.id : '';
	const firebase = useFirebase();
	const [settings, setSettings] = useState([]);
	const [feedbackQuestions, setFeedbackQuestions] = useState([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const unsubscribe =
			compId !== '' && isApproved && !isArchived
				? firebase.company(compId).onSnapshot((snap) => {
						setSettings(transformDocSnap(snap));
						setLoading(false);
				  })
				: () => console.log('waiting for user to load');
		return () => unsubscribe();
	}, [firebase, compId, isApproved, isArchived]);

	useEffect(() => {
		const unsubscribe =
			compId !== '' && isApproved && !isArchived
				? firebase.feedbackQuestions(compId).onSnapshot((snap) => {
						setFeedbackQuestions(transformCollectionSnap(snap));
						setLoading(false);
				  })
				: () => console.log('waiting for user to load');
		return () => unsubscribe();
	}, [firebase, compId, isApproved, isArchived]);

	const updateSettings = (values) => {
		firebase
			.company(compId)
			.update(formatSettings(values, user))
			.then(() => {
				toastr.success('Success', 'Settings updated');
			});
	};
	const updateRatings = (deliveryRates) => {
		firebase
			.company(compId)
			.update({
				deliveryRates: deliveryRates,
			})
			.then(() => {
				toastr.success('Success', 'Settings updated');
			});
	};
	const addQuestion = (question) => {
		firebase
			.feedbackQuestions(compId)
			.add(formatSettings(question, user))
			.then(() => {
				toastr.success('Success', 'Question added');
			});
	};

	const updateQuestions = async (questions) => {
		const batch = firebase.db.batch();
		questions.forEach((q, idx) => {
			batch.update(
				firebase.feedbackQuestions(compId).doc(q.id),
				formatSettings({ ...q, position: idx }, user)
			);
		});
		await batch.commit().then(() => {
			toastr.success('Success', 'Questions have been updated');
		});
	};

	return (
		<SettingsContext.Provider
			value={{
				settings,
				loading,
				updateSettings,
				feedbackQuestions,
				addQuestion,
				updateQuestions,
				updateRatings,
			}}
		>
			{children}
		</SettingsContext.Provider>
	);
};

export const SettingsConsumer = SettingsContext.Consumer;

export const useSettings = () => useContext(SettingsContext);
