import { format } from 'date-fns';
import { getObjFromLink } from '../../../../context/helpers';

const orderInvoiceNoPricePrint = (
	row,
	total,
	locationDetails,
	usedLocations,
	logo
) => {
	return `
        <!DOCTYPE html>
        <html lang="en">
            <head>
                <meta charset="UTF-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <title>${row.orderNumber}</title>
                <style type="text/css">
                    body {
                        width: 100%;
                        height: 100%;
                        margin: 0;
                        padding: 0;
                        background-color: #fafafa;
                        font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
                    }
                    * {
                        box-sizing: border-box;
                        -moz-box-sizing: border-box;
                    }
                    .page {
                        width: 210mm;
                        min-height: 297mm;
                        padding: 20mm;
                        margin: 10mm auto;
                        // border: 1px #d3d3d3 solid;
                        border-radius: 5px;
                        background: white;
                        // box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
                    }
                    .subpage {
                        // padding: 1cm;
                        padding: 0;
                        border: 5px transparent solid;
                        // height: 257mm;
                        // outline: 2cm #ffeaea solid;
                        outline: 2cm transparent solid;
                        color: #414a5a;
                    }

                    footer {
                        font-size: 15px;
                        margin: 40px 0 20mm 0;
                    }

                    .logo-box {
                        display: flex;
                        align-items: center;
                    }

                    .logo-box img {
                        display: block;
                        height: 35px;
                        width: auto;
                        margin-left: 25px;
                        -webkit-print-color-adjust:exact  !important;
                    }


                    /* -------------- PAGE HEADER SECTION ------------- */
                    .page-header {
                        display: grid;
                        grid-template-columns: 1fr 1fr;
                        border-bottom: 1px solid #a0a5ac;
                        margin-bottom: 50px;
                        padding-bottom: 8px;
                    }

                    .page-header h1 {
                        display: grid;
                        align-items: end;
                    }
                    .page-header h2 {
                        display: grid;
                        justify-items: end;
                        align-items: end;
                        margin-bottom: 0;
                    }

                    /* -------------- PAGE DETAILS SECTION ------------- */
                    .details-section {
                        display: grid;
                        grid-template-columns: 1fr 1fr;
                        grid-gap: 20px;
                        margin-bottom: 50px;
                    }
                    .details-section h3 {
                        display: block;
                        border-bottom: 1px solid #a0a5ac;
                        margin-bottom: 10px;
                    }
                    .borderBox {
                        font-size: 8px;
                        padding: 4px 5px;
                        border-radius: 4px;
                        border: 1px solid #414a5a;
                        text-transform: uppercase;
                    }
                    /* -------------- STOCK SECTION ------------- */
                    .stock-section {
                        margin-bottom: 30px;
                    }
                    .stock-section table {
                        margin-top: 7px;
                    }
                    .stock-section .bottomTable {
                        margin-top: 0px !important;
                    }
                    .stock-section svg {
                        width: 9px;
                        margin-left: 4px;
                    }
                    .stock-section td,
                    .stock-section th {
                        border: 1px solid #a0a5ac;
                    }
                    .stock-section td {
                        padding: 10px 5px;
                    }
                    .address {
                        font-size: 12px;
                        font-weight: 200;
                    }
                    .centered {
                        text-align: center;
                    }
                    .no-border {
                        border:none  !important;
                        padding: 0 !important;
                    }
                    .stock-section .no-padding {
                        padding: 0 !important;
                    }
                    .quantity-box {
                        display:grid;
                        grid-template-columns: 3fr 1.5fr;
                    }
                    .quantity-box div:first-child {
                        border-right: 1px solid #a0a5ac;
                    }

                    .quantity-box div {
                        padding: 23px 5px;
                    }
                    /* -------------- NOTES SECTION ------------- */
                    .notes-section {
                        padding: 10px;
                     }
                     .notes-section p {
                         margin-top: 0px;
                         margin-bottom: 3px;
                     }

                     .notes-section span {
                         display: block;
                         border-bottom: 1px solid #a0a5ac;
                         height: 20px;

                     }

                    /* -------------- TYPOGRAPHY ------------- */
                    h1 {
                        margin-top: 0px;
                        margin-bottom: 10px;
                    }
                    h2 {
                        margin-top: 0px;
                        margin-bottom: 10px;
                    }
                    h3 {
                        margin-top: 0px;
                        margin-bottom: 0px;
                        font-size: 15px;
                        font-weight: 400;
                    }
                    h4 {
                        margin-top: 0px;
                        margin-bottom: 0px;
                        font-size: 13px;
                    }

                    /* -------------- TABLE ------------- */
                    table {
                        width: 100%;
                        border-collapse: collapse;
                    }
                    td {
                        font-size: 12px;
                        padding: 5px 0px;
                    }
                    td.right {
                        text-align: right;
                    }
                    th {
                        padding: 10px;
                        font-size: 13px;
                    }
                    th:nth-child(1),
                    .totals-table td:nth-child(1) {
                            width: 60px;
                        }
                        th:nth-child(2),
                        .totals-table td:nth-child(2) {
                            width: 350px;
                        }
                        th:nth-child(3),
                        .totals-table td:nth-child(3) {
                            width: 252px;
                        }

                        .totals-table td strong {
                            font-size: 13px;
                        }
                    /* -------------- PAGE & PRINT ------------- */
                    @page {
                        size: auto;
                        margin: 80px 0px 0px 0px;
                    }
                    @page:first {
                        margin: 0px 0px 0px 0px;
                    }
                    @media print {
                        html,
                        body {
                            width: 210mm;
                            // height: 297mm;
                        }
                        .page {
                            margin: 0;
                            border: initial;
                            border-radius: initial;
                            width: initial;
                            min-height: 297mm;
                            box-shadow: initial;
                            background: initial;
                            page-break-after: always;
                        }
                    }
                    footer {
                        width:100%;
                        padding-top:12px;
                        border-top:1px solid black;
                    }
                </style>
            </head>
            <body>
                <div class="book">
                    <div class="page">
                        <div class="subpage">
                            <div class="page-header">
                            <div class="logo-box">
                                <svg width="155px" height="22px" viewBox="0 0 155 22" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                <!-- Generator: Sketch 53.1 (72631) - https://sketchapp.com -->
                                <title>Turnkey</title>
                                <desc>Created with Sketch.</desc>
                                <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <g id="Branding-/-TK-Logo-/-Dark" transform="translate(-10.000000, -20.000000)" fill="#424B5A">
                                        <g id="Turnkey" transform="translate(10.000000, 20.000000)">
                                            <polygon id="Path" points="99.7401247 10.0638298 107.798337 2.34042553 107.091476 0 104.158004 0 102.850312 0.76899696 96.983368 6.01823708 96.983368 1.33738602 95.6756757 0 93.3430353 0 92.035343 1.20364742 92 9.62917933 92.035343 10.3981763 92 20.662614 93.3076923 22 95.6403326 22 96.9480249 20.8297872 96.983368 14.0091185 104.051975 21.231003 105.39501 22 108.293139 22 109 19.6595745"></polygon>
                                            <polygon id="Path" points="129.644068 4.68085106 131 3.443769 131 1.23708207 129.813559 0 118.288136 0.0334346505 117 1.20364742 117 20.6960486 118.457627 21.9665653 129.644068 22 131 20.7629179 131 18.556231 129.813559 17.3191489 121.745763 17.2857143 121.745763 13.3404255 128.457627 13.3404255 129.813559 12.1367781 129.813559 9.89665653 128.627119 8.65957447 121.745763 8.65957447 121.745763 4.68085106"></polygon>
                                            <polygon id="Path" points="148.435294 13.7875569 155 2.03641882 153.588235 0.033383915 151.611765 0 150.023529 1.00151745 146 8.17905918 141.976471 1.00151745 140.388235 0 138.411765 0.033383915 137 2.03641882 143.494118 13.7207891 143.494118 20.6646434 144.8 22 147.164706 22 148.435294 20.7981791"></polygon>
                                            <polygon id="Path" points="14.7799564 0.033383915 1.39433551 0 0 1.23520486 0 3.43854325 1.22004357 4.6737481 5.47276688 4.6737481 5.47276688 20.6646434 6.76252723 22 9.06318083 22 10.3529412 20.7981791 10.3877996 4.70713202 14.6056645 4.70713202 16 3.47192716 16 1.26858877"></polygon>
                                            <path d="M40,2.07926829 L40,2.07926829 L40,1.34146341 L38.6895833,1.19145886e-15 L36.3520833,1.19145886e-15 L35.0416667,1.17378049 C35.0416667,1.17378049 35.00625,13.8170732 35.00625,13.9512195 C35.00625,15.7621951 33.4479167,17.2713415 31.5,17.2713415 C29.5875,17.2713415 27.99375,15.7621951 27.99375,13.9512195 C27.99375,13.8170732 27.9583333,1.17378049 27.9583333,1.17378049 L26.6479167,0 L24.3104167,0 L23,1.34146341 L23,2.07926829 L23,2.07926829 L23.0354167,14.2195122 L23,14.2195122 C23.1416667,18.5457317 26.8958333,22 31.4645833,22 C31.4645833,22 31.4645833,22 31.5,22 C31.5,22 31.5,22 31.5354167,22 C36.1395833,22 39.8583333,18.5121951 40,14.2195122 L40,2.07926829 Z" id="Path"></path>
                                            <path d="M68,19.9207317 L68,19.9207317 L68,20.6585366 L69.3104167,22 L71.6479167,22 L72.9583333,20.8262195 C72.9583333,20.8262195 72.99375,8.18292683 72.99375,8.04878049 C72.99375,6.23780488 74.5520833,4.72865854 76.5,4.72865854 C78.4125,4.72865854 80.00625,6.23780488 80.00625,8.04878049 C80.00625,8.18292683 80.0416667,20.8262195 80.0416667,20.8262195 L81.3520833,22 L83.6895833,22 L85,20.6585366 L85,19.9207317 L85,19.9207317 L84.9645833,7.7804878 L85,7.7804878 C84.8583333,3.45426829 81.1041667,0 76.5354167,0 C76.5354167,0 76.5354167,0 76.5,0 C76.5,0 76.5,0 76.4645833,0 C71.8604167,0 68.1416667,3.48780488 68,7.7804878 L68,19.9207317 Z" id="Path"></path>
                                            <path d="M62,6.41389728 C62,2.82477341 58.9075829,0 55.0687204,0 C54.8199052,0 48.8838863,0.0332326284 48.8838863,0.0332326284 L47,1.66163142 L47,4.75226586 L47,20.6706949 L48.3151659,22 L50.6611374,22 L51.9763033,20.836858 L52.0118483,13.060423 L52.8293839,13.060423 L56.8104265,21.2356495 L58.0900474,21.9667674 L60.0805687,21.9667674 L61.6090047,19.7069486 L57.9478673,12.2960725 C60.3293839,11.2990937 62,9.03927492 62,6.41389728 Z M55.0331754,8.57401813 L51.514218,8.57401813 L51.514218,4.6858006 L55.0331754,4.6858006 C56.1706161,4.6858006 57.0947867,5.54984894 57.0947867,6.61329305 C57.0947867,7.70996979 56.1706161,8.57401813 55.0331754,8.57401813 Z" id="Shape" fill-rule="nonzero"></path>
                                        </g>
                                    </g>
                                </g>
                                </svg>
                                <img src="${logo}" />
                            </div>
                                <h2>Invoice</h2>
                            </div>
                            <div class="details-section">
                                <div>
                                    <h3>DELIVERY DETAILS</h3>
                                    <table>
                                        <tr>
                                            <td>METHOD:</td>
                                            <td class="right"><span class="borderBox">${
																							row.deliveryMethod
																						}</span></td>
                                        </tr>
                                        <tr>
                                            <td>${
																							row.deliveryMethod ===
																							'collection'
																								? `COLLECTION:`
																								: `DISPATCH:`
																						}</td>
                                            <td class="right">${
																							row.dispatchDate
																								? format(
																										row.dispatchDate.toDate(),
																										'yyyy-MM-dd hh:mm a'
																								  )
																								: '-'
																						}</td>
                                        </tr>
                                        <tr>
                                            <td>${
																							row.deliveryMethod ===
																							'collection'
																								? `RETURN:`
																								: `COLLECTION:`
																						}</td>
                                            <td class="right">${
																							row.returnDate
																								? format(
																										row.returnDate.toDate(),
																										'yyyy-MM-dd hh:mm a'
																								  )
																								: '-'
																						}</td>
                                        </tr>
                                    </table>
                                </div>
                                <div>
                                    <h3>ORDER DETAILS</h3>
                                    <table>
                                        <tr>
                                            <td>ORDER TYPE:</td>
                                            <td class="right"><span class="borderBox">${
																							row.orderType
																						}</span></td>
                                        </tr>
                                        <tr>
                                            <td>ORDER NO:</td>
                                            <td class="right">${
																							row.orderNumber
																						}</td>
                                        </tr>
																				${
																					row.poNumber
																						? `
																				<tr>
                                            <td>PO NO:</td>
                                            <td class="right">${row.poNumber}</td>
                                        </tr>
																				`
																						: ''
																				}
                                        <tr>
                                            <td>ORDER DATE:</td>
                                            <td class="right">${
																							row.dateCreated
																								? format(
																										row.dateCreated.toDate(),
																										'yyyy-MM-dd'
																								  )
																								: '-'
																						}</td>
                                        </tr>
                                        <tr>
                                            <td>STATUS:</td>
                                            <td class="right"><span class="borderBox">${
																							row.status
																						}</span></td>
                                        </tr>
                                    </table>
                                </div>
                                <div>
                                    <h3>CONTACT PERSON</h3>
                                    <table>
                                        <tr>
                                            <td>NAME:</td>
                                            <td class="right">${
																							row.contactName +
																							' ' +
																							row.contactSurname
																						}</td>
                                        </tr>
                                        <tr>
                                            <td>PRIMARY TEL:</td>
                                            <td class="right">${
																							row.primaryTel
																						}</td>
                                        </tr>
                                    </table>
                                </div>
                                <div>
                                    <h3>EVENT DETAILS</h3>
                                    <table>
                                        <tr>
                                            <td>
                                                    <strong>${
																											row.eventName
																										} </strong> from
                                                    <strong>
                                                        ${
																													row.startDate
																														? format(
																																row.startDate.toDate(),
																																'yyyy-MM-dd'
																														  )
																														: '-'
																												}
                                                    </strong>
                                                    to
                                                    <strong>
                                                        ${
																													row.endDate
																														? format(
																																row.endDate.toDate(),
																																'yyyy-MM-dd'
																														  )
																														: '-'
																												}
                                                    </strong>
                                            </td>
                                        </tr>
                                        <tr>
                                        <td>
                                               ${row.eventAddress}
                                        </td>
                                    </tr>
                                    </table>
                                </div>
                            </div>
                            <div class="stock-section">
                                <div>
                                    <h3>Stock Items from <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="map-marker-alt" class="svg-inline--fa fa-map-marker-alt fa-w-12" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path fill="#2D333D" d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z"></path></svg> ${
																			(locationDetails &&
																				locationDetails[0] &&
																				locationDetails[0].shortName) ||
																			''
																		}, ${
		(locationDetails && locationDetails[0] && locationDetails[0].location) || ''
	}</span></h3>
                                </div>
                                <br>
                                ${usedLocations
																	?.map((location) => {
																		let warehouseQty = 0;
																		return `
                                    <div>
                                    <h4>Stock Items from ${location.substring(
																			location.indexOf('__') + 2
																		)}
                                    </span></h4>
                                </div>
                                <table >
                                <thead>
                                    <tr>
                                        <th>
                                            #
                                        </th>
                                        <th>
                                            Item Name
                                        </th>
                                        <th>
                                            Quantity
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                ${row.stock
																	.filter(
																		(stockItem) =>
																			stockItem.locationLink === location
																	)
																	.map((stockItem, index) => {
																		const stockObj = getObjFromLink(
																			stockItem.stockLink
																		);

																		warehouseQty += stockItem.quantity;
																		if (index === 7) {
																			return `
                                    <tr class="html2pdf__page-break"></tr>
                                    <tr>
                                        <td class="centered">${index + 1}</td>
                                        <td>${stockObj.name}</td>
                                        <td class="no-padding">
                                            <div class="quantity-box">
                                                <div class="centered">${
																									stockItem.size === 'Fixed'
																										? 'Qty'
																										: stockItem.size
																								}</div>
                                                <div class="centered">${
																									stockItem.quantity
																								}</div>
                                            </div>
                                        </td>
                                    </tr>
                                    `;
																		} else {
																			return `
                                    <tr>
                                        <td class="centered">${index + 1}</td>
                                        <td>${stockObj.name}</td>
                                        <td class="no-padding">
                                            <div class="quantity-box">
                                                <div class="centered">${
																									stockItem.size === 'Fixed'
																										? 'Qty'
																										: stockItem.size
																								}</div>
                                                <div class="centered">${
																									stockItem.quantity
																								}</div>
                                            </div>
                                        </td>
                                    </tr>
                                    `;
																		}
																	})
																	.join('')}
                                    <tr class="no-border">
                                        <td class="no-border"></td>
                                        <td>Warehouse Total</td>

                                        <td class="no-padding">
                                            <div class="quantity-box">
                                                <div class="centered">Qty</div>
                                                <div class="centered">${warehouseQty}</div>
                                            </div>
                                        </td>
                                        <td colspan="4" class="no-border">
                                    </td>
                                    </tr>
                                </tbody>
                            </table>
                                <br>    `;
																	})
																	.join('')}

                                <table class="totals-table">
                                <tbody>
                                    <tr class="no-border">
                                        <td class="no-border"></td>
																				<td class="no-border"></td>
                                        <td class="no-padding">
                                            <div class="quantity-box">
                                                <div class="centered"><strong>Total Items</strong></div>
                                                <div class="centered">${total}</div>
                                            </div>
                                        </td>
                                        <td colspan="3" class="no-border">
                                    </td>
                                    </tr>
                                </tbody>
                            </table>
                            </div>

                            <footer>
                                <div>Corner Gemini & Milkyway Avenue, Linbro Park, Frankenwald, Johannesburg, 2090.</div>
                                <div>+2711 549 5124/5</div>
                                <div>info@turnkeylogistics.co.za</div>
                            </footer>
                        </div>
                    </div>
                </div>
            </body>
        </html>
        `;
};

export default orderInvoiceNoPricePrint;
