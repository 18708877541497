import { IS_ORDER_EDITABLE } from '../app/shared/constants';
import moment from 'moment';
import { Loader } from '@googlemaps/js-api-loader';
// import { places } from '../Places';

export const deliveryFeeCalculator = (
	maximumWeight,
	deliveryType,
	type,
	transit
) => {
	const totalPrice =
		maximumWeight > deliveryType.kgs
			? (maximumWeight - +deliveryType.kgs) *
					+deliveryType.rate_per_kg_thereafter +
			  deliveryType.minim_for_kg
			: deliveryType.minim_for_kg;
	return {
		serviceName: deliveryType.service,
		totalPrice: totalPrice.toFixed(2),

		minimumForKg: deliveryType.minim_for_kg,
		ratePerKgThereAfter: deliveryType.rate_per_kg_thereafter,
		minKg: deliveryType.minim_for_kg,
		type: type,
		transit,
	};
};

export const placesWithSelectedPostalCode = (postalCode) => {
	const { places } = require('../Places');
	return places?.filter((place) => place?.Code?.trim() === postalCode?.trim());
};

export const placesWithSameName = (adress) => {
	const { places } = require('../Places');
	const surbubName = adress.split(',')[1];
	const cityName = adress.split(',')[2];
	const streetName = adress.split(',')[3];
	const line3 = adress.split(',')[4];
	const firstCheck = places.filter((place) =>
		place?.PlaceName?.trim().toLowerCase().includes(surbubName.toLowerCase())
	);
	if (firstCheck.length > 0) {
		return firstCheck;
	}
	const secondCheck = places.filter((place) =>
		place?.PlaceName?.trim().toLowerCase().includes(cityName.toLowerCase())
	);
	if (secondCheck.length > 0) {
		return secondCheck;
	}

	const thirdCheck = places.filter((place) =>
		place?.PlaceName?.trim().toLowerCase().includes(streetName.toLowerCase())
	);
	if (thirdCheck.length > 0) {
		return thirdCheck;
	}

	const fourthCheck = places.filter((place) =>
		place?.PlaceName?.trim().toLowerCase().includes(line3.toLowerCase())
	);
	if (fourthCheck.length > 0) {
		return fourthCheck;
	}
};

export const actualDeliveryPlaceInTheListOfPlaces = (postalCode, address) => {
	console.log(
		placesWithSelectedPostalCode(postalCode.trim()),
		'places with selected postal code'
	);
	const firstCheck = placesWithSelectedPostalCode(postalCode.trim())?.find(
		(place) => {
			const convToArr = place.PlaceName.split(',');
			const isPlaceFound = convToArr.find(
				(curr) =>
					curr.toLowerCase().trim() ===
					address.split(',')[1].toLowerCase().trim()
			);
			if (!isPlaceFound) {
				return false;
			}
			return true;
		}
	);
	if (firstCheck) {
		return firstCheck;
	}
	const secondCheck = placesWithSelectedPostalCode(postalCode.trim())?.find(
		(place) => {
			const convToArr = place.PlaceName.split(',');
			const isPlaceFound = convToArr.find(
				(curr) =>
					curr.toLowerCase().trim() ===
					address.split(',')[2].toLowerCase().trim()
			);
			if (!isPlaceFound) {
				return false;
			}
			return true;
		}
	);
	if (secondCheck) {
		return secondCheck;
	}

	const thirdCheck = placesWithSameName(address)?.find((place) => {
		const convToArr = place?.PlaceName?.split(',');
		const isPlaceFound = convToArr.find(
			(curr) =>
				curr.toLowerCase().trim() === address.split(',')[1].toLowerCase().trim()
		);
		if (!isPlaceFound) {
			return false;
		}
		return true;
	});
	if (thirdCheck) {
		return thirdCheck;
	}
	const lastCheck = placesWithSameName(address)?.find((place) => {
		const convToArr = place.PlaceName.split(',');
		const isPlaceFound = convToArr.find(
			(curr) =>
				curr.toLowerCase().trim() === address.split(',')[2].toLowerCase().trim()
		);
		if (!isPlaceFound) {
			return false;
		}
		return true;
	});
	if (lastCheck) {
		return lastCheck;
	}
	if (placesWithSelectedPostalCode(postalCode.trim()).length > 0) {
		return placesWithSelectedPostalCode(postalCode.trim())[0];
	}
	return null;
};

export const checkAreaName = (place) =>
	place?.split(' ')[place?.split(' ')?.length - 1].trim()?.toLowerCase();

export const transformCollectionSnap = (snap) => {
	return snap.docs.map((doc) => {
		const data = doc.data();

		return { id: doc.id, ...data };
	});
};

export const transformDocSnap = (snap) => {
	const data = snap.data();

	return { id: snap.id, ...data };
};

export const getObjFromLink = (link) => {
	const sep = link?.indexOf('__');
	const id = link?.substring(0, sep);
	const name = link?.substring(sep + 2);
	return { id, name };
};

export const showPriceForOrder = (
	allowPrices,
	orderAllowPrices,
	orderStatus
) => {
	const isOrderEditable = IS_ORDER_EDITABLE.includes(orderStatus);

	if (
		(!isOrderEditable && !orderAllowPrices) ||
		(isOrderEditable && !allowPrices)
	) {
		return false;
	}

	return true;
};

export const getOrdersByStatus = (orders, status) =>
	orders
		.filter((o) => o.orderNumber)
		.filter((order) => {
			return order.status === status;
		});

export const getLastMonths = (monthsNum) => {
	let months = [];
	for (let i = monthsNum - 1; i >= 0; i--) {
		const monthName = moment().subtract(i, 'month').format('MMMM');
		const monthDate = moment().subtract(i, 'month');
		const startOfMonth = moment(monthDate).startOf('month');
		const endOfMonth = moment(monthDate).endOf('month');

		months = {
			...months,
			[monthName]: {
				monthDate,
				startOfMonth,
				endOfMonth,
			},
		};
	}
	return months;
};

const getCoordinates = async (address) => {
	const resp = await fetch(
		`https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${process.env.REACT_APP_API_KEY}`
	);
	const data = await resp.json();

	return data.results[0]?.geometry.location;
};

export const getDistance = async (deliveryAddress, warehouseAddress) => {
	const loader = new Loader({
		apiKey: process.env.REACT_APP_API_KEY,
		version: 'weekly',
		libraries: ['places', 'geometry'],
	});
	const deliveryAddressCoordinates = await getCoordinates(deliveryAddress);
	const warehouseAddressCoordinates = await getCoordinates(warehouseAddress);

	return loader.load().then(() => {
		const distance =
			window.google.maps.geometry.spherical.computeDistanceBetween(
				new window.google.maps.LatLng(deliveryAddressCoordinates),
				new window.google.maps.LatLng(warehouseAddressCoordinates)
			);

		return distance;
	});
};
// export const getOrdersByStatus = (orders, status) =>
// 	orders
// 		.filter((o) => o.orderNumber)
// 		.filter((order) => {
// 			return order.status === status;
// 		});
