import styled, { createGlobalStyle } from 'styled-components';

export const theme = {
	black: '#000000',
	white: '#fff',
	grey1: '#F3F3F3',
	grey2: '#D6DBE1',
	grey3: '#9CA3AB',
	grey4: '#22272A',
	grey5: '#131517',
	primaryBg: localStorage.getItem('primaryBg'),
	primaryTxt: localStorage.getItem('primaryTxt'),
	secondaryBg: localStorage.getItem('secondaryBg'),
	secondaryTxt: localStorage.getItem('secondaryTxt'),
	hireBg: '#00bcd4',
	hireBorder: '#00a5ba',
	purchaseBg: '#cddc39',
	purchaseBorder: '#b8c726',
	text: '#1F1F24',
};

export const StyledPage = styled.div`
	background: ${(props) => props.theme.grey1};
	color: ${(props) => props.theme.black};
`;

export const GlobalStyle = createGlobalStyle`
  html {
    box-sizing: border-box;
    font-size: 16px;
  }
	*{
		font-family: 'Ubuntu', Arial, Helvetica, sans-serif;
	}

  *, *:before, *:after {
	box-sizing: inherit;

  }
  .warning {
	  background-color: orange;
  }
  .update{
	  background: #079c27 !important;
	}
	.align-right {
		text-align: right;
	}
	.vertical-height {
		height: 100vh;
	}
	.gJRzdO {
		height: 100%
	}
  .card img {
	  height: 200px
  }
  body {
    padding: 0;
    margin: 0;
    font-weight:300;

  }
  h1, h2, h3 , h4, h5, h6 {
	font-family: 'Ubuntu', sans-serif;
    font-weight: normal;
    font-style: normal;
    margin: 0;
    padding: 0;
  }
  h1 {
		font-size: 1.5rem;
  }
  h2 {
		font-size: 1.25rem;
  }
  h3 {
		font-size: 1.15rem;
  }
  h4 ,h5, h6 {
		font-size: 1rem;
  }
  .capitalize{
		text-transform:capitalize;
  }
  p {
		font-family: 'Ubuntu', Arial, Helvetica, sans-serif;
		font-size: 1rem;
		line-height: 1.5rem;
		color: ${(props) => props.theme.greyDark};
	}
  a {
    text-decoration: none;
    color: ${theme.black};
  }
  #map {
	  min-height: 220px;
	  margin-top:1em ;
  }
  img {
    max-width: 100%;
    height:auto;
  }
  .sizeDisp .disabled input{display: none !important}
  .sizeDisp .label{width: 100%}
  .loc {padding: 1%}
  .loc:nth-child(even) {background: #CCC}
  .choose-a-client {z-index: 20!important}
  .ui.fitted.toggle.checkbox {width: 9.5rem !important;
  }
  .ui.fitted.checkbox label {padding-left: 20%!important;}
	.extra .jALMhi {
		height: 100%;
	}
	.highlight{
		background: #F2F5F7
	}
	.ui.icon.button>.icon, .ui.icon.buttons .button>.icon {
		text-align:center !important;
	}
	@media print {
    .gJRzdO {
        background-color: white;
        height: 100%;
        width: 100%;
        position: fixed;
        top: 0;
        left: 0;
        margin: 0;
        padding: 15px;
        font-size: 14px;
		line-height: 18px;
		z-index:99999;
    }
	}
	.ui.fluid.input>input {
		min-width: 65px;
	}
	.grey {
		background-color: ${(props) => props.theme.grey2} !important;
	}

	.primary {
		background-color: ${(props) => props.theme.primaryBg} !important;
		color: ${(props) => props.theme.primaryTxt} !important;
	*{
		color: ${(props) => props.theme.primaryTxt} !important;
	}
}

.secondary {
	background-color: ${(props) => props.theme.secondaryBg} !important;
	color: ${(props) => props.theme.secondaryTxt} !important;

	*{
		color: ${(props) => props.theme.secondaryTxt} !important;
	}
}

.grey.icon {
	background-color: #00000000 !important;
}

.grey.button {
	background-color: #767676 !important;
}

.clickable {
	cursor: pointer;
}

.dispatchLocation {
	position: absolute !important;
  top: 82px;
	right: 2% !important;
	width: 20%;
}
.searchBar{
	position: absolute !important;
	top: 18.5% !important;
	  right: 2% !important;
	  width: 20%;
}
.tabBar .ui.menu {
	min-height: 60px;
	background: #eee;
}
.col-3 {width: 33.3%}
.col-4 {width: 25%}
.col-4 a {color:#4183c4; z-index: 10; cursor: default;}
.col-3 a {color:#4183c4; z-index: 10; cursor: default;}
.col-4 .content, .col-4 .content a {width: 100%}
.col-3 .content, .col-3 .content a {width: 100%}
nav a:hover {
	background-color: transparent !important;
	color:#4183c4 !important;
}
.eMjhTs{min-height: 0!important;}

.smalltxt {
	font-size: 12px;
}
.success {
	background-color:  #079c27 !important;
}
.in-form-archive {
	color: #000 !important;
}

.iskKrc {
	border: 1px solid grey
}
.block {
	padding: 0 !important;
}
.graph {height: 400px; width: 100%}
.fullwidth {width: 100% !important; text-transform: uppercase !important; text-align: center}
.center {
	text-align: center !important;
}
.right {
	text-align: right !important;
}
.icon-right{
	float:right;
}
.ui.checkbox input[type=checkbox], .ui.checkbox input[type=radio]{
	opacity: 1 !important;
}
.center .field {
	margin: auto !important
}
.loginHeading h1{
	background-color: ${localStorage.getItem('primaryBg')} !important
}
.emailInput input[type="text"]{
	text-transform: lowercase !important;
}
.locationLabel {
	font-weight: bold;
}
.subLabel {
	font-style: italic;
}
.content .header {
	min-height: 40px;
	display: grid !important;
    grid-template-columns: 2fr 1fr;
    align-items: center !important;
}
h3.ui.header {
    display: block !important;
}


`;
