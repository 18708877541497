import React, { createContext, useContext, useState, useEffect } from 'react';
import { transformCollectionSnap, getObjFromLink } from '../helpers';
import { useFirebase } from '../Firebase';
import { useAuth } from '../auth';
import { toastr } from 'react-redux-toastr';

const UsersContext = createContext({});

export const UsersProvider = ({ children }) => {
	const { companyLink, isAdmin, isClientAdmin, isApproved, isArchived } =
		useAuth();
	const compObj = companyLink ? getObjFromLink(companyLink) : {};
	const compId = compObj.id ? compObj.id : '';
	const firebase = useFirebase();
	const [users, setUsers] = useState([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		if (isAdmin || isClientAdmin) {
			const unsubscribe =
				compId !== '' && isApproved && !isArchived
					? firebase.users().onSnapshot((snap) => {
							setUsers(transformCollectionSnap(snap));
							setLoading(false);
					  })
					: () => console.log('waiting');

			return () => unsubscribe();
		} else {
			setLoading(false);
		}
	}, [firebase, compId, isAdmin, isClientAdmin, isApproved, isArchived]);

	const approveUser = (user) => {
		return firebase
			.user(user.id)
			.update({ isApproved: true, status: 'approved' })
			.then(() => {
				toastr.success('Success', `${user.nameDisplay} has been approved`);
			});
	};

	const archiveUser = (user) => {
		return firebase
			.user(user.id)
			.update({
				isArchived: !user.isArchived,
				status: !user.isArchived ? 'archived' : 'approved',
			})
			.then(() => {
				toastr.success(
					'Success',
					`${user.nameDisplay} has been ${
						!user.isArchived ? 'archived' : 'reactivated'
					}`
				);
			});
	};

	const updateUser = async (user, values) => {
		return firebase
			.user(user.id)
			.update(values)
			.then(() => {
				toastr.success('Success', `${user.nameDisplay} has been updated`);
			});
	};

	return (
		<UsersContext.Provider
			value={{ users, approveUser, archiveUser, updateUser, loading }}
		>
			{children}
		</UsersContext.Provider>
	);
};

export const UsersConsumer = UsersContext.Consumer;

export const useUsers = () => useContext(UsersContext);

// import React, { createContext, useContext, useState, useEffect } from 'react';
// import { transformCollectionSnap, getObjFromLink } from '../helpers';
// import { useFirebase } from '../Firebase';
// import { useAuth } from '../auth';
// import { toastr } from 'react-redux-toastr';

// const UsersContext = createContext({});

// export const UsersProvider = ({ children }) => {
// 	const {
// 		companyLink,
// 		isAdmin,
// 		isClientAdmin,
// 		isApproved,
// 		isArchived,
// 	} = useAuth();
// 	const compObj = companyLink ? getObjFromLink(companyLink) : {};
// 	const compId = compObj.id ? compObj.id : '';
// 	const firebase = useFirebase();
// 	const [users, setUsers] = useState([]);
// 	const [loading, setLoading] = useState(true);

// 	useEffect(() => {
// 		const unsubscribe =
// 			compId !== '' && isApproved && !isArchived
// 				? firebase.users().onSnapshot((snap) => {
// 						setUsers(transformCollectionSnap(snap));
// 						setLoading(false);
// 				  })
// 				: () => console.log('waiting');

// 		return () => unsubscribe();
// 	}, [firebase, compId, isAdmin, isClientAdmin, isApproved, isArchived]);

// 	const approveUser = (user) => {
// 		return firebase
// 			.user(user.id)
// 			.update({ isApproved: true, status: 'approved' })
// 			.then(() => {
// 				toastr.success('Success', `${user.nameDisplay} has been approved`);
// 			});
// 	};

// 	const archiveUser = (user) => {
// 		return firebase
// 			.user(user.id)
// 			.update({
// 				isArchived: !user.isArchived,
// 				status: !user.isArchived ? 'archived' : 'approved',
// 			})
// 			.then(() => {
// 				toastr.success(
// 					'Success',
// 					`${user.nameDisplay} has been ${
// 						!user.isArchived ? 'archived' : 'reactivated'
// 					}`
// 				);
// 			});
// 	};

// 	const updateUser = async (user, values) => {
// 		return firebase
// 			.user(user.id)
// 			.update(values)
// 			.then(() => {
// 				toastr.success('Success', `${user.nameDisplay} has been updated`);
// 			});
// 	};

// 	return (
// 		<UsersContext.Provider
// 			value={{ users, approveUser, archiveUser, updateUser, loading }}
// 		>
// 			{children}
// 		</UsersContext.Provider>
// 	);
// };

// export const UsersConsumer = UsersContext.Consumer;

// export const useUsers = () => useContext(UsersContext);
