import React, { createContext, useContext, useState, useEffect } from 'react';
import { transformCollectionSnap } from '../helpers';
import { useFirebase } from '../Firebase';
import { useUser, useAuth } from '../auth';
import { formatLocation, formatNewLocation } from './formatting';
import { toastr } from 'react-redux-toastr';

const LocationsContext = createContext({});

export const LocationsProvider = ({ children }) => {
	const user = useUser();
	const { isApproved, isArchived } = useAuth();
	const firebase = useFirebase();
	const [locations, setLocations] = useState([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const unsubscribe =
			isApproved && !isArchived
				? firebase.locations().onSnapshot((snap) => {
						setLocations(transformCollectionSnap(snap));
						setLoading(false);
				  })
				: () => console.log('waiting');

		return () => unsubscribe();
	}, [firebase, user, isApproved, isArchived]);

	const updateLocation = (values) => {
		firebase.updateLocation(formatLocation(values, user)).then(() => {
			toastr.success('Success', 'Warehouse Updated ');
		});
	};

	const createLocation = (values) => {
		firebase.createLocation(formatNewLocation(values, user)).then((id) => {
			toastr.success('Success', 'Warehouse Created ');

			return id;
		});
	};

	const archiveLocation = (groupId, archive) => {
		firebase
			.updateLocation(
				formatLocation({ id: groupId, isArchived: archive }, user)
			)
			.then(() => {
				toastr.success(
					'Success',
					archive ? 'Warehouse Archived ' : 'Warehouse Activated '
				);
			});
	};

	return (
		<LocationsContext.Provider
			value={{
				loading,
				locations,
				updateLocation,
				createLocation,
				archiveLocation,
			}}
		>
			{children}
		</LocationsContext.Provider>
	);
};

export const LocationsConsumer = LocationsContext.Consumer;

export const useLocations = () => useContext(LocationsContext);
