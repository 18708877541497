import { getObjFromLink } from '../helpers';

export const formatViewStockItems = (stock, variableSizes) => {
	return stock

		.map((item) => {
			return {
				...item,
				locations: item.quantities
					? item.quantities.map((q) => q.locationLink)
					: [],
			};
		})
		.map((item) => {
			if (item.quantities) {
				return {
					...item,
					quantities: item?.quantities?.sort((a, b) => {
						const sizeA = variableSizes.find((s) => a.size === s.nameLong);
						const sizeB = variableSizes.find((s) => b.size === s.nameLong);
						return sizeA?.magnitude > sizeB?.magnitude ? 1 : -1;
					}),
				};
			} else return item;
		})
		.map((item, idx) => {
			const img = item.coverImage;
			if (img) {
				const uploadText = img.indexOf('upload/');
				const afterUpload = img.substring(uploadText + 7);
				const sep = afterUpload.indexOf('/');
				const vText = afterUpload.substring(0, sep);
				const thumbnailImage = img.replace(vText, 'w_100');

				return { ...item, thumbnailImage };
			} else {
				return item;
			}
		});
};

export const formatStockItems = (stock, variableSizes) => {
	return stock
		.filter((s) => !s.isArchived)
		.map((item) => {
			return {
				...item,
				locations: item.quantities
					? item.quantities.map((q) => q.locationLink)
					: [],
			};
		})
		.map((item) => {
			if (item.quantities) {
				return {
					...item,
					quantities: item?.quantities?.sort((a, b) => {
						const sizeA = variableSizes.find((s) => a.size === s.nameLong);
						const sizeB = variableSizes.find((s) => b.size === s.nameLong);
						return sizeA?.magnitude > sizeB?.magnitude ? 1 : -1;
					}),
				};
			} else return item;
		})
		.map((item, idx) => {
			const img = item.coverImage;
			if (img) {
				const uploadText = img.indexOf('upload/');
				const afterUpload = img.substring(uploadText + 7);
				const sep = afterUpload.indexOf('/');
				const vText = afterUpload.substring(0, sep);
				const thumbnailImage = img.replace(vText, 'w_100');

				return { ...item, thumbnailImage };
			} else {
				return item;
			}
		});
};

export const formatNewStock = (stock, profile) => {
	const currentTime = new Date();

	const stockFields = {
		...stock,
		dateCreated: currentTime,
		createdBy: {
			name: profile.nameDisplay,
			id: profile.id,
			email: profile.email,
		},
		stockType: 'Fixed',
	};
	return stockFields;
};

export const formatUpdateStock = (stock, profile) => {
	const currentTime = new Date();

	const stockFields = {
		...stock,
		dateUpdated: currentTime,
		lastWorkedOnBy: {
			name: profile.nameDisplay,
			id: profile.id,
			email: profile.email,
		},
	};

	let actualSize = {};

	if (stock.actualWidth && stock.actualWidth !== '') {
		actualSize.actualWidth = stock.actualWidth;
	}
	if (stock.actualHeight && stock.actualHeight !== '') {
		actualSize.actualHeight = stock.actualHeight;
	}
	if (stock.actualDepth && stock.actualDepth !== '') {
		actualSize.depth = stock.actualDepth;
	}

	if (Object.keys(actualSize).length > 0) {
		stockFields.actualSize = actualSize;
	}

	let transportSize = {};

	if (stock.transportWidth && stock.transportWidth !== '') {
		transportSize.transportWidth = stock.transportWidth;
	}
	if (stock.transportHeight && stock.transportHeight !== '') {
		transportSize.transportHeight = stock.transportHeight;
	}
	if (stock.transportDepth && stock.transportDepth !== '') {
		transportSize.depth = stock.transportDepth;
	}

	if (Object.keys(transportSize).length > 0) {
		stockFields.transportSize = transportSize;
	}
	return stockFields;
};

export const formatUpdateCostCentre = (costCentre, profile) => {
	const currentTime = new Date();

	const costCentreFields = {
		...costCentre,
		dateUpdated: currentTime,
		lastWorkedOnBy: {
			name: profile.nameDisplay,
			id: profile.id,
			email: profile.email,
		},
	};

	return costCentreFields;
};

export const formatStockTakeStock = (stockItem, profile) => {
	const currentTime = new Date();

	return {
		...stockItem,
		lastStockTakeDate: currentTime,
		lastStockTakeBy: {
			name: profile.nameDisplay,
			id: profile.id,
			email: profile.email,
		},
	};
};

export const formatStockTake = (quantities, profile) => {
	const currentTime = new Date();
	const locationLink = quantities[0].locationLink;
	return {
		dateCreated: currentTime,
		createdBy: {
			name: profile.nameDisplay,
			id: profile.id,
			email: profile.email,
		},
		locationLink,
		quantities: quantities.filter((q) => q.counts),
	};
};

export const oldFormatStockItem = (stockItem) => {
	const formattedStock = {};
	if (stockItem.brandingGroupLink) {
		const obj = getObjFromLink(stockItem.brandingGroupLink);
		formattedStock.brandingGroupLink = obj.id;
	}

	if (stockItem.locations) {
		formattedStock.locations = stockItem.locations.map((link) => {
			const obj = getObjFromLink(link);
			return obj.id;
		});
	}
	if (stockItem.sizes && stockItem.stockType === 'Variable') {
		const sizes = {};
		stockItem.locations.forEach((locationLink, idx) => {
			sizes[`sizes_${idx}`] = stockItem.sizes[locationLink];
		});
		stockItem = { ...stockItem, ...sizes };
	}

	if (stockItem.selfCollection) {
		stockItem.selfCollection = 'yes';
	}

	if (stockItem.quantities && stockItem.stockType) {
		const quantity = {};
		if (stockItem.stockType === 'Fixed') {
			stockItem.locations.forEach((locationLink) => {
				const q = stockItem.quantities.find(
					(q) => q.locationLink === locationLink && q.size === 'Fixed'
				);
				const obj = getObjFromLink(locationLink);
				quantity[obj.id] = q.quantity;
			});
		} else {
			if (stockItem.sizes) {
				stockItem.locations.forEach((locationLink) => {
					stockItem.sizes[locationLink].forEach((size) => {
						const q = stockItem.quantities.find(
							(q) => q.locationLink === locationLink && q.size === size
						);
						const obj = getObjFromLink(locationLink);
						quantity[obj.id] = { [size]: q.quantity };
					});
				});
			}
		}
		stockItem.quantity = quantity;
	}

	return { ...stockItem, ...formattedStock };
};

/* Formats stock from db structure to new usable structure (EXAMPLE BELOW)

OLD
	//fixed

	quantity: {
		location_id: qty
	}

	//variable
		
	quantity:{
		location_id: {
			size: qty
		}
	}

NEW
	quantities: [
		{
			locationLink: location_id__location_name,
			quantity: qty,
			size: "fixed"/"size"
		}
	]

*/

export const formatStock = (stock, locations, brandingGroups) => {
	const formattedStock = stock.map((stockItem) => {
		if (stockItem.brandingGroupLink) {
			const group = brandingGroups.find(
				(b) => b.id === stockItem.brandingGroupLink
			);
			stockItem.brandingGroupLink = group ? `${group.id}__${group.name}` : null;
		}
		if (stockItem.stockType === 'Variable' && stockItem.locations) {
			const sizes = {};
			stockItem.locations.forEach((id, idx) => {
				const location = locations.find((l) => l.id === id);
				sizes[`${location.id}__${location.warehouseName}`] =
					stockItem[`sizes_${idx}`];
			});
			stockItem.sizes = sizes;
		}

		if (stockItem.locations) {
			stockItem.locations = stockItem.locations.map((id) => {
				const location = locations.find((l) => l.id === id);
				return `${location.id}__${location.warehouseName}`;
			});
		}

		if (stockItem.selfCollection && stockItem.selfCollection === 'yes') {
			stockItem.selfCollection = true;
		}

		if (stockItem.quantity) {
			let quantities = [];

			Object.keys(stockItem.quantity).forEach((locationId) => {
				const location = locations.find((l) => l.id === locationId);
				const locationLink = `${location.id}__${location.warehouseName}`;
				if (
					typeof stockItem.quantity[locationId] === 'object' &&
					stockItem.quantity[locationId] !== null
				) {
					Object.keys(stockItem.quantity[locationId]).forEach((size) => {
						quantities = [
							...quantities,
							{
								locationLink,
								quantity: parseInt(stockItem.quantity[locationId][size]),
								size,
							},
						];
					});
				} else {
					quantities = [
						...quantities,
						{
							locationLink,
							quantity: parseInt(stockItem.quantity[locationId]),
							size: 'Fixed',
						},
					];
				}
			});

			return {
				...stockItem,
				quantities,
			};
		} else {
			return stockItem;
		}
	});

	return formattedStock;
};
