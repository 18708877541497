export const formatLocation = (location, profile) => {
	const currentTime = new Date();
	return {
		...location,
		dateUpdated: currentTime,
		lastWorkedOnBy: {
			name: profile.nameDisplay,
			id: profile.id,
			email: profile.email
		}
	};
};

export const formatNewLocation = (location, profile) => {
	const currentTime = new Date();
	return {
		...location,
		dateCreated: currentTime,
		lastWorkedOnBy: {
			name: profile.nameDisplay,
			id: profile.id,
			email: profile.email
		},
		createdBy: {
			name: profile.nameDisplay,
			id: profile.id,
			email: profile.email
		}
	};
};
