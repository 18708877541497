import React, { useState } from 'react';
import { Form, Message } from 'semantic-ui-react';
import TextInput from '../../app/components/Form/TextInput';
import ClientButton from '../../app/layout/ui/genericStyles/ClientButton';
import { useForm } from '../../app/forms/useForm';
import { useFirebase } from '../../context/Firebase';
import styled from 'styled-components';
import { getObjFromLink } from '../../context/helpers';

export default function RegisterForm({ companyLink, history }) {
	const formFields = [
		{
			name: 'nameFirst',
			title: 'First Name',
			type: 'text',
			validate: (x) => {
				return x && x.length > 2;
			},
			error: 'Name must be longer than 2 characters',
			placeholder: 'First Name',
		},
		{
			name: 'nameLast',
			title: 'Surname',
			type: 'text',
			validate: (x) => {
				return x && x.length > 2;
			},
			error: 'Name must be longer than 2 characters',
			placeholder: 'Surname',
		},
		{
			name: 'email',
			title: 'Email',
			type: 'email',
			validate: (x) => {
				var re =
					/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
				return re.test(x);
			},
			error: 'Must be valid email address',
			placeholder: 'example@turnkey.com',
		},
		{
			name: 'phoneMobile',
			title: 'Mobile Number',
			type: 'text',
			validate: (x) => {
				return x === '' || x.length >= 9;
			},
			error: 'Number must be at least 9 characters',
			placeholder: '(000) 000 0000',
			optional: true,
		},
		{
			name: 'phoneWork',
			title: 'Work Number',
			type: 'text',
			validate: (x) => {
				return x === '' || x.length >= 9;
			},
			error: 'Number must be at least 9 characters',
			placeholder: '(000) 000 0000',
			optional: true,
		},
		{
			name: 'password',
			title: 'Password',
			type: 'password',
			validate: (x) => x.length >= 6,
			error: 'Password must be 6 characters or longer',
			placeholder: '••••••',
		},
	];
	const { formValid, inputProps, formattedValues, values } =
		useForm(formFields);
	const [error, setError] = useState();
	const [loading, setLoading] = useState(false);

	const firebase = useFirebase();

	const handleSubmit = () => {
		setLoading(true);
		formattedValues.nameDisplay = `${formattedValues.nameFirst} ${formattedValues.nameLast}`;
		firebase
			.signupWithEmail(formattedValues.email, formattedValues.password)
			.then((authUser) => {
				delete formattedValues.password;
				return firebase
					.user(authUser.user.uid)
					.set(
						{
							companyLink: companyLink,
							companyObj: getObjFromLink(companyLink),
							isClient: true,
							isAdmin: false,
							isWarehouse: false,
							...formattedValues,
							isApproved: false,
							isArchived: false,
							isCostCentreManager: false,
						},
						{ merge: true }
					)

					.then(() => {
						history.push('/dashboard');
					})
					.catch((error) => {
						console.log(error);
						setError(error);
						setLoading(false);
					});
			})
			.catch((error) => {
				console.log(error);
				setError(error);
				setLoading(false);
			});
	};

	const inputPropObj = { inputProps, values };
	return (
		<RegisterBlock>
			<Form>
				{formFields.map(({ title, name, placeholder }, idx) => (
					<TextInput
						key={idx}
						name={name}
						label={title}
						placeholder={placeholder}
						{...inputPropObj}
					/>
				))}
				{error && <Message negative>{error.message}</Message>}
				{loading ? (
					<ClientButton fluid loading>
						Loading
					</ClientButton>
				) : (
					<ClientButton
						fluid
						disabled={!formValid}
						onClick={() => handleSubmit()}
					>
						REGISTER
					</ClientButton>
				)}
			</Form>
		</RegisterBlock>
	);
}

const RegisterBlock = styled.div`
	.form {
		display: grid;
		grid-template-columns: 1fr 1fr;
		column-gap: 10px;
	}

	.form .field:nth-child(3),
	.form .field:nth-child(6),
	span:nth-child(7),
	div:nth-child(7),
	span:nth-child(8) {
		grid-column: 1 / 3;
	}

	.ui.form .field input,
	.ui.form .field label {
		padding: 8px 15px !important;
	}
`;
