import React from 'react';
import ValidationWrapper from './ValidationWrapper';

function TextInput({
	values,
	inputProps,
	name,
	label,
	placeholder,
	width,
	readOnly
}) {
	return (
		<ValidationWrapper values={values} name={name} width={width}>
			<label>{label}</label>
			<input {...inputProps(name)} {...{ placeholder }} readOnly={readOnly} />
		</ValidationWrapper>
	);
}

export default TextInput;
