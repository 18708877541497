// import React, { createContext, useContext, useState, useEffect } from 'react';
// import { useFirebase } from '../Firebase';
// import { useUser, useAuth } from '../auth';
// import { getObjFromLink } from '../helpers';

// const PaymentsContext = createContext({});

// export const PaymentsProvider = ({ children }) => {
// 	const user = useUser();
// 	const firebase = useFirebase();
// 	const { companyLink, isApproved, isArchived } = useAuth();
// 	const compObj = companyLink ? getObjFromLink(companyLink) : {};
// 	const compId = compObj.id ? compObj.id : '';
// 	const [loading, setLoading] = useState(true);

// 	useEffect(() => {
// 		const unsubscribe =
// 			compId !== '' && isApproved && !isArchived
// 				? () => setLoading(false)
// 				: () => console.log('waiting');
// 		return () => unsubscribe();
// 	}, [firebase, compId, isApproved, isArchived, user]);

// 	const addPaymentRequest = async (orderData, orderID) => {
// 		return firebase.addPaymentRequest(orderData, orderID).then((orderID) => {
// 			return orderID;
// 		});
// 	};

// 	const getPaymentRequest = async (paymentID) => {
// 		let paymentRequest;
// 		const result = await firebase
// 			.paymentRequests()
// 			.collection(paymentID)
// 			.get()
// 			.then((querySnapshot) => {
// 				querySnapshot.forEach((doc) => {
// 					paymentRequest = doc.data();
// 				});
// 			});
// 		return paymentRequest;
// 	};

// 	const getCompletedPayment = async (paymentID) => {
// 		let completedPayment;
// 		const result = await firebase
// 			.completedPayments()
// 			.collection(paymentID)
// 			.get()
// 			.then((querySnapshot) => {
// 				querySnapshot.forEach((doc) => {
// 					completedPayment = doc.data();
// 				});
// 			});

// 		return completedPayment;
// 	};

// 	return (
// 		<PaymentsContext.Provider
// 			value={{
// 				loading,
// 				addPaymentRequest,
// 				getPaymentRequest,
// 				getCompletedPayment,
// 			}}
// 		>
// 			{children}
// 		</PaymentsContext.Provider>
// 	);
// };

// export const PaymentsConsumer = PaymentsContext.Consumer;

// export const usePayments = () => useContext(PaymentsContext);

import React, { createContext, useContext, useState, useEffect } from 'react';
import { useFirebase } from '../Firebase';
import { useUser, useAuth } from '../auth';
import { getObjFromLink } from '../helpers';

const PaymentsContext = createContext({});

export const PaymentsProvider = ({ children }) => {
	const user = useUser();
	const firebase = useFirebase();
	const { companyLink, isApproved, isArchived } = useAuth();
	const compObj = companyLink ? getObjFromLink(companyLink) : {};
	const compId = compObj.id ? compObj.id : '';

	const addPaymentRequest = async (orderData, orderID) => {
		return firebase.addPaymentRequest(orderData, orderID).then((orderID) => {
			return orderID;
		});
	};

	const getPaymentRequest = async (paymentID) => {
		let paymentRequest;
		const result = await firebase
			.paymentRequests()
			.collection(paymentID)
			.get()
			.then((querySnapshot) => {
				querySnapshot.forEach((doc) => {
					paymentRequest = doc.data();
				});
			});
		return paymentRequest;
	};

	const getCompletedPayment = async (paymentID) => {
		let completedPayment;
		const result = await firebase
			.completedPayments()
			.collection(paymentID)
			.get()
			.then((querySnapshot) => {
				querySnapshot.forEach((doc) => {
					completedPayment = doc.data();
				});
			});

		return completedPayment;
	};

	return (
		<PaymentsContext.Provider
			value={{
				addPaymentRequest,
				getPaymentRequest,
				getCompletedPayment,
			}}
		>
			{children}
		</PaymentsContext.Provider>
	);
};

export const PaymentsConsumer = PaymentsContext.Consumer;

export const usePayments = () => useContext(PaymentsContext);
