export const formatBrandingGroup = (group, profile) => {
	const currentTime = new Date();
	return {
		...group,
		dateUpdated: currentTime,
		lastWorkedOnBy: {
			name: profile.nameDisplay,
			id: profile.id,
			email: profile.email
		}
	};
};

export const formatNewBrandingGroup = (group, profile) => {
	const currentTime = new Date();
	return {
		...group,
		dateCreated: currentTime,
		lastWorkedOnBy: {
			name: profile.nameDisplay,
			id: profile.id,
			email: profile.email
		},
		createdBy: {
			name: profile.nameDisplay,
			id: profile.id,
			email: profile.email
		}
	};
};
