import React, { createContext, useContext, useState, useEffect } from 'react';
import { useFirebase } from '../Firebase';
import { useUser, useAuth } from '../auth';
import { transformCollectionSnap, getObjFromLink } from '../helpers';
import { formatBrandingGroup, formatNewBrandingGroup } from './formatting';
import { toastr } from 'react-redux-toastr';

const BrandingGroupsContext = createContext({});

export const BrandingGroupsProvider = ({ children }) => {
	const user = useUser();
	const { companyLink, isApproved, isArchived } = useAuth();
	const compObj = companyLink ? getObjFromLink(companyLink) : {};
	const compId = compObj.id ? compObj.id : '';
	const firebase = useFirebase();
	const [brandingGroups, setBrandingGroups] = useState([]);
	const [archivedBrandingGroups, setArchivedBrandingGroups] = useState([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const unsubscribe =
			compId !== '' && isApproved && !isArchived
				? firebase.brandingGroups(compId).onSnapshot((snap) => {
						setBrandingGroups(
							transformCollectionSnap(snap).filter((b) => !b.isArchived)
						);
						setArchivedBrandingGroups(
							transformCollectionSnap(snap).filter((b) => b.isArchived)
						);
						setLoading(false);
				  })
				: () => console.log('bg waiting for user to load');
		return () => unsubscribe();
	}, [firebase, compId, isApproved, isArchived]);

	const updateBrandingGroup = (values) => {
		firebase
			.updateBrandingGroup(compId, formatBrandingGroup(values, user))
			.then(() => {
				toastr.success('Success', 'Branding Group Updated ');
			});
	};

	const createBrandingGroup = (values) => {
		firebase
			.createBrandingGroup(compId, formatNewBrandingGroup(values, user))
			.then((id) => {
				toastr.success('Success', 'Branding Group Created ');
				if (id) {
					return id;
				}
			});
	};

	const archiveBrandingGroup = (groupId, archive) => {
		firebase
			.updateBrandingGroup(
				compId,
				formatBrandingGroup({ id: groupId, isArchived: archive }, user)
			)
			.then(() => {
				toastr.success(
					'Success',
					archive ? 'Branding Group Archived ' : 'Branding Group Activated '
				);
			});
	};

	return (
		<BrandingGroupsContext.Provider
			value={{
				loading,
				brandingGroups,
				archivedBrandingGroups,
				updateBrandingGroup,
				createBrandingGroup,
				archiveBrandingGroup,
			}}
		>
			{children}
		</BrandingGroupsContext.Provider>
	);
};

export const BrandingGroupsConsumer = BrandingGroupsContext.Consumer;

export const useBrandingGroups = () => useContext(BrandingGroupsContext);
