import React, { useState, useEffect, useContext, createContext } from 'react';
import { useFirebase } from '../Firebase';

const AuthContext = createContext();
const UserContext = createContext();

export const AuthProvider = ({ children }) => {
	const firebase = useFirebase();

	const [authState, setAuthState] = useState({
		user: undefined,
		userId: undefined,
		loading: true,
	});
	const [userState, setUserState] = useState({ loading: true });

	const handleLoginRedirect = async (data) => {
		var pathArray = window.location.pathname.split('/');

		if (
			data.companyLink &&
			(window.location.pathname === '/' || pathArray[1] === 'login')
		) {
			window.location.replace('/dashboard');
		}
	};

	const handleDashboardRedirect = async (data) => {
		if (!data && window.location.pathname !== '/') {
			window.location.replace('/');
		}
	};

	useEffect(() => {
		firebase.checkUserAuth(async (authUser) => {
			handleDashboardRedirect(authUser);
			if (authUser) {
				await firebase.auth.currentUser
					.getIdTokenResult()
					.then((idTokenResult) => {
						const link = localStorage.getItem('companyLink');
						if (
							link !== 'undefined' &&
							link !== idTokenResult.claims.companyLink
						) {
							if (
								(link === 'TK-BOSS-ADMIN__Turnkey' &&
									idTokenResult.claims.isAdmin) ||
								idTokenResult.claims.isAdmin ||
								idTokenResult.claims.isWarehouse ||
								idTokenResult.claims.companyLink === undefined
							) {
								setAuthState((prev) => ({
									...prev,
									...idTokenResult.claims,
									user: authUser,
									userId: authUser.uid,
									companyLink: link,

									loading: false,
								}));
							} else {
								localStorage.setItem(
									'companyLink',
									idTokenResult.claims.companyLink
								);
								setAuthState((prev) => ({
									...prev,
									...idTokenResult.claims,
									user: authUser,
									userId: authUser.uid,
									companyLink: idTokenResult.claims.companyLink,
									loading: false,
								}));
							}

							handleLoginRedirect(idTokenResult.claims);
						} else {
							if (idTokenResult.claims.companyLink) {
								localStorage.setItem(
									'companyLink',
									idTokenResult.claims.companyLink
								);

								handleLoginRedirect(idTokenResult.claims);
							}

							setAuthState((prev) => ({
								...prev,
								...idTokenResult.claims,
								user: authUser,
								userId: authUser.uid,
								loading: false,
							}));
						}

						if (idTokenResult.claims.isApproved === undefined) {
							setAuthState((prev) => ({
								...prev,
								isApproved: false,
								loading: false,
							}));
						}
						if (idTokenResult.claims.isArchived === undefined) {
							setAuthState((prev) => ({
								...prev,
								isArchived: false,
								loading: false,
							}));
						}
					});
				await firebase.user(authUser.uid).update({ lastUpdate: new Date() });

				const userSnap = await firebase.user(authUser.uid).get();

				const userData = userSnap.data();

				if (userData.companyLink !== 'TK-BOSS-ADMIN__Turnkey') {
					localStorage.setItem('isClient', true);
				}

				setUserState({ ...userData, id: userSnap.id, loading: false });
			}
		});
		//including authState.comapnyLink in dependancies makes company switching not work
	}, [firebase]);

	const setCompanyLink = (link) => {
		setAuthState((prev) => ({ ...prev, companyLink: link }));
		if (link !== 'TK-BOSS-ADMIN__Turnkey') {
			localStorage.setItem('isClient', true);
		}

		localStorage.setItem('companyLink', link);
	};

	// useEffect(() => {
	// 	if (userState.isApproved !== authState.isApproved) {
	// 		setAuthState((prev) => ({ ...prev, ...userState }));
	// 	}
	// }, [authState, userState]);

	return (
		<UserContext.Provider value={userState}>
			<AuthContext.Provider value={{ ...authState, setCompanyLink }}>
				{children}
			</AuthContext.Provider>
		</UserContext.Provider>
	);
};

export const useAuth = () => useContext(AuthContext);
export const useUser = () => useContext(UserContext);
