export const formatSize = (size, profile) => {
	return {
		...size,
		dateUpdated: new Date(),
		lastWorkedOnBy: {
			name: profile.nameDisplay,
			id: profile.id,
			email: profile.email
		}
	};
};

export const formatNewSize = (size, profile) => {
	return {
		...size,
		dateCreated: new Date(),
		createdBy: {
			name: profile.nameDisplay,
			id: profile.id,
			email: profile.email
		}
	};
};
