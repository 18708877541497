import React from 'react';
import { Button } from 'semantic-ui-react';
import styled from 'styled-components';

const ClientBtn = styled(Button)`
	background: ${(props) => props.theme.primaryBg}!important;
	color: ${(props) => props.theme.primaryTxt} !important;
	position: relative;
	transition: all 0.3s ease;
	:hover {
		background: ${(props) => props.theme.secondaryBg}!important;
	}
`;

const ClientButton = (props) => (
	<span>
		<ClientBtn {...props} />
	</span>
);

export default ClientButton;
