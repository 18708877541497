import React, { createContext, useContext, useState, useEffect } from 'react';
import { transformCollectionSnap, getObjFromLink } from '../helpers';
import { useFirebase } from '../Firebase';
import { useAuth } from '../auth';

const CompaniesContext = createContext({});

export const CompaniesProvider = ({ children }) => {
	const { companyLink, isApproved, isArchived, isAdmin, isWarehouse } =
		useAuth();
	const compObj = companyLink ? getObjFromLink(companyLink) : {};
	const compId = compObj.id ? compObj.id : '';
	const firebase = useFirebase();
	const [companies, setCompanies] = useState([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		if (isAdmin || isWarehouse) {
			const unsubscribe =
				compId !== '' && isApproved && !isArchived
					? firebase.companies().onSnapshot((snap) => {
							setCompanies(transformCollectionSnap(snap));
							setLoading(false);
					  })
					: () => console.log('waiting');

			return () => unsubscribe();
		} else {
			setLoading(false);
		}
	}, [firebase, compId, isApproved, isArchived, isAdmin, isWarehouse]);

	return (
		<CompaniesContext.Provider value={{ companies, loading }}>
			{children}
		</CompaniesContext.Provider>
	);
};

export const CompaniesConsumer = CompaniesContext.Consumer;

export const useCompanies = () => useContext(CompaniesContext);
